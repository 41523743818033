import React from 'react';
import SurveyCard from '../../../components/Styled/SurveyCard';
import PollAccordion from '../../../components/Styled/PollAccordion';
import { Box } from '@chakra-ui/react';
import { useReactToPrint } from 'react-to-print';
import { set } from 'lodash';

const SurveyViewContainer = ({ surveyData }) => {
    const surveyCardRef = React.useRef();
    const pollAccordionRef = React.useRef();
    const [ expandAllItems, setExpandAllItems ] = React.useState(true);

    const handlePrint = useReactToPrint({
        content: () => {
            const printElement = document.createElement('div');
            const surveyCardElement = surveyCardRef.current.cloneNode(true);
            const pollAccordionElement = pollAccordionRef.current.cloneNode(true);

            const boxElement = document.createElement('div');
            boxElement.style.backgroundColor = 'white';
            boxElement.style.padding = '5px';
            boxElement.style.borderRadius = 'lg';
            boxElement.style.boxShadow = 'xl';
            boxElement.style.marginBottom = '5px';
            boxElement.appendChild(pollAccordionElement);

            printElement.appendChild(surveyCardElement);
            printElement.appendChild(boxElement);

            return printElement;
        },
        onAfterPrint: () => setExpandAllItems(false) // Reset after printing
    });

    const printSurvey = () => {
        console.log('Printing survey');
        setExpandAllItems(true);
        // Wait for state update to complete before printing
        setTimeout(() => {
            handlePrint();
        }, 0);
    };

    return (
        <>
            <div ref={surveyCardRef}>
                <SurveyCard 
                    title={surveyData.title} 
                    description={surveyData.description} 
                    status={surveyData.status} 
                    closingDate={surveyData.polls[0].closing_date}
                    handlePrint={printSurvey}
                />
            </div>
            <Box bg="white" p={5} borderRadius={'lg'} shadow='xl' mb={5} ref={pollAccordionRef}>
                <PollAccordion polls={surveyData.polls} expandAllItems={expandAllItems}/>
            </Box>
        </>
    );
};

export default SurveyViewContainer;
