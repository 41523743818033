import { API1 } from "../../../utils/api";

// Fetch All LabServiceQuotationRequests
export const fetchAllLabQuotationRequests = async (pageNumber = 1, pageSize = 10, filterOptions = {}) => {
    try {
        const queryParams = new URLSearchParams({
            ...filterOptions,
            pageNumber,
            pageSize
        }).toString();

        const response = await API1.get(`/labs/?${queryParams}`);

        if (response.status === 200 && response.data && response.data.data) {
            const { docs: labQuotationRequests, totalDocs } = response.data.data;
            return {
                labQuotationRequests,
                totalPages: Math.ceil(totalDocs / pageSize),
            };
        } else {
            throw new Error('Unexpected response from the server while fetching Lab Service Quotation Requests.');
        }
    } catch (error) {
        console.error("Error fetching Lab Service Quotation Requests:", error);
        throw new Error('Failed to fetch Lab Service Quotation Request. Please try again.');
    }
};



//Fetch LabQuotationRequestById

export const fetchLabQuotationRequestById = async (labServiceRequestId) => {
    try {
        const queryParams = new URLSearchParams({ id: labServiceRequestId }).toString();
        const response = await API1.get(`/labs/?${queryParams}`);
        if (response.status === 200 && response.data.data.docs.length > 0) {
            return response.data.data.docs[0];
        } else {
            throw new Error('Lab Service Quotation Request not found.');
        }
    } catch (error) {
        throw new Error(error?.message || "Failed to fetch the Lab Service Quotation Request details.");
    }
};