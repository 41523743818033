import styled from 'styled-components';
import {
    Flex,
    Box,
    Button,
    chakra,
    VStack,
    Link as ChakraLink,
    PopoverContent as ChakraPopoverContent,
    SimpleGrid,
    Stack,
    theme, PopoverContent
} from '@chakra-ui/react';


export const StyledHeader = styled(chakra.header)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  box-shadow: ${theme.shadows.md};
  transition: box-shadow 0.2s, height 0.2s ease;
  width: 100%;
  background-color: ${props => props.bg || theme.colors.white};
  border-top: 6px solid ${theme.colors.blue[300]};
  border-bottom-width: 2px;
  color: ${theme.colors.gray[800]};
`;

export const NavBarContainer = styled(Flex)`
  max-width: 1349px;
  margin: 0 auto;
  padding: 0 24px;
  align-items: center;
  justify-content: space-between;
  height: 4.5rem;
`;

export const NavButton = styled(Button)`
  background-color: transparent;
  &:hover {
    background-color: #EDF2F7;
    text-decoration: none;
  }
`;

export const Link = styled(ChakraLink)`
  color: #2B6CB0;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

export const MobileNavButton = styled(Button)`
  width: 100%;
  justify-content: flex-start;
  padding: 0.5rem 1rem;
`;

export const MobileNav = styled(VStack)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  flex-direction: column;
  padding: 8px;
  margin-top: 4.5rem;
  background-color: ${({ bg }) => bg};
  border-radius: 8px;
  box-shadow: ${theme.shadows.md};
  z-index: 20;
  display: none;
`;

export const StyledPopoverContent = styled(PopoverContent)`
  ${({ theme }) => `
    box-shadow: ${theme.shadows.xl} !important;
    border: 1px solid ${theme.colors.gray[200]};
    border-radius: ${theme.radii.md};
    padding: ${theme.space[4]};
    width: auto;
    min-width: 480px; // You can adjust this value as needed
    background-color: ${props => props.bg || theme.colors.white};
    .bottom-buttons-container {
      margin-top: ${theme.space[4]};
    }
  `}
`;

export const IconBox = styled(Box)`
  flex-shrink: 0;
  height: 24px;
  width: 24px;
  color: #A0AEC0;
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

export const SectionLink = styled(Link)`
  display: flex;
  align-items: flex-start;
  padding: 0.5rem;
  border-radius: 0.375rem;
  margin: 0.125rem 0;
  text-decoration: none;
  &:hover {
    background-color: #EDF2F7;
    text-decoration: none !important;
  }
`;

export const StyledSimpleGrid = styled(SimpleGrid)`
  gap: 24px;
  padding: 20px;
`;

export const FooterBox = styled(Box)`
  padding: 20px;
  background-color: ${({ hbg }) => hbg};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FooterStack = styled(Stack)`
  flex-direction: row;
  gap: 24px;
  justify-content: center;
  align-items: center;
`;
