import React, { useState } from 'react';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    useToast,
    Box,
    Text, SimpleGrid, Spinner,
    Table,
    Tbody,
    TableContainer, Switch, Flex,
    Grid,
    Input,
    InputGroup
} from '@chakra-ui/react';
import { ThemedStyledInput } from "../../../components/Styled";
import FormActionLabel from "../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../components/Styled/StyledButtons";
import { useDrawer } from '../../../Context/DrawerContext/DrawerContext';
import { addPoll } from "../SurveyAPI";
import { Formik, Form, Field, FieldArray } from 'formik';
import ConfirmDialog from "../../../Context/ConfirmDialog/ConfirmDialog";
import { DeleteButton } from "../../../components/Styled/ActionButtons";
import { Icon } from "@iconify/react";
import 'react-datepicker/dist/react-datepicker.css'; // Import styles

const PollAddForm = ({ refetchPollsData }) => {
    const { closeDrawer } = useDrawer();
    const toast = useToast({ position: 'top-right' });
    var [pollSchema, setPollSchema] = useState({
        question: '',
        options: [
            'default',
        ],
        votes: [],
        published: false,
        start_date: '',
        closing_date: ''
    });

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => { },
    });

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {

        console.log(values);
        // return;

        if (values.question == null || values.question == '') {
            toast({
                title: 'Poll question is required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        if (values.options == null || values.options.length == 0 || values.options == []) {
            toast({
                title: 'Poll options are required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        if (values.options.length < 2) {
            toast({
                title: 'At least 2 poll options are required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        if (values.start_date == null || values.start_date == '') {
            toast({
                title: 'Start date is required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        if (values.closing_date == null || values.closing_date == '') {

            toast({
                title: 'Closing date is required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        try {

            await addPoll(JSON.stringify({
                "question": values.question,
                "options": values.options,
                "published": values.published,
                "start_date": new Date(values.start_date),
                "closing_date": new Date(values.closing_date)
            }));

            toast({
                title: 'Polls added successfully',
                description: `It will be available once its published`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            });

            resetForm();
            refetchPollsData(); // Trigger refetching of polls data
            closeDrawer();

        } catch (error) {
            if (error.response) {
                // Server error with response
                toast({
                    title: 'Error adding polls',
                    description: error.response.data.message || 'An unexpected server error occurred',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } else if (error.request) {
                // Network error without response
                toast({
                    title: 'Network Error',
                    description: 'Unable to connect to the server. Please check your internet connection.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                // Other errors
                toast({
                    title: 'Error',
                    description: error.message || 'An unexpected error occurred',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } finally {
            setSubmitting(false);
        }
    };

    const requestConfirm = (options) => {
        setConfirmDialog({
            isOpen: true,
            title: options.title,
            message: options.message,
            onConfirm: options.onConfirm,
        });
    };

    const handleConfirm = () => {
        confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

     // a minDate function to get the minimum date
     const getMinAgeDate = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();
        return `${yyyy}-${mm}-${dd}`;
    };


    const handleDate = (e) => {
        const { value } = e.target;
        let minDate = getMinAgeDate()
        console.log(pollSchema.closing_date)
        if (minDate < value) {
            // setDate(getMinAgeDate())
            setPollSchema(prevState => ({
                ...prevState, closing_date: getMinAgeDate()
            }));
        }
        else {
            // setDate(value)
            setPollSchema(prevState => ({
                ...prevState, closing_date: value
            }));
        }
    };

    // console.log("Closing date", pollSchema.closing_date);


    return (
        <>
            <Formik
                initialValues={pollSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {formikProps => (
                    <Form>
                        <VStack spacing={4} align="stretch">
                            <FormActionLabel formAction="add" formName="Polls" />
                            <SimpleGrid columns={1} gap={4} px={2} py={4}>
                                <FieldControl formikProps={formikProps} name="question" label="Question" placeholder="Enter poll question" />
                                
                                <FormLabel marginTop={2} marginBottom={0}>Options</FormLabel>
                                <FieldArray name="options" >
                                    {({ push, remove }) => (
                                        <TableContainer >
                                            <Table variant="simple" size="lg" mb={4}>
                                                <Tbody>
                                                    {formikProps.values.options.map((option, index) => (
                                                        <Grid templateColumns='4fr 1fr' gap={4} key={index} my={3}>
                                                            <GridItem>
                                                                <Field
                                                                    as={Input}
                                                                    name={`options[${index}]`}
                                                                    placeholder="Enter option value"
                                                                />
                                                            </GridItem>
                                                            <GridItem pt={1}>
                                                                <DeleteButton onClick={() => remove(index)} />
                                                            </GridItem>
                                                        </Grid>
                                                    ))}
                                                </Tbody>
                                            </Table>
                                            <CustomButton
                                                type="button"
                                                showIcon={true}
                                                showTooltip={true}
                                                tooltipLabel="Add Option"
                                                icon={<Icon icon="ci:plus" />}
                                                onClick={() => push('')}
                                            >
                                                Add Option
                                            </CustomButton>
                                        </TableContainer>
                                    )}
                                </FieldArray>
                                <FormControl display='flex' alignItems='center'>
                                    <FormLabel htmlFor='published' mb='0'>
                                        Publish?
                                    </FormLabel>
                                    <Switch 
                                        id='published' 
                                        name="publish" 
                                        onChange={(e) => {
                                                formikProps.setFieldValue('published', e.target.checked);
                                                // setPollSchema((prevSchema) => ({
                                                //     ...prevSchema,
                                                //     published: e.target.checked,
                                                // }));
                                            }
                                        }
                                    />
                                </FormControl>
                                <Flex>
                                <FormControl id="start_date" isRequired>
                                    <FormLabel >Start Date</FormLabel>
                                    <InputGroup>
                                        <Input
                                            type="date"
                                            name="start_date"
                                            id="start_date"
                                            min={getMinAgeDate()}
                                            value={formikProps.values.start_date}
                                            onChange={(event) => {
                                                formikProps.handleChange(event);
                                                // handleDate(event);
                                            }}
                                        />
                                    </InputGroup>
                                </FormControl>
                                <FormControl id="closing_date" isRequired>
                                    <FormLabel>Closing Date</FormLabel>
                                    <InputGroup>
                                        <Input
                                            type="date"
                                            name="closing_date"
                                            id="closing_date"
                                            min={formikProps.values.start_date}
                                            value={formikProps.values.closing_date}
                                            onChange={(event) => {
                                                formikProps.handleChange(event);
                                                // handleDate(event);
                                            }}
                                        />
                                    </InputGroup>
                                    </FormControl>
                                </Flex>
                            </SimpleGrid>
                            <AlertAndButtons
                                formikProps={formikProps}
                                closeDrawer={closeDrawer}
                                requestConfirm={requestConfirm} />
                        </VStack>
                    </Form>
                )}
            </Formik>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

const FieldControl = ({ formikProps, name, label, placeholder }) => (
    <GridItem>
        <FormControl isInvalid={formikProps.errors[name] && formikProps.touched[name]}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <ThemedStyledInput
                id={name}
                placeholder={placeholder}
                {...formikProps.getFieldProps(name)}
            />
            {formikProps.errors[name] && formikProps.touched[name] && (
                <Text color="red.500" fontSize="sm">{formikProps.errors[name]}</Text>
            )}
        </FormControl>
    </GridItem>
);

const AlertAndButtons = ({ formikProps, closeDrawer, requestConfirm }) => {

    const handleCancel = () => {
        requestConfirm({
            title: "Cancel Confirmation",
            message: "Are you sure you want to cancel? Any unsaved changes will be lost.",
            onConfirm: () => {
                formikProps.handleReset();
                closeDrawer();
            }
        });
    };

    const handleReset = () => {
        requestConfirm({
            title: "Reset Confirmation",
            message: "Are you sure you want to reset all your changes?",
            onConfirm: () => {
                formikProps.handleReset();
            }
        });
    };

    return (
        <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}>
            <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
            <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                    Cancel
                </CustomButton>
                <CustomButton onClick={handleReset} type="reset">
                    Reset
                </CustomButton>
                <CustomButton
                    type="submit"
                    disabled={formikProps.isSubmitting}
                    style={{
                        opacity: formikProps.isSubmitting ? 0.7 : 1,
                        pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                    }}
                >
                    {formikProps.isSubmitting ? (
                        <>
                            <Spinner size="xs" mr={2} />
                            Submitting...
                        </>
                    ) : 'Submit'}
                </CustomButton>
            </ButtonStack>
        </Box>
    );
};


export default PollAddForm;
