import React from 'react'
import {Icon} from "@iconify/react";
import DefaultTemplate from "../../../components/DefaultTemplate/DefaultTemplate";
import VideoGalleryContainer from "./Components/VideoGalleryContainer";

const Videos = () => {
    return (
        <>
            <DefaultTemplate Content={ <VideoGalleryContainer/> } Title={"Videos" } icon={<Icon icon="ri:video-upload-fill" />}/>
        </>
    )
}

export default Videos
