import {API1} from "../../../utils/api";

// Create Video
export const createVideo = async (videoData) => {
    try {
        const response = await API1.post('/videos', videoData);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "An unexpected error occurred while creating the video.");
    }
};

// Fetch All Videos
export const fetchAllVideos = async (pageNumber = 1, pageSize = 10, filterOptions = {}) => {
    try {
        const queryParams = new URLSearchParams({
            ...filterOptions,
            pageNumber,
            pageSize
        }).toString();

        const response = await API1.get(`/videos?${queryParams}`);

        if (response.status === 200) {
            const { docs: videos, totalDocs } = response.data;
            return {
                videos,
                totalPages: Math.ceil(totalDocs / pageSize),
            };
        } else {
            throw new Error('Unexpected response from the server while fetching Videos.');
        }
    } catch (error) {
        console.error("Error fetching Videos:", error);
        throw new Error('Failed to fetch Videos. Please try again.');
    }
};


// Fetch Video by ID
export const fetchVideoById = async (videoId) => {
    try {
        const queryParams = new URLSearchParams({ id: videoId }).toString();
        const response = await API1.get(`/videos?${queryParams}`);
        if (response.status === 200 && response.data.docs.length > 0) {
            return response.data.docs[0];
        } else {
            throw new Error('Video not found.');
        }
    } catch (error) {
        throw error;
    }
};



// Update Video
export const updateVideo = async (videoId, videoData) => {
    try {
        const response = await API1.put(`/videos/${videoId}`, videoData);
        if (response.status !== 200) {
            throw new Error('Update failed due to server response');
        }
        return response.data;
    } catch (error) {
        console.error("Error updating video:", error);
        throw new Error(error?.message || "An error occurred while updating the video.");
    }
};


// Delete Video
export const deleteVideo = async (videoId) => {
    try {
        const response = await API1.delete(`/videos/${videoId}`);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "Failed to delete the video.");
    }
};