// faqService.js
import {API1} from '../utils/api'; // Make sure to import your API1 instance

const BASE_URL = process.env.REACT_APP_API_BASE_URL ?? '//localhost:3005'; // Replace this with your actual Base URL

const faqService = {
    createFAQ: async (faqData) => {
        const response = await API1.post(`/faqs`, faqData);
        if (!response.data) {
            throw new Error(`Error creating FAQ: ${response.statusText}`);
        }
        return response.data;
    },

    getAllFAQs: async (published = true) => {
        const response = await API1.get(`/faqs?published=${published}`);
        if (!response.data) {
            throw new Error(`Error fetching FAQs: ${response.statusText}`);
        }
        return response.data;
    },

    getFAQById: async (id) => {
        const response = await API1.get(`/faqs/${id}`);
        if (!response.data) {
            throw new Error(`Error fetching FAQ: ${response.statusText}`);
        }
        return response.data;
    },

    getFAQByCategory: async (category) => {
        const response = await API1.get(`/faqs/categories/${category}`);
        if (!response.data) {
            throw new Error(`Error fetching FAQ by category: ${response.statusText}`);
        }
        return response.data;
    },

    updateFAQ: async (id, faqData) => {
        const response = await API1.put(`/faqs/${id}`, faqData);
        if (!response.data) {
            throw new Error(`Error updating FAQ: ${response.statusText}`);
        }
        return response.data;
    },

    softDeleteFAQ: async (id) => {
        const response = await API1.delete(`/faqs/${id}`, { data: { published: false } });
        if (!response.data) {
            throw new Error(`Error soft deleting FAQ: ${response.statusText}`);
        }
        return response.data;
    },
};

export default faqService;
