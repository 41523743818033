export const checkImageDimensions = (file, width, height) => {
    return new Promise((resolve, reject) => {
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    if (img.width >= width && img.height >= height) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                };
                img.onerror = () => {
                    resolve(false);
                };
                img.src = e.target.result;
            };
            reader.readAsDataURL(file);
        } else {
            resolve(false);
        }
    });
};
