import React from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Box,
  VStack,
  useToast,
  Textarea,
  FormErrorMessage,
  Spinner
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { CustomButton } from '../../../../../components/Styled/StyledButtons';
import { useDrawer } from '../../../../../Context/DrawerContext/DrawerContext';
import photoService from '../../../../../services/photoService';
import fileService from '../../../../../services/fileService';

const AlbumAddForm = () => {
  const toast = useToast();
  const { closeDrawer } = useDrawer();

  const initialValues = {
    title: '',
    description: '',
    cover: null
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Album title is required'),
    description: Yup.string(),
    cover: Yup.mixed().nullable()
  });

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    try {
      let coverUrl = values.cover;
      if (values.cover) {
        const uploadResult = await fileService.uploadFile(values.cover, 'user123', 'albumCover');
        coverUrl = uploadResult.data.fileUrl; 
        console.log('COVER',coverUrl)
      }

      const albumData = {
        title: values.title,
        description: values.description,
        cover:coverUrl
      };

      await photoService.createAlbum(albumData);
      toast({
        title: 'Album Created',
        description: "The new album has been added successfully.",
        status: 'success',
        duration: 5000,
        isClosable: true
      });
      resetForm();
      closeDrawer();
    } catch (error) {
      toast({
        title: 'Failed to Create Album',
        description: error.message || "An error occurred while adding the album.",
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ isSubmitting, setFieldValue, errors, touched }) => (
        <Form>
          <VStack spacing={4} align="stretch">
            <FormControl isInvalid={errors.title && touched.title} isRequired>
              <FormLabel htmlFor='title'>Album Title</FormLabel>
              <Input id='title' name='title' placeholder='Enter album title' onChange={(e) => setFieldValue('title', e.target.value)} />
              <FormErrorMessage>{errors.title}</FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor='description'>Description</FormLabel>
              <Textarea id='description' name='description' placeholder='Enter album description' onChange={(e) => setFieldValue('description', e.target.value)} />
              <FormErrorMessage>{errors.description}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.cover && touched.cover}>
              <FormLabel htmlFor='cover'>Cover Image</FormLabel>
              <Input
                id='cover'
                name='cover'
                type='file'
                onChange={(event) => {
                  setFieldValue('cover', event.currentTarget.files[0]);
                }}
              />
              <FormErrorMessage>{errors.cover}</FormErrorMessage>
            </FormControl>
            {isSubmitting ? (
              <Spinner />
            ) : (
              <Box>
                <CustomButton type='submit' showIcon={false}  isLoading={isSubmitting} colorScheme='blue'>
                  Add Album
                </CustomButton>
              </Box>
            )}
          </VStack>
        </Form>
      )}
    </Formik>
  );
};

export default AlbumAddForm;
