import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import DefaultTemplate from '../../../../components/DefaultTemplate/DefaultTemplate'; // Adjust path as necessary
import PhotoContainer from './Components/PhotoContainer'; // Adjust path as necessary
import { Icon } from "@iconify/react";

const PhotoPage = () => {
    return (
        <DefaultTemplate
        Content={<PhotoContainer />}
        Title={<Heading as="h2" size="lg" width="full" my={4}><Icon icon="bx:album" /> Manage Photos</Heading>}
    />
    );
};

export default PhotoPage;
