import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.label`
    font-size: 0.75rem;
    font-weight: hairline;
    position: absolute;
    top: 20px;
    left: 0;
    background-color: ${(props) => {
        switch (props.formAction) {
            case 'add':
                return '#63B3ED'; // Blue for add
            case 'edit':
                return '#63B3ED'; // Orange for edit
            case 'view':
                return '#63B3ED'; // Green for view
            default:
                return '#63B3ED'; // Default to blue if no action is matched
        }
    }};
    color: white;
    padding: 2px 6px;
    border-radius: 0px 4px 4px 0px;
    margin-bottom: 4px;
    z-index: 999;
`;

const FormActionLabel = ({ formAction, formName }) => {
    let actionText = '';
    switch (formAction) {
        case 'add':
            actionText = 'Adding New';
            break;
        case 'edit':
            actionText = 'Editing';
            break;
        case 'view':
            actionText = 'Viewing';
            break;
        default:
            actionText = ''; // Or handle a default case as you see fit
    }

    return (
        <StyledLabel formAction={formAction}>{`${actionText} ${formName}`}</StyledLabel>
    );
};

export default FormActionLabel;
