import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Table, Thead, Tbody, Tr, Th, Td, Box, Badge} from '@chakra-ui/react';
import ConsultantEditForm from './ConsultantEditForm';
import ConsultantViewForm from './ConsultantViewForm';
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import { DeleteButton, EditButton, ViewButton } from "../../../../components/Styled/ActionButtons";
import { StyledTableContainer } from "../../../../components/Styled/StyledComponents";
import PaginationControls from "../../../../Context/PaginationControls/PaginationControls";
import { fetchConsultantById, fetchAllConsultants, deleteConsultant, fetchAllAreas } from "../ConsultantsAPI";
import ConfirmDialog from "../../../../Context/ConfirmDialog/ConfirmDialog";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";

const ConsultantTable = ({ consultants, setConsultants, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag, filterOptions }) => {
    const { openDrawer } = useDrawer();
    const [areas, setAreas] = useState([]);
    const [error, setError] = useState('');
    const showToast = useSingleToast();
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    const showToastRef = useRef(showToast);



    // Update function encapsulated in useCallback to ensure it's only created once
    const updateConsultantsAndPages = useCallback((newConsultants, totalDocs) => {
        setConsultants(newConsultants);
        setTotalPages(Math.ceil(totalDocs / 10));
    }, [setConsultants, setTotalPages]);

    const refetchConsultantData = useCallback(async (options = {}) => {
        setError('');
        try {
            const consultantsResponse = await fetchAllConsultants(pageNumber, 10, filterOptions);
            const areasResponse = await fetchAllAreas();
            
            if (consultantsResponse && consultantsResponse.totalPages) {
                updateConsultantsAndPages(consultantsResponse.consultants, consultantsResponse.totalDocs);
            } else {
                console.error("No totalPages found:", consultantsResponse);
                setConsultants([]);
                setTotalPages(0);
            }

            if (!consultantsResponse || !consultantsResponse.consultants || !areasResponse || !areasResponse.areas) {
                throw new Error('No data received from the server');
            }

            if (areasResponse && areasResponse.areas) {
                setAreas(areasResponse.areas);
            } else {
                console.error("Areas data missing:", areasResponse);
                setAreas([]);
            }

            updateConsultantsAndPages(consultantsResponse.consultants, consultantsResponse.totalDocs);
            setAreas(areasResponse.areas);
        } catch (error) {
            console.error("Error fetching data:", error);
            setError(error.message || 'Failed to fetch data. Please try again later.');
            showToastRef.current({
                title: "Error fetching data",
                description: error.message || "An unexpected error occurred.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    }, [filterOptions, pageNumber, setConsultants, setTotalPages, updateConsultantsAndPages]);

    useEffect(() => {
        refetchConsultantData();
    }, [refetchConsultantData, pageNumber, filterOptions, setRefreshFlag]);

    const getAreaNameByName = useCallback((name) => {
        const area = areas.find(mc => mc.name === name);
        return area ? area.name : "Unknown";
    }, [areas]);


    if (error) return <Box textAlign="center" my="4">{error}</Box>;
    if (dataLoaded && !consultants.length) return <Box textAlign="center" my="4">No records found.</Box>;


    const handleEdit = (consultantId) => {
        openDrawer({
            title: 'Edit Consultant',
            component: ConsultantEditForm,
            props: {
                consultantId: consultantId,
                refetchConsultantData: refetchConsultantData,
                setRefreshFlag: setRefreshFlag,
            },
            key: `edit-${consultantId}`,
        }, 'md');
    };


    const handleView = async (consultant) => {
        try {
            const consultantData = await fetchConsultantById(consultant._id);
            if (consultantData) {
                openDrawer({
                    title: 'View Consultant',
                    component: ConsultantViewForm,
                    props: { formData: consultantData },
                    key: `view-${consultant._id}`,
                }, 'md');
            } else {
                throw new Error('Consultant data not found.');
            }
        } catch (error) {
            setError(error.message);
            showToast({
                title: 'Error',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };


    const handleDelete = (consultantId) => {
        setConfirmDialog({
            isOpen: true,
            title: "Confirm Delete",
            message: "Are you sure you want to delete this consultant? This action cannot be undone.",
            onConfirm: () => confirmDelete(consultantId),
        });
    };

    const confirmDelete = async (consultantId) => {
        try {
            await deleteConsultant(consultantId);
            showToast({
                title: "Consultant Deleted",
                description: "Successfully deleted the consultant.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ ...confirmDialog, isOpen: false });
            refetchConsultantData(filterOptions); // Refresh the list
        } catch (error) {
            showToast({
                title: "Deletion Failed",
                description: "Could not delete the consultant. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ ...confirmDialog, isOpen: false });
        }
    };

    return (
        <>
            <StyledTableContainer>
                <PaginationControls pageNumber={pageNumber} setPageNumber={setPageNumber} totalPages={totalPages} />
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th>Consultant</Th>
                            <Th>Email</Th>
                            <Th>Area</Th>
                            <Th>Status</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {consultants.map(consultant => (
                            <Tr key={consultant._id}>
                                <Td>{consultant.firstName} {consultant.lastName}</Td>
                                <Td>{consultant.email}</Td>
                                <Td>{getAreaNameByName(consultant.area)}</Td>
                                <Td>
                                    <Badge colorScheme={consultant.status === 'active' ? 'green' : 'red'} ml={2}>
                                        {consultant.status.charAt(0).toUpperCase() + consultant.status.slice(1)}
                                    </Badge>
                                </Td>
                                <Td>
                                    <ViewButton onClick={() => handleView(consultant)} />
                                    <EditButton onClick={() => handleEdit(consultant._id)} />
                                    <DeleteButton onClick={() => handleDelete(consultant._id)} />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </StyledTableContainer>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                title={confirmDialog.title}
                message={confirmDialog.message}
                onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
                onConfirm={confirmDialog.onConfirm}
            />
        </>
    );
};

export default ConsultantTable;
