import React, { useEffect, useState, useCallback } from 'react';
import PollsTable from './PollsTable';
import PollTableTopBar from './PollTableTopBar';
import { fetchAllPolls } from '../SurveyAPI';

const PollsContainer = () => {
    const [polls, setPolls] = useState([]);
    const [error, setError] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const refetchPollsData = useCallback(async (filter={}, fetchAll = false) => {
        setDataLoaded(false);
        try {
            const data = await fetchAllPolls(pageNumber, 10, filter, fetchAll);
            setPolls(data.polls);
            setTotalPages(data.totalPages);
            setError('');
            setDataLoaded(true);
        } catch (error) {
            console.error('Error fetching all polls:', error.message);
            setError('Failed to fetch polls data.');
            setPolls([]);
            setTotalPages(0);
        }
    }, [pageNumber]);

    useEffect(() => {
        refetchPollsData();
    }, [pageNumber, refreshFlag, refetchPollsData]);

    return (
        <>
            <PollTableTopBar refetchPollsData={refetchPollsData} />
            <PollsTable
                polls={polls}
                setPolls={setPolls}
                setRefreshFlag={setRefreshFlag}
                refetchPollsData={refetchPollsData}
                error={error}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                dataLoaded={dataLoaded}
            />
        </>
    );
};

export default PollsContainer;
