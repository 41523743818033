import React from 'react';
import { VStack, SimpleGrid, Text, Badge, Box, IconButton, Tooltip, Icon } from '@chakra-ui/react';
import DetailSection from '../../components/DetailSection/DetailSection';
import CustomAlert from '../../components/Styled/StyledAlert';
import PollPieChart from '../../pages/SurveyManagemet/Components/PollPieChart';
import { ButtonStack, CustomButton } from '../../components/Styled/StyledButtons';
import FormActionLabel from '../../components/Styled/FormActionLabel';

const PollInformation = ({ formData, handlePrintDebug, closeDrawer, standalone = true }) => {
  return (
    <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
      {standalone && <FormActionLabel formAction="view" formName="Poll Information" />}
      <SimpleGrid columns={{ base: 1, sm: 2 }} gap={4} px={2} py={4} mt={4}>
        <DetailSection title="Poll Information" icon="mingcute:user-info-line">
          <Text>
            <strong>Question:</strong> {formData.question}
          </Text>
          <Text>
            <strong>Poll Number:</strong> {formData._id}
          </Text>
          <Text>
            <strong>Options:</strong>{' '}
            {formData.options.length === 0 ? (
              <Badge ml="1" colorScheme="red">
                None
              </Badge>
            ) : (
              formData.options.map((item, index) => (
                <Badge key={index} ml="1" colorScheme="green">
                  {item}
                </Badge>
              ))
            )}
          </Text>
          <Text>
            <strong>Votes:</strong>{' '}
            {formData.votes.length === 0 ? (
              <Badge ml="1" colorScheme="red">
                No Votes
              </Badge>
            ) : (
              formData.votes.map((item, index) => (
                <Badge key={index} ml="1" colorScheme="green">
                  {/^[0-9]+$/.test(item) ? item : 0}
                </Badge>
              ))
            )}
          </Text>
          <Box>
            <Text>
              <strong>Status:</strong>{' '}
              {new Date() > new Date(formData.closing_date) ? (
                <Badge ml="1" colorScheme="red">
                  Expired
                </Badge>
              ) : (
                <Badge ml="1" colorScheme="green">
                  Active
                </Badge>
              )}
            </Text>
          </Box>
        </DetailSection>
        <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }} mt={8} mb={8}>
          <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
            <CustomAlert status="warning" message="Poll Responses from respondents" />
            <PollPieChart pollData={{ options: formData.options, votes: formData.votes }} />
          </ButtonStack>
        </Box>
        {standalone &&
          <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }} mt={8} mb={8}>
            <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
              <CustomAlert status="warning" message="--End of Information--" />
              <CustomButton type="cancel" onClick={closeDrawer} showIcon={false}>
                Close
              </CustomButton>
              <Tooltip label="Print Poll Details">
                <IconButton
                  aria-label="Print"
                  icon={<Icon icon="material-symbols-light:print-outline" />}
                  onClick={handlePrintDebug}
                />
              </Tooltip>
            </ButtonStack>
          </Box>
        }
      </SimpleGrid>
    </VStack>
  );
};

export default PollInformation;
