import React from 'react'
import DefaultTemplate from '../../../components/DefaultTemplate/DefaultTemplate'
import LeakReportContainer from './Components/LeakReportContainer'
import {Icon} from "@iconify/react";

const LeakReport = () => {
    return (
        <>
            <DefaultTemplate Content={ <LeakReportContainer/> } Title={"Leak Reports" } icon={<Icon icon="game-icons:leak" />}/>
        </>
    )
}

export default LeakReport
