import axios from "axios";
import { API1, API2 } from "./api";
import DOMPurify from 'dompurify';
import moment from "moment";
import enums from "wuc-library/enums";

// Base URL for API requests
const API_BASE_URL = (process.env.REACT_APP_API_BASE_URL ?? '//localhost');

const api = axios.create({
  baseURL: API_BASE_URL,
});

const sanitizeHtml = (html) => {
  const cleanHtml = DOMPurify.sanitize(html, {
    ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'ol', 'li', 'p', 'strong', 'em', 'br', 'a'],
    ALLOWED_ATTR: ['href', 'title', 'style'],
  });
  return cleanHtml;
};


const utils = {
  isExpired: (time) => {
    // Check if the token has expired
    const tokenExpiration = time * 1000; // Convert expiration time to milliseconds
    const currentTimestamp = Date.now();
    return currentTimestamp < tokenExpiration;
  },
  decodeJWT: (authToken) => {

    if (authToken) {
      // Decode the JWT token
      const tokenParts = authToken.split(".");
      const encodedPayload = tokenParts[1];
      const decodedPayload = JSON.parse(atob(encodedPayload));
      return decodedPayload;
    }
    return null;
  },
  getToken: (token) => {
    return utils.decodeJWT(token)
  },
  isAuthenticated: (tok) => {
    const authToken = localStorage.getItem("authToken");
    const jwt = utils.decodeJWT(authToken);
    if (jwt) {
      // Check if the token has expired
      const tokenExpiration = jwt.exp * 1000; // Convert expiration time to milliseconds
      const currentTimestamp = Date.now();
      if (currentTimestamp < tokenExpiration) {
        // Token valid
        return true;
      }
    }
    return false;
  },
  getIsVerified: (authToken) => {
    if (authToken) {
      // Decode the JWT token
      const tokenParts = authToken.split(".");
      const encodedPayload = tokenParts[1];
      const jwt = JSON.parse(atob(encodedPayload));

      // Check if the token has expired
      if (jwt) {
        return jwt.isVerified;
      }
    }
    return false;
  },
  getRole: (authToken) => {
    if (authToken) {
      // Decode the JWT token
      const tokenParts = authToken.split(".");
      const encodedPayload = tokenParts[1];
      const jwt = JSON.parse(atob(encodedPayload));

      // Check if the token has expired
      if (jwt) {
        return jwt.role;
      }
    }
    return false;
  },
  getEmail: (authToken) => {
    if (authToken) {
      // Decode the JWT token
      const tokenParts = authToken.split(".");
      const encodedPayload = tokenParts[1];
      const jwt = JSON.parse(atob(encodedPayload));

      // Check if the token has expired
      if (jwt) {
        return jwt.email;
      }
    }
    return false;
  },
  getPermissions: (authToken) => {
    if (authToken) {
      // Decode the JWT token
      const tokenParts = authToken.split(".");
      const encodedPayload = tokenParts[1];
      const jwt = JSON.parse(atob(encodedPayload));

      // Check if the token has expired
      if (jwt) {
        return jwt.permissions;
      }
    }
    return [];
  },
  getIsFirstTimeLogin: (authToken) => {
    if (authToken) {
      // Decode the JWT token
      const tokenParts = authToken.split(".");
      const encodedPayload = tokenParts[1];
      const jwt = JSON.parse(atob(encodedPayload));

      // Check if the token has expired
      if (jwt) {
        return jwt.isFirstTimeLogin;
      }
    }
    return false;
  },
  getRequirePasswordChange: (authToken) => {
    if (authToken) {
      // Decode the JWT token
      const tokenParts = authToken.split(".");
      const encodedPayload = tokenParts[1];
      const jwt = JSON.parse(atob(encodedPayload));

      // Check if the token has expired
      if (jwt) {
        return jwt.requirePasswordChange;
      }
    }
    return false;
  },
  setToken: (name, token) => {
    localStorage.setItem(name, token);
  },
  getUserId: (token) => {
    let jwt = utils.decodeJWT(token);
    if (jwt) {
      return jwt.id;
    }
    return null;
  },
  getUserEmail: (token) => {
    let jwt = utils.decodeJWT(token);
    if (jwt) {
      return jwt.email;
    }
    return null;
  },
  getUser: () => {
    let jwt = utils.decodeJWT();
    if (jwt) {
      return { name: jwt.name, email: jwt.email };
    }
    return null;
  },
  logout: (navigate, showToast, showsToast = false) => {
    localStorage.clear();
    sessionStorage.clear();

    if (showsToast) {
      showToast({
        title: "Logged Out",
        description: "You have been logged out successfully.",
        status: "info",
        duration: 2000,
        isClosable: true,
      });
    }
    navigate('/login');
  },
  forgotPassword: async (email) => {
    try {
      const response = await API1.put(
          `/auth/password/reset`,
          {userRole: enums.UserRole.ADMIN, email}
      );
      return response
    } catch (error) {
      console.error('Error resetting  password', error);
      utils.sendMessageToBugNinja('ERROR SETTING NEW PASSWORD', error);
    }
  },
  validatePassword: (password) => {
    const result = { msg: "", digit: true, uppercase: true, lowercase: true, isLength: true, isValid: true };
    if (password.length < 8) {
      result.isLength = false;
      result.isValid = false;
      result.msg = "Password does not meet minimum requirements";
    }

    if (!/[A-Z]/.test(password)) {
      result.uppercase = false;
      result.isValid = false;
      result.msg = "Password does not meet minimum requirements";
    }

    if (!/[a-z]/.test(password)) {
      result.lowercase = false;
      result.isValid = false;
      result.msg = "Password does not meet minimum requirements";
    }
    if (!/\d/.test(password)) {
      result.digit = false;
      result.isValid = false;
      result.msg = "Password does not meet minimum requirements";
    }

    return result;
  },// Adjusting API calls for versioning
  signupUser: async (userData) => {
    try {
      return await API1.post('/auth/signup', userData);
    } catch (error) {
      //console.error('Error during signup:', error.response ? error.response.data : error);
      throw error;
    }
  },
  login: async (email, password) => {
    try {
      return await API1.post('/auth/signin', { email, password });
    } catch (error) {
      console.error('Error during login:', error?.message ? error.message : error);
      utils.sendMessageToBugNinja("Failed login", error.message, error)
      throw error;
    }
  },
  requestNewOTP: async (email, usage = 'REGISTRATION') => {
    try {
      return await API1.post('/auth/otp/change', { email, usage });
    } catch (error) {
      utils.sendMessageToBugNinja("Failed request new otp", error.message, error)
      throw error;
    }
  },
  validateOTP: async (email, otpCode) => {
    try {
      return await API1.post('/auth/otp/validate', { email, otpCode });
    } catch (error) {
      utils.sendMessageToBugNinja("Failed validate otp", error.message, error)
      throw error;
    }
  },
  setNewPassword: async (userId, newPassword) => {
    try {
      return await API1.put('/auth/password/set', { userId, newPassword });
    } catch (error) {
      utils.sendMessageToBugNinja("Failed to set new passowrd", error.message, error)
      throw error;
    }
  },
  changePassword: async (userId, oldPassword, newPassword) => {
    try {
      return await API1.put('/auth/password/change', { userId, oldPassword, newPassword });
    } catch (error) {
      utils.sendMessageToBugNinja("Failed change password", error.message, error)
      throw error;
    }
  },
  resetPassword: async (email, userRole) => {
    try {
      return await API1.put('/auth/password/reset', { email, userRole });
    } catch (error) {
      utils.sendMessageToBugNinja("Failed upload file", error.message, error)
      throw error;
    }
  },
  getUserByResetPasswordToken: async (resetPasswordToken) => {
    try {
      const data = {
        filterOptions: { "resetPasswordToken" : resetPasswordToken },
        pageNumber: 1,
        pageSize: 3,
      };
      const response = await API1.post('/auth/users/', data);
      console.log("response data", response.data)
      if (response.data && response.data.data && response.data.data.docs.length > 0) {
        return response.data.data.docs[0];
      } else {
        throw new Error('User not found for the provided token.');
      }
    } catch (error) {
      const errorMsg = error?.response && error?.response.data && error?.response.data.message
        ? error?.message
        : 'Failed to fetch user by reset token.';
      utils.sendMessageToBugNinja("Failed get Reset password token", error.message, error)
      throw new Error(errorMsg);
    }
  },
  handleFileUpload: async (file, folderPath) => {
    let formData = new FormData();
    formData.append('folderPath', folderPath);
    formData.append('file', file);

    try {
      const response = await API1.post('/common/file/upload', formData);
      //const response = await axios.post('http://4.234.179.204:3005/api/v1/common/file/upload', formData);
      return response.data;
    } catch (e) {
      utils.sendMessageToBugNinja("Failed upload file", e.message, e)
      throw e;
    }
  },

sanitizeHtml: (html) => {
  return {
    __html: DOMPurify.sanitize(html)
  };
},
  formatDate: (date) => {
    //console.log("Input date:", date);
    const parsedDate = moment(date, "YYYY-MM-DD");
    if (!parsedDate.isValid()) {
      return '';
    }
    return parsedDate.format("YYYY-MM-DD");
  },
  getOriginalFileName: (path) => {
    try {

      if(typeof path === 'string'){
      const parts = path?.split('/');
      const fileNameWithParams = parts[parts.length - 1];
      return fileNameWithParams?.split('?')[0];}

    } catch (e) {
      utils.sendMessageToBugNinja("Failed original filename", e.message, e)
    }
  },
  truncateText: (text, limit = 30) => {
    if (text.length <= limit) {
      return text;
    }
    return text.substring(0, limit - 3) + '...';
  },
  capitalizeFirstLetter: (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  },
  getFileExtensionFromUrl: (url) => {
    const lastSegment = url.split('/').pop();
    return lastSegment.split('.').pop();
  },
  getFileTypeIcon: (extension) => {
    const icons = {
      pdf: 'vscode-icons:file-type-pdf2',
      docx: 'vscode-icons:file-type-word',
      csv: 'vscode-icons:file-type-excel',
      xlsx: 'vscode-icons:file-type-excel',
      doc: 'vscode-icons:file-type-word',
      png: 'vscode-icons:file-type-genstat',
      jpg: 'vscode-icons:file-type-genstat',
      jpeg: 'vscode-icons:file-type-genstat',
    };
    return icons[extension] || 'mdi-file-question';
  },
  formatFileSize: (size) => {
    if (size < 1024) return size + ' bytes';
    let sizeInKb = size / 1024;
    if (sizeInKb < 1024) return sizeInKb.toFixed(2) + ' KB';
    let sizeInMb = sizeInKb / 1024;
    return sizeInMb.toFixed(2) + ' MB';
  },
  DateFormat: (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  },
  sendMessageToBugNinja: async (title, message, error) => {

    // Send error message to Bug Ninja Discord channel if the environment is not local
    if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== 'LOCAL') {

      let full_error = JSON.stringify(error);

      //  check if the full error message is too long
      if (full_error?.length > 1400) {
        full_error = full_error.substring(0, 1400);
      }


      // const webhookUrl = process.env.WEBHOOK_URL;
      const webhookUrl = "https://discordapp.com/api/webhooks/1252254219889676298/tuVZCGbj3xyLuPvt0frtnm1RrSlz_K7kyqyekpyFki5QeZDLPDGrt1t5FJsb3QEJd0T_";
      try {
        const response = await axios.post(webhookUrl,
          {
            username: "Admin Portal Error Alert",
            avatar_url: "https://imgur.com/gr9UQGz",
            embeds: [
              {
                author: {
                  name: "Bug Ninja 🐞",
                  url: "https://imgur.com/gr9UQGz",
                  icon_url: "https://imgur.com/gr9UQGz"
                },
                title: `TITLE: ${title}`,
                description: `MESSAGE: ${message.substring(0, 500)}`,
                color: 50000,
                fields: [
                  {
                    name: "Environment",
                    value: `Environment: ${process.env.REACT_APP_ENV}`,
                    inline: true
                  },
                  {
                    name: "Error",
                    value: `${full_error}`,
                    inline: false
                  }
                ]
              }
            ]
          },
          {
            'Content-Type': 'application/json',
          },)
        if (response.statusCode === 204) {
          console.log('Bug Ninja Message sent successfully');
        }
      } catch (error) {
        // utils.sendMessageToBugNinja('ERROR SENDING MESSAGE TO BUG NINJA', error.message, error);
        console.log("Error sending message to bug ninja", error.message)
        console.log(error)
      }
      
    }
  },
  
};


export default utils;
