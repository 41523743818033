import React, { useRef, useCallback, useState, useEffect } from 'react';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    useToast,
    Box,
    Switch,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Button,
    Select,
    Flex
} from '@chakra-ui/react';
import { CustomButton, ButtonStack } from "../../../components/Styled/StyledButtons";
import CustomAlert from "../../../components/Styled/StyledAlert";
import pageService from '../../../services/pageService';
import fileService from '../../../services/fileService';
import { useDrawer } from '../../../Context/DrawerContext/DrawerContext';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDisclosure } from '@chakra-ui/react';
import { ThemedStyledInput } from "../../../components/Styled";
import DropBoxList from "./DropboxList";
import { StyledTooltip } from "../../../components/Styled/StyledComponents";

const categories = {
    RESIDENTIAL: 'Residential',
    COMMERCIAL: 'Commercial',
    SERVICES: 'Services',
    YOUR_WATER_ACCOUNT: 'Your Water Account',
};

const modules = {
    toolbar: {
        container: [
            [{ 'font': [] }, { 'size': [] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'script': 'super' }, { 'script': 'sub' }],
            [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['direction', { 'align': [] }],
            ['link', 'image', 'video', 'formula'],
            ['clean']
        ],
    },
    clipboard: {
        matchVisual: false,
    }
};

const formats = [
    'header', 'font',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet',
    'link', 'image', 'video'
];

const PageAddForm = ({ refetchPages, pages }) => {
    const { closeDrawer } = useDrawer();
    const quillRef = useRef(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [category, setCategory] = useState(null);
    const [isForm, setIsForm] = useState(false);
    const toast = useToast({ position: 'top-right' });

    const imageHandler = useCallback(() => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            if (file) {
                try {
                    const response = await fileService.uploadFile(file, new Date().getTime().toString(), "pageImages");
                    const quill = quillRef.current.getEditor();
                    const range = quill.getSelection(true);
                    quill.insertEmbed(range.index, 'image', response.data.fileUrl, Quill.sources.USER);
                    quill.setSelection(range.index + 1, Quill.sources.SILENT);
                } catch (error) {
                    console.error('Error uploading image: ', error);
                    toast({
                        title: 'Error uploading image',
                        description: error.message || 'Failed to upload image.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                }
            }
        };
    }, [toast]);

    useEffect(() => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();
            quill.getModule('toolbar').addHandler('image', imageHandler);
        }
    }, [imageHandler]);

    const pageSchema = Yup.object({
        pageName: Yup.string().required('Page name is required.'),
        isForm: Yup.boolean().nullable(),
        description: Yup.string().when('isForm', (isForm, schema) => {
            return isForm === false ? schema.required('Description is required.') : schema.notRequired();
        }),
        category: Yup.string().required('Category is required.'),
        windowTitle: Yup.string().nullable(),
        parentPage: Yup.string().nullable(),
        url: Yup.array().of(Yup.object().shape({
            title: Yup.string().nullable(),
            link: Yup.string().nullable(),
        })).required("requied"),
        isQuicklink: Yup.boolean().nullable()
    });


    const handleParentPageChange = async (pageId, setFieldValue) => {
        setFieldValue('parentPage', pageId);
        try {
            if (pageId) {
                const response = await pageService.getPageCategory(pageId);
                if (response) {
                    setCategory(response);
                    setFieldValue('category', response);
                } else {
                    setCategory(null);
                }
            } else {
                setCategory("");
                setFieldValue('category', "");
            }
        } catch (error) {
            console.error(error);
            setCategory(null);
        }
    };

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            if (values.isForm && (values.url.length && !values.url[values.url.length - 1]?.link)) {
                values.description = ""
                toast({
                    title: 'Upload document on the last input field ',
                    status: 'warning',
                    duration: 5000,
                    isClosable: true,
                });
                return
            }
            if (values.parentPage && !values.isForm && !values.description) {
                values.url = []
                toast({
                    title: 'Page content is required',
                    status: 'warning',
                    duration: 5000,
                    isClosable: true,
                });
                return
            }
            const data = {
                ...values,
                initialRevision: {
                    description: values.description,
                    active: true,
                    windowTitle: values.windowTitle,
                    isForm: values.isForm,
                    url: values?.url || []
                }
            };
            await pageService.createPage(data);
            toast({
                title: 'Page added successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchPages();
            closeDrawer();
        } catch (error) {
            toast({
                title: 'Error adding page',
                description: error.message || 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                pageName: '',
                description: '',
                category: '',
                windowTitle: '',
                parentPage: '',
                url: [],
                isForm: false,
                isQuicklink: false
            }}
            validationSchema={pageSchema}
            onSubmit={handleSubmit}
        >
            {formikProps => (
                <Form>
                    <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                        <CustomAlert status="warning" message="Make sure all fields are correctly filled before submitting." />
                        <Box>
                            <GridItem>
                                <FormControl isInvalid={formikProps.errors.parentPage && formikProps.touched.parentPage}>
                                    <FormLabel htmlFor="parentPage">Parent Page</FormLabel>
                                    <Select {...formikProps.getFieldProps('parentPage')} onChange={(e) => handleParentPageChange(e.target.value, formikProps.setFieldValue)} id="parentPage">
                                        <option value="">No Parent Page</option>
                                        {pages
                                            .filter(page => !page.isForm)
                                            .map(page => (
                                                <option key={page._id} value={page._id}>{page.pageName}</option>
                                            ))}
                                    </Select>
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl isRequired isInvalid={formikProps.errors.category && formikProps.touched.category}>
                                    <FormLabel htmlFor="category">Category</FormLabel>
                                    {category ?
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('category')}
                                            id="category"
                                            disabled
                                            placeholder="Enter the category"
                                        /> :
                                        <Select {...formikProps.getFieldProps('category')} id="category">
                                            <option value="">Select Category</option>
                                            {Object.values(categories)?.map((category) => (
                                                <option key={category} value={category}>{category}</option>
                                            ))}
                                        </Select>
                                    }
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl isRequired isInvalid={formikProps.errors.pageName && formikProps.touched.pageName}>
                                    <FormLabel htmlFor="pageName">Page Name</FormLabel>
                                    <ThemedStyledInput
                                        {...formikProps.getFieldProps('pageName')}
                                        id="pageName"
                                        placeholder="Enter the page name"
                                    />
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl isRequired isInvalid={formikProps.errors.windowTitle && formikProps.touched.windowTitle}>
                                    <FormLabel htmlFor="windowTitle">Window Title</FormLabel>
                                    <ThemedStyledInput
                                        {...formikProps.getFieldProps('windowTitle')}
                                        id="windowTitle"
                                        placeholder="Enter the window title"
                                    />
                                </FormControl>
                            </GridItem>
                            {formikProps.values.parentPage &&
                                <>
                                    <StyledTooltip label={'Select to add a document'}>
                                        <GridItem justify={'end'}>
                                            <Flex mb={-2} mt={4}>
                                                <FormControl display="flex" alignItems="center" justifyContent={'end'}>
                                                    <FormLabel htmlFor="isFormPage" mb="0" fontWeight={'normal'}>Document link</FormLabel>
                                                    <Switch
                                                        id="isFormPage"
                                                        isChecked={formikProps.values.isForm}
                                                        onChange={(e) => formikProps.setFieldValue('isForm', e.target.checked)}
                                                    />
                                                </FormControl>
                                            </Flex>
                                        </GridItem>
                                    </StyledTooltip>
                                    {!formikProps.values.isForm ?
                                        <GridItem>
                                            <FormControl isRequired isInvalid={formikProps.errors.description && formikProps.touched.description}>
                                                <FormLabel htmlFor="description">Content</FormLabel>
                                                <ReactQuill
                                                    theme="snow"
                                                    ref={quillRef}
                                                    value={formikProps.values.description}
                                                    modules={modules}
                                                    onChange={(content) => formikProps.setFieldValue('description', content)}
                                                />
                                            </FormControl>
                                        </GridItem> :
                                        <DropBoxList formikProps={formikProps} />
                                    }
                                </>
                            }
                        </Box>

                        {/* isQuickLink toggle */}
                        {formikProps.values.parentPage &&
                            <FormControl display='flex' alignItems='center'>
                                <FormLabel htmlFor='isQuicklink' mb='0'>
                                    Show in Quick Links?
                                </FormLabel>
                                <Switch
                                    id='isQuicklink'
                                    name="isQuicklink"
                                    onChange={(e) => {
                                        formikProps.setFieldValue('isQuicklink', e.target.checked);
                                    }}
                                />
                            </FormControl>
                        }
                        {/* End of isQuickLink toggle */}

                        <ButtonStack>
                            {!formikProps.values.isForm &&
                                <CustomButton type="button" showIcon={false} onClick={onOpen}>Preview</CustomButton>

                            }                             <CustomButton type="submit" showIcon={false} isLoading={formikProps.isSubmitting}>Submit</CustomButton>
                        </ButtonStack>
                    </VStack>
                    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Preview Page</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <div className="ql-editor">
                                    <Text mb={4} dangerouslySetInnerHTML={{ __html: formikProps.values.description }} />
                                </div>
                            </ModalBody>
                            <Button mr={3} onClick={onClose}>
                                Close
                            </Button>
                        </ModalContent>
                    </Modal>
                </Form>
            )}
        </Formik>
    );
};

export default PageAddForm;
