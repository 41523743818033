import React from 'react'
import DefaultTemplate from '../../../../components/DefaultTemplate/DefaultTemplate'
import {Icon} from "@iconify/react";
import PublicationCategoryContainer from "./Components/PublicationCategoryContainer";

const PublicationCategory = () => {
    return (
        <>
            <DefaultTemplate Content={ <PublicationCategoryContainer/> } Title={"Publication Categories" } icon={<Icon icon="icomoon-free:folder-open" />}/>
        </>
    )
}

export default PublicationCategory
