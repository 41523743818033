import {API1} from "../../../utils/api";

// Create City
export const addConnection = async (connection) => {
    try {
        const response = await API1.post('/connection/request/submit', connection);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "An unexpected error occurred while creating new connection request.");
    }
};

export const fetchAllConnections = async (pageNumber = 1, pageSize = 10, filterOptions = {}) => {
    try {
        const queryParams = new URLSearchParams({
            ...filterOptions,
            pageNumber,
            pageSize
        }).toString();

        const response = await API1.get(`/connection/find/individual-connection-requests?${queryParams}`);

        if (response.status === 200) {
            const { docs: connections, totalDocs } = response.data.data;
            return {
                connections,
                totalPages: Math.ceil(totalDocs / pageSize),
            };
        } else {
            throw new Error('Unexpected response from the server while fetching connections.');
        }
    } catch (error) {
        console.error("Error fetching connections:", error);
        throw new Error('Failed to fetch connections. Please try again.');
    }
};

// Update Connection
export const updateConnection = async (connectionId, connection) => {
    try {
        const response = await API1.put(`/connection/request/update`, {id: connectionId, ...connection});
        return response.data;
    } catch (error) {
        console.log(error)
        throw new Error(error?.message || "An error occurred while updating connection request.");
    }
};

// Delete City
export const deleteConnection = async (connectionId) => {
    try {
        const response = await API1.delete(`/connection/request/delete`,{params:{id:connectionId}});
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "Failed to delete connection Request");
    }
};


