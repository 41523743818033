import React, {useCallback} from 'react';
import {Flex, GridItem} from '@chakra-ui/react';
import VideoAddForm from './VideoAddForm';
import { StyledGrid } from "../../../../components/Styled/StyledComponents";
import { ButtonStack, CustomButton } from "../../../../components/Styled/StyledButtons";
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import {Icon} from "@iconify/react";
import VideoSearchFilter from "./VideoSearchFilter";

const VideoTableTopBar = ({ refetchVideoData, setFilterOptions, setFetchTrigger }) => {
  const { openDrawer } = useDrawer();

  const handleFilterChange = useCallback(async (newFilterOptions) => {
    //setPageNumber(1);
    await new Promise(resolve => setTimeout(resolve, 0));
    setFilterOptions(newFilterOptions);
    setFetchTrigger(f => !f);
  }, [setFilterOptions, setFetchTrigger]);

  return (
      <>
        <StyledGrid templateColumns="repeat(12, 1fr)">
          <GridItem colSpan={{ base: 10, md: 11 }}>
            <VideoSearchFilter onFilterChange={handleFilterChange} />
          </GridItem>
          <GridItem colSpan={{ base: 12, md: 1 }}>
            <ButtonStack direction="row">
              <CustomButton
                  type="addNew"
                  showIcon={true}
                  showTooltip={true}
                  tooltipLabel="Add New Video"
                  icon={<Icon icon="healthicons:video-outline" />}
                  onClick={() => openDrawer({
                    title: 'Add New Video',
                    component: VideoAddForm,
                    props: { refetchVideoData }
                  }, 'md')}
              >
                Add New
              </CustomButton>
            </ButtonStack>
          </GridItem>
        </StyledGrid>
        <Flex bg="white" mb={4}></Flex>
      </>
  );
};

export default VideoTableTopBar;
