import { API1 } from "../../utils/api";

// #################################
//          BANNERS API FUNCTIONS
// #################################

// Fetch All LabServiceProducts
export const fetchAllBanners = async (pageNumber = 1, pageSize = 10, filterOptions = {}) => {
    try {
        const queryParams = new URLSearchParams({
            ...filterOptions,
            pageNumber,
            pageSize
        }).toString();

        const response = await API1.get(`/banners?${queryParams}`);
        if (response.status === 200) {
            return response.data.data;
        } else {
            throw new Error('Unexpected response from the server while fetching banners.');
        }
    } catch (error) {
        throw new Error('Failed to fetch banners. Please try again.');
    }
};

// addBanner API
export const addBanner = async (bannerData) => {
    try {
        const response = await API1.post(`/banners`, bannerData);
        if (response.status === 201) {
            return response.data;
        } else {
            throw new Error('Unexpected response from the server while adding banner.');
        }
    } catch (error) {
        throw new Error('Failed to add banner. Please try again.');
    }
};

// fetch banners by id
export const fetchBannerById = async (bannerId) => {
    try {
        const response = await API1.get(`/banners/${bannerId}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Unexpected response from the server while fetching banner.');
        }
    } catch (error) {
        throw new Error('Failed to fetch banner. Please try again.');
    }
};

// update banner API
export const updateBanner = async (bannerId, bannerData) => {
    try {

        const response = await API1.put(`/banners/${bannerId}`, bannerData);

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Unexpected response from the server while updating banner.');
        }
    } catch (error) {
        throw new Error('Failed to update banner. Please try again.');
    }
};

// delete banner by ID API
export const deleteBanner = async (bannerId) => {
    try {
        const response = await API1.delete(`/banners/${bannerId}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Unexpected response from the server while deleting banner.');
        }
    } catch (error) {
        throw new Error('Failed to delete banner. Please try again.');
    }
};
// #################################
//          BANNERS COMMON FUNCTIONS
// #################################

export const checkImageDimensions = async (file, width, height) => {
    return new Promise((resolve, reject) => {
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    if (width === img.width && height === img.height) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                };
                img.onerror = () => {
                    reject(new Error('Failed to load image'));
                };
                img.src = e.target.result;
            };
            reader.onerror = () => {
                reject(new Error('Failed to read file'));
            };
            reader.readAsDataURL(file);
        } else {
            resolve(false);
        }
    });
};
