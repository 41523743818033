import React, {useCallback} from 'react';
import { Flex, GridItem } from '@chakra-ui/react';
import AdminAddForm from './AdminAddForm';
import { StyledGrid } from "../../../../components/Styled/StyledComponents";
import { ButtonStack, CustomButton } from "../../../../components/Styled/StyledButtons";
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import { Icon } from "@iconify/react";
import AdminSearchFilter from "./AdminSearchFilter";

const AdminTableTopBar = ({ refetchAdminData, setPageNumber, setFilterOptions, setFetchTrigger }) => {
    const { openDrawer } = useDrawer();

    const handleFilterChange = useCallback(async (newFilterOptions) => {
        setPageNumber(1);
        await new Promise(resolve => setTimeout(resolve, 0));
        setFilterOptions(newFilterOptions);
        setFetchTrigger(f => !f);
    }, [setPageNumber, setFilterOptions, setFetchTrigger]);

    return (
        <>
            <StyledGrid templateColumns="repeat(12, 1fr)" alignItems="center">
                <GridItem colSpan={{ base: 10, md: 11 }}>
                    <AdminSearchFilter onFilterChange={handleFilterChange} />
                </GridItem>
                <GridItem colSpan={{ base: 2, md: 1 }}>
                    <ButtonStack direction="row" justifyContent="flex-end">
                        <CustomButton
                            type="addNew"
                            showIcon={true}
                            showTooltip={true}
                            tooltipLabel="Add New Admin"
                            icon={<Icon icon="eos-icons:admin-outlined" />}
                            onClick={() => openDrawer({
                                title: 'Add New Admin',
                                component: AdminAddForm,
                                props: { refetchAdminData }
                            }, 'xxl')}
                        >
                            Add New
                        </CustomButton>
                    </ButtonStack>
                </GridItem>
            </StyledGrid>
            <Flex bg="white" mb={4}></Flex>
        </>
    );
};

export default AdminTableTopBar;
