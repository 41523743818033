import React, { useState } from 'react';
import {
    FormControl,
    FormLabel,
    VStack,
    useToast,
    Box,
    SimpleGrid, Spinner,
    Grid, GridItem,
    Flex, Image, Input, InputGroup,
} from '@chakra-ui/react';
import DropBox from "../../../components/DropBox/DropBox";
import FormActionLabel from "../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../components/Styled/StyledButtons";
import FieldControl from '../../../components/Styled/FieldControl';
import { useDrawer } from '../../../Context/DrawerContext/DrawerContext';
import { Formik, Form } from 'formik';
import ConfirmDialog from "../../../Context/ConfirmDialog/ConfirmDialog";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-datepicker/dist/react-datepicker.css'; // Import styles
import { addTender } from "../tenderAPI";
import SectionHeading from "../../../components/SectionHeading/SectionHeading";
import utils from "../../../utils/commonFunctions";
import { IconButton, Center, useDisclosure } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import pdfFileImage from '../../../assests/images/pdfFile.png';
import PdfViewerModal from "../../../components/PdfViewer/PdfViewerModal";

const TenderAddForm = ({ refetchTendersData, totalTenders }) => {
    const { closeDrawer } = useDrawer();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [descriptionValue, setDescriptionValue] = useState('');
    const toast = useToast({ position: 'top-right' });
    const [advertDocumentKey, setAdvertDocumentKey] = useState('');
    const [tenderDocumentKey, setTenderDocumentKey] = useState('');
    const [otherDocumentsKeys, setOtherDocumentsKeys] = useState([]);

    const [loading, setLoading] = useState(false);
    const [tenderDocLoading, setTenderDocLoading] = useState(false);
    const [otherDocsLoading, setOtherDocsLoading] = useState(false);
    const [filePath, setFilePath] = useState();

    var [tenderSchema, setTenderSchema] = useState({
        title: '',
        number: '',
        fees: 0,
        description: '',
        advertDocumentKey: {},
        tenderDocumentKey: {},
        otherDocumentsKeys: [],
        startDate: '',
        closingDate: '',
        isClosed: false
    });

    const handlePdfClick = (pdf) => {
        if (pdf){
            setFilePath(pdf)
            onOpen()
        }
    };

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => { },
    });

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {

        // validations for title
        if (values.title === null || values.title === '') {
            toast({
                title: 'Tender title is required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        // validations for tender fees
        if (values.fees === null || values.fees === '' || values.fees === 0) {
            toast({
                title: 'Tender fees is required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        // validations for tender value to be a number
        if (isNaN(values.fees) || values.fees === 0) {
            toast({
                title: 'Tender fees must be a number',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        // validations for descriptions
        if (values.description === null || values.description === '') {
            toast({
                title: 'Tender description is required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        // validations for advertDocumentKey
        if (advertDocumentKey === null || advertDocumentKey === '') {
            toast({
                title: 'Tender advert document is required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        // validations for startdate
        if (values.startDate === null || values.startDate === '') {

            toast({
                title: 'Start date is required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        if (values.closingDate === null || values.closingDate === '') {

            toast({
                title: 'Closing date is required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        try {
            await addTender(JSON.stringify({
                "title": values.title,
                "fees": values.fees?.toFixed(2),
                "number": 'WUC-TDR-00' + (totalTenders + 1),
                "description": descriptionValue,
                "advertDocumentKey": advertDocumentKey,
                "tenderDocumentKey": tenderDocumentKey,
                "otherDocumentsKeys": otherDocumentsKeys,
                "closingDate": values.closingDate,
                "startDate": values.startDate
            }));
            toast({
                title: 'Tender added successfully',
                // description: `It will be available once its published`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
            resetForm();
            refetchTendersData(); // Trigger refetching of tenders data
            closeDrawer();
        } catch (error) {
            if (error.response) {
                // Server error with response
                toast({
                    title: 'Error adding tender',
                    description: error.response.data.message || 'An unexpected server error occurred',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } else if (error.request) {
                // Network error without response
                toast({
                    title: 'Network Error',
                    description: 'Unable to connect to the server. Please check your internet connection.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                // Other errors
                toast({
                    title: 'Error',
                    description: error.message || 'An unexpected error occurred',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } finally {
            setSubmitting(false);
        }
    };

    const requestConfirm = (options) => {
        setConfirmDialog({
            isOpen: true,
            title: options.title,
            message: options.message,
            onConfirm: options.onConfirm,
        });
    };

    const handleConfirm = () => {
        confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    function isValidFileType(file) {
        // Define acceptable MIME types for PDFs
        const validPdfType = 'application/pdf';
        if (file.type === validPdfType) {
            return true;
        }

        return false;
    }

    const handleFileChange = async (fieldName, event, setFieldValue) => {
        const file = event.target.files[0];
        console.log("File:", file);
        if (file) {
            setLoading(true);
            try {

                // Check file size (5MB = 5242880 bytes)
                if (file.size > 5242880) {
                    toast({
                        title: 'File size too large',
                        description: 'Please upload files less than 5MB in size.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                    setLoading(false);
                    return;
                }

                // Check file type
                const validFileType = isValidFileType(file);
                console.log("Valid file type:", validFileType);
                if (!validFileType) {
                    toast({
                        title: 'Invalid file type',
                        description: 'Please upload PDF files only.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                    setLoading(false);
                    return;
                }

                // Proceed with file upload
                const uploadResponse = await utils.handleFileUpload(file, `tenders/${Date.now()}`);
                if (uploadResponse && uploadResponse.data && uploadResponse.data.fileUrl) {
                    setAdvertDocumentKey({
                        name: file.name,
                        key: uploadResponse.data.fileUrl
                    });
                    setFieldValue(`advertDocumentKey`, uploadResponse.data.fileUrl);

                    toast({
                        title: 'File uploaded successfully',
                        description: 'Your file has been uploaded.',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    });

                } else {
                    setFieldValue(`files.advertDocumentKey.error`, 'Upload failed. Incomplete response received.');
                    toast({
                        title: 'File upload failed',
                        description: 'An error occurred while uploading the file. Please try again.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                toast({
                    title: 'File upload error',
                    description: error.message || 'An unexpected error occurred while uploading the file.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                console.error("File upload error:", error);
                // setFieldValue(`files.${fieldName}.error`, 'Upload failed: ' + error.message);
            } finally {
                setLoading(false);
            }

        }
    };

    const uploadTenderDocument = async (fieldName, event, setFieldValue) => {
        const file = event.target.files[0];
        if (file) {
            setTenderDocLoading(true);
            try {
                // Check file size (5MB = 5242880 bytes)
                if (file.size > 5242880) {
                    toast({
                        title: 'File size too large',
                        description: 'Please upload files less than 5MB in size.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                    setTenderDocLoading(false);
                    return;
                }

                // Check file type
                const validFileType = isValidFileType(file);
                console.log("Valid file type:", validFileType);
                if (!validFileType) {
                    toast({
                        title: 'Invalid file type',
                        description: 'Please upload PDF files only.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                    setTenderDocLoading(false);
                    return;
                }

                // Proceed with file upload
                const uploadResponse = await utils.handleFileUpload(file, `tenders/${Date.now()}`);
                if (uploadResponse && uploadResponse.data && uploadResponse.data.fileUrl) {
                    setTenderDocumentKey({
                        name: file.name,
                        key: uploadResponse.data.fileUrl
                    });
                    setFieldValue(`tenderDocumentKey`, uploadResponse.data.fileUrl);

                    toast({
                        title: 'File uploaded successfully',
                        description: 'Your file has been uploaded.',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    });

                } else {
                    setFieldValue(`files.tenderDocumentKey.error`, 'Upload failed. Incomplete response received.');
                    toast({
                        title: 'File upload failed',
                        description: 'An error occurred while uploading the file. Please try again.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                toast({
                    title: 'File upload error',
                    description: error.message || 'An unexpected error occurred while uploading the file.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                console.error("File upload error:", error);
                // setFieldValue(`files.${fieldName}.error`, 'Upload failed: ' + error.message);
            } finally {
                setTenderDocLoading(false);
            }

        }
    };

    const uploadOtherTenderDocument = async (fieldName, event, setFieldValue) => {
        const file = event.target.files[0];
        // return console.log("file", file); 
        if (file) {
            setOtherDocsLoading(true);
            try {

                // Check file size (5MB = 5242880 bytes)
                if (file.size > 5242880) {
                    toast({
                        title: 'File size too large',
                        description: 'Please upload files less than 5MB in size.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                    setOtherDocsLoading(false);
                    return;
                }

                // Check file type
                const validFileType = isValidFileType(file);
                // console.log("Valid file type:", validFileType);
                if (!validFileType) {
                    toast({
                        title: 'Invalid file type',
                        description: 'Please upload PDF files only.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                    setOtherDocsLoading(false);
                    return;
                }

                // Proceed with file upload
                const uploadResponse = await utils.handleFileUpload(file, `tenders/${Date.now()}`);
                if (uploadResponse && uploadResponse.data && uploadResponse.data.fileUrl) {
                    setOtherDocumentsKeys([
                        ...otherDocumentsKeys,
                        {
                            name: file.name,
                            key: uploadResponse.data.fileUrl
                        }
                    ]);

                    setFieldValue(`otherDocumentsKeys`, uploadResponse.data.fileUrl);

                    toast({
                        title: 'File uploaded successfully',
                        description: 'Your file has been uploaded.',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    });

                } else {
                    setFieldValue(`files.otherDocumentsKeys.error`, 'Upload failed. Incomplete response received.');
                    toast({
                        title: 'File upload failed',
                        description: 'An error occurred while uploading the file. Please try again.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                toast({
                    title: 'File upload error',
                    description: error.message || 'An unexpected error occurred while uploading the file.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                utils.sendMessageToBugNinja("Error fetching tenders data:", error.message)
            } finally {
                console.log("Other Documents Keys:", otherDocumentsKeys);
                setOtherDocsLoading(false);
            }

        }
    };

    const handleDate = (e) => {
        const { value } = e.target;
        let minDate = getMinAgeDate()
        console.log(tenderSchema.closingDate)
        if (minDate < value) {
            setTenderSchema(prevState => ({
                ...prevState, closing_date: getMinAgeDate()
            }));
        }
        else {
            setTenderSchema(prevState => ({
                ...prevState, closing_date: value
            }));
        }
    };

    // a minDate function to get the minimum date
    const getMinAgeDate = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();
        return `${yyyy}-${mm}-${dd}`;
    };

    const deleteTenderDoc = () => {
        setTenderDocumentKey('');
    };

    const deleteAdvertDoc = () => {
        setAdvertDocumentKey('');
    };

    const handlePdfDelete = (index) => {
        const newPdfs = otherDocumentsKeys.filter((_, i) => i !== index);
        setOtherDocumentsKeys(newPdfs);
    };

    return (
        <>
            <Formik
                initialValues={tenderSchema}
                onSubmit={handleSubmit}
            >
                {formikProps => (
                    <Form>
                        <VStack spacing={4} align="stretch">
                            <FormActionLabel formAction="add" formName="Tender" />
                            <SimpleGrid columns={1} gap={4} px={2} py={4}>
                                <FieldControl formikProps={formikProps} name="title" label="Title" placeholder="Enter tender title" />
                                <FieldControl type="number" formikProps={formikProps} name="fees" label="Purchase Price (BWP)" placeholder="Enter tender fee" />
                                <Box>
                                    <FormLabel htmlFor="description">Description</FormLabel>
                                    <ReactQuill
                                        theme="snow"
                                        value={formikProps.values.description}
                                        onChange={(value) => {
                                            setDescriptionValue(value);
                                            formikProps.setFieldValue('description', value);
                                        }}
                                    />
                                </Box>

                                {/*Section for advert document  */}
                                <SectionHeading icon="mdi:account-file-text" text="Upload Advert Document" />
                                <Grid templateColumns="repeat(12, 1fr)" gap={1} color={"blue.300"} px={4}>
                                    <GridItem colSpan={{ base: 12, md: 12 }}>
                                        <DropBox
                                            label={"Advert Document"}
                                            name="advertDocumentKey"
                                            // filePath={formikProps.values.files}
                                            loading={loading}
                                            formikProps={formikProps}
                                            callback={(event) => handleFileChange('idKey', event, formikProps.setFieldValue, formikProps.values.userId)}
                                            instruction={`Please upload a PDF file not more than 5MB in size.`}
                                        />
                                    </GridItem>
                                </Grid>
                                <CustomAlert status="info" message="This section is for advert document." />
                                {advertDocumentKey && (
                                    <Box position="relative" mr="4" bg={'gray.100'} p={3} shadow={'md'} rounded={'md'}>
                                        <Center>
                                            <Image 
                                                src={pdfFileImage} 
                                                alt={`advert pdf-file`} 
                                                boxSize="200px" 
                                                onClick={() => handlePdfClick(advertDocumentKey?.key)} 
                                                _hover={{ cursor: 'pointer' }}
                                            />
                                            <IconButton
                                                icon={<CloseIcon />}
                                                size="sm"
                                                aria-label="Delete PDF"
                                                position="absolute"
                                                bg={'tomato'}
                                                top="2"
                                                right="2"
                                                onClick={() => deleteAdvertDoc()}
                                            />
                                        </Center>
                                    </Box>
                                )}

                                {/*Section for tender document  */}
                                <SectionHeading icon="mdi:account-file-text" text="Upload Tender Document" />
                                <Grid templateColumns="repeat(12, 1fr)" gap={1} color={"blue.300"} px={4}>
                                    <GridItem colSpan={{ base: 12, md: 12 }}>
                                        <DropBox
                                            label={"Tender Document"}
                                            name="tenderDocumentKey"
                                            loading={tenderDocLoading}
                                            formikProps={formikProps}
                                            callback={(event) => uploadTenderDocument('idKey', event, formikProps.setFieldValue, formikProps.values.tenderDocumentKey)}
                                            instruction={`Please upload a PDF file not more than 5MB in size.`}
                                        />
                                    </GridItem>
                                </Grid>
                                <CustomAlert status="info" message="This section is for tender document." />
                                {tenderDocumentKey && (
                                    <Box position="relative" mr="4" bg={'gray.100'} p={3} shadow={'md'} rounded={'md'}>
                                        <Center>
                                            <Image 
                                                src={pdfFileImage} 
                                                alt={`tender document pdf-file`} 
                                                boxSize="200px" 
                                                onClick={() => handlePdfClick(tenderDocumentKey?.key)} 
                                                _hover={{ cursor: 'pointer' }}
                                            />
                                            <IconButton
                                                icon={<CloseIcon />}
                                                size="sm"
                                                aria-label="Delete PDF"
                                                position="absolute"
                                                bg={'tomato'}
                                                top="2"
                                                right="2"
                                                onClick={() => deleteTenderDoc()}
                                            />
                                        </Center>
                                    </Box>

                                )}

                                {/*Section for other document  */}
                                <SectionHeading icon="mdi:account-file-text" text="Upload Other Documents" />
                                <Grid templateColumns="repeat(12, 1fr)" gap={1} color={"blue.300"} px={4}>
                                    <GridItem colSpan={{ base: 12, md: 12 }}>
                                        <DropBox
                                            label={"Other Document"}
                                            name="otherDocumentsKeys"
                                            loading={otherDocsLoading}
                                            formikProps={formikProps}
                                            callback={(event) => uploadOtherTenderDocument('idKey', event, formikProps.setFieldValue, formikProps.values.otherDocumentsKeys)}
                                            instruction={`Please upload a PDF file not more than 5MB in size.`}
                                        />
                                    </GridItem>
                                </Grid>
                                <CustomAlert status="info" message="This section is for other tender document." />
                                {otherDocumentsKeys?.map((docKey, index) => (
                                    <Box key={index} position="relative" mr="4" bg={'gray.100'} p={3} shadow={'md'} rounded={'md'}>
                                        <Center>
                                            <Image 
                                                src={pdfFileImage} 
                                                alt={`other tender document pdf-file`} 
                                                boxSize="200px" 
                                                _hover={{ cursor: 'pointer' }}    
                                                onClick={() => handlePdfClick(docKey?.key)}
                                            />
                                            <IconButton
                                                icon={<CloseIcon />}
                                                size="sm"
                                                aria-label="Delete PDF"
                                                position="absolute"
                                                bg={'tomato'}
                                                top="2"
                                                right="2"
                                                onClick={() => handlePdfDelete(index)}
                                            />
                                        </Center>
                                    </Box>
                                ))}

                                <Flex justify={'space-between'}>
                                    <FormControl id="startDate" isRequired me={2}>
                                        <FormLabel >Start Date</FormLabel>
                                        <InputGroup>
                                            <Input
                                                type="date"
                                                name="startDate"
                                                id="startDate"
                                                min={getMinAgeDate()}
                                                value={formikProps.values.startDate}
                                                onChange={(event) => {
                                                    formikProps.handleChange(event);
                                                    handleDate(event);
                                                }}
                                            />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl id="closingDate" isRequired>
                                        <FormLabel>Closing Date</FormLabel>
                                        <InputGroup>
                                            <Input
                                                type="date"
                                                name="closingDate"
                                                id="closingDate"
                                                min={formikProps.values.startDate}
                                                value={formikProps.values.closingDate}
                                                onChange={(event) => {
                                                    formikProps.handleChange(event);
                                                    handleDate(event);
                                                }}
                                            />
                                        </InputGroup>
                                    </FormControl>
                                </Flex>

                            </SimpleGrid>
                            <AlertAndButtons
                                formikProps={formikProps}
                                closeDrawer={closeDrawer}
                                requestConfirm={requestConfirm}
                            />
                        </VStack>
                    </Form>
                )}
            </Formik>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />

            {filePath && <PdfViewerModal filePath={filePath} isOpen={isOpen} onOpen={onOpen} onClose={onClose} />}
        </>
    );
};

const AlertAndButtons = ({ formikProps, closeDrawer, requestConfirm }) => {

    const handleCancel = () => {
        requestConfirm({
            title: "Cancel Confirmation",
            message: "Are you sure you want to cancel? Any unsaved changes will be lost.",
            onConfirm: () => {
                formikProps.handleReset();
                closeDrawer();
            }
        });
    };

    const handleReset = () => {
        requestConfirm({
            title: "Reset Confirmation",
            message: "Are you sure you want to reset all your changes?",
            onConfirm: () => {
                formikProps.handleReset();
            }
        });
    };

    return (
        <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}>
            <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
            <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                    Cancel
                </CustomButton>
                <CustomButton onClick={handleReset} type="reset">
                    Reset
                </CustomButton>
                <CustomButton
                    type="submit"
                    disabled={formikProps.isSubmitting}
                    style={{
                        opacity: formikProps.isSubmitting ? 0.7 : 1,
                        pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                    }}
                >
                    {formikProps.isSubmitting ? (
                        <>
                            <Spinner size="xs" mr={2} />
                            Submitting...
                        </>
                    ) : 'Submit'}
                </CustomButton>
            </ButtonStack>
        </Box>
    );
};



export default TenderAddForm;
