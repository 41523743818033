import React from 'react'
import DefaultTemplate from '../../../../components/DefaultTemplate/DefaultTemplate'
import {Icon} from "@iconify/react";
import PublicationsContainer from "./Components/PublicationsContainer";

const Publications = () => {
    return (
        <>
            <DefaultTemplate Content={ <PublicationsContainer/> } Title={"Publications" } icon={<Icon icon="icomoon-free:folder-upload" />}/>
        </>
    )
}

export default Publications
