import React, { useEffect, useState, useCallback } from 'react';
import EventsTable from './EventsTable';
import EventTableTopBar from './EventTableTopBar';
import { fetchAllEvents } from '../eventAPI';

const EventsContainer = () => {
    const [events, setEvents] = useState([]);
    const [error, setError] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const refetchEventsData = useCallback(async (filter={}, fetchAll = false) => {
        setDataLoaded(false);
        try {
            const data = await fetchAllEvents(pageNumber, 10, filter, fetchAll);
            setEvents(data.events);
            setTotalPages(data.totalPages);
            setError('');
            setDataLoaded(true);
        } catch (error) {
            console.error('Error fetching all events:', error.message);
            setError('Failed to fetch events data.');
            setEvents([]);
            setTotalPages(0);
        }
    }, [pageNumber]);

    useEffect(() => {
        refetchEventsData();
    }, [pageNumber, refreshFlag, refetchEventsData]);

    return (
        <>
            <EventTableTopBar refetchEventsData={refetchEventsData} />
            <EventsTable
                events={events}
                setEvents={setEvents}
                setRefreshFlag={setRefreshFlag}
                refetchEventsData={refetchEventsData}
                error={error}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                dataLoaded={dataLoaded}
            />
        </>
    );
};

export default EventsContainer;
