import React, { createContext, useContext, useState } from 'react';

const DrawerContext = createContext();

export const useDrawer = () => useContext(DrawerContext);

export const DrawerProvider = ({ children }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [drawerContent, setDrawerContent] = useState({});
    const [drawerSize, setDrawerSize] = useState("md");

    // OpenDrawer function updated to set content directly
    const openDrawer = (content, size = 'md') => {
        setDrawerContent(content);
        setDrawerSize(size);
        setIsDrawerOpen(true);
    };

    // CloseDrawer function remains the same
    const closeDrawer = () => {
        setIsDrawerOpen(false);
        setDrawerContent({});
    };

    return (
        <DrawerContext.Provider value={{ isDrawerOpen, drawerContent, openDrawer, closeDrawer, drawerSize }}>
            {children}
        </DrawerContext.Provider>
    );
};
