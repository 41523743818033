import {API1} from "../../../utils/api";

// Create Disconnection Application
export const addDisconnection = async (disconnection) => {
    try {
        const response = await API1.post('/connection/disconnect/submit', disconnection);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "An unexpected error occurred while creating new connection request.");
    }
};

//fetch All Disconnection Requests
export const fetchAllDisconnections = async (pageNumber = 1, pageSize = 10, filterOptions = {}) => {
    try {
        const queryParams = new URLSearchParams({
            ...filterOptions,
            pageNumber,
            pageSize
        }).toString();

        const response = await API1.get(`/connection/find/disconnection-requests?${queryParams}`);

        if (response.status === 200) {
            const { docs: disconnections, totalDocs } = response.data.data;
            return {
                disconnections,
                totalPages: Math.ceil(totalDocs / pageSize),
            };
        } else {
            throw new Error('Unexpected response from the server while fetching Disconnection Requests.');
        }
    } catch (error) {
        console.error("Error fetching Disconnection Requests:", error);
        throw new Error('Failed to fetch Disconnection Requests. Please try again.');
    }
};


// Fetch Disconnection Request by ID
export const fetchDisconnectionById = async (disconnectionId) => {
    try {
        const queryParams = new URLSearchParams({ id: disconnectionId }).toString();
        const response = await API1.get(`/connection/find/disconnection-requests?${queryParams}`);
        if (response.data.status === 'Success' && response.data.data.docs.length > 0) {
            return response.data.data.docs[0];
        } else {
            throw new Error('Disconnection Request not found.');
        }
    } catch (error) {
        throw error;
    }
};


export const updateDisconnection = async (disconnectionId, disconnectionData) => {
    try {
        const response = await API1.put(`/connection/disconnect/submit/`, {id: disconnectionId, ...disconnectionData});
        if (response.status === 201) {
            return response.data;
        } else {
            throw new Error('Failed to update disconnection application' + response.data.message);
        }
    } catch (error) {
        if (error.response) {

            throw new Error('Server error: ' + error?.message);
        } else if (error.request) {

            throw new Error('Network error: Unable to reach the server.');
        } else {
            throw new Error('Error: ' + error.message);
        }
    }
};


// Delete Disconnection Application
export const deleteDisconnection = async (disconnectionId) => {
    try {
        const response = await API1.delete(`/connection/disconnect/delete`,{params:{id:disconnectionId}});
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "Failed to delete disconnection Request");
    }
};

export const fetchContractNumbers = async (customerNumber) => {
    try {
        const response = await API1.get(`/utility-contracts/customer/${customerNumber}`);
        if (response.data.success) {
            return response.data.utilityContracts.map(contract => ({
                label: contract.utilityContractNumber,
                value: contract.utilityContractNumber
            }));
        } else {
            throw new Error(response.data.message || "Failed to fetch contracts.");
        }
    } catch (error) {
        if (error.response) {
            console.error("Server Error:", error.response.data);
            throw new Error(error?.message || "Error fetching contract numbers.");
        } else if (error.request) {
            console.error("Network Error:", error.request);
            throw new Error("Network error. Please try again.");
        } else {
            console.error("Error:", error.message);
            throw new Error(error.message);
        }
    }
};
