import { API1 } from "../../../../utils/api";

// Create Publication
export const createPublication = async (publicationData) => {
    try {
        const response = await API1.post('/publications', publicationData);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "An unexpected error occurred while creating the publication.");
    }
};

// Fetch All Publications
export const fetchAllPublications = async (pageNumber = 1, pageSize = 10, filterOptions = {}) => {
    try {
        const queryParams = new URLSearchParams({
            ...filterOptions,
            pageNumber,
            pageSize
        }).toString();

        const response = await API1.get(`/publications?${queryParams}`);

        if (response.status === 200) {
            const { docs: publications, totalDocs } = response.data;
            return {
                publications,
                totalPages: Math.ceil(totalDocs / pageSize),
            };
        } else {
            throw new Error('Unexpected response from the server while fetching Publications.');
        }
    } catch (error) {
        console.error("Error fetching Publications:", error);
        throw new Error('Failed to fetch Publications. Please try again.');
    }
};


// Fetch Publication by ID
export const fetchPublicationById = async (publicationId) => {
    try {
        const queryParams = new URLSearchParams({ id: publicationId }).toString();
        const response = await API1.get(`/publications?${queryParams}`);
        if (response.status === 200 && response.data.docs.length > 0) {
            return response.data.docs[0];
        } else {
            throw new Error('Publication not found.');
        }
    } catch (error) {
        throw error;
    }
};



// Update Publication
export const updatePublication = async (publicationId, publicationData) => {
    try {
        const response = await API1.put(`/publications/${publicationId}`, publicationData);
        if (response.status !== 200) {
            throw new Error('Update failed due to server response');
        }
        return response.data;
    } catch (error) {
        console.error("Error updating publication:", error);
        throw new Error(error?.message || "An error occurred while updating the publication.");
    }
};


// Delete Publication
export const deletePublication = async (publicationId) => {
    try {
        const response = await API1.delete(`/publications/${publicationId}`);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "Failed to delete the publication.");
    }
};


// Fetch All Publication categories
export const fetchAllPublicationsCategories = async () => {
    try {
        const response = await API1.get(`/publicationCategories`);
        if (response.status === 200) {
            console.log("Publication categories", response)
            const { docs: publicationCategories} = response.data;
            return {
                publicationCategories,
            };
        } else {
            throw new Error('Unexpected response from the server while fetching publication categories.');
        }
    } catch (error) {
        console.error("Error fetching publication categories:", error);
        throw new Error('Failed to fetch publication categories. Please try again.');
    }
};