import React, {useEffect, useRef, useState} from 'react';
import { Formik, Form, Field } from 'formik';
import { FormControl, Stack, Select } from '@chakra-ui/react';
import * as Yup from 'yup';
import { CustomButton } from "../../../../components/Styled/StyledButtons";
import { Icon } from "@iconify/react";
import { fetchAllAreas } from "../ConsultantsAPI";
import FieldErrorMessage from "../../../../components/FieldErrorMessage/FieldErrorMessage";
import {ThemedStyledInput} from "../../../../components/Styled";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";

const validationSchema = Yup.object({
    email: Yup.string()
        .email('Search & Filters Error: Invalid email address, please input a valid email address')
        .max(50, 'Search & Filters Error: Email Must be 50 characters or less')
        .trim('Search & Filters Error: Email cannot have whitespace')
        .strict(true),
    firstName: Yup.string()
        .max(30, 'Search & Filters Error: First Name Must be 30 characters or less')
        .trim('Search & Filters Error: First Name cannot have whitespace')
        .strict(true),
    lastName: Yup.string()
        .max(30, 'Search & Filters Error: Last Name Must be 30 characters or less')
        .trim('Search & Filters Error: Last Name cannot have whitespace')
        .strict(true)
});

const ConsultantSearchFilter = ({ onFilterChange }) => {
    const showToast = useSingleToast();
    const showToastRef = useRef(showToast);
    const [areas, setAreas] = useState([]);



    useEffect(() => {
        fetchAllAreas().then(data => {
            setAreas(data.areas);
        }).catch(error => {
            showToastRef.current({
                title: "Error",
                description: "Failed to fetch areas.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        });
    }, []);

    return (
        <Formik
            initialValues={{ firstName: '', lastName: '', email: '', area: '' }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                if (!values.email && !values.firstName && !values.lastName && !values.area) {
                    showToast({
                        title: "No Parameters Supplied",
                        description: "Please enter search criteria.",
                        status: "warning",
                        duration: 5000,
                        isClosable: true,
                    });
                } else {
                    onFilterChange(values);
                }
                setSubmitting(false);
            }}
            onReset={() => {
                onFilterChange({ firstName: '', lastName: '', email: '', area: '' });
            }}
        >
            {({ handleSubmit, resetForm }) => (
                <Form>
                    <Stack spacing={2} direction="row" align="center">
                        <FormControl>
                            <Field as={ThemedStyledInput} id="firstName" name="firstName" type="text" placeholder="Search by First Name" />
                        </FormControl>
                        <FormControl>
                            <Field as={ThemedStyledInput} id="lastName" name="lastName" type="text" placeholder="Search by Last Name" />
                        </FormControl>
                        <FormControl>
                            <Field as={ThemedStyledInput} id="email" name="email" type="email" placeholder="Search by Email" />
                        </FormControl>
                        <FormControl>
                            <Field as={Select} id="area" name="area" placeholder=" -- Select Area --">
                                {areas.map(area => (
                                    <option key={area._id} value={area.name}>{area.name}</option>
                                ))}
                            </Field>
                        </FormControl>
                        <CustomButton onClick={handleSubmit} type="search" tooltipLabel="Search" icon={<Icon icon="bi:search" />} showLabel={false}>
                            Search
                        </CustomButton>
                        <CustomButton
                            type="reset"
                            onClick={() => {
                                resetForm();
                                onFilterChange({ firstName: '', lastName: '', email: '', area: '' });
                            }}
                            tooltipLabel="Clear Filters"
                            icon={<Icon icon="radix-icons:reset" />}
                            showLabel={false}
                        >
                            Clear
                        </CustomButton>
                    </Stack>
                    <FieldErrorMessage name="firstName" />
                    <FieldErrorMessage name="lastName" />
                    <FieldErrorMessage name="email" />
                    <FieldErrorMessage name="area" />
                </Form>
            )}
        </Formik>
    );
};

export default ConsultantSearchFilter;
