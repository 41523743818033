import React, {useState} from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    Box,
    Text,
    InputGroup,
    InputRightElement,
    IconButton,
    SimpleGrid,
    Spinner,
    Textarea,
    Select
} from '@chakra-ui/react';
import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';
import {useDrawer} from "../../../../Context/DrawerContext/DrawerContext";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";
import utils from "../../../../utils/commonFunctions";
import FormActionLabel from "../../../../components/Styled/FormActionLabel";
import {ThemedStyledInput} from "../../../../components/Styled";
import {Icon} from "@iconify/react";
import ConfirmDialog from "../../../../Context/ConfirmDialog/ConfirmDialog";
import CustomAlert from "../../../../components/Styled/StyledAlert";
import {ButtonStack, CustomButton} from "../../../../components/Styled/StyledButtons";
import DropBox from "../../../../components/DropBox/DropBox";
import {createVideo} from "../VideoGalleryAPI";

const VideoGalleryAddForm = ({ refetchVideoData, filterOptions }) => {
    const { closeDrawer } = useDrawer();
    const showToast = useSingleToast();
    const [loading, setLoading] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });
    const [source, setSource] = useState('');
    const [videoPreviewUrl, setVideoPreviewUrl] = useState(null);
    const [embedCode, setEmbedCode] = useState('');


    const videoSchema = Yup.object().shape({
        title: Yup.string().required('Title is required.').min(3, 'Title must be at least 3 characters.'),
        videoCode: Yup.string().when('source', (source, schema) => {
            return source === 'YouTube' ? schema.required('Embed code is required.') : schema.notRequired()
        }),
        fileUrl: Yup.string().when('source', (source, schema) => {
            return source === 'URL' ? schema.required('Video file is required for URL source.') : schema.notRequired()
        }),
        thumbnail: Yup.string(),
        source: Yup.string().required('Source is required.'),
        published: Yup.boolean()
    });


    const handleSourceChange = (event, formikProps) => {
        const newSource = event.target.value;
        setSource(newSource);
        formikProps.setFieldValue('source', newSource);
        formikProps.setFieldValue('videoCode', '');
        formikProps.setFieldValue('fileUrl', '');

        if (newSource === 'URL') {
            setEmbedCode('');
        } else {
            setVideoPreviewUrl(null);
        }
    };


    const handleFileChange = async (fieldName, event, setFieldValue) => {
        const file = event.target.files[0];
        if (file && source === 'URL') {
            setLoading(prev => ({ ...prev, [fieldName]: true }));
            try {
                const uploadResponse = await utils.handleFileUpload(file, `videos/${uuidv4()}`);
                if (uploadResponse && uploadResponse.data && uploadResponse.data.fileUrl) {
                    setFieldValue('fileUrl', uploadResponse.data.fileUrl);
                    setVideoPreviewUrl(uploadResponse.data.fileUrl);
                } else {
                    showToast({
                        title: 'Upload Failed',
                        description: 'Unable to upload file, please try again.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                    setFieldValue('fileUrl', '');
                    setVideoPreviewUrl(null);
                }
            } catch (error) {
                console.error("File upload error:", error);
                showToast({
                    title: 'Error',
                    description: `Upload failed: ${error.message}`,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                setFieldValue('fileUrl', '');
                setVideoPreviewUrl(null);
            } finally {
                setLoading(false);
            }
        }
    };


    const handleVideoCodeChange = (e, setFieldValue) => {
        const newEmbedCode = e.target.value;
        setFieldValue('videoCode', newEmbedCode);
        setEmbedCode(newEmbedCode);
    };

    const handleSubmit = async (values, actions) => {
        if (values.source === 'URL' && !values.fileUrl) {
            showToast({
                title: 'Missing Video',
                description: 'Please upload a video file before submitting.',
                status: 'warning',
                duration: 5000,
                isClosable: true,
            });
            actions.setSubmitting(false);
            return;
        }

        try {
            await createVideo(values);
            showToast({
                title: 'Success',
                description: 'Video added successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchVideoData(filterOptions);
            closeDrawer();
        } catch (error) {
            showToast({
                title: 'Error',
                description: error.message || 'An unexpected error occurred',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            actions.setSubmitting(false);
        }
    };

    const requestConfirm = (options) => {
        setConfirmDialog({
            isOpen: true,
            title: options.title,
            message: options.message,
            onConfirm: options.onConfirm,
        });
    };

    const handleConfirm = () => {
        confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };


    return (
        <>
            <FormActionLabel formAction="add" formName="Video" />
            <Formik
                initialValues={{
                    title: '',
                    videoCode: '',
                    fileUrl: '',
                    thumbnail: '',
                    source: '',
                    published: true,
                }}
                validationSchema={videoSchema}
                onSubmit={(values, actions) => {
                    handleSubmit(values, actions);
                    actions.setSubmitting(false);
                }}
            >
                {(formikProps) => (
                    <Form>
                        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                            <SimpleGrid columns={1} gap={4} px={2} py={4}>
                                {/* Title input field */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.title && formikProps.touched.title}>
                                        <FormLabel htmlFor="title">Video Caption</FormLabel>
                                        <InputGroup>
                                            <ThemedStyledInput
                                                {...formikProps.getFieldProps('title')}
                                                id="title"
                                                placeholder="Enter Video Title"
                                            />
                                            <InputRightElement>
                                                <IconButton
                                                    aria-label="Icon button label"
                                                    bg="blue.300"
                                                    _hover={{ bg: '#69C5EC' }}
                                                    _active={{ bg: '#4699bc' }}
                                                    color="white"
                                                    icon={<Icon icon="mdi:rename-outline" />}
                                                />
                                            </InputRightElement>
                                        </InputGroup>
                                        {formikProps.errors.title && formikProps.touched.title && (
                                            <Text color="red.500" fontSize="sm">{formikProps.errors.title}</Text>
                                        )}
                                    </FormControl>
                                </GridItem>
                                {/* Source selection dropdown */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.source && formikProps.touched.source}>
                                        <FormLabel htmlFor="source">Source</FormLabel>
                                        <Select
                                            {...formikProps.getFieldProps('source')}
                                            id="source"
                                            onChange={(event) => handleSourceChange(event, formikProps)}
                                            placeholder="Select Source"
                                            borderWidth="2px"
                                            focusBorderColor="#63B3ED"
                                        >
                                            <option value="YouTube">YouTube</option>
                                            <option value="URL">URL</option>
                                        </Select>
                                        {formikProps.errors.source && formikProps.touched.source && (
                                            <Text color="red.500" fontSize="sm">{formikProps.errors.source}</Text>
                                        )}
                                    </FormControl>
                                </GridItem>
                                {/* Video code and URL fields */}
                                {source === 'YouTube' && (
                                    <>
                                        <CustomAlert
                                            status="info"
                                            message={
                                                embedCode ?
                                                    "A YouTube embed code is already added. You can preview it below or update it by pasting a new embed code in the field below." :
                                                    "Please enter the YouTube embed code for your video. Find this by clicking 'Share' on YouTube, selecting 'Embed', and copying the code provided."
                                            }
                                        />
                                        <GridItem>
                                            <FormControl isInvalid={formikProps.errors.videoCode && formikProps.touched.videoCode}>
                                                <FormLabel htmlFor="videoCode">Embed Video Code</FormLabel>
                                                <Field name="videoCode">
                                                    {({ field }) => (
                                                        <Textarea
                                                            {...field}
                                                            id="videoCode"
                                                            placeholder="Enter Embed Code"
                                                            borderWidth="2px"
                                                            focusBorderColor="#63B3ED"
                                                            onChange={(e) => handleVideoCodeChange(e, formikProps.setFieldValue)}
                                                        />
                                                    )}
                                                </Field>
                                                {formikProps.errors.videoCode && formikProps.touched.videoCode && (
                                                    <Text color="red.500" fontSize="sm">{formikProps.errors.videoCode}</Text>
                                                )}
                                                {embedCode && (
                                                    <Box mt="4">
                                                        <Text fontSize="md" mb="2">Preview:</Text>
                                                        <Box as="iframe" srcDoc={embedCode} width="100%" height="200px" frameBorder="0" allowFullScreen></Box>
                                                    </Box>
                                                )}
                                            </FormControl>
                                        </GridItem>
                                    </>
                                )}
                                {source === 'URL' && (
                                    <>
                                    <CustomAlert
                                        status="info"
                                        message={
                                            videoPreviewUrl
                                                ? "Your video file has been uploaded successfully. You can replace it by uploading a different MP4 file or change the URL."
                                                : "Please provide a video by entering a URL or by uploading an MP4 video file directly."
                                        }
                                    />

                                    <GridItem>
                                        <FormControl isInvalid={formikProps.errors.fileUrl && formikProps.touched.fileUrl}>
                                            <FormLabel htmlFor="fileUrl">Video URL</FormLabel>
                                            <ThemedStyledInput
                                                {...formikProps.getFieldProps('fileUrl')}
                                                id="fileUrl"
                                                placeholder="Enter Video URL"
                                            />
                                            {formikProps.errors.fileUrl && formikProps.touched.fileUrl && (
                                                <Text color="red.500" fontSize="sm">{formikProps.errors.fileUrl}</Text>
                                            )}
                                        </FormControl>

                                        <FormControl
                                            isInvalid={formikProps.errors.fileData && formikProps.errors.fileData.fileUrl && formikProps.touched.fileData && formikProps.touched.fileData.fileUrl}>
                                            <DropBox
                                                label="Video File"
                                                name="fileData.fileUrl"
                                                filePath={videoPreviewUrl}
                                                loading={loading}
                                                formikProps={formikProps}
                                                callback={(event) => handleFileChange('fileUrl', event, formikProps.setFieldValue)}
                                                result={videoPreviewUrl}
                                                instruction="Upload your MP4 video file."
                                            />
                                            {formikProps.errors.fileData && formikProps.errors.fileData.fileUrl && formikProps.touched.fileData && formikProps.touched.fileData.fileUrl && (
                                                <Text color="red.500" fontSize="sm">{formikProps.errors.fileData.fileUrl}</Text>
                                            )}
                                            {/* Video file preview */}
                                            {videoPreviewUrl && (
                                                <Box mt="4">
                                                    <video key={videoPreviewUrl} controls>
                                                        <source src={videoPreviewUrl} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                </Box>
                                            )}
                                        </FormControl>
                                    </GridItem>
                                    </>
                                )}
                                {/* Publishing status dropdown */}
                                <GridItem>
                                    <FormControl>
                                        <FormLabel htmlFor="published">Published</FormLabel>
                                        <Select
                                            {...formikProps.getFieldProps('published')}
                                            id="published"
                                            placeholder=" -- Select Publishing Status -- "
                                        >
                                            <option value={true}>Published</option>
                                            <option value={false}>Unpublished</option>
                                        </Select>
                                    </FormControl>
                                </GridItem>
                            </SimpleGrid>
                            <AlertAndButtons
                                formikProps={formikProps}
                                closeDrawer={closeDrawer}
                                requestConfirm={requestConfirm}
                                setVideoPreviewUrl={setVideoPreviewUrl}
                                setEmbedCode={setEmbedCode}
                            />
                        </VStack>
                    </Form>
                )}
            </Formik>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};


const AlertAndButtons = ({ formikProps, closeDrawer, requestConfirm, setVideoPreviewUrl, setEmbedCode }) => {

    const handleCancel = () => {
        requestConfirm({
            title: "Cancel Confirmation",
            message: "Are you sure you want to cancel? Any unsaved changes will be lost.",
            onConfirm: () => {
                formikProps.handleReset();
                setVideoPreviewUrl(null);
                setEmbedCode('');
                closeDrawer();
            }
        });
    };

    const handleReset = () => {
        requestConfirm({
            title: "Reset Confirmation",
            message: "Are you sure you want to reset all your changes?",
            onConfirm: () => {
                formikProps.handleReset();
                setVideoPreviewUrl(null);
                setEmbedCode('');
            }
        });
    };

    return (
        <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}>
            <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
            <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                    Cancel
                </CustomButton>
                <CustomButton onClick={handleReset} type="reset">
                    Reset
                </CustomButton>
                <CustomButton
                    type="submit"
                    disabled={formikProps.isSubmitting}
                    style={{
                        opacity: formikProps.isSubmitting ? 0.7 : 1,
                        pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                    }}
                >
                    {formikProps.isSubmitting ? (
                        <>
                            <Spinner size="xs" mr={2} />
                            Submitting...
                        </>
                    ) : 'Submit'}
                </CustomButton>
            </ButtonStack>
        </Box>
    );
};

export default VideoGalleryAddForm;
