import React, { useEffect, useState, useCallback } from 'react';
import TendersTable from './TendersTable';
import TenderTableTopBar from './TendersTableTopBar';
import { fetchAllTenders } from '../tenderAPI';
import utils from '../../../utils/commonFunctions';

const TendersContainer = () => {
    const [tenders, setTenders] = useState([]);
    const [error, setError] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const refetchTendersData = useCallback(async (filter={}, fetchAll = false) => {
        setDataLoaded(false);
        try {
            const data = await fetchAllTenders(pageNumber, 10, filter, fetchAll);
            if (data) {
                setTenders(data.tenders);
                setTotalPages(data.totalPages);
                setError('');
                setDataLoaded(true);
            }
        } catch (error) {
            utils.sendMessageToBugNinja("Error refetching tenders", error.message, error);
            setError('Failed to fetch tenders data.');
            setTenders([]);
            setTotalPages(0);
        }
    }, [pageNumber]);

    useEffect(() => {
        refetchTendersData();
    }, [pageNumber, refreshFlag, refetchTendersData]);


    return (
        <>
            <TenderTableTopBar refetchTendersData={refetchTendersData} totalTenders={tenders?.length}/>
            <TendersTable
                tenders={tenders}
                setTenders={setTenders}
                setRefreshFlag={setRefreshFlag}
                refetchTendersData={refetchTendersData}
                error={error}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                dataLoaded={dataLoaded}
            />
        </>
    );
};

export default TendersContainer;
