import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import { fetchAllCustomers } from '../../pages/CustomerRelated/CustomerUserManagement/CustomerAPI';

const CustomerContext = createContext();

export const useCustomer = () => useContext(CustomerContext);

export const CustomerProvider = ({ children }) => {
    const [customers, setCustomers] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [filterOptions, setFilterOptions] = useState({});
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [error, setError] = useState('');
    const fetchTimeoutRef = useRef(null);

    const fetchCustomers = async () => {
        try {
            const response = await fetchAllCustomers(pageNumber, pageSize, filterOptions);
            setCustomers(response.customers);
            setTotalPages(response.totalPages);
        } catch (error) {
            setError(error.message);
            console.error('Error fetching all customers:', error.message);
        }
    }

    useEffect(() => {
        if (fetchTimeoutRef.current) {
            clearTimeout(fetchTimeoutRef.current);
        }
        fetchTimeoutRef.current = setTimeout(() => {
            fetchCustomers();
        }, 500); // Set the timeout interval to 500 milliseconds

        return () => {
            if (fetchTimeoutRef.current) {
                clearTimeout(fetchTimeoutRef.current);
            }
        };
    }, [pageNumber, pageSize, filterOptions]);

    return (
        <CustomerContext.Provider value={{
            customers,
            fetchCustomers,
            totalPages,
            setTotalPages,
            pageSize,
            setPageSize,
            pageNumber,
            setPageNumber,
            filterOptions,
            setFilterOptions,
            error,
            setError
        }}>
            {children}
        </CustomerContext.Provider>
    );
};
