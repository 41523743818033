import React from 'react'
import NewsContainer from './Components/NewsContainer'
import {Icon} from "@iconify/react";
import DefaultTemplate from "../../components/DefaultTemplate/DefaultTemplate";

const News = () => {
    return (
        <>
            <DefaultTemplate 
                Content={ <NewsContainer/> }
                Title={"News"}
                icon={<Icon icon="fluent:news-16-regular" />}
            />
        </>
    )
}

export default News
