import React, { useEffect, useState } from 'react';
import DropBox from "../../../components/DropBox/DropBox";
import {Box, Button, FormControl, FormLabel, Grid, GridItem, useToast} from "@chakra-ui/react";
import { ThemedStyledInput } from "../../../components/Styled";
import utils from "../../../utils/commonFunctions";
import { DeleteButton } from "../../../components/Styled/ActionButtons";
import { AddIcon } from "@chakra-ui/icons";

const DropBoxList = ({ formikProps }) => {
    const [dropBoxes, setDropBoxes] = useState(formikProps.values.url || [{ title: "", link: "" }]);
    const [loading, setLoading] = useState(false);
    const toast = useToast({ position: 'top-right' });
    useEffect(() => {
        console.log("Dropboxes",formikProps.values.url)
        if (formikProps.values.url && formikProps.values.url.length > 0) {
            setDropBoxes(formikProps.values.url);
        } else {
            setDropBoxes([{ title: "", link: "" }]);
        }
    }, [formikProps.values.url]);

    const handleAddNew = () => {
        const lastDropBox = dropBoxes[dropBoxes.length - 1];
        if (lastDropBox.title && lastDropBox.link) {
            const newDropBoxes = [...dropBoxes, { title: "", link: "" }];
            setDropBoxes(newDropBoxes);
            formikProps.setFieldValue('url', newDropBoxes);
        } else {
            toast.closeAll()
            toast({
                title: 'Please fill out the title and upload document of the previous dropbox before adding a new one.',
                status: 'warning',
                duration: 5000,
                isClosable: true,
            });
            return
            alert("");
        }
    };

    const handleFileChange = async (fieldName, event, setFieldValue) => {
        const file = event.target.files[0];
        if (file) {
            setLoading(true);
            try {
                const uploadResponse = await utils.handleFileUpload(file, 'pages');
                if (uploadResponse && uploadResponse?.data?.fileUrl) {
                    setFieldValue(fieldName, uploadResponse.data.fileUrl);
                } else {
                    setFieldValue(fieldName, '');
                }
            } catch (error) {
                console.error("File upload error:", error);
                setFieldValue(fieldName, '');
            }
            setLoading(false);
        }
    };

    const handleInputChange = (index, field, value) => {
        const updatedDropBoxes = dropBoxes.map((dropBox, i) =>
            i === index ? { ...dropBox, [field]: value } : dropBox
        );
        setDropBoxes(updatedDropBoxes);
        formikProps.setFieldValue('url', updatedDropBoxes);
    };

    const handleDelete = (index) => {
        const updatedDropBoxes = dropBoxes.filter((_, i) => i !== index);
        setDropBoxes(updatedDropBoxes);
        formikProps.setFieldValue('url', updatedDropBoxes);
    };

    return (
        <Box boxShadow={'md'} borderRadius={'2xl'} p={4} mt={2}>
            {dropBoxes.map((dropBox, index) => (
                <React.Fragment key={index}>
                    <FormControl isRequired isInvalid={formikProps.errors.url && formikProps.touched.url}>
                        <FormLabel htmlFor={`title-${index}`}>Document Title</FormLabel>
                        <Grid templateColumns='repeat(12, 1fr)' alignItems={'center'}>
                            <GridItem colSpan={11}>
                                <ThemedStyledInput
                                    id={`title-${index}`}
                                    value={dropBox.title}
                                    onChange={(e) => handleInputChange(index, 'title', e.target.value)}
                                    placeholder="Enter document title"
                                />
                            </GridItem>
                            <GridItem colSpan={1}>
                                <DeleteButton onClick={() => handleDelete(index)} />
                            </GridItem>
                        </Grid>
                    </FormControl>
                    <DropBox
                        label={'Attachment'}
                        isRequired={true}
                        filePath={dropBox.link}
                        name={`link-${index}`}
                        loading={loading}
                        formikProps={formikProps}
                        callback={(event) => handleFileChange(`url[${index}].link`, event, formikProps.setFieldValue)}
                        instruction={`Please attach relevant pdf documents.`}
                    />
                </React.Fragment>
            ))}
            <Button
                w={'full'}
                textAlign={'center'}
                variant={'solid'}
                type="button"
                onClick={handleAddNew}
                leftIcon={<AddIcon />}
                colorScheme="gray"
                mt={4}
            >
                Add New
            </Button>
        </Box>
    );
};

export default DropBoxList;
