import React from 'react';
import Chart from 'react-apexcharts';

class PollPieChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          type: 'donut',
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false
            },
          },
        },
        labels: [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      series: [],
    };
  }

  componentDidMount() {
    // Get poll data from props
    const { pollData } = this.props;
    console.log('Poll Data:', pollData); // Debugging line

    if (pollData && pollData.options && pollData.votes) {
      const { adjustedOptions, adjustedSeries } = this.alignData(pollData.options, pollData.votes);

      this.setState({
        options: {
          ...this.state.options,
          labels: adjustedOptions,
        },
        series: adjustedSeries,
      });
    }
  }

  alignData(options, series) {
    const adjustedOptions = [];
    const adjustedSeries = [];
    
    options.forEach((option, index) => {
      adjustedOptions.push(option);
      adjustedSeries.push(series[index] !== undefined ? Number(series[index]) : 0);
    });

    // Remove any series data that doesn't have a corresponding option
    series.forEach((value, index) => {
      if (index >= options.length) {
        adjustedSeries.push(0);
      }
    });

    return { adjustedOptions, adjustedSeries };
  }

  render() {
    return (
      <div className="poll-chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="donut"
          width="380"
        />
      </div>
    );
  }
}

export default PollPieChart;
