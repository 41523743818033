import { API1 } from "../../utils/api";

// #################################
//          EVENTS API FUNCTIONS
// #################################

// Fetch All Events
export const fetchAllEvents = async (pageNumber = 1, pageSize = 10, filterOptions = {}) => {
    try {
        const queryParams = new URLSearchParams({
            ...filterOptions,
            pageNumber,
            pageSize
        }).toString();

        const response = await API1.get(`/events?${queryParams}`);
    console.log(response)
        if (response.status === 200) {
            const { docs: events, totalDocs } = response.data;
            return {
                events: response.data,
                totalPages: Math.ceil(totalDocs / pageSize),
            };
        } else {
            throw new Error('Unexpected response from the server while fetching events.');
        }
    } catch (error) {
        console.error("Error fetching events:", error);
        throw new Error('Failed to fetch events. Please try again.');
    }
};

// addEvent API
export const addEvent = async (eventData) => {
    try {
        console.table("eventData", eventData);
        const response = await API1.post(`/events`, eventData);
        if (response.status === 201) {
            return response.data;
        } else {
            throw new Error('Unexpected response from the server while adding event.');
        }
    } catch (error) {
        console.error("Error adding event:", error);
        throw new Error('Failed to add event. Please try again.');
    }
};

// fetch events by id
export const fetchEventById = async (eventId) => {
    try {
        const response = await API1.get(`/events/${eventId}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Unexpected response from the server while fetching event.');
        }
    } catch (error) {
        console.error("Error fetching event:", error);
        throw new Error('Failed to fetch event. Please try again.');
    }
};

// update event API
export const updateEvent = async (eventId, eventData) => {
    try {
        console.log("eventData", eventData);
        console.log("eventId", eventId);
        const response = await API1.put(`/events/${eventId}`, eventData);

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Unexpected response from the server while updating event.');
        }
    } catch (error) {
        console.error("Error updating event:", error);
        throw new Error('Failed to update event. Please try again.');
    }
};

// delete event by ID API
export const deleteEvent = async (eventId) => {
    try {
        const response = await API1.delete(`/events/${eventId}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Unexpected response from the server while deleting event.');
        }
    } catch (error) {
        console.error("Error deleting event:", error);
        throw new Error('Failed to delete event. Please try again.');
    }
};
