import {API1} from "../utils/api";

const BASE_URL = (process.env.REACT_APP_API_BASE_URL ?? '//localhost'); // Replace this with your actual Base URL

const photoService = {
    // Album related services
    createAlbum: async (albumData) => {
        const response = await API1.post(`/images/albums`,albumData)

        if (!response.data) {
            throw new Error(`Error creating album: ${response.statusText}`);
        }
        return response;
    },

    getAllAlbums: async () => {
        const response = await API1.get(`/images/albums`);
        if (!response.data) {
            throw new Error(`Error fetching albums: ${response.statusText}`);
        }
        return response.data;
    },

    getAlbumById: async (id) => {
        const response = await API1.get(`/images/albums/${id}`);
        if (!response.data) {
            throw new Error(`Error fetching album: ${response.statusText}`);
        }
        return response.data;
    },

    updateAlbum: async (id, albumData) => {
        const response = await API1.put(`/images/albums/${id}`, albumData);
        if (!response.data) {
            throw new Error(`Error updating album: ${response.statusText}`);
        }
        return response;
    },

    deleteAlbum: async (id) => {
        const response = await API1.delete(`/images/albums/${id}`);
        if (!response.data) {
            throw new Error(`Error deleting album: ${response.statusText}`);
        }
        return response;
    },

    // Photo related services
    addPhotoToAlbum: async (photoData) => {
        const response = await API1.post(`/images/photos`, photoData);
        if (!response.data) {
            throw new Error(`Error adding photo to album: ${response.statusText}`);
        }
        return response;
    },

    getAllPhotos: async () => {
        const response = await API1.get(`/images/photos`);
        if (!response.data) {
            throw new Error(`Error fetching photos: ${response.statusText}`);
        }
        return response.data;
    },

    getPhotoById: async (id) => {
        const response = await API1.get(`/images/photos/${id}`);
        if (!response.data) {
            throw new Error(`Error fetching photo: ${response.statusText}`);
        }
        return response.data;
    },

    getPhotosByAlbum: async (albumId) => {
        const response = await API1.get(`/images/albums/${albumId}/photos`);
        if (!response.data) {
            throw new Error(`Error fetching photos by album: ${response.statusText}`);
        }
        return response.data;
    },

    updatePhoto: async (id, photoData) => {
        const response = await API1.put(`/images/photos/${id}`, photoData);
        if (!response.data) {
            throw new Error(`Error updating photo: ${response.statusText}`);
        }
        return response.data
    },

    deletePhoto: async (id) => {
        const response = await API1.delete(`/images/photos/${id}`);
        if (!response.data) {
            throw new Error(`Error deleting photo: ${response.statusText}`);
        }
        return response.data;
    },
};

export default photoService;
