import React from 'react';
import { Heading, Text, Badge, Button,Flex, Box } from '@chakra-ui/react';
import { StyledBox, ContentBox} from './StyledComponents';
import { FaPrint } from 'react-icons/fa';

const SurveyCard = ({ title, description, status, closingDate, handlePrint }) => {

  const getStatusColor = (status) => {
    switch (status) {
      case 'Published':
        return 'green';
      case 'Draft':
        return 'yellow';
      case 'Closed':
        return 'red';
      default:
        return 'gray'; // Default color for unknown statuses
    }
  };

  return (
    <Box bg='white' boxShadow='lg' p='6' rounded='md' mb={5}>
      <ContentBox p={0}>
            <Heading ps={0} as="h2" size="lg" mb={4}>
                {title}
            </Heading>
            <Text mb={4} ps={5}>
              <div dangerouslySetInnerHTML={{ __html: description}} />
            </Text>
            <Flex my={4}>
              <Text as='b'>Survey Status:</Text>
              <Badge colorScheme={getStatusColor(status)} pt={1} ms={2}>
                  {status ?? 'Not Set'}
              </Badge>
            </Flex>
            <Flex justify="space-between" mb={4}>
                <Box>
                  <Text mb={4} as="b">Closing Date: {closingDate}</Text>
                </Box>
                <Button 
                  leftIcon={<FaPrint />} 
                  colorScheme="blue"
                  onClick={handlePrint}
                  >Print Survey</Button>
            </Flex>    
        </ContentBox>
    </Box>
  );
};

export default SurveyCard;
