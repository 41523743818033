// BannerView.js
import React from 'react';
import {
  Box, Text,
  Card,
  CardBody,
  Flex,
  Heading,
  IconButton,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Icon, Badge, Progress 
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import ImageCarousel from "./ImageCarousel";
import DefaultTemplate from "../../../components/DefaultTemplate/DefaultTemplate";
import ProjectDocumentList from './ProjectDocumentList';
import { useState, useEffect } from 'react';
import { fetchProjectById } from "../projectAPI";
import utils from '../../../utils/commonFunctions';

const ProjectView = () => {
  const { projectId } = useLocation().state;
  return (
    <>
      <DefaultTemplate
        Content={<ViewContainer projectId={projectId} />}
        Title={"View Project"}
        icon={<Icon icon="mdi:poll" />}
      />
    </>
  );
};

const ViewContainer = ({ projectId }) => {
  const [project, setProject] = useState({
    title: '',
    value: '',
    description: '',
    images: [],
    pdfs: [],
    closingDate: '',
    startDate: ''
  });
  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const projectData = await fetchProjectById(projectId);
        // console.log("projectData", projectData);
        if (projectData) {
          setProject({
            title: projectData.title,
            value: projectData.value,
            description: projectData.description,
            images: projectData.imagesDocumentsKeys || [],
            pdfs: projectData.otherDocumentsKeys || [],
            startDate: projectData.startDate,
            closingDate: projectData.closingDate
          });

          // Calculate progress value
          const currentDate = new Date();
          const startDate = new Date(projectData.startDate);
          const closingDate = new Date(projectData.closingDate);
          const projectDuration = closingDate.getTime() - startDate.getTime();
          const timeElapsed = currentDate.getTime() - startDate.getTime();
          const progressValue = (timeElapsed / projectDuration) * 100;
          setProgressValue(progressValue);

        }
      } catch (error) {
        utils.sendMessageToBugNinja("Error fetching project data:", error.message, error)
      }
    };

    fetchProjectData();
  }, [projectId]);

  return (
    <Card maxW='full' mb={8} marginBottom={8}>
      <CardBody>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          justify="space-between"
          w="100%"
          p={4}
        >
          <Box
            flex={{ base: '100%', md: '50%' }}
            bg="gray.200"
            p={4}
            m={2}
            borderRadius="md"
            boxShadow="md"
          >
            <ImageCarousel images={project.images}/>
          </Box>
          <Box
            flex={{ base: '100%', md: '50%' }}
            bg="white"
            p={4}
            m={2}
            borderRadius="md"
            align="start"
            width={{ base: '100%', md: '45%' }}
          >
            <Tabs isManual variant='enclosed'>
              <TabList>
                <Tab>Overview</Tab>
                <Tab>Documents</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Flex spacing='4'>
                    <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap' mb={4}>
                      {/* <Avatar name='Segun Adebayo' src='https://bit.ly/sage-adebayo' /> */}
                      <Box>
                        <Heading size='sm'>Title: {project.title}</Heading>
                        <Text>
                          Start Date:
                          <Badge ml='1' colorScheme='green'>
                            {project.startDate}
                          </Badge>,
                          Closing Date:
                          <Badge ml='1' colorScheme='green'>
                            {project.closingDate}
                          </Badge>
                        </Text>
                      </Box>
                    </Flex>
                    <IconButton
                      variant='ghost'
                      colorScheme='gray'
                      aria-label='See menu' />
                  </Flex>
                  <Progress hasStripe value={progressValue || 0} />
                  <Heading size='sm' mt={4}>Description:</Heading>
                  <Text ms={4}>
                    <div dangerouslySetInnerHTML={{ __html: project.description }} />
                  </Text>
                </TabPanel>
                <TabPanel>
                  <ProjectDocumentList pdfs={project.pdfs} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default ProjectView;
