import { API1 } from "../../utils/api";
import utils from "../../utils/commonFunctions";

// #################################
//          TENDER API FUNCTIONS
// #################################

// Fetch All Events
export const fetchAllTenders = async (pageNumber = 1, pageSize = 10, filterOptions = {}) => {
    try {
        const queryParams = new URLSearchParams({
            ...filterOptions,
            pageNumber,
            pageSize
        }).toString();

        const response = await API1.get(`/tenders?${queryParams}`);
        // console.log("response data");
        // console.table(response.data);
        if (response.status === 200) {
            const { docs: tenders, totalDocs } = response.data;
            return {
                tenders: response.data.docs,
                totalPages: Math.ceil(totalDocs / pageSize),
            };
        } else {
            throw new Error('Unexpected response from the server while fetching tenders.');
        }
    } catch (error) {
        utils.sendMessageToBugNinja("Error fetching tenders data:", error.message, error)
        throw new Error('Failed to fetch tenders. Please try again.');
    }
};

// addTender API
export const addTender = async (tenderData) => {
    try {
        console.table("tenderData", tenderData);
        const response = await API1.post(`/tenders`, tenderData);
        if (response.status === 201) {
            return response.data;
        } else {
            throw new Error('Unexpected response from the server while adding tender.');
        }
    } catch (error) {
        utils.sendMessageToBugNinja("Error while adding tender data:", error.message, error)
        throw new Error('Failed to add tender. Please try again.');
    }
};

// fetch tenders by id
export const fetchTenderById = async (tenderId) => {
    try {
        const response = await API1.get(`/tenders/${tenderId}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Unexpected response from the server while fetching tender.');
        }
    } catch (error) {
        utils.sendMessageToBugNinja("Error fetching tenders data by id:", error.message, error)
        throw new Error('Failed to fetch tender. Please try again.');
    }
};

// fetch tender purchases by id
export const fetchPurchasesTenderById = async (tenderId) => {
    try {
        const response = await API1.get(`/payment/tender/${tenderId}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Unexpected response from the server while fetching tender.');
        }
    } catch (error) {
        utils.sendMessageToBugNinja("Error fetching tenders purchase list data by id:", error.message, error)
        throw new Error('Failed to fetch tender purchase list. Please try again.');
    }
};

// update tender API
export const updateTender = async (tenderId, tenderData) => {
    try {
        console.log("tenderData", tenderData);
        console.log("tenderId", tenderId);
        const response = await API1.put(`/tenders/${tenderId}`, tenderData);

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Unexpected response from the server while updating tender.');
        }
    } catch (error) {
        utils.sendMessageToBugNinja("Error occured while updating tenders data:", error.message, error)
        throw new Error('Failed to update tender. Please try again.');
    }
};

// delete tender by ID API
export const deleteTender = async (tenderId) => {
    try {
        const response = await API1.delete(`/tenders/${tenderId}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Unexpected response from the server while deleting tender.');
        }
    } catch (error) {
        utils.sendMessageToBugNinja("Error occured while deleting tenders data:", error.message, error)
        throw new Error('Failed to delete tender. Please try again.');
    }
};
