import React from "react";
import {
  SimpleGrid,
  Image,
  Box,
  Center,
  VStack,
  Text
} from '@chakra-ui/react';
import emptyFiles from '../../../assests/images/folderEmpty.png';
import pdfFileImage from '../../../assests/images/pdfFile.png';
import PdfViewerModal from "../../../components/PdfViewer/PdfViewerModal";
import { useDisclosure } from "@chakra-ui/react";

const ProjectDocumentList = ({ pdfs }) => {

  const { isOpen, onOpen, onClose }=useDisclosure()
  const [ filePath, setFilePath ]=React.useState('')

  const handlePdfClick = (pdf) => {
    if(pdf){
      setFilePath(pdf)
      onOpen()
    }
  };

  return (
    <>
      <SimpleGrid columns={2} spacing={10}>
        {pdfs.map((pdf, index) => (
          <Box key={index} 
                position="relative"
                mr="4" bg={'gray.100'} 
                p={3} shadow={'md'} 
                rounded={'md'}
                cursor="pointer"
                onClick={() => handlePdfClick(pdf)}
                >
            <Center>
              <Image src={pdfFileImage} alt={`pdf-file ${index + 1}`} />
            </Center>
          </Box>
        ))}
      </SimpleGrid>
      {pdfs.length === 0 && (
        <VStack spacing={4} align="stretch">
          <Center>
            <Image
              objectFit="fit"
              src={emptyFiles}
              alt={`no documents found`}
              borderRadius="md"
              height={{ base: '150px', md: '150px' }}
            />
          </Center>
          <Box>
            <Text fontSize="lg" fontWeight="bold" align="center" color="gray.500">
              No Documents
            </Text>
          </Box>
        </VStack>
      )}

      <PdfViewerModal filePath={filePath} isOpen={isOpen} onOpen={onOpen} onClose={onClose}/>
    </>
  );
};

export default ProjectDocumentList;
