// EventView.js
import React from 'react';
import {
  Box, Text,
  Card,
  CardBody,
  Flex,
  Heading,
  IconButton,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Icon, Badge, Progress,
  Image
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import DefaultTemplate from "../../../components/DefaultTemplate/DefaultTemplate";
import TenderDocumentList from './TenderDocumentList';
import { useState, useEffect } from 'react';
import { fetchTenderById } from "../tenderAPI";
import tenderImage from '../../../assests/images/planList.png';
import utils from '../../../utils/commonFunctions';

const TenderView = () => {
  const { tenderId, title } = useLocation().state;

  return (
    <>
      <DefaultTemplate
        Content={<ViewContainer tenderId={tenderId} />}
        Title={"View Tender"}
        icon={<Icon icon="fa:briefcase" />}
      />
    </>
  );
};

const ViewContainer = ({ tenderId }) => {

  const [tender, setTender] = useState({});

  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    const fetchTenderData = async () => {
      try {
        const tenderData = await fetchTenderById(tenderId);
        console.log("tenderData", tenderData);
        setTender(tenderData);

        // Calculate progress value
        const currentDate = new Date();
        const startDate = new Date(tenderData.startDate);
        const closingDate = new Date(tenderData.closingDate);
        const tenderDuration = closingDate.getTime() - startDate.getTime();
        const timeElapsed = currentDate.getTime() - startDate.getTime();
        const progressValue = (timeElapsed / tenderDuration) * 100;
        setProgressValue(progressValue);

      } catch (error) {
        console.error("Error fetching tender data:", error);
      }
    };

    fetchTenderData();
  }, [tenderId]);

  return (
    <Card maxW='full' mb={8} marginBottom={8}>
      <CardBody>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          justify="space-between"
          w="100%"
          p={4}
        >
          <Box
            flex={{ base: '100%', md: '30%' }}
            bg="gray.200"
            p={4}
            m={2}
            borderRadius="md"
            boxShadow="md"
          >
            <Image
              src={tenderImage}
              alt="tender image"
            />
          </Box>
          <Box
            flex={{ base: '100%', md: '70%' }}
            bg="white"
            p={4}
            m={2}
            borderRadius="md"
            align="start"
            width={{ base: '100%', md: '45%' }}
          >
            <Tabs isManual variant='enclosed'>
              <TabList>
                <Tab>Overview</Tab>
                <Tab>Documents</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Flex spacing='4'>
                    <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap' mb={4}>
                      <Box>
                        <Heading size='lg' mb={4}>Title: {tender.title}</Heading>
                        <Text>
                          Start Date:
                          <Badge ml='1' colorScheme='green'>
                            {tender.startDate}
                          </Badge>,
                          Closing Date:
                          <Badge ml='1' colorScheme='green'>
                            {tender.closingDate}
                          </Badge>
                        </Text>
                      </Box>
                    </Flex>
                    <IconButton
                      variant='ghost'
                      colorScheme='gray'
                      aria-label='See menu' />
                  </Flex>
                  <Progress hasStripe value={progressValue || 0} color={progressValue == 100 ? 'red' : 'blue'}/>
                  <Heading size='sm' mt={4}>Description:</Heading>
                  <Text ms={4}>
                    <div dangerouslySetInnerHTML={{ __html: tender.description }} />
                  </Text>
                </TabPanel>
                <TabPanel>
                  <TenderDocumentList tender={tender} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default TenderView;
