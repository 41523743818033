import React from 'react'
import DefaultTemplate from '../../../components/DefaultTemplate/DefaultTemplate'
import CityUserContainer from './Components/CityContainer'
import {Icon} from "@iconify/react";

const Cities = () => {
    return (
        <>
            <DefaultTemplate Content={ <CityUserContainer/> } Title={"Cities Management" } icon={<Icon icon="game-icons:modern-city" />}/>
        </>
    )
}

export default Cities
