import React from 'react'
import DefaultTemplate from '../../../components/DefaultTemplate/DefaultTemplate'
import CityUserContainer from './Components/NoticeContainer'
import {Icon} from "@iconify/react";

const Notices = () => {
    return (
        <>
            <DefaultTemplate Content={ <CityUserContainer/> } Title={"Notice Management" } icon={<Icon icon="octicon:bell-fill-24" />}/>
        </>
    )
}

export default Notices
