// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-quill .DraftEditor-root {
    background-color: white;
    color: black;
    font-size: 16px;
    height: 300px;
    margin-bottom: 30px;
    padding: 10px;
    border-radius: 4px;
}

.custom-quill .public-DraftEditorPlaceholder-root,
.custom-quill .public-DraftEditor-content {
    margin: 0 -10px -10px;
    padding: 10px;
}
.custom-quill .ql-container {
    border: none;
    border-radius: 5px;
}

.custom-quill .ql-toolbar {
    border-color: #E2E8F0;
    border: none;
    border-bottom: 2px solid #E2E8F0 !important;
}

.custom-quill .ql-editor {
    border-color: #E2E8F0;
}
`, "",{"version":3,"sources":["webpack://./src/css/TextEditor.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,YAAY;IACZ,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,kBAAkB;AACtB;;AAEA;;IAEI,qBAAqB;IACrB,aAAa;AACjB;AACA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,YAAY;IACZ,2CAA2C;AAC/C;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".custom-quill .DraftEditor-root {\n    background-color: white;\n    color: black;\n    font-size: 16px;\n    height: 300px;\n    margin-bottom: 30px;\n    padding: 10px;\n    border-radius: 4px;\n}\n\n.custom-quill .public-DraftEditorPlaceholder-root,\n.custom-quill .public-DraftEditor-content {\n    margin: 0 -10px -10px;\n    padding: 10px;\n}\n.custom-quill .ql-container {\n    border: none;\n    border-radius: 5px;\n}\n\n.custom-quill .ql-toolbar {\n    border-color: #E2E8F0;\n    border: none;\n    border-bottom: 2px solid #E2E8F0 !important;\n}\n\n.custom-quill .ql-editor {\n    border-color: #E2E8F0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
