import React, { useEffect, useState } from 'react';
import {
    VStack, Box, Text, Stack, Flex, SimpleGrid, Heading, Badge, Td, Grid, Table, Thead, Tr, Th, GridItem, Tbody
} from '@chakra-ui/react';
import FormActionLabel from "../../../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../../../components/Styled/StyledButtons";
import { useDrawer } from "../../../../../Context/DrawerContext/DrawerContext";
import {Icon} from "@iconify/react";
import {fetchLabQuotationRequestById} from "../../LabQuotationRequestsAPI";
import SectionHeading from "../../../../../components/SectionHeading/SectionHeading";
import getStatusColor from "../LabRequestStatusColors";
import utils from "../../../../../utils/commonFunctions";
import {TableContainer} from "../../../../../components/Styled/StyledComponents";



const LabQuotationRequestViewForm = ({ formData }) => {
    const { closeDrawer } = useDrawer();
    const [labQuotationRequestData, setLabQuotationRequestData] = useState({
        zQuotationHeader: {},
        gtPartners: { item: [] },
        gtServiceItems: { item: [] }
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const labQuotationRequest = await fetchLabQuotationRequestById(formData._id);
                setLabQuotationRequestData(labQuotationRequest);
            } catch (error) {
                console.log('Error fetching lab Quotation Request details:', error);
            }
        };

        fetchData();
    }, [formData]);

    return (
        <>
            <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                <FormActionLabel formAction="view" formName="Lab Quotation Request"/>
                <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={4}>
                    <Box>
                            <SectionHeading text="Quotation Header Information" icon="fluent:number-symbol-square-20-filled" />
                            <SimpleGrid columns={1} gap={4} px={6} py={4}>
                                {Object.entries(labQuotationRequestData.zQuotationHeader).map(([key, value]) => (
                                    <Text key={key}><strong>{key.replace(/([A-Z])/g, ' $1').trim()}:</strong> {value}</Text>
                                ))}
                                <Text><strong>Status:</strong>
                                    <Badge ml='1' colorScheme={getStatusColor(formData.status)} variant="subtle">
                                        {formData.status}
                                    </Badge>
                                </Text>
                                <Text><strong>Created At:</strong> {`${utils.formatDate(formData.createdAt)}`}</Text>
                                <Text><strong>Updated At:</strong> {`${utils.formatDate(formData.updatedAt)}`}</Text>
                            </SimpleGrid>
                    </Box>
                    <Box>
                        <SectionHeading text="Partner Details" icon="fluent-mdl2:test-user-solid" />
                        {labQuotationRequestData.gtPartners.item.map((partner, index) => (
                            <SimpleGrid key={index} columns={1} gap={4} px={6} py={4}>
                                <Text><strong>Partner Number:</strong> {partner.pNumber}</Text>
                                <Text><strong>Name:</strong> {partner.name}</Text>
                                <Text><strong>Street:</strong> {partner.street}</Text>
                                <Text><strong>City:</strong> {partner.city}</Text>
                                <Text><strong>Postal Code:</strong> {partner.postalCode}</Text>
                                <Text><strong>Email Address:</strong> {partner.emailAddress}</Text>
                            </SimpleGrid>
                        ))}
                    </Box>



                </Grid>

                <Box>
                    <SectionHeading text="Service Items" icon="fluent:apps-list-detail-24-filled" />

                        <Table>
                            <Thead>
                                <Tr>
                                    <Th>Material Number</Th>
                                    <Th>Material</Th>
                                    <Th>Quantity</Th>
                                    <Th>Price</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {labQuotationRequestData.gtServiceItems.item.map((item, index) => (
                                    <Tr key={index} columns={1} gap={4} px={6} py={4}>
                                        <Td> {item.number}</Td>
                                        <Td> {item.material}</Td>
                                        <Td> {item.quantity}</Td>
                                        <Td> {item.price||0.00}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>


                </Box>
                <Box colSpan={2} flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }} mt={8} mb={8}>
                    <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                        <CustomButton type="cancel" onClick={closeDrawer} showIcon={false}>
                            Close
                        </CustomButton>
                    </ButtonStack>
                </Box>
            </VStack>
        </>
    );
};

export default LabQuotationRequestViewForm;
