import React, {useEffect, useRef, useState} from 'react';
import { Formik, Form, Field } from 'formik';
import { FormControl, Stack, Select } from '@chakra-ui/react';
import * as Yup from 'yup';
import { CustomButton } from "../../../../components/Styled/StyledButtons";
import { Icon } from "@iconify/react";
import FieldErrorMessage from "../../../../components/FieldErrorMessage/FieldErrorMessage";
import {ThemedStyledInput} from "../../../../components/Styled";
import { fetchAllAreas } from "../../../LocationNetwork/Consultants/ConsultantsAPI";
import { fetchAllCities } from "../../../LocationNetwork/Areas/AreasAPI";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";

const validationSchema = Yup.object({
    title: Yup.string()
        .max(30, 'Search & Filters Error: First Name Must be 30 characters or less')
        .trim('Search & Filters Error: First Name cannot have whitespace')
        .strict(true)
});

const NoticesSearchFilter = ({ onFilterChange }) => {
    const showToast = useSingleToast();
    const showToastRef = useRef(showToast);
    const [areas, setAreas] = useState([]);
    const [cities, setCities] = useState([]);



    useEffect(() => {
        fetchAllAreas().then(data => {
            setAreas(data.areas);
        }).catch(error => {
            showToastRef.current({
                title: "Error fetching areas",
                description: "Failed to fetch areas.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        });
        fetchAllCities().then(data => {
            setCities(data.cities);
        }).catch(error => {
            showToastRef.current({
                title: "Error fetching cities",
                description: "Failed to fetch cities.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        });
    }, []);

    return (
        <Formik
            initialValues={{ title: '', city: '', area: '' }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                if (!values.city && !values.title && !values.lastName && !values.area) {
                    showToast({
                        title: "No Parameters Supplied",
                        description: "Please enter search criteria.",
                        status: "warning",
                        duration: 5000,
                        isClosable: true,
                    });
                } else {
                    onFilterChange(values);
                }
                setSubmitting(false);
            }}
            onReset={() => {
                onFilterChange({ title: '', city: '', area: '' });
            }}
        >
            {({ handleSubmit, resetForm }) => (
                <Form>
                    <Stack spacing={2} direction="row" align="center">
                        <FormControl>
                            <Field as={ThemedStyledInput} id="title" name="title" type="text" placeholder="Search by Title" />
                        </FormControl>
                        <FormControl>
                            <Field as={Select} id="city" name="city" placeholder=" -- Select City --">
                                {cities.map(city => (
                                    <option key={city._id} value={city._id}>{city.name}</option>
                                ))}
                            </Field>
                        </FormControl>
                        <FormControl>
                            <Field as={Select} id="area" name="area" placeholder=" -- Select Area --">
                                {areas.map(area => (
                                    <option key={area._id} value={area._id}>{area.name}</option>
                                ))}
                            </Field>
                        </FormControl>
                        <CustomButton onClick={handleSubmit} type="search" tooltipLabel="Search" icon={<Icon icon="bi:search" />} showLabel={false}>
                            Search
                        </CustomButton>
                        <CustomButton
                            type="reset"
                            onClick={() => {
                                resetForm();
                                onFilterChange({ title: '', city: '', area: '' });
                            }}
                            tooltipLabel="Clear Filters"
                            icon={<Icon icon="radix-icons:reset" />}
                            showLabel={false}
                        >
                            Clear
                        </CustomButton>
                    </Stack>
                    <FieldErrorMessage name="title" />
                </Form>
            )}
        </Formik>
    );
};

export default NoticesSearchFilter;
