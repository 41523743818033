import React, { useEffect, useState, useCallback } from 'react';
import BannerTable from './BannerTable';
import BannerTableTopBar from './BannerTableTopBar';
import { fetchAllBanners } from '../BannerAPI';

const BannerContainer = () => {
    const [banners, setBanners] = useState([]);
    const [error, setError] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const refetchBannersData = useCallback(async (filter={}, fetchAll = false) => {
        setDataLoaded(false);
        try {
            const data = await fetchAllBanners(pageNumber, 10, filter, fetchAll);
            setBanners(data.docs);
            setTotalPages(data.totalPages);
            setError('');
            setDataLoaded(true);
        } catch (error) {
            setError('Failed to fetch banners data.');
            setBanners([]);
            setTotalPages(0);
        }
    }, [pageNumber]);

    useEffect(() => {
        refetchBannersData();
    }, [pageNumber, refreshFlag, refetchBannersData]);

    return (
        <>
            <BannerTableTopBar refetchBannersData={refetchBannersData} />
            <BannerTable
                banners={banners}
                setBanners={setBanners}
                setRefreshFlag={setRefreshFlag}
                refetchBannersData={refetchBannersData}
                error={error}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                dataLoaded={dataLoaded}
            />
        </>
    );
};

export default BannerContainer;
