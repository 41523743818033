import React, {useEffect, useRef, useState} from 'react';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    Box,
    Text, SimpleGrid, Spinner
} from '@chakra-ui/react';
import {ThemedStyledInput, ThemedStyledSelectInput} from "../../../../components/Styled";
import FormActionLabel from "../../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../../components/Styled/StyledButtons";
import { useDrawer } from '../../../../Context/DrawerContext/DrawerContext';
import {addDisconnection} from "../DisconnectionAPI";
import { Formik, Form} from 'formik';
import * as Yup from 'yup';
import ConfirmDialog from "../../../../Context/ConfirmDialog/ConfirmDialog";
import {fetchAllAreas, fetchAllCities} from "../../../LocationNetwork/Areas/AreasAPI";
import SectionHeading from "../../../../components/SectionHeading/SectionHeading";
import {API1} from "../../../../utils/api";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";

const DisconnectionAddForm = ({ refetchDisconnectionData, filterOptions }) => {
    const { closeDrawer } = useDrawer();
    const [cities, setCities] = useState([]);
    const [wards, setWards] = useState([]);
    const [contractNumbers, setContractNumbers] = useState([]);
    const [loadingCities, setLoadingCities] = useState(false);
    const [loadingWards, setLoadingWards] = useState(false);
    const showToast = useSingleToast();
    const showToastRef = useRef(showToast);
    const [customerNumber, setCustomerNumber] = useState('');
    const [shouldFetchContracts, setShouldFetchContracts] = useState(false);


    useEffect(() => {
        const fetchCitiesAndWards = async () => {
            setLoadingCities(true);
            setLoadingWards(true);
            try {
                const citiesData = await fetchAllCities();
                const wardsData = await fetchAllAreas();
                setCities(citiesData.cities);
                setWards(wardsData.areas);
            } catch (error) {
                showToast({
                    title: 'Error fetching data',
                    description: error.message || 'Could not fetch cities and areas.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } finally {
                setLoadingCities(false);
                setLoadingWards(false);
            }
        };

        fetchCitiesAndWards();
    }, []);

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    const disconnectionSchema = Yup.object().shape({
        userId: Yup.string().required('User ID is required.'),
        profileId: Yup.string().required('Profile ID is required.'),
        customerNumber: Yup.string().required('Customer number is required.'),
        contractNumber: Yup.string().required('Contract number is required.'),
        personalDetails: Yup.object({
            firstName: Yup.string().required('First name is required.'),
            middleName: Yup.string(),
            lastName: Yup.string().required('Last name is required.'),
        }),
        address: Yup.object({
            lotNo: Yup.string(),
            street: Yup.string().required('Street is required.'),
            ward: Yup.string(),
            city: Yup.string().required('City is required.'),
            district: Yup.string().required('District is required.'),
        }),
        landLordDetails: Yup.object({
            name: Yup.string().required('Landlord name is required.'),
            identityNo: Yup.string(),
            identityType: Yup.string(),
            cell: Yup.string().required('Cell phone number is required.'),
            email: Yup.string().email('Invalid email address'),
        }),
        bankingDetails: Yup.object({
            name: Yup.string().required('Bank name is required.'),
            branch: Yup.string().required('Branch name is required.'),
            branchCode: Yup.string().required('Branch code is required.'),
            accountNumber: Yup.string().required('Account number is required.'),
        }),
        status: Yup.string().required('Status is required.'),
        consent: Yup.boolean(),
        dateOfConsent: Yup.date(),
    });


    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            await addDisconnection(values);
            showToastRef.current({
                title: 'Disconnection added successfully',
                description: `Temporary password that has been sent: ${values.password}`,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            resetForm();
            refetchDisconnectionData(filterOptions); // Trigger refetching of disconnection data
            closeDrawer();
        } catch (error) {
            if (error.response) {
                // Server error with response
                showToast({
                    title: 'Error adding disconnection',
                    description: error.response.data.message || 'An unexpected server error occurred',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } else if (error.request) {
                // Network error without response
                showToast({
                    title: 'Network Error',
                    description: 'Unable to connect to the server. Please check your internet connection.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                // Other errors
                showToast({
                    title: 'Error',
                    description: error.message || 'An unexpected error occurred',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } finally {
            setSubmitting(false);
        }
    };


    useEffect(() => {
        if (shouldFetchContracts && customerNumber) {
            async function fetchContractNumbers() {
                try {
                    const response = await API1.get(`/utility-contracts/customer/${customerNumber}`);
                    const data = response.data.utilityContracts;
                    const newContractNumbers = data.map(contract => ({
                        label: contract.utilityContractNumber,
                        value: contract.utilityContractNumber
                    }));
                    setContractNumbers(newContractNumbers);
                } catch (error) {
                    showToast({
                        title: 'Error fetching contracts',
                        description: error.response?.data?.message || error.message,
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                } finally {
                    setShouldFetchContracts(false);
                }
            }

            fetchContractNumbers();
        }
    }, [customerNumber, shouldFetchContracts, showToast]);

    const handleCustomerNumberChange = (setFieldValue) => (event) => {
        const newCustomerNumber = event.target.value;
        setCustomerNumber(newCustomerNumber);
        setFieldValue('customerNumber', newCustomerNumber);
        setShouldFetchContracts(true);
    };


    const requestConfirm = (options) => {
        setConfirmDialog({
            isOpen: true,
            title: options.title,
            message: options.message,
            onConfirm: options.onConfirm,
        });
    };

    const handleConfirm = () => {
        confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    return (
        <>
            <Formik
                initialValues={{
                    userId: '',
                    profileId: '',
                    customerNumber: '',
                    contractNumber: '',
                    personalDetails: {
                        firstName: '',
                        middleName: '',
                        lastName: '',
                    },
                    address: {
                        lotNo: '',
                        street: '',
                        ward: '',
                        city: '',
                        district: '',
                    },
                    landLordDetails: {
                        name: '',
                        identityNo: '',
                        identityType: '',
                        cell: '',
                        email: '',
                    },
                    bankingDetails: {
                        name: '',
                        branch: '',
                        branchCode: '',
                        accountNumber: '',
                    },
                    status: '',
                    consent: true,
                    dateOfConsent: new Date(),
                }}
                validationSchema={disconnectionSchema}
                onSubmit={handleSubmit}
            >
                {formikProps => (
                    <Form>
                        <VStack spacing={4} align="stretch">
                            <FormActionLabel formAction="add" formName="Disconnection"/>

                            <SectionHeading icon="mdi:account-plus" text="Customer Number and Utility Contract Number" />
                            <SimpleGrid columns={2} gap={4} px={2} py={4}>
                                <FieldControl
                                    formikProps={formikProps}
                                    name="customerNumber"
                                    label="Customer Number"
                                    placeholder="Enter Customer Number"
                                    onChange={(event) => handleCustomerNumberChange(event, formikProps.setFieldValue)}
                                    setCustomerNumber={setCustomerNumber}
                                />

                                {/*{contractNumbers.length > 0 && (
                                    <DropdownFieldControl
                                        formikProps={formikProps}
                                        name="contractNumber"
                                        label="Contract Number"
                                        options={contractNumbers}
                                    />
                                )}*/}
                                <DropdownFieldControl
                                    formikProps={formikProps}
                                    name="contractNumber"
                                    label="Contract Number"
                                    options={contractNumbers}
                                />
                            </SimpleGrid>

                            <SectionHeading icon="mdi:account-plus" text="Personal Details" />
                            <SimpleGrid columns={2} gap={4} px={2} py={4}>
                                <FieldControl formikProps={formikProps} name="personalDetails.firstName" label="First Name" placeholder="Enter First Name" />
                                <FieldControl formikProps={formikProps} name="personalDetails.middleName" label="Middle Name" placeholder="Enter Middle Name" />
                                <FieldControl formikProps={formikProps} name="personalDetails.lastName" label="Last Name" placeholder="Enter Last Name" />
                            </SimpleGrid>

                            <SectionHeading icon="mdi:home-city-outline" text="Address Details" />
                            <SimpleGrid columns={2} gap={4} px={2} py={4}>
                                <FieldControl formikProps={formikProps} name="address.lotNo" label="Plot No" placeholder="Enter Plot No." />
                                <FieldControl formikProps={formikProps} name="address.street" label="Street" placeholder="Enter Street Name" />
                                <DropdownFieldControl
                                    formikProps={formikProps}
                                    name="ward"
                                    label="Ward"
                                    options={wards.map((ward) => ({ value: ward.name, label: ward.name }))}
                                />
                                <DropdownFieldControl
                                    formikProps={formikProps}
                                    name="city"
                                    label="City"
                                    options={cities.map((city) => ({ value: city.name, label: city.name }))}
                                />
                                <FieldControl formikProps={formikProps} name="address.district" label="District" placeholder="Enter District" />
                            </SimpleGrid>

                            <SectionHeading icon="mdi:account-cash-outline" text="Landlord Details" />
                            <SimpleGrid columns={2} gap={4} px={2} py={4}>
                                <FieldControl formikProps={formikProps} name="landLordDetails.name" label="Landlord Name" placeholder="Enter Landlord's Name" />
                                <FieldControl formikProps={formikProps} name="landLordDetails.identityNo" label="Identity No" placeholder="Enter Identity Number" />
                                <FieldControl formikProps={formikProps} name="landLordDetails.identityType" label="Identity Type" placeholder="Enter Identity Type" />
                                <FieldControl formikProps={formikProps} name="landLordDetails.cell" label="Cell Phone" placeholder="Enter Cell Phone Number" />
                                <FieldControl formikProps={formikProps} name="landLordDetails.email" label="Email" placeholder="Enter Email" />
                            </SimpleGrid>

                            <SectionHeading icon="mdi:bank-outline" text="Banking Details" />
                            <SimpleGrid columns={2} gap={4} px={2} py={4}>
                                <FieldControl formikProps={formikProps} name="bankingDetails.name" label="Bank Name" placeholder="Enter Bank Name" />
                                <FieldControl formikProps={formikProps} name="bankingDetails.branch" label="Branch" placeholder="Enter Branch Name" />
                                <FieldControl formikProps={formikProps} name="bankingDetails.branchCode" label="Branch Code" placeholder="Enter Branch Code" />
                                <FieldControl formikProps={formikProps} name="bankingDetails.accountNumber" label="Account Number" placeholder="Enter Account Number" />
                            </SimpleGrid>

                            <AlertAndButtons
                                formikProps={formikProps}
                                closeDrawer={closeDrawer}
                                requestConfirm={requestConfirm} />
                        </VStack>
                    </Form>
                    )}
            </Formik>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};



const FieldControl = ({ formikProps, name, label, placeholder, setCustomerNumber }) => {
    const onChange = (event) => {
        if (name === 'customerNumber') {
            setCustomerNumber(event.target.value);
        }
        formikProps.handleChange(event);
    };

    return (
        <GridItem>
            <FormControl isInvalid={formikProps.errors[name] && formikProps.touched[name]}>
                <FormLabel htmlFor={name}>{label}</FormLabel>
                <ThemedStyledInput
                    {...formikProps.getFieldProps(name)}
                    id={name}
                    placeholder={placeholder}
                    onChange={onChange}
                />
                {formikProps.errors[name] && formikProps.touched[name] && (
                    <Text color="red.500" fontSize="sm">{formikProps.errors[name]}</Text>
                )}
            </FormControl>
        </GridItem>
    );
};




const AlertAndButtons = ({ formikProps, closeDrawer, requestConfirm }) => {

    const handleCancel = () => {
        requestConfirm({
            title: "Cancel Confirmation",
            message: "Are you sure you want to cancel? Any unsaved changes will be lost.",
            onConfirm: () => {
                formikProps.handleReset();
                closeDrawer();
            }
        });
    };

    const handleReset = () => {
        requestConfirm({
            title: "Reset Confirmation",
            message: "Are you sure you want to reset all your changes?",
            onConfirm: () => {
                formikProps.handleReset();
            }
        });
    };

    return (
        <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}>
            <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
            <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                    Cancel
                </CustomButton>
                <CustomButton onClick={handleReset} type="reset">
                    Reset
                </CustomButton>
                <CustomButton
                    type="submit"
                    disabled={formikProps.isSubmitting}
                    style={{
                        opacity: formikProps.isSubmitting ? 0.7 : 1,
                        pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                    }}
                >
                    {formikProps.isSubmitting ? (
                        <>
                            <Spinner size="xs" mr={2} />
                            Submitting...
                        </>
                    ) : 'Submit'}
                </CustomButton>
            </ButtonStack>
        </Box>
    );
};

const DropdownFieldControl = ({ formikProps, name, label, options }) => (
    <GridItem>
        <FormControl isInvalid={formikProps.errors[name] && formikProps.touched[name]}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <ThemedStyledSelectInput
                {...formikProps.getFieldProps(name)}
                id={name}
                placeholder={`Select ${label.toLowerCase()}`}
            >
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </ThemedStyledSelectInput>
            {formikProps.errors[name] && formikProps.touched[name] && (
                <Text color="red.500" fontSize="sm">{formikProps.errors[name]}</Text>
            )}
        </FormControl>
    </GridItem>
);

export default DisconnectionAddForm;
