import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { FormControl, Stack } from '@chakra-ui/react';
import { ThemedStyledInput } from '../../../../components/Styled';
import * as Yup from 'yup';
import { CustomButton } from "../../../../components/Styled/StyledButtons";
import { Icon } from "@iconify/react";

import { fetchAllShortageReports} from "../ShortagesAPI";
import FieldErrorMessage from "../../../../components/FieldErrorMessage/FieldErrorMessage";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";

const validationSchema = Yup.object({
    name: Yup.string()
        .max(30, 'Search & Filters Error: Name Must be 30 characters or less')
        .trim('Search & Filters Error: Name cannot be just whitespace')
        .strict(true)
});

const ShortageReportSearchFilter = ({ onFilterChange }) => {
    const showToast = useSingleToast();
    const showToastRef = useRef(showToast);
    const [cities, setCities] = useState([]);

    useEffect(() => {
        fetchAllShortageReports().then(data => {
            setCities(data.cities);
        }).catch(error => {
            showToastRef.current({
                title: "Error",
                description: "Failed to fetch cities.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        });
    }, []);

    return (
        <Formik
            initialValues={{ name: '', email: '', dateCreated: '', ticket: '' }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                onFilterChange({ ...values });
                setSubmitting(false);
            }}
            onReset={() => {
                onFilterChange({ name: '', email: '', dateCreated: '', ticket: '' });
            }}
        >
            {({ handleSubmit, resetForm, setFieldValue }) => (
                <Form>
                    <Stack spacing={2} direction="row" align="center">
                        <FormControl>
                            <Field as={ThemedStyledInput} id="ticket" name="ticket" type="text" placeholder="Ticket Number" />
                        </FormControl>
                        <FormControl>
                            <Field as={ThemedStyledInput} id="name" name="name" type="text" placeholder="Reporter Name" />
                        </FormControl>
                        <FormControl>
                            <Field as={ThemedStyledInput} id="email" name="email" type="email" placeholder="Email" />
                        </FormControl>
                        <FormControl>
                            <Field as={ThemedStyledInput} id="dateCreated" name="dateCreated" type="date" placeholder="Date Reported" />
                        </FormControl>

                        <CustomButton
                            onClick={handleSubmit}
                            type="search"
                            tooltipLabel="Search"
                            icon={<Icon icon="bi:search" />}
                            showLabel={false}
                        >
                            Search
                        </CustomButton>
                        <CustomButton
                            type="reset"
                            onClick={() => {
                                resetForm();
                                setFieldValue('ticket', '', false);
                                setFieldValue('name', '', false);
                                setFieldValue('email', '', false);
                                setFieldValue('dateCreated', '', false);
                                onFilterChange({ name: '', email: '', dateCreated: '', ticket: '' });
                            }}
                            tooltipLabel="Clear Filters"
                            icon={<Icon icon="radix-icons:reset" />}
                            showLabel={false}
                        >
                            Clear
                        </CustomButton>
                    </Stack>

                    <FieldErrorMessage name="name" />
                    <FieldErrorMessage name="email" />
                </Form>
            )}
        </Formik>
    );
};

export default ShortageReportSearchFilter;
