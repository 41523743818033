import React, {useEffect, useState} from 'react';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    Box,
    Text,
    SimpleGrid, Radio, HStack, RadioGroup, Select, Stack, Grid, Checkbox, Spinner, Badge,
} from '@chakra-ui/react';
import { Formik, Form} from 'formik';
import * as Yup from 'yup';
import { useDrawer } from '../../../../Context/DrawerContext/DrawerContext';
import {fetchUserProfileById, updateUserProfile} from "../CustomerAPI";
import ConfirmDialog from "../../../../Context/ConfirmDialog/ConfirmDialog";
import FormActionLabel from "../../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../../components/Styled/StyledButtons";
import {ThemedStyledInput} from "../../../../components/Styled";
import DropBox from "../../../../components/DropBox/DropBox";
import SectionHeading from "../../../../components/SectionHeading/SectionHeading";
import utils from "../../../../utils/commonFunctions";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";
import {fetchAllCities} from "../../../LocationNetwork/Areas/AreasAPI";
import {fetchAllAreas} from "../../../LocationNetwork/Consultants/ConsultantsAPI";
import FieldErrorMessage from "../../../../components/FieldErrorMessage/FieldErrorMessage";
import {validateCustomerNumber} from "../../../../utils/validateContract";



const CustomerUpdateKYCForm = ({ customer, customerId, fetchCustomers }) => {
    const { closeDrawer } = useDrawer();
    const showToast = useSingleToast();
    const [flag, setFlag] = useState(true);
    const [cities, setCities] = useState([]);
    const [areas, setAreas] = useState([]);
    const [lastSavedStatus, setLastSavedStatus] = useState(customer.status || 'Submitted');
    const [initialStatus, setInitialStatus] = useState(customer.status);
    const [loading, setLoading] = useState({
        idKey: false,
        maritalProofKey: false,
    });
    const [initialValues, setInitialValues] = useState({
        customerNumber: '',
        personalDetails: {
            firstName: '',
            lastName: '',
            middleName: '',
            identityNo: '',
            identityType: '',
            maritalStatus: '',
            maritalType: '',
            sex: '',
            dateOfBirth: ''
        },
        nextOfKin: {
            firstName: '',
            middleName: '',
            lastName: '',
            relationship: '',
            cell: ''
        },
        address: {
            plotNo: '',
            postal: '',
            street: '',
            ward: '',
            city: '',
            district: ''
        },
        contactDetails: {
            homeTel: '',
            workTel: '',
            cell: '',
            email: ''
        },
        files: {
            idKey: { file: null, key: '', originalName: '', error: '', progress: 0 },
            maritalProofKey: { file: null, key: '', originalName: '', error: '', progress: 0 },
        },
        consent: false,
        dateOfConsent: '',
        spouseCustomerNumber: null,
        status: '',
    });
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    const getStatusColor = (status) => {
        switch (status) {
            case 'Approved':
                return 'green';
            case 'Declined':
                return 'red';
            case 'Submitted':
                return 'blue';
            case 'Review':
                return 'yellow';
            default:
                return 'gray';
        }
    };

    const statusTransitions = {
        Submitted: ["Submitted", "Review", "Declined", "Approved"],
        Review: ["Review", "Approved", "Declined"],
        Declined: ["Declined"],
        Approved: ["Approved"]
    };


    const getAllowedTransitions = (currentStatus) => {
        return statusTransitions[currentStatus] || [];
    };

    useEffect(() => {
        setInitialStatus(customer.status);
    }, [customer.status]);

    useEffect(() => {
        const loadData = async () => {
            const cities = await fetchAllCities();
            const areas = await fetchAllAreas();
            setCities(cities.cities);
            setAreas(areas.areas);
        };
        loadData();
    }, []);



    useEffect(() => {
        if (!flag) return;

        const fetchAndSetUserData = async () => {
            try {
                const dateOfConsent = customer.dateOfConsent ? new Date(customer.dateOfConsent).toISOString().split('T')[0] : '';
                setInitialValues({
                    customerNumber: customer.customerNumber || '',
                    personalDetails: {
                        firstName: customer.personalDetails.firstName || '',
                        lastName: customer.personalDetails.lastName || '',
                        middleName: customer.personalDetails.middleName || '',
                        identityNo: customer.personalDetails.identityNo || '',
                        identityType: customer.personalDetails.identityType || '',
                        maritalStatus: customer.personalDetails.maritalStatus || '',
                        maritalType: customer.personalDetails.maritalType || '',
                        sex: customer.personalDetails.sex || '',
                        dateOfBirth: customer.personalDetails.dateOfBirth ? utils.formatDate(customer.personalDetails.dateOfBirth) : ''
                    },
                    nextOfKin: {
                        firstName: customer.nextOfKin.firstName || '',
                        middleName: customer.nextOfKin.middleName || '',
                        lastName: customer.nextOfKin.lastName || '',
                        relationship: customer.nextOfKin.relationship || '',
                        cell: customer.nextOfKin.cell || ''
                    },
                    address: {
                        plotNo: customer.address.plotNo || '',
                        postal: customer.address.postal || '',
                        street: customer.address.street || '',
                        ward: customer.address.ward || '',
                        city: customer.address.city || '',
                        district: customer.address.district || ''
                    },
                    contactDetails: {
                        homeTel: customer.contactDetails.homeTel || '',
                        workTel: customer.contactDetails.workTel || '',
                        cell: customer.contactDetails.cell || '',
                        email: customer.contactDetails.email || ''
                    },
                    files: {
                        idKey: customer.files?.idKey||'',
                        maritalProofKey: customer.files?.maritalProofKey|| ''

                    },
                    consent: customer.consent === true,
                    dateOfConsent: dateOfConsent || '',
                    spouseCustomerNumber: customer.spouseCustomerNumber || '',
                    status: customer.status || 'Submitted',
                });
            } catch (error) {
                showToast.closeAll();
                showToast({
                    title: 'Customer has not yet updated their KYC.',
                    // description: error.message || 'An unexpected error occurred.',
                    status: 'warning',
                    duration: 5000,
                    isClosable: true,
                });
            } finally {
                setFlag(false);
            }
        };

        fetchAndSetUserData();
    }, [flag, customer, showToast]);




    const profileUpdateSchema = Yup.object({
        customerNumber: Yup.string().nullable(),
        personalDetails: Yup.object({
            firstName: Yup.string().required('First name is required.'),
            lastName: Yup.string().required('Last name is required.'),
            middleName: Yup.string().nullable(),
            identityNo: Yup.string().required('Identity number is required.'),
            identityType: Yup.string().required('Identity type is required.'),
            maritalStatus: Yup.string().required('Marital status is required.'),
            maritalType: Yup.string().when('maritalStatus', (maritalStatus, schema) => {
                return maritalStatus === 'Married' ? schema.required('Marital type is required when marital status is married.') : schema.notRequired()
            }),
            sex: Yup.string().required('Gender is required.'),
            dateOfBirth: Yup.date().required('Date of birth is required.')
        }),
        nextOfKin: Yup.object({
            firstName: Yup.string().required('First name is required.'),
            middleName: Yup.string().nullable(),
            lastName: Yup.string().required('Last name is required.'),
            relationship: Yup.string().required('Relationship is required.'),
            cell: Yup.string()
                .required('Cell number is required.')
                .matches(/^\d+$/, 'Valid phone number is required.')
        }),
        address: Yup.object({
            plotNo: Yup.string().required('Plot number is required.'),
            postal: Yup.string().required('Postal address is required.'),
            street: Yup.string().required('Street is required.'),
            ward: Yup.string().required('Ward is required.'),
            city: Yup.string().required('City is required.'),
            district: Yup.string().required('District is required.')
        }),
        contactDetails: Yup.object({
            homeTel: Yup.string().matches(/^\d+$/, 'Valid home telephone is required.').nullable(),
            workTel: Yup.string().matches(/^\d+$/, 'Valid work telephone is required.').nullable(),
            cell: Yup.string().matches(/^\d+$/, 'Valid cell number is required.').required('Cell number is required.'),
            email: Yup.string().email('Invalid email address').required('Email is required.')
        }),
        files: Yup.object({
            idKey: Yup.mixed().required('ID document is required.'),
            maritalProofKey: Yup.string().test(
                'marital-proof-required',
                'Marriage proof document is required.',
                function(value) {
                    const maritalStatus = this.options.context.personalDetails.maritalStatus;
                    return maritalStatus === 'Married' ? !!value : true;
                }
            )
        }),
        consent: Yup.boolean().oneOf([true], 'Consent is required'),
        dateOfConsent: Yup.date().when('consent', (consent, schema) => {
            return consent ? schema.required('Date of consent is required when consent is given.') : schema.notRequired()
        }),
        spouseCustomerNumber: Yup.string().nullable(),
        status: Yup.string().required('Status is required.')
    });



    const handleFileChange = async (fieldName, event, setFieldValue, userId) => {
        const file = event.target.files[0];
        if (file) {
            setLoading(prev => ({ ...prev, [fieldName]: true }));
            try {
                const uploadResponse = await utils.handleFileUpload(file, `kyc/${userId}`);
                if (uploadResponse && uploadResponse.data && uploadResponse.data.fileUrl) {
                    setFieldValue(`files.${fieldName}`, uploadResponse.data.fileUrl);
                } else {
                    setFieldValue(`files.${fieldName}.error`, 'Upload failed. Incomplete response received.');
                }
            } catch (error) {
                console.error("File upload error:", error);
                setFieldValue(`files.${fieldName}.error`, 'Upload failed: ' + error.message);
            } finally {
                setLoading(prev => ({ ...prev, [fieldName]: false }));
            }
        }
    };

    const getAllowedStatusOptions = () => {
        let allowed = statusTransitions[lastSavedStatus] || [];
        return Object.keys(statusTransitions).map((status) => ({
            value: status,
            label: status,
            disabled: !allowed.includes(status) && status !== lastSavedStatus
        }));
    };





    const handleSubmit = async (values, { setSubmitting }) => {
        console.log("Current form values on submit:", values);

        let errorOccurred = false;
        // Check for the ID document
        if (!values.files.idKey) {
            showToast({
                title: 'ID Document Missing',
                description: 'Please upload the required ID document.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            errorOccurred = true;
        }

        // Check for the Marriage Proof document only if the marital status is 'Married'
        if (values.personalDetails.maritalStatus === 'Married' && !values.files.maritalProofKey) {
            showToast({
                title: 'Marriage Proof Document Missing',
                description: 'Please upload the required marriage proof document.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            errorOccurred = true;
        }

        // Stop submission if any of the required documents are missing
        if (errorOccurred) {
            setSubmitting(false);
            return;
        }
        if ((['Approved', 'Declined'].includes(values.status) && initialStatus !== values.status)) {
            requestConfirm({
                title: "Confirm Status Change",
                message: `Setting the status to ${values.status} cannot be reverted. Are you sure you want to proceed?`,
                onConfirm: () => finalizeSubmission(values, setSubmitting)
            });
        } else {
            await finalizeSubmission(values, setSubmitting);
        }
    };

    const finalizeSubmission = async (values, setSubmitting) => {

        if (values.personalDetails.maritalStatus !== 'Married') {
            values.personalDetails.maritalType = null;
        }
        const payload = {
            userId: customerId,
            customerNumber: values.customerNumber,
            personalDetails: {
                ...values.personalDetails,
                dateOfBirth: utils.formatDate(values.personalDetails.dateOfBirth),
            },
            nextOfKin: values.nextOfKin,
            address: values.address,
            contactDetails: values.contactDetails,
            files: {
                idKey: values.files.idKey,
                maritalProofKey: values.personalDetails.maritalStatus === 'Married' ? values.files.maritalProofKey : null,
            },
            consent: values.consent,
            dateOfConsent: values.consent ? new Date(values.dateOfConsent).toISOString() : null,
            spouseCustomerNumber: values.spouseCustomerNumber,
            status: values.status,
        };

        console.log("Submitting the following payload to backend:", payload);

        try {
            const updateResponse = await updateUserProfile(payload);
            if (updateResponse.status === 'Success') {
                setLastSavedStatus(values.status);
                showToast({
                    title: 'Success',
                    description: updateResponse.message || 'KYC profile updated successfully.',
                    status: 'success',
                    isClosable: true,
                });
                closeDrawer();
                fetchCustomers();
            } else {
                throw new Error(updateResponse.message || 'Failed to update KYC profile.');
            }
        } catch (error) {
            showToast({
                title: 'Error',
                description: error.message || 'An error occurred while updating the KYC profile.',
                status: 'error',
                isClosable: true,
            });
        } finally {
            setSubmitting(false);
        }
    };

    const requestConfirm = (options) => {
        setConfirmDialog({
            isOpen: true,
            title: options.title,
            message: options.message,
            onConfirm: options.onConfirm,
        });
    };

    const handleCancel = () => {
        requestConfirm({
            title: "Cancel Confirmation",
            message: "Are you sure you want to cancel? Any unsaved changes will be lost.",
            onConfirm: closeDrawer
        });
    };

    const handleConfirm = () => {
        confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleValidateCustomerNumber = async (e, setFieldError) => {
        const { value } = e.target;
        const validationError = await validateCustomerNumber(value);
        setFieldError('customerNumber', validationError);
    };



    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={profileUpdateSchema}
                onSubmit={handleSubmit}
                enableReinitialize
                validateOnMount={true}
            >
                {(formikProps) => (
                    <Form>
                        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                            <FormActionLabel formAction="edit" formName="KYC Profile"/>

                            <SectionHeading icon="mdi:account-tag" text="Customer Number :" />
                            <SimpleGrid columns={2} gap={4} px={4} mt={3}>
                                <GridItem>
                                    <FormControl>
                                        <FormLabel htmlFor="customerNumber">Customer Number</FormLabel>
                                        <ThemedStyledInput
                                            id="customerNumber"
                                            type="text"
                                            {...formikProps.getFieldProps('customerNumber')}
                                            onBlur={(e) => {
                                                handleValidateCustomerNumber(e, formikProps.setFieldError);
                                                formikProps.handleBlur(e);
                                            }}
                                            bg={formikProps.values.customerNumber ? "gray.100" : "white"}
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.customerNumber && formikProps.touched.customerNumber && formikProps.errors.customerNumber}
                                        </Text>
                                    </FormControl>
                                </GridItem>
                            </SimpleGrid>


                            <SectionHeading icon="bxs:user-detail" text="Personal Details :" />
                            <SimpleGrid columns={2} gap={4} px={4} >
                                {/* First Name */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.personalDetails?.firstName && formikProps.touched.personalDetails?.firstName}>
                                        <FormLabel htmlFor="personalDetails.firstName">First Name</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('personalDetails.firstName')}
                                            id="personalDetails.firstName"
                                            placeholder="Enter First Name"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.personalDetails?.firstName && formikProps.touched.personalDetails?.firstName && formikProps.errors.personalDetails.firstName}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Middle Name */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.personalDetails?.middleName && formikProps.touched.personalDetails?.middleName}>
                                        <FormLabel htmlFor="personalDetails.middleName">Middle Name</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('personalDetails.middleName')}
                                            id="personalDetails.middleName"
                                            placeholder="Enter Middle Name"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.personalDetails?.middleName && formikProps.touched.personalDetails?.middleName && formikProps.errors.personalDetails.middleName}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Last Name */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.personalDetails?.lastName && formikProps.touched.personalDetails?.lastName}>
                                        <FormLabel htmlFor="personalDetails.lastName">Last Name</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('personalDetails.lastName')}
                                            id="personalDetails.lastName"
                                            placeholder="Enter Last Name"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.personalDetails?.lastName && formikProps.touched.personalDetails?.lastName && formikProps.errors.personalDetails.lastName}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.personalDetails?.sex && formikProps.touched.personalDetails?.sex}>
                                        <FormLabel htmlFor="personalDetails.sex">Gender</FormLabel>
                                        <RadioGroup
                                            name="personalDetails.sex"
                                            value={formikProps.values.personalDetails.sex} // Explicitly set the value from Formik's state
                                            onChange={val => formikProps.setFieldValue('personalDetails.sex', val)} // Use setFieldValue to update
                                        >
                                            <HStack>
                                                <Radio value="Male">Male</Radio>
                                                <Radio value="Female">Female</Radio>
                                                <Radio value="Other">Other</Radio>
                                            </HStack>
                                        </RadioGroup>
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.personalDetails?.sex && formikProps.touched.personalDetails?.sex && formikProps.errors.personalDetails.sex}
                                        </Text>
                                    </FormControl>

                                </GridItem>


                                {/* Identity Number */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.personalDetails?.identityNo && formikProps.touched.personalDetails?.identityNo}>
                                        <FormLabel htmlFor="personalDetails.identityNo">Identity No</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('personalDetails.identityNo')}
                                            id="personalDetails.identityNo"
                                            placeholder="Enter Identity No"
                                            bg={formikProps.values.personalDetails?.identityNo ? "gray.100" : "white"}
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.personalDetails?.identityNo && formikProps.touched.personalDetails?.identityNo && formikProps.errors.personalDetails.identityNo}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Identity Type Radio Buttons */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.personalDetails?.identityType && formikProps.touched.personalDetails?.identityType}>
                                        <FormLabel htmlFor="personalDetails.identityType">Identity Type</FormLabel>
                                        <RadioGroup
                                            name="personalDetails.identityType"
                                            value={formikProps.values.personalDetails.identityType}
                                            onChange={val => formikProps.setFieldValue('personalDetails.identityType', val)}
                                        >
                                            <Stack direction="row">
                                                <Radio value="Omang">Identity card (omang)</Radio>
                                                <Radio value="Passport">Passport</Radio>
                                            </Stack>
                                        </RadioGroup>
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.personalDetails?.identityType && formikProps.touched.personalDetails?.identityType && formikProps.errors.personalDetails.identityType}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Marital Status Radio Buttons */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.personalDetails?.maritalStatus && formikProps.touched.personalDetails?.maritalStatus}>
                                        <FormLabel htmlFor="personalDetails.maritalStatus">Marital Status</FormLabel>
                                        <RadioGroup
                                            name="personalDetails.maritalStatus"
                                            value={formikProps.values.personalDetails.maritalStatus}
                                            onChange={val => {
                                                formikProps.setFieldValue('personalDetails.maritalStatus', val);
                                                if (val === 'Married') {
                                                    formikProps.setFieldValue('personalDetails.maritalType', formikProps.values.personalDetails.maritalType || 'In Community Of Property');
                                                } else {
                                                    formikProps.setFieldValue('personalDetails.maritalType', null);
                                                }
                                            }}
                                        >
                                            <HStack>
                                                <Radio value="Single">Single</Radio>
                                                <Radio value="Married">Married</Radio>
                                                <Radio value="Divorced">Divorced</Radio>
                                                <Radio value="Widowed">Widowed</Radio>
                                            </HStack>
                                        </RadioGroup>
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.personalDetails?.maritalStatus && formikProps.touched.personalDetails?.maritalStatus && formikProps.errors.personalDetails.maritalStatus}
                                        </Text>
                                    </FormControl>

                                </GridItem>

                                {/* Conditional Marital Type Radio Buttons */}
                                {formikProps.values.personalDetails.maritalStatus === 'Married' && (


                                    <GridItem>
                                        <FormControl isInvalid={formikProps.errors.personalDetails?.maritalType && formikProps.touched.personalDetails?.maritalType}>
                                            <FormLabel htmlFor="personalDetails.maritalType">Marriage Type</FormLabel>
                                            <RadioGroup
                                                name="personalDetails.maritalType"
                                                value={formikProps.values.personalDetails.maritalType}
                                                onChange={val => formikProps.setFieldValue('personalDetails.maritalType', val)}
                                            >
                                                <HStack>
                                                    {/* Adjust the value to match the enum in the backend */}
                                                    <Radio value="In Community Of Property">In community of property</Radio>
                                                    <Radio value="Out Of Community Of Property">Out of community of property</Radio>
                                                </HStack>
                                            </RadioGroup>
                                            <Text color="red.500" fontSize="sm">
                                                {formikProps.errors.personalDetails?.maritalType && formikProps.touched.personalDetails?.maritalType && formikProps.errors.personalDetails.maritalType}
                                            </Text>
                                        </FormControl>
                                    </GridItem>

                                )}

                                {/* Date of Birth */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.personalDetails?.dateOfBirth && formikProps.touched.personalDetails?.dateOfBirth}>
                                        <FormLabel htmlFor="personalDetails.dateOfBirth">Date of Birth</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('personalDetails.dateOfBirth')}
                                            id="personalDetails.dateOfBirth"
                                            placeholder="Enter Date of Birth"
                                            type="date"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.personalDetails?.dateOfBirth && formikProps.touched.personalDetails?.dateOfBirth && formikProps.errors.personalDetails.dateOfBirth}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                            </SimpleGrid>


                            <SectionHeading icon="material-symbols:supervisor-account" text="Next of Kin Details :" />
                            <SimpleGrid columns={2} gap={4} px={4}>
                                {/* First Name */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.nextOfKin?.firstName && formikProps.touched.nextOfKin?.firstName}>
                                        <FormLabel htmlFor="nextOfKin.firstName">First Name</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('nextOfKin.firstName')}
                                            id="nextOfKin.firstName"
                                            placeholder="Enter First Name"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.nextOfKin?.firstName && formikProps.touched.nextOfKin?.firstName && formikProps.errors.nextOfKin.firstName}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Middle Name */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.nextOfKin?.middleName && formikProps.touched.nextOfKin?.middleName}>
                                        <FormLabel htmlFor="nextOfKin.middleName">Middle Name</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('nextOfKin.middleName')}
                                            id="nextOfKin.middleName"
                                            placeholder="Enter Middle Name (Optional)"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.nextOfKin?.middleName && formikProps.touched.nextOfKin?.middleName && formikProps.errors.nextOfKin.middleName}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Last Name */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.nextOfKin?.lastName && formikProps.touched.nextOfKin?.lastName}>
                                        <FormLabel htmlFor="nextOfKin.lastName">Last Name</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('nextOfKin.lastName')}
                                            id="nextOfKin.lastName"
                                            placeholder="Enter Last Name"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.nextOfKin?.lastName && formikProps.touched.nextOfKin?.lastName && formikProps.errors.nextOfKin.lastName}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Relationship */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.nextOfKin?.relationship && formikProps.touched.nextOfKin?.relationship}>
                                        <FormLabel htmlFor="nextOfKin.relationship">Relationship</FormLabel>
                                        <Select
                                            {...formikProps.getFieldProps('nextOfKin.relationship')}
                                            id="nextOfKin.relationship"
                                            placeholder="-- Select relationship --"
                                        >
                                            <option value="spouse">Spouse</option>
                                            <option value="parent">Parent</option>
                                            <option value="sibling">Sibling</option>
                                            <option value="child">Child</option>
                                            <option value="friend">Friend</option>
                                            <option value="other">Other</option>
                                        </Select>
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.nextOfKin?.relationship && formikProps.touched.nextOfKin?.relationship && formikProps.errors.nextOfKin.relationship}
                                        </Text>
                                    </FormControl>
                                </GridItem>


                                {/* Cell */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.nextOfKin?.cell && formikProps.touched.nextOfKin?.cell}>
                                        <FormLabel htmlFor="nextOfKin.cell">Cell</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('nextOfKin.cell')}
                                            id="nextOfKin.cell"
                                            placeholder="Enter Cell Number"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.nextOfKin?.cell && formikProps.touched.nextOfKin?.cell && formikProps.errors.nextOfKin.cell}
                                        </Text>
                                    </FormControl>
                                </GridItem>
                            </SimpleGrid>


                            <SectionHeading icon="basil:map-location-solid" text="Address Details :" />
                            <SimpleGrid columns={2} gap={4} px={4}>
                                {/* Plot No */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.address?.plotNo && formikProps.touched.address?.plotNo}>
                                        <FormLabel htmlFor="address.plotNo">Plot No</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('address.plotNo')}
                                            id="address.plotNo"
                                            placeholder="Enter Plot No"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.address?.plotNo && formikProps.touched.address?.plotNo && formikProps.errors.address.plotNo}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Postal */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.address?.postal && formikProps.touched.address?.postal}>
                                        <FormLabel htmlFor="address.postal">Postal</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('address.postal')}
                                            id="address.postal"
                                            placeholder="Enter Postal Address"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.address?.postal && formikProps.touched.address?.postal && formikProps.errors.address.postal}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Street */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.address?.street && formikProps.touched.address?.street}>
                                        <FormLabel htmlFor="address.street">Street</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('address.street')}
                                            id="address.street"
                                            placeholder="Enter Street Name"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.address?.street && formikProps.touched.address?.street && formikProps.errors.address.street}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* City */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.address?.city && formikProps.touched.address?.city}>
                                        <FormLabel htmlFor="address.city">City</FormLabel>
                                        <Select
                                            {...formikProps.getFieldProps('address.city')}
                                            id="address.city"
                                            placeholder="Select City"
                                        >
                                            {cities.map(city => (
                                                <option key={city._id} value={city.name}>{city.name}</option>
                                            ))}
                                        </Select>
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.address?.city && formikProps.touched.address?.city && formikProps.errors.address.city}
                                        </Text>
                                    </FormControl>

                                </GridItem>
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.address?.ward && formikProps.touched.address?.ward}>
                                        <FormLabel htmlFor="address.ward">Ward</FormLabel>
                                        <Select
                                            {...formikProps.getFieldProps('address.ward')}
                                            id="address.ward"
                                            placeholder="Select Ward"
                                        >
                                            {areas.map(area => (
                                                <option key={area._id} value={area.name}>{area.name}</option>
                                            ))}
                                        </Select>
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.address?.ward && formikProps.touched.address?.ward && formikProps.errors.address.ward}
                                        </Text>
                                    </FormControl>

                                </GridItem>
                            </SimpleGrid>


                            <SectionHeading icon="mage:phone-call-fill" text="Contact Details :" />
                            <SimpleGrid columns={2} gap={4} px={4}>
                                {/* Cell */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.contactDetails?.cell && formikProps.touched.contactDetails?.cell}>
                                        <FormLabel htmlFor="contactDetails.cell">Cell</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('contactDetails.cell')}
                                            id="contactDetails.cell"
                                            placeholder="Enter Cell Number"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.contactDetails?.cell && formikProps.touched.contactDetails?.cell && formikProps.errors.contactDetails.cell}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Home Telephone */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.contactDetails?.homeTel && formikProps.touched.contactDetails?.homeTel}>
                                        <FormLabel htmlFor="contactDetails.homeTel">Home Telephone</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('contactDetails.homeTel')}
                                            id="contactDetails.homeTel"
                                            placeholder="Enter Home Telephone Number (Optional)"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.contactDetails?.homeTel && formikProps.touched.contactDetails?.homeTel && formikProps.errors.contactDetails.homeTel}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Work Telephone */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.contactDetails?.workTel && formikProps.touched.contactDetails?.workTel}>
                                        <FormLabel htmlFor="contactDetails.workTel">Work Telephone</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('contactDetails.workTel')}
                                            id="contactDetails.workTel"
                                            placeholder="Enter Work Telephone Number (Optional)"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.contactDetails?.workTel && formikProps.touched.contactDetails?.workTel && formikProps.errors.contactDetails.workTel}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Email */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.contactDetails?.email && formikProps.touched.contactDetails?.email}>
                                        <FormLabel htmlFor="contactDetails.email">Email</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('contactDetails.email')}
                                            id="contactDetails.email"
                                            placeholder="Enter Email Address"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.contactDetails?.email && formikProps.touched.contactDetails?.email && formikProps.errors.contactDetails.email}
                                        </Text>
                                    </FormControl>
                                </GridItem>
                            </SimpleGrid>



                            <SectionHeading icon="fluent:document-one-page-link-20-filled" text="Documents Upload :" />
                            <Grid templateColumns="repeat(12, 1fr)" gap={2} color={"blue.300"} px={4}>
                                {/* Certified National Identity Card/Passport */}
                                <GridItem colSpan={{base: 12, md: 6}}>
                                    <DropBox
                                        label={"Identity Proof Document"}
                                        name="idKey"
                                        filePath={formikProps.values.files.idKey}
                                        loading={loading.idKey}
                                        formikProps={formikProps}
                                        callback={(event) => handleFileChange('idKey', event, formikProps.setFieldValue,formikProps.values.userId)}
                                        result={formikProps.values.files.idKey}
                                        instruction={`Please attach relevant documents.`}
                                    />
                                    {/*<pre>{JSON.stringify(formikProps.errors, null, 2)}</pre>
                                    <pre>{JSON.stringify(formikProps.values, null, 2)}</pre>*/}

                                </GridItem>
                                {/* Proof Of Marriage */}
                                {formikProps.values.personalDetails.maritalStatus === 'Married' && (
                                    <GridItem colSpan={{base: 12, md: 6}}>
                                        <DropBox
                                            label={"Marriage Proof Document"}
                                            name="maritalProofKey"
                                            filePath={formikProps.values.files.maritalProofKey}
                                            loading={loading.maritalProofKey}
                                            formikProps={formikProps}
                                            callback={(event) => handleFileChange('maritalProofKey', event, formikProps.setFieldValue,formikProps.values.userId)}
                                            result={formikProps.values.files.maritalProofKey}
                                            instruction={`Please attach relevant documents.`}
                                        />
                                    </GridItem>
                                )}
                            </Grid>


                            <SectionHeading icon="mdi:user-settings" text="Consent Details :" />
                            <SimpleGrid columns={2} gap={4} px={4}>
                                <GridItem>
                                    <FormControl display="flex" alignItems="center">
                                        <Checkbox
                                            id="consent"
                                            isChecked={formikProps.values.consent}
                                            onChange={formikProps.handleChange}
                                            name="consent"
                                            colorScheme="blue"
                                            variant="outline"
                                            mr={2}
                                        >
                                            Consent :
                                        </Checkbox>
                                        <Text pl={2}>{formikProps.values.consent ? "Yes" : "No"}</Text>
                                    </FormControl>
                                </GridItem>

                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.dateOfConsent && formikProps.touched.dateOfConsent}>
                                        <FormLabel htmlFor="dateOfConsent">Date of Consent</FormLabel>
                                        <ThemedStyledInput
                                            type="date"
                                            {...formikProps.getFieldProps('dateOfConsent')}
                                            id="dateOfConsent"
                                        />
                                        {formikProps.errors.dateOfConsent && formikProps.touched.dateOfConsent && (
                                            <Text color="red.500" fontSize="sm">{formikProps.errors.dateOfConsent}</Text>
                                        )}
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.spouseCustomerNumber && formikProps.touched.spouseCustomerNumber}>
                                        <FormLabel htmlFor="spouseCustomerNumber">Spouse Customer Number</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('spouseCustomerNumber')}
                                            id="spouseCustomerNumber"
                                            placeholder="Enter Spouse Customer Number (Optional)"
                                        />
                                        {formikProps.errors.spouseCustomerNumber && formikProps.touched.spouseCustomerNumber && (
                                            <Text color="red.500" fontSize="sm">{formikProps.errors.spouseCustomerNumber}</Text>
                                        )}
                                    </FormControl>
                                </GridItem>
                            </SimpleGrid>


                            <SectionHeading icon="material-symbols-light:order-approve-rounded" text="KYC Profile Status :">
                                <Badge ml="4" colorScheme={getStatusColor(formikProps.values.status)} variant="solid">
                                    {formikProps.values.status || 'Not Set'}
                                </Badge>
                            </SectionHeading>

                            <SimpleGrid columns={2} gap={4} px={4}>
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.status && formikProps.touched.status}>
                                        <FormLabel htmlFor="status">Update Status</FormLabel>
                                        <Select
                                            id="status"
                                            placeholder="-- Select Status --"
                                            value={formikProps.values.status}
                                            onChange={(e) => {
                                                const newStatus = e.target.value;
                                                formikProps.setFieldValue('status', newStatus);
                                            }}
                                            disabled={formikProps.isSubmitting}
                                        >
                                            {getAllowedStatusOptions().map(option => (
                                                <option key={option.value} value={option.value} disabled={option.disabled}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Select>
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.status && formikProps.touched.status && formikProps.errors.status}
                                        </Text>
                                    </FormControl>

                                </GridItem>
                            </SimpleGrid>




                            <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }} mt={8} mb={8}>
                                <SimpleGrid columns={2} gap={4} px={4}>
                                    <>
                                    <GridItem colSpan={1}>
                                        <CustomAlert status="info" message="'Identity number', 'Identity type', 'Email Address' cannot be changed" />
                                        <CustomAlert status="info" message={`Allowed transitions for the current KYC Profile: ${getAllowedTransitions(formikProps.values.status).join(', ') || 'Completed'}`} />
                                        <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
                                    </GridItem>
                                    <GridItem colSpan={1}>
                                        <Text fontSize="lg" fontWeight="bold" mb={2}>Form Errors:</Text>
                                        <FieldErrorMessage name="customerNumber" />
                                        <FieldErrorMessage name="personalDetails.firstName" />
                                        <FieldErrorMessage name="personalDetails.middleName" />
                                        <FieldErrorMessage name="personalDetails.lastName" />
                                        <FieldErrorMessage name="personalDetails.identityNo" />
                                        <FieldErrorMessage name="personalDetails.identityType" />
                                        <FieldErrorMessage name="personalDetails.maritalStatus" />
                                        <FieldErrorMessage name="personalDetails.maritalType" />
                                        <FieldErrorMessage name="personalDetails.sex" />
                                        <FieldErrorMessage name="personalDetails.dateOfBirth" />
                                        <FieldErrorMessage name="nextOfKin.firstName" />
                                        <FieldErrorMessage name="nextOfKin.middleName" />
                                        <FieldErrorMessage name="nextOfKin.lastName" />
                                        <FieldErrorMessage name="nextOfKin.relationship" />
                                        <FieldErrorMessage name="nextOfKin.cell" />
                                        <FieldErrorMessage name="address.plotNo" />
                                        <FieldErrorMessage name="address.postal" />
                                        <FieldErrorMessage name="address.street" />
                                        <FieldErrorMessage name="address.ward" />
                                        <FieldErrorMessage name="address.city" />
                                        <FieldErrorMessage name="address.district" />
                                        <FieldErrorMessage name="contactDetails.cell" />
                                        <FieldErrorMessage name="contactDetails.homeTel" />
                                        <FieldErrorMessage name="contactDetails.workTel" />
                                        <FieldErrorMessage name="contactDetails.email" />
                                        <FieldErrorMessage name="files.idKey" />
                                        <FieldErrorMessage name="files.maritalProofKey" />
                                        <FieldErrorMessage name="consent" />
                                        <FieldErrorMessage name="status" />
                                        <FieldErrorMessage name="dateOfConsent" />
                                        <FieldErrorMessage name="spouseCustomerNumber" />
                                        <FieldErrorMessage name="comment" />
                                    </GridItem>

                                    <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                                        <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                                            Cancel
                                        </CustomButton>
                                        <CustomButton type="submit" disabled={formikProps.isSubmitting} style={{
                                            opacity: formikProps.isSubmitting ? 0.7 : 1,
                                            pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                                        }}>
                                            {formikProps.isSubmitting ? (
                                                <>
                                                    <Spinner size="xs" mr={2} />
                                                    Updating...
                                                </>
                                            ) : 'Update'}
                                        </CustomButton>
                                    </ButtonStack>
                                </>
                                </SimpleGrid>
                            </Box>
                        </VStack>
                    </Form>
                )}
            </Formik>

            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

export default CustomerUpdateKYCForm;
