import React from 'react';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    Box, Select,
    Text, InputGroup, InputRightElement, IconButton, SimpleGrid, Spinner
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useDrawer } from '../../../Context/DrawerContext/DrawerContext';
import { createGroupEmailManagement } from "../GroupEmailManagementAPI";
import { Icon } from "@iconify/react";
import {ThemedStyledInput} from "../../../components/Styled";
import CustomAlert from "../../../components/Styled/StyledAlert";
import {ButtonStack, CustomButton} from "../../../components/Styled/StyledButtons";
import FormActionLabel from "../../../components/Styled/FormActionLabel";
import useSingleToast from "../../../hooks/UseSingleToast/UseSingleToast";
import {GroupEmails} from "./GroupEmails"

const GroupEmailManagementAddForm = ({ refetchGroupEmailManagementData, filterOptions }) => {
    const { closeDrawer } = useDrawer();
    const showToast = useSingleToast();

    const groupEmailManagementSchema = Yup.object().shape({
        name: Yup.string().required('Users name is required.'),
        email: Yup.string().email('Invalid email address').required('Email is required.'),
        category: Yup.string().required('Users group is required.')
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            await createGroupEmailManagement(values);
            showToast({
                title: 'User email added successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchGroupEmailManagementData(filterOptions);
            closeDrawer();
        } catch (error) {
            showToast({
                title: 'Error',
                description: error.message || 'An unexpected error occurred',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <FormActionLabel formAction="add" formName="Group Email Management" />
            <Formik
                initialValues={{
                    name: '',
                    email: '',
                    category: ''
                }}
                validationSchema={groupEmailManagementSchema}
                onSubmit={handleSubmit}
            >
                {formikProps => (
                    <Form>
                        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                            <SimpleGrid columns={1} gap={4} px={2} py={4}>
                                <FieldControl
                                    formikProps={formikProps}
                                    name="name"
                                    label="User's FullName"
                                    placeholder="Enter FullName"
                                    icon={<Icon icon="mdi:rename-outline" />}
                                />
                                <FieldControl
                                    formikProps={formikProps}
                                    name="category"
                                    label="Category"
                                    placeholder="Select Group"
                                    component="select"
                                    options={GroupEmails}
                                />
                                <FieldControl
                                    formikProps={formikProps}
                                    name="email"
                                    label="Email"
                                    placeholder="Enter Email"
                                    icon={<Icon icon="ic:outline-email" />}
                                />
                            </SimpleGrid>
                            <AlertAndButtons
                                formikProps={formikProps}
                                closeDrawer={closeDrawer}
                            />
                        </VStack>
                    </Form>
                )}
            </Formik>
        </>
    );
};

const FieldControl = ({ formikProps, name, label, placeholder, component = "input", options = [], icon }) => (
    <GridItem>
        <FormControl isInvalid={formikProps.errors[name] && formikProps.touched[name]}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <InputGroup>
                {component === "select" ? (
                    <Field as={Select} id={name} name={name} placeholder={placeholder}>
                        {options.map(option => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </Field>
                ) : (
                    <ThemedStyledInput
                        {...formikProps.getFieldProps(name)}
                        id={name}
                        placeholder={placeholder}
                    />
                )}
                {icon && (
                    <InputRightElement>
                        <IconButton
                            aria-label="Icon button label"
                            bg="blue.300"
                            _hover={{ bg: '#69C5EC' }}
                            _active={{ bg: '#4699bc' }}
                            color="white"

                        >
                            {icon}
                        </IconButton>
                    </InputRightElement>

                )}
            </InputGroup>
            {formikProps.errors[name] && formikProps.touched[name] && (
                <Text color="red.500" fontSize="sm">{formikProps.errors[name]}</Text>
            )}
        </FormControl>
    </GridItem>
);

const AlertAndButtons = ({ formikProps, closeDrawer }) => (
    <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}>
        <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
        <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
            <CustomButton onClick={closeDrawer} type="cancel" showIcon={false}>
                Cancel
            </CustomButton>
            <CustomButton
                type="submit"
                disabled={formikProps.isSubmitting}
                style={{
                    opacity: formikProps.isSubmitting ? 0.7 : 1,
                    pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                }}
            >
                {formikProps.isSubmitting ? (
                    <>
                        <Spinner size="xs" mr={2} />
                        Submitting...
                    </>
                ) : 'Submit'}
            </CustomButton>
        </ButtonStack>
    </Box>
);

export default GroupEmailManagementAddForm;
