import React from "react";
import AuthenticationContainer from "../../components/Authentication/AuthenticationContainer";
import OTPForm from "./components/OTPForm";

const OTP = () => {
  return (
    <>
      <AuthenticationContainer Children={<OTPForm />} />
    </>
  );
};

export default OTP;
