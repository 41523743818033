import React from 'react'
import DefaultTemplate from "../../../../components/DefaultTemplate/DefaultTemplate";
import Icon from "../../../../@core/components/icon";
import AlbumsContainer from "./Components/AlbumsContainer";
const Albums = () => {
    return (
        <>
            <DefaultTemplate Content={ <AlbumsContainer/> } Title={"Albums" } icon={<Icon icon="bi:card-image" />}/>
        </>
    )
}

export default Albums
