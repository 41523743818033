import React from 'react'
import SurveyViewContainer from './SurveyViewContainer'
import {Icon} from "@iconify/react";
import { useLocation } from 'react-router-dom';
import DefaultTemplate from "../../../components/DefaultTemplate/DefaultTemplate";

const SurveyView = () => {
    const location = useLocation();
    const { surveyData } = location.state || {}; // Accessing the state passed via navigate

    return (
        <>
            <DefaultTemplate 
                Content={<SurveyViewContainer surveyData={surveyData} />}
                Title={surveyData.title ?? "Survey"} 
                icon={<Icon icon="mdi:poll"/>}
            />
        </>
    )
}

export default SurveyView