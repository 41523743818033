import React from 'react';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box, Circle, Flex } from '@chakra-ui/react';
import PollInformation from "./PollInformation";

const PollAccordion = ({ polls, expandAllItems }) => {

  const allIndices = polls.map((_, index) => index);

  return (
    <Accordion allowToggle defaultIndex={expandAllItems ? allIndices : []}>
      {polls.map((poll, index) => (
        <AccordionItem key={poll._id}>
          <AccordionButton>
            <Flex align="center" flex="1">
              <Circle size="30px" bg="blue.300" color="white" mr={4}>
                {index + 1}
              </Circle>
              <Box flex="1" textAlign="left">
                {poll.question}
              </Box>
            </Flex>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <PollInformation formData={poll} standalone={false}/>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default PollAccordion;
