import React from 'react';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    Box,
    Text, InputGroup, InputRightElement, IconButton, SimpleGrid, Spinner, Switch, Badge
} from '@chakra-ui/react';
import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';
import {useDrawer} from "../../../../Context/DrawerContext/DrawerContext";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";
import {createFaq} from "../FaqsAPI";
import FormActionLabel from "../../../../components/Styled/FormActionLabel";
import {ThemedStyledInput, ThemedStyledSelectInput} from "../../../../components/Styled";
import CustomAlert from "../../../../components/Styled/StyledAlert";
import {ButtonStack, CustomButton} from "../../../../components/Styled/StyledButtons";
import Icon from "../../../../@core/components/icon";
import faqCategories from "./FaqCategories";
import TextEditor from "../../../../Context/TextEditor/TextEditor";

const FaqAddForm = ({ refetchFaqData, filterOptions }) => {
    const { closeDrawer } = useDrawer();
    const showToast = useSingleToast();

    const faqSchema = Yup.object().shape({
        question: Yup.string().required('FAQ question is required.'),
        answer: Yup.string().required('FAQ answer is required.'),
        category: Yup.string().required('Category is required.'),
        published: Yup.boolean().required('Published status is required.')
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            await createFaq(values);
            showToast({
                title: 'FAQ added successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchFaqData(filterOptions);
            closeDrawer();
        } catch (error) {
            showToast({
                title: 'Error',
                description: error?.message || 'An unexpected error occurred',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <FormActionLabel formAction="add" formName="FAQ" />
            <Formik
                initialValues={{
                    question: '',
                    answer: '',
                    category: '',
                    published: false
                }}
                validationSchema={faqSchema}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue, ...formikProps }) => (
                    <Form>
                        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                            <SimpleGrid columns={1} gap={4} px={2} py={4}>
                                <FieldControl
                                    formikProps={formikProps}
                                    name="question"
                                    label="Question : "
                                    placeholder="Enter Question"
                                    icon={<Icon icon="mdi:rename-outline" />}
                                />
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.answer && formikProps.touched.answer}>
                                        <FormLabel htmlFor="answer">Answer :</FormLabel>
                                        <Field name="answer">
                                            {({ field }) => (
                                                <TextEditor
                                                    value={field.value}
                                                    onChange={(value) => setFieldValue('answer', value)}
                                                    height="200px"
                                                />
                                            )}
                                        </Field>
                                        {formikProps.errors.answer && formikProps.touched.answer && (
                                            <Text color="red.500" fontSize="sm">{formikProps.errors.answer}</Text>
                                        )}
                                    </FormControl>
                                </GridItem>
                                <FormControl isInvalid={formikProps.errors['category'] && formikProps.touched['category']}>
                                    <FormLabel htmlFor="category">Category : </FormLabel>
                                    <ThemedStyledSelectInput
                                        {...formikProps.getFieldProps('category')}
                                        placeholder=" -- Select Category -- "
                                    >
                                        {Object.values(faqCategories).map((category, index) => (
                                            <option key={index} value={category}>{category}</option>
                                        ))}
                                    </ThemedStyledSelectInput>
                                    {formikProps.errors['category'] && formikProps.touched['category'] && (
                                        <Text color="red.500" fontSize="sm">{formikProps.errors['category']}</Text>
                                    )}
                                </FormControl>
                                <FormControl display="flex" alignItems="center">
                                    <FormLabel htmlFor="published" mb="0">
                                        Visibility Status :
                                    </FormLabel>
                                    <Box display="flex" alignItems="center">
                                        <Switch {...formikProps.getFieldProps('published')} id="published" isChecked={formikProps.values.published} />
                                        <Badge ml={2} variant="solid" colorScheme={formikProps.values.published ? "green" : "red"}>
                                            {formikProps.values.published ? "Published" : "Unpublished"}
                                        </Badge>
                                    </Box>
                                </FormControl>

                            </SimpleGrid>
                            <AlertAndButtons
                                formikProps={formikProps}
                                closeDrawer={closeDrawer}
                            />
                        </VStack>
                    </Form>
                )}
            </Formik>
        </>
    );
};

const FieldControl = ({ formikProps, name, label, placeholder, icon }) => (
    <GridItem>
        <FormControl isInvalid={formikProps.errors[name] && formikProps.touched[name]}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <InputGroup>
                <ThemedStyledInput
                    {...formikProps.getFieldProps(name)}
                    id={name}
                    placeholder={placeholder}
                />
                {icon && (
                    <InputRightElement>
                        <IconButton
                            aria-label="Icon button label"
                            bg="blue.300"
                            _hover={{ bg: '#69C5EC' }}
                            _active={{ bg: '#4699bc' }}
                            color="white"

                        >
                            {icon}
                        </IconButton>
                    </InputRightElement>

                )}
            </InputGroup>
            {formikProps.errors[name] && formikProps.touched[name] && (
                <Text color="red.500" fontSize="sm">{formikProps.errors[name]}</Text>
            )}
        </FormControl>
    </GridItem>
);

const AlertAndButtons = ({ formikProps, closeDrawer }) => (
    <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}>
        <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
        <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
            <CustomButton onClick={closeDrawer} type="cancel" showIcon={false}>
                Cancel
            </CustomButton>
            <CustomButton
                type="submit"
                disabled={formikProps.isSubmitting}
                style={{
                    opacity: formikProps.isSubmitting ? 0.7 : 1,
                    pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                }}
            >
                {formikProps.isSubmitting ? (
                    <>
                        <Spinner size="xs" mr={2} />
                        Submitting...
                    </>
                ) : 'Submit'}
            </CustomButton>
        </ButtonStack>
    </Box>
);

export default FaqAddForm;
