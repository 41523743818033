import React, { useEffect, useState, useCallback } from 'react';
import LabServiceProductTable from './LabServiceProductsTable';
import LabServiceProductTableTopBar from './LabServiceProductTableTopBar';
import { fetchAllLabServiceProducts } from '../../LabServiceProductsAPI';

const LabServiceProductContainer = () => {
    const [labServiceProducts, setLabServiceProducts] = useState([]);
    const [error, setError] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [filterOptions, setFilterOptions] = useState({});
    const [fetchTrigger, setFetchTrigger] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const refetchLabServiceProductData = useCallback(async (options = {}) => {
        setDataLoaded(false);
        try {
            const data = await fetchAllLabServiceProducts(pageNumber, 10, filterOptions);
            setLabServiceProducts(data.labServiceProducts);
            setTotalPages(data.totalPages);
            setError('');
            setDataLoaded(true);
        } catch (error) {
            console.error('Error fetching all labServiceProducts:', error.message);
            setError('Failed to fetch labServiceProducts data.');
            setLabServiceProducts([]);
            setTotalPages(0);
        }
    }, [filterOptions, pageNumber]);

    useEffect(() => {
        refetchLabServiceProductData();
    }, [pageNumber, refreshFlag, refetchLabServiceProductData, totalPages, fetchTrigger, filterOptions ]);

    return (
        <>
            <LabServiceProductTableTopBar
                refetchLabServiceProductData={refetchLabServiceProductData}
                setPageNumber={setPageNumber}
                setFilterOptions={setFilterOptions}
                setFetchTrigger={setFetchTrigger}
            />
            <LabServiceProductTable
                labServiceProducts={labServiceProducts}
                setLabServiceProducts={setLabServiceProducts}
                setRefreshFlag={setRefreshFlag}
                refetchLabServiceProductData={refetchLabServiceProductData}
                error={error}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                filterOptions={filterOptions}
                dataLoaded={dataLoaded}
            />
        </>
    );
};

export default LabServiceProductContainer;
