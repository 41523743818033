import React, {useEffect, useRef, useState} from 'react';
import {Formik, Form, Field} from 'formik';
import {FormControl, Select, Stack} from '@chakra-ui/react';
import {ThemedStyledInput} from '../../../../components/Styled';
import * as Yup from 'yup';
import {CustomButton} from "../../../../components/Styled/StyledButtons";
import {Icon} from "@iconify/react";
import FieldErrorMessage from "../../../../components/FieldErrorMessage/FieldErrorMessage";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";
import {values} from "lodash";

const validationSchema = Yup.object({
    title: Yup.string()
        .max(30, 'Search & Filters Error: Name Must be 30 characters or less')
        .trim('Search & Filters Error: Name cannot be just whitespace')
        .strict(true)
});

const VideoSearchFilter = ({onFilterChange}) => {
    const showToast = useSingleToast();
    const showToastRef = useRef(showToast);
    return (
        <Formik
            initialValues={{title: '', published: ''}}
            validationSchema={validationSchema}
            onSubmit={(values, {setSubmitting}) => {
                let filter = {...values}
                if (!filter.title) {
                    delete filter.title;
                }
                if (!filter.published) {
                    delete filter.published;
                }
                onFilterChange(filter);
                setSubmitting(true);
            }}
            onReset={() => {
                onFilterChange({});
            }}
        >
            {({handleSubmit, resetForm, setFieldValue, formikProps}) => (
                <Form>
                    <Stack spacing={2} direction="row" align="center">
                        <FormControl>
                            <Field as={ThemedStyledInput} id="title" name="title" type="text"
                                   placeholder="Search by video title"/>
                        </FormControl>
                        <FormControl>
                            <Field as={Select} name="published" placeholder=" -- Select Published Status--">
                                <option key='1' value='true'>Published</option>
                                <option key='2' value='false'>Unpublished</option>
                            </Field>
                        </FormControl>
                        <CustomButton
                            onClick={() => {
                                handleSubmit()
                            }}
                            type="search"
                            tooltipLabel="Search"
                            icon={<Icon icon="bi:search"/>}
                            showLabel={false}
                        >
                            Search
                        </CustomButton>
                        <CustomButton
                            type="reset"
                            onClick={() => {
                                resetForm();
                                onFilterChange({});
                            }}
                            tooltipLabel="Clear Filters"
                            icon={<Icon icon="radix-icons:reset"/>}
                            showLabel={false}
                        >
                            Clear
                        </CustomButton>
                    </Stack>
                    <FieldErrorMessage name="title"/>
                    <FieldErrorMessage name="published"/>
                </Form>
            )}
        </Formik>
    );
};


export default VideoSearchFilter;
