import React, { useEffect, useState, useCallback } from 'react';
import NewsTable from './NewsTable';
import NewsTableTopBar from './NewsTableTopBar';
import { fetchAllNews } from '../NewsAPI';

const NewsContainer = () => {
    const [news, setNews] = useState([]);
    const [error, setError] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const refetchNewsData = useCallback(async (filter={}, fetchAll = false) => {
        setDataLoaded(false);
        try {
            const data = await fetchAllNews(pageNumber, 10, filter, fetchAll);
            setNews(data.docs);
            setTotalPages(data.totalPages);
            setError('');
            setDataLoaded(true);
        } catch (error) {
            setError('Failed to fetch news data.');
            setNews([]);
            setTotalPages(0);
        }
    }, [pageNumber]);

    useEffect(() => {
        refetchNewsData();
    }, [pageNumber, refreshFlag, refetchNewsData]);

    return (
        <>
            <NewsTableTopBar refetchNewsData={refetchNewsData} />
            <NewsTable
                news={news}
                setNews={setNews}
                setRefreshFlag={setRefreshFlag}
                refetchNewsData={refetchNewsData}
                error={error}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                dataLoaded={dataLoaded}
            />
        </>
    );
};

export default NewsContainer;
