// EventView.js
import React from 'react';
import {
  Box, Text,
  Card,
  CardBody,
  Flex,
  Heading,
  IconButton,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Icon, Badge, Progress 
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import ImageCarousel from "./ImageCarousel";
import DefaultTemplate from "../../../components/DefaultTemplate/DefaultTemplate";
import EventDocumentList from './EventDocumentList';
import { useState, useEffect } from 'react';
import { fetchEventById } from "../eventAPI";

const EventView = () => {
  const { eventId } = useLocation().state;
  return (
    <>
      <DefaultTemplate
        Content={<ViewContainer eventId={eventId} />}
        Title={"View Events"}
        icon={<Icon icon="mdi:poll" />}
      />
    </>
  );
};

const ViewContainer = ({ eventId }) => {
  const [event, setEvent] = useState({
    title: '',
    value: '',
    description: '',
    images: [],
    pdfs: [],
    closingDate: '',
    startDate: ''
  });
  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const eventData = await fetchEventById(eventId);
        console.log("eventData", eventData);
        if (eventData) {
          setEvent({
            title: eventData.title,
            value: eventData.value,
            description: eventData.description,
            images: eventData.imagesDocumentsKeys || [],
            pdfs: eventData.otherDocumentsKeys || [],
            startDate: eventData.startDate,
            closingDate: eventData.closingDate
          });

          // Calculate progress value
          const currentDate = new Date();
          const startDate = new Date(eventData.startDate);
          const closingDate = new Date(eventData.closingDate);
          const eventDuration = closingDate.getTime() - startDate.getTime();
          const timeElapsed = currentDate.getTime() - startDate.getTime();
          const progressValue = (timeElapsed / eventDuration) * 100;
          setProgressValue(progressValue);

        }
      } catch (error) {
        console.error("Error fetching event data:", error);
      }
    };

    fetchEventData();
  }, [eventId]);

  console.log("event", event);

  return (
    <Card maxW='full' mb={8} marginBottom={8}>
      <CardBody>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          justify="space-between"
          w="100%"
          p={4}
        >
          <Box
            flex={{ base: '100%', md: '50%' }}
            bg="gray.200"
            p={4}
            m={2}
            borderRadius="md"
            boxShadow="md"
          >
            <ImageCarousel images={event.images}/>
          </Box>
          <Box
            flex={{ base: '100%', md: '50%' }}
            bg="white"
            p={4}
            m={2}
            borderRadius="md"
            align="start"
            width={{ base: '100%', md: '45%' }}
          >
            <Tabs isManual variant='enclosed'>
              <TabList>
                <Tab>Overview</Tab>
                <Tab>Documents</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Flex spacing='4'>
                    <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap' mb={4}>
                      {/* <Avatar name='Segun Adebayo' src='https://bit.ly/sage-adebayo' /> */}
                      <Box>
                        <Heading size='sm'>Title: {event.title}</Heading>
                        <Text>
                          Start Date:
                          <Badge ml='1' colorScheme='green'>
                            {event.startDate}
                          </Badge>,
                          Closing Date:
                          <Badge ml='1' colorScheme='green'>
                            {event.closingDate}
                          </Badge>
                        </Text>
                      </Box>
                    </Flex>
                    <IconButton
                      variant='ghost'
                      colorScheme='gray'
                      aria-label='See menu' />
                  </Flex>
                  <Progress hasStripe value={progressValue || 0} />
                  <Heading size='sm' mt={4}>Description:</Heading>
                  <Text ms={4}>
                    <div dangerouslySetInnerHTML={{ __html: event.description }} />
                  </Text>
                </TabPanel>
                <TabPanel>
                  <EventDocumentList pdfs={event.pdfs} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default EventView;
