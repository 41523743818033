import { API1 } from "../../utils/api";

// #################################
//          POLLS API FUNCTIONS
// #################################

// Fetch All Polls
export const fetchAllPolls = async (pageNumber = 1, pageSize = 10, filterOptions = {}) => {
    try {
        const queryParams = new URLSearchParams({
            ...filterOptions,
            pageNumber,
            pageSize
        }).toString();

        const response = await API1.get(`/common/polls?${queryParams}`);

        if (response.status === 200) {
            const { docs: polls, totalDocs } = response.data;
            return {
                polls: response.data,
                totalPages: Math.ceil(totalDocs / pageSize),
            };
        } else {
            throw new Error('Unexpected response from the server while fetching polls.');
        }
    } catch (error) {
        console.error("Error fetching polls:", error);
        throw new Error('Failed to fetch polls. Please try again.');
    }
};

// addPoll API
export const addPoll = async (pollData) => {
    try {
        const response = await API1.post(`/common/polls`, pollData);
        console.log("Inside the add poll function");
        console.log(pollData);
        if (response.status === 201) {
            return response.data;
        } else {
            throw new Error('Unexpected response from the server while adding poll.');
        }
    } catch (error) {
        console.error("Error adding poll:", error);
        throw new Error('Failed to add poll. Please try again.');
    }
};

// fetch polls by id
export const fetchPollById = async (pollId) => {
    try {
        const response = await API1.get(`/common/polls/${pollId}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Unexpected response from the server while fetching poll.');
        }
    } catch (error) {
        console.error("Error fetching poll:", error);
        throw new Error('Failed to fetch poll. Please try again.');
    }
};

// update poll API
export const updatePoll = async (pollId, pollData) => {
    try {
        const response = await API1.put(`/common/polls/${pollId}`, pollData);

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Unexpected response from the server while updating poll.');
        }
    } catch (error) {
        console.error("Error updating poll:", error);
        throw new Error('Failed to update poll. Please try again.');
    }
};

// delete poll by ID API
export const deletePoll = async (pollId) => {
    try {
        const response = await API1.delete(`/common/polls/${pollId}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Unexpected response from the server while deleting poll.');
        }
    } catch (error) {
        console.error("Error deleting poll:", error);
        throw new Error('Failed to delete poll. Please try again.');
    }
};

// #################################
//          SURVEYS API FUNCTIONS
// #################################
// Fetch All surveys
export const fetchAllSurveys = async (pageNumber = 1, pageSize = 10, filterOptions = {}) => {
    try {
        const queryParams = new URLSearchParams({
            ...filterOptions,
            pageNumber,
            pageSize
        }).toString();

        const response = await API1.get(`/common/surveys?${queryParams}`);

        if (response.status === 200) {
            const { docs: surveys, totalDocs } = response.data;
            return {
                surveys: response.data,
                totalPages: Math.ceil(totalDocs / pageSize),
            };
        } else {
            throw new Error('Unexpected response from the server while fetching survey.');
        }
    } catch (error) {
        console.error("Error fetching survey:", error);
        throw new Error('Failed to fetch survey. Please try again.');
    }
};

// addSurvey API
export const addSurvey = async (surveyData) => {
    try {
        console.log("Inside the add survey function");
        console.log(surveyData);
        const response = await API1.post(`/common/surveys`, surveyData);
        if (response.status === 201) {
            return response.data;
        } else {
            throw new Error('Unexpected response from the server while adding survey.');
        }
    } catch (error) {
        console.error("Error adding survey:", error);
        throw new Error('Failed to add survey. Please try again.');
    }
};

// fetch survey by id
export const fetchSurveyById = async (pollId) => {
    try {
        const response = await API1.get(`/common/surveys/${pollId}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Unexpected response from the server while fetching survey.');
        }
    } catch (error) {
        console.error("Error fetching survey:", error);
        throw new Error('Failed to fetch survey. Please try again.');
    }
};

// update survey API
export const updateSurvey = async (surveyId, surveyData) => {
    try {
        console.log("Inside the update survey function");
        console.log(surveyData);
        const response = await API1.put(`/common/surveys/${surveyId}`, surveyData);

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Unexpected response from the server while updating survey.');
        }
    } catch (error) {
        console.error("Error updating survey:", error);
        throw new Error('Failed to update survey. Please try again.');
    }
};

// delete survey by ID API
export const deleteSurvey = async (surveyId) => {
    try {
        const response = await API1.delete(`/common/surveys/${surveyId}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Unexpected response from the server while deleting survey.');
        }
    } catch (error) {
        console.error("Error deleting survey:", error);
        throw new Error('Failed to delete survey. Please try again.');
    }
};