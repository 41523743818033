import React from 'react'
import DefaultTemplate from '../../../components/DefaultTemplate/DefaultTemplate'
import ManagementCenterUserContainer from './Components/ManagementCenterContainer'
import {Icon} from "@iconify/react";

const ManagementCenter = () => {
    return (
        <>
            <DefaultTemplate Content={ <ManagementCenterUserContainer/> } Title={"Management Centers" } icon={<Icon icon="fa6-solid:building-shield" />}/>
        </>
    )
}

export default ManagementCenter
