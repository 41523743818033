import React from 'react'
import DefaultTemplate from '../../../components/DefaultTemplate/DefaultTemplate'
import {Icon} from "@iconify/react";
import LabServiceRequestContainer from "./Components/LabQuotationRequests/LabQuotationRequestContainer";

const LabQuotationRequests = () => {
    return (
        <>
            <DefaultTemplate Content={ <LabServiceRequestContainer/> } Title={"Lab Service Requests" } icon={<Icon icon="material-symbols:lab-research-rounded" />}/>
        </>
    )
}

export default LabQuotationRequests
