import React, {useEffect, useState} from 'react';
import {
    FormControl,
    FormLabel,
    Text,
    Box,
    useToast, SimpleGrid, Flex, useDisclosure
} from '@chakra-ui/react';
import {useDrawer} from '../../../Context/DrawerContext/DrawerContext'; // Make sure this path is correct
import {CustomButton} from '../../../components/Styled/StyledButtons'; // Import path for your custom button

import pageService from '../../../services/pageService';
import FileDisplay from "../../../components/FileDisplay";
import PdfViewerModal from "../../../components/PdfViewer/PdfViewerModal"; // Adjust the import path as necessary

const ViewPageForm = ({pageId}) => {
    const {closeDrawer} = useDrawer();
    const toast = useToast();
    const [page, setPage] = useState(null);
    const [revision, setRevision] = useState(null);
    const { isOpen,onOpen,onClose } = useDisclosure();
    const [filePath, setFilePath] = useState("");
    const handleViewPdf= async (link) => {
        try {
            setFilePath(link)
            onOpen()
        } catch (e) {
            console.log(e)
        }

    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fetchedPage = await pageService.getPageById(pageId);
                setPage(fetchedPage.data);
            } catch (error) {
                toast.closeAll();
                toast({
                    title: 'Error fetching page data',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                });
            }
        };
        if(pageId){
            fetchData();
        }

    }, [pageId, toast]);
    useEffect(() => {
        const getActiveRevision = async () => {
            try {
                if (page.revisions) {
                    setRevision(await page.revisions.find(revision => revision?.active));
                } else {
                    setRevision(null);
                }
            } catch (e) {
                setRevision(null);
            }
        };
        if(page){
            getActiveRevision();
        }
    }, [page]);


    return (
        <>
            {(page) ? (
                    <Box padding="20px">
                        {console.log(revision)}
                        <FormControl>
                            <FormLabel>Category</FormLabel>
                            <Text mb={4}>{page?.category}</Text>
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Parent Page</FormLabel>
                            <Text mb={4}>{page?.parentPage?.pageName  || 'No Parent Page'}</Text>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Page Name</FormLabel>
                            <Text mb={4}>{page?.pageName}</Text>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Window Title</FormLabel>
                            <Text mb={4}>{revision?.windowTitle}</Text>
                        </FormControl>
                        {!revision?.isForm&&

                        <FormControl mt={4}>
                            <FormLabel>Description</FormLabel>
                            <Box p={2} bg="gray.100" borderRadius="md">
                                <div class="ql-editor">
                                    <div dangerouslySetInnerHTML={{__html: revision?.description}}/>
                                </div>
                            </Box>
                        </FormControl>}
                        {revision&&revision.isForm&&
                            <SimpleGrid columns={{base:1,md:2}}>
                                {revision.url.map((link)=>(
                                    <Flex direction={'column'} bg={"white"} borderRadius={'2xl'} p={4} boxShadow={'md'}>
                                        <Text size={'sm'} fontWeight={'semibold'}>{link.title}</Text>
                                        <Flex justify={'center'} fontWeight={'semibold'} bg={'gray.200'} borderRadius={'md'} p={2} mt={4} >
                                            <Box cursor={'pointer'} textAlign={'center'} w={'full'} onClick={()=>handleViewPdf(link.link)}>Download</Box>
                                        </Flex>
                                    </Flex>
                                ))}

                            </SimpleGrid>
                        }
                    </Box>
                ) : (
                    <Text>Loading page details...</Text>
                )
            }
            <PdfViewerModal filePath={filePath} isOpen={isOpen} onOpen={onOpen} onClose={onClose}/>
        </>
    )
};

export default ViewPageForm;
