import React, { useEffect, useState } from 'react';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    Box,
    Text,
    SimpleGrid,
    Switch,
    Tooltip, Spinner, Flex, FormHelperText,
} from '@chakra-ui/react';
import { ThemedStyledInput } from "../../../../components/Styled";
import FormActionLabel from "../../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../../components/Styled/StyledButtons";
import { useDrawer } from '../../../../Context/DrawerContext/DrawerContext';
import { fetchAdminById, updateAdmin } from "../AdminAPI";
import { AdminPermissions } from "wuc-library/permissions";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ConfirmDialog from "../../../../Context/ConfirmDialog/ConfirmDialog";
import FieldErrorMessage from "../../../../components/FieldErrorMessage/FieldErrorMessage";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";
import {Icon} from "@iconify/react";
import {FiLock} from "react-icons/fi";

const AdminEditForm = ({ adminId, refetchAdminData, setRefreshFlag }) => {
    const { closeDrawer } = useDrawer();
    const showToast = useSingleToast();
    const [flag, setFlag] = useState(false);
    const [initialValues, setInitialValues] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        permissions: [],
    });
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    useEffect(() => {
        const fetchAndSetAdminData = async () => {
            if (flag) {
                try {
                    const adminData = await fetchAdminById(adminId);
                    if (adminData) {
                        setInitialValues({
                            firstName: adminData.firstName,
                            lastName: adminData.lastName,
                            phone: adminData.phone,
                            email: adminData.email,
                            permissions: adminData.permissions || [],
                        });
                    }
                } catch (error) {
                    showToast({
                        title: 'Error fetching admin data',
                        description: error.message || 'An unexpected error occurred.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                }
            }
            // Reset flag after execution
            setFlag(false);
        };

        fetchAndSetAdminData();
    }, [flag, adminId, showToast]);

    // Effect to trigger the fetch operation when adminId changes
    useEffect(() => {
        setFlag(true);
    }, [adminId]);



    const adminSchema = Yup.object().shape({
        firstName: Yup.string().required('First name is required.'),
        lastName: Yup.string().required('Last name is required.'),
        phone: Yup.string().matches(/^\d{8}$/, 'Valid phone number is required (8 digits).').required('Phone is required.'),
        email: Yup.string().email('Invalid email address').required('Email is required.'),
        permissions: Yup.array().min(1, 'At least one permission is required.'),
    });

    const handleSubmit = async (values, actions) => {
        try {
            await updateAdmin(adminId, values);
            showToast({
                title: 'Admin updated successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchAdminData();
            setRefreshFlag(prevFlag => !prevFlag);
            closeDrawer();
        } catch (error) {
            showToast({
                title: 'Error updating admin',
                description: error.message || 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            actions.setSubmitting(false);
        }
    };

    const requestConfirm = (options) => {
        setConfirmDialog({
            isOpen: true,
            title: options.title,
            message: options.message,
            onConfirm: options.onConfirm,
        });
    };

    const handleCancel = () => {
        requestConfirm({
            title: "Cancel Confirmation",
            message: "Are you sure you want to cancel? Any unsaved changes will be lost.",
            onConfirm: closeDrawer
        });
    };

    const handleConfirm = () => {
        confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    return (
        <>
            <Formik
                key={adminId}
                adminId={adminId}
                refetchAdminData={refetchAdminData}
                initialValues={initialValues}
                validationSchema={adminSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {(formikProps) => (
                    <Form>
                        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                            <FormActionLabel formAction="edit" formName="Admin User"/>
                            <SimpleGrid columns={3} gap={4} px={2} py={4}>
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.firstName && formikProps.touched.firstName}>
                                        <FormLabel htmlFor="firstName">First Name</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('firstName')}
                                            id="firstName"
                                            placeholder="Enter First Name"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.firstName && formikProps.touched.firstName && formikProps.errors.firstName}
                                        </Text>
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.lastName && formikProps.touched.lastName}>
                                        <FormLabel htmlFor="lastName">Last Name</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('lastName')}
                                            id="lastName"
                                            placeholder="Enter Last Name"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.lastName && formikProps.touched.lastName && formikProps.errors.lastName}
                                        </Text>
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.phone && formikProps.touched.phone}>
                                        <FormLabel htmlFor="phone">Phone</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('phone')}
                                            id="phone"
                                            placeholder="Enter Phone"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.phone && formikProps.touched.phone && formikProps.errors.phone}
                                        </Text>
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.email && formikProps.touched.email}>
                                        <FormLabel htmlFor="email">Email</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('email')}
                                            id="email"
                                            placeholder="Enter Email"
                                            readOnly
                                            bg="gray.100"
                                        />
                                        <Flex align="center" justify="space-between">
                                            <Text color="red.500" fontSize="sm">
                                                {formikProps.errors.email && formikProps.touched.email && formikProps.errors.email}
                                            </Text>
                                        </Flex>
                                        <FormHelperText>Note: Email cannot be changed.</FormHelperText>
                                    </FormControl>
                                </GridItem>
                                <GridItem colSpan={3}>
                                    <FormControl id="permissions">
                                        <PermissionsControl formikProps={formikProps} />
                                    </FormControl>
                                </GridItem>
                            </SimpleGrid>
                            <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}  mt={8} mb={8}>
                                <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
                                <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                                    <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                                        Cancel
                                    </CustomButton>
                                    <CustomButton
                                        type="submit"
                                        disabled={formikProps.isSubmitting}
                                        style={{
                                            opacity: formikProps.isSubmitting ? 0.7 : 1,
                                            pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                                        }}
                                    >
                                        {formikProps.isSubmitting ? (
                                            <>
                                                <Spinner size="xs" mr={2} />
                                                Updating...
                                            </>
                                        ) : 'Update'}
                                    </CustomButton>
                                </ButtonStack>
                            </Box>
                        </VStack>
                    </Form>
                )}
            </Formik>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

const PermissionsControl = ({ formikProps }) => {
    const allPermissionsChecked = formikProps.values.permissions.length === Object.values(AdminPermissions).length;

    const handleToggleAllPermissions = () => {
        if (allPermissionsChecked) {
            formikProps.setFieldValue('permissions', []);
        } else {
            formikProps.setFieldValue('permissions', Object.values(AdminPermissions));
        }
    };

    return (
        <GridItem colSpan={3}>
            <FormControl id="permissions" mt={4}>
                <FormLabel>Permissions</FormLabel>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={4}
                     p={3} borderWidth="1px" borderRadius="md" bg="blue.50" borderColor="blue.200">
                    <Text>Select All Permissions :</Text>
                    <Tooltip label="Select All Permissions" hasArrow placement="top">
                    <Switch
                        isChecked={allPermissionsChecked}
                        onChange={handleToggleAllPermissions}
                        size="lg"
                        colorScheme="blue"
                    />
                    </Tooltip>
                </Box>
                <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 5 }} spacing={5}>
                    {Object.entries(AdminPermissions).map(([key, permission]) => (
                        <Tooltip key={key} label={`Assign "${permission}" permission`} placement="top" hasArrow>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Text mr={2}>{permission}</Text>
                                <Switch
                                    isChecked={formikProps.values.permissions.includes(permission)}
                                    onChange={(e) => {
                                        const permissions = e.target.checked
                                            ? [...formikProps.values.permissions, permission]
                                            : formikProps.values.permissions.filter(p => p !== permission);
                                        formikProps.setFieldValue('permissions', permissions);
                                    }}
                                />
                            </Box>
                        </Tooltip>
                    ))}
                </SimpleGrid>
                <FieldErrorMessage name="permissions"/>
            </FormControl>
        </GridItem>
    );
};


export default AdminEditForm;
