import React, {useEffect, useRef, useState} from 'react';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    Box,
    Text, InputGroup, InputRightElement, IconButton, SimpleGrid, Select, Spinner, Switch, Tooltip, Flex, Badge
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useDrawer } from '../../../../Context/DrawerContext/DrawerContext';
import { createConsultant, fetchAllAreas } from "../ConsultantsAPI";
import { Icon } from "@iconify/react";
import {ThemedStyledInput} from "../../../../components/Styled";
import CustomAlert from "../../../../components/Styled/StyledAlert";
import {ButtonStack, CustomButton} from "../../../../components/Styled/StyledButtons";
import FormActionLabel from "../../../../components/Styled/FormActionLabel";
import ConfirmDialog from "../../../../Context/ConfirmDialog/ConfirmDialog";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";

const ConsultantAddForm = ({ refetchConsultantData, filterOptions}) => {
    const { closeDrawer } = useDrawer();
    const showToast = useSingleToast();
    const [areas, setAreas] = useState([]);
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });
    const showToastRef = useRef(showToast);



    useEffect(() => {
        const fetchAreas = async () => {
            try {
                const { areas } = await fetchAllAreas();
                setAreas(areas);
            } catch (error) {
                showToastRef.current({
                    title: 'Error',
                    description: 'Failed to fetch areas',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };
        fetchAreas();
    }, []);


    const consultantSchema = Yup.object().shape({
        firstName: Yup.string()
            .trim()
            .required('First name is required.')
            .min(2, 'First name must be at least 2 characters long'),
        lastName: Yup.string()
            .trim()
            .required('Last name is required.')
            .min(2, 'Last name must be at least 2 characters long'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required.'),
        status: Yup.boolean()
            .required('Status is required'),
        area: Yup.string()
            .required('Area is required.')
            .test(
                'is-not-blank',
                'Area cannot be blank',
                value => value?.trim().length > 0
            ),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const payload = {
                ...values,
                status: values.status ? 'active' : 'inactive',
            };
            await createConsultant(payload);
            showToast({
                title: 'Consultant added successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchConsultantData(filterOptions); // Trigger refetching of consultant data
            closeDrawer();
        } catch (error) {
            showToast({
                title: 'Error',
                description: error.message || 'An unexpected error occurred',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setSubmitting(false);
        }
    };

    const requestConfirm = (options) => {
        setConfirmDialog({
            isOpen: true,
            title: options.title,
            message: options.message,
            onConfirm: options.onConfirm,
        });
    };

    const handleConfirm = () => {
        confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    return (
        <>
            <FormActionLabel formAction="add" formName="Consultant" />
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    status: false, // true for active, false for inactive
                    area: ''
                }}
                validationSchema={consultantSchema}
                onSubmit={handleSubmit}
            >
                {formikProps => (
                    <Form>
                        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                            <SimpleGrid columns={1} gap={4} px={2} py={4}>
                                <FieldControl
                                    formikProps={formikProps}
                                    name="firstName"
                                    label="First Name"
                                    placeholder="Enter First Name"
                                    icon={<Icon icon="mingcute:user-edit-line" />}
                                />
                                <FieldControl
                                    formikProps={formikProps}
                                    name="lastName"
                                    label="Last Name"
                                    placeholder="Enter Last Name"
                                    icon={<Icon icon="mingcute:user-edit-line" />}
                                />
                                <FieldControl
                                    formikProps={formikProps}
                                    name="email"
                                    label="Email"
                                    placeholder="Enter Email Address"
                                    icon={<Icon icon="ic:outline-email" />}
                                />
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.area && formikProps.touched.area}>
                                        <FormLabel htmlFor="area">Area</FormLabel>
                                        <Field as={Select} name="area" placeholder="Select Area">
                                            {areas.map((area) => (
                                                <option key={area._id} value={area.name}>
                                                    {area.name}
                                                </option>
                                            ))}
                                        </Field>
                                        {formikProps.errors.area && formikProps.touched.area && (
                                            <Text color="red.500" fontSize="sm">{formikProps.errors.area}</Text>
                                        )}
                                    </FormControl>
                                </GridItem>

                                <GridItem>
                                    <FormControl display="flex" alignItems="center">
                                        <FormLabel htmlFor="status" mb="0" mr={4}>
                                            Status
                                        </FormLabel>
                                        <Flex alignItems="center">
                                            <Tooltip
                                                label={formikProps.values.status ? 'Deactivate consultant' : 'Activate consultant'}
                                                placement="top"
                                                hasArrow
                                            >
                                                <span>
                                                  <Switch
                                                      id="status"
                                                      {...formikProps.getFieldProps('status')}
                                                      isChecked={formikProps.values.status}
                                                      onChange={(e) => formikProps.setFieldValue('status', e.target.checked)}
                                                      mr={2} // Adjust space between switch and badge as needed
                                                  />
                                                </span>
                                            </Tooltip>
                                            <Badge ml={4} mt={1}
                                                   colorScheme={formikProps.values.status ? 'green' : 'red'}
                                            >
                                                {formikProps.values.status ? 'Activated' : 'Deactivated'}
                                            </Badge>
                                        </Flex>
                                    </FormControl>
                                </GridItem>

                            </SimpleGrid>
                            <AlertAndButtons
                                formikProps={formikProps}
                                closeDrawer={closeDrawer}
                                requestConfirm={requestConfirm}
                            />
                        </VStack>
                    </Form>
                )}
            </Formik>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

const FieldControl = ({ formikProps, name, label, placeholder, icon }) => (
    <GridItem>
        <FormControl isInvalid={formikProps.errors[name] && formikProps.touched[name]}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <InputGroup>
                <ThemedStyledInput
                    {...formikProps.getFieldProps(name)}
                    id={name}
                    placeholder={placeholder}
                />
                {icon && (
                    <InputRightElement>
                        <IconButton
                            aria-label="Icon button label"
                            bg="blue.300"
                            _hover={{ bg: '#69C5EC' }}
                            _active={{ bg: '#4699bc' }}
                            color="white"

                        >
                            {icon}
                        </IconButton>
                    </InputRightElement>

                )}
            </InputGroup>
            {formikProps.errors[name] && formikProps.touched[name] && (
                <Text color="red.500" fontSize="sm">{formikProps.errors[name]}</Text>
            )}
        </FormControl>
    </GridItem>
);

const AlertAndButtons = ({ formikProps, closeDrawer, requestConfirm }) => {

    const handleCancel = () => {
        requestConfirm({
            title: "Cancel Confirmation",
            message: "Are you sure you want to cancel? Any unsaved changes will be lost.",
            onConfirm: () => {
                formikProps.handleReset();
                closeDrawer();
            }
        });
    };

    const handleReset = () => {
        requestConfirm({
            title: "Reset Confirmation",
            message: "Are you sure you want to reset all your changes?",
            onConfirm: () => {
                formikProps.handleReset();
            }
        });
    };

    return (
        <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}>
            <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
            <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                    Cancel
                </CustomButton>
                <CustomButton onClick={handleReset} type="reset">
                    Reset
                </CustomButton>
                <CustomButton
                    type="submit"
                    disabled={formikProps.isSubmitting}
                    style={{
                        opacity: formikProps.isSubmitting ? 0.7 : 1,
                        pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                    }}
                >
                    {formikProps.isSubmitting ? (
                        <>
                            <Spinner size="xs" mr={2} />
                            Submitting...
                        </>
                    ) : 'Submit'}
                </CustomButton>
            </ButtonStack>
        </Box>
    );
};

export default ConsultantAddForm;
