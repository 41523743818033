import React, {useCallback} from 'react';
import {Flex, GridItem} from '@chakra-ui/react';
import DisconnectionAddForm from './DisconnectionAddForm';
import { StyledGrid } from "../../../../components/Styled/StyledComponents";
import { ButtonStack, CustomButton } from "../../../../components/Styled/StyledButtons";
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import {Icon} from "@iconify/react";
import DisconnectionSearchFilter from "./DisconnectionSearchFilter";

const DisconnectionTableTopBar = ({ refetchDisconnectionData, setPageNumber, setFilterOptions, setFetchTrigger }) => {
    const { openDrawer } = useDrawer();

    const handleFilterChange = useCallback(async (newFilterOptions) => {
        setPageNumber(1);
        await new Promise(resolve => setTimeout(resolve, 0));
        setFilterOptions(newFilterOptions);
        setFetchTrigger(f => !f);
    }, [setPageNumber, setFilterOptions, setFetchTrigger]);

    return (
        <>
            <StyledGrid templateColumns="repeat(12, 1fr)">
                <GridItem colSpan={{ base: 10, md: 11 }}>
                    <DisconnectionSearchFilter onFilterChange={handleFilterChange} />
                <Flex bg="white" mb={4}></Flex>
                </GridItem>
                <GridItem colSpan={{ base: 2, md: 1 }}>
                    <ButtonStack direction="row">
                        <CustomButton
                            type="addNew"
                            showIcon={true}
                            showTooltip={true}
                            tooltipLabel="Add New Disconnection Request"
                            icon={<Icon icon="mdi:water-off-outline" />}
                            onClick={() => openDrawer({
                                title: 'Add New Disconnection Request',
                                component: DisconnectionAddForm,
                                props: { refetchDisconnectionData }
                            }, 'xl')}
                        >
                            Add New
                        </CustomButton>
                    </ButtonStack>
                </GridItem>
            </StyledGrid>
            <Flex bg="white" mb={4}></Flex>
        </>
    );
};

export default DisconnectionTableTopBar;
