import React, { useState } from 'react';
import {
    FormControl,
    FormLabel,
    VStack,
    useToast,
    Box,
    Text,
    SimpleGrid,
    Spinner,
    Grid,
    GridItem,
    Flex,
    Image,
    Input,
    InputGroup,
    Select,
    Switch,
    IconButton,
    Center,
    Textarea
} from '@chakra-ui/react';
import DropBox from "../../../components/DropBox/DropBox";
import FormActionLabel from "../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../components/Styled/StyledButtons";
import FieldControl from '../../../components/Styled/FieldControl';
import { useDrawer } from '../../../Context/DrawerContext/DrawerContext';
import { Formik, Form, Field } from 'formik';
import ConfirmDialog from "../../../Context/ConfirmDialog/ConfirmDialog";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { addNews } from "../NewsAPI";
import SectionHeading from "../../../components/SectionHeading/SectionHeading";
import utils from "../../../utils/commonFunctions";
import { CloseIcon } from '@chakra-ui/icons';

const NewsAddForm = ({ refetchNewsData }) => {
    const { closeDrawer } = useDrawer();
    const [descriptionValue, setDescriptionValue] = useState('');
    const toast = useToast({ position: 'top-right' });
    const [loading, setLoading] = useState(false);
    const [embedCode, setEmbedCode] = useState('');
    const newschema = {
        title: '',
        description: '',
        images: [],
        videoEmbed: ''
    };

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => { },
    });

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        if (!values.title) {
            toast({
                title: 'News title is required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        if (!values.description) {
            toast({
                title: 'News description is required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }
        try {
            await addNews(JSON.stringify(values));
            toast({
                title: 'News added successfully',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
            resetForm();
            refetchNewsData();
            closeDrawer();
        } catch (error) {
            if (error.response) {
                toast({
                    title: 'Error adding news',
                    description: error.response.data.message || 'An unexpected server error occurred',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } else if (error.request) {
                toast({
                    title: 'Network Error',
                    description: 'Unable to connect to the server. Please check your internet connection.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: 'Error',
                    description: error.message || 'An unexpected error occurred',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } finally {
            setSubmitting(false);
        }
    };

    const requestConfirm = (options) => {
        setConfirmDialog({
            isOpen: true,
            title: options.title,
            message: options.message,
            onConfirm: options.onConfirm,
        });
    };

    const handleConfirm = () => {
        confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const isValidFileType = (file) => {
        const validImageTypes = ['image/jpeg', 'image/png'];
        if (validImageTypes.includes(file.type)) {
            return {
                valid: true,
                type: 'image'
            };
        }
        return {
            valid: false,
            type: 'invalid'
        };
    };

    const handleFileChange = async (fieldName, event, setFieldValue, formikProps) => {
        const file = event.target.files[0];
        if (file) {
            setLoading(true);
            try {
                if (file.size > 2097152) {
                    toast({
                        title: 'File size too large',
                        description: 'Please upload files less than 2MB in size.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                    setLoading(false);
                    return;
                }

                const validFileType = isValidFileType(file);
                if (!validFileType.valid) {
                    toast({
                        title: 'Invalid file type',
                        description: 'Please upload images only.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                    setLoading(false);
                    return;
                }

                const uploadResponse = await utils.handleFileUpload(file, `news/${validFileType.type}/${Date.now()}`);
                if (uploadResponse && uploadResponse.data && uploadResponse.data.fileUrl) {
                    if (validFileType.type === 'image') {
                        setFieldValue(`images`, [...formikProps.values.images, uploadResponse.data.fileUrl]);
                        toast({
                            title: 'File uploaded successfully',
                            description: 'Your file has been uploaded.',
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                        });
                    } else {
                        toast({
                            title: 'File upload failed',
                            description: 'Only images allowed.',
                            status: 'danger',
                            duration: 5000,
                            isClosable: true,
                        });
                    }
                } else {
                    setFieldValue(`files.${fieldName}.error`, 'Upload failed. Incomplete response received.');
                    toast({
                        title: 'File upload failed',
                        description: 'An error occurred while uploading the file. Please try again.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                toast({
                    title: 'File upload error',
                    description: error.message || 'An unexpected error occurred while uploading the file.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                console.error("File upload error:", error);
                setFieldValue(`files.${fieldName}.error`, 'Upload failed: ' + error.message);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleDelete = (formikProps, index) => {
        let images = formikProps.values.images.filter((_, imgIndex) => imgIndex !== index);
        formikProps.setFieldValue('images', images);
    };


    return (
        <>
            <Formik initialValues={newschema} onSubmit={handleSubmit}>
                {formikProps => (
                    <Form>
                        <VStack spacing={4} align="stretch">
                            <FormActionLabel formAction="add" formName="News" />
                            <SimpleGrid columns={1} gap={4} px={2} py={4}>
                                <FieldControl isRequired={true} formikProps={formikProps} name="title" label="Title" placeholder="Enter news title" />
                                <FormControl isRequired={true}>
                                    <FormLabel htmlFor="description">Description</FormLabel>
                                    <ReactQuill
                                        theme="snow"
                                        value={formikProps.values.description}
                                        onChange={(value) => {
                                            formikProps.setFieldValue('description', value);
                                        }}
                                    />
                                </FormControl>
                                <SectionHeading icon="mdi:account-file-text" text="Upload News Image" />
                                <Grid templateColumns="repeat(12, 1fr)" gap={1} color={"blue.300"} px={4}>
                                    <GridItem colSpan={{ base: 12, md: 12 }}>
                                        <DropBox
                                            label={"News Images"}
                                            isRequired={false}
                                            name="images"
                                            loading={loading}
                                            formikProps={formikProps}
                                            callback={(event) => handleFileChange('images', event, formikProps.setFieldValue, formikProps)}
                                            instruction={`
                                                Please attach images only png, jpeg.
                                                Max size 2MB.
                                            `}
                                        />
                                    </GridItem>
                                </Grid>
                                <CustomAlert status="info" message="This section is for news images." />
                                {formikProps.values.images.length > 0 ? (

                                        <SimpleGrid columns={4}>
                                            {formikProps.values.images.map((image, index) => (
                                                <React.Fragment key={index}>
                                                    <Box position="relative" mr="2" mb={2} bg={'gray.100'}  shadow={'md'} rounded={'md'}>
                                                    <Image src={image} alt={`Image ${index + 1}`} boxSize="contain" bgSize={'full'} />
                                                    <IconButton
                                                        icon={<CloseIcon />}
                                                        size="xs"
                                                        aria-label="Delete image"
                                                        position="absolute"
                                                        bg={'tomato'}
                                                        top="0"
                                                        right="0"
                                                        onClick={() => handleDelete(formikProps,index)}
                                                    />
                                                    </Box>
                                                </React.Fragment>
                                            ))}
                                        </SimpleGrid>

                                ) : (
                                    <Center>
                                        <Text color="gray.500" fontSize="sm" mt={2}>No image uploaded</Text>
                                    </Center>
                                )}

                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.videoEmbed && formikProps.touched.videoEmbed}>
                                        <FormLabel htmlFor="videoEmbed">Embed Video Code</FormLabel>
                                        <Field name="videoEmbed">
                                            {({ field }) => (
                                                <Textarea
                                                    {...field}
                                                    id="videoEmbed"
                                                    placeholder="Enter Embed Code"
                                                    borderWidth="2px"
                                                    focusBorderColor="#63B3ED"
                                                />
                                            )}
                                        </Field>
                                        {formikProps.errors.videoEmbed && formikProps.touched.videoEmbed && (
                                            <Text color="red.500" fontSize="sm">{formikProps.errors.videoEmbed}</Text>
                                        )}
                                        {formikProps.values.videoEmbed && (
                                            <Box mt="4">
                                                <Text fontSize="md" mb="2">Preview:</Text>
                                                <Box as="iframe" srcDoc={formikProps.values.videoEmbed} width="100%" height="200px" frameBorder="0" allowFullScreen></Box>
                                            </Box>
                                        )}
                                        <CustomAlert
                                            status="info"
                                            message={
                                                embedCode ?
                                                    "A YouTube embed code is already added. You can preview it below or update it by pasting a new embed code in the field below." :
                                                    "Please enter the YouTube embed code for your video. Find this by clicking 'Share' on YouTube, selecting 'Embed', and copying the code provided."
                                            }
                                        />
                                    </FormControl>
                                </GridItem>
                            </SimpleGrid>
                            <AlertAndButtons formikProps={formikProps} closeDrawer={closeDrawer} requestConfirm={requestConfirm} />
                        </VStack>
                    </Form>
                )}
            </Formik>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

const AlertAndButtons = ({ formikProps, closeDrawer, requestConfirm }) => {
    const handleCancel = () => {
        requestConfirm({
            title: "Cancel Confirmation",
            message: "Are you sure you want to cancel? Any unsaved changes will be lost.",
            onConfirm: () => {
                formikProps.handleReset();
                closeDrawer();
            }
        });
    };

    const handleReset = () => {
        requestConfirm({
            title: "Reset Confirmation",
            message: "Are you sure you want to reset all your changes?",
            onConfirm: () => {
                formikProps.handleReset();
            }
        });
    };

    return (
        <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}>
            <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
            <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                    Cancel
                </CustomButton>
                <CustomButton onClick={handleReset} type="reset">
                    Reset
                </CustomButton>
                <CustomButton
                    type="submit"
                    disabled={formikProps.isSubmitting}
                    style={{
                        opacity: formikProps.isSubmitting ? 0.7 : 1,
                        pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                    }}
                >
                    {formikProps.isSubmitting ? (
                        <>
                            <Spinner size="xs" mr={2} />
                            Submitting...
                        </>
                    ) : 'Submit'}
                </CustomButton>
            </ButtonStack>
        </Box>
    );
};

export default NewsAddForm;
