import React, { useState, useEffect } from 'react';
import {
    FormControl,
    FormLabel,
    VStack,
    useToast,
    Box,
    Text, SimpleGrid, Spinner,
    Radio, RadioGroup,
    Stack, Flex, Badge,
    List,
    ListItem,
    InputGroup,
    Input,
} from '@chakra-ui/react';
import FormActionLabel from "../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../components/Styled/StyledButtons";
import FieldControl from '../../../components/Styled/FieldControl';
import { useDrawer } from '../../../Context/DrawerContext/DrawerContext';
import { Formik, Form, Field } from 'formik';
import ConfirmDialog from "../../../Context/ConfirmDialog/ConfirmDialog";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import styles
import { updateSurvey } from "../SurveyAPI";
import SurveyEditQuestionForm from './SurveyQuestionEditForm';
import { DeleteButton } from '../../../components/Styled/ActionButtons';
import { Icon } from '@iconify/react';

const SurveyEditForm = ({ surveyData, refetchSurveyData, pollData }) => {
    const { closeDrawer } = useDrawer();
    const [descriptionValue, setDescriptionValue] = useState('');
    const toast = useToast({ position: 'top-right' });
    const [options, setOptions] = useState([]);
    const [value, setValue] = React.useState('Published');
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [question, setQuestion] = useState({});
    // const [editIndex, setEditIndex] = useState(null);

    const handleOpen = () =>{
        setQuestion({}); // Reset question
         setModalOpen(true);
    }
    const handleClose = () => setModalOpen(false);

    const updateQuestions = (newQuestion) => {

        console.log('New Question:', newQuestion)

        // Check if question already exists
        const index = questions.findIndex(q => q.question === newQuestion.question);
        
        console.log('Index:', index);

        setQuestions((prevQuestions) => [...prevQuestions, newQuestion]);
    };

    var [surveySchema, setSurveySchema] = useState({
        title: '',
        description: '',
        polls: [],
        published: 'Published',
        start_date: '',
        closing_date: ''
    });

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => { },
    });

    /**
     * Adds start_date and end_date fields to each object in the array.
     * @param {Array} arr - The array of objects.
     * @param {string} start_date - The start date to be added to each object.
     * @param {string} end_date - The end date to be added to each object.
     * @returns {Array} - The modified array with added date fields.
     */
    const addDates = (arr, start_date, closing_date) => {
        return arr.map(item => ({
            ...item,
            start_date: start_date,
            closing_date: closing_date,
            forSurvey: true
        }));
    };

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        if (values.title === null || values.title === '') {
            toast({
                title: 'Survey title is required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (descriptionValue === null || descriptionValue === '') {
            toast({
                title: 'Survey title is required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (questions === null || questions?.length === 0 || questions == []) {
            toast({
                title: 'Survey questions are required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (questions.length < 2) {
            toast({
                title: 'At least 2 survey questions are required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (values.start_date === null || values.start_date === '') {
            toast({
                title: 'Start date is required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (values.closing_date === null || values.closing_date === '') {
            toast({
                title: 'Closing date is required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        console.log('start_date: ', values.start_date);

        // return;

        try {
            await updateSurvey(surveyData._id, JSON.stringify({
                "title": values.title,
                "description": descriptionValue,
                "polls": addDates(questions, values.start_date, values.closing_date), // Add start_date and closing_date to each poll
                "status": values.published,
                "closing_date": values.closing_date,
                "start_date": values.start_date
            }));
            toast({
                title: 'Survey updated successfully',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
            resetForm();
            refetchSurveyData(); // Trigger refetching of polls data
            closeDrawer();
        } catch (error) {
            if (error.response) {
                // Server error with response
                toast({
                    title: 'Error adding survey',
                    description: error.response.data.message || 'An unexpected server error occurred',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } else if (error.request) {
                // Network error without response
                toast({
                    title: 'Network Error',
                    description: 'Unable to connect to the server. Please check your internet connection.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                // Other errors
                toast({
                    title: 'Error',
                    description: error.message || 'An unexpected error occurred',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } finally {
            setSubmitting(false);
        }
    };

    const requestConfirm = (options) => {
        setConfirmDialog({
            isOpen: true,
            title: options.title,
            message: options.message,
            onConfirm: options.onConfirm,
        });
    };

    const handleConfirm = () => {
        confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleChange = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
    };

    useEffect(() => {
        if (surveyData) {
            setSurveySchema({
                title: surveyData.title,
                description: surveyData.description,
                polls: surveyData.polls,
                published: surveyData.status,
                start_date: surveyData?.polls[0]?.start_date ? surveyData?.polls[0]?.start_date.split('T')[0]: new Date(),
                closing_date: surveyData.closing_date ? surveyData.closing_date.split('T')[0] : new Date()
            });
            setDescriptionValue(surveyData.description);
            setQuestions(surveyData.polls); // Initialize questions with surveyData.polls
        }
        
        if (pollData) {
            const transformedOptions = pollData.map(poll => ({
                value: poll.question,
                label: poll.question,
                id: poll._id,
            }));

            setOptions(transformedOptions);
        }
    }, [surveyData, pollData]);

    const deleteOption = (index) => {
        setQuestions((prevQuestions) => prevQuestions.filter((_, i) => i !== index));
    };

    const editOption = (question) => {
        console.log('Edit:', question);
        setQuestion(question);
        setModalOpen(true);
    };

    const removePreviousQuestion = (poll) => {

        console.log('Poll:', poll);
        //find poll by poll.question from questions array
        const index = questions.findIndex(q => q.question === poll.question);
        console.log('Index:', index);
        //delete poll from questions array
        deleteOption(index);
    };

    const truncateText = (text, limit = 30) => {
        if (text.length <= limit) {
            return text;
        }
        return text.substring(0, limit - 3) + '...';
    };

    const handleDate = (e) => {
        const { value } = e.target;
        let minDate = getMinAgeDate()
        if (minDate < value) {
            // setDate(getMinAgeDate())
            setSurveySchema(prevState => ({
                ...prevState, closing_date: getMinAgeDate()
            }));
        }
        else {
            // setDate(value)
            setSurveySchema(prevState => ({
                ...prevState, closing_date: value
            }));
        }
    };

    // a minDate function to get the minimum date
    const getMinAgeDate = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();
        return `${yyyy}-${mm}-${dd}`;
    };

    return (
        <>
            <Formik
                initialValues={surveySchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {formikProps => (
                    <Form>
                        <VStack spacing={4} align="stretch">
                            <FormActionLabel formAction="edit" formName="Survey" />
                            <SimpleGrid columns={1} gap={4} px={2} py={4}>
                                <FieldControl formikProps={formikProps} name="title" label="Title" placeholder="Enter survey title" />
                                <Box>
                                    <FormLabel htmlFor="description">Description</FormLabel>
                                    <ReactQuill
                                        theme="snow"
                                        value={descriptionValue}
                                        onChange={setDescriptionValue}
                                    />
                                </Box>

                                <RadioGroup onChange={setValue} value={value} mb={4}>
                                    <FormLabel htmlFor="description">Status</FormLabel>
                                    <Stack direction='row'>
                                        <Radio value='Published'>Published</Radio>
                                        <Radio value='Closed'>Closed</Radio>
                                    </Stack>
                                </RadioGroup>

                                <CustomAlert status="warning" message="Add questions that will appear on this survey. At least 2 questions are required." />

                                <SurveyEditQuestionForm
                                    modalTitle="Edit"
                                    updateQuestions={updateQuestions}
                                    removePreviousQuestion={removePreviousQuestion}
                                    poll={question}
                                    isOpen={isModalOpen}
                                    onOpen={handleOpen}
                                    onClose={handleClose}
                                />

                                <List spacing={3}>
                                    {questions?.map((q, index) => (
                                        <ListItem 
                                            key={index} 
                                            cursor='pointer'
                                            _hover={{ bg: 'gray.100' }}
                                        >
                                            <Flex>
                                                <Badge ml='1' colorScheme='green'>
                                                    <Text fontSize='lg'>{index + 1}. </Text>
                                                </Badge>
                                                <Badge 
                                                    variant='outline' 
                                                    colorScheme='green' 
                                                    style={{ textTransform: 'none' }}
                                                    ml='1'
                                                    onClick={() => editOption(q)}
                                                >
                                                    <Text fontSize='lg' noOfLines={[1, 2, 3]}>{truncateText(q.question, 39)}</Text>
                                                </Badge>
                                                <DeleteButton onClick={() => deleteOption(index)} />
                                            </Flex>
                                        </ListItem>
                                    ))}
                                </List>

                                <CustomButton
                                    type="button"
                                    showIcon={true}
                                    showTooltip={true}
                                    tooltipLabel="Add Option"
                                    icon={<Icon icon="ci:plus" />}
                                    onClick={handleOpen}
                                >
                                    Add a survey question
                                </CustomButton>

                                <Flex>
                                <FormControl id="start_date" isRequired>
                                    <FormLabel >Start Date</FormLabel>
                                    <InputGroup>
                                        <Input
                                            type="date"
                                            name="start_date"
                                            id="start_date"
                                            min={getMinAgeDate()}
                                            value={formikProps.values.start_date}
                                            onChange={(event) => {
                                                formikProps.handleChange(event);
                                                // handleDate(event);
                                            }}
                                        />
                                    </InputGroup>
                                </FormControl>
                                <FormControl id="closing_date" isRequired>
                                    <FormLabel>Closing Date</FormLabel>
                                    <InputGroup>
                                        <Input
                                            type="date"
                                            name="closing_date"
                                            id="closing_date"
                                            min={formikProps.values.start_date}
                                            value={formikProps.values.closing_date}
                                            onChange={(event) => {
                                                formikProps.handleChange(event);
                                                handleDate(event);
                                            }}
                                        />
                                    </InputGroup>
                                </FormControl>
                                </Flex>
                            </SimpleGrid>
                            <AlertAndButtons
                                formikProps={formikProps}
                                closeDrawer={closeDrawer}
                                requestConfirm={requestConfirm}
                            />
                        </VStack>
                    </Form>
                )}
            </Formik>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

const AlertAndButtons = ({ formikProps, closeDrawer, requestConfirm }) => {
    const handleCancel = () => {
        requestConfirm({
            title: "Cancel Confirmation",
            message: "Are you sure you want to cancel? Any unsaved changes will be lost.",
            onConfirm: () => {
                formikProps.handleReset();
                closeDrawer();
            }
        });
    };

    const handleReset = () => {
        requestConfirm({
            title: "Reset Confirmation",
            message: "Are you sure you want to reset all your changes?",
            onConfirm: () => {
                formikProps.handleReset();
            }
        });
    };

    return (
        <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}>
            <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
            <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                    Cancel
                </CustomButton>
                <CustomButton onClick={handleReset} type="reset">
                    Reset
                </CustomButton>
                <CustomButton
                    type="submit"
                    disabled={formikProps.isSubmitting}
                    style={{
                        opacity: formikProps.isSubmitting ? 0.7 : 1,
                        pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                    }}
                >
                    {formikProps.isSubmitting ? (
                        <>
                            <Spinner size="xs" mr={2} />
                            Submitting...
                        </>
                    ) : 'Submit'}
                </CustomButton>
            </ButtonStack>
        </Box>
    );
};

export default SurveyEditForm;
