import React from 'react'
import SurveyContainer from './SurveyContainer'
import {Icon} from "@iconify/react";
import DefaultTemplate from "../../../components/DefaultTemplate/DefaultTemplate";

const Surveys = () => {
    return (
        <>
            <DefaultTemplate Content={ <SurveyContainer/> } Title={"Surveys" } icon={<Icon icon="mdi:poll" />}/>
        </>
    )
}

export default Surveys
