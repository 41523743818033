import React from 'react';
import DefaultTemplate from '../../../components/DefaultTemplate/DefaultTemplate';
import PhotoMenuGrid from './PhotoMenuGrid'; // Adjust path as necessary
import { Icon } from "@iconify/react";

const MediaMenu = ({ fileType }) => {
     

    return (
        <DefaultTemplate
            Content={<PhotoMenuGrid />}
            Title={"Photo Management"} 
        />
    );
};

export default MediaMenu;
