import React, {useEffect, useRef, useState} from 'react';
import {Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {FormControl, Select, Stack} from '@chakra-ui/react';
import {ThemedStyledInput} from "../../../../components/Styled";
import {CustomButton} from "../../../../components/Styled/StyledButtons";
import Icon from "../../../../@core/components/icon";
import FieldErrorMessage from "../../../../components/FieldErrorMessage/FieldErrorMessage";
import faqCategories from "./FaqCategories";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";

const validationSchema = Yup.object({
    question: Yup.string()
        .trim('Search & Filters Error: Question cannot be just whitespace')
        .strict(true),
});

const FaqSearchFilter = ({ onFilterChange }) => {
    const showToast = useSingleToast();
    const showToastRef = useRef(showToast);

    const faqCategoryOptions = Object.entries(faqCategories).map(([key, value]) => ({
        id: key,
        name: value
    }));

    return (
        <Formik
            initialValues={{ question: '', category: '', published: '' }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                if (!values.question && !values.category && !values.published) {
                    onFilterChange({ question: '', category: '', published: '' });
                } else {
                    onFilterChange(values);
                }
                setSubmitting(false);
            }}
            onReset={() => {
                onFilterChange({ question: '', category: '', published: '' });
            }}
        >
            {({ resetForm, handleSubmit }) => (
                <Form>
                    <Stack spacing={2} direction="row" align="center">
                        <FormControl flex="2">
                            <Field
                                as={ThemedStyledInput}
                                id="question"
                                name="question"
                                type="text"
                                placeholder="Search by Question"
                            />
                            <FieldErrorMessage name="question" />
                        </FormControl>
                        <FormControl flex="1">
                            <Field as={Select} name="category" placeholder=" -- Select Category --">
                                {faqCategoryOptions.map(({ id, name }) => (
                                    <option key={id} value={name}>{name}</option>
                                ))}
                            </Field>
                        </FormControl>
                        <FormControl flex="1">
                            <Field
                                as={Select}
                                name="published"
                                placeholder="Filter by Status"
                            >
                                <option value="true">Published</option>
                                <option value="false">Unpublished</option>
                            </Field>
                        </FormControl>
                        <CustomButton
                            onClick={handleSubmit}
                            type="search"
                            tooltipLabel="Search"
                            icon={<Icon icon="bi:search" />}
                            showLabel={false}
                        >
                            Search
                        </CustomButton>
                        <CustomButton
                            type="reset"
                            onClick={() => {
                                resetForm();
                                onFilterChange({ question: '', category: '', published: '' });
                            }}
                            tooltipLabel="Clear Filters"
                            icon={<Icon icon="radix-icons:reset" />}
                            showLabel={false}
                        >
                            Clear
                        </CustomButton>
                    </Stack>
                </Form>
            )}
        </Formik>
    );
};

export default FaqSearchFilter;
