import React, {useEffect, useRef, useState} from 'react';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    Box,
    Text,
    SimpleGrid,
    Select,
    InputGroup,
    InputRightElement,
    IconButton, Spinner, Textarea
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useDrawer } from '../../../../Context/DrawerContext/DrawerContext';
import { fetchBranchById, updateBranch } from "../BranchesAPI";
import { Icon } from "@iconify/react";
import { ThemedStyledInput } from "../../../../components/Styled";
import CustomAlert from "../../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../../components/Styled/StyledButtons";
import FormActionLabel from "../../../../components/Styled/FormActionLabel";
import ConfirmDialog from "../../../../Context/ConfirmDialog/ConfirmDialog";
import TextEditor from "../../../../Context/TextEditor/TextEditor";
import {fetchAllAreas} from "../../Consultants/ConsultantsAPI";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";

const BranchEditForm = ({ branchId, refetchBranchData }) => {
    const notifyUserRef = useRef();
    const { closeDrawer } = useDrawer();
    const showToast = useSingleToast();
    const [areas, setAreas] = useState([]);
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });
    const [initialValues, setInitialValues] = useState({
        name: '',
        areaId: '',
        description: '',
        branchMap: ''
    });

    notifyUserRef.current = (options) => {
        showToast(options);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const branchData = await fetchBranchById(branchId);
                const areasData = await fetchAllAreas();
                if (branchData && areasData) {
                    setInitialValues({
                        name: branchData.name,
                        areaId: branchData.areaId,
                        description: branchData.description || '',
                        branchMap: branchData.branchMap || ''
                    });
                    setAreas(areasData.areas);
                }
            } catch (error) {
                notifyUserRef.current({
                    title: 'Error',
                    description: 'Failed to fetch data',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };
        fetchData();
    }, [branchId]);

    const branchSchema = Yup.object().shape({
        name: Yup.string().required('Branch name is required.'),
        areaId: Yup.string().required('Area is required.'),
        description: Yup.string(),
        branchMap: Yup.string()
    });

    const handleSubmit = async (values, actions) => {
        try {
            await updateBranch(branchId, values);
            showToast({
                title: 'Branch updated successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchBranchData(); // Refresh the data
            closeDrawer();
        } catch (error) {
            showToast({
                title: 'Error',
                description: error.message || 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            actions.setSubmitting(false);
        }
    };

    const handleCancel = () => {
        setConfirmDialog({
            isOpen: true,
            title: "Cancel Confirmation",
            message: "Are you sure you want to cancel? Any unsaved changes will be lost.",
            onConfirm: () => {
                closeDrawer();
                setConfirmDialog({ ...confirmDialog, isOpen: false }); // Close dialog after confirmation
            }
        });
    };

    const handleConfirm = () => {
        confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    return (
        <>
            <FormActionLabel formAction="edit" formName="Branch" />
            <Formik
                initialValues={initialValues}
                validationSchema={branchSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ setFieldValue, ...formikProps }) => (
                    <Form>
                        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                            <SimpleGrid columns={1} gap={4} px={2} py={4}>
                                <FieldControl
                                    formikProps={formikProps}
                                    name="name"
                                    label="Branch Name"
                                    placeholder="Enter Branch Name"
                                    icon={<Icon icon="grommet-icons:map" />}
                                />
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.areaId && formikProps.touched.areaId}>
                                        <FormLabel htmlFor="areaId">Area</FormLabel>
                                        <Field as={Select} name="areaId" placeholder="Select Area" borderWidth="2px">
                                            {areas.map((area) => (
                                                <option key={area._id} value={area._id}>
                                                    {area.name}
                                                </option>
                                            ))}
                                        </Field>
                                        {formikProps.errors.areaId && formikProps.touched.areaId && (
                                            <Text color="red.500" fontSize="sm">{formikProps.errors.areaId}</Text>
                                        )}
                                    </FormControl>
                                </GridItem>

                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.description && formikProps.touched.description}>
                                        <FormLabel htmlFor="description">Description</FormLabel>
                                        <Field name="description">
                                            {({ field }) => (
                                                <TextEditor
                                                    value={field.value}
                                                    onChange={(value) => setFieldValue('description', value)}
                                                    height="200px"
                                                />
                                            )}
                                        </Field>
                                        {formikProps.errors.description && formikProps.touched.description && (
                                            <Text color="red.500" fontSize="sm">{formikProps.errors.description}</Text>
                                        )}
                                    </FormControl>
                                </GridItem>

                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.branchMap && formikProps.touched.branchMap}>
                                        <FormLabel htmlFor="branchMap">Branch Map URL</FormLabel>
                                        <Field name="branchMap">
                                            {({ field }) => (
                                                <Textarea {...field} id="branchMap" placeholder="Enter Branch Map URL" borderWidth="2px" focusBorderColor="#63B3ED"/>
                                            )}
                                        </Field>
                                        {formikProps.errors.branchMap && formikProps.touched.branchMap && (
                                            <Text color="red.500" fontSize="sm">{formikProps.errors.branchMap}</Text>
                                        )}
                                    </FormControl>
                                </GridItem>
                            </SimpleGrid>
                            <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}  mt={8} mb={8}>
                                <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
                                <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                                    <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                                        Cancel
                                    </CustomButton>
                                    <CustomButton
                                        type="submit"
                                        disabled={formikProps.isSubmitting}
                                        style={{
                                            opacity: formikProps.isSubmitting ? 0.7 : 1,
                                            pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                                        }}
                                    >
                                        {formikProps.isSubmitting ? (
                                            <>
                                                <Spinner size="xs" mr={2} />
                                                Updating...
                                            </>
                                        ) : 'Update'}
                                    </CustomButton>
                                </ButtonStack>
                            </Box>
                        </VStack>
                    </Form>
                )}
            </Formik>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

const FieldControl = ({ formikProps, name, label, placeholder, icon }) => (
    <GridItem>
        <FormControl isInvalid={formikProps.errors[name] && formikProps.touched[name]}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <InputGroup>
                <ThemedStyledInput
                    {...formikProps.getFieldProps(name)}
                    id={name}
                    placeholder={placeholder}
                />
                {icon && (
                    <InputRightElement>
                        <IconButton
                            aria-label="Icon button label"
                            bg="blue.300"
                            _hover={{ bg: '#69C5EC' }}
                            _active={{ bg: '#4699bc' }}
                            color="white"
                        >
                            {icon}
                        </IconButton>
                    </InputRightElement>
                )}
            </InputGroup>
            {formikProps.errors[name] && formikProps.touched[name] && (
                <Text color="red.500" fontSize="sm">{formikProps.errors[name]}</Text>
            )}
        </FormControl>
    </GridItem>
);




export default BranchEditForm;
