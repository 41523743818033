import {extendTheme} from "@chakra-ui/react";

const focusStyles = {
    _focus: {
        borderColor: '#63B3ED',
        borderWidth: '2px',
        boxShadow: 'none',
    },
};

const customTheme = extendTheme({
    colors: {
        blue: {
            300: '#63B3ED',
        },
        green: {
            50: '#ecf4ee',
            300: '#217645',
        },
    },
    components: {
        Badge: {
            baseStyle: {
                fontSize: '9px',
                letterSpacing: '1px',
            },
        },
        Alert: {
            variants: {
                lightBlue: {
                    container: {
                        color: 'white',
                        bg: 'blue.300',
                    },
                },
            },
        },
        Breadcrumb: {
            baseStyle: {
                container: {
                    spacing: '8px',
                    borderRadius: 'md',
                },
            },
            BreadcrumbLink: {
                baseStyle: {
                    textDecoration: 'none',
                    fontSize: '16px', // Consider using responsive values directly in the theme if necessary
                    fontWeight: 'normal',
                },
            },
        },
        Link: {
            baseStyle: {
                color: 'black !important', // default link color
                _hover: {
                    textDecoration: 'none',
                    color: 'black', // you can change this to any color you want on hover
                },
            },
        },
        Table: {
            variants: {
                striped: {
                    th: {
                        // Your style overrides for `th` in the simple variant
                    },
                    td: {
                        py: '6px',
                        color: 'gray.500'
                    },
                },
            },
        },
        Modal: {
            baseStyle: {
                dialog: {
                    boxShadow: '2xl',
                    p:'20px',
                    mt: 32,
                    borderRadius: '15px',
                    maxW: { base: "xs", sm: "sm", md: "md", lg: "md", xl: "md" },
                    border: "1px solid gray.200",
                },
                footer: {
                    display: 'flex',
                    justifyContent: 'right',
                },
                overlay: {
                    bg: 'transparent',
                }
            },
        },
        FormLabel: {
            baseStyle: {
                fontWeight: 600,
                color: "gray.800",
            },
        },
        Button: {
            variants: {
                solid: (props) => ({
                    /*bg: 'blue.300',
                    color: 'white',
                    _hover: {
                        bg: 'blue.500',
                    },
                    _active: {
                        bg: 'blue.600',
                    },
                    _focus: {
                        boxShadow: 'none',
                    },*/
                }),
                ghost: (props) => ({
                    /*bg: 'white',
                    color: 'gray.500',
                    display: 'inline-flex',
                    alignItems: 'center',
                    fontSize: 'md',
                    _hover: {
                        color: 'gray.800',
                    },
                    _focus: {
                        boxShadow: 'none',
                    },*/
                }),
                view: (props) => ({
                    /*color: "white",
                    bg: "#63B3ED",
                    _hover: { bg: "#63B3ED" },
                    _active: { bg: "#63B3ED" },*/
                }),
                edit: (props) => ({
                    /*bg: "green.500", // Assuming you want a green background
                    color: "white",
                    _hover: { bg: "green.600" },*/
                }),
                outline: (props) => ({
                   /* _focus: {
                        boxShadow: "none",
                    }*/
                })
            },
        },
        Input: {
            baseStyle: {
                borderWidth: "2px",
                borderColor: "blue.300"
            },
        },
        Textarea: {
            baseStyle: {
                ...focusStyles,
            },
        },
        Select: {
            baseStyle: {
                field: {
                    ...focusStyles,
                },
            },
        },
        NumberInput: {
            baseStyle: {
                field: {
                    ...focusStyles,
                },
            },
        },
        PinInput: {
            baseStyle: {
                ...focusStyles,
            },
        },
        Checkbox: {
            baseStyle: {
                control: {
                    _checked: {
                        bg: 'blue.300',
                        borderColor: 'blue.300',
                        color: 'white',

                        _hover: {
                            bg: 'blue.400',
                            borderColor: 'blue.400',
                        },
                    },
                },
            },
        },
        Radio: {
            baseStyle: {
                control: {
                    ...focusStyles,
                },
            },
        },
        Switch: {
            baseStyle: {
                track: {
                    _focus: {
                        boxShadow: 'none',
                    },
                    _checked: {
                        bg: "blue.300",
                    },
                },
            },
        },
        SearchIcon:{
            color:'gray.300',
        }
    },
    styles: {
        global: {
            '.table-container': {

            },
            '.loader-container': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '80vh',
                width: '100%',
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 9999,
            },
        },
    },
});

export default customTheme;