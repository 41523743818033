import React, { useEffect, useState, useCallback } from 'react';
import {Box} from "@chakra-ui/react";
import {fetchAllVideos} from "../VideoGalleryAPI";
import {useError} from "../../../../Context/ErrorContext/ErrorContext";
import VideoGalleryTable from "./VideoGalleryTable";
import VideoGalleryTableTopBar from "./VideoGalleryTableTopBar";

const VideosContainer = () => {
    const [videos, setVideos] = useState([]);
    const [filterOptions, setFilterOptions] = useState({});
    const [error, setError] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [fetchTrigger, setFetchTrigger] = useState(false);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const { handleError } = useError();

    const refetchVideoData = useCallback(async (options = {}) => {
        setDataLoaded(false);
        try {
            const data = await fetchAllVideos(pageNumber, 10, filterOptions, handleError);
            setVideos(data.videos);
            setTotalPages(data.totalPages);
            setError('');
            setDataLoaded(true);
        } catch (error) {
            setError(error.message);
            setVideos([]);
            setTotalPages(0);
        }
    }, [pageNumber, filterOptions, handleError]);

    useEffect(() => {
        refetchVideoData();
    }, [fetchTrigger, refetchVideoData, refreshFlag, totalPages, fetchTrigger, filterOptions]);

    if (!videos) {
        return (
            <Box textAlign="center" my="4" p="4" color="red.600" bg="blue.50">
                <p>Loading Videos...</p>
            </Box>
        );
    }

    return (
        <>
            <VideoGalleryTableTopBar
                refetchVideoData={refetchVideoData}
                setPageNumber={setPageNumber}
                setFilterOptions={setFilterOptions}
                setFetchTrigger={setFetchTrigger}
            />
            <VideoGalleryTable
                videos={videos}
                setVideos={setVideos}
                error={error}
                pageNumber={pageNumber}
                setRefreshFlag={setRefreshFlag}
                setPageNumber={setPageNumber}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                filterOptions={filterOptions}
                dataLoaded={dataLoaded}
                refetchVideoData={refetchVideoData}
            />
        </>
    );
};

export default VideosContainer;
