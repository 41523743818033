import React, {useCallback, useEffect, useState} from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box,
} from '@chakra-ui/react';
import PublicationCategoryEditForm from './PublicationCategoryEditForm';
import { useDrawer } from "../../../../../Context/DrawerContext/DrawerContext";
import { DeleteButton, EditButton } from "../../../../../components/Styled/ActionButtons";
import { StyledTableContainer } from "../../../../../components/Styled/StyledComponents";
import PaginationControls from "../../../../../Context/PaginationControls/PaginationControls";
import {fetchAllPublicationCategories, deletePublicationCategory} from "../PublicationCategoryAPI";
import ConfirmDialog from "../../../../../Context/ConfirmDialog/ConfirmDialog";
import useSingleToast from "../../../../../hooks/UseSingleToast/UseSingleToast";
import {useError} from "../../../../../Context/ErrorContext/ErrorContext";
import utils from "../../../../../utils/commonFunctions";

const PublicationCategoryTable = ({ publicationCategories, setPublicationCategories, error, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag, filterOptions }) => {
    const { handleError } = useError();
    const { openDrawer } = useDrawer();
    const showToast = useSingleToast();
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });


    const handleEdit = (publicationCategoryId) => {
        openDrawer({
            title: 'Edit Publication Category',
            component: PublicationCategoryEditForm,
            props: {
                publicationCategoryId: publicationCategoryId,
                refetchPublicationCategoryData: refetchPublicationCategoryData,
                setRefreshFlag: setRefreshFlag,
            },
            key: `edit-${publicationCategoryId}`,
        }, 'md');
    };





    const refetchPublicationCategoryData = useCallback(async (options = {}) => {
        try {
            const data = await fetchAllPublicationCategories(pageNumber, 10, filterOptions, handleError);
            setPublicationCategories(data.publicationCategories);
            setTotalPages(Math.ceil(data.totalDocs / 10));
        } catch (error) {
            handleError(error.message || "An error occurred.");
        }
    }, [filterOptions, handleError, pageNumber, setPublicationCategories, setTotalPages]);



    useEffect(() => {
        refetchPublicationCategoryData(filterOptions);
    }, [pageNumber, refetchPublicationCategoryData, filterOptions, setRefreshFlag]);


    /*if (error) {
        return <Box textAlign="center" my="4">Failed to fetch publicationCategory data: {error}</Box>;
    }*/

    if (!publicationCategories) {
        return (
            <Box textAlign="center" my="4" p="4" color="red.600" bg="blue.50">
                <p>Loading publication categories...</p>
            </Box>
        );
    }


    if (error) {
        return (
            <Box textAlign="center" my="4" p="4" color="red.600" bg="yellow.100">
                <p>Error fetching publication category data:</p>
                <code>{error.toString()}</code>
            </Box>
        );
    }



    if (dataLoaded && Array.isArray(publicationCategories) && publicationCategories.length === 0) {
        return <Box textAlign="center" my="4">No records found.</Box>;
    }

    const handleDelete = (publicationCategoryId) => {
        setConfirmDialog({
            isOpen: true,
            title: "Confirm Delete",
            message: "Are you sure you want to delete this publication category? This action cannot be undone.",
            onConfirm: () => confirmDelete(publicationCategoryId),
        });
    };

    const confirmDelete = async (publicationCategoryId) => {
        try {
            await deletePublicationCategory(publicationCategoryId);
            showToast({
                title: "Publication Category Deleted",
                description: "Successfully deleted the publication category.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ isOpen: false });
            setRefreshFlag(prev => !prev);
        } catch (error) {
            showToast({
                title: "Deletion Failed",
                description: error.message || "Failed to delete the publication category.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ isOpen: false });
        }
    };



    return (
        <>
            <StyledTableContainer>
                <PaginationControls
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    totalPages={totalPages}
                />
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th>#</Th>
                            <Th>Publication Category Name</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {publicationCategories.map((publicationCategory, index) => (
                            <Tr key={index}>
                                <Td>PC0{index + 1}</Td>
                                <Td>{publicationCategory.name}</Td>
                                <Td>
                                    <EditButton onClick={() => handleEdit(publicationCategory._id)} />
                                    <DeleteButton onClick={() => handleDelete(publicationCategory._id)} />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </StyledTableContainer>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                title={confirmDialog.title}
                message={confirmDialog.message}
                onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
                onConfirm={confirmDialog.onConfirm}
            />
        </>
    );
};

export default PublicationCategoryTable;
