import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  Badge,
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext"; // Ensure this import matches your project structure
import {StyledTableContainer, TableContainer} from "../../../../components/Styled/StyledComponents";
import PaginationControls from "../../../../Context/PaginationControls/PaginationControls";
import {ViewButton, EditButton, DeleteButton, PublishButton} from "../../../../components/Styled/ActionButtons";
import VideoEditForm from './VideoEditForm';
import VideoViewForm from './VideoViewForm';
import videoService from '../../../../services/videoService';
import VideoPlayer from "./VideoPlayer";
import {deleteVideo, fetchAllCities} from "../../../LocationNetwork/Cities/CitiesAPI";
import {fetchAllManagementCenters} from "../../../LocationNetwork/managementCenters/ManagementCenterAPI";
import ConfirmDialog from "../../../../Context/ConfirmDialog/ConfirmDialog";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";

const VideoTable = ({ videos, setVideos, dataLoaded, setRefreshFlag, filterOptions }) => {
  const { openDrawer } = useDrawer();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [video, setVideo] = useState(null);
  const showToast = useSingleToast();
  const showToastRef = useRef(showToast);
  const updateVideos = useCallback((newVideos) => {
    setVideos(newVideos);
  }, [setVideos]);
  const refetchVideoData = useCallback(async (options = {}) => {
    try {
      const videosResponse =  await videoService.getAllVideos(filterOptions);
      console.log(videosResponse,filterOptions,"Table")
      if (videosResponse) {
        updateVideos(videosResponse);
      } else {
        setVideos([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [filterOptions,  updateVideos]);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    message: '',
    onConfirm: () => {},
  });
  useEffect(() => {
    refetchVideoData();
  }, [refetchVideoData,filterOptions]);

  const confirmDelete = async (id) => {
    try {
      await videoService.deleteVideo(id);
      showToastRef.current({
        title: "Video Deleted",
        description: "Successfully deleted the video.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setConfirmDialog({ ...confirmDialog, isOpen: false });
      await refetchVideoData(filterOptions); // Refresh the list
    } catch (error) {
      showToastRef.current({
        title: "Deletion Failed",
        description: "Could not delete the video.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setConfirmDialog({ ...confirmDialog, isOpen: false });
    }
  };

  const handleWatch = async (video) => {
    try {
      setVideo(video);
      onOpen();
    } catch (error) {
      showToastRef.current({
        title: 'Failed to View Video',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
  const handlePublish= async (videoId) => {
    try {
      await videoService.updateVideo(videoId, {published:true});
      showToastRef.current({
        title: 'Video Published ',
        description: "The video has been published successfully.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      await refetchVideoData(filterOptions);
    } catch (error) {
      showToastRef.current({
        title: 'Failed to Published Video',
        description: error.message || "An error occurred while publishing the video.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

  }


  return (
      <>
        <StyledTableContainer>
              <Table variant="striped">
                <Thead>
                  <Tr>
                    <Th>Title</Th>
                    <Th>Status</Th>
                    <Th isNumeric>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {videos?.length > 0 ? (
                      videos.map((video) => (
                          <Tr key={video._id}>
                            <Td>
                              <Text fontWeight="bold">{video.title}</Text>
                            </Td>
                            <Td>
                              {video?.published?
                                  <Badge colorScheme={'green'}>Published</Badge>: <Badge colorScheme={'red'}>Unpublished</Badge>
                              }

                            </Td>
                            <Td isNumeric>
                              <ViewButton  onClick={() => handleWatch(video)}/>
                              {!video?.published&&
                                <PublishButton  onClick={() => handlePublish(video._id)}/>
                              }
                              <EditButton onClick={() => openDrawer({
                                title: "Edit Video",
                                component: VideoEditForm,
                                props: {
                                  videoId: video._id,
                                  refetchVideoData: refetchVideoData,
                                  setRefreshFlag: setRefreshFlag,
                                }
                              })} />
                              <DeleteButton onClick={() => confirmDelete(video._id)} />
                            </Td>
                          </Tr>
                      ))
                  ) : (
                      <Box p={5}>No videos found. Please add some videos.</Box>
                  )}
                </Tbody>
              </Table>
        </StyledTableContainer>
        <VideoPlayer video={video} isOpen={isOpen} onClose={onClose}/>
      </>
  );
};

export default VideoTable;
