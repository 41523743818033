import React, { useEffect, useState, useCallback } from 'react';
import ShortageReportTable from './ShortageReportTable';
import ShortageReportTableTopBar from './ShortageReportTableTopBar';
import {fetchAllShortageReports} from '../ShortagesAPI';


const ShortageReportContainer = () => {
    const [ShortageReports, setShortageReports] = useState([]);
    const [error, setError] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [filterOptions, setFilterOptions] = useState({});
    const [fetchTrigger, setFetchTrigger] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const refetchShortageReportData = useCallback(async (options = {}) => {
        setDataLoaded(false);
        try {
            const data = await fetchAllShortageReports(pageNumber, 10, filterOptions);
            setShortageReports(data.shortageReports);
            setTotalPages(data.totalPages);
            setError('');
            setDataLoaded(true);
        } catch (error) {
            console.error('Error fetching all Shortage Reports:', error.message);
            setError('Failed to fetch Shortage Reports data.');
            setShortageReports([]);
            setTotalPages(0);
        }
    }, [filterOptions, pageNumber]);


    useEffect(() => {

        refetchShortageReportData();
    }, [pageNumber, refreshFlag, refetchShortageReportData, totalPages, fetchTrigger, filterOptions ]);

    return (
        <>
            <ShortageReportTableTopBar
                refetchShortageReportData={refetchShortageReportData}
                setPageNumber={setPageNumber}
                setFilterOptions={setFilterOptions}
                setFetchTrigger={setFetchTrigger}
            />
            <ShortageReportTable
                shortageReports={ShortageReports}
                setShortageReports={setShortageReports}
                setRefreshFlag={setRefreshFlag}
                refetchShortageReportData={refetchShortageReportData}
                error={error}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                filterOptions={filterOptions}
                dataLoaded={dataLoaded}
            />
        </>
    );
};

export default ShortageReportContainer;
