import React, {useEffect, useRef, useState} from 'react';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    Box,
    Text,
    SimpleGrid, Spinner, Select
} from '@chakra-ui/react';
import {ThemedStyledInput} from "../../../../components/Styled";
import FormActionLabel from "../../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../../components/Styled/StyledButtons";
import { useDrawer } from '../../../../Context/DrawerContext/DrawerContext';
import { fetchDisconnectionById, updateDisconnection } from "../DisconnectionAPI";
import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';
import ConfirmDialog from "../../../../Context/ConfirmDialog/ConfirmDialog";
import SectionHeading from "../../../../components/SectionHeading/SectionHeading";
import {fetchAllAreas, fetchAllCities} from "../../../LocationNetwork/Areas/AreasAPI";
import {validTransitions} from "../../../../components/MeterMgt/StatusTransitions";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";
import {validateContractNumber, validateCustomerNumber} from "../../../../utils/validateContract";
import FieldErrorMessage from "../../../../components/FieldErrorMessage/FieldErrorMessage";
import utils from "../../../../utils/commonFunctions";

const DisconnectionEditForm = ({ disconnectionId, refetchDisconnectionData, setRefreshFlag }) => {
    const { closeDrawer } = useDrawer();
    const [validStatus,setValidStatus] = useState([]);
    const showToast = useSingleToast();
    const showToastRef = useRef(showToast);
    const [cities, setCities] = useState([]);
    const [wards, setWards] = useState([]);
    const [customerNumber, setCustomerNumber] = useState('');
    const hasFetched = useRef(false);
    const [initialValues, setInitialValues] = useState({
        userId: '',
        profileId: '',
        customerNumber: '',
        contractNumber: '',
        personalDetails: {
            firstName: '',
            middleName: '',
            lastName: '',
        },
        address: {
            lotNo: '',
            street: '',
            ward: '',
            city: '',
            district: '',
        },
        landLordDetails: {
            name: '',
            identityNo: '',
            identityType: '',
            cell: '',
            email: '',
        },
        bankingDetails: {
            name: '',
            branch: '',
            branchCode: '',
            accountNumber: '',
        },
        status: '',
        consent: true,
        dateOfConsent: new Date(),
    });
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    useEffect(() => {
        fetchAllAreas().then(data => {
            setWards(data.areas);
        }).catch(error => {
            showToastRef.current({
                title: "Error fetching areas",
                description: "Failed to fetch areas.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        });
        fetchAllCities().then(data => {
            setCities(data.cities);
        }).catch(error => {
            showToastRef.current({
                title: "Error fetching cities",
                description: "Failed to fetch cities.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        });
    }, []);

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const disconnectionData = await fetchDisconnectionById(disconnectionId);
                if (disconnectionData) {
                    setInitialValues(disconnectionData);
                    setValidStatus(validTransitions[disconnectionData.status]);
                    setCustomerNumber(disconnectionData.customerNumber);
                    hasFetched.current = true;
                }
            } catch (error) {
                console.error('Error fetching initial data:', error);
                showToast({
                    title: 'Error fetching disconnection data',
                    description: error.message || 'An unexpected error occurred.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };

        if (!hasFetched.current) {
            fetchInitialData();
        }
    }, [disconnectionId, setInitialValues, showToast]);


    const disconnectionSchema = Yup.object().shape({
        customerNumber: Yup.string().required('Customer number is required.'),
        contractNumber: Yup.string().required('Contract number is required.'),
        personalDetails: Yup.object({
            firstName: Yup.string().required('First name is required.'),
            middleName: Yup.string().nullable(),
            lastName: Yup.string().required('Last name is required.'),
        }),
        address: Yup.object({
            lotNo: Yup.string().nullable(),
            street: Yup.string().required('Street is required.'),
            ward: Yup.string().required('Area/ward is required.'),
            city: Yup.string().required('City is required.'),
            district: Yup.string().required('District is required.'),
        }),
        landLordDetails: Yup.object({
            name: Yup.string().required('Landlord name is required.'),
            identityNo: Yup.string().required('Identity is required.'),
            identityType: Yup.string().required('Identity type is required.'),
            cell: Yup.string().required('Cell phone number is required.'),
            email: Yup.string().email('Invalid email address'),
        }),
        bankingDetails: Yup.object({
            name: Yup.string().required('Bank name is required.'),
            branch: Yup.string().required('Branch name is required.'),
            branchCode: Yup.string().required('Branch code is required.'),
            accountNumber: Yup.string().required('Account number is required.'),
        }),
        status: Yup.string().required('Status is required.'),
        consent: Yup.boolean(),
        dateOfConsent: Yup.date(),
    });
    const handleSubmit = async (values, actions) => {
        try {
            const disconnectionData = {...values};
            const updateResponse = await updateDisconnection(disconnectionId, disconnectionData);
            if (updateResponse.status === 'Success') {
            showToast({
                title: 'Disconnection request updated successfully',
                description: updateResponse.message,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchDisconnectionData();
            setRefreshFlag(prevFlag => !prevFlag);
            closeDrawer();
            } else {
                throw new Error(updateResponse.message || 'Error updating disconnection request');
            }
        } catch (error) {
            console.error('Update failed:', error);
            showToast({
                title: 'Error updating disconnection request',
                description: error.message || 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            actions.setSubmitting(false);
        }
    };

    const requestConfirm = (options) => {
        setConfirmDialog({
            isOpen: true,
            title: options.title,
            message: options.message,
            onConfirm: options.onConfirm,
        });
    };
    const handleCancel = () => {
        requestConfirm({
            title: "Cancel Confirmation",
            message: "Are you sure you want to cancel? Any unsaved changes will be lost.",
            onConfirm: closeDrawer
        });
    };
    const handleConfirm = () => {
        confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleCustomerNumberBlur = async (event, setFieldError) => {
        const validationResult = await validateCustomerNumber(event.target.value);
        setFieldError('customerNumber', validationResult);
    };

    const handleContractNumberBlur = async (event, setFieldError) => {
        const validationResult = await validateContractNumber(customerNumber, event.target.value);
        setFieldError('contractNumber', validationResult);
    };


    // FieldControl component
    const FieldControl = ({ formikProps, name, label, placeholder, onBlur }) => (
        <GridItem colSpan={[3, 1]}>
            <FormControl isInvalid={formikProps.errors[name] && formikProps.touched[name]}>
                <FormLabel htmlFor={name}>{label}</FormLabel>
                <ThemedStyledInput
                    {...formikProps.getFieldProps(name)}
                    id={name}
                    placeholder={placeholder}
                    onBlur={onBlur}
                />
                {formikProps.errors[name] && formikProps.touched[name] && (
                    <Text color="red.500" fontSize="sm">{formikProps.errors[name]}</Text>
                )}
            </FormControl>
        </GridItem>
    );


    return (
        <>

            <Formik
                initialValues={initialValues}
                validationSchema={disconnectionSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {(formikProps) => {
                    return (
                        <Form>
                            <VStack spacing={4} align="stretch" marginY={2} marginX={{base: 0, md: 8}}>
                                <FormActionLabel formAction="add" formName="Disconnection"/>

                                <SectionHeading icon="mdi:account-tag"
                                                text="Customer Number and Utility Contract Number"/>
                                <SimpleGrid columns={2} gap={4} px={2} py={4}>
                                    <FieldControl
                                        formikProps={formikProps}
                                        name="customerNumber"
                                        label="Customer Number"
                                        placeholder="Enter Customer Number"
                                        onBlur={(event) => handleCustomerNumberBlur(event, formikProps.setFieldError)}
                                    />

                                    <FieldControl
                                        formikProps={formikProps}
                                        name="contractNumber"
                                        label="Contract Number"
                                        placeholder="Enter Contract Number"
                                        onBlur={(event) => handleContractNumberBlur(event, formikProps.setFieldError)}
                                    />




                                </SimpleGrid>

                                <SectionHeading icon="bxs:user-detail" text="Personal Details"/>
                                <SimpleGrid columns={2} gap={4} px={2} py={4}>
                                    <FieldControl formikProps={formikProps} name="personalDetails.firstName"
                                                  label="First Name" placeholder="Enter First Name"/>
                                    <FieldControl formikProps={formikProps} name="personalDetails.middleName"
                                                  label="Middle Name" placeholder="Enter Middle Name"/>
                                    <FieldControl formikProps={formikProps} name="personalDetails.lastName"
                                                  label="Last Name" placeholder="Enter Last Name"/>
                                </SimpleGrid>

                                <SectionHeading icon="material-symbols:location-away" text="Address Details"/>
                                <SimpleGrid columns={2} gap={4} px={2} py={4}>
                                    <FieldControl formikProps={formikProps} name="address.lotNo" label="Plot No"
                                                  placeholder="Enter Plot No."/>
                                    <FieldControl formikProps={formikProps} name="address.street" label="Street"
                                                  placeholder="Enter Street Name"/>

                                    <GridItem>
                                        <FormControl isInvalid={formikProps.errors.address?.city && formikProps.touched.address?.city}>
                                            <FormLabel htmlFor="address.city">City</FormLabel>
                                            <Field as={Select} id="city" name="address.city" placeholder="Select City">
                                                {cities.map(city => (
                                                    <option key={city._id} value={city.name}>{city.name}</option>
                                                ))}
                                            </Field>
                                            {formikProps.errors.address?.city && formikProps.touched.address?.city && (
                                                <Text color="red.500" fontSize="sm">{formikProps.errors.address?.city}</Text>
                                            )}
                                        </FormControl>
                                    </GridItem>
                                    <GridItem>
                                        <FormControl isInvalid={formikProps.errors.address?.ward && formikProps.touched.address?.ward}>
                                        <FormLabel htmlFor="address.ward">Area/Ward</FormLabel>
                                            <Field as={Select} id="ward" name="address.ward" placeholder="Select Ward">
                                                {wards.map(ward => (
                                                    <option key={ward._id} value={ward.name}>{ward.name}</option>
                                                ))}
                                            </Field>
                                            {formikProps.errors.address?.ward && formikProps.touched.address?.ward && (
                                                <Text color="red.500" fontSize="sm">{formikProps.errors.address?.ward}</Text>
                                            )}
                                        </FormControl>
                                    </GridItem>
                                </SimpleGrid>

                                <SectionHeading icon="material-symbols-light:add-home" text="Landlord Details"/>
                                <SimpleGrid columns={2} gap={4} px={2} py={4}>
                                    <FieldControl formikProps={formikProps} name="landLordDetails.name"
                                                  label="Landlord Name" placeholder="Enter Landlord's Name"/>
                                    <FieldControl formikProps={formikProps} name="landLordDetails.identityNo"
                                                  label="Identity No" placeholder="Enter Identity Number"/>
                                    <FieldControl formikProps={formikProps} name="landLordDetails.identityType"
                                                  label="Identity Type" placeholder="Enter Identity Type"/>
                                    <FieldControl formikProps={formikProps} name="landLordDetails.cell"
                                                  label="Cell Phone" placeholder="Enter Cell Phone Number"/>
                                    <FieldControl formikProps={formikProps} name="landLordDetails.email" label="Email"
                                                  placeholder="Enter Email"/>
                                </SimpleGrid>

                                <SectionHeading icon="fluent:building-bank-link-48-filled" text="Banking Details"/>
                                <SimpleGrid columns={2} gap={4} px={2} py={4}>
                                    <FieldControl formikProps={formikProps} name="bankingDetails.name" label="Bank Name"
                                                  placeholder="Enter Bank Name"/>
                                    <FieldControl formikProps={formikProps} name="bankingDetails.branch" label="Branch"
                                                  placeholder="Enter Branch Name"/>
                                    <FieldControl formikProps={formikProps} name="bankingDetails.branchCode"
                                                  label="Branch Code" placeholder="Enter Branch Code"/>
                                    <FieldControl formikProps={formikProps} name="bankingDetails.accountNumber"
                                                  label="Account Number" placeholder="Enter Account Number"/>
                                </SimpleGrid>

                                <SectionHeading icon="material-symbols-light:order-approve-rounded" text="Status of the Application"/>
                                <SimpleGrid columns={2} gap={4} px={2} py={4}>
                                    <FormControl
                                        isInvalid={formikProps.errors["status"] && formikProps.touched["status"]}>
                                        <FormLabel htmlFor="status">Application Status</FormLabel>
                                        <Field as={Select} name="status" >
                                            {validStatus.map(status => (
                                                <option key={status} value={status}>{status}</option>
                                            ))}
                                        </Field>

                                        {formikProps.errors["status"] && formikProps.touched["status"] && (
                                            <Text color="red.500" fontSize="sm">{formikProps.errors["status"]}</Text>
                                        )}
                                    </FormControl>
                                </SimpleGrid>

                                <Box flexDirection="column" alignItems="left" style={{alignItems: 'flex-start'}}>
                                    <SimpleGrid columns={2} gap={4} px={4}>
                                        <>
                                        <GridItem colSpan={1}>
                                            <CustomAlert status="info" message={`Date of application: ${utils.formatDate(formikProps.values.dateOfApplication)}`} />
                                            <CustomAlert status="info" message={`Customer Number & Contract Number: ${formikProps.values.customerNumber} , ${formikProps.values.contractNumber}`} />
                                            <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
                                        </GridItem>
                                        <GridItem colSpan={1}>
                                            <Text fontSize="lg" fontWeight="bold" mb={2}>Form Errors:</Text>
                                            <FieldErrorMessage name="customerNumber" />
                                            <FieldErrorMessage name="contractNumber" />
                                            <FieldErrorMessage name="personalDetails.firstName" />
                                            <FieldErrorMessage name="personalDetails.middleName" />
                                            <FieldErrorMessage name="personalDetails.lastName" />
                                            <FieldErrorMessage name="address.houseNo" />
                                            <FieldErrorMessage name="address.lotNo" />
                                            <FieldErrorMessage name="address.street" />
                                            <FieldErrorMessage name="address.ward" />
                                            <FieldErrorMessage name="address.city" />
                                            <FieldErrorMessage name="address.district" />
                                            <FieldErrorMessage name="landLordDetails.name" />
                                            <FieldErrorMessage name="landLordDetails.identityNo" />
                                            <FieldErrorMessage name="landLordDetails.identityType" />
                                            <FieldErrorMessage name="landLordDetails.cell" />
                                            <FieldErrorMessage name="landLordDetails.tel" />
                                            <FieldErrorMessage name="landLordDetails.email" />
                                            <FieldErrorMessage name="landLordDetails.postal" />
                                            <FieldErrorMessage name="bankingDetails.name" />
                                            <FieldErrorMessage name="bankingDetails.branch" />
                                            <FieldErrorMessage name="bankingDetails.branchCode" />
                                            <FieldErrorMessage name="bankingDetails.accountNumber" />
                                            <FieldErrorMessage name="status" />
                                            <FieldErrorMessage name="comments" />
                                            <FieldErrorMessage name="consent" />
                                            <FieldErrorMessage name="dateOfConsent" />
                                        </GridItem>
                                    <ButtonStack direction="row" style={{justifyContent: 'flex-start'}}>
                                        <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                                            Cancel
                                        </CustomButton>
                                        <CustomButton
                                            type="submit"
                                            disabled={formikProps.isSubmitting}
                                            style={{
                                                opacity: formikProps.isSubmitting ? 0.7 : 1,
                                                pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                                            }}
                                        >
                                            {formikProps.isSubmitting ? (
                                                <>
                                                    <Spinner size="xs" mr={2}/>
                                                    Updating...
                                                </>
                                            ) : 'Update'}
                                        </CustomButton>
                                    </ButtonStack>
                                    </>
                                    </SimpleGrid>
                                </Box>
                            </VStack>
                        </Form>
                    );
                }}
            </Formik>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};



export default DisconnectionEditForm;

