import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import {FormControl, Stack, Select, useToast, Grid, GridItem } from '@chakra-ui/react';
import * as Yup from 'yup';
import { CustomButton } from "../../../../components/Styled/StyledButtons";
import { Icon } from "@iconify/react";
import FieldErrorMessage from "../../../../components/FieldErrorMessage/FieldErrorMessage";
import { ThemedStyledInput } from "../../../../components/Styled";
import { fetchAllAreas } from "../../../LocationNetwork/Consultants/ConsultantsAPI";
import { fetchAllCities } from "../../../LocationNetwork/Areas/AreasAPI";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";

const applicantTypes = ["Tenant", "Owner"];
const connectionTypes = ["Portable", "Wastewater"];
const statuses = ["Submitted", "Review", "Approved", "Declined", "Complete"];

const NewConnectionSearchFilter = ({ onFilterChange }) => {
    const showToast = useSingleToast();
    const showToastRef = useRef(showToast);
    const [areas, setAreas] = useState([]);
    const [cities, setCities] = useState([]);

    useEffect(() => {
        fetchAllAreas().then(data => {
            setAreas(data.areas);
        }).catch(error => {
            showToastRef.current({
                title: "Error fetching areas",
                description: "Failed to fetch areas.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        });
        fetchAllCities().then(data => {
            setCities(data.cities);
        }).catch(error => {
            showToastRef.current({
                title: "Error fetching cities",
                description: "Failed to fetch cities.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        });
    }, []);

    return (
        <Formik
            initialValues={{
                customerNumber: '',
                customerName: '',
                email: '',
                identityNo: '',
                contractNumber: '',
                city: '',
                ward: '',
                applicantType: '',
                connectionType: '',
                isExisting: '',
                status: ''
            }}
            validationSchema={Yup.object({
                email: Yup.string()
                    .email('Invalid email address')
                    .max(50, 'Email must be 50 characters or less')
                    .trim()
                    .strict(),
                customerName: Yup.string()
                    .max(50, 'Customer Name must be 50 characters or less')
                    .trim('Customer Name should not have spaces')
                    .strict(),
                identityNo: Yup.string()
                    .max(20, 'Identity number must be 20 characters or less'),
                customerNumber: Yup.string()
                    .trim('Customer Number should not have spaces')
                    .strict(),
                contractNumber: Yup.string()
                    .trim('Contract Number should not have spaces')
                    .strict(),
                city: Yup.string(),
                ward: Yup.string(),
                applicantType: Yup.string(),
                connectionType: Yup.string(),
                isExisting: Yup.boolean(),
                status: Yup.string()
            })}
            onSubmit={(values, { setSubmitting }) => {
                const filteredValues = Object.fromEntries(Object.entries(values).filter(([_, v]) => v !== ''));

                if (Object.keys(filteredValues).length === 0) {
                    showToast({
                        title: "No Parameters Supplied",
                        description: "Please enter search criteria.",
                        status: "warning",
                        duration: 5000,
                        isClosable: true,
                    });
                } else {
                    onFilterChange(filteredValues);
                }
                setSubmitting(false);
            }}
        >
            {({ handleSubmit, resetForm }) => (
                <Form>
                    <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                        <GridItem colSpan={[12, 3]}>
                            <Field as={ThemedStyledInput} id="customerName" name="customerName" type="customerName" placeholder="Customer Name" />
                            <FieldErrorMessage name="customerName" />
                        </GridItem>
                        <GridItem colSpan={[12, 3]}>
                            <Field as={ThemedStyledInput} id="identityNo" name="identityNo" type="text" placeholder="Identity No" />
                            <FieldErrorMessage name="identityNo" />
                        </GridItem>
                        <GridItem colSpan={[12, 3]}>
                            <Field as={ThemedStyledInput} id="email" name="email" type="email" placeholder="Email" />
                            <FieldErrorMessage name="email" />
                        </GridItem>
                        <GridItem colSpan={[12, 3]}>
                            <Field as={ThemedStyledInput} id="customerNumber" name="customerNumber" type="text" placeholder="Customer Number" />
                            <FieldErrorMessage name="customerNumber" />
                        </GridItem>
                        <GridItem colSpan={[12, 3]}>
                            <Field as={ThemedStyledInput} id="contractNumber" name="contractNumber" type="text" placeholder="Contract Number" />
                            <FieldErrorMessage name="contractNumber" />
                        </GridItem>
                        <GridItem colSpan={[12, 3]}>
                            <Field as={Select} id="city" name="city" placeholder="Select City">
                                {cities.map(city => (
                                    <option key={city._id} value={city.name}>{city.name}</option>
                                ))}
                            </Field>
                            <FieldErrorMessage name="city" />
                        </GridItem>
                        <GridItem colSpan={[12, 3]}>
                            <Field as={Select} id="ward" name="ward" placeholder="Select Ward">
                                {areas.map(area => (
                                    <option key={area._id} value={area.name}>{area.name}</option>
                                ))}
                            </Field>
                            <FieldErrorMessage name="ward" />
                        </GridItem>
                        <GridItem colSpan={[12, 3]}>
                            <Field as={Select} id="applicantType" name="applicantType" placeholder="Select Applicant Type">
                                {applicantTypes.map(type => (
                                    <option key={type} value={type}>{type}</option>
                                    ))}
                                    </Field>
                                    <FieldErrorMessage name="applicantType" />
                                    </GridItem>
                                    <GridItem colSpan={[12, 3]}>
                                <Field as={Select} id="connectionType" name="connectionType" placeholder="Select Service Type">
                                    {connectionTypes.map(type => (
                                        <option key={type} value={type}>{type}</option>
                                    ))}
                                </Field>
                                <FieldErrorMessage name="connectionType" />
                        </GridItem>
                        <GridItem colSpan={[12, 3]}>
                            <FormControl>
                                <Field as={Select} id="isExisting" name="isExisting" placeholder="Select Connection Type">
                                    <option value="true">Existing Connection</option>
                                    <option value="false">New Connection</option>
                                </Field>
                                <FieldErrorMessage name="isExisting" />
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={[12, 3]}>
                            <Field as={Select} id="status" name="status" placeholder="Select Status">
                                {statuses.map(status => (
                                    <option key={status} value={status}>{status}</option>
                                ))}
                            </Field>
                            <FieldErrorMessage name="status" />
                        </GridItem>
                        <GridItem colSpan={[12, 3]}>
                            <Stack spacing={2} direction="row" align="center" >
                                <CustomButton onClick={handleSubmit} type="search" tooltipLabel="Search" icon={<Icon icon="bi:search" />} showLabel={false}>
                                    Search
                                </CustomButton>
                                <CustomButton
                                    type="reset"
                                    onClick={() => {
                                        resetForm();
                                        onFilterChange({
                                            customerNumber: '', customerName: '', email: '', identityNo: '', contractNumber: '',
                                            city: '', ward: '', applicantType: '', connectionType: '', isExisting: '', status: ''
                                        });
                                    }}
                                    tooltipLabel="Clear Filters"
                                    icon={<Icon icon="radix-icons:reset" />}
                                    showLabel={false}
                                >
                                    Clear
                                </CustomButton>
                            </Stack>
                        </GridItem>
                    </Grid>
                </Form>
                )}
        </Formik>
    );
};

export default NewConnectionSearchFilter;


