import React from 'react'
import DefaultTemplate from '../../../components/DefaultTemplate/DefaultTemplate'
import DisconnectionContainer from './Components/DisconnectionContainer'
import {Icon} from "@iconify/react";

const DisconnectionRequest = () => {
    return (
        <>
            <DefaultTemplate Content={ <DisconnectionContainer/> } Title={"Disconnection Requests" } icon={<Icon icon="mdi:water-off" />}/>
        </>
    )
}

export default DisconnectionRequest
