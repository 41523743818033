import React, { useEffect, useState, useCallback } from 'react';
import FAQTable from './FAQTable';  
import FAQTableTopBar from './FAQTableTopBar';  
import faqService from '../../../services/faqService'; 
const categories = {
    BILLING_PAYMENT: 'Billing and Payment',
    WATER_QUALITY: 'Water Quality',
    SERVICE_ISSUES: 'Service Issues',
    CONSERVATION_TIPS: 'Conservation Tips',
    ACCOUNT_MANAGEMENT: 'Account Management',
    REGULATIONS_COMPLIANCE: 'Regulations and Compliance',
    INSTALLATION_SERVICES: 'Installation Services',
    EMERGENCY_PROCEDURES: 'Emergency Procedures',
    SUSTAINABILITY_INITIATIVES: 'Sustainability Initiatives',
    TECHNICAL_SUPPORT: 'Technical Support'
};
const FAQContainer = () => {
    const [faqs, setFAQs] = useState([]);
    const [filteredFAQs, setFilteredFAQs] = useState([]); 
    const [error, setError] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [searchQuery, setSearchQuery] = useState({ text: '', category: '' });
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [refreshFlag, setRefreshFlag] = useState(false);
    // const applyCategoryFilter = (faqs, category) => {
    //     if (category === 'All') {
    //         return faqs;
    //     } else {
    //         return faqs.filter(faq => faq.category === category);
    //     }
    // };
    const applyFilters = (faqs, query) => {
        return faqs.filter(faq => 
            (query.category === '' || faq.category === query.category) &&
            (faq.question.toLowerCase().includes(query.text.toLowerCase()))
        );
    };

    const refetchFAQData = useCallback(async (published) => {
        try {
            const response = await faqService.getAllFAQs(published ?? true);  
            setFAQs(response);
            setFilteredFAQs(applyFilters(response, searchQuery));
            setTotalPages(Math.ceil(response.total / 10));  
            setError('');
        } catch (error) {
            console.error('Error fetching FAQs:', error.message);
            setError('Failed to fetch FAQ data.');
            setFAQs([]);
            setFilteredFAQs([]);
            setTotalPages(0);
        }
    }, [pageNumber]);

    useEffect(() => {
        refetchFAQData();
        const applyFilters = () => {
            const filtered = faqs.filter(faq => 
                (searchQuery.category === '' || faq.category === searchQuery.category) &&
                faq.question.toLowerCase().includes(searchQuery.text.toLowerCase())
            );
            setFilteredFAQs(filtered);
        };
        applyFilters();
        // fetchCategories(); // Implement this if you need to fetch categories from an API
    }, [pageNumber, refreshFlag,searchQuery, faqs, refetchFAQData]);

    return (
        <>
            <FAQTableTopBar 
                refetchData={refetchFAQData}
                faqs={faqs} 
                setFilteredFAQs={setFilteredFAQs}
                setSearchQuery={setSearchQuery}
                searchQuery={searchQuery}
                setSelectedCategory={setSelectedCategory}
                categories={Object.values(categories)}
            />
            <FAQTable
                faqs={filteredFAQs}
                setFAQs={setFAQs}
                setRefreshFlag={setRefreshFlag}
                refetchFAQData={refetchFAQData}
                error={error}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
            />
        </>
    );
};

export default FAQContainer;
