import React, {useCallback, useEffect, useState} from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Badge,
} from '@chakra-ui/react';
import AdminEditForm from './AdminEditForm';
import AdminViewForm from './AdminViewForm';
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import {DeactivateAdminButton, EditButton, ViewButton} from "../../../../components/Styled/ActionButtons";
import { StyledTableContainer } from "../../../../components/Styled/StyledComponents";
import PaginationControls from "../../../../Context/PaginationControls/PaginationControls";
import {fetchAdminById, fetchAllAdmins} from "../AdminAPI";

const AdminTable = ({ admins, setAdmins, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag, filterOptions }) => {
  const { openDrawer } = useDrawer();
  const [error, setError] = useState('');

  const handleEdit = (adminId) => {
    openDrawer({
      title: 'Edit Admin',
      component: AdminEditForm,
      props: {
        adminId: adminId,
        refetchAdminData: refetchAdminData,
        setRefreshFlag: setRefreshFlag,
      },
      key: `edit-${adminId}`,
    }, 'xxl');
  };


  const handleView = async (admin) => {
    try {
      // Attempt to fetch the latest admin data
      const updatedAdminData = await fetchAdminById(admin._id);

      // On successful fetch, open the drawer with the latest data
      openDrawer({
        title: 'View Admin',
        component: AdminViewForm,
        props: { formData: updatedAdminData }, // Use the freshly fetched data
        key: `view-${admin._id}`, // Ensure uniqueness for re-render
      }, 'xl');
    } catch (error) {
      console.error("Failed to fetch latest admin data:", error);
    }
  };


  const refetchAdminData = useCallback(async (options={}) => {
    setError('');
    try {
      const data = await fetchAllAdmins(pageNumber, 10, filterOptions);
      setAdmins(data.admins);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error("Fetch admins error:", error.message);
    }
  }, [filterOptions, pageNumber, setAdmins, setTotalPages]);

  useEffect(() => {
    refetchAdminData();
  }, [refetchAdminData, pageNumber, filterOptions]);

  if (error) {
    return <Box textAlign="center" my="4">Failed to fetch admin data: {error}</Box>;
  }


  if (dataLoaded && Array.isArray(admins) && admins.length === 0) {
    return <Box textAlign="center" my="4">No records found.</Box>;
  }

  return (
      <>
        <StyledTableContainer>
          <PaginationControls
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              totalPages={totalPages}
          />
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th>First Name</Th>
                <Th>Last Name</Th>
                <Th>Email</Th>
                <Th>Phone</Th>
                <Th>Verification Status</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {admins.map((admin) => (
                  <Tr key={admin._id}>
                    <Td>{admin.firstName}</Td>
                    <Td>{admin.lastName}</Td>
                    <Td>{admin.email}</Td>
                    <Td>{admin.phone}</Td>
                    <Td>
                      <Badge colorScheme={admin.isVerified ? 'green' : 'red'} ml={2} variant='subtle'>
                        {admin.isVerified ? 'Verified' : 'Not Verified'}
                      </Badge>
                    </Td>
                    <Td>
                      <ViewButton onClick={() => handleView(admin)} />
                      <EditButton onClick={() => handleEdit(admin._id)} />
                      <DeactivateAdminButton />
                    </Td>
                  </Tr>
              ))}
            </Tbody>
          </Table>
        </StyledTableContainer>
      </>
  );
};

export default AdminTable;
