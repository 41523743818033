import React, {useCallback, useEffect, useRef, useState} from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Box,Text } from '@chakra-ui/react';
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import {DeleteButton, ViewButton} from "../../../../components/Styled/ActionButtons";
import { StyledTableContainer } from "../../../../components/Styled/StyledComponents";
import PaginationControls from "../../../../Context/PaginationControls/PaginationControls";
import {fetchAllLeakReports, deleteLeakReport} from "../LeaksAPI";
import ConfirmDialog from "../../../../Context/ConfirmDialog/ConfirmDialog";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";
import LeakReportViewForm from "./LeakReportViewForm";
import moment from "moment/moment";


const AreaTable = ({ leakReports, setLeakReports, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag, filterOptions }) => {
    const { openDrawer } = useDrawer();
    const [cities, setCities] = useState([]);
    const [error, setError] = useState('');
    const showToast = useSingleToast();
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    const showToastRef = useRef(showToast);


    // Update function encapsulated in useCallback to ensure it's only created once
    const updateLeakReportsAndPages = useCallback((newLeakReports, totalDocs) => {
        setLeakReports(newLeakReports);
        setTotalPages(Math.ceil(totalDocs / 10));
    }, [setLeakReports, setTotalPages]);

    const refetchLeakReportData = useCallback(async (options = {}) => {
        setError('');
        try {
            const leakReportsResponse = await fetchAllLeakReports(pageNumber, 10, filterOptions);
            if (leakReportsResponse && leakReportsResponse.totalPages) {
                updateLeakReportsAndPages(leakReportsResponse.leakReports, leakReportsResponse.totalPages);
            } else {
                console.error("No totalPages found:", leakReportsResponse);
                setLeakReports([]);
                setTotalPages(0);
            }

            if (!leakReportsResponse || !leakReportsResponse.leakReports ) {
                throw new Error('No data received from the server');
            }


            updateLeakReportsAndPages(leakReportsResponse.leakReports, leakReportsResponse.totalDocs);
        } catch (error) {
            console.error("Error fetching data:", error);
            setError(error.message || 'Failed to fetch data. Please try again later.');
            showToastRef.current({
                title: "Error fetching data",
                description: error.message || "An unexpected error occurred.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    }, [filterOptions, pageNumber, setLeakReports, setTotalPages, updateLeakReportsAndPages]);

    useEffect(() => {
        refetchLeakReportData();
    }, [refetchLeakReportData, pageNumber, filterOptions]);



    if (error) return <Box textAlign="center" my="4">{error}</Box>;
    if (dataLoaded && !leakReports?.length) return <Box textAlign="center" my="4">No records found.</Box>;

    const handleView = async (leakReport ) => {
        try {
            if (leakReport ) {
                openDrawer({
                    title: 'View Leak Report',
                    component: LeakReportViewForm,
                    props: { formData: leakReport },
                    key: `view-${leakReport ._id}`,
                }, 'md');
            } else {
                throw new Error('leak Report not found.');
            }
        } catch (error) {
            setError(error.message);
            showToast({
                title: 'Error',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleDelete = (areaId) => {
        setConfirmDialog({
            isOpen: true,
            title: "Confirm Delete",
            message: "Are you sure you want to delete this leak report record? This action cannot be undone.",
            onConfirm: () => confirmDelete(areaId),
        });
    };

    const confirmDelete = async (areaId) => {
        try {
            await deleteLeakReport (areaId);
            showToast({
                title: "Leak report Deleted",
                description: "Successfully deleted the leak report record.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ ...confirmDialog, isOpen: false });
            refetchLeakReportData(filterOptions); // Refresh the list
        } catch (error) {
            showToast({
                title: "Deletion Failed",
                description: "Could not delete the area. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ ...confirmDialog, isOpen: false });
        }
    };
    return (
        <>
            <StyledTableContainer>
                <PaginationControls pageNumber={pageNumber} setPageNumber={setPageNumber} totalPages={totalPages} />
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th>Ticket</Th>
                            <Th>Name</Th>
                            <Th>Contact Details</Th>
                            <Th>Where is the leaks</Th>
                            <Th>Village/Town</Th>
                            <Th>Address</Th>
                            <Th>Date</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {leakReports?.map(area => (
                            <Tr key={area._id}>
                                <Td>{area?.ticket}</Td>
                                <Td>{area?.name}</Td>
                                <Td>
                                    <Text> {area.contactDetails.phone}</Text>
                                    <Text> {area.contactDetails.email}</Text>
                                </Td>
                                <Td>{area.where}</Td>
                                <Td>{area.village}</Td>
                                <Td>{area.address}</Td>
                                <Td>{moment(area.createdDate).format("DD-MM-YYYY")}</Td>
                                <Td>

                                    <ViewButton onClick={() => handleView(area)} />
                                    <DeleteButton onClick={() => handleDelete(area?._id)} />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </StyledTableContainer>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                title={confirmDialog.title}
                message={confirmDialog.message}
                onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
                onConfirm={confirmDialog.onConfirm}
            />
        </>
    );
};

export default AreaTable;
