import React from 'react';
import {Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerBody, DrawerHeader} from '@chakra-ui/react';
import { useDrawer } from './DrawerContext';

const ReusableDrawer = () => {
    const { isDrawerOpen, drawerContent, closeDrawer, drawerSize } = useDrawer();

    return (
        <Drawer isOpen={isDrawerOpen} onClose={closeDrawer} placement="right" size={drawerSize}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                {/*<DrawerHeader>{drawerContent.title || ''}</DrawerHeader>*/}
                <DrawerHeader></DrawerHeader>
                <DrawerBody>
                    {drawerContent.component && React.createElement(drawerContent.component, {
                        ...drawerContent.props,
                        closeDrawer: closeDrawer,
                        key: drawerContent.key || 'default-key'
                    })}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

export default ReusableDrawer;
