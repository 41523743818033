import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
    VStack,
    Box,
    SimpleGrid,
    Image,
    Text,
    Spinner,
    FormControl,
    FormLabel,
    FormErrorMessage, GridItem, IconButton, Center,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { useDrawer } from '../../../../../Context/DrawerContext/DrawerContext';
import utils from '../../../../../utils/commonFunctions';
import {
    addPhotosToAlbum,
    deletePhoto, fetchAlbumById,
    fetchPhotosByAlbumId,
} from '../AlbumAPI';
import useSingleToast from '../../../../../hooks/UseSingleToast/UseSingleToast';
import CustomAlert from '../../../../../components/Styled/StyledAlert';
import ConfirmDialog from '../../../../../Context/ConfirmDialog/ConfirmDialog';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {ButtonStack, CustomButton} from "../../../../../components/Styled/StyledButtons";
import {DeleteIcon} from "@chakra-ui/icons";
import FormActionLabel from "../../../../../components/Styled/FormActionLabel";
import SectionHeading from "../../../../../components/SectionHeading/SectionHeading";
import Icon from "../../../../../@core/components/icon";


const AlbumUploadImages = ({ albumId, refetchAlbumData, filterOptions }) => {
    const { closeDrawer } = useDrawer();
    const showToast = useSingleToast();
    const notifyUserRef = useRef();
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]);
    const [deletedImages, setDeletedImages] = useState([]);
    const [album, setAlbum] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });


    const initialValues = {
        images: [],
    };

    /*const validationSchema = Yup.object().shape({
        images: Yup.array().min(1, 'Images are required').required('Images are required'),
    });*/

    notifyUserRef.current = (options) => {
        showToast(options);
    };

    useEffect(() => {
        const fetchAlbum = async () => {
            try {
                const albumData = await fetchAlbumById(albumId);
                setAlbum(albumData);
            } catch (error) {
                notifyUserRef.current({
                    title: 'Error',
                    description: `Failed to fetch album details: ${error.message}`,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };

        fetchAlbum();
    }, [albumId]);


    useEffect(() => {
        const fetchExistingPhotos = async () => {
            try {
                const response = await fetchPhotosByAlbumId(albumId);
                console.log("fetchPhotosByAlbumId",response)
                if (response && response.length > 0) {
                    setImages(response.map(photo => ({
                        id: uuidv4(),
                        preview: photo.FileUrl,
                        fileUrl: photo.FileUrl,
                        photoId: photo._id,
                        albumId: albumId,
                    })));
                } else {
                    setImages([]);
                }
            } catch (error) {
                notifyUserRef.current({
                    title: 'Error',
                    description: `Could not fetch existing photos: ${error.message}`,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };

        fetchExistingPhotos();
    }, [albumId]);

    const onDrop = async (acceptedFiles, setFieldValue) => {
        setLoading(true);
        try {
            const newImages = await Promise.all(
                acceptedFiles.map(async (file) => {
                    const fileId = uuidv4();
                    const filePath = `albums/${albumId}/${fileId}`;
                    const uploadResponse = await utils.handleFileUpload(file, filePath);

                    if (uploadResponse && uploadResponse.data && uploadResponse.data.fileUrl) {
                        return {
                            file,
                            id: fileId,
                            preview: uploadResponse.data.fileUrl,
                            fileUrl: uploadResponse.data.fileUrl,
                            albumId: albumId,
                        };
                    }
                    throw new Error(`Upload failed for ${file.name}`);
                })
            );

            setImages((currentImages) => [...currentImages, ...newImages]);
            setFieldValue('images', [...images, ...newImages]);
            showToast({
                title: 'Upload Successful',
                description: 'All files uploaded successfully.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            /*showToast({
                title: 'Upload Failed',
                description: `Failed to upload one or more files: ${error.message}`,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });*/
        } finally {
            setLoading(false);
        }
    };


    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles, setFieldValue) => onDrop(acceptedFiles, setFieldValue),
    });

    const handleDeleteImage = async (image, setFieldValue) => {
        setLoading(true);
        try {
            if (image.photoId) {
                await deletePhoto(albumId, image.photoId);
                setDeletedImages((current) => [...current, image.photoId]);
            }
            const updatedImages = images.filter((img) => img.id !== image.id);
            setImages(updatedImages);
            setFieldValue('images', updatedImages);
            showToast({
                title: 'Deleted',
                description: 'Photo deleted successfully.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            showToast({
                title: 'Error',
                description: `Failed to delete the photo: ${error.message}`,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (values) => {
        const photosToUpload = images.filter((img) => !img.photoId).map((img) => ({
            FileUrl: img.fileUrl,
            caption: '',
        }));

        try {
            if (photosToUpload.length > 0) {
                await addPhotosToAlbum(albumId, photosToUpload);
            }
            showToast({
                title: 'Success',
                description: 'Photos updated successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchAlbumData(filterOptions);
            closeDrawer();
        } catch (error) {
            showToast({
                title: 'Error',
                description: error.message || 'An unexpected error occurred',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleCancel = () => {
        setConfirmDialog({
            isOpen: true,
            title: 'Cancel Confirmation',
            message: 'Are you sure you want to cancel? Any unsaved changes will be lost.',
            onConfirm: () => {
                closeDrawer();
                setConfirmDialog({ ...confirmDialog, isOpen: false });
            },
        });
    };

    return (
        <>
            <FormActionLabel formAction="edit" formName="Album Images" />
            <Formik
                initialValues={initialValues}
                /*validationSchema={validationSchema}*/
                onSubmit={handleSubmit}
            >
                {(formikProps) => (
                    <Form>
                        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                            <SimpleGrid columns={1} gap={4} px={2} py={4}>
                                <>
                                    {album && (
                                        <>
                                        <SectionHeading icon="fluent:apps-list-detail-24-filled" text={album.title} />
                                        {/*<Box borderWidth="1px" borderRadius="lg" p={4} mb={4}>
                                            <Text fontSize="xl" color="blue.300" fontWeight="bold" mb={2}>{album.title}</Text>
                                            {album.description && (
                                                <>
                                                    <Divider my={2} />
                                                    <Text>{album.description}</Text>
                                                </>
                                            )}
                                        </Box>*/}
                                        </>
                                    )}
                                </>
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.images && formikProps.touched.images}>
                                        <FormLabel htmlFor="images">Album Images :</FormLabel>
                                        <Box
                                            {...getRootProps()}
                                            border="2px dashed #ccc"
                                            padding="20px"
                                            textAlign="center"
                                            cursor="pointer"
                                            transition="border .3s ease-in-out"
                                        >
                                            <input {...getInputProps()} />
                                            <Icon
                                                icon="mdi:cloud-upload"
                                                width="20px"
                                                height="20px"
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    color: '#63B3ED'
                                                }}
                                            />
                                            {isDragActive ? (
                                                <p>Drop the files here...</p>
                                            ) : (
                                                <p>Drag 'n' drop some images here, or click to select images for your album</p>
                                            )}
                                        </Box>

                                        {formikProps.errors.images && formikProps.touched.images && (
                                            <FormErrorMessage>{formikProps.errors.images}</FormErrorMessage>
                                        )}
                                    </FormControl>
                                </GridItem>
                            </SimpleGrid>
                            {/*{images.length > 0 ? (
                                <SimpleGrid columns={3} spacing={4} pt={4}>
                                    {images.map((image) => (
                                        <Box key={image.id} p={1} borderWidth="1px" borderRadius="lg" overflow="hidden" position="relative">
                                            <Image src={image.preview} alt="Preview" boxSize="100px" objectFit="cover" />
                                            <DeleteButton onClick={() => handleDeleteImage(image, formikProps.setFieldValue)} />
                                        </Box>
                                    ))}
                                </SimpleGrid>
                            ) : (
                                <Text>No Images in this Album</Text>
                            )}*/}

                            {images.length > 0 ? (
                                <SimpleGrid columns={{ base: 2, sm: 3, md: 4, lg: 5, xl: 6 }} spacing={4} pt={4}>
                                    {images.map((image) => (
                                        <Box key={image.id} p={1} borderWidth="1px" borderRadius="lg" overflow="hidden" position="relative">
                                            <Image src={image.preview} alt="Preview" boxSize="100px" objectFit="cover" />
                                            <IconButton
                                                icon={<DeleteIcon />}
                                                onClick={() => handleDeleteImage(image, formikProps.setFieldValue)}
                                                variant="solid"
                                                colorScheme="red"
                                                position="absolute"
                                                top="2px"
                                                right="2px"
                                                size="xs"
                                                bg="rgba(255, 0, 0, 0.7)"
                                                color="white"
                                                _hover={{ bg: "rgba(255, 0, 0, 1)" }}
                                            />
                                        </Box>
                                    ))}
                                </SimpleGrid>
                            ) : (
                                <Center py={6}>
                                    <Text color="gray.500">No Images in this Album</Text>
                                </Center>
                            )}

                            <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }} mt={8} mb={8}>
                                <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
                                <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                                    <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                                        Cancel
                                    </CustomButton>
                                    <CustomButton
                                        type="submit"
                                        disabled={formikProps.isSubmitting || loading}
                                        style={{
                                            opacity: formikProps.isSubmitting || loading ? 0.7 : 1,
                                            pointerEvents: formikProps.isSubmitting || loading ? 'none' : 'auto',
                                        }}
                                    >
                                        {formikProps.isSubmitting || loading ? (
                                            <>
                                                <Spinner size="xs" mr={2} />
                                                Submitting...
                                            </>
                                        ) : 'Submit'}
                                    </CustomButton>
                                </ButtonStack>
                            </Box>
                        </VStack>
                    </Form>
                )}
            </Formik>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
                onConfirm={confirmDialog.onConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

export default AlbumUploadImages;
