import React, { useEffect, useState, useCallback } from 'react';
import {useError} from "../../../../../Context/ErrorContext/ErrorContext";
import {Box} from "@chakra-ui/react";
import {fetchAllPublications} from "../PublicationsAPI";
import PublicationsTableTopBar from "./PublicationsTableTopBar";
import PublicationsTable from "./PublicationsTable";

const PublicationsContainer = () => {
    const [publications, setPublications] = useState([]);
    const [filterOptions, setFilterOptions] = useState({});
    const [error, setError] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [fetchTrigger, setFetchTrigger] = useState(false);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const { handleError } = useError();

    const refetchPublicationData = useCallback(async (options = {}) => {
        setDataLoaded(false);
        try {
            const data = await fetchAllPublications(pageNumber, 10, filterOptions, handleError);
            setPublications(data.publications);
            setTotalPages(data.totalPages);
            setError('');
            setDataLoaded(true);
        } catch (error) {
            setError(error.message);
            setPublications([]);
            setTotalPages(0);
        }
    }, [pageNumber, filterOptions, handleError]);

    useEffect(() => {
        refetchPublicationData();
    }, [fetchTrigger, refetchPublicationData, refreshFlag, totalPages, fetchTrigger, filterOptions]);

    if (!publications) {
        return (
            <Box textAlign="center" my="4" p="4" color="red.600" bg="blue.50">
                <p>Loading Publications...</p>
            </Box>
        );
    }

    return (
        <>
            <PublicationsTableTopBar
                refetchPublicationData={refetchPublicationData}
                setPageNumber={setPageNumber}
                setFilterOptions={setFilterOptions}
                setFetchTrigger={setFetchTrigger}
            />
            <PublicationsTable
                publications={publications}
                setPublications={setPublications}
                error={error}
                pageNumber={pageNumber}
                setRefreshFlag={setRefreshFlag}
                setPageNumber={setPageNumber}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                filterOptions={filterOptions}
                dataLoaded={dataLoaded}
                refetchPublicationData={refetchPublicationData}
            />
        </>
    );
};

export default PublicationsContainer;
