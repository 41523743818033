import React, { useEffect, useState, useCallback } from 'react';
import PublicationCategoryTable from './PublicationCategoryTable';
import PublicationCategoryTableTopBar from './PublicationCategoryTableTopBar';
import { fetchAllPublicationCategories } from '../PublicationCategoryAPI';
import {useError} from "../../../../../Context/ErrorContext/ErrorContext";
import {Box} from "@chakra-ui/react";

const PublicationCategoryContainer = () => {
    const [publicationCategories, setPublicationCategories] = useState([]);
    const [filterOptions, setFilterOptions] = useState({});
    const [error, setError] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [fetchTrigger, setFetchTrigger] = useState(false);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const { handleError } = useError();

    const refetchPublicationCategoryData = useCallback(async (options = {}) => {
        setDataLoaded(false);
        try {
            const data = await fetchAllPublicationCategories(pageNumber, 10, filterOptions, handleError);
            setPublicationCategories(data.publicationCategories);
            setTotalPages(data.totalPages);
            setError('');
            setDataLoaded(true);
        } catch (error) {
            setError(error.message);
            setPublicationCategories([]);
            setTotalPages(0);
        }
    }, [pageNumber, filterOptions, handleError]);

    useEffect(() => {
        refetchPublicationCategoryData();
    }, [fetchTrigger, refetchPublicationCategoryData, refreshFlag, totalPages, fetchTrigger, filterOptions]);

    if (!publicationCategories) {
        return (
            <Box textAlign="center" my="4" p="4" color="red.600" bg="blue.50">
                <p>Loading Publication Categories...</p>
            </Box>
        );
    }

    return (
        <>
            <PublicationCategoryTableTopBar
                refetchPublicationCategoryData={refetchPublicationCategoryData}
                setPageNumber={setPageNumber}
                setFilterOptions={setFilterOptions}
                setFetchTrigger={setFetchTrigger}
            />
            <PublicationCategoryTable
                publicationCategories={publicationCategories}
                setPublicationCategories={setPublicationCategories}
                error={error}
                pageNumber={pageNumber}
                setRefreshFlag={setRefreshFlag}
                setPageNumber={setPageNumber}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                filterOptions={filterOptions}
                dataLoaded={dataLoaded}
                refetchPublicationCategoryData={refetchPublicationCategoryData}
            />
        </>
    );
};

export default PublicationCategoryContainer;
