import React from 'react'
import DefaultTemplate from '../../../components/DefaultTemplate/DefaultTemplate'
import CityUserContainer from './Components/NewConnectionContainer'
import {Icon} from "@iconify/react";

const NewConnection = () => {
    return (
        <>
            <DefaultTemplate Content={ <CityUserContainer/> } Title={"Individual Connection Requests" } icon={<Icon icon="fa-solid:hand-holding-water" />}/>
        </>
    )
}

export default NewConnection
