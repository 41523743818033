import * as Yup from 'yup';
import enums from 'wuc-library/enums';

const connectionUpdateSchema = Yup.object({
    customerNumber: Yup.string().test(
        'customer-number-required',
        'Customer Number is required when the request status is COMPLETE.',
        function(value) {
            const { status } = this.parent;
            return status === enums.ConnectionRequestStatus.COMPLETE ? value && value.trim() !== '' : true;
        }
    ),
    contractNumber: Yup.string().test(
        'contract-number-required',
        'Contract Number is required when the request status is COMPLETE.',
        function(value) {
            const { status } = this.parent;
            return status === enums.ConnectionRequestStatus.COMPLETE ? value && value.trim() !== '' : true;
        }
    ),
    isExistingConnection: Yup.boolean(),
    isPlotFenced: Yup.boolean(),
    connectionType: Yup.array().of(Yup.string()).required('Connection type is required.'),
    applicantType: Yup.string().required('Applicant type is required.'),
    serviceConnectionDetails: Yup.object({
        plotNo: Yup.string().required('Service Plot number is required.'),
        ward: Yup.string().required('Service Area/Ward is required.'),
        city: Yup.string().required('Service City/Village/Town is required.')
    }),
    ownerOrLandlord: Yup.object({
        firstName: Yup.string().required('Owner/Landlord First name is required.'),
        middleName: Yup.string().nullable(),
        lastName: Yup.string().required('Owner/Landlord Last name is required.'),
        cell: Yup.string().required('Owner/Landlord Cellphone Number is required.'),
        cell2: Yup.string().nullable(),
        email: Yup.string().email('Invalid email address').required('Owner/Landlord Email is required.')
    }),
    comments: Yup.string().nullable(),
    personalDetails: Yup.object({
        firstName: Yup.string().required('First name is required.'),
        lastName: Yup.string().required('Last name is required.'),
        middleName: Yup.string().nullable(),
        identityNo: Yup.string().required('Identity number is required.'),
        identityType: Yup.string().required('Identity type is required.'),
        maritalStatus: Yup.string().required('Marital status is required.'),
        maritalType: Yup.string().when('maritalStatus', (maritalStatus, schema) => {
            return maritalStatus === 'Married' ? schema.required('Marital type is required when marital status is married.') : schema.notRequired()
        }),
        sex: Yup.string().required('Gender is required.'),
        dateOfBirth: Yup.date().required('Date of birth is required.')
    }),
    nextOfKin: Yup.object({
        firstName: Yup.string().required('First name is required.'),
        middleName: Yup.string().nullable(),
        lastName: Yup.string().required('Last name is required.'),
        relationship: Yup.string().required('Relationship is required.'),
        cell: Yup.string()
            .required('Cell number is required.')
            .matches(/^\d+$/, 'Valid phone number is required.')
    }),
    address: Yup.object({
        plotNo: Yup.string().required('Plot number is required.'),
        postal: Yup.string().required('Postal address is required.'),
        street: Yup.string().required('Street is required.'),
        ward: Yup.string().required('Ward is required.'),
        city: Yup.string().required('City is required.'),
        district: Yup.string().required('District is required.')
    }),
    contactDetails: Yup.object({
        homeTel: Yup.string().matches(/^\d+$/, 'Valid home telephone is required.').nullable(),
        workTel: Yup.string().matches(/^\d+$/, 'Valid work telephone is required.').nullable(),
        cell: Yup.string().matches(/^\d+$/, 'Valid cell number is required.').required('Cell number is required.'),
        email: Yup.string().email('Invalid email address').required('Email is required.')
    }),
    files: Yup.object({
        idKey: Yup.mixed().required('ID document is required.'),
        /*maritalProofKey: Yup.mixed().when('personalDetails.maritalStatus', (maritalStatus, schema) => {
            return maritalStatus === 'Married' ? schema.required('Marriage proof document is required.') : schema.notRequired()
        }),*/
        maritalProofKey: Yup.string().test(
            'marital-proof-required',
            'Marriage proof document is required.',
            function(value) {
                const maritalStatus = this.options.context.personalDetails.maritalStatus;
                return maritalStatus === enums.MaritalStatus.MARRIED ? !!value : true;
            }
        ),
        leeseOrTitleDeed: Yup.mixed().required('Lease/Title Deed is required.')
    }),
    consent: Yup.boolean().oneOf([true], 'Consent is required'),
    dateOfConsent: Yup.date().when('consent', (consent, schema) => {
        return consent ? schema.required('Date of consent is required when consent is given.') : schema.notRequired()
    }),
    spouseCustomerNumber: Yup.string().nullable(),
    status: Yup.string().required('Status is required.')
});

export default connectionUpdateSchema;
