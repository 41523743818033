import React, { useState, useEffect } from 'react';
import {
    useToast
} from '@chakra-ui/react';
import PageTableTopBar from './PageTableTopBar'; 
import PageTable from './PageTable';  
import pageService from '../../../services/pageService'; 
const categories = [
    "RESIDENTIAL",
    "COMMERCIAL",
    "SERVICES",
    "HELP_AND_ADVICE",
    "PRODUCT_AND_SERVICES",
    "DAM_LEVELS",
    "WUC_AT_A_GLANCE",
    "YOUR_WATER_ACCOUNT",
    "PRE_DEVELOPMENT_ENQUIRY",
    "ABOUT_YOUR_METER_READING",
    "TERMS_AND_CONDITIONS",
    "QUICK_LINKS"
];
const PageContainer = () => {
    const [pages, setPages] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [categoryFilter, setCategoryFilter] = useState('');
    const toast = useToast();

    // Function to fetch pages from the API
    const fetchPages = async () => {
        try {
            const data = await pageService.getPages();
        
            setPages(data.data);
        } catch (error) {
            toast({
                title: 'Error fetching pages',
                description: error.message || 'There was an error accessing the page data.',
                status: 'error',
                duration: 5000,
                isClosable: true
            });
        }
    };

    useEffect(() => {
        fetchPages(); // Fetch pages on component mount and when filters change
    }, [searchQuery, categoryFilter]);

    return (
        <>
                <PageTableTopBar
                    setSearchQuery={setSearchQuery}
                    setCategoryFilter={setCategoryFilter}
                    categories={categories} 
                    pages={pages}
                    refetchPages={fetchPages}
                />
                <PageTable
                    pages={pages}
                    refetchPages={fetchPages}
                />
      
        </>
    );
};

export default PageContainer;
