import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Table, Thead, Tbody, Tr, Th, Td, Box, Badge} from '@chakra-ui/react';
import {useDrawer} from "../../../../../Context/DrawerContext/DrawerContext";
import useSingleToast from "../../../../../hooks/UseSingleToast/UseSingleToast";
import {DeleteButton, EditButton, ViewButton} from "../../../../../components/Styled/ActionButtons";
import ConfirmDialog from "../../../../../Context/ConfirmDialog/ConfirmDialog";
import {StyledTableContainer} from "../../../../../components/Styled/StyledComponents";
import PaginationControls from "../../../../../Context/PaginationControls/PaginationControls";
import PublicationEditForm from "./PublicationEditForm";
import {
    deletePublication,
    fetchAllPublications,
    fetchAllPublicationsCategories,
    fetchPublicationById
} from "../PublicationsAPI";
import PublicationViewForm from "./PublicationViewForm";
import {Icon} from "@iconify/react";
import utils from "../../../../../utils/commonFunctions";

const PublicationTable = ({ publications, setPublications, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag, filterOptions }) => {
    const { openDrawer } = useDrawer();
    const [publicationCategories, setPublicationCategories] = useState([]);
    const [error, setError] = useState('');
    const showToast = useSingleToast();
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    const showToastRef = useRef(showToast);


    // Update function encapsulated in useCallback to ensure it's only created once
    const updatePublicationsAndPages = useCallback((newPublications, totalDocs) => {
        setPublications(newPublications);
        setTotalPages(Math.ceil(totalDocs / 10));
    }, [setPublications, setTotalPages]);

    const refetchPublicationData = useCallback(async (options = {}) => {
        setError('');
        try {
            const publicationsResponse = await fetchAllPublications(pageNumber, 10, filterOptions);
            const publicationCategoriesResponse = await fetchAllPublicationsCategories();

            if (publicationsResponse && publicationsResponse.totalPages) {
                updatePublicationsAndPages(publicationsResponse.publications, publicationsResponse.totalPages);
            } else {
                console.error("No totalPages found:", publicationsResponse);
                setPublications([]);
                setTotalPages(0);
            }

            if (!publicationsResponse || !publicationsResponse.publications || !publicationCategoriesResponse || !publicationCategoriesResponse.publicationCategories) {
                throw new Error('No data received from the server');
            }

            if (publicationCategoriesResponse && publicationCategoriesResponse.publicationCategories) {
                setPublicationCategories(publicationCategoriesResponse.publicationCategories);
            } else {
                console.error("PublicationCategories data missing:", publicationCategoriesResponse);
                setPublicationCategories([]);
            }

            updatePublicationsAndPages(publicationsResponse.publications, publicationsResponse.totalDocs);
            setPublicationCategories(publicationCategoriesResponse.publicationCategories);
        } catch (error) {
            console.error("Error fetching data:", error);
            setError(error.message || 'Failed to fetch data. Please try again later.');
            showToastRef.current({
                title: "Error fetching data",
                description: error.message || "An unexpected error occurred.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    }, [filterOptions, pageNumber, setPublications, setTotalPages, updatePublicationsAndPages]);

    useEffect(() => {
        refetchPublicationData();
    }, [refetchPublicationData, pageNumber, filterOptions]);

    const getCategoryNameById = useCallback((id) => {
        const center = publicationCategories.find(mc => mc._id === id);
        return center ? center.name : "Unknown";
    }, [publicationCategories]);


    if (error) {
        return <Box textAlign="center" my="4">{error}</Box>;
    }

    if (!publications.length && dataLoaded) {
        return <Box textAlign="center" my="4">No records found.</Box>;
    }



    const handleEdit = (publicationId) => {
        openDrawer({
            title: 'Edit Publication',
            component: PublicationEditForm,
            props: {
                publicationId: publicationId,
                refetchPublicationData: refetchPublicationData,
                setRefreshFlag: setRefreshFlag,
            },
            key: `edit-${publicationId}`,
        }, 'md');
    };


    const handleView = async (publication) => {
        try {
            const publicationData = await fetchPublicationById(publication._id);
            if (publicationData) {
                openDrawer({
                    title: 'View Publication',
                    component: PublicationViewForm,
                    props: { formData: publicationData },
                    key: `view-${publication._id}`,
                }, 'md');
            } else {
                throw new Error('Publication data not found.');
            }
        } catch (error) {
            setError(error.message);
            showToast({
                title: 'Error',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };


    const handleDelete = (publicationId) => {
        setConfirmDialog({
            isOpen: true,
            title: "Confirm Delete",
            message: "Are you sure you want to delete this publication? This action cannot be undone.",
            onConfirm: () => confirmDelete(publicationId),
        });
    };

    const confirmDelete = async (publicationId) => {
        try {
            await deletePublication(publicationId);
            showToast({
                title: "Publication Deleted",
                description: "Successfully deleted the publication.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ ...confirmDialog, isOpen: false });
            refetchPublicationData(filterOptions); // Refresh the list
        } catch (error) {
            showToast({
                title: "Deletion Failed",
                description: "Could not delete the publication. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ ...confirmDialog, isOpen: false });
        }
    };

    return (
        <>
            <StyledTableContainer>
                <PaginationControls pageNumber={pageNumber} setPageNumber={setPageNumber} totalPages={totalPages} />
                {publications.length > 0 && (
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th>Type</Th>
                            <Th>Publication Title</Th>
                            <Th>Category</Th>
                            <Th>Size</Th>
                            <Th>Status</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {publications.map(publication => (
                            <Tr key={publication._id}>
                                <Td>
                                    <Icon icon={utils.getFileTypeIcon(publication.extension)} />
                                </Td>
                                <Td>{publication.title}</Td>
                                <Td>{getCategoryNameById(publication.categoryId._id)}</Td>
                                <Td>
                                    <Badge colorScheme="blue" variant="solid">{utils.formatFileSize(publication.size)}</Badge>
                                </Td>
                                <Td>
                                    <Badge variant="solid" colorScheme={publication.status === 'Archived' ? 'red' : 'green'}>
                                        {publication.status}
                                    </Badge>
                                </Td>
                                <Td>
                                    <ViewButton onClick={() => handleView(publication)} />
                                    <EditButton onClick={() => handleEdit(publication._id)} />
                                    <DeleteButton onClick={() => handleDelete(publication._id)} />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
                )}
            </StyledTableContainer>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                title={confirmDialog.title}
                message={confirmDialog.message}
                onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
                onConfirm={confirmDialog.onConfirm}
            />
        </>
    );
};

export default PublicationTable;
