import {API1} from "../../../../utils/api";


// Create Album
export const createAlbum = async (albumData) => {
    try {
        const response = await API1.post('/albums', albumData);
        return response.data.album;
    } catch (error) {
        throw new Error(error?.message || "An unexpected error occurred while creating the album.");
    }
};

// Fetch All Albums
export const fetchAllAlbums = async (pageNumber = 1, pageSize = 10, filterOptions = {}) => {
    try {
        const queryParams = new URLSearchParams({
            ...filterOptions,
            pageNumber,
            pageSize
        }).toString();

        const response = await API1.get(`/albums?${queryParams}`);

        if (response.status === 200) {
            const { docs: albums, totalDocs } = response.data;
            return {
                albums,
                totalPages: Math.ceil(totalDocs / pageSize),
            };
        } else {
            throw new Error('Unexpected response from the server while fetching Albums.');
        }
    } catch (error) {
        console.error("Error fetching Albums:", error);
        throw new Error('Failed to fetch Albums. Please try again.');
    }
};


// Fetch Album by ID
export const fetchAlbumById = async (albumId) => {
    try {
        const queryParams = new URLSearchParams({ id: albumId }).toString();
        const response = await API1.get(`/albums?${queryParams}`);
        if (response.status === 200 && response.data.docs.length > 0) {
            return response.data.docs[0];
        } else {
            throw new Error('Album not found.');
        }
    } catch (error) {
        throw error;
    }
};


// Update Album
export const updateAlbum = async (albumId, albumData) => {
    try {
        const response = await API1.put(`/albums/${albumId}`, albumData);
        if (response.status !== 200) {
            throw new Error('Update failed due to server response');
        }
        return response.data;
    } catch (error) {
        console.error("Error updating album:", error);
        throw new Error(error?.message || "An error occurred while updating the album.");
    }
};


// Delete Album
export const deleteAlbum = async (albumId) => {
    try {
        const response = await API1.delete(`/albums/${albumId}`);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "Failed to delete the album.");
    }
};

// Add Photos to Album
export const addPhotosToAlbum = async (albumId, photos) => {
    try {
        const response = await API1.put(`/albums/${albumId}/photos`, { photos });
        return response.data;
    } catch (error) {
        console.error("Error adding photos:", error);
        throw new Error(error?.message || "Failed to add photos to the album.");
    }
};

// Delete Photo
export const deletePhoto = async (albumId, photoId) => {
    try {
        const response = await API1.delete(`/albums/${albumId}/photos/${photoId}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(`Failed to delete photo: ${response.statusText}`);
        }
    } catch (error) {
        console.error("Error deleting photo:", error);
        throw new Error(error?.response?.data?.message || "Failed to delete the photo.");
    }
};


export const fetchPhotosByAlbumId = async (albumId) => {
    try {
        const queryParams = new URLSearchParams({ albumId }).toString();
        const response = await API1.get(`/albums/photos?${queryParams}`);
        if (response.status === 200) {
            return response.data.docs;
        } else {
            throw new Error('Failed to fetch photos.');
        }
    } catch (error) {
        throw error;
    }
};

