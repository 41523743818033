// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 
-Hide the badge

.grecaptcha-badge {
  display: none;
} 

*/

.grecaptcha-badge {
  transform: translateY(-60px);
}

.custom-datepicker {
  border: 1px solid #E2E8F0;
  border-radius: 0.375rem;
  padding: 0.5rem 0.75rem;
}

`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;;;;;;CAOC;;AAED;EACE,4BAA4B;AAC9B;;AAEA;EACE,yBAAyB;EACzB,uBAAuB;EACvB,uBAAuB;AACzB","sourcesContent":["/* \n-Hide the badge\n\n.grecaptcha-badge {\n  display: none;\n} \n\n*/\n\n.grecaptcha-badge {\n  transform: translateY(-60px);\n}\n\n.custom-datepicker {\n  border: 1px solid #E2E8F0;\n  border-radius: 0.375rem;\n  padding: 0.5rem 0.75rem;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
