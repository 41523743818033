import React, { useEffect, useState } from 'react';
import {
    VStack, Box, Text, Stack, Flex, SimpleGrid, Heading
} from '@chakra-ui/react';
import FormActionLabel from "../../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../../components/Styled/StyledButtons";
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import {Icon} from "@iconify/react";
import { fetchBranchById, fetchAreaById } from "../BranchesAPI";
import utils from "../../../../utils/commonFunctions";
import DOMPurify from "dompurify";

const DetailSection = ({ icon, title, children, style = {} }) => (
    <Box>
        <Flex align="center" px={2} py={3} bg="blue.50" {...style} mt={8} borderRadius="0.375em" mb={4}>
            <Icon icon={icon} width="20" height="24" style={{ marginRight: '10px', color: '#63b3ed', marginLeft: '10px' }} />
            <Heading size="sm" fontWeight="bold" color="blue.800">{title}</Heading>
        </Flex>
        {children}
    </Box>
);

const BranchViewForm = ({ formData }) => {
    const { closeDrawer } = useDrawer();
    const [branchData, setBranchData] = useState({
        name: '',
        areaName: '',
        description: '',
        branchMap: null
    });

    const safeDescription = utils.sanitizeHtml(branchData.description);

    const createMarkup = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, "text/html");

        const iframes = doc.getElementsByTagName("iframe");
        for (let iframe of iframes) {
            iframe.style.width = "100%";
            iframe.style.height = "250px";
        }

        const serializedContent = doc.body.innerHTML;
        const cleanContent = DOMPurify.sanitize(serializedContent, {
            ADD_TAGS: ["iframe"],
            ADD_ATTR: ['style', 'frameborder', 'allow', 'allowfullscreen', 'loading', 'referrerpolicy', 'src']
        });

        return { __html: cleanContent };
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const branch = await fetchBranchById(formData._id);
                const area = await fetchAreaById(formData.areaId);
                setBranchData({
                    ...branch,
                    areaName: area ? area.name : 'N/A'
                });
            } catch (error) {
                console.log('Error fetching branch details:', error);
            }
        };
        fetchData();
    }, [formData]);

    return (
        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
            <FormActionLabel formAction="view" formName="Branch"/>
            <Stack spacing={4} px={2} py={4} mt={4}>
                <DetailSection title="Branch Information" icon="gis:map-poi">
                    <SimpleGrid columns={{ base: 1, sm: 1 }} gap={4} px={4}>
                        <Text><strong>Name:</strong> {branchData.name}</Text>
                        <Text><strong>Area:</strong> {branchData.areaName}</Text>
                        <Text><strong>Description:</strong>
                            <div dangerouslySetInnerHTML={{__html: safeDescription}}/>
                        </Text>
                    </SimpleGrid>
                </DetailSection>
                <DetailSection title="Branch Map" icon="gis:poi-map-o">
                    {branchData.branchMap ? (
                        <div dangerouslySetInnerHTML={createMarkup(branchData.branchMap)} />
                    ) : (
                        <Text>No map available</Text>
                    )}
                </DetailSection>

                <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }} mt={8} mb={8}>
                    <CustomAlert status="warning" message="View Only Mode" />
                    <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                        <CustomButton type="cancel" onClick={closeDrawer} showIcon={false}>
                            Close
                        </CustomButton>
                    </ButtonStack>
                </Box>
            </Stack>
        </VStack>
    );
};

export default BranchViewForm;