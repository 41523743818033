import React, { useEffect, useState, useCallback } from 'react';
import BranchTable from './BranchesTable';
import BranchTableTopBar from './BranchTableTopBar';
import { fetchAllBranches } from '../BranchesAPI';

const BranchContainer = () => {
    const [branches, setBranches] = useState([]);
    const [error, setError] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [filterOptions, setFilterOptions] = useState({});
    const [fetchTrigger, setFetchTrigger] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const refetchBranchData = useCallback(async (options = {}) => {
        setDataLoaded(false);
        try {
            const data = await fetchAllBranches(pageNumber, 10, filterOptions);
            setBranches(data.branches);
            setTotalPages(data.totalPages);
            setError('');
            setDataLoaded(true);
        } catch (error) {
            console.error('Error fetching all branches:', error.message);
            setError('Failed to fetch branches data.');
            setBranches([]);
            setTotalPages(0);
        }
    }, [filterOptions, pageNumber]);

    useEffect(() => {
        refetchBranchData();
    }, [pageNumber, refreshFlag, refetchBranchData, totalPages, fetchTrigger, filterOptions]);

    return (
        <>
            <BranchTableTopBar
                refetchBranchData={refetchBranchData}
                setPageNumber={setPageNumber}
                setFilterOptions={setFilterOptions}
                setFetchTrigger={setFetchTrigger}

            />
            <BranchTable
                branches={branches}
                setBranches={setBranches}
                setRefreshFlag={setRefreshFlag}
                refetchBranchData={refetchBranchData}
                error={error}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                filterOptions={filterOptions}
                dataLoaded={dataLoaded}
            />
        </>
    );
};

export default BranchContainer;
