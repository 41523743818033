import React, {useCallback, useEffect, useState} from 'react';
import {Flex, GridItem} from '@chakra-ui/react';
import { StyledGrid } from "../../../components/Styled/StyledComponents";
import { ButtonStack, CustomButton } from "../../../components/Styled/StyledButtons";
import {Icon} from "@iconify/react";
import PaymentsSearchFilter from "./PaymentsSearchFilter";
import CsvDownloadButton from "react-json-to-csv";
import moment from "moment/moment";
import {exportPayments} from "../PaymentsAPI";

const PaymentsTableTopBar = ({  setPageNumber, setFilterOptions, setFetchTrigger }) => {
    const [exportPayment, setExportPaymentReports] = useState([]);
    useEffect(() => {
        const exportData=async ()=>{
            const exportPayment= await exportPayments();
            setExportPaymentReports(exportPayment)
        }
        exportData()
    }, []);

    const handleFilterChange = useCallback(async (newFilterOptions) => {
        setPageNumber(1);
        await new Promise(resolve => setTimeout(resolve, 0));
        setFilterOptions(newFilterOptions);
        setFetchTrigger(f => !f);
    }, [setPageNumber, setFilterOptions, setFetchTrigger]);

    return (
        <>
            <StyledGrid templateColumns="repeat(12, 1fr)">
                <GridItem colSpan={{ base: 10, md: 11 }}>
                    <PaymentsSearchFilter onFilterChange={handleFilterChange} />
                </GridItem>
                <GridItem colSpan={{ base: 12, md: 1 }}>
                    <ButtonStack direction="row">
                        <CsvDownloadButton children={ <CustomButton
                            type="addNew"
                            showIcon={true}
                            showTooltip={true}
                            tooltipLabel="Export csv"
                            icon={<Icon icon="ph:file-csv-light" />}

                        >
                            Export
                        </CustomButton>} data={exportPayment?exportPayment:[]} filename={`WUC-online-payments-${moment().format('DD-MM-YYYY')}`}/>

                    </ButtonStack>
                </GridItem>
            </StyledGrid>
            <Flex bg="white" mb={4}></Flex>
        </>
    );
};

export default PaymentsTableTopBar;
