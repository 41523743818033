import { Box, Flex, Heading} from '@chakra-ui/react';
import {Icon} from "@iconify/react";

const DetailSection = ({ icon, title, children, style = {} }) => (
    <Box>
        <Flex
            align="center"
            px={2}
            py={3}
            bg="blue.50"
            mt={8}
            borderRadius="0.375em"
            mb={4}
            sx={{ ...style }}
        >
            <Icon
                icon={icon}
                width="24"
                height="24"
                style={{ marginRight: '10px', color: '#63b3ed', marginLeft: '10px' }}
            />
            <Heading size="sm" fontWeight="bold" color="blue.800">{title}</Heading>
        </Flex>
        {children}
    </Box>
);

export default DetailSection;
