import React from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  useToast, Flex,
} from '@chakra-ui/react';
import { ViewButton, EditButton, DeleteButton } from '../../../../../components/Styled/ActionButtons'; // Adjust path as necessary
import { StyledTableContainer } from '../../../../../components/Styled/StyledComponents'; // Adjust path as necessary
import PhotoEditForm from './PhotoEditForm'; // Adjust path as necessary
import PhotoViewForm from './PhotoViewForm'; // Adjust path as necessary
import { useDrawer } from '../../../../../Context/DrawerContext/DrawerContext'; // Adjust path as necessary
import photoService from '../../../../../services/photoService'; // Adjust path as necessary

const PhotoTable = ({ filteredPhotos,albums, fetchPhotos }) => {
  const toast = useToast();
  const { openDrawer } = useDrawer();

  const handleView = (photo) => {
    openDrawer({
      title: 'View Photo',
      component: PhotoViewForm,
      props: { photo }
    });
  };

  const handleEdit = (photoId) => {
    openDrawer({
      title: 'Edit Photo',
      component: PhotoEditForm,
      props: { photoId,albums, fetchPhotos }
    });
  };

  const handleDelete = async (photoId) => {
    try {
      await photoService.deletePhoto(photoId);
      toast({
        title: 'Photo Deleted',
        description: 'The photo has been successfully deleted.',
        status: 'success',
        duration: 5000,
        isClosable: true
      });
      fetchPhotos(); // Refresh the photo list after deletion
    } catch (error) {
      toast({
        title: 'Failed to Delete Photo',
        description: error.message || 'An error occurred while deleting the photo.',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  };

  return (
    <StyledTableContainer>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>Album</Th>
            <Th>Caption</Th>
            <Th>Photo</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredPhotos.map((photo) => (
            <Tr key={photo._id}>
              <Td>{photo.album.title}</Td>
              <Td>{photo.caption}</Td>
              <Td>
                <Flex alignItems={'center'} height="100px" width="100px" overflow="hidden">
                  <img src={photo.url} alt="Photo" style={{ width: '100%', height: 'auto' }} />
                </Flex>
              </Td>
              <Td>
                <ViewButton onClick={() => handleView(photo)} />
                <EditButton onClick={() => handleEdit(photo._id)} />
                <DeleteButton onClick={() => handleDelete(photo._id)} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </StyledTableContainer>
  );
};

export default PhotoTable;
