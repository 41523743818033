import React from 'react'
import DefaultTemplate from '../../../components/DefaultTemplate/DefaultTemplate'
import ConsultantUserContainer from './Components/ConsultantContainer'
import {Icon} from "@iconify/react";

const Consultants = () => {
    return (
        <>
            <DefaultTemplate Content={ <ConsultantUserContainer/> } Title={"Consultants Management" } icon={<Icon icon="streamline:customer-support-1-solid" />}/>
        </>
    )
}

export default Consultants
