import React, { useEffect, useRef } from 'react';
import { useDrawer } from "../../../Context/DrawerContext/DrawerContext";
import { useReactToPrint } from 'react-to-print';
import PollInformation from "../../../components/Styled/PollInformation";
import useSingleToast from "../../../hooks/UseSingleToast/UseSingleToast";



const PollViewForm = ({ formData, pollData }) => {
    const showToast = useSingleToast();
    const showToastRef = useRef(false);
    const printComponentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current,
        onBeforeGetContent: () => console.log("Preparing document..."),
        onAfterPrint: () => console.log("Printing process done."),
    });

    const handlePrintDebug = () => {
        console.log("Attempting to print...");
        handlePrint();
    };

    useEffect(() => {
        if ((!formData || Object.keys(formData).length === 0 ) && !showToastRef.current) {
            showToast({
                title: 'Poll Data Information',
                description: "Poll data is yet to updated.",
                status: 'warning',
                duration: 5000,
                isClosable: true,
            });
            console.log(formData);
            showToastRef.current = true;
        }
    }, [pollData, showToast]);

    const { closeDrawer } = useDrawer();

    return (
        <PollInformation formData={formData} handlePrintDebug={handlePrintDebug} closeDrawer={closeDrawer}/>
    );
};

export default PollViewForm;
