import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Table, Thead, Tbody, Tr, Th, Td, Box, useToast, useDisclosure, Badge} from '@chakra-ui/react';
import NewConnectionEditForm from './NewConnectionEditForm';
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import { DeleteButton, EditButton, ViewButton } from "../../../../components/Styled/ActionButtons";
import { StyledTableContainer } from "../../../../components/Styled/StyledComponents";
import PaginationControls from "../../../../Context/PaginationControls/PaginationControls";
import {deleteConnection, fetchAllConnections} from "../NewConnectionAPI";
import ConfirmDialog from "../../../../Context/ConfirmDialog/ConfirmDialog";
import ApplicationRequestPdf from "../../../../components/PdfViewer/AppliactionRequestPdf";
import PdfModal from "../../../../components/PdfViewer/PdfModal";
import {fetchAllCities} from "../../../LocationNetwork/Areas/AreasAPI";
const moment = require('moment');
const ConnectionTable = ({ connections, setConnections, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag, filterOptions }) => {
    const { openDrawer } = useDrawer();
    const { isOpen,onOpen,onClose } = useDisclosure();
    const [error, setError] = useState('');
    const toast = useToast({ position: 'top-right' });
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });
    // New state for storing city names
    const [cityNames, setCityNames] = useState({});
    const toastRef = useRef(toast);
    const [pdfData, setPdfData] = useState([]);

    const statusColors = {
        Approved: 'green',
        Declined: 'red',
        Complete: 'blue',
        Review: 'yellow',
        Submitted: 'cyan',
    };

    const connectionTypeColors = {
        Portable: 'pink',
        Wastewater: 'purple'
    };

    const applicantTypeColors = {
        Tenant: 'orange',
        Owner: 'green'
    };

    const connectionStatusColors = {
        'Existing Connection': 'blue',
        'New Connection': 'teal'
    };



    // Function to fetch all city names
    const fetchCityNames = async () => {
        try {
            const cityResponse = await fetchAllCities();
            const cityNamesMap = cityResponse.cities.reduce((acc, city) => {
                acc[city._id] = city.name;
                return acc;
            }, {});
            setCityNames(cityNamesMap);
        } catch (error) {
            console.error('Failed to fetch city names:', error);
            // Handle error
        }
    };


    // Update function encapsulated in useCallback to ensure it's only created once
    const updateConnectionsAndPages = useCallback((newConnections, totalDocs) => {
        setConnections(newConnections);
        setTotalPages(Math.ceil(totalDocs / 10));
    }, [setConnections, setTotalPages]);

    const refetchConnectionData = useCallback(async (options={}) => {
        setError('');
        try {
            const connectionsResponse = await fetchAllConnections(pageNumber, 10, filterOptions);
            if (!connectionsResponse || !connectionsResponse.connections) {
                throw new Error('No data received from the server');
            }

            if (connectionsResponse && connectionsResponse.totalDocs) {
                updateConnectionsAndPages(connectionsResponse.connections, connectionsResponse.totalPages);
            } else {
                console.error("No totalPages found:", connectionsResponse);
                setConnections([]);
                setTotalPages(0);
            }

            updateConnectionsAndPages(connectionsResponse.connections, connectionsResponse.totalDocs);
            setConnections(connectionsResponse.connections);


        } catch (error) {
            console.error("Error fetching data:", error);
            setError(error.message || 'Failed to fetch data. Please try again later.');
            toastRef.current({
                title: "Error fetching data",
                description: error.message || "An unexpected error occurred.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    }, [filterOptions, pageNumber, setConnections, setTotalPages, updateConnectionsAndPages]);

    useEffect(() => {
       refetchConnectionData();
    }, [refetchConnectionData, pageNumber, filterOptions]);

    // Effect to fetch city names on component mount or when connections data changes
    useEffect(() => {
        fetchCityNames();
    }, [connections,refetchConnectionData]);


    if (error) return <Box textAlign="center" my="4">{error}</Box>;

    if (dataLoaded && Array.isArray(connections) && connections.length === 0) {
        return <Box textAlign="center" my="4">No records found. </Box>;
    }


    const handleEdit = async (connectionId) => {
        try {
            const response = await fetchAllConnections(1, 10, { id: connectionId });
            console.log("response from handleEdit", response);
            const connection = response.connections[0];
            if (connection) {
                openDrawer({
                    title: 'Edit Connection',
                    component: NewConnectionEditForm,
                    props: {
                        connection: connection,
                        connectionId: connectionId,
                        refetchConnectionData: refetchConnectionData,
                        setRefreshFlag: setRefreshFlag,
                    },
                    key: `edit-${connectionId}`,
                }, 'xl');
            } else {
                console.error("No connection found with the given ID.");
            }
        } catch (error) {
            console.error("Failed to fetch connection data:", error);
        }
    };




    const handleView= async (application) => {
        try {
            setPdfData(application)
            onOpen()
        } catch (e) {
            console.log(e)
        }

    };


    const handleDelete = (connectionId) => {
        setConfirmDialog({
            isOpen: true,
            title: "Confirm Delete",
            message: "Are you sure you want to delete this connection? This action cannot be undone.",
            onConfirm: () => confirmDelete(connectionId),
        });
    };

    const confirmDelete = async (connectionId) => {
        try {
           await deleteConnection(connectionId);
            toast({
                title: "Connection Deleted",
                description: "Successfully deleted connection.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ ...confirmDialog, isOpen: false });
           refetchConnectionData(filterOptions); // Refresh the list
        } catch (error) {
            console.log(error)
            toast({
                title: "Deletion Failed",
                description: "Could not delete.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ ...confirmDialog, isOpen: false });
        }
    };



    return (
        <>
            <StyledTableContainer>
                <PaginationControls pageNumber={pageNumber} setPageNumber={setPageNumber} totalPages={totalPages} />
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th>Name</Th>
                            <Th>Service Type</Th>
                            <Th>Applicant Type</Th>
                            <Th>Connection Type</Th>
                            <Th>Cell</Th>
                            <Th>Email</Th>
                            <Th>City</Th>
                            <Th>Status</Th>
                            <Th>Date</Th>
                            <Th>Action</Th>

                        </Tr>
                    </Thead>
                    <Tbody>
                        {connections && connections.map(conn => (
                            <Tr key={conn._id}>
                                <Td>{conn.personalDetails.firstName + " " + conn.personalDetails.lastName}</Td>
                                <Td>
                                    {conn.connectionType.map((type, index) => (
                                        <Badge key={index} colorScheme={connectionTypeColors[type]} mr={1} variant='outline'>
                                            {type}
                                        </Badge>
                                    ))}
                                </Td>
                                <Td>
                                    <Badge colorScheme={applicantTypeColors[conn.applicantType]} variant='subtle'>
                                        {conn.applicantType}
                                    </Badge>
                                </Td>
                                <Td>
                                    <Badge colorScheme={connectionStatusColors[conn.isExistingConnection ? 'Existing Connection' : 'New Connection']} variant='outline'>
                                        {conn.isExistingConnection ? 'Existing Connection' : 'New Connection'}
                                    </Badge>
                                </Td>
                                <Td>{conn.contactDetails.cell}</Td>
                                <Td>{conn.contactDetails.email}</Td>
                                <Td>{conn.serviceConnectionDetails.city}</Td>
                                <Td>
                                    <Badge colorScheme={statusColors[conn.status]} variant='solid'>
                                        {conn.status}
                                    </Badge>
                                </Td>
                                <Td>{moment(conn.dateOfApplication).format('DD-MM-YYYY')}</Td>
                                <Td>
                                    <ViewButton  onClick={()=>handleView(conn)}/>
                                    <EditButton onClick={() => handleEdit(conn._id)} />
                                    <DeleteButton onClick={() => handleDelete(conn._id)} />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>

                </Table>
            </StyledTableContainer>
            <PdfModal PdfTemplate={  <ApplicationRequestPdf pdfData={pdfData}/>} isOpen={isOpen} onClose={onClose} />
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                title={confirmDialog.title}
                message={confirmDialog.message}
                onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
                onConfirm={confirmDialog.onConfirm}
            />
        </>
    );
};

export default ConnectionTable;
