import React from 'react';
import {
    VStack, Box, Text, Badge, Stack, Flex, SimpleGrid, Heading, useColorModeValue
} from '@chakra-ui/react';
import FormActionLabel from "../../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../../components/Styled/StyledButtons";
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import { Icon } from "@iconify/react";

const DetailSection = ({ icon, title, children, style = {} }) => (
    <Box>
        <Flex align="center" px={2} py={3} bg="blue.50" {...style} mt={8} borderRadius="0.375em" mb={4}>
            <Icon icon={icon} width="20" height="24" style={{ marginRight: '10px', color: '#63b3ed', marginLeft: '10px' }} />
            <Heading size="sm" fontWeight="bold" color="blue.800">{title}</Heading>
        </Flex>
        {children}
    </Box>
);

const ConsultantViewForm = ({ formData }) => {
    const { closeDrawer } = useDrawer();
    const { firstName, lastName, email, area, status } = formData;

    // Optionally, use color mode value if your application supports dark mode
    const statusColor = useColorModeValue(status === 'active' ? 'green' : 'red', status === 'active' ? 'green' : 'red');

    return (
        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
            <FormActionLabel formAction="view" formName="Consultant"/>
            <Stack spacing={4} px={2} py={4} mt={4}>
                <DetailSection title="Consultant Information" icon="fa-solid:user-tie">
                    <SimpleGrid columns={{ base: 1, sm: 1 }} gap={4} px={4}>
                        <Text><strong>Name:</strong> {`${firstName} ${lastName}`}</Text>
                        <Text><strong>Email:</strong> {email}</Text>
                        <Text><strong>Area:</strong> {area}</Text>
                        <Text>
                            <strong>Status:</strong>
                            <Badge ml={2} colorScheme={statusColor}>
                                {status.charAt(0).toUpperCase() + status.slice(1)}
                            </Badge>
                        </Text>
                    </SimpleGrid>
                </DetailSection>

                <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}  mt={8} mb={8}>
                    <CustomAlert status="warning" message="View Only Mode" />
                    <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                        <CustomButton type="cancel" onClick={closeDrawer} showIcon={false}>
                            Close
                        </CustomButton>
                    </ButtonStack>
                </Box>
            </Stack>
        </VStack>
    );
};

export default ConsultantViewForm;
