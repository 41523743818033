import React, { useCallback, useEffect, useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    useDisclosure,
    Modal,
    ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter,
    Flex, Box, Text, List, ListItem,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useToast,
    Button, Badge, SimpleGrid, Heading
} from '@chakra-ui/react';
import { useDrawer } from '../../../Context/DrawerContext/DrawerContext'; // Ensure this path is correct
import {Link} from "react-router-dom";
import {
    ViewButton,
    EditButton,
    RevisionButton,
    DeleteButton,
    PublishButton
} from '../../../components/Styled/ActionButtons'; // Ensure paths are correct
import pageService from '../../../services/pageService'; // Adjust the import path as necessary
import EditPageForm from './EditPageForm'; // Ensure this path is correct
import ViewPageForm from './ViewPageForm'; // Ensure this path is correct
import { PropagateLoader } from "react-spinners";
import {TableContainer} from "../../../components/Styled/StyledComponents";
import PdfViewerModal from "../../../components/PdfViewer/PdfViewerModal";

const NestedPageRow = ({ page, pages, handleView, handleRevisionModalOpen, handleEdit,handlePublish,  handleDeleteModalOpen, level = 0, numbering = '1' }) => {
    // Find children pages
    const childPages = pages.filter(childPage => childPage.parentPage?._id === page._id);

    return (
        <>
            <Tr key={page._id}>
                <Td pl={level * 4}>{`${numbering} ${page.pageName}`}</Td>
                <Td><Badge colorScheme={page.published?"green":'red'}>{page.published?"Published":'Unpublished'}</Badge></Td>
                <Td>{page.category}</Td>
                <Td> <Badge colorScheme={page.isForm?"blue":"orange"}>{!page.isForm?"Content":"Document"}</Badge> </Td>
                <Td isNumeric>
                    <ViewButton onClick={() => handleView(page)} />
                    <RevisionButton onClick={() => handleRevisionModalOpen(page)} />
                    <EditButton onClick={() => handleEdit(page)} />
                    {!page?.published&&
                        <PublishButton onClick={() => handlePublish(page)} />
                    }
                    {!page?.isParent&&
                        <DeleteButton onClick={() => handleDeleteModalOpen(page)} />
                    }

                </Td>
            </Tr>
            {childPages.map((childPage, index) => (
                <NestedPageRow
                    key={childPage._id}
                    page={childPage}
                    pages={pages}
                    handleView={handleView}
                    handleRevisionModalOpen={handleRevisionModalOpen}
                    handleEdit={handleEdit}
                    handlePublish={handlePublish}
                    handleDeleteModalOpen={handleDeleteModalOpen}
                    level={level + 1}
                    numbering={`${numbering}.${index + 1}`}
                />
            ))}
        </>
    );
};

const PageTable = ({ pages, refetchPages }) => {
    const { openDrawer } = useDrawer();
    const { isOpen: isRevisionModalOpen, onOpen: onRevisionModalOpen, onClose: onRevisionModalClose } = useDisclosure();
    const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteModalClose } = useDisclosure();
    const [selectedPage, setSelectedPage] = useState(null);
    const [activeRevision, setActiveRevision] = useState(null);
    const [loading, setLoading] = useState(false);
    const toast = useToast({ position: 'top-right' });
    const [showLoader, setShowLoader] = useState(false);
    const { isOpen,onOpen,onClose } = useDisclosure();
    const [filePath, setFilePath] = useState("");
    const handleViewPdf= async (link) => {
        try {
            setFilePath(link)
            onOpen()
        } catch (e) {
            console.log(e)
        }

    };
    useEffect(() => {
        let timer;
        if (loading) {
            timer = setTimeout(() => {
                setShowLoader(true);
            }, 200);
        } else {
            setShowLoader(false);
        }

        return () => clearTimeout(timer);
    }, [loading]);

    const handleDeleteModalOpen = (page) => {
        setSelectedPage(page);
        onDeleteModalOpen();
    };

    const handleRevisionModalOpen = (page) => {
        if(page){
            const activeRevision = page.revisions.find(revision => revision.active);
            setSelectedPage(page);
            setActiveRevision(activeRevision);
            // Initially set to the active revision for preview
            onRevisionModalOpen();
        }

    };

    const handleRevisionSelect = (revision) => {
        setActiveRevision(revision);
    };

    const handleSetAsActive = async () => {
        if (activeRevision) {
            try {
                await pageService.setActiveRevision(selectedPage._id, activeRevision._id);
                toast.closeAll()
                toast({
                    title: "Revision activated",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                refetchPages();
                onRevisionModalClose();
            } catch (error) {
                toast.closeAll()
                toast({
                    title: "Could not activate revision",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                console.error('Error setting active revision:', error);
            }
        }
    };

    const handleEdit = (page) => {
        openDrawer({
            title: 'Edit Page',
            component: EditPageForm,
            props: { pageId: page._id, refetchPages }
        });
    };

    const handleView = (page) => {
        openDrawer({
            title: 'View Page',
            component: ViewPageForm,
            props: { pageId: page._id }
        });
    };

    const handleDelete = async () => {
        try {
            setLoading(true);
            await pageService.deletePage(selectedPage._id);
            toast({
                title: 'Page deleted successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchPages();
            onDeleteModalClose();
        } catch (error) {
            toast({
                title: error?.response?.data?.message || error,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            console.error('Error deleting page:', error);
        } finally {
            setLoading(false);
        }
    };
    const handlePublish = async (page) => {
        try {
            setLoading(true);
            await pageService.publishPage(page._id);
            refetchPages();
        } catch (error) {
            toast({
                title: error?.response?.data?.message || "Error Publishing page",
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            console.error('Error Publishing page:', error);
        } finally {
            setLoading(false);
        }
    };
    const cancelRef = React.useRef();

    // Filter root pages
    const rootPages = pages?.filter(page => page.parentPage === null);

    return (
        <TableContainer>
            <Table variant="striped" >
                <Thead>
                    <Tr>
                        <Th>Page Name</Th>
                        <Th>Published</Th>
                        <Th>Category</Th>
                        <Th>Type</Th>
                        <Th isNumeric>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {rootPages?.map((page, index) => (
                        <NestedPageRow
                            key={page._id}
                            page={page}
                            pages={pages}
                            handleView={handleView}
                            handleRevisionModalOpen={handleRevisionModalOpen}
                            handleEdit={handleEdit}
                            handlePublish={handlePublish}
                            handleDeleteModalOpen={handleDeleteModalOpen}
                            numbering={`${index + 1}`}
                        />
                    ))}
                </Tbody>
            </Table>

            {/* Revision Management Modal */}
            {selectedPage && (
                <Modal isOpen={isRevisionModalOpen} onClose={onRevisionModalClose} size="2xl">
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Revisions of {selectedPage.pageName}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Flex>
                                <Box width="30%">
                                    <List spacing={3} gap={3} style={{ overflowY: 'auto', maxHeight: '300px' }}>
                                        {selectedPage?.revisions &&
                                            selectedPage.revisions
                                                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort revisions by latest date
                                                .map((revision, index) => (
                                                    <ListItem bg={activeRevision?._id===revision?._id?'gray.50':'white'} _hover={{ bg: 'gray.50' }} p={2} mx={2}  _active={{ bg: 'gray.200' }} borderRadius={'2xl'} key={revision?._id} onClick={() => handleRevisionSelect(revision)} cursor="pointer">
                                                        <Text fontWeight="bold">Version {index + 1}</Text>
                                                        <Text fontSize="sm">{new Date(revision?.createdAt).toLocaleDateString()}</Text>
                                                        <Badge colorScheme={revision?.active ? 'green' : "red"}>{revision?.active ? 'Active' : "Inactive"}</Badge>
                                                    </ListItem>
                                                ))
                                        }

                                    </List>
                                </Box>
                                <Box  width="70%" p={4} bg="gray.100" borderRadius="md" overflowY="auto">
                                    {activeRevision && (
                                        <>
                                            {activeRevision?.description && (
                                                <Box className="ql-editor">
                                                    <Text mb={4} dangerouslySetInnerHTML={{ __html: activeRevision?.description }} />
                                                </Box>
                                            )}
                                            {activeRevision.isForm && (
                                                <SimpleGrid columns={{base:1,md:2}}>
                                                    {activeRevision.url.map((link)=>(
                                                        <Flex direction={'column'} bg={"white"} borderRadius={'2xl'} p={4} boxShadow={'md'}>
                                                            <Text size={'sm'} fontWeight={'semibold'}>{link.title}</Text>
                                                            <Flex justify={'center'} fontWeight={'semibold'} bg={'gray.200'} borderRadius={'md'} p={2} mt={4} >
                                                                <Box cursor={'pointer'} textAlign={'center'} w={'full'} onClick={()=>handleViewPdf(link.link)}>Download</Box>
                                                            </Flex>
                                                        </Flex>
                                                    ))}

                                                </SimpleGrid>
                                            )}
                                        </>


                                    )}
                                </Box>
                            </Flex>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={handleSetAsActive}>
                                Set as Active
                            </Button>
                            <Button onClick={onRevisionModalClose}>Close</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            )}

            <AlertDialog
                isOpen={isDeleteModalOpen}
                leastDestructiveRef={cancelRef}
                onClose={onDeleteModalClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Delete Page
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onDeleteModalClose}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={handleDelete} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <PdfViewerModal filePath={filePath} isOpen={isOpen} onOpen={onOpen} onClose={onClose}/>

        </TableContainer>
    );
};

export default PageTable;
