import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  GridItem,
  Image,
  Text,
  Button,
  VStack
} from '@chakra-ui/react';

const PhotoMenuGrid = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <Box p={5}>
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        <GridItem w="100%">
          <VStack spacing={4} align="stretch" p={5} borderWidth="1px" borderRadius="lg" overflow="hidden">
            <Image src="https://via.placeholder.com/150" alt="Album Management" />
            <Text fontSize="xl">Album Management</Text>
            <Text>Manage and organize your albums. Add, edit, and delete albums, and manage their contents.</Text>
            <Button
              colorScheme="teal"
              onClick={() => handleNavigation('/manage-albums')}
            >
              Manage Albums
            </Button>
          </VStack>
        </GridItem>
        <GridItem w="100%">
          <VStack spacing={4} align="stretch" p={5} borderWidth="1px" borderRadius="lg" overflow="hidden">
            <Image src="https://via.placeholder.com/150" alt="Photo Management" />
            <Text fontSize="xl">Photo Management</Text>
            <Text>Manage and organize photos within albums. Perform operations such as create, read, update, and delete on photos.</Text>
            <Button
              colorScheme="pink"
              onClick={() => handleNavigation('/manage-photos')}
            >
              Manage Photos
            </Button>
          </VStack>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default PhotoMenuGrid;
