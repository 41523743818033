import { API1 } from "../../../utils/api";

// Fetch All Areas
export const fetchAllShortageReports = async (pageNumber = 1, pageSize = 10, filterOptions = {}) => {
    try {
        const queryParams = new URLSearchParams({
            ...filterOptions,
            pageNumber,
            pageSize
        }).toString();

        const response = await API1.get(`/shortages/?${queryParams}`);
        console.log("RESPONSE:",response)
        if (response.status === 200) {
            const { docs: shortageReports, totalDocs } = response.data;
            return {
                shortageReports,
                totalPages: Math.ceil(totalDocs / pageSize),
            };
        } else {
            throw new Error('Unexpected response from the server while fetching shortages.');
        }
    } catch (error) {
        console.error("Error fetching shortages:", error);
        throw new Error('Failed to fetch shortages. Please try again.');
    }
};

export const exportShortageReports = async () => {
    try {

        const response = await API1.get(`/shortages/export`);

        if (response.status === 200) {
            const shortages = response.data;
            console.log("shortages:",shortages)
            return shortages;
        } else {
            throw new Error('Unexpected response from the server while fetching shortages.');
        }
    } catch (error) {
        console.error("Error fetching shortages:", error);
        throw new Error('Failed to fetch shortages. Please try again.');
    }
};

export const deleteShortageReport = async (areaId) => {
    try {
        const response = await API1.delete(`/shortages/${areaId}`);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "Failed to delete shortage report");
    }
};




