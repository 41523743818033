import React, { useEffect, useState } from 'react';
import {
    VStack, Box, Text, Stack, Flex, SimpleGrid, Heading
} from '@chakra-ui/react';
import FormActionLabel from "../../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../../components/Styled/StyledButtons";
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import {Icon} from "@iconify/react";
import { fetchCityById, fetchManagementCenterById } from "../CitiesAPI";

const DetailSection = ({ icon, title, children, style = {} }) => (
    <Box>
        <Flex align="center" px={2} py={3} bg="blue.50" {...style} mt={8} borderRadius="0.375em" mb={4}>
            <Icon icon={icon} width="20" height="24" marginRight='10px' style={{ color: '#63b3ed', marginLeft: '10px' }} />
            <Heading size="sm" fontWeight="bold" color="blue.800">{title}</Heading>
        </Flex>
        {children}
    </Box>
);

const CityViewForm = ({ formData }) => {
    const { closeDrawer } = useDrawer();
    const [cityData, setCityData] = useState({ name: '', managementCenterName: '' });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const city = await fetchCityById(formData._id);
                const managementCenter = await fetchManagementCenterById(formData.managementCenterId);
                setCityData({
                    ...city,
                    managementCenterName: managementCenter ? managementCenter.name : 'N/A',
                });
            } catch (error) {
                console.log('Error fetching city details:', error);
            }
        };

        fetchData();
    }, [formData]);

    return (
        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
            <FormActionLabel formAction="view" formName="City"/>
            <Stack spacing={4} px={2} py={4} mt={4}>
                <DetailSection title="City Information" icon="healthicons:city">
                    <SimpleGrid columns={{ base: 1, sm: 1 }} gap={4} px={4}>
                        <Text><strong>City Name:</strong> {cityData.name}</Text>
                        <Text><strong>Management Center:</strong> {cityData.managementCenterName}</Text>
                    </SimpleGrid>
                </DetailSection>

                <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}  mt={8} mb={8}>
                    <CustomAlert status="warning" message="View Only Mode" />
                    <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                        <CustomButton type="cancel" onClick={closeDrawer} showIcon={false}>
                            Close
                        </CustomButton>
                    </ButtonStack>
                </Box>
            </Stack>
        </VStack>
    );
};

export default CityViewForm;