import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import DefaultTemplate from '../../../../components/DefaultTemplate/DefaultTemplate'; // Adjust path as necessary
import AlbumContainer from './Components/AlbumContainer'; // Adjust path as necessary
import { Icon } from "@iconify/react";

const AlbumPage = () => {
    return (
        <DefaultTemplate
            Content={<AlbumContainer />}
            Title={<Heading as="h2" size="lg" width="full" my={4}><Icon icon="bx:album" /> Manage Albums</Heading>}
        />
    );
};

export default AlbumPage;
