// fileService.js
import {API1} from '../utils/api'; // Make sure to import your API1 instance

const fileService = {
  // Upload a file to the server
  uploadFile: async (file, userId, docType) => {
    const formData = new FormData();
    console.log('UPLOADING', file, userId, docType);
    formData.append('file', file);
    formData.append('folderPath', `${docType}/${userId}`);

    const response = await API1.post(`/common/file/upload`, formData);
    console.log(response);
    if (!response.data) {
      throw new Error(`Error uploading file: ${response.statusText}`);
    }
    return response.data;
  },

  // Save a file record in the database
  saveFile: async (fileData) => {
    const response = await API1.post(`/documents`, fileData);
    if (!response.data) {
      throw new Error(`Error saving file record: ${response.statusText}`);
    }
    return response.data;
  },

  // Retrieve all files from the database
  getAllFiles: async () => {
    const response = await API1.get(`/documents`);
    if (!response.data) {
      throw new Error(`Error fetching files: ${response.statusText}`);
    }
    return response.data;
  },

  // Update a file's details in the database
  updateFile: async (id, fileData) => {
    const response = await API1.put(`/documents/${id}`, fileData);
    if (!response.data) {
      throw new Error(`Error updating file: ${response.statusText}`);
    }
    return response.data;
  },

  // Perform a soft delete on a file
  softDeleteFile: async (id) => {
    const response = await API1.put(`/documents/${id}`, { status: 'Deleted' });
    if (!response.data) {
      throw new Error(`Error deleting file: ${response.statusText}`);
    }
    return response.data;
  },

  // Get a single file by its ID
  getFileById: async (id) => {
    const response = await API1.get(`/documents/${id}`);
    if (!response.data) {
      throw new Error(`Error fetching file: ${response.statusText}`);
    }
    return response.data;
  },

  // Save a document category
  saveDocumentCategory: async (name) => {
    const response = await API1.post(`/documentCategories`, { name });
    if (!response.data) {
      throw new Error(`Error saving category: ${response.statusText}`);
    }
    return response.data;
  },

  // Retrieve all document categories
  getAllDocumentCategory: async () => {
    const response = await API1.get(`/documentCategories`);
    if (!response.data) {
      throw new Error(`Error fetching category: ${response.statusText}`);
    }
    return response.data;
  },

  // Update a document category's details
  updateDocumentCategory: async (id, fileData) => {
    const response = await API1.put(`/documentCategories/${id}`, fileData);
    if (!response.data) {
      throw new Error(`Error updating category: ${response.statusText}`);
    }
    return response.data;
  },

  // Delete a document category
  deleteDocumentCategory: async (id) => {
    const response = await API1.delete(`/documentCategories/${id}`, { status: 'Deleted' });
    if (!response.data) {
      throw new Error(`Error deleting category: ${response.statusText}`);
    }
    return response.data;
  },

  // Get a single document category by its ID
  getCategoryById: async (id) => {
    const response = await API1.get(`/documentCategories/${id}`);
    if (!response.data) {
      throw new Error(`Error fetching category: ${response.statusText}`);
    }
    return response.data;
  },
};

export default fileService;
