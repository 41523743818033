import React from 'react';
import {Box, FormControl, FormLabel, Input, Image, VStack, useToast, Flex} from '@chakra-ui/react';
import {  CustomButton } from "../../../../../components/Styled/StyledButtons";
import { useDrawer } from '../../../../../Context/DrawerContext/DrawerContext'; 

const PhotoViewForm = ({ photo }) => {
    const { closeDrawer } = useDrawer();
    const toast = useToast();

    if (!photo) {
        toast({
            title: 'Error',
            description: "Photo data is not available.",
            status: 'error',
            duration: 5000,
            isClosable: true
        });
        closeDrawer();
    }

    return (



                <Flex h={'full'} w={'full'} justify={'center'} align={'center'}>
                    {photo.url ? (
                        <Image src={photo.url} alt="Photo" boxSize="full" objectFit="contain" />
                    ) : (
                        <p>No image to display</p>
                    )}
                </Flex>



    );
};

export default PhotoViewForm;
