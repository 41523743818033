import React, {useEffect, useRef, useState} from 'react';
import { Formik, Form, Field } from 'formik';
import { FormControl, Select, Stack } from '@chakra-ui/react';
import { ThemedStyledInput } from '../../../../components/Styled';
import * as Yup from 'yup';
import { CustomButton } from "../../../../components/Styled/StyledButtons";
import { Icon } from "@iconify/react";
import FieldErrorMessage from "../../../../components/FieldErrorMessage/FieldErrorMessage";
import {fetchAllAreas} from "../../Consultants/ConsultantsAPI";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";

const validationSchema = Yup.object({
    name: Yup.string()
        .max(30, 'Search & Filters Error: Name Must be 30 characters or less')
        .trim('Search & Filters Error: Name cannot be just whitespace')
        .strict(true)
});

const BranchSearchFilter = ({ onFilterChange }) => {
    const showToast = useSingleToast();
    const showToastRef = useRef(showToast);
    const [areas, setAreas] = useState([]);

    useEffect(() => {
        fetchAllAreas().then(data => {
            setAreas(data.areas);
        }).catch(error => {
            showToastRef.current({
                title: "Error",
                description: "Failed to fetch areas.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        });
    }, []);

    return (
        <Formik
            initialValues={{ name: '', areaId: '' }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                if (!values.name && !values.areaId) {
                    showToast({
                        title: "No Parameters Supplied",
                        description: "Please enter search criteria.",
                        status: "warning",
                        duration: 5000,
                        isClosable: true,
                    });
                } else {
                    onFilterChange({ ...values });
                }
                setSubmitting(false);
            }}
            onReset={() => {
                onFilterChange({ name: '', areaId: '' });
            }}
        >
            {({ handleSubmit, resetForm, setFieldValue }) => (
                <Form>
                    <Stack spacing={2} direction="row" align="center">
                        <FormControl>
                            <Field as={ThemedStyledInput} id="name" name="name" type="text" placeholder="Search by Branch Name" />
                        </FormControl>
                        <FormControl>
                            <Field as={Select} name="areaId" placeholder=" -- Select Area -- ">
                                {areas.map(mc => (
                                    <option key={mc._id} value={mc._id}>{mc.name}</option>
                                ))}
                            </Field>
                        </FormControl>
                        <CustomButton
                            onClick={handleSubmit}
                            type="search"
                            tooltipLabel="Search"
                            icon={<Icon icon="bi:search" />}
                            showLabel={false}
                        >
                            Search
                        </CustomButton>
                        <CustomButton
                            type="reset"
                            onClick={() => {
                                resetForm();
                                setFieldValue('areaId', '', false);
                                onFilterChange({ name: '', areaId: '' });
                            }}
                            tooltipLabel="Clear Filters"
                            icon={<Icon icon="radix-icons:reset" />}
                            showLabel={false}
                        >
                            Clear
                        </CustomButton>
                    </Stack>
                    <FieldErrorMessage name="name" />
                    <FieldErrorMessage name="areaId" />
                </Form>
            )}
        </Formik>
    );
};



export default BranchSearchFilter;
