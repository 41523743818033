import React from 'react';
import {Formik, Form, Field } from 'formik';
import { FormControl, Stack } from '@chakra-ui/react';
import { ThemedStyledInput } from '../../../../components/Styled';
import * as Yup from 'yup';
import { CustomButton } from "../../../../components/Styled/StyledButtons";
import { Icon } from "@iconify/react";
import FieldErrorMessage from "../../../../components/FieldErrorMessage/FieldErrorMessage";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";

const validationSchema = Yup.object({
    email: Yup.string()
        .email('Search & Filters Error: Invalid email address, please input a valid email address')
        .max(50, 'Search & Filters Error: Email Must be 50 characters or less')
        .trim('Search & Filters Error: Email cannot be just whitespace')
        .strict(true),
    name: Yup.string()
        .max(30, 'Search & Filters Error: Name Must be 30 characters or less')
        .trim('Search & Filters Error: Name cannot be just whitespace')
        .strict(true)
});

const ManagementCenterSearchFilter = ({ onFilterChange }) => {
    const showToast = useSingleToast();
    return (
        <Formik
            initialValues={{ email: '', name: '' }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                onFilterChange(values);
                setSubmitting(false);
            }}
            onReset={() => {
                onFilterChange({ email: '', name: '' });
            }}
        >
            {({ values, resetForm, handleSubmit }) => (
                <Form>
                    <Stack spacing={2} direction="row" align="center">
                        <FormControl>
                            <Field
                                as={ThemedStyledInput}
                                id="name"
                                name="name"
                                type="text"
                                placeholder="Search by Management Center Name"
                            />
                        </FormControl>
                        <FormControl>
                            <Field
                                as={ThemedStyledInput}
                                id="email"
                                name="email"
                                type="email"
                                placeholder="Search by Email"
                            />
                        </FormControl>
                        <CustomButton
                            onClick={handleSubmit}
                            type="search"
                            tooltipLabel="Search"
                            icon={<Icon icon="bi:search" />}
                            showLabel={false}
                        >
                            Search
                        </CustomButton>
                        <CustomButton
                            type="reset"
                            onClick={() => {
                                resetForm();
                                onFilterChange({ email: '', name: '' });
                            }}
                            tooltipLabel="Clear Filters"
                            icon={<Icon icon="radix-icons:reset" />}
                            showLabel={false}
                        >
                            Clear
                        </CustomButton>
                    </Stack>
                    <FieldErrorMessage name="email" />
                    <FieldErrorMessage name="name" />

                </Form>
            )}
        </Formik>
    );
};

export default ManagementCenterSearchFilter;
