import React from 'react';
import { Box, Link, Stack, Text } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const Sidebar = () => {
    return (
        <Box width="20%" p="5" bg="gray.100" height="100vh">
            <Text fontSize="lg" fontWeight="bold" mb="4">Navigation</Text>
            <Stack spacing={4}>
                <Link as={RouterLink} to="/dashboard" style={{ textDecoration: 'none' }}>
                    <Text p="2" _hover={{ background: "gray.200", borderRadius: "md" }}>
                        Dashboard
                    </Text>
                </Link>
                <Link as={RouterLink} to="/admin-users" style={{ textDecoration: 'none' }}>
                    <Text p="2" _hover={{ background: "gray.200", borderRadius: "md" }}>
                        Admin Users
                    </Text>
                </Link>
                <Link as={RouterLink} to="/customers" style={{ textDecoration: 'none' }}>
                    <Text p="2" _hover={{ background: "gray.200", borderRadius: "md" }}>
                        Customers
                    </Text>
                </Link>
                {/* More links can be added here manually */}
            </Stack>
        </Box>
    );
};

export default Sidebar;
