import React, {useEffect, useRef} from 'react';
import {
    VStack, Box, Text, Badge, SimpleGrid, Stack, IconButton, Tooltip,
} from '@chakra-ui/react';
import FormActionLabel from "../../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../../components/Styled/StyledButtons";
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import {Icon} from "@iconify/react";
import { useReactToPrint } from 'react-to-print';
import PrintableComponent from "../../../../components/PrintableComponent/PrintableComponent";
import DetailSection from "../../../../components/DetailSection/DetailSection";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";
import FileDisplay from "../../../../components/FileDisplay";


const CustomerViewForm = ({ formData, profileData }) => {
    const showToast = useSingleToast();
    const showToastRef = useRef(false);
    const printComponentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current,
        onBeforeGetContent: () => console.log("Preparing document..."),
        onAfterPrint: () => console.log("Printing process done."),
    });

    const handlePrintDebug = () => {
        console.log("Attempting to print...");
        handlePrint();
    };


    const getStatusColor = (status) => {
        switch (status) {
            case 'Approved':
                return 'green';
            case 'Declined':
                return 'red';
            case 'Submitted':
                return 'blue';
            case 'Review':
                return 'yellow';
            default:
                return 'gray';
        }
    };


    useEffect(() => {
        if ((!profileData || Object.keys(profileData).length === 0 || !profileData.personalDetails) && !showToastRef.current) {
            showToast({
                title: 'KYC Information',
                description: "User is yet to update his KYC.",
                status: 'warning',
                duration: 5000,
                isClosable: true,
            });
            showToastRef.current = true;
        }
    }, [profileData, showToast]);

    const { closeDrawer } = useDrawer();
    const { firstName, lastName, phone, email, isVerified } = formData;


    const {
        customerNumber = '',
        personalDetails = {},
        nextOfKin = {},
        address = {},
        contactDetails = {},
        files = {},
        consent = false,
        dateOfConsent = '',
        status = '',
    } = profileData || {};


    const formattedDateOfConsent = dateOfConsent
        ? new Date(dateOfConsent).toLocaleDateString()
        : '';

    const printData = {
        'Customer Information': {
            'Customer Number': profileData.customerNumber || '',
            'Customer Name': `${formData.firstName || ''} ${formData.lastName || ''}`,
            'Phone Number': formData.phone || '',
            'Email': formData.email || '',
            'Verified': formData.isVerified ? 'Verified' : 'Not Verified',
        },
        'Personal Details': {
            'Full Name': `${profileData.personalDetails?.firstName || ''} ${profileData.personalDetails?.middleName || ''} ${profileData.personalDetails?.lastName || ''}`,
            'Identity Number': profileData.personalDetails?.identityNo || '',
            'ID Type': profileData.personalDetails?.identityType || '',
            'Marital Status': profileData.personalDetails?.maritalStatus || '',
            'Marital Type': profileData.personalDetails?.maritalType || '',
            'Gender': profileData.personalDetails?.sex || '',
            'Date of Birth': profileData.personalDetails?.dateOfBirth || '',
        },
        'Next Of Kin': {
            'Name': `${profileData.nextOfKin?.firstName || ''} ${profileData.nextOfKin?.lastName || ''}`,
            'Relationship': profileData.nextOfKin?.relationship || '',
            'Cell': profileData.nextOfKin?.cell || '',
        },
        'Address': {
            'Plot No': profileData.address?.plotNo || '',
            'Postal': profileData.address?.postal || '',
            'Street': profileData.address?.street || '',
            'Ward': profileData.address?.ward || '',
            'City': profileData.address?.city || '',
            'District': profileData.address?.district || '',
        },
        'Contact Details': {
            'Cell': profileData.contactDetails?.cell || '',
            'Home Tel': profileData.contactDetails?.homeTel || '',
            'Work Tel': profileData.contactDetails?.workTel || '',
            'Email': profileData.contactDetails?.email || '',
        },
        'Documents': {
            'ID Document': profileData.files?.idKey || '',
            'Marital Proof': profileData.files?.maritalProofKey || '',
        },
        'Consent Details': {
            'Consent Given': profileData.consent ? 'Yes' : 'No',
            'Date of Consent': profileData.dateOfConsent ? new Date(profileData.dateOfConsent).toLocaleDateString() : '',
        },
        'Status': {
            'KYC Status': profileData.status  || '',
        }
    };

    const Attachments = () => {
        //let files = profileData.files;
        return (
            <>
                { files?.idKey&&<FileDisplay filePath={files?.idKey} label={"Identity Documents"}/>}
                {files?.maritalProofKey&&<FileDisplay filePath={files?.maritalProofKey} label={"Marital Proof Documents"}/>}
            </>

        );
    };


    return (
        <>
            <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                <FormActionLabel formAction="view" formName="Customer's KYC Profile"/>
                <Stack spacing={4} px={2} py={4} mt={4}>

                    <DetailSection title="User Information" icon="mdi:account-tag">
                        <SimpleGrid columns={{base: 1, sm: 2}} gap={4} px={4}>
                            <Text><strong>Customer Number:</strong> {customerNumber}</Text>
                            <Text><strong>Name:</strong> {`${firstName} ${lastName}`}</Text>
                            <Text><strong>Email:</strong> {email}</Text>
                            <Text><strong>Phone:</strong> {phone}</Text>
                            <Box>
                                <Text>
                                    <strong>Verified:</strong>
                                    <Badge colorScheme={isVerified ? 'green' : 'red'} ml={2} variant="solid">
                                        {isVerified ? 'Verified' : 'Not Verified'}
                                    </Badge>
                                </Text>
                            </Box>
                        </SimpleGrid>
                    </DetailSection>

                    {/* KYC Details Sections */}
                    {profileData ? (
                        <>
                            <DetailSection title="KYC Personal Details" icon="bxs:user-detail">
                                <SimpleGrid columns={{base: 1, sm: 2}} gap={4} px={4}>
                                    <Text><strong>Full
                                        Name:</strong> {`${personalDetails.firstName || ''} ${personalDetails.middleName || ''} ${personalDetails.lastName || ''}`}
                                    </Text>
                                    <Text><strong>Identity Number:</strong> {personalDetails.identityNo || ''}</Text>
                                    <Text><strong>ID Type:</strong> {personalDetails.identityType || ''}</Text>
                                    <Text><strong>Marital Status:</strong> {personalDetails.maritalStatus || ''}
                                    </Text>
                                    <Text><strong>Marital Type:</strong> {personalDetails.maritalType || ''}</Text>
                                    <Text><strong>Sex:</strong> {personalDetails.sex || ''}</Text>
                                    <Text><strong>Date of Birth:</strong> {personalDetails.dateOfBirth || ''}</Text>
                                </SimpleGrid>
                            </DetailSection>

                            <DetailSection title="KYC Next Of Kin" icon="material-symbols:supervisor-account">
                                <SimpleGrid columns={{base: 1, sm: 2}} gap={4} px={4}>
                                    <Text><strong>Name:</strong> {`${nextOfKin.firstName || ''} ${nextOfKin.lastName || ''}`}
                                    </Text>
                                    <Text><strong>Relationship:</strong> {nextOfKin.relationship || ''}</Text>
                                    <Text><strong>Cell:</strong> {nextOfKin.cell || ''}</Text>

                                </SimpleGrid>
                            </DetailSection>

                            <DetailSection title="KYC Address" icon="basil:map-location-solid">
                                <SimpleGrid columns={{base: 1, sm: 2}} gap={4} px={4}>
                                    <Text><strong>Plot No:</strong> {address.plotNo || ''}</Text>
                                    <Text><strong>Postal:</strong> {address.postal || ''}</Text>
                                    <Text><strong>Street:</strong> {address.street || ''}</Text>
                                    <Text><strong>Ward:</strong> {address.ward || ''}</Text>
                                    <Text><strong>City:</strong> {address.city || ''}</Text>
                                    <Text><strong>District:</strong> {address.district || ''}</Text>
                                </SimpleGrid>
                            </DetailSection>

                            <DetailSection title="KYC Contact Details" icon="mage:phone-call-fill">
                                <SimpleGrid columns={{base: 1, sm: 2}} gap={4} px={4}>
                                    <Text><strong>Cell:</strong> {contactDetails.cell || ''}</Text>
                                    <Text><strong>Home Tel:</strong> {contactDetails.homeTel || ''}</Text>
                                    <Text><strong>Work Tel:</strong> {contactDetails.workTel || ''}</Text>
                                    <Text><strong>Email:</strong> {contactDetails.email || ''}</Text>
                                </SimpleGrid>
                            </DetailSection>

                            <DetailSection title="KYC Files" icon="fluent:document-one-page-link-20-filled">
                                <SimpleGrid columns={{base: 1, sm: 2}} gap={4} px={4}>
                                    {/*<Text><strong>ID Document:</strong> {files.idKey || ''}</Text>
                                    <Text><strong>Marital Proof:</strong> {files.maritalProofKey || ''}</Text>*/}
                                    <Attachments/>
                                </SimpleGrid>
                            </DetailSection>

                            <DetailSection title="KYC Consent" icon="mdi:user-settings">
                                <SimpleGrid columns={{base: 1, sm: 2}} gap={4} px={4}>
                                    <Text><strong>Consent Given:</strong> {consent ? 'Yes' : 'No'}</Text>
                                    <Text><strong>Date of Consent:</strong> {formattedDateOfConsent}</Text>

                                </SimpleGrid>
                            </DetailSection>

                            <DetailSection title="KYC Profile Status" icon="material-symbols-light:order-approve-rounded">
                                <Badge ml="4" colorScheme={getStatusColor(status)}>
                                    {status || 'Not Set'}
                                </Badge>
                            </DetailSection>

                        </>
                    ) : (
                        <Box flexDirection="column" alignItems="left" style={{alignItems: 'flex-start'}}>
                            <CustomAlert status="warning" message="The user has not completed their KYC details."/>
                        </Box>
                    )}

                    <Box flexDirection="column" alignItems="left" style={{alignItems: 'flex-start'}} mt={8} mb={8}>
                        <ButtonStack direction="row" style={{justifyContent: 'flex-start'}}>
                            {/*<CustomAlert status="warning" message="--End of Information--"/>*/}
                            <CustomButton type="cancel" onClick={closeDrawer} showIcon={false}>
                                Close
                            </CustomButton>
                            <Tooltip label="Print KYC Details">
                                <IconButton
                                    aria-label="Print"
                                    icon={<Icon icon="material-symbols-light:print-outline"/>}
                                    onClick={handlePrintDebug}
                                />
                            </Tooltip>
                        </ButtonStack>
                    </Box>
                </Stack>
            </VStack>
            <div style={{display: "none"}}>
                <PrintableComponent ref={printComponentRef} data={printData} title="Customer's KYC Profile" />
            </div>
        </>
    );
};

export default CustomerViewForm;
