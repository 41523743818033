import React, { useState } from 'react';
import {
  Flex,
  Input,
  Stack,
  Icon,
  Image,
  Button,
  Box,
  Heading,
  FormLabel,
  FormControl,
  VStack,
  HStack,
  Link,
  InputGroup,
  InputRightElement,
  IconButton, useDisclosure,
} from '@chakra-ui/react';
import { FaCircleUser } from 'react-icons/fa6';
import { PropagateLoader } from 'react-spinners';
import WUCLogo from '../../../assests/images/BlueLogoNoBG.png';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import utils from "../../../utils/commonFunctions";
import { LiaUnlockAltSolid } from "react-icons/lia";
import enums from "wuc-library/enums";
import useSingleToast from "../../../hooks/UseSingleToast/UseSingleToast";
import MigratedAlert from "./MigratedAlert";

const LoginForm = () => {
  //const { executeRecaptcha } = useGoogleReCaptcha();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const showToast = useSingleToast();
  const [isMigrated, setMigrated] = useState(false);
  const [message, setMessage] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure()
  
  const toggleShowPassword = () => setShowPassword(prevState => !prevState);

  const handleLogin = async (event) => {
    event.preventDefault();

    if (!email || !password) {
      showToast({
        title: 'Missing Credentials',
        description: 'Both email and password are required to log in.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);

    try {
      const response = await utils.login(email, password);
      if(!response){
        showToast({
          title: 'Login Error',
          description: 'Error occured while logging in.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        navigate('/login');
        return
      }
      if (response.status === 202) {
        await utils.forgotPassword(email)
        setLoading(false); // Stop loading here as well
        setMigrated(true)
        onOpen()
        setMessage(response.data.message)
        return;
      }
      if(response?.data.status === 401){
        showToast({
          title: 'Invalid Credentials',
          description: response?.data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        navigate('/login');
        return
      }

        const { token } = response.data;
        utils.setToken('userToken', token);

        const role = utils.getRole(token);
        if(role!=='admin'){
          showToast({
            title: 'Access Denied',
            description: 'Only admins can access the admin panel.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          navigate('/login');
          return
        }

          const isVerified = utils.getIsVerified(token);
          const isFirstTimeLogin = utils.getIsFirstTimeLogin(token);
          const requirePasswordChange = utils.getRequirePasswordChange(token);
        if (!isVerified) {
          await requestOTPAndNavigate(email);
          return
        }
        if(isFirstTimeLogin || requirePasswordChange){
          navigateToCreatePassword();
          return
        }
        successfulLoginFlow(token);
    } catch (error) {

      console.log(error)
      if(error.code==='ERR_NETWORK'){
        showToast({
          title: error.message,
          description:"Please try again later",
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      else {
        showToast({
          title: 'Login Error',
          description: error?.message || 'Server error. Please try again later.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } finally {
      setLoading(false);
    }
  };


  const successfulLoginFlow = (token) => {
    showToast({
      title: 'Login Successful',
      description: 'Welcome back! You have been successfully logged in.',
      status: 'success',
      duration: 1500,
      isClosable: true,
    });
    utils.setToken('authToken', `Bearer ${token}`);
    localStorage.removeItem('userToken');
    navigate('/dashboard');
  };

  const adminConditionalRedirects = (isVerified, isFirstTimeLogin, requirePasswordChange) => {
    console.log(isVerified,isFirstTimeLogin,requirePasswordChange)
    if (!isVerified) {
      requestOTPAndNavigate(email);
    }
    if( isFirstTimeLogin){
      navigateToCreatePassword();
    }
    if (requirePasswordChange) {
      navigateToCreatePassword();
    }
  };

  const requestOTPAndNavigate = async (email) => {
    try {
      await utils.requestNewOTP(email, enums.OTPUsage.REGISTRATION);
      showToast({
        title: 'OTP Verification Required',
        description: 'An OTP has been sent to your email. Please enter it on the next screen to verify your account.',
        status: 'info',
        duration: 5000,
        isClosable: true,
      });
      navigate('/otp');
    } catch (otpError) {
      console.error('Error requesting new OTP:', otpError);
      showToast({
        title: 'OTP Request Error',
        description: 'Failed to send OTP. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const navigateToCreatePassword = () => {
    showToast({
      title: 'Password Change Required',
      description: 'You are required to change your password. Please reset now.',
      status: 'warning',
      duration: 5000,
      isClosable: true,
    });
    navigate('/create-password');
  };

  return (
      <>
        <Stack
            bg='white'
            h={500}
            w={1000}
            rounded='xl'
            boxShadow='lg'
            direction={{ base: 'column', md: 'row' }}
        >
          {isMigrated&&<MigratedAlert isOpen={isOpen} onClose={onClose} message={message}/>}
          <Flex p={8} flex={1} align={'center'} justify={'center'}>
            <Stack spacing={4} w={'full'} maxW={'md'}>
              <Flex align={'center'} justify='center'>
                <VStack>
                  <Box
                      backgroundColor='blue.300'
                      borderRadius='50%'
                      width='100px'
                      height='100px'
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                  >
                    <Icon as={LiaUnlockAltSolid} color='white' boxSize='50%' />
                  </Box>
                  <Heading color='blue.800' as='h6' size='lg'>
                    Admin Panel
                  </Heading>
                </VStack>
              </Flex>
              <FormControl id='email'>
                <FormLabel color='blue.800'>Email</FormLabel>
                <InputGroup>
                  <Input
                      type={'text'}
                      focusBorderColor='blue.300'
                      onChange={(e) => setEmail(e.target.value)}
                  />
                  <InputRightElement>
                    <IconButton
                        icon={<FaCircleUser color='white' />}
                        bg='blue.300'
                        _hover={{ bg: '#69C5EC' }}
                        _active={{ bg: '#4699bc' }}
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <FormControl id='password'>
                <FormLabel color='blue.800'>Password</FormLabel>
                <InputGroup>
                  <Input
                      type={showPassword ? 'text' : 'password'}
                      focusBorderColor='blue.300'
                      onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputRightElement>
                    <IconButton
                        aria-label={showPassword ? 'Hide password' : 'Show password'}
                        icon={showPassword ? <ViewOffIcon color='white' /> : <ViewIcon color='white' />}
                        onClick={toggleShowPassword} // This replaces handleClick
                        bg='blue.300'
                        _hover={{ bg: '#69C5EC' }}
                        _active={{ bg: '#4699bc' }}
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Stack spacing={6}>
                {loading ? (
                    <Button bg={'blue.300'} _hover={{ bg: '#00ace6' }}>
                      {loading ? (
                          <PropagateLoader size={8} color='white' />
                      ) : (
                          'Click me'
                      )}
                    </Button>
                ) : (
                    <Button
                        _hover={{
                          bg: 'blue.300',
                          color: 'white',
                          borderColor: 'blue.800',
                        }}
                        colorScheme='black'
                        type='submit'
                        variant='outline'
                        onClick={handleLogin}
                    >
                      Sign in
                    </Button>
                )}
                <HStack
                    spacing={40}
                    justifyContent='center'
                    alignItems='center'
                >
                  <Link as={RouterLink} to='/forgot-password' style={{ textDecoration: 'none', color: 'blue.800' }} _hover={{ textDecoration: 'none' }}>
                    Forgot Password
                  </Link>
                  <Link href='https://www.wuc.bw/' color='blue.800' isExternal _hover={{ textDecoration: 'none' }}>
                    View the site
                  </Link>
                </HStack>
              </Stack>
            </Stack>
          </Flex>
          <Flex flex={1}>
            <Image
                alt={'ResetPassword Image'}
                src={WUCLogo}
                objectFit={'contain'}
                h={400}
                w={400}
                mt={[20]}
                ml={[8]}
                display={{ base: 'none', md: 'flex' }}
            />
          </Flex>
        </Stack>
      </>
  );


};

export default LoginForm;
