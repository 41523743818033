import React from 'react';
import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from "@chakra-ui/react";
import ReactPlayer from "react-player";

const VideoPlayer = ({video, isOpen, onClose}) => (
    <Modal isOpen={isOpen} onClose={onClose} size={{base:'full',md:'4xl'}}>
        <ModalOverlay/>
        <ModalContent w={'full'}>
            {video &&
                <>
                    <ModalHeader>{video?.title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex w={'full'} h={'full'} className='video-player'>
                            <ReactPlayer url={video?.url} width='100%' height='100%' controls/>
                        </Flex>
                    </ModalBody>
                </>
            }
        </ModalContent>
    </Modal>
);

export default VideoPlayer;
