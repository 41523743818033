import React, {useCallback} from 'react';
import {Flex, GridItem} from '@chakra-ui/react';
import { StyledGrid } from "../../../../../components/Styled/StyledComponents";
import LabQuotationRequestSearchFilter from "./LabQuotationRequestSearchFilter";

const LabQuotationRequestTableTopBar = ({ setPageNumber, setFilterOptions, setFetchTrigger }) => {

    const handleFilterChange = useCallback(async (newFilterOptions) => {
        setPageNumber(1);
        await new Promise(resolve => setTimeout(resolve, 0));
        setFilterOptions(newFilterOptions);
        setFetchTrigger(f => !f);
    }, [setPageNumber, setFilterOptions, setFetchTrigger]);

    return (
        <>
            <StyledGrid templateColumns="repeat(12, 1fr)">
                <GridItem colSpan={{ base: 12, md: 12 }}>
                    <LabQuotationRequestSearchFilter onFilterChange={handleFilterChange} />
                </GridItem>
            </StyledGrid>
            <Flex bg="white" mb={4}></Flex>
        </>
    );
};

export default LabQuotationRequestTableTopBar;
