// src/App.js
import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TinyMCE= ({value}) => {
    const [content, setContent] = useState(value);

    const handleEditorChange = (content) => {
        setContent(content);
    };

    return (
        <div className="App">
            <Editor
                apiKey="YOUR_TINYMCE_API_KEY"  // Replace with your TinyMCE API key
                value={content}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help'
                }}
                onEditorChange={handleEditorChange}
            />
        </div>
    );
};

export default TinyMCE;
