import React, {useEffect, useRef, useState} from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    Box,
    Text,
    SimpleGrid, InputRightElement, InputGroup, IconButton, Spinner, Select, Textarea, FormErrorMessage,
} from '@chakra-ui/react';
import { ThemedStyledInput } from "../../../../../components/Styled";
import FormActionLabel from "../../../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../../../components/Styled/StyledButtons";
import { useDrawer } from '../../../../../Context/DrawerContext/DrawerContext';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ConfirmDialog from "../../../../../Context/ConfirmDialog/ConfirmDialog";
import useSingleToast from "../../../../../hooks/UseSingleToast/UseSingleToast";
import DropBox from "../../../../../components/DropBox/DropBox";
import utils from "../../../../../utils/commonFunctions";
import {fetchAllPublicationsCategories, fetchPublicationById, updatePublication} from "../PublicationsAPI";
import {Icon} from "@iconify/react";

const PublicationEditForm = ({ publicationId, refetchPublicationData, filterOptions }) => {
    const { closeDrawer } = useDrawer();
    const notifyUserRef = useRef();
    const showToast = useSingleToast();
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [initialValues, setInitialValues] = useState({
        title: '',
        description: '',
        categoryId: '',
        status: '',
        fileData: {
            fileId: null,
            fileUrl: null,
            fileName: null,
            fileType: null,
            size: null,
            extension: '',
        }
    });
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    notifyUserRef.current = (options) => {
        showToast(options);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const publicationCategoriesData = await fetchAllPublicationsCategories();
                const publicationData = await fetchPublicationById(publicationId);
                console.log("ediform", publicationData)
                if (publicationData) {
                    setInitialValues({
                        title: publicationData.title,
                        description: publicationData.description,
                        categoryId: publicationData.categoryId._id,
                        status: publicationData.status,
                        fileData: {
                            fileId: publicationData.fileId,
                            fileUrl: publicationData.fileUrl,
                            fileName: publicationData.fileName,
                            fileType: publicationData.fileType,
                            size: publicationData.size,
                            extension: publicationData.extension,
                        }
                    });
                    setCategories(publicationCategoriesData.publicationCategories);
                }
            } catch (error) {
                notifyUserRef.current({
                    title: 'Error loading data',
                    description: error.message || 'Failed to load publication data',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };

        fetchData();
    }, [publicationId]);

    const publicationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required.'),
        description: Yup.string().required('Description is required.'),
        categoryId: Yup.string().required('Category is required.'),
        status: Yup.string().required('Status is required.'),
        fileData: Yup.object({
            fileUrl: Yup.string().required('Publication document is required.'),
            fileName: Yup.string().required('File name is required.'),
            extension: Yup.string().required('File extension is required.'),
        })
    });

    const handleFileChange = async (fieldName, event, setFieldValue) => {
        const file = event.target.files[0];
        if (file) {
            setLoading(true);
            try {
                const fileId = uuidv4();
                const uploadResponse = await utils.handleFileUpload(file, `publications/${fileId}`);
                if (uploadResponse && uploadResponse.data && uploadResponse.data.fileUrl) {
                    setFieldValue('fileData.fileUrl', uploadResponse.data.fileUrl);
                    setFieldValue('fileData.fileId', fileId);
                    setFieldValue('fileData.fileName', file.name);
                    setFieldValue('fileData.fileType', file.type);
                    setFieldValue('fileData.size', file.size);
                    setFieldValue('fileData.extension', utils.getFileExtensionFromUrl(file.name));
                } else {
                    showToast({
                        title: 'File Upload Error',
                        description: 'Upload failed. Incomplete response received.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                showToast({
                    title: 'File Upload Error',
                    description: `Upload failed: ${error.message}`,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } finally {
                setLoading(false);
            }
        }
    };

    const handleSubmit = async (values, actions) => {
        try {
            const submissionData = {
                ...values,
                fileId: values.fileData.fileId,
                fileName: values.fileData.fileName,
                fileType: values.fileData.fileType,
                extension: values.fileData.extension,
                fileUrl: values.fileData.fileUrl,
                size: values.fileData.size,
            };
            await updatePublication(publicationId, submissionData);
            refetchPublicationData(filterOptions);
            closeDrawer();
            showToast({
                title: 'Success',
                description: 'Publication updated successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            showToast({
                title: 'Error updating publication',
                description: error.message || 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            actions.setSubmitting(false);
        }
    };

    const handleCancel = () => {
        setConfirmDialog({
            isOpen: true,
            title: "Cancel Confirmation",
            message: "Are you sure you want to cancel? Any unsaved changes will be lost.",
            onConfirm: () => {
                closeDrawer();
                setConfirmDialog({ ...confirmDialog, isOpen: false }); // Close dialog after confirmation
            }
        });
    };


    const handleConfirm = () => {
        confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    return (
        <>
            <FormActionLabel formAction="edit" formName="Publication" />
            <Formik
                initialValues={initialValues}
                validationSchema={publicationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {(formikProps) => (
                    <Form>
                        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                            <SimpleGrid columns={1} gap={4} px={2} py={4}>
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.title && formikProps.touched.title}>
                                        <FormLabel htmlFor="title">Publication Title</FormLabel>
                                        <InputGroup>
                                            <ThemedStyledInput
                                                {...formikProps.getFieldProps('title')}
                                                id="title"
                                                placeholder="Enter Publication Title"
                                            />
                                            <InputRightElement>
                                                <IconButton
                                                    aria-label="Icon button label"
                                                    bg="blue.300"
                                                    _hover={{ bg: '#69C5EC' }}
                                                    _active={{ bg: '#4699bc' }}
                                                    color="white"
                                                    icon={<Icon icon="mdi:rename-outline" />}
                                                />
                                            </InputRightElement>
                                        </InputGroup>
                                        {formikProps.errors.title && formikProps.touched.title && (
                                            <FormErrorMessage>{formikProps.errors.title}</FormErrorMessage>
                                        )}
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.description && formikProps.touched.description}>
                                        <FormLabel htmlFor="description">Description</FormLabel>
                                        <Textarea
                                            {...formikProps.getFieldProps('description')}
                                            id="description"
                                            placeholder="Enter Description"
                                            borderWidth="2px"
                                            focusBorderColor="#63B3ED"
                                        />
                                        {formikProps.errors.description && formikProps.touched.description && (
                                            <FormErrorMessage>{formikProps.errors.description}</FormErrorMessage>
                                        )}
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.categoryId && formikProps.touched.categoryId}>
                                        <FormLabel htmlFor="categoryId">Category</FormLabel>
                                        <Select
                                            {...formikProps.getFieldProps('categoryId')}
                                            id="categoryId"
                                            placeholder="Select Category"
                                            value={formikProps.values.categoryId} // Ensures the formik state controls the selected value
                                        >
                                            {categories.map(option => (
                                                <option key={option._id} value={option._id}>{option.name}</option>
                                            ))}
                                        </Select>
                                        {formikProps.errors.categoryId && formikProps.touched.categoryId && (
                                            <FormErrorMessage>{formikProps.errors.categoryId}</FormErrorMessage>
                                        )}
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.fileData && formikProps.errors.fileData.fileUrl && formikProps.touched.fileData && formikProps.touched.fileData.fileUrl}>
                                        <DropBox
                                            label="Publication Document"
                                            name="fileData.fileUrl"
                                            filePath={formikProps.values.fileData.fileUrl}
                                            loading={loading}
                                            formikProps={formikProps}
                                            callback={(event) => handleFileChange('fileUrl', event, formikProps.setFieldValue)}
                                            result={formikProps.values.fileData.fileUrl}
                                            instruction="Please attach relevant documents."
                                        />
                                        {formikProps.errors.fileData && formikProps.errors.fileData.fileUrl && formikProps.touched.fileData && formikProps.touched.fileData.fileUrl && (
                                            <Text color="red.500"
                                                  fontSize="sm">{formikProps.errors.fileData.fileUrl}</Text>
                                        )}
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.status && formikProps.touched.status}>
                                        <FormLabel htmlFor="status">Status</FormLabel>
                                        <Select
                                            {...formikProps.getFieldProps('status')}
                                            id="status"
                                            placeholder="Select Status"
                                        >
                                            <option value="Active">Published</option>
                                            <option value="Archived">Archived</option>
                                        </Select>
                                        {formikProps.errors.status && formikProps.touched.status && (
                                            <Text color="red.500" fontSize="sm">{formikProps.errors.status}</Text>
                                        )}
                                    </FormControl>
                                </GridItem>
                            </SimpleGrid>
                            <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}  mt={8} mb={8}>
                                <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
                                <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                                    <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                                        Cancel
                                    </CustomButton>
                                    <CustomButton
                                        type="submit"
                                        disabled={formikProps.isSubmitting}
                                        style={{
                                            opacity: formikProps.isSubmitting ? 0.7 : 1,
                                            pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                                        }}
                                    >
                                        {formikProps.isSubmitting ? (
                                            <>
                                                <Spinner size="xs" mr={2} />
                                                Updating...
                                            </>
                                        ) : 'Update'}
                                    </CustomButton>
                                </ButtonStack>
                            </Box>
                        </VStack>
                    </Form>
                )}
            </Formik>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

export default PublicationEditForm;
