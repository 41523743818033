import React from 'react'
import DefaultTemplate from '../../../components/DefaultTemplate/DefaultTemplate'
import ShortageReportContainer from './Components/ShortageReportContainer'
import {Icon} from "@iconify/react";

const ShortageReport = () => {
    return (
        <>
            <DefaultTemplate Content={ <ShortageReportContainer/> } Title={"Shortage Reports" } icon={<Icon icon="game-icons:shortage" />}/>
        </>
    )
}

export default ShortageReport
