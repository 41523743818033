import { API1 } from "../../../utils/api";

// Create Management Center
export const createManagementCenter = async (managementCenterData) => {
    try {
        const response = await API1.post('/managementCenters', managementCenterData);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "An unexpected error occurred while creating the management center.");
    }
};

// Fetch All Management Centers
export const fetchAllManagementCenters = async (pageNumber = 1, pageSize = 10, filterOptions = {}, handleError) => {
    try {
        const queryParams = new URLSearchParams({ ...filterOptions, pageNumber, pageSize }).toString();
        const response = await API1.get(`/managementCenters?${queryParams}`);
        if (response.status === 200) {
            return {
                managementCenters: response.data.docs,
                totalPages: Math.ceil(response.data.totalDocs / pageSize),
            };
        }
        throw new Error('Unexpected response status: ' + response.status);
    } catch (error) {
        const errorMessage = error?.message || "Failed to fetch management centers. Please try again.";
        if (handleError) {
            handleError(errorMessage);
        }
        throw new Error(errorMessage);
    }
};


// Fetch Management Center by ID using query parameters
export const fetchManagementCenterById = async (managementCenterId) => {
    try {
        const queryParams = new URLSearchParams({ id: managementCenterId }).toString();
        const response = await API1.get(`/managementCenters?${queryParams}`);
        if (response.status === 200 && response.data.docs.length > 0) {
            return response.data.docs[0];
        } else {
            throw new Error('Management center not found.');
        }
    } catch (error) {
        throw new Error(error?.message || "Failed to fetch the management center details.");
    }
};

// Update Management Center
export const updateManagementCenter = async (managementCenterId, managementCenterData) => {
    try {
        const response = await API1.put(`/managementCenters/${managementCenterId}`, managementCenterData);
        if (response.status !== 200) {
            throw new Error('Update failed due to server response');
        }
        return response.data;
    } catch (error) {
        console.error("Error updating management center:", error);
        throw new Error(error?.message || "An error occurred while updating the management center.");
    }
};


// Delete Management Center
export const deleteManagementCenter = async (managementCenterId) => {
    try {
        const response = await API1.delete(`/managementCenters/${managementCenterId}`);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "Failed to delete the management center. It might have associated cities.");
    }
};
