import React, {useState, useEffect} from 'react';
import Header from '../../components/Header/Header';
import MiniStatistics from '../../components/MiniStatistics/MiniStatistics';
import { Box, SimpleGrid, Flex } from '@chakra-ui/react';
import { BsSpeedometer } from 'react-icons/bs';
import {
  FiDollarSign,
  FiFileText,
} from 'react-icons/fi';
import {
  MdReport,
  MdPayment,
  MdWork,
  MdBuild,
  MdNotificationImportant,
  MdLogin,
  MdPeople,
} from 'react-icons/md';
import { GiWaterDrop, GiTap } from 'react-icons/gi';
import AnalyticsDashboard from '../../components/AnalyticsDashboard/AnalyticsDashboard';
import Footer from '../../components/Footer/Footer';
import { getSummationUserLogs, getCMSTallies } from './dashboardAPI';

const Dashboard = () => {
  const [userLogs, setUserLogs] = useState();
  const [cmsTallies, setCmsTallies] = useState();

  useEffect(() => {

    async function fetchUserLogs() {
      const user_logs = await getSummationUserLogs();
      setUserLogs(user_logs);
    }
    async function fetchCMSTallies() {
      const cms_tallies = await getCMSTallies();
      setCmsTallies(cms_tallies);
    }

    fetchUserLogs();
    fetchCMSTallies();
  }, []);

  return (
    <div>
      <Header />
      <Flex
        bg='gray.50'
        minH={'100vh'}
        alignItems='center'
        justifyContent='center'
      >
        <Box
          maxW='8xl'
          mx={'auto'}
          px={{ base: '10', md: '0' }}
          pt={{ base: 10, sm: 0, md: 32 }}
        >
          <SimpleGrid columns={{ base: 1, md: 4 }} spacing={{ base: 5, lg: 8 }}>
            <MiniStatistics
              title={'Successful Customer Logins'}
              stat={userLogs?.totalSuccessfulLogins || 0}
              icon={<MdLogin size={'3em'} color='#63B3ED' />}
            />
            <MiniStatistics
              title={'Successful Account Balance Checks'}
              stat={userLogs?.totalCheckBillCounts || 0}
              icon={<FiDollarSign size={'3em'} color='#63B3ED' />}
            />
            <MiniStatistics
              title={'Successful Meter Readings'}
              stat={userLogs?.totalMeterReadingCounts || 0}
              icon={<BsSpeedometer size={'3em'} color='#63B3ED' />}
            />
            <MiniStatistics
              title={'Sucessful Bill Requests'}
              stat={userLogs?.totalCheckBillCounts || 0}
              icon={<FiFileText size={'3em'} color='#63B3ED' />}
            />
            <MiniStatistics
              title={'Registered Customers'}
              stat={userLogs?.totalUserLogs || 0}
              icon={<MdPeople size={'3em'} color='#63B3ED' />}
            />
            <MiniStatistics
              title={'Online Payments'}
              stat={userLogs?.totalPaymentCount || 0}
              icon={<MdPayment size={'3em'} color='#63B3ED' />}
            />
            <MiniStatistics
              title={'Leak Reports'}
              stat={userLogs?.totalLeakReportCounts || 0}
              icon={<MdReport size={'3em'} color='#63B3ED' />}
            />
            <MiniStatistics
              title={'Water Shortage Reports'}
              stat={userLogs?.totalWaterShortageReportCounts || 0}
              icon={<GiWaterDrop size={'3em'} color='#63B3ED' />}
            />
            <MiniStatistics
              title={'Connection Applications'}
              stat={userLogs?.totalConnectionRequests || 0}
              icon={<GiTap size={'3em'} color='#63B3ED' />}
            />
            <MiniStatistics
              title={'Notices'}
              stat={cmsTallies?.noticesCount || 0}
              icon={<MdNotificationImportant size={'3em'} color='#63B3ED' />}
            />
            <MiniStatistics
              title={'Projects'}
              stat={cmsTallies?.projectsCount || 0}
              icon={<MdBuild size={'3em'} color='#63B3ED' />}
            />
            <MiniStatistics
              title={'Jobs & Tenders'}
              stat={cmsTallies?.tendersCount || 0}
              icon={<MdWork size={'3em'} color='#63B3ED' />}
            />
          </SimpleGrid>
          <AnalyticsDashboard />
        </Box>
      </Flex>
      <Footer />
    </div>
  );
};

export default Dashboard;
