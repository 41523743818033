import React, { useState, useEffect } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
  VStack,
  Image,
  Select
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import videoService from '../../../../services/videoService';
import {useDrawer} from "../../../../Context/DrawerContext/DrawerContext"; // Adjust the import path as necessary

// Placeholder image URL
const PLACEHOLDER_THUMBNAIL = 'https://via.placeholder.com/150';

const VideoEditForm = ({ videoId,refetchVideoData }) => {
  const toast = useToast();
  const [thumbnails, setThumbnails] = useState([]);
  const { closeDrawer } = useDrawer();
  // This function should be replaced with the actual API call to fetch thumbnails based on the video source and code
  const fetchThumbnails = async (source, code) => {
    // Mocked API response - Replace this with actual API calls
    return [
      PLACEHOLDER_THUMBNAIL,
      'https://via.placeholder.com/150/0000FF',
      'https://via.placeholder.com/150/FF0000'
    ];
  };

  useEffect(() => {
    // Fetch video details and populate the form (For the purpose of this example, we're mocking this data)
    const fetchVideoDetails = async () => {
      const videoDetails=await videoService.getVideoById(videoId)
      if (videoDetails){
        formik.setValues({
          title: videoDetails.title,
          videoCode: videoDetails.videoCode,
          thumbnail: PLACEHOLDER_THUMBNAIL
        });
      }
      const fetchedThumbnails = await fetchThumbnails(videoDetails.source, videoDetails.videoCode);
      setThumbnails(fetchedThumbnails);
    };

    fetchVideoDetails();
  }, [videoId]);

  const formik = useFormik({
    initialValues: {
      title: '',
      videoCode: '',
      thumbnail: ''
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Required'),
      videoCode: Yup.string().required('Required')
    }),
    onSubmit: async (values, actions) => {
      try {
        await videoService.updateVideo(videoId, {

          title: values.title,
          videoCode: values.videoCode,
          thumbnail: values.thumbnail
        });
        toast({
          title: 'Video Updated',
          description: "The video has been updated successfully.",
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        refetchVideoData()
        closeDrawer();
      } catch (error) {
        toast({
          title: 'Failed to Update Video',
          description: error.message || "An error occurred while updating the video.",
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      actions.setSubmitting(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <VStack spacing={4}>
        <FormControl id="title" isRequired>
          <FormLabel>Title</FormLabel>
          <Input
            placeholder="Enter video title"
            {...formik.getFieldProps('title')}
          />
        </FormControl>
        <FormControl id="videoCode" isRequired>
          <FormLabel>Video Code</FormLabel>
          <Input
            placeholder="Enter video code"
            {...formik.getFieldProps('videoCode')}
          />
        </FormControl>
        <FormControl id="thumbnail">
          <FormLabel>Thumbnail</FormLabel>
          <Select
            placeholder="Select a thumbnail"
            {...formik.getFieldProps('thumbnail')}
          >
            {thumbnails.map((thumb, index) => (
              <option key={index} value={thumb}>
                Thumbnail {index + 1}
              </option>
            ))}
          </Select>
          {formik.values.thumbnail && (
            <Image src={formik.values.thumbnail} alt="Thumbnail preview" />
          )}
        </FormControl>
        <Button
          mt={4}
          colorScheme="blue"
          isLoading={formik.isSubmitting}
          type="submit"
        >
          Update Video
        </Button>
      </VStack>
    </form>
  );
};

export default VideoEditForm;
