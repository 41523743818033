import React, {useState} from 'react';
import { Flex, GridItem, Input, Select } from '@chakra-ui/react';
import AlbumAddForm from './AlbumAddForm'; // Adjust the import path as necessary
import { StyledGrid } from "../../../../../components/Styled/StyledComponents";
import { ButtonStack, CustomButton } from "../../../../../components/Styled/StyledButtons";
import { useDrawer } from "../../../../../Context/DrawerContext/DrawerContext";
import { Icon } from "@iconify/react";

const AlbumTableTopBar = ({ fetchData, setFilteredAlbums, albums, searchQuery, setSearchQuery }) => {
    const { openDrawer } = useDrawer();
    const handleSearchChange = (event) => {
        const query = event.target.value.toLowerCase();
       setSearchQuery(query)
    };

    const handleAddNewAlbum = () => {
        openDrawer({
            title: 'Add New Album',
            component: AlbumAddForm,
            props: { refetchAlbums: fetchData }
        });
    };

    return (
        <>
            <StyledGrid templateColumns="repeat(12, 1fr)">
                <GridItem colSpan={{ base: 6, md: 9 }}>
                    <Input
                        placeholder="Search albums by title"
                        onChange={handleSearchChange}
                        value={searchQuery}
                    />
                </GridItem>
                <GridItem colSpan={{ base: 6, md: 3 }}>
                    <ButtonStack direction="row">
                        <CustomButton
                            type="addNew"
                            showIcon={true}
                            showTooltip={true}
                            tooltipLabel="Add New Album"
                            onClick={handleAddNewAlbum}
                            leftIcon={<Icon icon="fa6-solid:plus" />}
                        >
                            Add New
                        </CustomButton>
                    </ButtonStack>
                </GridItem>
            </StyledGrid>
        </>
    );
};

export default AlbumTableTopBar;
