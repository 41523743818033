import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Card,
  CardBody,
  Flex,
  Heading,
  Icon,
  Badge,
  Image,
  Center,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  MenuItem, MenuList, MenuButton,Menu

} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import DefaultTemplate from "../../../components/DefaultTemplate/DefaultTemplate";
import { fetchTenderById, fetchPurchasesTenderById } from "../tenderAPI";
import moneyBag from '../../../assests/images/moneyBag.png';
import utils from '../../../utils/commonFunctions';
import { FaPrint } from 'react-icons/fa';
import { useReactToPrint } from 'react-to-print';
import {ChevronDownIcon} from "@chakra-ui/icons";
import DownloadExcel from "../../../components/Tools/DownloadExcel";
import DownloadCSV from "../../../components/Tools/DownloadCSV";

const TenderPurchasesToolsMenu = ({print,data,fileName}) => {
  return (
      <>
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            Actions
          </MenuButton>
          <MenuList>
            <MenuItem onClick={()=>print()}  leftIcon={<FaPrint />}>Print Pdf</MenuItem>
            <MenuItem >
              <DownloadCSV data={data} fileName={fileName}/>
            </MenuItem>
            <MenuItem >
              <DownloadExcel fileName={fileName} data={data}/>
            </MenuItem>
          </MenuList>
        </Menu>
      </>
  );
};

const TenderViewPurchases = () => {
  const { tenderId } = useLocation().state;
  return (
    <>
      <DefaultTemplate
        Content={<ViewContainer tenderId={tenderId} />}
        Title={"Tender purchases"}
        icon={<Icon icon="fa:briefcase" />}
      />
    </>
  );
};

const ViewContainer = ({ tenderId }) => {

  const TenderPurchaseListTable = React.useRef();

  const [tender, setTender] = useState({});
  const [tenderPurchaseList, setTenderPurchaseList] = useState([]);

  useEffect(() => {
    const fetchTenderData = async () => {
      try {
        const tenderData = await fetchTenderById(tenderId);
        console.log("tenderData", tenderData);
        setTender(tenderData);

      } catch (error) {
        console.error("Error fetching tender data:", error);
      }
    };
    const fetchTenderPurchaseListData = async () => {
      try {
        const tenderPurchaseData = await fetchPurchasesTenderById(tenderId);
        console.log("tender Purchase List Data", tenderPurchaseData);
        setTenderPurchaseList(tenderPurchaseData);

      } catch (error) {
        console.error("Error fetching tender purchase list data:", error);
      }
    };

    if (tenderId) {
      fetchTenderData();
      fetchTenderPurchaseListData();
    }
  }, [tenderId]);

    const handlePrint = useReactToPrint({
      content: () => TenderPurchaseListTable.current,
      documentTitle: 'Tender Purchase List',
        // onAfterPrint: () => setExpandAllItems(false) // Reset after printing
    });

  return (
    <Card maxW='full' mb={8} marginBottom={8}>
      <CardBody>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          justify="space-between"
          w="100%"
          p={4}
          height={'auto'}
        >
          <Box
            flex={{ base: '100%', md: '100%' }}
            bg="white.200"
            p={4}
            // m={2}
            borderRadius="md"
            boxShadow="md"
          >
            <Center>
              <Image
                src={moneyBag}
                alt="tender image"
                width="20vh"
                height="20vh"
              />
            </Center>
            <Center mt={4}>
              <Heading size={'lg'}>Title: {tender.title || 'No Title'}</Heading>
            </Center>
            <Flex w={'full'} justify={'end'}  mt={4}>
              <TenderPurchasesToolsMenu print={handlePrint} download={handlePrint} data={tenderPurchaseList} fileName={'tender-purchase'}/>
            </Flex>
          </Box>
        </Flex>
        <Box
          flex={{ base: '100%', md: '100%' }}
          bg="white"
          p={4}
          m={2}
          borderRadius="md"
          align="center"
          width={{ base: '100%', md: '100%' }}
          boxShadow="md"
          ref={TenderPurchaseListTable}
        >
          <Table variant="striped" maxW='full'>
            <Thead>
              <Tr>
                <Th>Reference #</Th>
                <Th>Company</Th>
                <Th>Email</Th>
                <Th>Category</Th>
                <Th>Amount(BWP)</Th>
                <Th><Center>Date</Center></Th>
              </Tr>
            </Thead>
            <Tbody>
              {tenderPurchaseList?.map((purchase, index) => (
                <Tr key={index}>
                  <Td>{purchase.reference}</Td>
                  <Td>{purchase.fullName}</Td>
                  <Td>{purchase.email}</Td>
                  <Td>
                    {purchase.isYouth ?
                      <Badge ml='1' colorScheme='green'>
                        Youth
                      </Badge>
                      :
                      <Badge ml='1' colorScheme='blue'>
                        Non-Youth
                      </Badge>
                    }
                  </Td>
                  <Td>
                    <Badge ml='1' colorScheme='green'>
                      {purchase.amount}
                    </Badge>
                  </Td>
                  <Td>
                    <Badge ml='1' colorScheme='green'>
                      {utils.formatDate(purchase.createdDate)}
                    </Badge>
                  </Td>
                </Tr>
              ))}

              {tenderPurchaseList.length === 0 && (
                <Tr>
                  <Td colSpan={6}>
                    <Center>
                      <Text>No purchases found for this tender.</Text>
                    </Center>
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </Box>
      </CardBody>
    </Card>
  );
};
export default TenderViewPurchases;