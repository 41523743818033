import { API1 } from "../../../utils/api";

// Create Area
export const createArea = async (areaData) => {
    try {
        const response = await API1.post('/areas', areaData);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "An unexpected error occurred while creating the area.");
    }
};

// Fetch All Areas
export const fetchAllAreas = async (pageNumber = 1, pageSize = 10, filterOptions = {}) => {
    try {
        const queryParams = new URLSearchParams({
            ...filterOptions,
            pageNumber,
            pageSize
        }).toString();

        const response = await API1.get(`/areas?${queryParams}`);

        if (response.status === 200) {
            const { docs: areas, totalDocs } = response.data;
            return {
                areas,
                totalPages: Math.ceil(totalDocs / pageSize),
            };
        } else {
            throw new Error('Unexpected response from the server while fetching areas.');
        }
    } catch (error) {
        console.error("Error fetching areas:", error);
        throw new Error('Failed to fetch areas. Please try again.');
    }
};

// Fetch Area by ID using query parameters
export const fetchAreaById = async (areaId) => {
    try {
        const queryParams = new URLSearchParams({ id: areaId }).toString();
        const response = await API1.get(`/areas?${queryParams}`);
        if (response.status === 200 && response.data.docs.length > 0) {
            return response.data.docs[0];
        } else {
            throw new Error('Area not found.');
        }
    } catch (error) {
        throw new Error(error?.message || "Failed to fetch the area details.");
    }
};

// Update Area
export const updateArea = async (areaId, areaData) => {
    try {
        const response = await API1.put(`/areas/${areaId}`, areaData);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "An error occurred while updating the area.");
    }
};

// Delete Area
export const deleteArea = async (areaId) => {
    try {
        const response = await API1.delete(`/areas/${areaId}`);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "Failed to delete the area. It might have associated branches.");
    }
};


export const fetchAllCities = async () => {
    try {

        const response = await API1.get(`/cities`);

        if (response.status === 200) {
            const { docs: cities} = response.data;
            return {
                cities,

            };
        } else {
            throw new Error('Unexpected response from the server while fetching cities.');
        }
    } catch (error) {
        console.error("Error fetching cities:", error);
        throw new Error('Failed to fetch cities. Please try again.');
    }
};

// Fetch Management Center by ID using query parameters
export const fetchCityById = async (cityId) => {
    try {
        const queryParams = new URLSearchParams({ id: cityId }).toString();
        const response = await API1.get(`/cities?${queryParams}`);
        if (response.status === 200 && response.data.docs.length > 0) {
            return response.data.docs[0];
        } else {
            throw new Error('City not found.');
        }
    } catch (error) {
        throw new Error(error?.message || "Failed to fetch the city details.");
    }
};