import { useToast } from "@chakra-ui/react";
import { useState } from "react";

function useSingleToast() {
    const toast = useToast({ position: 'top-right' });
    const [toastId, setToastId] = useState(null);

    const showToast = ({ title, description, status, duration = 5000, isClosable = true }) => {
        // Close existing toast if it exists
        if (toastId) {
            toast.close(toastId);
        }
        // Create a new toast and save its ID
        const id = toast({
            title,
            description,
            status,
            duration,
            isClosable,
        });
        setToastId(id);
    };

    return showToast;
}

export default useSingleToast;
