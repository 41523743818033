import React from 'react'
import ProjectsContainer from './Components/ProjectsContainer'
import {Icon} from "@iconify/react";
import DefaultTemplate from "../../components/DefaultTemplate/DefaultTemplate";

const Projects = () => {
    return (
        <>
            <DefaultTemplate 
                Content={ <ProjectsContainer/> } 
                Title={"Projects"} 
                icon={<Icon icon="mdi:poll" />}
            />
        </>
    )
}

export default Projects
