import {
  Flex,
  Input,
  Stack,
  Icon,
  Text,
  Image,
  Button,
  Box,
  Heading,
  useToast,
  FormLabel,
  FormControl,
  VStack,
  HStack,
  Link,
  InputGroup,
  InputRightElement,
  IconButton,
} from '@chakra-ui/react';
import { useState } from 'react';
import { PropagateLoader } from 'react-spinners';
import WUCLogo from './../../assests/images/BlueLogoNoBG.png';
import Footer from '../../components/Footer/Footer';
import { Link as RouterLink } from 'react-router-dom';
import { MdOutlineAlternateEmail, MdLockReset } from 'react-icons/md';
import utils from "../../utils/commonFunctions";

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const toast = useToast({ position: 'top-right' });

  const handlePasswordReset = async () => {
    if (!email.trim()) {
      toast({
        title: 'Missing Information',
        description: 'Email must be provided.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);

    try {
      const response = await utils.resetPassword(email, 'admin');
      if (response.status === 200) {
        toast({
          title: 'Success',
          description: 'A password reset link has been sent to your email.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        setEmail('');
      } else {
        throw new Error('Failed to send password reset email.');
      }
    } catch (error) {
      let errorMessage = 'Failed to send reset link. Please try again later.';
      if (error.response) {
        // Handling server errors
        errorMessage = error.response.data.message || errorMessage;
      } else if (error.request) {
        // Handling network errors or request made but no response
        errorMessage = 'Network error. Please check your internet connection.';
      }
      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
      <>
        <Flex
            bg='gray.50'
            minH={'100vh'}
            alignItems='center'
            justifyContent='center'
            mt={-10}
            px={{ base: '10', md: '0' }}
        >
          <Stack
              bg='white'
              h={500}
              w={1000}
              rounded='xl'
              boxShadow='lg'
              direction={{ base: 'column', md: 'row' }}
          >
            <Flex p={8} flex={1} align={'center'} justify={'center'}>
              <Stack spacing={4} w={'full'} maxW={'md'}>
                <Flex align={'center'} justify='center'>
                  <VStack>
                    <Box
                        backgroundColor='blue.300'
                        borderRadius='50%'
                        width='100px'
                        height='100px'
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                    >
                      <Icon as={MdLockReset} color='white' boxSize='50%' />
                    </Box>
                    <Heading color='blue.800' as='h6' size='lg'>
                      Forgot Password
                    </Heading>
                  </VStack>
                </Flex>
                <FormControl id='emailID'>
                  <FormLabel color='blue.800'>Email</FormLabel>
                  <InputGroup>
                    <Input
                        type={'text'}
                        value={email}
                        focusBorderColor='blue.300'
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <InputRightElement>
                      <IconButton
                          icon={<MdOutlineAlternateEmail color='white' />}
                          bg='blue.300'
                          _hover={{ bg: '#69C5EC' }}
                          _active={{ bg: '#4699bc' }}
                      />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <Stack spacing={6}>
                  {loading ? (
                      <Button bg={'blue.300'} _hover={{ bg: '#00ace6' }}>
                        <PropagateLoader size={8} color='white' />
                      </Button>
                  ) : (
                      <Button
                          _hover={{
                            bg: 'blue.300',
                            color: 'white',
                            borderColor: 'blue.800',
                          }}
                          colorScheme='black'
                          type='submit'
                          variant='outline'
                          onClick={handlePasswordReset}
                      >
                        Send
                      </Button>
                  )}
                  <HStack
                      spacing={40}
                      justifyContent='center'
                      alignItems='center'
                  >
                    <RouterLink to='/login' style={{ textDecoration: 'none', color: 'blue.800' }}>
                      Login
                    </RouterLink>
                    <Link
                        href='https://www.wuc.bw/'
                        color='blue.800'
                        isExternal
                        _hover={{ textDecoration: 'none' }}
                    >
                      View the site
                    </Link>
                  </HStack>
                </Stack>
              </Stack>
            </Flex>
            <Flex flex={1}>
              <Image
                  alt={'Loginbk Image'}
                  src={WUCLogo}
                  objectFit={'contain'}
                  h={400}
                  w={400}
                  mt={[20]}
                  ml={[8]}
                  display={{ base: 'none', md: 'flex' }}
              />
            </Flex>
          </Stack>
        </Flex>
        <Footer />
      </>
  );
};

export default ForgotPassword;

