import React, {useEffect, useState} from 'react';
import {
  Flex,
  Stack,
  Icon,
  Text,
  Image,
  Box,
  Heading,
  FormControl,
  VStack,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Button,
  List,
  ListItem,
  ListIcon,
  Link, FormLabel,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import utils from "../../../utils/commonFunctions";
import WUCLogo from '../../../assests/images/BlueLogoNoBG.png';
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import {MdLockReset} from "react-icons/md";
import useSingleToast from "../../../hooks/UseSingleToast/UseSingleToast";

const ResetPasswordForm = () => {
  const [token,setToken]=useState(useParams()?.token)
  const navigate = useNavigate();
  const showToast = useSingleToast();
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [conPassword, setConPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  const [lowerCaps, setLowerCaps] = useState(false);
  const [upperCaps, setUpperCaps] = useState(false);
  const [digit, setDigit] = useState(false);
  const [isLength, setIsLength] = useState(false);
  const [validPass, setValidPass] = useState(false);
  useEffect(() => {
    if (token) {
      fetchUser();
    }
  }, [token]);

  const fetchUser = async () => {
    setLoading(true);
    try {
      console.log(utils.decodeJWT(token))

      if (!utils.isExpired(utils.decodeJWT(token).exp)) {
        throw new Error('Invalid or expired reset link.');
      }

      const data = await utils.getUserByResetPasswordToken(utils.decodeJWT(token).id);

      if (!data) {
        throw new Error('User not found. Please try again.');
      }

       setUserId(data._id);

    } catch (error) {
      utils.sendMessageToBugNinja("Password Reset Error", error.message, error)
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleError = (error) => {
    let description = 'An unexpected error occurred. Please try again later.';
    if (error.response) {
      description = error.response.data.message || description;
    } else if (!navigator.onLine) {
      description = 'Network error. Please check your internet connection.';
    }

    showToast({
      title: 'Error',
      description: description,
      status: 'error',
      duration: 5000,
      isClosable: true,
    });

    if (error.message.includes('Invalid or expired reset link')) {
      navigate('/forgot-password');
    }
  };

  const handleSetPassword = (password) => {
    setPassword(password);
    let isValid = utils.validatePassword(password);
    setDigit(isValid.digit);
    setUpperCaps(isValid.uppercase);
    setLowerCaps(isValid.lowercase);
    setIsLength(isValid.isLength);
    setValidPass(isValid.isValid);
  };

  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowConPassword = () => setShowConPassword(!showConPassword);

  const handleNewPassword = async () => {
    if (!userId) {
      showToast({
        title: 'User ID Error',
        description: 'No user ID available for password reset.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!password || !conPassword) {
      showToast({
        title: 'Password Required',
        description: 'Please enter a new password and confirm it.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (password !== conPassword) {
      showToast({
        title: 'Password Mismatch',
        description: 'The passwords you entered do not match.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!validPass) {
      showToast({
        title: 'Invalid Password',
        description: 'The password does not meet the required criteria.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);
    try {
      const response = await utils.setNewPassword(userId, password);
      if (response.status === 200) {
        showToast({
          title: 'Password Reset Successful',
          description: 'Your password has been reset successfully. You can now log in with your new password.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        navigate('/login');
      } else {
        throw new Error('Failed to reset password.');
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };


  return(<>

    <Stack
        bg='white'
        h={500}
        w={1000}
        rounded='xl'
        boxShadow='lg'
        direction={{base: 'column', md: 'row'}}
    >
      <Flex
          p={8} flex={1} align={'center'} justify={'center'}
      >
        <Stack
            spacing={4} w={'full'} maxW={'md'}
        >
          <VStack>
            <Box
                backgroundColor='blue.300'
                borderRadius='50%'
                width='100px'
                height='100px'
                display='flex'
                justifyContent='center'
                alignItems='center'
            >
              <Icon as={MdLockReset} color='white' boxSize='50%'/>
            </Box>
            <Heading color='blue.800' as='h6' size='lg'>
              Reset Password
            </Heading>
          </VStack>

          <Text fontSize="sm" color="gray.500" textAlign="center">
            Enter new password below.
          </Text>
          <FormControl id='password'>
            <FormLabel color='blue.800'>New Password</FormLabel>
            <InputGroup>
              <Input
                  type={showPassword ? 'text' : 'password'}
                  focusBorderColor='blue.300'
                  onChange={(e)=>handleSetPassword(e.target.value)}
              />
              <InputRightElement>
                <IconButton
                    aria-label={showPassword ? 'Hide password' : 'Show password'}
                    icon={showPassword ? <ViewOffIcon color='white' /> : <ViewIcon color='white' />}
                    onClick={toggleShowPassword} // This replaces handleClick
                    bg='blue.300'
                    _hover={{ bg: '#69C5EC' }}
                    _active={{ bg: '#4699bc' }}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <FormControl id='password'>
            <FormLabel color='blue.800'>Confirm New Password</FormLabel>
            <InputGroup>
              <Input
                  type={showConPassword ? 'text' : 'password'}
                  focusBorderColor='blue.300'
                  onChange={(e) => setConPassword(e.target.value)}
              />
              <InputRightElement>
                <IconButton
                    aria-label={showConPassword ? 'Hide password' : 'Show password'}
                    icon={showConPassword ? <ViewOffIcon color='white' /> : <ViewIcon color='white' />}
                    onClick={toggleShowConPassword} // This replaces handleClick
                    bg='blue.300'
                    _hover={{ bg: '#69C5EC' }}
                    _active={{ bg: '#4699bc' }}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <Button
              _hover={{
                bg: 'blue.300',
                color: 'white',
                borderColor: 'blue.800',
              }}
              colorScheme='black'
              type='submit'
              variant='outline'
              isLoading={loading}
              loadingText="Verifying..."
              onClick={handleNewPassword}
          >
            Create Password
          </Button>
          <Text fontSize="sm" textAlign="center">
            Need Help?{' '}
            <Link
                color={loading ? "gray.500" : "blue.500"}
                onClick={!loading ? () => window.location.href = 'mailto:admin@example.com?subject=Help Needed' : undefined}
                _hover={{
                  textDecoration: !loading ? 'underline' : 'none'
                }}
                cursor={loading ? 'not-allowed' : 'pointer'}
            >
              Contact Super Administrator
            </Link>

          </Text>
        </Stack>
      </Flex>
      <Flex flex={1}>
        <Flex direction={'column'} justify={'center'} align={'center'} w={'full'} h={'full'}>
          <Image
              alt={'ResetPassword Image'}
              src={WUCLogo}
              objectFit={'contain'}
              h={200}
              w={200}

              display={{base: 'none', md: 'flex'}}
          />
          <List textAlign={"start"} spacing={3} color={"blue.800"} p={4} borderRadius={'2xl'} bg={"blue.50"}>
            <ListItem>
              <ListIcon
                  as={isLength ? FaCheckCircle : FaTimesCircle}
                  color={isLength ? "green.500" : "red.500"}
              />
              Password must contain atleast 8 characters
            </ListItem>
            <ListItem>
              <ListIcon
                  as={upperCaps ? FaCheckCircle : FaTimesCircle}
                  color={upperCaps ? "green.500" : "red.500"}
              />
              Password must contain uppercase letter
            </ListItem>
            <ListItem>
              <ListIcon
                  as={lowerCaps ? FaCheckCircle : FaTimesCircle}
                  color={lowerCaps ? "green.500" : "red.500"}
              />
              Password must contain lowercase letter
            </ListItem>
            <ListItem>
              <ListIcon
                  as={digit ? FaCheckCircle : FaTimesCircle}
                  color={digit ? "green.500" : "red.500"}
              />
              Password must contain a digit
            </ListItem>
          </List>
        </Flex>

      </Flex>
    </Stack>
  </>)

}


export default ResetPasswordForm;
