import React from 'react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, useTheme, useBreakpointValue } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom'; 
import { ChevronRightIcon } from '@chakra-ui/icons';

const CustomBreadcrumb = () => {
  const location = useLocation();
  const navigate = useNavigate();  
  const theme = useTheme();
  const fontSize = useBreakpointValue({ base: 'sm', md: 'md' });

  const formatText = (text) => {
    return text
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const getCurrentPage = (path) => {
    const routes = {
      dashboard: 'Dashboard',
      'new-connection': 'New Connection',
      'pay-bill-online': 'Pay Bill Online',
      'view-account-balance': 'View Account Balance',
      'submit-meter-reading': 'Submit Meter Reading',
      'request-monthly-bill': 'Request Monthly Bill',
      'report-leak': 'Report Leak',
      'manage-utility-contracts': 'Manage Utility Contracts',
      'manage-beneficiary': 'Manage Beneficiary',
      'know-your-customer': 'Know Your Customer',
      'update-profile': 'Update Profile',
      'change-password': 'Change Password',
      'tender-view': 'View Tender',
    };

    if (path.startsWith('/tender/view/')) {
      const id = path.split('/').pop();
      return `View Tender > ${id}`;
    }

    const lastSegment = path.split('/').pop();
    return routes[lastSegment] || formatText(lastSegment);
  };

  const currentPage = getCurrentPage(location.pathname);

  return (
    <div>
      <Button onClick={() => navigate(-1)} my={2}>
        Navigate Back
      </Button>

      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon boxSize={useBreakpointValue({ base: '2', md: '4' })} color="brand.500" />}
        borderRadius="md"
      >
        <BreadcrumbItem>
          <BreadcrumbLink
            href="/dashboard"
            textDecoration="none"
            fontSize="14px"
            fontWeight="600"
          >
            Dashboard
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink
            href="#"
            fontSize="14px"
            fontWeight="normal"
          >
            {currentPage}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
  );
};

export default CustomBreadcrumb;
