import React, {useState} from 'react';
import { Flex, Select, Input, GridItem } from '@chakra-ui/react';
import { StyledGrid } from '../../../../../components/Styled/StyledComponents';  
import { ButtonStack, CustomButton } from "../../../../../components/Styled/StyledButtons";
import { useDrawer } from '../../../../../Context/DrawerContext/DrawerContext';  
import PhotoAddForm from './PhotoAddForm';  
import { Icon } from "@iconify/react";

const PhotoTableTopBar = ({ albums, setFilteredPhotos, photos }) => {
    const { openDrawer } = useDrawer();

    const [filter, setFilter] = useState({ album: "all", search: "" });

    const handleFilterChange = (event, filterType) => {
        const value = event.target.value;
        const newFilter = { ...filter, [filterType]: value };
        setFilter(newFilter);

        let filtered = photos;

        if (newFilter.album !== "all") {
            filtered = filtered.filter(photo => photo.album === newFilter.album);
        }

        if (newFilter.search) {
            const query = newFilter.search.toLowerCase();
            filtered = filtered.filter(photo => photo.caption.toLowerCase().includes(query));
        }

        setFilteredPhotos(filtered);
    };

    const handleAlbumChange = (event) => {
        handleFilterChange(event, "album");
    };

    const handleSearchChange = (event) => {
        handleFilterChange(event, "search");
    };

    const openAddPhotoDrawer = () => {
        openDrawer({
            title: 'Add New Photo',
            component: PhotoAddForm,
            props: { albums } // Passing the albums to the form for the dropdown
        });
    };

    return (
        <StyledGrid templateColumns="repeat(12, 1fr)" gap={4}>
            <GridItem colSpan={{ base: 12, md: 5 }}>
                <Select onChange={handleAlbumChange}>
                    <option value="all">All Albums</option>
                    {albums.map(album => (
                        <option key={album._id} value={album._id}>{album.title}</option>
                    ))}
                </Select>
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 5 }}>
                <Input
                    placeholder="Search by description"
                    onChange={handleSearchChange}
                />
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 2 }}>
            <CustomButton
                            type="addNew"
                            showIcon={true}
                            showTooltip={true}
                            tooltipLabel="Add New Album"
                            onClick={openAddPhotoDrawer}
                            leftIcon={<Icon icon="fa6-solid:plus" />}
                        >
                            Add Photo
                        </CustomButton>
             
            </GridItem>
        </StyledGrid>
    );
};

export default PhotoTableTopBar;
