import { API1 } from "../../utils/api";

// Fetch All Online Payments
export const fetchAllPayments = async (pageNumber = 1, pageSize = 10, filterOptions = {}) => {
    try {
        const queryParams = new URLSearchParams({
            ...filterOptions,
            pageNumber,
            pageSize
        }).toString();

        const response = await API1.get(`/payment/admin/get/all?${queryParams}`);
        if (response.status === 200) {
            const { docs: payments, totalDocs } = response.data;
            return {
                payments,
                totalPages: Math.ceil(totalDocs / pageSize)
            };
        } else {
            throw new Error('Unexpected response from the server while fetching payments.');
        }
    } catch (error) {
        console.error("Error fetching payments:", error);
        throw new Error('Failed to fetch payments. Please try again.');
    }
};

export const exportPayments = async () => {
    try {

        const response = await API1.get(`/payment/admin/get/all`);

        if (response.status === 200) {
            const payments = response.data.docs;
            return payments;
        } else {
            throw new Error('Unexpected response from the server while fetching payments for CSV Export.');
        }
    } catch (error) {
        console.error("Error fetching payments for CSV Export:", error);
        throw new Error('Failed to fetch payments for CSV Export. Please try again.');
    }
};



