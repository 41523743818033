import React from 'react';
import { ErrorMessage } from 'formik';

const FieldErrorMessage = ({ name }) => (
    <ErrorMessage
        name={name}
        component="div"
        style={{ color: 'red', fontSize: '11px', marginTop: '10px' }}
    />
);

export default FieldErrorMessage;
