import React, {useCallback, useEffect, useState} from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box,
} from '@chakra-ui/react';
import ManagementCenterEditForm from './ManagementCenterEditForm';
import ManagementCenterViewForm from './ManagementCenterViewForm';
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import { DeleteButton, EditButton, ViewButton } from "../../../../components/Styled/ActionButtons";
import { StyledTableContainer } from "../../../../components/Styled/StyledComponents";
import PaginationControls from "../../../../Context/PaginationControls/PaginationControls";
import {fetchManagementCenterById, fetchAllManagementCenters, deleteManagementCenter} from "../ManagementCenterAPI";
import ConfirmDialog from "../../../../Context/ConfirmDialog/ConfirmDialog";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";
import {useError} from "../../../../Context/ErrorContext/ErrorContext";

const ManagementCenterTable = ({ managementCenters, setManagementCenters, error, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag, filterOptions }) => {
    const { handleError } = useError();
    const { openDrawer } = useDrawer();
    const showToast = useSingleToast();
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });


    const handleEdit = (managementCenterId) => {
        openDrawer({
            title: 'Edit ManagementCenter',
            component: ManagementCenterEditForm,
            props: {
                managementCenterId: managementCenterId,
                refetchManagementCenterData: refetchManagementCenterData,
                setRefreshFlag: setRefreshFlag,
            },
            key: `edit-${managementCenterId}`,
        }, 'md');
    };


    const handleView = async (managementCenter) => {

        try {
            const updatedManagementCenterData = await fetchManagementCenterById(managementCenter._id);
            openDrawer({
                title: 'View ManagementCenter',
                component: ManagementCenterViewForm,
                props: { formData: updatedManagementCenterData },
                key: `view-${managementCenter._id}`,
            }, 'md');
        } catch (error) {
            handleError("Failed to fetch latest managementCenter data.");
        }
    };


    const refetchManagementCenterData = useCallback(async (options = {}) => {
        try {
            const data = await fetchAllManagementCenters(pageNumber, 10, filterOptions, handleError);
            setManagementCenters(data.managementCenters);
            setTotalPages(Math.ceil(data.totalDocs / 10));
        } catch (error) {
            handleError(error.message || "An error occurred.");
        }
    }, [filterOptions, handleError, pageNumber, setManagementCenters, setTotalPages]);



    useEffect(() => {
        refetchManagementCenterData(filterOptions);
    }, [pageNumber, refetchManagementCenterData, filterOptions, setRefreshFlag]);


    /*if (error) {
        return <Box textAlign="center" my="4">Failed to fetch managementCenter data: {error}</Box>;
    }*/

    if (!managementCenters) {
        return (
            <Box textAlign="center" my="4" p="4" color="red.600" bg="blue.50">
                <p>Loading management centers...</p>
            </Box>
        );
    }


    if (error) {
        return (
            <Box textAlign="center" my="4" p="4" color="red.600" bg="yellow.100">
                <p>Error fetching management center data:</p>
                <code>{error.toString()}</code>
            </Box>
        );
    }



    if (dataLoaded && Array.isArray(managementCenters) && managementCenters.length === 0) {
        return <Box textAlign="center" my="4">No records found.</Box>;
    }

    const handleDelete = (managementCenterId) => {
        setConfirmDialog({
            isOpen: true,
            title: "Confirm Delete",
            message: "Are you sure you want to delete this management center? This action cannot be undone.",
            onConfirm: () => confirmDelete(managementCenterId),
        });
    };

    const confirmDelete = async (managementCenterId) => {
        try {
            await deleteManagementCenter(managementCenterId);
            showToast({
                title: "Management Center Deleted",
                description: "Successfully deleted the management center.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ isOpen: false });
            setRefreshFlag(prev => !prev);
        } catch (error) {
            showToast({
                title: "Deletion Failed",
                description: "Failed to delete the management center. It might have associated cities.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ isOpen: false });
        }
    };



    return (
        <>
            <StyledTableContainer>
                <PaginationControls
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    totalPages={totalPages}
                />
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th>Management Center Name</Th>
                            <Th>Email</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {managementCenters.map((managementCenter) => (
                            <Tr key={managementCenter._id}>
                                <Td>{managementCenter.name}</Td>
                                <Td>{managementCenter.email}</Td>
                                <Td>
                                    <ViewButton onClick={() => handleView(managementCenter)} />
                                    <EditButton onClick={() => handleEdit(managementCenter._id)} />
                                    <DeleteButton onClick={() => handleDelete(managementCenter._id)} />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </StyledTableContainer>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                title={confirmDialog.title}
                message={confirmDialog.message}
                onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
                onConfirm={confirmDialog.onConfirm}
            />
        </>
    );
};

export default ManagementCenterTable;
