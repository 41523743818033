import React from 'react'
import {Icon} from "@iconify/react";
import FaqsContainer from "./Components/FaqsContainer";
import DefaultTemplate from "../../../components/DefaultTemplate/DefaultTemplate";

const Faqs = () => {
    return (
        <>
            <DefaultTemplate Content={ <FaqsContainer/> } Title={"FAQs" } icon={<Icon icon="mdi:folder-question" />}/>
        </>
    )
}

export default Faqs
