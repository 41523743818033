import React, { useState, useEffect } from 'react';
import {
    FormControl,
    FormLabel,
    VStack,
    useToast,
    Box,
    Text, SimpleGrid, Spinner,
    Radio, RadioGroup,
    Stack, Flex, Badge
} from '@chakra-ui/react';
import FormActionLabel from "../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../components/Styled/StyledButtons";
import FieldControl from '../../../components/Styled/FieldControl';
import { useDrawer } from '../../../Context/DrawerContext/DrawerContext';
import { Formik, Form, Field } from 'formik';
import ConfirmDialog from "../../../Context/ConfirmDialog/ConfirmDialog";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import styles
import { addSurvey } from "../SurveyAPI";
import SurveyAddQuestionForm from './SurveyQuestionAddForm';
import {
    List,
    ListItem} from '@chakra-ui/react'
import { DeleteButton } from '../../../components/Styled/ActionButtons';
import { Icon } from '@iconify/react';

const SurveyAddForm = ({ refetchPollsData, pollData }) => {
    const { closeDrawer } = useDrawer();
    const [descriptionValue, setDescriptionValue] = useState('');
    const toast = useToast({ position: 'top-right' });
    const [options, setOptions] = useState([]);
    const [value, setValue] = React.useState('Published')
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [questions, setQuestions] = useState([]);

    const handleOpen = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);

    const updateQuestions = (newQuestion) => {
        setQuestions((prevQuestions) => [...prevQuestions, newQuestion]);
    };

    var [surveySchema, setSurveySchema] = useState({
        title: '',
        description: '',
        polls: [],
        published: 'Published',
        start_date: '',
        closing_date: ''
    });

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => { },
    });

    /**
     * Adds start_date and end_date fields to each object in the array.
     * @param {Array} arr - The array of objects.
     * @param {string} start_date - The start date to be added to each object.
     * @param {string} end_date - The end date to be added to each object.
     * @returns {Array} - The modified array with added date fields.
     */
    const addDates = (arr, start_date, closing_date) => {
        return arr.map(item => ({
            ...item,
            start_date: start_date,
            closing_date: closing_date,
            forSurvey: true
        }));
    };

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {

        if (values.title === null || values.title === '') {
            toast({
                title: 'Survey title is required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        if (descriptionValue === null || descriptionValue === '') {
            toast({
                title: 'Survey title is required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        if (questions === null || questions?.length === 0 || questions == []) {
            toast({
                title: 'Survey questions are required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        if (questions.length < 2) {
            toast({
                title: 'At least 2 survey questions are required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        if (values.start_date === null || values.start_date === '') {

            toast({
                title: 'Start date is required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        if (values.closing_date === null || values.closing_date === '') {

            toast({
                title: 'Closing date is required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        try {
            await addSurvey(JSON.stringify({
                "title": values.title,
                "description": descriptionValue,
                "polls": addDates(questions, values.closing_date, values.closing_date), // Add start_date and closing_date to each poll
                "status": values.published,
                "closing_date": values.closing_date,
                "start_date": values.start_date
            }));
            toast({
                title: 'Survey added successfully',
                description: `It will be available once its published`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
            resetForm();
            refetchPollsData(); // Trigger refetching of polls data
            closeDrawer();
        } catch (error) {
            if (error.response) {
                // Server error with response
                toast({
                    title: 'Error adding survey',
                    description: error.response.data.message || 'An unexpected server error occurred',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } else if (error.request) {
                // Network error without response
                toast({
                    title: 'Network Error',
                    description: 'Unable to connect to the server. Please check your internet connection.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                // Other errors
                toast({
                    title: 'Error',
                    description: error.message || 'An unexpected error occurred',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } finally {
            setSubmitting(false);
        }
    };

    const requestConfirm = (options) => {
        setConfirmDialog({
            isOpen: true,
            title: options.title,
            message: options.message,
            onConfirm: options.onConfirm,
        });
    };

    const handleConfirm = () => {
        confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleChange = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
    };

    useEffect(() => {
        if (pollData) {
            const transformedOptions = pollData.map(poll => ({
                value: poll.question,
                label: poll.question,
                id: poll._id,
            }));

            setOptions(transformedOptions);
        }
    }, [pollData]);


    const deleteOption = (index) => {
        console.log(index);
        const newOptions = [...questions];
        newOptions.splice(index, 1);
        setQuestions(newOptions);
    };

    const truncateText = (text, limit = 30) => {
        if (text.length <= limit) {
            return text;
        }
        return text.substring(0, limit - 3) + '...';
    };
    return (
        <>
            <Formik
                initialValues={surveySchema}
                onSubmit={handleSubmit}
            >
                {formikProps => (
                    <Form>
                        <VStack spacing={4} align="stretch">
                            <FormActionLabel formAction="add" formName="Survey" />
                            <SimpleGrid columns={1} gap={4} px={2} py={4}>
                                <FieldControl formikProps={formikProps} name="title" label="Title" placeholder="Enter survey title" />
                                <Box>
                                    <FormLabel htmlFor="description">Description</FormLabel>
                                    <ReactQuill
                                        theme="snow"
                                        value={descriptionValue}
                                        onChange={setDescriptionValue}
                                    />
                                </Box>


                                <RadioGroup onChange={setValue} value={value} mb={4}>
                                    <FormLabel htmlFor="description">Status</FormLabel>
                                    <Stack direction='row'>
                                        <Radio value='Published'>Published</Radio>
                                        <Radio value='Draft'>Draft</Radio>
                                        <Radio value='Closed' isDisabled>Closed</Radio>
                                    </Stack>
                                </RadioGroup>

                                <CustomAlert status="warning" message="Add questions that will appear on this survey. At least 2 questions are required." />
                                {/* <Button onClick={handleOpen}>Add Question</Button> */}

                                <SurveyAddQuestionForm
                                    updateQuestions={updateQuestions}
                                    isOpen={isModalOpen}
                                    onOpen={handleOpen}
                                    onClose={handleClose}
                                />



                                <List spacing={3}>
                                    {questions.map((q, index) => (
                                        <ListItem key={index}>
                                            <Flex>
                                                <Badge ml='1' colorScheme='green'>
                                                    <Text fontSize='lg' >{index + 1}. </Text>
                                                </Badge>
                                                <Badge variant='outline' colorScheme='green' style={{ textTransform: 'none' }}>
                                                    <Text fontSize='lg' noOfLines={[1, 2, 3]}>{truncateText(q.question, 39)}</Text>
                                                </Badge>
                                                <DeleteButton onClick={() => deleteOption(index)} />
                                            </Flex>
                                        </ListItem>
                                    ))}
                                </List>


                                <CustomButton
                                    type="button"
                                    showIcon={true}
                                    showTooltip={true}
                                    tooltipLabel="Add Option"
                                    icon={<Icon icon="ci:plus" />}
                                    onClick={handleOpen}
                                >
                                    Add a survey question
                                </CustomButton>


                                <Flex>
                                    <FormControl id="start_date" >
                                        <FormLabel>Start Date</FormLabel>
                                        <Field name="start_date">
                                            {({ form }) => (
                                                <DatePicker
                                                    selected={form.values.start_date}
                                                    onChange={(date) => {
                                                        const formattedDate = date.toLocaleDateString('en-GB');
                                                        form.setFieldValue('start_date', date);
                                                        setSurveySchema((prevSchema) => ({
                                                            ...prevSchema,
                                                            start_date: `${formattedDate}`, // Update start_date in for all polls
                                                        }));
                                                    }}
                                                    placeholderText="Select closing date"
                                                    className="custom-datepicker"
                                                    dateFormat="dd/MM/yyyy"
                                                />
                                            )}
                                        </Field>
                                        {formikProps.touched.start_date && formikProps.errors.start_date && (
                                            <Text color="red.500" fontSize="sm">
                                                {formikProps.errors.start_date}
                                            </Text>
                                        )}
                                    </FormControl>
                                    <FormControl id="closing_date" >
                                        <FormLabel>Closing Date</FormLabel>
                                        <Field name="closing_date">
                                            {({ form }) => (
                                                <DatePicker
                                                    selected={form.values.closing_date}
                                                    onChange={(date) => {
                                                        const formattedDate = date.toLocaleDateString('en-GB');
                                                        form.setFieldValue('closing_date', date);
                                                        setSurveySchema((prevSchema) => ({
                                                            ...prevSchema,
                                                            closing_date: `${formattedDate}`, // Update closing_date in for all polls
                                                        }));
                                                    }}
                                                    placeholderText="Select closing date"
                                                    className="custom-datepicker"
                                                    dateFormat="dd/MM/yyyy"
                                                />
                                            )}
                                        </Field>
                                        {formikProps.touched.closing_date && formikProps.errors.closing_date && (
                                            <Text color="red.500" fontSize="sm">
                                                {formikProps.errors.closing_date}
                                            </Text>
                                        )}
                                    </FormControl>
                                </Flex>


                            </SimpleGrid>
                            <AlertAndButtons
                                formikProps={formikProps}
                                closeDrawer={closeDrawer}
                                requestConfirm={requestConfirm}
                            />
                        </VStack>
                    </Form>
                )}
            </Formik>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

const AlertAndButtons = ({ formikProps, closeDrawer, requestConfirm }) => {

    const handleCancel = () => {
        requestConfirm({
            title: "Cancel Confirmation",
            message: "Are you sure you want to cancel? Any unsaved changes will be lost.",
            onConfirm: () => {
                formikProps.handleReset();
                closeDrawer();
            }
        });
    };

    const handleReset = () => {
        requestConfirm({
            title: "Reset Confirmation",
            message: "Are you sure you want to reset all your changes?",
            onConfirm: () => {
                formikProps.handleReset();
            }
        });
    };

    return (
        <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}>
            <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
            <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                    Cancel
                </CustomButton>
                <CustomButton onClick={handleReset} type="reset">
                    Reset
                </CustomButton>
                <CustomButton
                    type="submit"
                    disabled={formikProps.isSubmitting}
                    style={{
                        opacity: formikProps.isSubmitting ? 0.7 : 1,
                        pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                    }}
                >
                    {formikProps.isSubmitting ? (
                        <>
                            <Spinner size="xs" mr={2} />
                            Submitting...
                        </>
                    ) : 'Submit'}
                </CustomButton>
            </ButtonStack>
        </Box>
    );
};


export default SurveyAddForm;
