import React from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Switch,
  Button,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import videoService from '../../../../services/videoService';
import {useDrawer} from "../../../../Context/DrawerContext/DrawerContext"; // Adjust the import path as necessary

const VideoAddForm = ({refetchVideoData}) => {
  const toast = useToast();
  const { closeDrawer } = useDrawer();
  const generateURL = (source, videoCode) => {
    switch (source) {
      case 'YouTube':
        return `https://www.youtube.com/watch?v=${videoCode}`;
      case 'Facebook':
        return `https://www.facebook.com/watch/?v=${videoCode}`;
      default:
        return videoCode; // For 'URL', use the full code as the URL
    }
  };

  const formik = useFormik({
    initialValues: {
      title: '',
      videoCode: '', // This replaces 'url' with 'videoCode'
      source: 'YouTube',
      published: false,
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Required'),
      videoCode: Yup.string().required('Required'),
      source: Yup.string().oneOf(['Facebook', 'YouTube', 'URL']).required('Required'),
    }),
    onSubmit: async (values, actions) => {
      try {
        // Generate the URL based on the source and videoCode
        const url = generateURL(values.source, values.videoCode);

        const videoData = {
          ...values,
          url,
          thumbnail: '', // Thumbnail is blank when first added
        };

        const response = await videoService.createVideo(videoData);
        toast({
          title: 'Video Added',
          description: `The new video "${response.title}" has been added successfully.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        closeDrawer()
        refetchVideoData()
        actions.resetForm();
      } catch (error) {
        toast({
          title: 'Failed to Add Video',
          description: error.message || "An error occurred while adding the video.",
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      actions.setSubmitting(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <VStack spacing={4}>
        <FormControl id="title" isRequired>
          <FormLabel>Title</FormLabel>
          <Input
            placeholder="Enter video title"
            {...formik.getFieldProps('title')}
          />
        </FormControl>
        <FormControl id="videoCode" isRequired>
          <FormLabel>Video Code</FormLabel>
          <Input
            placeholder="Enter video code"
            {...formik.getFieldProps('videoCode')}
          />
        </FormControl>
        <FormControl id="source" isRequired>
          <FormLabel>Source</FormLabel>
          <Select {...formik.getFieldProps('source')}>
            <option value="YouTube">YouTube</option>
            <option value="Facebook">Facebook</option>
            <option value="URL">Direct URL</option>
          </Select>
        </FormControl>
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="published" mb="0">
            Published
          </FormLabel>
          <Switch id="published" {...formik.getFieldProps('published')} />
        </FormControl>
        <Button
          mt={4}
          colorScheme="blue"
          isLoading={formik.isSubmitting}
          type="submit"
        >
          Add Video
        </Button>
      </VStack>
    </form>
  );
};

export default VideoAddForm;
