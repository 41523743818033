import React, {useEffect, useRef, useState} from 'react';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    Box,
    Text,
    SimpleGrid,
    Select,
    InputGroup,
    InputRightElement,
    IconButton, Spinner
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {fetchAllManagementCenters, fetchNoticeById, updateNotice} from "../NoticesAPI";
import { Icon } from "@iconify/react";
import { ThemedStyledInput } from "../../../../components/Styled";
import CustomAlert from "../../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../../components/Styled/StyledButtons";
import FormActionLabel from "../../../../components/Styled/FormActionLabel";
import ConfirmDialog from "../../../../Context/ConfirmDialog/ConfirmDialog";
import {useDrawer} from "../../../../Context/DrawerContext/DrawerContext";
import TextEditor from "../../../../Context/TextEditor/TextEditor";
import {fetchAllCities} from "../../../LocationNetwork/Cities/CitiesAPI";
import {fetchAllAreas} from "../../../LocationNetwork/Areas/AreasAPI";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";

const NoticeEditForm = ({ noticeId, refetchNoticeData }) => {
    const notifyUserRef = useRef();
    const { closeDrawer } = useDrawer();
    const showToast = useSingleToast();
    const [cities, setCities] = useState([]);
    const [areas, setAreas] = useState([]);
    const [selectedCity, setSelectedCity] = useState();
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });
    const [initialValues, setInitialValues] = useState({
        title: '',
        area: '',
        description: '',
        city: ''
    });


    notifyUserRef.current = (options) => {
        showToast(options);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const noticeData = await fetchNoticeById(noticeId);
                const cities = await fetchAllCities(1,10000,{});
            if (noticeData) {
                    setInitialValues({
                        title: noticeData.title,
                        area: noticeData.area,
                        city: noticeData.city,
                        description: noticeData.description,
                    });
                    setCities(cities.cities);
                    setAreas(areas.areas);
                }
            } catch (error) {
                // Accessing the function directly through the ref's current property
                notifyUserRef.current({
                    title: 'Error',
                    description: 'Failed to fetch data',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };
        fetchData();
    }, [noticeId]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const { areas } = await fetchAllAreas(1,10000,{cityId:selectedCity});
                setAreas(areas);
            } catch (error) {
                setAreas([]);
            }
        };
        if(selectedCity){
            fetchData();
        }
       
    }, [selectedCity]);

    const noticeSchema = Yup.object().shape({
        title: Yup.string().required('Title is required.'),
        area: Yup.string().required('Area is required.'),
        city: Yup.string().required('City is required.'),
        description: Yup.string().required('Description is required.')
    });

    const handleSubmit = async (values, actions) => {
        try {
            await updateNotice(noticeId, values);
            showToast({
                title: 'Notice updated successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchNoticeData(); // Refresh the data
            closeDrawer();
        } catch (error) {
            showToast({
                title: 'Error',
                description: error.message || 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            actions.setSubmitting(false);
        }
    };

    const handleCancel = () => {
        setConfirmDialog({
            isOpen: true,
            title: "Cancel Confirmation",
            message: "Are you sure you want to cancel? Any unsaved changes will be lost.",
            onConfirm: () => {
                closeDrawer();
                setConfirmDialog({ ...confirmDialog, isOpen: false }); // Close dialog after confirmation
            }
        });
    };


    const handleConfirm = () => {
        confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    return (
        <>
            <FormActionLabel formAction="edit" formName="Notice" />
            <Formik
                initialValues={initialValues}
                validationSchema={noticeSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {formikProps => (
                    <Form>
                        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                            <SimpleGrid columns={1} gap={4} px={2} py={4}>
                                <FieldControl
                                    formikProps={formikProps}
                                    name="title"
                                    label="Title"
                                    placeholder="Enter Notice Title"
                                    icon={<Icon icon="mdi:rename-outline" />}
                                />

                                <GridItem>
                                    <FormControl  isInvalid={formikProps.errors.city && formikProps.touched.city}>
                                        <FormLabel htmlFor="city">City</FormLabel>
                                        <Field as={Select} name="city" placeholder="Select City"  onChange={(e) => {
                                            const selectedCity = e.target.value;
                                            setSelectedCity(selectedCity); // Update the state with selected city
                                            formikProps.setFieldValue('city', selectedCity); // Update Formik's state for city
                                            }}>
                                            {cities&&cities.map((city) => (
                                                <option key={city._id} value={city._id}>
                                                    {city.name}
                                                </option>
                                            ))}
                                        </Field>
                                        {formikProps.errors.city && formikProps.touched.city && (
                                            <Text color="red.500" fontSize="sm">{formikProps.errors.city}</Text>
                                        )}
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.area&& formikProps.touched.area}>
                                        <FormLabel htmlFor="area">Area</FormLabel>
                                        <Field as={Select} name="area" placeholder="Select Area">
                                            {areas&&areas.map((area) => (
                                                <option key={area._id} value={area._id}>
                                                    {area.name}
                                                </option>
                                            ))}
                                        </Field>
                                        {formikProps.errors.area && formikProps.touched.area && (
                                            <Text color="red.500" fontSize="sm">{formikProps.errors.area}</Text>
                                        )}
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.description && formikProps.touched.description}>
                                        <FormLabel htmlFor="description">Description</FormLabel>
                                        <TextEditor
                                            value={formikProps.values.description}
                                            onChange={(value) => formikProps.setFieldValue('description', value)}
                                        />

                                    </FormControl>
                                </GridItem>
                            </SimpleGrid>
                            <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}  mt={8} mb={8}>
                                <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
                                <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                                    <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                                        Cancel
                                    </CustomButton>
                                    <CustomButton
                                        type="submit"
                                        disabled={formikProps.isSubmitting}
                                        style={{
                                            opacity: formikProps.isSubmitting ? 0.7 : 1,
                                            pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                                        }}
                                    >
                                        {formikProps.isSubmitting ? (
                                            <>
                                                <Spinner size="xs" mr={2} />
                                                Updating...
                                            </>
                                        ) : 'Update'}
                                    </CustomButton>
                                </ButtonStack>
                            </Box>
                        </VStack>
                    </Form>
                )}
            </Formik>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

const FieldControl = ({ formikProps, name, label, placeholder, icon }) => (
    <GridItem>
        <FormControl isInvalid={formikProps.errors[name] && formikProps.touched[name]}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <InputGroup>
                <ThemedStyledInput
                    {...formikProps.getFieldProps(name)}
                    id={name}
                    placeholder={placeholder}
                />
                {icon && (
                    <InputRightElement>
                        <IconButton
                            aria-label="Icon button label"
                            bg="blue.300"
                            _hover={{ bg: '#69C5EC' }}
                            _active={{ bg: '#4699bc' }}
                            color="white"
                        >
                            {icon}
                        </IconButton>
                    </InputRightElement>
                )}
            </InputGroup>
            {formikProps.errors[name] && formikProps.touched[name] && (
                <Text color="red.500" fontSize="sm">{formikProps.errors[name]}</Text>
            )}
        </FormControl>
    </GridItem>
);




export default NoticeEditForm;
