import React from 'react';
import {
    FormControl,
    FormLabel,
    GridItem,
    Text
} from '@chakra-ui/react';
import { ThemedStyledInput } from '../Styled';

// FieldControl component
const FieldControl = ({ formikProps, name, label, placeholder,isRequired, type }) => (
    <GridItem>
        <FormControl isRequired={isRequired} isInvalid={formikProps.errors[name] && formikProps.touched[name]}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <ThemedStyledInput
                id={name}
                type={type ? type : 'text'}
                placeholder={placeholder}
                {...formikProps.getFieldProps(name)}
            />
            {formikProps.errors[name] && formikProps.touched[name] && (
                <Text color="red.500" fontSize="sm">{formikProps.errors[name]}</Text>
            )}
        </FormControl>
    </GridItem>
);

export default FieldControl;