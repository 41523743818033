import React, {useCallback, useEffect, useRef, useState} from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Box, useToast } from '@chakra-ui/react';
import BranchEditForm from './BranchEditForm';
import BranchViewForm from './BranchViewForm';
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import { DeleteButton, EditButton, ViewButton } from "../../../../components/Styled/ActionButtons";
import { StyledTableContainer } from "../../../../components/Styled/StyledComponents";
import PaginationControls from "../../../../Context/PaginationControls/PaginationControls";
import { fetchBranchById, fetchAllBranches, deleteBranch } from "../BranchesAPI";
import ConfirmDialog from "../../../../Context/ConfirmDialog/ConfirmDialog";
import {fetchAllAreas} from "../../Consultants/ConsultantsAPI";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";

const BranchTable = ({ branches, setBranches, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag, filterOptions  }) => {
    const { openDrawer } = useDrawer();
    const [areas, setAreas] = useState([]);
    const [error, setError] = useState('');
    const showToast = useSingleToast();
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    const showToastRef = useRef(showToast);


    // Update function encapsulated in useCallback to ensure it's only created once
    const updateBranchesAndPages = useCallback((newBranches, totalDocs) => {
        setBranches(newBranches);
        setTotalPages(Math.ceil(totalDocs / 10));
    }, [setBranches, setTotalPages]);

    const refetchBranchData = useCallback(async (options = {}) => {
        setError('');
        try {
            const branchesResponse = await fetchAllBranches(pageNumber, 10, filterOptions);
            const areasResponse = await fetchAllAreas();

            if (branchesResponse && branchesResponse.totalPages) {
                updateBranchesAndPages(branchesResponse.branches, branchesResponse.totalPages);
            } else {
                console.error("No totalPages found:", branchesResponse);
                setBranches([]);
                setTotalPages(0);
            }

            if (!branchesResponse || !branchesResponse.branches || !areasResponse || !areasResponse.areas) {
                throw new Error('No data received from the server');
            }

            if (areasResponse && areasResponse.managementCenters) {
                setAreas(areasResponse.managementCenters);
            } else {
                console.error("Areas data missing:", areasResponse);
                setAreas([]);
            }

            updateBranchesAndPages(branchesResponse.branches, branchesResponse.totalDocs);
            setAreas(areasResponse.areas);
        } catch (error) {
            console.error("Error fetching data:", error);
            setError(error.message || 'Failed to fetch data. Please try again later.');
            showToastRef.current({
                title: "Error fetching data",
                description: error.message || "An unexpected error occurred.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    }, [filterOptions, pageNumber, setBranches, setTotalPages, updateBranchesAndPages]);

    useEffect(() => {
        refetchBranchData();
    }, [refetchBranchData, pageNumber, filterOptions]);

    const getAreaNameById = useCallback((id) => {
        const area = areas.find(mc => mc._id === id);
        return area ? area.name : "Unknown";
    }, [areas]);


    if (error) return <Box textAlign="center" my="4">{error}</Box>;
    if (dataLoaded && !branches.length) return <Box textAlign="center" my="4">No records found.</Box>;


    const handleEdit = (branchId) => {
        openDrawer({
            title: 'Edit Branch',
            component: BranchEditForm,
            props: {
                branchId: branchId,
                refetchBranchData: refetchBranchData,
                setRefreshFlag: setRefreshFlag,
            },
            key: `edit-${branchId}`,
        }, 'lg');
    };


    const handleView = async (branch) => {
        try {
            const branchData = await fetchBranchById(branch._id);
            if (branchData) {
                openDrawer({
                    title: 'View Branch',
                    component: BranchViewForm,
                    props: { formData: branchData },
                    key: `view-${branch._id}`,
                }, 'lg');
            } else {
                throw new Error('Branch data not found.');
            }
        } catch (error) {
            setError(error.message);
            showToast({
                title: 'Error',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };


    const handleDelete = (branchId) => {
        setConfirmDialog({
            isOpen: true,
            title: "Confirm Delete",
            message: "Are you sure you want to delete this branch? This action cannot be undone.",
            onConfirm: () => confirmDelete(branchId),
        });
    };

    const confirmDelete = async (branchId) => {
        try {
            await deleteBranch(branchId);
            showToast({
                title: "Branch Deleted",
                description: "Successfully deleted the branch.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ ...confirmDialog, isOpen: false });
            refetchBranchData(filterOptions); // Refresh the list
        } catch (error) {
            showToast({
                title: "Deletion Failed",
                description: "Could not delete the branch. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ ...confirmDialog, isOpen: false });
        }
    };

    return (
        <>
            <StyledTableContainer>
                <PaginationControls pageNumber={pageNumber} setPageNumber={setPageNumber} totalPages={totalPages} />
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th>Branch</Th>
                            <Th>Area</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {branches.map(branch => (
                            <Tr key={branch._id}>
                                <Td>{branch.name}</Td>
                                <Td>{getAreaNameById(branch.areaId)}</Td>
                                <Td>
                                    <ViewButton onClick={() => handleView(branch)} />
                                    <EditButton onClick={() => handleEdit(branch._id)} />
                                    <DeleteButton onClick={() => handleDelete(branch._id)} />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </StyledTableContainer>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                title={confirmDialog.title}
                message={confirmDialog.message}
                onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
                onConfirm={confirmDialog.onConfirm}
            />
        </>
    );
};

export default BranchTable;
