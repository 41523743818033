import React, {useCallback, useEffect, useRef, useState} from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Box } from '@chakra-ui/react';
import AreaEditForm from './AreaEditForm';
import AreaViewForm from './AreaViewForm';
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import { DeleteButton, EditButton, ViewButton } from "../../../../components/Styled/ActionButtons";
import { StyledTableContainer } from "../../../../components/Styled/StyledComponents";
import PaginationControls from "../../../../Context/PaginationControls/PaginationControls";
import { fetchAreaById, fetchAllAreas, deleteArea, fetchAllCities } from "../AreasAPI";
import ConfirmDialog from "../../../../Context/ConfirmDialog/ConfirmDialog";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";

const AreaTable = ({ areas, setAreas, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag, filterOptions }) => {
    const { openDrawer } = useDrawer();
    const [cities, setCities] = useState([]);
    const [error, setError] = useState('');
    const showToast = useSingleToast();
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    const showToastRef = useRef(showToast);


    // Update function encapsulated in useCallback to ensure it's only created once
    const updateAreasAndPages = useCallback((newAreas, totalDocs) => {
        setAreas(newAreas);
        setTotalPages(Math.ceil(totalDocs / 10));
    }, [setAreas, setTotalPages]);

    const refetchAreaData = useCallback(async (options = {}) => {
        setError('');
        try {
            const areasResponse = await fetchAllAreas(pageNumber, 10, filterOptions);
            const citiesResponse = await fetchAllCities();

            if (areasResponse && areasResponse.totalPages) {
                updateAreasAndPages(areasResponse.areas, areasResponse.totalPages);
            } else {
                console.error("No totalPages found:", areasResponse);
                setAreas([]);
                setTotalPages(0);
            }

            if (!areasResponse || !areasResponse.areas || !citiesResponse || !citiesResponse.cities) {
                throw new Error('No data received from the server');
            }

            if (citiesResponse && citiesResponse.cities) {
                setCities(citiesResponse.cities);
            } else {
                console.error("Cities data missing:", citiesResponse);
                setCities([]);
            }

            updateAreasAndPages(areasResponse.areas, areasResponse.totalDocs);
            setCities(citiesResponse.cities);
        } catch (error) {
            console.error("Error fetching data:", error);
            setError(error.message || 'Failed to fetch data. Please try again later.');
            showToastRef.current({
                title: "Error fetching data",
                description: error.message || "An unexpected error occurred.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    }, [filterOptions, pageNumber, setAreas, setTotalPages, updateAreasAndPages]);

    useEffect(() => {
        refetchAreaData();
    }, [refetchAreaData, pageNumber, filterOptions]);

    const getCityNameById = useCallback((id) => {
        const center = cities.find(mc => mc._id === id);
        return center ? center.name : "Unknown";
    }, [cities]);


    if (error) return <Box textAlign="center" my="4">{error}</Box>;
    if (dataLoaded && !areas.length) return <Box textAlign="center" my="4">No records found.</Box>;


    const handleEdit = (areaId) => {
        openDrawer({
            title: 'Edit Area',
            component: AreaEditForm,
            props: {
                areaId: areaId,
                refetchAreaData: refetchAreaData,
                setRefreshFlag: setRefreshFlag,
            },
            key: `edit-${areaId}`,
        }, 'md');
    };


    const handleView = async (area) => {
        try {
            const areaData = await fetchAreaById(area._id);
            if (areaData) {
                openDrawer({
                    title: 'View Area',
                    component: AreaViewForm,
                    props: { formData: areaData },
                    key: `view-${area._id}`,
                }, 'md');
            } else {
                throw new Error('Area data not found.');
            }
        } catch (error) {
            setError(error.message);
            showToast({
                title: 'Error',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };


    const handleDelete = (areaId) => {
        setConfirmDialog({
            isOpen: true,
            title: "Confirm Delete",
            message: "Are you sure you want to delete this area? This action cannot be undone.",
            onConfirm: () => confirmDelete(areaId),
        });
    };

    const confirmDelete = async (areaId) => {
        try {
            await deleteArea(areaId);
            showToast({
                title: "Area Deleted",
                description: "Successfully deleted the area.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ ...confirmDialog, isOpen: false });
            refetchAreaData(filterOptions); // Refresh the list
        } catch (error) {
            showToast({
                title: "Deletion Failed",
                description: "Could not delete the area. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ ...confirmDialog, isOpen: false });
        }
    };

    return (
        <>
            <StyledTableContainer>
                <PaginationControls pageNumber={pageNumber} setPageNumber={setPageNumber} totalPages={totalPages} />
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th>Area</Th>
                            <Th>City</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {areas.map(area => (
                            <Tr key={area._id}>
                                <Td>{area.name}</Td>
                                <Td>{getCityNameById(area.cityId)}</Td>
                                <Td>
                                    <ViewButton onClick={() => handleView(area)} />
                                    <EditButton onClick={() => handleEdit(area._id)} />
                                    <DeleteButton onClick={() => handleDelete(area._id)} />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </StyledTableContainer>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                title={confirmDialog.title}
                message={confirmDialog.message}
                onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
                onConfirm={confirmDialog.onConfirm}
            />
        </>
    );
};

export default AreaTable;
