import styled, { css } from 'styled-components';
import { Input, Select } from '@chakra-ui/react';

const ThemedStyledInput = styled(Input)`
  ${({ theme }) => css`
    border-width: 2px;
    color: ${theme.colors.gray[500]};

    &:focus {
      border-color: ${theme.colors.blue[300]};
      box-shadow: none;
    }
  `}
`;

const ThemedStyledSelectInput = styled(Select)`
  ${({ theme }) => css`
    border-width: 2px;

    &:focus {
      border-color: ${theme.colors.blue[300]};
      box-shadow: none;
    }
  `}
`;

export { ThemedStyledInput, ThemedStyledSelectInput };
