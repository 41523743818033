import React from 'react';
import styled, { css } from 'styled-components';
import { Alert as ChakraAlert, AlertIcon, useTheme } from '@chakra-ui/react';

const StyledAlert = styled(ChakraAlert)`
    ${({ theme }) => css`
        border-radius: ${theme.radii.md};
        margin-bottom: ${theme.space[4]};
        font-size: ${theme.fontSizes.xs};
        background-color: ${theme.colors.blue[50]}; // Set the background color to blue.300
        color: black; // Assuming you want a contrasting color for text
    `}
`;

const CustomAlert = ({ status = "info", message }) => {
    const theme = useTheme(); // Use the Chakra UI theme hook to access theme values if needed

    return (
        <StyledAlert status={status} theme={theme}>
            <AlertIcon color="blue.300"/>
            {message}
        </StyledAlert>
    );
};

export default CustomAlert;
