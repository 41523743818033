import React, {useCallback, useEffect, useRef, useState} from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Box,Text, Badge } from '@chakra-ui/react';
import { useDrawer } from "../../../Context/DrawerContext/DrawerContext";
import {DeleteButton, ViewButton} from "../../../components/Styled/ActionButtons";
import { StyledTableContainer } from "../../../components/Styled/StyledComponents";
import PaginationControls from "../../../Context/PaginationControls/PaginationControls";
import {fetchAllPayments} from "../PaymentsAPI";
import ConfirmDialog from "../../../Context/ConfirmDialog/ConfirmDialog";
import useSingleToast from "../../../hooks/UseSingleToast/UseSingleToast";
// import PaymentsViewForm from "./PaymentsViewForm";
import moment from "moment/moment";


const PaymentsTable = ({ payments, setPayments, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag, filterOptions }) => {
    const { openDrawer } = useDrawer();
    const [error, setError] = useState('');
    const showToast = useSingleToast();
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    const showToastRef = useRef(showToast);


    // Update function encapsulated in useCallback to ensure it's only created once
    const updatePayments = useCallback((newPayments, totalDocs) => {
        setPayments(newPayments);
        setTotalPages(Math.ceil(totalDocs / 10));
    }, [setPayments, setTotalPages]);

    const refetchPaymentsData = useCallback(async (options = {}) => {
        setError('');
        try {

            const paymentsResponse = await fetchAllPayments(pageNumber, 10, filterOptions);
            
            if (paymentsResponse || paymentsResponse.totalPages) {
                updatePayments(paymentsResponse, paymentsResponse.totalPages || 1);
            } else {
                console.error("No totalPages found:", paymentsResponse);
                setPayments([]);
                setTotalPages(0);
            }

            if (!paymentsResponse) {
                throw new Error('No data received from the server');
            }

            updatePayments(paymentsResponse.payments, paymentsResponse.totalDocs);
        } catch (error) {
            console.error("Error fetching data:", error);
            setError(error.message || 'Failed to fetch data. Please try again later.');
            showToastRef.current({
                title: "Error fetching data",
                description: error.message || "An unexpected error occurred.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    }, [filterOptions, pageNumber, setPayments, setTotalPages, updatePayments]);

    useEffect(() => {
        refetchPaymentsData();
    }, [refetchPaymentsData, pageNumber, filterOptions]);



    if (error) return <Box textAlign="center" my="4">{error}</Box>;
    if (dataLoaded && !payments?.length) return <Box textAlign="center" my="4">No records found.</Box>;

    const handleView = async ( ) => {
        
    };

    const handleDelete = (paymentId) => {
        setConfirmDialog({
            isOpen: true,
            title: "Confirm Delete",
            message: "Are you sure you want to delete this payment report record? This action cannot be undone.",
            onConfirm: () => confirmDelete(paymentId),
        });
    };

    const confirmDelete = async (paymentId) => {
        try {
            // await deletePaymentReport (paymentId);
            showToast({
                title: "Payment report Deleted",
                description: "Successfully deleted the payment report record.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ ...confirmDialog, isOpen: false });
            refetchPaymentsData(filterOptions); // Refresh the list
        } catch (error) {
            showToast({
                title: "Deletion Failed",
                description: "Could not delete the area. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ ...confirmDialog, isOpen: false });
        }
    };

    function paymentStatus(input) {
        if (!input) {
            return <Badge colorScheme="red">Pending</Badge>;
        }
        return input?.includes('APPROVED') 
            ? <Badge colorScheme="green">Success</Badge> 
            : <Badge colorScheme="red">Pending</Badge>;
    }

    function formatToTwoDecimalPlaces(number) {
        if (typeof number !== 'number') {
            return number;
        }
        return number.toFixed(2);
    }

    return (
        <>
            <StyledTableContainer>
                <PaginationControls pageNumber={pageNumber} setPageNumber={setPageNumber} totalPages={totalPages} />
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th>REF</Th>
                            <Th>Customer Names</Th>
                            <Th>Contact Details</Th>
                            <Th>Payment Category</Th>
                            <Th>Amount</Th>
                            <Th>Status</Th>
                            <Th>Date</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {payments?.map(record => (
                            <Tr key={record._id}>
                                <Td>{record?.reference}</Td>
                                <Td>{record?.fullName}</Td>
                                <Td>
                                    <Text>{record?.phone}</Text>
                                    <Text>{record?.email}</Text>
                                </Td>
                                <Td>{record?.paymentType}</Td>
                                <Td>BWP{formatToTwoDecimalPlaces(record?.amount)}</Td>
                                <Td>{paymentStatus(record?.result)}</Td>
                                <Td>{moment(record?.createdDate).format("DD-MM-YYYY")}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </StyledTableContainer>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                title={confirmDialog.title}
                message={confirmDialog.message}
                onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
                onConfirm={confirmDialog.onConfirm}
            />
        </>
    );
};

export default PaymentsTable;
