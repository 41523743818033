const faqCategories = {
    BILLING_PAYMENT: 'Billing and Payment',
    WATER_QUALITY: 'Water Quality',
    SERVICE_ISSUES: 'Service Issues',
    CONSERVATION_TIPS: 'Conservation Tips',
    ACCOUNT_MANAGEMENT: 'Account Management',
    REGULATIONS_COMPLIANCE: 'Regulations and Compliance',
    INSTALLATION_SERVICES: 'Installation Services',
    EMERGENCY_PROCEDURES: 'Emergency Procedures',
    SUSTAINABILITY_INITIATIVES: 'Sustainability Initiatives',
    TECHNICAL_SUPPORT: 'Technical Support'
};

export default faqCategories;
