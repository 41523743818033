// videoService.js
import {API1} from '../utils/api'; // Make sure to import your API1 instance

const videoService = {
    createVideo: async (videoData) => {
        const response = await API1.post(`/videos`, videoData);
        if (!response.data) {
            throw new Error(`Error creating video: ${response.statusText}`);
        }
        return response.data;
    },

    getAllVideos: async (filter) => {
        const queryString = new URLSearchParams(filter).toString();
        const response = await API1.get(`/videos?${queryString}`);
        if (!response.data) {
            throw new Error(`Error fetching videos: ${response.statusText}`);
        }
        return response.data;
    },

    getVideoById: async (id) => {
        const response = await API1.get(`/videos/${id}`);
        if (!response.data) {
            throw new Error(`Error fetching video: ${response.statusText}`);
        }
        return response.data;
    },

    updateVideo: async (id, videoData) => {
        const response = await API1.put(`/videos/${id}`, videoData);
        if (!response.data) {
            throw new Error(`Error updating video: ${response.statusText}`);
        }
        return response.data;
    },

    deleteVideo: async (id) => {
        const response = await API1.delete(`/videos/${id}`);
        if (!response.data) {
            throw new Error(`Error deleting video: ${response.statusText}`);
        }
        return response.data;
    },
};

export default videoService;
