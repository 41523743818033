import React, { useState } from 'react';
import {
    FormControl,
    FormLabel,
    VStack,
    useToast,
    Box,
    Text, SimpleGrid, Spinner,
    Grid, GridItem,
    Flex, Image, Input, InputGroup, Select, Switch,
    IconButton, Center
} from '@chakra-ui/react';
import DropBox from "../../../components/DropBox/DropBox";
import FormActionLabel from "../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../components/Styled/StyledButtons";
import FieldControl from '../../../components/Styled/FieldControl';
import { useDrawer } from '../../../Context/DrawerContext/DrawerContext';
import { Formik, Form } from 'formik';
import ConfirmDialog from "../../../Context/ConfirmDialog/ConfirmDialog";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { updateBanner, checkImageDimensions } from "../BannerAPI";
import SectionHeading from "../../../components/SectionHeading/SectionHeading";
import utils from "../../../utils/commonFunctions";
import { CloseIcon } from '@chakra-ui/icons';
import { StyledTooltip } from "../../../components/Styled/StyledComponents";

const BannerEditForm = ({ refetchBannersData, bannerData }) => {
    const { closeDrawer } = useDrawer();
    const toast = useToast({ position: 'top-right' });
    const [loading, setLoading] = useState(false);
    const bannerSchema = {bannerData};
    const dimensions={
        Top:{w:1600,h:300},
        Slide:{w:1600,h:450},
        Bottom:{w:1600,h:300}
    }
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => { },
    });

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        if (!values.imageKey) {
            toast({
                title: 'Banner image is required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }
        if(!values.hasText){
            values.description=""
        }
        try {
            await updateBanner(values._id,JSON.stringify(values));
            toast({
                title: 'Banner edited successfully',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
            resetForm();
            refetchBannersData();
            closeDrawer();
        } catch (error) {
            if (error.response) {
                toast({
                    title: 'Error editing banner',
                    description: error.response.data.message || 'An unexpected server error occurred',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } else if (error.request) {
                toast({
                    title: 'Network Error',
                    description: 'Unable to connect to the server. Please check your internet connection.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: 'Error',
                    description: error.message || 'An unexpected error occurred',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } finally {
            setSubmitting(false);
        }
    };

    const requestConfirm = (options) => {
        setConfirmDialog({
            isOpen: true,
            title: options.title,
            message: options.message,
            onConfirm: options.onConfirm,
        });
    };

    const handleConfirm = () => {
        confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const isValidFileType = (file) => {
        const validImageTypes = ['image/jpeg', 'image/png'];
        if (validImageTypes.includes(file.type)) {
            return {
                valid: true,
                type: 'image'
            };
        }
        return {
            valid: false,
            type: 'invalid'
        };
    };

    const handleFileChange = async (fieldName, banner,setFieldValue, formikProps) => {
        const file = banner.target.files[0];
        let pos=formikProps.values.position
        let valid = await checkImageDimensions(file, dimensions[pos]?.w, dimensions[pos]?.h);
        if (!valid) {
            setLoading(true);
            toast.closeAll()
            toast({
                title: 'File Dimensions incorrect',
                description: `Required Banner Dimensions
                 ${dimensions[pos]?.w}px *
                ${dimensions[pos]?.h}px`,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            setLoading(false);
            return;
        }
        if (file) {
            setLoading(true);
            try {
                if (file.size > 2097152) {
                    toast({
                        title: 'File size too large',
                        description: 'Please upload files less than 2MB in size.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                    setLoading(false);
                    return;
                }

                const validFileType = isValidFileType(file);
                if (!validFileType.valid) {
                    toast({
                        title: 'Invalid file type',
                        description: 'Please upload images only.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                    setLoading(false);
                    return;
                }

                const uploadResponse = await utils.handleFileUpload(file, `banners/${validFileType.type}/${Date.now()}`);
                if (uploadResponse && uploadResponse.data && uploadResponse.data.fileUrl) {
                    if (validFileType.type === 'image') {
                        setFieldValue(`imageKey`, uploadResponse.data.fileUrl);
                        toast({
                            title: 'File uploaded successfully',
                            description: 'Your file has been uploaded.',
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                        });
                    } else {
                        toast({
                            title: 'File upload failed',
                            description: 'Only images allowed.',
                            status: 'danger',
                            duration: 5000,
                            isClosable: true,
                        });
                    }
                } else {
                    setFieldValue(`files.${fieldName}.error`, 'Upload failed. Incomplete response received.');
                    toast({
                        title: 'File upload failed',
                        description: 'An error occurred while uploading the file. Please try again.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                toast({
                    title: 'File upload error',
                    description: error.message || 'An unexpected error occurred while uploading the file.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                setFieldValue(`files.${fieldName}.error`, 'Upload failed: ' + error.message);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleDelete = (setFieldValue) => {
        setFieldValue('imageKey', "")
    };

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={bannerSchema.bannerData}
                onSubmit={handleSubmit}
            >
                {formikProps => (
                    <Form>
                        <VStack spacing={4} align="stretch">
                            <FormActionLabel formAction="edit" formName="Banner" />
                            <SimpleGrid columns={1} gap={4} px={2} py={4}>
                                <FormControl isRequired>
                                    <FormLabel>Banner Position</FormLabel>
                                    <Select
                                        name="position"
                                        placeholder="Select Banner Position"
                                        value={formikProps.values.position}
                                        onChange={formikProps.handleChange}
                                        onBlur={formikProps.handleBlur}
                                    >
                                        <option value="Top">Top</option>
                                        <option value="Bottom">Bottom</option>
                                        <option value="Slide">Slide</option>
                                    </Select>
                                </FormControl>

                                <FieldControl isRequired={true} formikProps={formikProps} name="title" label="Title" placeholder="Enter banner title" />
                                {formikProps.values.position==="Slide"&&
                                    <>
                                        <StyledTooltip label={'Select to add caption,this would create text overlay over banner image'}>
                                            <GridItem justify={'end'}>
                                                <Flex mb={-2} mt={4}>
                                                    <FormControl display="flex" alignItems="center" justifyContent={'end'}>
                                                        <FormLabel htmlFor="isFormPage" mb="0" fontWeight={'normal'}>Add Banner Caption</FormLabel>
                                                        <Switch
                                                            id="hasCaption"
                                                            isChecked={formikProps.values.hasText}
                                                            onChange={(e) => formikProps.setFieldValue('hasText', e.target.checked)}
                                                        />
                                                    </FormControl>
                                                </Flex>
                                            </GridItem>
                                        </StyledTooltip>
                                    </>}
                                {formikProps.values.hasText && (
                                    <Box>
                                        <FormLabel htmlFor="description">Caption</FormLabel>
                                        <ReactQuill
                                            theme="snow"
                                            value={formikProps.values.description}
                                            onChange={(value) => {
                                                formikProps.setFieldValue('description', value);
                                            }}
                                        />
                                    </Box>
                                )}

                                {formikProps.values.position && (
                                    <>
                                        <SectionHeading icon="mdi:account-file-text" text="Upload Banner Image" />
                                        <Grid templateColumns="repeat(12, 1fr)" gap={1} color={"blue.300"} px={4}>
                                            <GridItem colSpan={{ base: 12, md: 12 }}>
                                                <DropBox
                                                    label={"Banner Image"}
                                                    name="images"
                                                    loading={loading}
                                                    formikProps={formikProps}
                                                    callback={(banner) => handleFileChange('imageKey', banner, formikProps.setFieldValue, formikProps)}
                                                    instruction={`
                                                    Please attach images only png, jpeg.
                                                    Max size 5MB.
                                                    Required Banner Dimensions
                                                    ${dimensions[formikProps.values.position].w}px *                   
                                                    ${dimensions[formikProps.values.position].h}px
                                                    `}
                                                />
                                            </GridItem>
                                        </Grid>
                                        <CustomAlert status="info" message="This section is for banner images." />
                                        {formikProps.values.imageKey &&
                                            <Box position="relative" mr="4" bg={'gray.100'} p={3} shadow={'md'} rounded={'md'}>
                                                <Center>
                                                    <Image src={formikProps.values.imageKey} alt={`Image 1`} boxSize="contain" p={8} />
                                                </Center>
                                                <IconButton
                                                    icon={<CloseIcon />}
                                                    size="sm"
                                                    aria-label="Delete image"
                                                    position="absolute"
                                                    bg={'tomato'}
                                                    top="2"
                                                    right="2"
                                                    onClick={() => handleDelete(formikProps.setFieldValue)}
                                                />
                                            </Box>
                                        }
                                        {!formikProps.values.imageKey && (
                                            <Center>
                                                <Text color="gray.500" fontSize="sm" mt={2}>No image uploaded</Text>
                                            </Center>
                                        )}
                                    </>
                                )}
                            </SimpleGrid>
                            <AlertAndButtons
                                formikProps={formikProps}
                                closeDrawer={closeDrawer}
                                requestConfirm={requestConfirm}
                            />
                        </VStack>
                    </Form>
                )}
            </Formik>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

const AlertAndButtons = ({ formikProps, closeDrawer, requestConfirm }) => {

    const handleCancel = () => {
        requestConfirm({
            title: "Cancel Confirmation",
            message: "Are you sure you want to cancel? Any unsaved changes will be lost.",
            onConfirm: () => {
                formikProps.handleReset();
                closeDrawer();
            }
        });
    };

    const handleReset = () => {
        requestConfirm({
            title: "Reset Confirmation",
            message: "Are you sure you want to reset all your changes?",
            onConfirm: () => {
                formikProps.handleReset();
            }
        });
    };

    return (
        <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}>
            <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
            <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                    Cancel
                </CustomButton>
                <CustomButton onClick={handleReset} type="reset">
                    Reset
                </CustomButton>
                <CustomButton
                    type="submit"
                    disabled={formikProps.isSubmitting}
                    style={{
                        opacity: formikProps.isSubmitting ? 0.7 : 1,
                        pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                    }}
                >
                    {formikProps.isSubmitting ? (
                        <>
                            <Spinner size="xs" mr={2} />
                            Submitting...
                        </>
                    ) : 'Submit'}
                </CustomButton>
            </ButtonStack>
        </Box>
    );
};

export default BannerEditForm;
