import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Image,
  VStack,
  useToast,
  Button as ChakraButton
} from '@chakra-ui/react';
import photoService from '../../../../../services/photoService'; // Adjust the import path as necessary
import { ButtonStack, CustomButton } from "../../../../../components/Styled/StyledButtons";
import { useDrawer } from '../../../../../Context/DrawerContext/DrawerContext'; // Assuming you have a context to manage drawers

const AlbumViewForm = ({ albumId }) => {
    const toast = useToast();
    const { closeDrawer } = useDrawer();
    const [album, setAlbum] = useState({
        title: '',
        description: '',
        cover: ''
    });

    useEffect(() => {
        const fetchAlbumDetails = async () => {
            try {
                const data = await photoService.getAlbumById(albumId);
                setAlbum(data);
            } catch (error) {
                toast({
                    title: 'Error fetching album details',
                    description: error.message || "An error occurred while fetching the album details.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                });
            }
        };

        fetchAlbumDetails();
    }, [albumId, toast]);

    return (
        <VStack spacing={4} align="stretch">
              {album.cover && (
                <Box>
                    <FormLabel>Cover Image</FormLabel>
                    <Image src={album?.cover} alt="Album Cover" boxSize="250px" objectFit="cover" />
                </Box>
            )}
            <FormControl>
                <FormLabel htmlFor='title'>Album Title</FormLabel>
                <Input id='title' value={album?.title || ''} isReadOnly placeholder='Album title' />
            </FormControl>
            <FormControl>
                <FormLabel htmlFor='description'>Description</FormLabel>
                <Input id='description' value={album?.description || ''} isReadOnly placeholder='Album description' />
            </FormControl>
          
            <Box>
                <CustomButton showIcon={false} onClick={() => closeDrawer()}>Close</CustomButton>
            </Box>
        </VStack>
    );
};

export default AlbumViewForm;
