import {
    FaChartLine,
    FaAd,
    FaTree,
    FaClipboardList,
    FaInfoCircle,
    FaUserShield,
    FaBuilding,
    FaCity,
    FaUser,
    FaCreditCard,
    FaChartPie,
    FaNewspaper,
    FaQuestionCircle,
    FaProjectDiagram,
    FaUserFriends,
    FaMapMarkedAlt,
    FaSitemap,
    FaWater,
    FaFaucet,
    FaPoll,
    FaBriefcase,
    FaTintSlash,
    FaFlask,
    FaFolderOpen,
    FaUpload, FaVideo, FaImage, FaExclamationTriangle
} from "react-icons/fa";
import {IoIosArrowDown} from "react-icons/io";
import {FaBell} from "react-icons/fa6";
import {MdEvent} from "react-icons/md";



const FeatureSections = [
    {
        key: 'dashboard',
        title: 'Dashboard',
        icon: IoIosArrowDown,
        permissionsRequired: ['Advertising Banner', 'Database Backup', 'Trail Log'],
        links: [
            {
                title: 'Analytics',
                description: 'Monitor key metrics to make data-driven decisions.',
                icon: FaChartLine,
                permissionsRequired: ['Database Backup'],
            },
            {
                title: 'Leak Reports',
                description: 'Review and resolve customer-reported water leaks.',
                icon: FaExclamationTriangle,
                link: '/leak-reports',
                permissionsRequired: ['Database Backup'],
            },
            {
                title: 'Water Shortage Reports',
                description: 'Manage water shortages efficiently.',
                icon:  FaWater,
                link:'/shortage-reports',
                permissionsRequired: ['Database Backup'],
            },
            {
                title: 'Advertising Banner',
                description: 'Manage banners to maximize your advertising revenue.',
                icon: FaAd,
                link: '/banners',
                permissionsRequired: ['Advertising Banner'],
            },
            {
                title: 'Trail Log',
                description: 'Keep a log of all the trails for auditing purposes.',
                icon: FaTree,
                permissionsRequired: ['Trail Log'],
            },
        ],
    },
    {
        key: 'primaryFeatures',
        title: 'Primary Features',
        icon: IoIosArrowDown,
        permissionsRequired: ['Panels Arrangement', 'Modules Arrangement', 'Text Management', 'Content Versions', 'Survey', 'Notices', 'Online Polls', 'Water-shortage-report'],
        links: [
            {
                title: 'User & Access Control',
                description: 'Admin user roles, profile, permissions and contact details.',
                icon: FaUserShield,
                link: '/admin-users',
                permissionsRequired: ['Customer'],
            },
            {
                title: 'Content & Information Management',
                description: 'Manage informational pages and content versions.',
                icon: FaInfoCircle,
                link: '/pages',
                permissionsRequired: ['Content Versions'],
            },
            {
                title: 'Notices',
                description: 'Manage latest updates and important announcements.',
                icon: FaBell,
                link: '/notices',
                permissionsRequired: ['Notices'],
            },
            {
                title: 'Survey',
                description: 'Conduct surveys to gather valuable customer insights.',
                icon: FaClipboardList,
                link: '/surveys',
                permissionsRequired: ['Survey'],
            },
            {
                title: 'Polls',
                description: 'Create polls and publish polls to gather insights.',
                icon: FaPoll,
                link: '/polls',
                permissionsRequired: ['Online Polls'],
            },

        ],
    },
    {
        key: 'customerRelated',
        title: 'Customer Related',
        icon: IoIosArrowDown,
        permissionsRequired: ['Customer', 'Online Payments', 'Connection Applications', 'User Statistics'],
        links: [
            {
                title: 'Customers',
                description: 'Maintain a database of your customers.',
                icon: FaUser,
                link: '/customers',
                permissionsRequired: ['Customer'],
            },
            {
                title: 'Online Payments',
                description: 'Handle and track online payments.',
                icon: FaCreditCard,
                link: '/payments',
                permissionsRequired: ['Online Payments'],
            },
            {
                title: 'Connection Applications',
                description: 'Manage customer applications for new connections.',
                icon: FaFaucet,
                link: '/individual-connection',
                permissionsRequired: ['Connection Applications'],
            },
            {
                title: 'Disconnection Requests',
                description: 'Manage customers planning to move out.',
                icon: FaTintSlash,
                link: '/disconnection-requests',
                permissionsRequired: ['Connection Applications'],
            },
            {
                title: 'Laboratory Services Products',
                description: 'Manage a catalog of laboratory products.',
                icon: FaFlask,
                link: '/lab-services-products',
                permissionsRequired: ['Connection Applications'],
            },
            {
                title: 'Laboratory Services Quotation Requests',
                description: 'Oversee quotation requests for laboratory services.',
                icon: FaClipboardList,
                link: '/lab-services-quotation-requests',
                permissionsRequired: ['Connection Applications'],
            },
            {
                title: 'User Statistics',
                description: 'Analyze user behavior and statistics.',
                icon: FaChartPie,
                link: '#',
                permissionsRequired: ['User Statistics'],
            },
        ],
    },
    {
        key: 'locationsNetwork',
        title: 'Location Network',
        icon: IoIosArrowDown,
        permissionsRequired: ['Management-center', 'Branches', 'Cities List', 'Areas List', 'Customer-care-consultants'],
        links: [
            {
                title: 'Management Center',
                description: 'Manage centers.',
                icon: FaBuilding,
                link: '/management-centers',
                permissionsRequired: ['Management-center'],
            },
            {
                title: 'City Management',
                description: 'Manage cities.',
                icon: FaCity,
                link: '/cities',
                permissionsRequired: ['Cities List'],
            },
            {
                title: 'Area Management',
                description: 'Manage areas.',
                icon: FaMapMarkedAlt,
                link: '/areas',
                permissionsRequired: ['Areas List'],
            },
            {
                title: 'Customer Care Consultants',
                description: 'Support customers.',
                icon: FaUserFriends,
                link: '/consultants',
                permissionsRequired: ['Customer-care-consultants'],
            },
            {
                title: 'Branch Management',
                description: 'Manage branches.',
                icon: FaSitemap,
                link: '/branches',
                permissionsRequired: ['Branches'],
            },
            {
                title: 'Group Email Management',
                description: 'Manage Group User Emails Easily.',
                icon: FaSitemap,
                link: '/group-emails',
                permissionsRequired: ['Branches'],
            },
        ],
    },
    {
        key: 'galleries',
        title: 'Galleries',
        icon: IoIosArrowDown,
        permissionsRequired: ['Pictures', 'Videos', 'Documents', 'News', 'Events', 'Online Polls', 'Projects', 'Tenders', 'FAQ'],
        links: [
            {
                title: 'Tender Management',
                description: 'Monitor tenders and review applications.',
                icon: FaBriefcase,
                permissionsRequired: ['Tenders'],
                link: '/tenders',
            },
            {
                title: 'Publication Category Management',
                description: 'Administrate categories for publications.',
                link: '/publication-categories',
                icon: FaFolderOpen,
                permissionsRequired: ['Documents'],
            },
            {
                title: 'Publications Management',
                description: 'Create, edit, and manage publications.',
                link: '/publications',
                icon: FaUpload,
                permissionsRequired: ['Documents'],
            },
            {
                title: 'Image Gallery',
                description: 'Curate your photo gallery with ease.',
                link: '/albums',
                icon: FaImage,
                permissionsRequired: ['Pictures'],
            },
            {
                title: 'Video Gallery',
                description: 'Add, update, and delete video content',
                link: '/videos',
                icon: FaVideo,
                permissionsRequired: ['Videos'],
            },
            {
                title: 'Events',
                description: 'Manage events with ease.',
                link: '/events',
                icon: MdEvent,
                permissionsRequired: ['Events'],
            },
            {
                title: 'News',
                description: 'Publish news and manage new content.',
                link: '/news',
                icon: FaNewspaper,
                permissionsRequired: ['News'],
            },
            {
                title: 'Projects Management',
                description: 'Monitor projects and review project progress.',
                icon: FaProjectDiagram,
                permissionsRequired: ['Projects'],
                link: '/projects',
            },
            {
                title: 'FAQs',
                description: 'Update FAQ entries to maintain accuracy and relevance.',
                icon: FaQuestionCircle,
                link: '/faqs',
                permissionsRequired: ['FAQ'],
            },
        ],
    },

];

export default FeatureSections;
