import React, {useEffect, useRef, useState} from 'react';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    Box,
    Text, Select,
    SimpleGrid, InputRightElement, InputGroup, IconButton, Spinner,
} from '@chakra-ui/react';
import { ThemedStyledInput } from "../../../components/Styled";
import FormActionLabel from "../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../components/Styled/StyledButtons";
import { useDrawer } from '../../../Context/DrawerContext/DrawerContext';
import { fetchGroupEmailManagementById, updateGroupEmailManagement } from "../GroupEmailManagementAPI";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ConfirmDialog from "../../../Context/ConfirmDialog/ConfirmDialog";
import {Icon} from "@iconify/react";
import useSingleToast from "../../../hooks/UseSingleToast/UseSingleToast";
import { GroupEmails } from './GroupEmails';

const GroupEmailManagementEditForm = ({ groupEmailId, refetchGroupEmailManagementData }) => {
    const { closeDrawer } = useDrawer();
    const showToast = useSingleToast();
    const [initialValues, setInitialValues] = useState({
        _id: '',
        name: '',
        email: '',
        category: ''
    });
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    const hasFetched = useRef(false);

    useEffect(() => {
        const fetchAndSetGroupEmailData = async () => {
            if (hasFetched.current) {
                return;
            }

            try {
                const groupEmailData = await fetchGroupEmailManagementById(groupEmailId);
                if (groupEmailData) {
                    console.log(groupEmailData)
                    setInitialValues({
                        _id: groupEmailData._id,
                        name: groupEmailData.name,
                        email: groupEmailData.email,
                        category: groupEmailData.category
                    });
                }
                hasFetched.current = true;
            } catch (error) {
                showToast({
                    title: 'Error fetching email data',
                    description: error.message || 'An unexpected error occurred.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };

        fetchAndSetGroupEmailData();
    }, [groupEmailId, showToast]);



    const groupEmailSchema = Yup.object().shape({
        name: Yup.string().required('User name is required.'),
        category: Yup.string().required('User email group is required.'),
        email: Yup.string().email('Invalid email address').required('Email is required.'),
    });

    const handleSubmit = async (values, actions) => {
        try {
            await updateGroupEmailManagement(groupEmailId, values);
            showToast({
                title: 'Email updated successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchGroupEmailManagementData(); // Trigger refetching of management center data
            closeDrawer();
        } catch (error) {
            showToast({
                title: 'Error updating email',
                description: error.message || 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            actions.setSubmitting(false);
        }
    };


    const handleCancel = () => {
        setConfirmDialog({
            isOpen: true,
            title: "Cancel Confirmation",
            message: "Are you sure you want to cancel? Any unsaved changes will be lost.",
            onConfirm: () => {
                closeDrawer();
                setConfirmDialog({ ...confirmDialog, isOpen: false }); // Close dialog after confirmation
            }
        });
    };


    const handleConfirm = () => {
        confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };


    return (
        <>
            <FormActionLabel formAction="edit" formName="Group Email Management" />
            <Formik
                initialValues={initialValues}
                validationSchema={groupEmailSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {formikProps => (
                    <Form>
                        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                            <SimpleGrid columns={1} gap={4} px={2} py={4}>
                                <FieldControl
                                    formikProps={formikProps}
                                    name="name"
                                    label="User's FullName"
                                    placeholder="Enter user's full Name"
                                    icon={<Icon icon="mdi:rename-outline" />}
                                />
                                <FieldControl
                                    formikProps={formikProps}
                                    name="category"
                                    label="Category"
                                    placeholder="Select Group"
                                    component="select"
                                    options={GroupEmails}
                                />
                                <FieldControl
                                    formikProps={formikProps}
                                    name="email"
                                    label="Email"
                                    placeholder="Enter Email"
                                    icon={<Icon icon="ic:outline-email" />}
                                />
                            </SimpleGrid>
                            <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}  mt={8} mb={8}>
                                <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
                                <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                                    <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                                        Cancel
                                    </CustomButton>
                                    <CustomButton
                                        type="submit"
                                        disabled={formikProps.isSubmitting}
                                        style={{
                                            opacity: formikProps.isSubmitting ? 0.7 : 1,
                                            pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                                        }}
                                    >
                                        {formikProps.isSubmitting ? (
                                            <>
                                                <Spinner size="xs" mr={2} />
                                                Updating...
                                            </>
                                        ) : 'Update'}
                                    </CustomButton>
                                </ButtonStack>
                            </Box>
                        </VStack>
                    </Form>
                )}
            </Formik>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

const FieldControl = ({ formikProps, name, label, placeholder, component = "input", options = [], icon }) => (
    <GridItem>
        <FormControl isInvalid={formikProps.errors[name] && formikProps.touched[name]}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <InputGroup>
                {component === "select" ? (
                    <Field as={Select} id={name} name={name} placeholder={placeholder}>
                        {options.map(option => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </Field>
                ) : (
                    <ThemedStyledInput
                        {...formikProps.getFieldProps(name)}
                        id={name}
                        placeholder={placeholder}
                    />
                )}
                {icon && (
                    <InputRightElement>
                        <IconButton
                            aria-label="Icon button label"
                            bg="blue.300"
                            _hover={{ bg: '#69C5EC' }}
                            _active={{ bg: '#4699bc' }}
                            color="white"

                        >
                            {icon}
                        </IconButton>
                    </InputRightElement>

                )}
            </InputGroup>
            {formikProps.errors[name] && formikProps.touched[name] && (
                <Text color="red.500" fontSize="sm">{formikProps.errors[name]}</Text>
            )}
        </FormControl>
    </GridItem>
);


export default GroupEmailManagementEditForm;
