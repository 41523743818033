import React from 'react'
import DefaultTemplate from '../../../components/DefaultTemplate/DefaultTemplate'
import AdminUserContainer from './Components/AdminContainer'
import {Icon} from "@iconify/react";

const Admin = () => {
  return (
    <>
        <DefaultTemplate Content={ <AdminUserContainer/> } Title={"Admin Users" } icon={<Icon icon="fa6-solid:users-gear" />}/>
    </>
  )
}

export default Admin
