import React from 'react'
import DefaultTemplate from '../../components/DefaultTemplate/DefaultTemplate'
import PaymentsContainer from './Components/PaymentsContainer'
import {Icon} from "@iconify/react";
import {FaRegCreditCard} from "react-icons/fa";

const Payments = () => {
    return (
        <>
            <DefaultTemplate Content={ <PaymentsContainer/> } Title={"Online Payments" } icon={<Icon icon={FaRegCreditCard} />}/>
        </>
    )
}

export default Payments
