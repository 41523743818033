import React from 'react';
import CustomerTable from './CustomerTable';
import CustomerTableTopBar from './CustomerTableTopBar';

const CustomerContainer = () => {
    return (
        <>
            <CustomerTableTopBar/>
            <CustomerTable/>
        </>
    );
};

export default CustomerContainer;
