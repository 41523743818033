import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from '@chakra-ui/react';
import { StyledIconButton, StyledTooltip } from './StyledComponents';
import { AiFillEdit, AiOutlineEye, AiFillSetting } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import {FaBell, FaBellSlash} from "react-icons/fa6";
import Icon from "../../@core/components/icon";

// Adjusted Styled Button component with dynamic styling for reduced size
const ActionButton = styled(Button)`
  ${({ variant, theme }) => {
    // Define styles for each variant using CSS template literals
    const variantStyles = {
        solid: css`
        background-color: ${theme.colors.blue[300]};
        color: white;
        &:hover {
          background-color: ${theme.colors.blue[500]};
        }
      `,
        ghost: css`
        background-color: white;
        color: ${theme.colors.gray[500]};
        &:hover {
          color: ${theme.colors.gray[800]};
        }
      `,
        view: css`
        background-color: ${theme.colors.blue[300]};
        color: white;
        &:hover {
          background-color: ${theme.colors.blue[300]};
        }
      `,
        revision: css`
        background-color: ${theme.colors.purple[300]};
        color: white;
        &:hover {
          background-color: ${theme.colors.purple[100]};
        }
      `,
        publish: css`
            background-color: ${theme.colors.orange[300]};
            color: white;
            &:hover {
                background-color: ${theme.colors.orange[100]};
            }
        `,
        edit: css`
        background-color: ${theme.colors.green[300]};
        color: white;
        &:hover {
          background-color: ${theme.colors.green[600]};
        }
      `,
        delete: css`
        background-color: transparent;
        color: ${theme.colors.red[600]};
        border: 1px solid ${theme.colors.red[600]};
        &:hover {
          background-color: ${theme.colors.red[50]};
          color: ${theme.colors.red[600]};
        }
      `,
        deactivate: css`
        background-color: ${theme.colors.red[300]};
        color: white;
        border: 1px solid ${theme.colors.red[600]};
        &:hover {
          background-color: ${theme.colors.red[100]};
          color: ${theme.colors.red[600]};
        }
       `,
    };

    return css`
      ${variantStyles[variant] || ''};
      margin: 0 6px;
      font-size: 14px !important;
    `;
}}
`;

/// Specific action buttons with tooltips
const ViewButton = ({ onClick }) => (
    <StyledTooltip label="View" placement="bottom">
        <ActionButton
            as={StyledIconButton}
            icon={<AiOutlineEye />}
            aria-label="View"
            onClick={onClick}
            variant="view"
            style={{ minWidth: '32px', height: '32px', padding: '0' }}
        />
    </StyledTooltip>
);

const EditButton = ({ onClick,disabled }) => (
    <StyledTooltip label="Edit" placement="bottom">
        <ActionButton
            as={StyledIconButton}
            icon={<AiFillEdit />}
            aria-label="Edit"
            onClick={onClick}
            variant="edit"
            style={{ minWidth: '32px', height: '32px', padding: '0' }}
            disabled={disabled||false}
            cursor={disabled?'not-allowed':'pointer'}
        />
    </StyledTooltip>
);


const RevisionButton = ({ onClick }) => (
  <StyledTooltip label="Revisions" placement="bottom">
      <ActionButton
          as={StyledIconButton}
          icon={<AiFillSetting />}
          aria-label="Edit"
          onClick={onClick}
          variant="revision"
          style={{ minWidth: '32px', height: '32px', padding: '0' }}
      />
  </StyledTooltip>
);
const PublishButton = ({ onClick,published }) => (
    <StyledTooltip label="Publish" placement="bottom">
        <ActionButton
            as={StyledIconButton}
            icon={!published?<FaBell/>:<FaBellSlash/>}
            aria-label="Publish"
            onClick={onClick}
            variant="publish"
            style={{ minWidth: '32px', height: '32px', padding: '0' }}
        />
    </StyledTooltip>
);

const DeleteButton = ({ onClick }) => (
    <StyledTooltip label="Delete" placement="bottom">
        <ActionButton
            as={StyledIconButton}
            icon={<BsFillTrashFill />}
            aria-label="Delete"
            onClick={onClick}
            variant="delete"
            style={{ minWidth: '32px', height: '32px', padding: '0' }}
        />
    </StyledTooltip>
);

const UpdateKYC = ({ onClick }) => (
    <StyledTooltip label="Update KYC" placement="bottom">
        <ActionButton
            as={StyledIconButton}
            icon={<Icon icon="grommet-icons:document-update" />}
            aria-label="Update KYC"
            onClick={onClick}
            variant="solid"
            style={{
                minWidth: '32px', height: '32px', padding: '0',
                backgroundColor: 'orange',
                color: '#ffffff',
                borderColor: '#0056b3'
            }}
        />
    </StyledTooltip>
);

const UpdateApplication = ({ onClick }) => (
    <StyledTooltip label="Update Application" placement="bottom">
        <ActionButton
            as={StyledIconButton}
            icon={<Icon icon="grommet-icons:document-update" />}
            aria-label="Update Application"
            onClick={onClick}
            variant="solid"
            style={{
                minWidth: '32px', height: '32px', padding: '0',
                backgroundColor: 'orange',
                color: '#ffffff',
                borderColor: '#0056b3'
            }}
        />
    </StyledTooltip>
);

const Purchases = ({ onClick }) => (
    <StyledTooltip label="Tender Purchases" placement="bottom">
        <ActionButton
            as={StyledIconButton}
            icon={<Icon icon="grommet-icons:document-update" />}
            aria-label="Tender Purchases"
            onClick={onClick}
            variant="solid"
            style={{
                minWidth: '32px', height: '32px', padding: '0',
                backgroundColor: 'orange',
                color: '#ffffff',
                borderColor: '#0056b3'
            }}
        />
    </StyledTooltip>
);

const DeactivateAdminButton = ({ onClick }) => (
    <StyledTooltip label="Deactivate Admin" placement="bottom">
        <ActionButton
            as={StyledIconButton}
            icon={<Icon icon="ic:baseline-admin-panel-settings" />}
            aria-label="Deactivate Admin"
            onClick={onClick}
            variant="delete"
            style={{ minWidth: '32px', height: '32px', padding: '0' }}
        />
    </StyledTooltip>
);

const UploadImagesButton = ({ onClick }) => (
    <StyledTooltip label="Upload Images" placement="bottom">
        <ActionButton
            as={StyledIconButton}
            icon={<Icon icon="mdi:cloud-upload-outline" />}
            aria-label="Upload Images"
            onClick={onClick}
            variant="solid"
            style={{
                minWidth: '32px', height: '32px', padding: '0',
                backgroundColor: 'orange',
                color: '#ffffff',
                borderColor: '#0056b3'
            }}
        />
    </StyledTooltip>
);


export { ViewButton, EditButton, DeleteButton,RevisionButton, UpdateKYC, UpdateApplication, DeactivateAdminButton,PublishButton, Purchases, UploadImagesButton };

