import React from 'react';
import {
  Box,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
} from '@chakra-ui/react';


const ImageModal = ({ image,isOpen,onClose }) => {


  return (
      <>
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{image?.title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box
                  flex="1"
                  bg="gray.200"
                  p={4}
                  width={{ base: '100%', md: '100%' }}
                  height={{ base: '200px', md: '300px' }}
                  overflow="hidden"
              >
                {image && (
                    <Image
                        objectFit="fit"
                        src={image.imageKey}
                        alt={`Slide`}
                        borderRadius="md"
                        width="100%"
                        height={{ base: '200px', md: '300px' }}
                    />
                )}
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
  );
};

export default ImageModal;
