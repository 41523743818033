import React from 'react';
import { Flex, GridItem,Input, Select } from '@chakra-ui/react';
import FAQAddForm from './FAQAddForm';
import { StyledGrid } from "../../../components/Styled/StyledComponents";
import { ButtonStack, CustomButton } from "../../../components/Styled/StyledButtons";
import { useDrawer } from "../../../Context/DrawerContext/DrawerContext";
import { Icon } from "@iconify/react";

const FAQTableTopBar = ({ refetchData, setFilteredFAQs,setSelectedCategory, faqs, categories , searchQuery, setSearchQuery}) => {
    const { openDrawer } = useDrawer();
    let selectedCategory = 'All'
    const handlePublishedChange = (event) => {
        refetchData(event.target.value === 'true');
         
    };
    const handleSearchChange = (event) => {
        setSearchQuery({ ...searchQuery, text: event.target.value });
    };

    const handleCategoryChange = (event) => {
        const category = event.target.value;
        selectedCategory = category
        setSelectedCategory(category);  
        if (category === 'All') {
            setFilteredFAQs(faqs);
        } else { 
            setFilteredFAQs(faqs.filter(faq => faq.category === category));
        }
    };

    return (
        <>
            <StyledGrid templateColumns="repeat(12, 1fr)">
                {/* <GridItem colSpan={{ base: 6, md: 5 }}>
                    <Select placeholder="Select category" onChange={handleCategoryChange}>
                        <option value="All">All Categories</option>
                        {categories.map(category => (
                            <option key={category} value={category}>{category}</option>
                        ))}
                    </Select>
                </GridItem> */}
                   <GridItem colSpan={{ base: 3, md: 6 }}>
                    <Input
                        placeholder="Search"
                        onChange={handleSearchChange}
                        value={searchQuery.text}
                    />
                </GridItem>
                <GridItem colSpan={{ base: 6, md: 3 }}>
                    <Select   onChange={handlePublishedChange}>
                        <option value="true">Published</option>
                        <option value="false">Unpublished</option>
                    </Select>
                </GridItem>
                <GridItem colSpan={{ base: 12, md: 3 }}>
                    <ButtonStack direction="row">
                        <CustomButton
                            type="addNew"
                            showIcon={true}
                            showTooltip={true} 
                            tooltipLabel="Add New FAQ"
                            onClick={() => openDrawer({
                                title: 'Add New FAQ',
                                component: FAQAddForm,
                                props: { refetchFAQData: refetchData }
                            }, 'md')}
                            leftIcon={<Icon icon="material-symbols:add-comment-outline" />}
                        >
                            Add New
                        </CustomButton>
                    </ButtonStack>
                </GridItem>
            </StyledGrid>
        </>
    );
};

export default FAQTableTopBar;
