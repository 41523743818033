import React, {useCallback, useEffect, useState} from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box,
    Badge,
} from '@chakra-ui/react';
import PollsEditForm from './PollsEditForm';
import PollViewForm from './PollViewForm';
import { useDrawer } from "../../../Context/DrawerContext/DrawerContext";
import {EditButton, ViewButton, DeleteButton} from "../../../components/Styled/ActionButtons";
import { StyledTableContainer } from "../../../components/Styled/StyledComponents";
import PaginationControls from "../../../Context/PaginationControls/PaginationControls";
import { fetchAllPolls, fetchPollById, deletePoll} from "../SurveyAPI";
import ConfirmDialog from "../../../Context/ConfirmDialog/ConfirmDialog";
import useSingleToast from "../../../hooks/UseSingleToast/UseSingleToast";

const PollsTable = ({ polls, setPolls, error, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag }) => {
    const { openDrawer } = useDrawer();
    const showToast = useSingleToast();

    const handleEdit = (pollId) => {
        console.log("Inside Table poll edit function. Id:"+pollId);
        openDrawer({
            title: 'Edit Survey',
            component: PollsEditForm,
            props: {
                pollId: pollId,
                refetchPollData: refetchPollData,
                setRefreshFlag: setRefreshFlag,
            },
            key: `edit-${pollId}`,
        }, 'md');
    };

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    const requestConfirm = (options) => {
        setConfirmDialog({
            isOpen: true,
            title: options.title,
            message: options.message,
            onConfirm: options.onConfirm,
            pollId: options.pollId 
        });
    };

    const handleView = async (poll) => {
        try {
            const fetchedPollById = await fetchPollById(poll._id);
            let pollResponse;
            try {
                pollResponse = await fetchPollById(poll._id);
            } catch (profileError) {
                console.error("Failed to fetch poll data:", profileError);
                pollResponse = { success: false, data: {} };
            }

            openDrawer({
                title: 'View Poll',
                component: PollViewForm,
                props: {
                    formData: fetchedPollById,
                    pollData: pollResponse ? pollResponse.data : {},
                },
                key: `view-${poll._id}`,
            }, 'xl');

        } catch (error) {
            console.error("Failed to fetch poll data:", error);
        }
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const deleteOption = (pollId) => {
        console.log("Inside the delete option ID:"+pollId);
        console.log(pollId);
        requestConfirm({
            title: "Delete Confirmation",
            message: "Are you sure you want to delete this poll? If you proceed this poll question will be lost forever!",
            onConfirm: handleConfirm,
            pollId: pollId
        });
    };

    const handleConfirm = async () => {
        try{
            await deletePoll(confirmDialog.pollId);
            showToast({
                title: 'Poll question deleted successfully',
                description: deletePoll.message,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchPollData();
        }catch(error){
            console.log("An unexpected error occured while deleting record" + error);
            showToast({
                title: 'Error deleting poll data',
                description: error.message || 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
        // confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const refetchPollData = useCallback(async () => {
        try {
            const data = await fetchAllPolls(pageNumber, 10);
            setPolls(data.polls);
            setTotalPages(data.totalPages);
        } catch (error) {
            console.error("Fetch polls error:", error.message);
        }
    }, [pageNumber, setPolls, setTotalPages]);

    useEffect(() => {
        refetchPollData();
    }, [refetchPollData]);

    if (error) {
        return <Box textAlign="center" my="4">Failed to fetch Polls & Survey data: {error}</Box>;
    }

    if (dataLoaded && Array.isArray(polls) && polls.length === 0) {
        return <Box textAlign="center" my="4">No records found.</Box>;
    }

    return (
        <>
            <StyledTableContainer>
                <PaginationControls
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    totalPages={totalPages}
                />
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th>#</Th>
                            <Th>Question</Th>
                            <Th>Options</Th>
                            <Th>Status</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {polls?.map((poll, index) => (
                            <Tr key={index}>
                                <Td>PE0{index + 1}</Td>
                                <Td>{poll.question}</Td>
                                <Td>{
                                        poll.options.length === 0 ? 
                                        <Badge ml='1' colorScheme='red'>
                                            None
                                        </Badge> 
                                        : 
                                        poll.options.map((item, index) => (
                                            <Badge key={index} ml='1' colorScheme='green'>
                                                {item}
                                            </Badge>
                                        ))}                                        
                                    </Td>
                                    <Td>{
                                        poll.published ? 
                                        <Badge ml='1' colorScheme='green'>
                                            Active
                                        </Badge>
                                        :
                                        <Badge ml='1' colorScheme='red'>
                                            Closed
                                        </Badge>
                                    }
                                </Td>
                                <Td>
                                    <ViewButton onClick={() => handleView(poll)} />
                                    <EditButton onClick={() => handleEdit(poll._id)} />
                                    <DeleteButton onClick={() => deleteOption(poll._id)} />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </StyledTableContainer>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

export default PollsTable;
