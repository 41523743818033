import React from 'react';
import DefaultTemplate from '../../../components/DefaultTemplate/DefaultTemplate';
import MenuGrid from './Components/MenuGrid'; // Adjust path as necessary
import { Icon } from "@iconify/react";

const MediaMenu = ({ fileType }) => {
     

    return (
        <DefaultTemplate
            Content={<MenuGrid />}
            Title={"Media Gallery"} 
        />
    );
};

export default MediaMenu;
