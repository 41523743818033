import React from 'react';
import {
    VStack, Box, Text, Stack, SimpleGrid, Badge
} from '@chakra-ui/react';
import {Icon} from "@iconify/react";
import {useDrawer} from "../../../../../Context/DrawerContext/DrawerContext";
import FormActionLabel from "../../../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../../../components/Styled/StyledAlert";
import {ButtonStack, CustomButton} from "../../../../../components/Styled/StyledButtons";
import SectionHeading from "../../../../../components/SectionHeading/SectionHeading";
import utils from "../../../../../utils/commonFunctions";
import FileDisplay from "../../../../../components/FileDisplay";


const PublicationViewForm = ({ formData }) => {
    const { closeDrawer } = useDrawer();
    const { title, description, categoryId, status, fileName, extension, size, fileUrl, fileType } = formData;

    const getStatusColor = (status) => {
        switch (status) {
            case 'Archived': return 'red';
            case 'Active': return 'green';
            default: return 'gray';
        }
    };

    return (
        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
            <FormActionLabel formAction="view" formName="Publication"/>
            <Stack spacing={4} px={2} py={4} >
                <SectionHeading icon="fluent:apps-list-detail-24-filled" text="Publication Information" />
                <SimpleGrid columns={{base: 1, sm: 1}} gap={4} px={4}>
                    <Text><Icon icon={utils.getFileTypeIcon(extension)} /></Text>
                    <Text><strong>Title: </strong> {title}</Text>
                    <Text><strong>Description: </strong> {description}</Text>
                    <Box>
                        <Text>
                            <strong>Category: </strong>
                            <Badge variant="outline" colorScheme="orange">{categoryId.name}</Badge>
                        </Text>
                    </Box>
                    <Box>
                        <Text>
                            <strong>Size: </strong>
                            <Badge colorScheme="blue" variant="solid">{utils.formatFileSize(size)}</Badge>
                        </Text>
                    </Box>
                    <Box>
                        <Text>
                            <strong>Status: </strong>
                            <Badge variant="solid" colorScheme={getStatusColor(status)}>{status}</Badge>
                        </Text>
                    </Box>
                    <Text><strong>File Name: </strong> {fileName}</Text>
                    <Text><strong>Created At:</strong> {`${utils.DateFormat(formData.createdAt)}`}</Text>
                    <Text><strong>Updated At:</strong> {`${utils.DateFormat(formData.updatedAt)}`}</Text>
                    {fileUrl && <FileDisplay filePath={fileUrl} label="Publication Document"/>}

                    <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }} mt={8} mb={8}>
                        <CustomAlert status="warning" message="View Only Mode" />
                        <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                            <CustomButton type="cancel" onClick={closeDrawer} showIcon={false}>
                                Cancel
                            </CustomButton>
                        </ButtonStack>
                    </Box>
                </SimpleGrid>
            </Stack>
        </VStack>
    );
};

export default PublicationViewForm;