import { API1 } from "../../utils/api";

// Create Grouped Email Center
export const createGroupEmailManagement = async (GroupEmailManagementData) => {
    try {
        const response = await API1.post('/labs/email/', GroupEmailManagementData);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "An unexpected error occurred while creating the Grouped Email.");
    }
};

// Fetch All Group Emails
export const fetchAllGroupEmailManagements = async (pageNumber = 1, pageSize = 10, filterOptions = {}, handleError) => {
    try {
        const queryParams = new URLSearchParams({ ...filterOptions, pageNumber, pageSize }).toString();
        const response = await API1.get(`/labs/email/?${queryParams}`);
        
        if(response.status === 200) {
            return {
                groupEmails: response.data.data.docs,
                totalPages: Math.ceil(response.data.data.totalDocs / pageSize),
            };
        }
        throw new Error('Unexpected response status: ' + response.status);
    } catch (error) {
        const errorMessage = error?.message || "Failed to fetch Group Email. Please try again.";
        if (handleError) {
            handleError(errorMessage);
        }
        throw new Error(errorMessage);
    }
};

// Fetch Email by ID using query parameters
export const fetchGroupEmailManagementById = async (GroupEmailManagementId) => {
    try {
        const response = await API1.get(`/labs/email/${GroupEmailManagementId}`);
        if (response.status === 200) {
            return response.data.data;
        } else {
            throw new Error('Email not found.');
        }
    } catch (error) {
        throw new Error(error?.message || "Failed to fetch the Email details.");
    }
};

// Update Email
export const updateGroupEmailManagement = async (GroupEmailManagementId, GroupEmailManagementData) => {
    try {
        // console.log(GroupEmailManagementId, GroupEmailManagementData)
        const response = await API1.put(`/labs/email/update/${GroupEmailManagementId}`, GroupEmailManagementData);
        // console.log(response)
        if (response.status !== 201) {
            throw new Error('Update failed due to server response');
        }
        return response.data;
    } catch (error) {
        console.error( error);
        throw new Error(error?.message || "An error occurred while updating the Email");
    }
};


// Delete Email
export const deleteGroupEmailManagement = async (GroupEmailManagementId) => {
    try {
        // console.log(GroupEmailManagementId)
        const response = await API1.delete(`/labs/email/delete/${GroupEmailManagementId}`);
        console.log(response)
        return response;
    } catch (error) {
        throw new Error(error?.message || "Failed to delete the Email");
    }
};
