import React from 'react';
import CustomerUserContainer from './Components/CustomerContainer';
import { Icon } from "@iconify/react";
import DefaultTemplate from "../../../components/DefaultTemplate/DefaultTemplate";
import { CustomerProvider } from "../../../Context/CustomerContext/CustomerContext";
const Customer = () => {
    return (
        <CustomerProvider>
            <DefaultTemplate Content={<CustomerUserContainer />} Title={"Customers"} icon={<Icon icon="fa-solid:users" />} />
        </CustomerProvider>
    );
}

export default Customer;
