import { API1 } from "../../../utils/api";

// Create Branch
export const createBranch = async (branchData) => {
    try {
        const response = await API1.post('/branches', branchData);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "An unexpected error occurred while creating the branch.");
    }
};

// Fetch All Branches
export const fetchAllBranches = async (pageNumber = 1, pageSize = 10, filterOptions = {}) => {
    try {
        const queryParams = new URLSearchParams({
            ...filterOptions,
            pageNumber,
            pageSize
        }).toString();

        const response = await API1.get(`/branches?${queryParams}`);

        if (response.status === 200) {
            const { docs: branches, totalDocs } = response.data;
            return {
                branches,
                totalPages: Math.ceil(totalDocs / pageSize),
            };
        } else {
            throw new Error('Unexpected response from the server while fetching branches.');
        }
    } catch (error) {
        console.error("Error fetching branches:", error);
        throw new Error('Failed to fetch branches. Please try again.');
    }
};

// Fetch Branch by ID using query parameters
export const fetchBranchById = async (branchId) => {
    try {
        const queryParams = new URLSearchParams({ id: branchId }).toString();
        const response = await API1.get(`/branches?${queryParams}`);
        if (response.status === 200 && response.data.docs.length > 0) {
            return response.data.docs[0];
        } else {
            throw new Error('Branch not found.');
        }
    } catch (error) {
        throw new Error(error?.message || "Failed to fetch the branch details.");
    }
};

// Update Branch
export const updateBranch = async (branchId, branchData) => {
    try {
        const response = await API1.put(`/branches/${branchId}`, branchData);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "An error occurred while updating the branch.");
    }
};

// Delete Branch
export const deleteBranch = async (branchId) => {
    try {
        const response = await API1.delete(`/branches/${branchId}`);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "Failed to delete the branch. It might have associated branches.");
    }
};

// Fetch Area by ID using query parameters
export const fetchAreaById = async (areaId) => {
    try {
        const queryParams = new URLSearchParams({ id: areaId }).toString();
        const response = await API1.get(`/areas?${queryParams}`);
        if (response.status === 200 && response.data.docs.length > 0) {
            return response.data.docs[0];
        } else {
            throw new Error('Area not found.');
        }
    } catch (error) {
        throw new Error(error?.message || "Failed to fetch the area details.");
    }
};