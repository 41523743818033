import React, {useState, useEffect, useCallback} from 'react';
import {
  Flex,
  Stack,
  SimpleGrid,
  chakra,
  ButtonGroup,
  IconButton,
  useColorModeValue,
  Box,
  Tooltip,
} from '@chakra-ui/react';
import { AiFillEdit, AiOutlineEye } from 'react-icons/ai';
import { BsFillTrashFill } from 'react-icons/bs';
import Chart from 'react-apexcharts';
import { fetchAllProjects } from '../../pages/Projects/projectAPI';
import {getIndividualConnectionsStatistics, getWaterShortageReportStatistics, getPaymentStatistics} from '../../pages/Dashboard/dashboardAPI';
import { useNavigate } from 'react-router-dom';
import utils from '../../utils/commonFunctions';

const AnalyticsDashboard = () => {
  const [projects, setProjects] = useState();
  const [connectionStats, setConnectionStats] = useState([0,0,0,0,0,0,0,0,0,0,0,0]);
  const [waterShortageReportStats, setWaterShortageReportStats] = useState([0,0,0,0,0,0,0,0,0,0,0,0]);
  const [onlinePaymentStats, setOnlinePaymentStats] = useState([0,0,0,0,0,0,0,0,0,0,0,0]);
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const refetchProjectsData = useCallback(async (filter={}, fetchAll = false) => {
      setDataLoaded(false);
      try {
          const data = await fetchAllProjects(pageNumber, 10, filter, fetchAll);
          setProjects(data.projects);
          setTotalPages(data.totalPages);
          setError('');
          setDataLoaded(true);
      } catch (error) {
          utils.sendMessageToBugNinja("Error fetching project data:", error.message, error)
          setError('Failed to fetch projects data.');
          setProjects([]);
          setTotalPages(0);
      }
  }, [pageNumber]);

  const fetchConnectionsStatitstics = async () => {
      try {
          const response = await getIndividualConnectionsStatistics();
          setConnectionStats(response?.data?.connections);
      } catch (error) {
          utils.sendMessageToBugNinja("Error fetching connections stats:", error.message, error)
      }
  };
  const fetchWaterShortageStatitstics = async () => {
      try {
          const response = await getWaterShortageReportStatistics();
          setWaterShortageReportStats(response?.data?.shortages);
      } catch (error) {
          utils.sendMessageToBugNinja("Error fetching water shortage stats:", error.message, error)
      }
  };
  const fetchPaymentStatitstics = async () => {
      try {
          const response = await getPaymentStatistics();
          setOnlinePaymentStats(response?.data?.payments);
      } catch (error) {
          utils.sendMessageToBugNinja("Error fetching payment stats:", error.message, error)
      }
  };

  useEffect(() => {
      refetchProjectsData();
      fetchConnectionsStatitstics();
      fetchWaterShortageStatitstics();
      fetchPaymentStatitstics()
  }, [refetchProjectsData]);

  // Color modes for Chakra UI
  const bg = useColorModeValue('white', 'gray.800');
  const bg2 = useColorModeValue('white', 'gray.800');
  const bg3 = useColorModeValue('gray.100', 'gray.700');

  // Chart data and options for Connection Applications
  const connectionApplicationsOptions = {
    chart: { id: 'connection-applications' },
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
    },
    colors: ['#63B3ED'],
  };
  const connectionApplicationsSeries = [
    {
      name: 'Connection Applications',
      data: connectionStats,
    },
  ];

  // Chart data and options for Water Shortage Reports
  const waterShortageReportsOptions = {
    chart: { id: 'water-shortage-reports' },
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
    },
    colors: ['#63B3ED'],
  };
  const waterShortageReportsSeries = [
    {
      name: 'Water Shortage Reports',
      data: waterShortageReportStats,
    },
  ];

  // Chart data and options for Online Payments
  const onlinePaymentsOptions = {
    chart: { id: 'online-payments' },
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
    },
    colors: ['#63B3ED'],
  };
  const onlinePaymentsSeries = [
    {
      name: 'Online Payments',
      data: onlinePaymentStats,
    },
  ];

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + '...';
  };

  const handleClick = ()=>{
    // /projects
    navigate(`/projects`);
  }

  return (
    <Flex
      w='full'
      bg='gray.50'
      _dark={{ bg: '#3e3e3e' }}
      py={10}
      alignItems='center'
      justifyContent='center'
    >
      <Stack direction={{ base: 'column', md: 'row' }} w='full'>
        {/* Table */}
        <Box
          bg={bg}
          p={5}
          shadow='md'
          borderWidth='1px'
          borderRadius='md'
          direction={{ base: 'column' }}
          w={{ base: 'full', md: '3/4' }}
        >
          <chakra.h2 fontSize='xl' mb={5} color='blue.800'>
            Projects
          </chakra.h2>
          <Stack
            direction={{ base: 'column' }}
            w={{ base: 'full', md: '3/7' }}
            bg={{ md: bg }}
            shadow='lg'
          >
            {projects?.map((project, tid) => (
              <Flex
                direction={{ base: 'row', md: 'column' }}
                bg={bg2}
                key={tid}
              >
                <SimpleGrid
                  spacingY={3}
                  columns={{ base: 1, md: 4 }}
                  w={{ base: 120, md: 'full' }}
                  textTransform='uppercase'
                  bg={bg3}
                  color={'gray.500'}
                  py={{ base: 1, md: 4 }}
                  px={{ base: 2, md: 10 }}
                  fontSize='md'
                  fontWeight='hairline'
                >
                  <span>Project Name</span>
                  <span>Start Date</span>
                  <span>End Date</span>
                  <span>Actions</span>
                </SimpleGrid>
                <SimpleGrid
                  spacingY={3}
                  columns={{ base: 1, md: 4 }}
                  w='full'
                  py={2}
                  px={10}
                  fontWeight='hairline'
                >
                  <span>{truncateText(project.title, 15)}</span>
                  <chakra.span
                    textOverflow='ellipsis'
                    overflow='hidden'
                    whiteSpace='nowrap'
                  >
                    {project.startDate}
                  </chakra.span>
                  <chakra.span
                    textOverflow='ellipsis'
                    overflow='hidden'
                    whiteSpace='nowrap'
                  >
                    {project.closingDate}
                  </chakra.span>
                  <Flex
                    justify={{
                      md: 'end',
                    }}
                  >
                    <ButtonGroup variant='solid' size='sm' spacing={3}>
                      <Tooltip label='View' aria-label='A tooltip for view'>
                        <IconButton
                          color='white'
                          bg='#63B3ED'
                          _hover={{ bg: '#63B3ED' }}
                          _active={{ bg: '#63B3ED' }}
                          icon={<AiOutlineEye />}
                          onClick={handleClick}
                          aria-label='View'
                        />
                      </Tooltip>
                      <Tooltip label='Edit' aria-label='A tooltip for edit'>
                        <IconButton
                          colorScheme='green'
                          icon={<AiFillEdit />}
                          aria-label='Edit'
                          onClick={handleClick}
                        />
                      </Tooltip>
                      <Tooltip label='Delete' aria-label='A tooltip for delete'>
                        <IconButton
                          colorScheme='red'
                          variant='outline'
                          icon={<BsFillTrashFill />}
                          onClick={handleClick}
                          aria-label='Delete'
                        />
                      </Tooltip>
                    </ButtonGroup>
                  </Flex>
                </SimpleGrid>
              </Flex>
            ))}
          </Stack>
        </Box>
        {/* Charts */}
        <Stack
          direction={{ base: 'column', md: 'column' }}
          w={{ base: 'full', md: '1/4' }}
          spacing={5}
        >
          <Box bg={bg} p={5} shadow='md' borderWidth='1px' borderRadius='md'>
            <chakra.h2 fontSize='xl' mb={5} color='blue.800'>
              Connection Applications
            </chakra.h2>
            <Chart
              options={connectionApplicationsOptions}
              series={connectionApplicationsSeries}
              type='bar'
              height={200}
            />
          </Box>

          <Box bg={bg} p={5} shadow='md' borderWidth='1px' borderRadius='md'>
            <chakra.h2 fontSize='xl' mb={5} color='blue.800'>
              Water Shortage Reports
            </chakra.h2>
            <Chart
              options={waterShortageReportsOptions}
              series={waterShortageReportsSeries}
              type='area'
              height={200}
            />
          </Box>
          {/* Online Payments Chart */}
          <Box bg={bg} p={5} shadow='md' borderWidth='1px' borderRadius='md'>
            <chakra.h2 fontSize='xl' mb={5} color='blue.800'>
              Online Payments
            </chakra.h2>
            <Chart
              options={onlinePaymentsOptions}
              series={onlinePaymentsSeries}
              type='line'
              height={200}
            />
          </Box>
        </Stack>
      </Stack>
    </Flex>
  );
};

export default AnalyticsDashboard;
