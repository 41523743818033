import React, { useEffect, useState } from 'react';
import {
    VStack, Box, Text, Stack, Flex, SimpleGrid, Heading
} from '@chakra-ui/react';
import FormActionLabel from "../../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../../components/Styled/StyledButtons";
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import {Icon} from "@iconify/react";
import { fetchNoticeById} from "../NoticesAPI";
import DOMPurify from 'dompurify';

const DetailSection = ({ icon, title, children, style = {} }) => (
    <Box>
        <Flex align="center" px={2} py={3} bg="blue.50" {...style} mt={8} borderRadius="0.375em" mb={4}>
            <Icon icon={icon} width="20" height="24" style={{ marginRight: '10px', color: '#63b3ed', marginLeft: '10px' }} />
            <Heading size="sm" fontWeight="bold" color="blue.800">{title}</Heading>
        </Flex>
        {children}
    </Box>
);

const DescriptionBox = ({ noticeData }) => {
    const createMarkup = (htmlContent) => {
        return {
            __html: DOMPurify.sanitize(htmlContent)
        };
    };

    return (
        <Box>
            <strong>Description:</strong>
            {/* Use dangerouslySetInnerHTML only with sanitized content */}
            <div dangerouslySetInnerHTML={createMarkup(noticeData.description)} />
        </Box>
    );
};

const NoticeViewForm = ({ formData }) => {
    const { closeDrawer } = useDrawer();
    const [noticeData, setNoticeData] = useState(
        {   title: '',
            area: '',
            description: '',
            city: '' });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const notice = await fetchNoticeById(formData._id);
                setNoticeData({
                    ...notice,
                });
            } catch (error) {
                console.log('Error fetching notice details:', error);
            }
        };

        fetchData();
    }, [formData]);

    return (
        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
            <FormActionLabel formAction="view" formName="Notice"/>
            <Stack spacing={4} px={2} py={4} mt={4}>
                <DetailSection title="Notice Information" icon="mdi:bell-check">
                    <SimpleGrid columns={{ base: 1, sm: 1 }} gap={4} px={4}>
                        <Text><strong>Notice title:</strong> {noticeData.title}</Text>
                        <Text><strong>Area title:</strong> {noticeData.areaName}</Text>
                        <Text><strong>City:</strong> {noticeData.cityName}</Text>
                        <DescriptionBox noticeData={noticeData}/>
                    </SimpleGrid>
                </DetailSection>

                <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}  mt={8} mb={8}>
                    <CustomAlert status="warning" message="View Only Mode" />
                    <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                        <CustomButton type="cancel" onClick={closeDrawer} showIcon={false}>
                            Close
                        </CustomButton>
                    </ButtonStack>
                </Box>
            </Stack>
        </VStack>
    );
};

export default NoticeViewForm;