import React, { useEffect, useState } from 'react';
import { Flex, GridItem, Stack } from '@chakra-ui/react';
import NewsAddForm from './NewsAddForm'; // Ensure this form is for adding new customers
import { StyledGrid } from "../../../components/Styled/StyledComponents";
import { ButtonStack, CustomButton } from "../../../components/Styled/StyledButtons";
import { useDrawer } from "../../../Context/DrawerContext/DrawerContext";
import { Icon } from "@iconify/react";
import { ThemedStyledInput } from "../../../components/Styled";

const SearchAndFilterComponent = ({ callback, resetFilters }) => {
    const [filters, setFilters] = useState({});
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        callback(filters);
    }, [filters, callback]);

    const handleSearch = (e) => {
        const { name, value } = e.target;
        setFilters(prev => ({ ...prev, [name]: value }));
        setSearchValue(value);
    };

    const handleReset = () => {
        setFilters({});
        setSearchValue('');
        resetFilters();
    };

    return (
        <Stack spacing={2} direction="row" align="center">
            <ThemedStyledInput
                type="text"
                name="title"
                w="full"
                placeholder="Search by Title"
                value={searchValue}
                onChange={handleSearch}
            />
            <GridItem colSpan={{ base: 12, md: 1 }}>
                <CustomButton
                    type="reset"
                    onClick={handleReset}
                    tooltipLabel="Clear Filters"
                    icon={<Icon icon="radix-icons:reset" />}
                    showLabel={false}
                >
                    Clear
                </CustomButton>
            </GridItem>
        </Stack>
    );
};

const NewsTableTopBar = ({ refetchNewsData, newsData }) => { // Pass refetchNewsData if needed for the AddForm to trigger a refresh
    const { openDrawer } = useDrawer();

    const resetFilters = () => {
        refetchNewsData({}, true);
    };

    return (
        <>
            <StyledGrid templateColumns="repeat(12, 1fr)">
                <GridItem colSpan={{ base: 10, md: 11 }}>
                    <SearchAndFilterComponent callback={refetchNewsData} resetFilters={resetFilters} />
                </GridItem>
                <GridItem colSpan={{ base: 12, md: 1 }}>
                    <ButtonStack direction="row">
                        <CustomButton
                            type="addNew"
                            showIcon={true}
                            showTooltip={true}
                            tooltipLabel="Add New News"
                            icon={<Icon icon="mdi:chart-box-plus-outline" />}
                            onClick={() => openDrawer({
                                title: 'Create New News',
                                component: NewsAddForm,
                                props: { refetchNewsData, newsData }
                            }, 'md')}
                        >
                            Add New
                        </CustomButton>
                    </ButtonStack>
                </GridItem>
            </StyledGrid>
            <Flex bg="white" mb={4}></Flex>
        </>
    );
};

export default NewsTableTopBar;
