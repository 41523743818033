import {API1} from "./api";

export const validateCustomerNumber = async (customerNumber) => {
    if (!customerNumber) return "Customer number is required.";
    try {
        const response = await API1.post(`/meter/validate-customer-number`, { customerNumber });
        return response.data.success ? null : "Invalid customer number.";
    } catch (error) {
        return "Failed to validate customer number.";
    }
};

export const validateContractNumber = async (customerNumber, contractNumber) => {
    if (!contractNumber) return "Contract number is required.";
    if (!customerNumber) return "Customer number is required for validation.";

    try {
        const response = await API1.post(`/meter/validate-contract-number`, { customerNumber, contractNumber });
        return response.data.success ? null : response.data.message || "Invalid contract number.";
    } catch (error) {
        return "Failed to validate contract number.";
    }
};

