import { API1 } from "../../utils/api";

// #################################
//          NEWS API FUNCTIONS
// #################################

// Fetch All LabServiceProducts
export const fetchAllNews = async (pageNumber = 1, pageSize = 10, filterOptions = {}) => {
    try {
        const queryParams = new URLSearchParams({
            ...filterOptions,
            pageNumber,
            pageSize
        }).toString();

        const response = await API1.get(`/news?${queryParams}`);
        if (response.status === 200) {
            return response.data.data;
        } else {
            throw new Error('Unexpected response from the server while fetching news.');
        }
    } catch (error) {
        throw new Error('Failed to fetch news. Please try again.');
    }
};

// addNews API
export const addNews = async (newsData) => {
    try {
        const response = await API1.post(`/news`, newsData);
        if (response.status === 201) {
            return response.data;
        } else {
            throw new Error('Unexpected response from the server while adding news.');
        }
    } catch (error) {
        throw new Error('Failed to add news. Please try again.');
    }
};

// fetch news by id
export const fetchNewsById = async (newsId) => {
    try {
        const response = await API1.get(`/news/${newsId}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Unexpected response from the server while fetching news.');
        }
    } catch (error) {
        throw new Error('Failed to fetch news. Please try again.');
    }
};

// update news API
export const updateNews = async (newsId, newsData) => {
    try {

        const response = await API1.put(`/news/${newsId}`, newsData);

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Unexpected response from the server while updating news.');
        }
    } catch (error) {
        throw new Error('Failed to update news. Please try again.');
    }
};

// delete news by ID API
export const deleteNews = async (newsId) => {
    try {
        const response = await API1.delete(`/news/${newsId}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Unexpected response from the server while deleting news.');
        }
    } catch (error) {
        throw new Error('Failed to delete news. Please try again.');
    }
};
// #################################
//          NEWS COMMON FUNCTIONS
// #################################

export const checkImageDimensions = async (file, width, height) => {
    return new Promise((resolve, reject) => {
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    if (width === img.width && height === img.height) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                };
                img.onerror = () => {
                    reject(new Error('Failed to load image'));
                };
                img.src = e.target.result;
            };
            reader.onerror = () => {
                reject(new Error('Failed to read file'));
            };
            reader.readAsDataURL(file);
        } else {
            resolve(false);
        }
    });
};
