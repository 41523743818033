import React from 'react';
import {Page, Text, View, Document, StyleSheet, Image, Font} from '@react-pdf/renderer';
import bgImage from '../../assests/images/BlueLogoNoBG.png'; // Your background image
import logoImage from '../../assests/images/BlueLogoNoBG.png'; // Your logo image

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFF',
        position: 'relative',
        fontFamily: 'Helvetica', // Change to registered font if necessary
        fontSize: 11,
        padding: 40,
    },
    logo: {
        width: 80,
        height: 80,
        position: 'absolute',
        top: 30,
        left: '50%',
    },
    background: {
        position: 'absolute',
        minWidth: '70%',
        minHeight: '70%',
        height: '70%',
        width: '70%',
        opacity: 0.1,
        top: '50%',
        left: '50%',
        transform: 'translate(-150%, -280%)',

    },
    header: {
        marginTop: 70, // Adjust this to position your header correctly
        marginBottom: 30,
        fontSize: 15,
        textAlign: 'center',
        fontWeight: 'extrabold',
    },
    grid: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginRight: -15,
        marginLeft: -15,
        marginBottom: 15
    },
    col: {
        paddingLeft: 15,
        paddingRight: 15,
        flexBasis: '50%',

    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 8, // Add space between rows
    },
    label: {
        fontWeight: 'bold',
    },
    checkbox: {
        borderColor: 'gray',
        borderWidth: 1,
        backgroundColor: "blue",
        borderRadius: '30px',
        width: 8,
        height: 8
    },
    title: {
        fontSize: 14,
        fontWeight: 'extrabold',
        paddingLeft: 15,
        paddingRight: 15,
        flexBasis: '100%',
        marginBottom: 8
    },
    fieldValue: {
        marginLeft: 4,
        textAlign: 'justify'
    },
    // ... rest of your styles
});

const Grid = ({children}) => <View style={styles.grid}>{children}</View>;
const Col = ({children}) => <View style={styles.col}>{children}</View>;
const Row = ({children}) => <View style={styles.row}>{children}</View>;
// Your component that uses the grid
const ApplicationRequestPdf = ({pdfData}) => {
    return (
        <Document>
            <Page size='A4' style={styles.page}>
                <Image style={styles.background} src={bgImage}/>
                <Image style={styles.logo} src={logoImage}/>
                <Text style={styles.header}>Application For Water Service Connection/ Individual Or Domestic
                    Connection</Text>
                <Grid>
                    <Col>
                        <Row>
                            <Text style={styles.label}>Service Type:</Text>
                            <Text style={styles.fieldValue}>{pdfData.connectionType}</Text>
                        </Row>
                        <Row>
                            <Text style={styles.label}>Connection Type:</Text>
                            <Text
                                style={styles.fieldValue}>{pdfData.isExistingConnection ? "Existing Connection" : "New Connection"}</Text>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Text style={styles.label}>Applicant Type:</Text>
                            <Text style={styles.fieldValue}>{pdfData.applicantType}</Text>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Text style={styles.label}>Customer Number:</Text>
                            <Text style={styles.fieldValue}>{pdfData.customerNumber}</Text>
                        </Row>
                        <Row>
                            <Text style={styles.label}>Marital Status:</Text>
                            <Text style={styles.fieldValue}>{pdfData.personalDetails.maritalStatus}</Text>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Text style={styles.label}>PC/Plot No:</Text>
                            <Text style={styles.fieldValue}>{pdfData.serviceConnectionDetails.plotNo}</Text>
                        </Row>
                    </Col>
                </Grid>
                <Grid>
                    <Text style={styles.title}>Customer Details</Text>
                    <Col>
                        <Row>
                            <Text style={styles.label}>Customer Name:</Text>
                            <Text
                                style={styles.fieldValue}>{pdfData.personalDetails.firstName + " " + pdfData.personalDetails.lastName}</Text>
                        </Row>
                        <Row>
                            <Text style={styles.label}>Date Of Birth:</Text>
                            <Text style={styles.fieldValue}>{pdfData.personalDetails.dateOfBirth}</Text>
                        </Row>
                        <Row>
                            <Text style={styles.label}>Tel (Home):</Text>
                            <Text style={styles.fieldValue}>{pdfData.contactDetails.homeTel}</Text>
                        </Row>
                        <Row>
                            <Text style={styles.label}>Email Address:</Text>
                            <Text style={styles.fieldValue}>{pdfData.contactDetails.email}</Text>
                        </Row>
                    </Col>

                    <Col>
                        <Row>
                            <Text style={styles.label}>Identity/Passport Number:</Text>
                            <Text style={styles.fieldValue}>{pdfData.personalDetails.identityNo}</Text>
                        </Row>
                        <Row>
                            <Text style={styles.label}>Postal Address:</Text>
                            <Text style={styles.fieldValue}>{pdfData.contactDetails.postal}</Text>
                        </Row>
                        <Row>
                            <Text style={styles.label}>Tel (Work):</Text>
                            <Text style={styles.fieldValue}>{pdfData.contactDetails.workTel}</Text>
                        </Row>
                        <Row>
                            <Text style={styles.label}>Cell:</Text>
                            <Text style={styles.fieldValue}>{pdfData.contactDetails.cell}</Text>
                        </Row>
                    </Col>

                </Grid>
                <Grid>
                    <Text style={styles.title}>Physical Address</Text>
                    <Col>
                        <Row>
                            <Text style={styles.label}>Postal Address:</Text>
                            <Text style={styles.fieldValue}>{pdfData.address.postal}</Text>
                        </Row>
                        <Row>
                            <Text style={styles.label}>Area / Ward:</Text>
                            <Text style={styles.fieldValue}>{pdfData.address.ward}</Text>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Text style={styles.label}>City / Village / Town:</Text>
                            <Text style={styles.fieldValue}>{pdfData.address.city}</Text>
                        </Row>
                    </Col>
                </Grid>
                <Grid>
                    <Text style={styles.title}>Service Connection Details</Text>
                    <Col>
                        <Row>
                            <Text style={styles.label}>Postal Address:</Text>
                            <Text style={styles.fieldValue}>{pdfData.serviceConnectionDetails.postal}</Text>
                        </Row>
                        <Row>
                            <Text style={styles.label}>Area / Ward:</Text>
                            <Text style={styles.fieldValue}>{pdfData.serviceConnectionDetails.ward}</Text>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Text style={styles.label}>City / Village / Town:</Text>
                            <Text style={styles.fieldValue}>{pdfData.serviceConnectionDetails.city}</Text>
                        </Row>
                    </Col>
                </Grid>

                <Text style={styles.fieldValue}>
                    I, {`${pdfData.personalDetails.firstName} ${pdfData.personalDetails.lastName} `}declare that to the best of
                    my knowledge and belief the information in this application and the accompanying documents is true and correct
                    and have read and understood the implications of the Waterworks Act and all the relevant Legislation and Regulations
                </Text>
            </Page>
        </Document>
    );
};

export default ApplicationRequestPdf;
