import React, { useEffect, useState, useCallback } from 'react';
import ManagementCenterTable from './ManagementCenterTable';
import ManagementCenterTableTopBar from './ManagementCenterTableTopBar';
import { fetchAllManagementCenters } from '../ManagementCenterAPI';
import {useError} from "../../../../Context/ErrorContext/ErrorContext";
import {Box} from "@chakra-ui/react";

const ManagementCenterContainer = () => {
    const [managementCenters, setManagementCenters] = useState([]);
    const [filterOptions, setFilterOptions] = useState({});
    const [error, setError] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [fetchTrigger, setFetchTrigger] = useState(false);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const { handleError } = useError();

    const refetchManagementCenterData = useCallback(async (options = {}) => {
        setDataLoaded(false);
        try {
            const data = await fetchAllManagementCenters(pageNumber, 10, filterOptions, handleError);
            setManagementCenters(data.managementCenters);
            setTotalPages(data.totalPages);
            setError('');
            setDataLoaded(true);
        } catch (error) {
            setError(error.message);
            setManagementCenters([]);
            setTotalPages(0);
        }
    }, [pageNumber, filterOptions, handleError]);

    useEffect(() => {
        refetchManagementCenterData();
    }, [fetchTrigger, refetchManagementCenterData, refreshFlag, totalPages, fetchTrigger, filterOptions]);

    if (!managementCenters) {
        return (
            <Box textAlign="center" my="4" p="4" color="red.600" bg="blue.50">
                <p>Loading management centers...</p>
            </Box>
        );
    }

    return (
        <>
            <ManagementCenterTableTopBar
                refetchManagementCenterData={refetchManagementCenterData}
                setPageNumber={setPageNumber}
                setFilterOptions={setFilterOptions}
                setFetchTrigger={setFetchTrigger}
            />
            <ManagementCenterTable
                managementCenters={managementCenters}
                setManagementCenters={setManagementCenters}
                error={error}
                pageNumber={pageNumber}
                setRefreshFlag={setRefreshFlag}
                setPageNumber={setPageNumber}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                filterOptions={filterOptions}
                dataLoaded={dataLoaded}
                refetchManagementCenterData={refetchManagementCenterData}
            />
        </>
    );
};

export default ManagementCenterContainer;
