import React, { useEffect, useState } from 'react';
import {
    VStack, Box, Text, Stack, Flex, SimpleGrid, Heading
} from '@chakra-ui/react';
import FormActionLabel from "../../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../../components/Styled/StyledButtons";
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import {Icon} from "@iconify/react";
import { fetchAreaById} from "../LeaksAPI";
import moment from "moment";

const DetailSection = ({ icon, title, children, style = {} }) => (
    <Box>
        <Flex align="center" px={2} py={3} bg="blue.50" {...style} mt={8} borderRadius="0.375em" mb={4}>
            <Icon icon={icon} width="20" height="24" style={{ marginRight: '10px', color: '#63b3ed', marginLeft: '10px' }} />
            <Heading size="sm" fontWeight="bold" color="blue.800">{title}</Heading>
        </Flex>
        {children}
    </Box>
);

const AreaViewForm = ({ formData }) => {
    const { closeDrawer } = useDrawer();
    const [areaData, setAreaData] = useState({ name: '', cityName: '' });

    useEffect(() => {
        const fetchData = async () => {
            try {
                setAreaData({
                    ...formData,
                });
            } catch (error) {
                console.log('Error fetching area details:', error);
            }
        };
        fetchData();
    }, [formData]);

    return (
        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
            <FormActionLabel formAction="view" formName="Area"/>
            <Stack spacing={4} px={2} py={4} mt={4}>

                <DetailSection title="Leak Report Information" icon="game-icons:leak">
                    <SimpleGrid columns={{ base: 1, sm: 1 }} gap={4} px={4}>
                        <Text><strong>Ticket Number:</strong> {areaData.ticket}</Text>
                        <Text><strong>Name:</strong> {areaData.name}</Text>
                        <Text><strong>Email:</strong> {areaData.contactDetails?.email}</Text>
                        <Text><strong>Phone:</strong> {areaData.contactDetails?.phone}</Text>
                        <Text><strong>Village/Town:</strong> {areaData.village}</Text>
                        <Text><strong>Address:</strong> {areaData.address}</Text>
                        <Text><strong>Leak Location:</strong> {areaData.where}</Text>

                        <Text><strong>Date Reported:</strong> {moment(areaData.createdDate, "DD-MM-YYYY").format("DD-MM-YYYY")}</Text>
                        <Text><strong>Comments:</strong> {areaData.comment}</Text>
                    </SimpleGrid>
                </DetailSection>

                <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}  mt={8} mb={8}>
                    <CustomAlert status="warning" message="View Only Mode" />
                    <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                        <CustomButton type="cancel" onClick={closeDrawer} showIcon={false}>
                            Close
                        </CustomButton>
                    </ButtonStack>
                </Box>
            </Stack>
        </VStack>
    );
};

export default AreaViewForm;