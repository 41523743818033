import { API1 } from "../../../../utils/api";

// Create Publication Category
export const createPublicationCategory = async (publicationCategoryData) => {
    try {
        const response = await API1.post('/publicationCategories', publicationCategoryData);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "An unexpected error occurred while creating the publication category.");
    }
};

// Fetch All Publication Categories
export const fetchAllPublicationCategories = async (pageNumber = 1, pageSize = 10, filterOptions = {}, handleError) => {
    try {
        const queryParams = new URLSearchParams({ ...filterOptions, pageNumber, pageSize }).toString();
        const response = await API1.get(`/publicationCategories?${queryParams}`);
        if (response.status === 200) {
            return {
                publicationCategories: response.data.docs,
                totalPages: Math.ceil(response.data.totalDocs / pageSize),
            };
        }
        throw new Error('Unexpected response status: ' + response.status);
    } catch (error) {
        const errorMessage = error?.message || "Failed to fetch publication categories. Please try again.";
        if (handleError) {
            handleError(errorMessage);
        }
        throw new Error(errorMessage);
    }
};


// Fetch Publication Category by ID using query parameters
export const fetchPublicationCategoryById = async (publicationCategoryId) => {
    try {
        const queryParams = new URLSearchParams({ id: publicationCategoryId }).toString();
        const response = await API1.get(`/publicationCategories?${queryParams}`);
        if (response.status === 200 && response.data.docs.length > 0) {
            return response.data.docs[0];
        } else {
            throw new Error('Publication Category not found.');
        }
    } catch (error) {
        throw new Error(error?.message || "Failed to fetch the publication category details.");
    }
};

// Update Publication Category
export const updatePublicationCategory = async (publicationCategoryId, publicationCategoryData) => {
    try {
        const response = await API1.put(`/publicationCategories/${publicationCategoryId}`, publicationCategoryData);
        if (response.status !== 200) {
            throw new Error('Update failed due to server response');
        }
        return response.data;
    } catch (error) {
        console.error("Error updating publication category:", error);
        throw new Error(error?.message || "An error occurred while updating the publication category.");
    }
};


// Delete Publication Category
export const deletePublicationCategory = async (publicationCategoryId) => {
    try {
        const response = await API1.delete(`/publicationCategories/${publicationCategoryId}`);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "Failed to delete the publication category. It might have associated publications.");
    }
};
