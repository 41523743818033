import React, { Children } from 'react'
import Footer from '../Footer/Footer'
import { Flex } from '@chakra-ui/react'

const AuthenticationContainer = ({Children}) => {
    return (
        <>
            <Flex
                bg='gray.50'
                minH={'100vh'}
                alignItems='center'
                justifyContent='center'
                mt={-10}
                px={{ base: '10', md: '0' }}
            >
                {Children}
            </Flex>
            <Footer/>
        </>
    )
}

export default AuthenticationContainer
