import React, { useEffect, useState } from 'react';
import {
    VStack, Box, Text, Stack, SimpleGrid, Badge, Image, Spinner, Center, Grid, GridItem
} from '@chakra-ui/react';
import FormActionLabel from "../../../../../components/Styled/FormActionLabel";
import SectionHeading from "../../../../../components/SectionHeading/SectionHeading";
import utils from "../../../../../utils/commonFunctions";
import CustomAlert from "../../../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../../../components/Styled/StyledButtons";
import { useDrawer } from "../../../../../Context/DrawerContext/DrawerContext";
import { fetchPhotosByAlbumId } from '../AlbumAPI';

const AlbumViewForm = ({ formData }) => {
    const { closeDrawer } = useDrawer();
    const { title, description, cover, status, createdAt, updatedAt, _id } = formData;
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await fetchPhotosByAlbumId(_id);
                setImages(response);
            } catch (error) {
                console.error('Error fetching images:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchImages();
    }, [_id]);

    const getStatusColor = (status) => {
        return status ? 'green' : 'red';
    };

    return (
        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
            <FormActionLabel formAction="view" formName="Album"/>
            <Grid templateColumns={{ base: "1fr", md: "1fr 2fr" }} gap={4}>
                <GridItem>
                    <Stack spacing={4} px={2} py={4}>
                        <SectionHeading icon="fluent:apps-list-detail-24-filled" text="Album Information" />
                        <SimpleGrid columns={{ base: 1, sm: 1 }} gap={4} px={4}>
                            <Text><strong>Caption: </strong> {title}</Text>
                            <Text><strong>Description: </strong> {description}</Text>
                            {cover && (
                                <Box mt="4">
                                    <Image src={cover} alt="Cover" style={{ width: '100%', borderRadius: '8px' }} />
                                </Box>
                            )}
                            <Text><strong>Status: </strong>
                                <Badge variant="subtle" colorScheme={getStatusColor(status)}>{status}</Badge>
                            </Text>
                            <Text><strong>Created At:</strong> {`${utils.DateFormat(createdAt)}`}</Text>
                            <Text><strong>Updated At:</strong> {`${utils.DateFormat(updatedAt)}`}</Text>
                        </SimpleGrid>
                    </Stack>
                </GridItem>
                <GridItem>
                    <Stack spacing={4} px={2} py={4}>
                        <SectionHeading icon="fa-solid:image" text="Album Images" />
                        {loading ? (
                            <Center>
                                <Spinner />
                            </Center>
                        ) : images.length > 0 ? (
                            <SimpleGrid columns={{ base: 2, sm: 3, md: 4, lg: 4, xl: 4 }} spacing={4} pt={4}>
                                {images.map((image) => (
                                    <Box key={image._id} p={1} borderWidth="1px" borderRadius="lg" overflow="hidden">
                                        <Image src={image.FileUrl} alt="Album Image" boxSize="100px" objectFit="cover" />
                                    </Box>
                                ))}
                            </SimpleGrid>
                        ) : (
                            <Center py={6}>
                                <Text color="gray.500">No Images in this Album</Text>
                            </Center>
                        )}
                    </Stack>
                </GridItem>
            </Grid>
            <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }} mt={8} mb={8}>
                <CustomAlert status="warning" message="View Only Mode" />
                <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                    <CustomButton type="cancel" onClick={closeDrawer} showIcon={false}>
                        Cancel
                    </CustomButton>
                </ButtonStack>
            </Box>
        </VStack>
    );
};

export default AlbumViewForm;
