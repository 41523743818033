import React, { useEffect, useState } from 'react';
import {
    VStack, Box, Text, Stack, Flex, SimpleGrid, Heading, Badge
} from '@chakra-ui/react';
import FormActionLabel from "../../../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../../../components/Styled/StyledButtons";
import { useDrawer } from "../../../../../Context/DrawerContext/DrawerContext";
import {Icon} from "@iconify/react";
import { fetchLabServiceProductByMaterialNumber } from "../../LabServiceProductsAPI";

const DetailSection = ({ icon, title, children, style = {} }) => (
    <Box>
        <Flex align="center" px={2} py={3} bg="blue.50" {...style} mt={8} borderRadius="0.375em" mb={4}>
            <Icon icon={icon} width="20" height="24" style={{ marginRight: '10px', color: '#63b3ed', marginLeft: '10px' }} />
            <Heading size="sm" fontWeight="bold" color="blue.800">{title}</Heading>
        </Flex>
        {children}
    </Box>
);

const LabServiceProductViewForm = ({ formData }) => {
    const { closeDrawer } = useDrawer();
    const [labServiceProductData, setLabServiceProductData] = useState({ name: '', category: '', unitPrice: '' });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const labServiceProduct = await fetchLabServiceProductByMaterialNumber(formData.materialNumber);
                setLabServiceProductData({
                    name: labServiceProduct.name,
                    category: labServiceProduct.category,
                    unitPrice: labServiceProduct.unitPrice,
                    isActive: labServiceProduct.isActive
                });
            } catch (error) {
                console.log('Error fetching lab Service Product details:', error);
            }
        };

        fetchData();
    }, [formData]);

    function itemStatus(isActive) {
        if (!isActive) {
            return <Badge colorScheme="red">Deactivated</Badge>;
        }
        return isActive
            ? <Badge colorScheme="green">Active</Badge> 
            : <Badge colorScheme="red">Deactivated</Badge>;
    }

    return (
        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
            <FormActionLabel formAction="view" formName="Lab Service Product"/>
            <Stack spacing={4} px={2} py={4} mt={4}>
                <DetailSection title="Lab Service Product Information" icon="icomoon-free:lab">
                    <SimpleGrid columns={{ base: 1, sm: 1 }} gap={4} px={4}>
                        <Text><strong>Product Name:</strong> {labServiceProductData.name}</Text>
                        <Text><strong>Category:</strong> {labServiceProductData.category}</Text>
                        <Text><strong>Unit Price:</strong> {labServiceProductData.unitPrice}</Text>
                        <Text><strong>Status:</strong> {itemStatus(labServiceProductData.isActive)}</Text>
                    </SimpleGrid>
                </DetailSection>

                <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}  mt={8} mb={8}>
                    <CustomAlert status="warning" message="View Only Mode" />
                    <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                        <CustomButton type="cancel" onClick={closeDrawer} showIcon={false}>
                            Close
                        </CustomButton>
                    </ButtonStack>
                </Box>
            </Stack>
        </VStack>
    );
};

export default LabServiceProductViewForm;