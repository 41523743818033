import React, { useEffect, useState, useCallback } from 'react';
import PaymentsTable from './PaymentsTable';
import PaymentsTableTopBar from './PaymentsTableTopBar';
import { fetchAllPayments } from '../PaymentsAPI';


const PaymentsContainer = () => {
    const [Payments, setPayments] = useState([]);
    const [error, setError] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [filterOptions, setFilterOptions] = useState({});
    const [fetchTrigger, setFetchTrigger] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const refetchPaymentsData = useCallback(async (options = {}) => {
        setDataLoaded(false);
        try {
            const data = await fetchAllPayments(pageNumber, 10, filterOptions);
            // console.log(data)
            setPayments(data.payments);
            setTotalPages(data.totalPages || 1);
            setError('');
            setDataLoaded(true);
        } catch (error) {
            console.error('Error fetching all Payments:', error.message);
            setError('Failed to fetch Payments data.');
            setPayments([]);
            setTotalPages(0);
        }
    }, [filterOptions, pageNumber]);

    useEffect(() => {
        refetchPaymentsData();
    }, [pageNumber, refreshFlag, refetchPaymentsData, totalPages, fetchTrigger, filterOptions ]);

    return (
        <>
            <PaymentsTableTopBar
                refetchPaymentsReportData={refetchPaymentsData}
                setPageNumber={setPageNumber}
                setFilterOptions={setFilterOptions}
                setFetchTrigger={setFetchTrigger}
                PaymentsReports={Payments}
            />
            <PaymentsTable
                payments={Payments}
                setPayments={setPayments}
                setRefreshFlag={setRefreshFlag}
                refetchPaymentsReportData={refetchPaymentsData}
                error={error}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                filterOptions={filterOptions}
                dataLoaded={dataLoaded}
            />
        </>
    );
};

export default PaymentsContainer;
