import React, { useEffect, useState, useCallback } from 'react';
import LabQuotationRequestTable from "./LabQuotationRequestTable";
import LabQuotationRequestTableTopBar from "./LabQuotationRequestTableTopBar";
import {fetchAllLabQuotationRequests} from "../../LabQuotationRequestsAPI";

const LabQuotationRequestContainer = () => {
    const [labQuotationRequests, setLabQuotationRequests] = useState([]);
    const [error, setError] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [filterOptions, setFilterOptions] = useState({});
    const [fetchTrigger, setFetchTrigger] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const refetchLabQuotationRequestData = useCallback(async (options = {}) => {
        setDataLoaded(false);
        try {
            const data = await fetchAllLabQuotationRequests(pageNumber, 10, filterOptions);
            if (data && data.labQuotationRequests.length > 0) {
                setLabQuotationRequests(data.labQuotationRequests);
                setTotalPages(data.totalPages);
                setDataLoaded(true);
                setError('');
            } else {
                setError('No data found');
                setDataLoaded(true);
            }
        } catch (error) {
            console.error('Error fetching all Lab Service Quotation Requests:', error.message);
            setError('Failed to fetch Lab Service Quotation Requests data.');
            setLabQuotationRequests([]);
            setTotalPages(0);
            setDataLoaded(true);
        }
    }, [filterOptions, pageNumber]);

    useEffect(() => {
        refetchLabQuotationRequestData();
    }, [pageNumber, refreshFlag, refetchLabQuotationRequestData, totalPages, fetchTrigger, filterOptions ]);

    return (
        <>
            <LabQuotationRequestTableTopBar
                refetchLabQuotationRequestData={refetchLabQuotationRequestData}
                setPageNumber={setPageNumber}
                setFilterOptions={setFilterOptions}
                setFetchTrigger={setFetchTrigger}
            />
            <LabQuotationRequestTable
                labQuotationRequests={labQuotationRequests}
                setLabQuotationRequests={setLabQuotationRequests}
                setRefreshFlag={setRefreshFlag}
                refetchLabQuotationRequestData={refetchLabQuotationRequestData}
                error={error}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                filterOptions={filterOptions}
                dataLoaded={dataLoaded}
            />
        </>
    );
};

export default LabQuotationRequestContainer;
