import React, { useEffect, useState, useCallback } from 'react';
import ConnectionTable from './NewConnectionTable';
import ConnectionTableTopBar from './ConnectionTableTopBar';
import { fetchAllConnections } from '../NewConnectionAPI';

const NewConnectionContainer = () => {
    const [connections, setConnections] = useState([]);
    const [error, setError] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [filterOptions, setFilterOptions] = useState({});
    const [fetchTrigger, setFetchTrigger] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const refetchConnectionData = useCallback(async (options = {}) => {
        setDataLoaded(false);
        try {
            const data = await fetchAllConnections(pageNumber, 10, filterOptions);
            if (data && Array.isArray(data.connections)) {
                setConnections(data.connections);
                setTotalPages(data.totalPages || 0);
            }else {
                setConnections([]);
                setTotalPages(0);
            }
            setError('');
            setDataLoaded(true);
        } catch (error) {
            console.error('Error fetching all connections:', error.message);
            setError('Failed to fetch connections data.');
            setConnections([]);
            setTotalPages(0);
        }
    }, [filterOptions, pageNumber]);

    useEffect(() => {
        refetchConnectionData();
    }, [pageNumber, refreshFlag, refetchConnectionData, totalPages, fetchTrigger, filterOptions ]);

    return (
        <>
            <ConnectionTableTopBar
                refetchConnectionData={refetchConnectionData}
                setPageNumber={setPageNumber}
                setFilterOptions={setFilterOptions}
                setFetchTrigger={setFetchTrigger}
            />
            <ConnectionTable
                connections={connections}
                setConnections={setConnections}
                setRefreshFlag={setRefreshFlag}
                refetchConnectionData={refetchConnectionData}
                error={error}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                filterOptions={filterOptions}
                dataLoaded={dataLoaded}
            />
        </>
    );
};

export default NewConnectionContainer;
