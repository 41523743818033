import React from 'react';
import * as Yup from 'yup';
import {Formik, Form, Field } from 'formik';
import {FormControl, Select, Stack} from '@chakra-ui/react';
import {ThemedStyledInput} from "../../../../components/Styled";
import FieldErrorMessage from "../../../../components/FieldErrorMessage/FieldErrorMessage";
import {CustomButton} from "../../../../components/Styled/StyledButtons";
import {Icon} from "@iconify/react";

const validationSchema = Yup.object({
    title: Yup.string().max(100, 'Maximum 100 characters'),
    published: Yup.string(),
    source: Yup.string()
});

const VideoGallerySearchFilter = ({ onFilterChange }) => {
    return (
        <Formik
            initialValues={{
                title: '',
                published: '',
                source: ''
            }}

            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                if (!values.title && !values.published && !values.source) {
                    onFilterChange({ title: '', published: '', source: '' });
                } else {
                    onFilterChange(values);
                }
                setSubmitting(false);
            }}
            onReset={() => {
                onFilterChange({ title: '', published: '', source: '' });
            }}
        >
            {({ handleSubmit, resetForm }) => (
                <Form>
                    <Stack spacing={2} direction="row" align="center">
                        <FormControl flex="2">
                            <Field
                                as={ThemedStyledInput}
                                id="title"
                                name="title"
                                type="text"
                                placeholder="Search by Title"
                            />
                            <FieldErrorMessage name="title" />
                        </FormControl>
                        <FormControl flex="1">
                            <Field as={Select} name="published" placeholder="Filter by Status">
                                <option value="true">Published</option>
                                <option value="false">Unpublished</option>
                            </Field>
                        </FormControl>
                        <FormControl flex="1">
                            <Field as={Select} name="source" placeholder="Filter by Source">
                                <option value="YouTube">YouTube</option>
                                <option value="URL">URL</option>
                            </Field>
                        </FormControl>
                        <CustomButton
                            onClick={handleSubmit}
                            type="search"
                            tooltipLabel="Search"
                            icon={<Icon icon="bi:search" />}
                            showLabel={false}
                        >
                            Search
                        </CustomButton>
                        <CustomButton
                            type="reset"
                            onClick={() => {
                                resetForm();
                                onFilterChange({ title: '', published: '', source: '' });
                            }}
                            tooltipLabel="Clear Filters"
                            icon={<Icon icon="radix-icons:reset" />}
                            showLabel={false}
                        />
                    </Stack>
                </Form>
            )}
        </Formik>
    );
};

export default VideoGallerySearchFilter;
