import React, {useEffect, useRef, useState} from 'react';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    Box,
    Text, InputGroup, InputRightElement, IconButton, SimpleGrid, Select, Spinner
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useDrawer } from '../../../../Context/DrawerContext/DrawerContext';
import { createCity, fetchAllManagementCenters } from "../CitiesAPI";
import { Icon } from "@iconify/react";
import {ThemedStyledInput} from "../../../../components/Styled";
import CustomAlert from "../../../../components/Styled/StyledAlert";
import {ButtonStack, CustomButton} from "../../../../components/Styled/StyledButtons";
import FormActionLabel from "../../../../components/Styled/FormActionLabel";
import ConfirmDialog from "../../../../Context/ConfirmDialog/ConfirmDialog";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";

const CityAddForm = ({ refetchCityData, filterOptions }) => {
    const { closeDrawer } = useDrawer();
    const showToast = useSingleToast();
    const [managementCenters, setManagementCenters] = useState([]);
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });
    const showToastRef = useRef(showToast);



    useEffect(() => {
        const fetchManagementCenters = async () => {
            try {
                const { managementCenters } = await fetchAllManagementCenters();
                setManagementCenters(managementCenters);
            } catch (error) {
                showToastRef.current({
                    title: 'Error',
                    description: 'Failed to fetch management centers',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };
        fetchManagementCenters();
    }, []);


    const citySchema = Yup.object().shape({
        name: Yup.string().required('City name is required.'),
        managementCenterId: Yup.string().required('Management Center is required.')
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            await createCity({
                name: values.name,
                managementCenterId: values.managementCenterId,
            });
            showToast({
                title: 'City added successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchCityData(filterOptions); // Trigger refetching of city data
            closeDrawer();
        } catch (error) {
            showToast({
                title: 'Error',
                description: error.message || 'An unexpected error occurred',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setSubmitting(false);
        }
    };

    const requestConfirm = (options) => {
        setConfirmDialog({
            isOpen: true,
            title: options.title,
            message: options.message,
            onConfirm: options.onConfirm,
        });
    };

    const handleConfirm = () => {
        confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    return (
        <>
            <FormActionLabel formAction="add" formName="City" />
            <Formik
                initialValues={{
                    name: '',
                    managementCenterId: ''
                }}
                validationSchema={citySchema}
                onSubmit={handleSubmit}
            >
                {formikProps => (
                    <Form>
                        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                            <SimpleGrid columns={1} gap={4} px={2} py={4}>
                                <FieldControl
                                    formikProps={formikProps}
                                    name="name"
                                    label="City Name"
                                    placeholder="Enter City Name"
                                    icon={<Icon icon="mdi:rename-outline" />}
                                />
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.managementCenterId && formikProps.touched.managementCenterId}>
                                        <FormLabel htmlFor="managementCenterId">Management Center</FormLabel>
                                        <Field as={Select} name="managementCenterId" placeholder="Select Management Center">
                                            {managementCenters.map((center) => (
                                                <option key={center._id} value={center._id}>
                                                    {center.name}
                                                </option>
                                            ))}
                                        </Field>
                                        {formikProps.errors.managementCenterId && formikProps.touched.managementCenterId && (
                                            <Text color="red.500" fontSize="sm">{formikProps.errors.managementCenterId}</Text>
                                        )}
                                    </FormControl>
                                </GridItem>
                            </SimpleGrid>
                            <AlertAndButtons
                                formikProps={formikProps}
                                closeDrawer={closeDrawer}
                                requestConfirm={requestConfirm}
                            />
                        </VStack>
                    </Form>
                )}
            </Formik>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

const FieldControl = ({ formikProps, name, label, placeholder, icon }) => (
    <GridItem>
        <FormControl isInvalid={formikProps.errors[name] && formikProps.touched[name]}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <InputGroup>
                <ThemedStyledInput
                    {...formikProps.getFieldProps(name)}
                    id={name}
                    placeholder={placeholder}
                />
                {icon && (
                    <InputRightElement>
                        <IconButton
                            aria-label="Icon button label"
                            bg="blue.300"
                            _hover={{ bg: '#69C5EC' }}
                            _active={{ bg: '#4699bc' }}
                            color="white"

                        >
                            {icon}
                        </IconButton>
                    </InputRightElement>

                )}
            </InputGroup>
            {formikProps.errors[name] && formikProps.touched[name] && (
                <Text color="red.500" fontSize="sm">{formikProps.errors[name]}</Text>
            )}
        </FormControl>
    </GridItem>
);

const AlertAndButtons = ({ formikProps, closeDrawer, requestConfirm }) => {

    const handleCancel = () => {
        requestConfirm({
            title: "Cancel Confirmation",
            message: "Are you sure you want to cancel? Any unsaved changes will be lost.",
            onConfirm: () => {
                formikProps.handleReset();
                closeDrawer();
            }
        });
    };

    const handleReset = () => {
        requestConfirm({
            title: "Reset Confirmation",
            message: "Are you sure you want to reset all your changes?",
            onConfirm: () => {
                formikProps.handleReset();
            }
        });
    };

    return (
        <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}>
            <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
            <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                    Cancel
                </CustomButton>
                <CustomButton onClick={handleReset} type="reset">
                    Reset
                </CustomButton>
                <CustomButton
                    type="submit"
                    disabled={formikProps.isSubmitting}
                    style={{
                        opacity: formikProps.isSubmitting ? 0.7 : 1,
                        pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                    }}
                >
                    {formikProps.isSubmitting ? (
                        <>
                            <Spinner size="xs" mr={2} />
                            Submitting...
                        </>
                    ) : 'Submit'}
                </CustomButton>
            </ButtonStack>
        </Box>
    );
};

export default CityAddForm;
