import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

const ImageCarousel = ({images}) => {

  const splideOptions = {
    type: 'loop',
    perPage: 1,
    // autoplay: true,
    pauseOnHover: true,
    pagination: true,
    arrows: true,
    breakpoints: {
      640: {
        perPage: 1,
      },
      768: {
        perPage: 1,
      },
      1024: {
        perPage: 1,
      },
    },
  };

  return (
    <Box
      flex="1"
      bg="gray.200"
      p={4}
      // m={2}
      // borderRadius="md"
      // boxShadow="md"
      width={{ base: '100%', md: '100%' }}
      minHeight={{ base: '450px', md: '450px' }}
      overflow="hidden"
    >
      <Splide options={splideOptions}>
        {images.map((src, index) => (
          <SplideSlide key={index}>
            <Image
              objectFit="fit"
              src={src}
              alt={`Slide ${index}`}
              borderRadius="md"
              width="100%"
              height={{ base: '450px', md: '450px' }}
            />
          </SplideSlide>
        ))}
      </Splide>
    </Box>
  );
};

export default ImageCarousel;
