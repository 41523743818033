import React from 'react'
import BannerContainer from './Components/BannerContainer'
import {Icon} from "@iconify/react";
import DefaultTemplate from "../../components/DefaultTemplate/DefaultTemplate";

const Banners = () => {
    return (
        <>
            <DefaultTemplate 
                Content={ <BannerContainer/> }
                Title={"Advertising Banner Management"}
                icon={<Icon icon="fa-solid:ad" />}
            />
        </>
    )
}

export default Banners
