import React from 'react'
import DefaultTemplate from '../../components/DefaultTemplate/DefaultTemplate'
import GroupEmailManagementContainer from './Components/GroupEmailManagementContainer'
import {Icon} from "@iconify/react";

const GroupEmailManagement = () => {
    return (
        <>
            <DefaultTemplate Content={ <GroupEmailManagementContainer/> } Title={"Group Email Management" } icon={<Icon icon="fa6-solid:building-shield" />}/>
        </>
    )
}

export default GroupEmailManagement
