import React,{useState, useEffect} from 'react';
import { Routes as RouterRoutes, Route } from 'react-router-dom';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import Dashboard from '../pages/Dashboard/Dashboard';
import NotFound from "../error/NotFound";
import PrivateRoute from "../components/Private/PrivateRoute";
import Preloader from "../components/Preloader/Preloader";
import Admin from "../pages/PrimaryFeatures/AdminUserManagement/Admin";
import Login from "../pages/Login/Login";
import OTP from "../pages/VerifyOTP/OTP";
import CreatePassword from "../pages/CreatePassword/CreatePassword";
import AuthRouter from "../components/Authentication/AuthRouter";
import VerificationRouter from "../components/Authentication/VerificationRouter";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import Customer from "../pages/CustomerRelated/CustomerUserManagement/Customer";
import ManagementCenter from "../pages/LocationNetwork/managementCenters/ManagementCenter";
import Cities from "../pages/LocationNetwork/Cities/Cities";
import Areas from "../pages/LocationNetwork/Areas/Areas";
import Consultants from "../pages/LocationNetwork/Consultants/Consultants";
import NewConnection from "../pages/CustomerRelated/NewConnection/NewConnection";
import Notices from "../pages/PrimaryFeatures/Notices/Notices";
import FAQPage from "../pages/FAQ/FAQPage"
import Branches from "../pages/LocationNetwork/Branches/Branches";
import Disconnection from "../pages/CustomerRelated/DisconnectionRequests/Disconnection";

import VideoPage from "../pages/Gallery/Videos/VideoPage"
import Page from "../pages/Pages/Pages"

import ImageMenu from "../pages/Gallery/Images/ImageMenu"
import AlbumPage from "../pages/Gallery/Images/Albums/AlbumPage"
import PhotoPage from "../pages/Gallery/Images/Photos/PhotoPage"
import MediaMenu from "../pages/Gallery/Documents/MediaMenu"

import Project from "../pages/Projects/Projects"
import ProjectView from "../pages/Projects/Components/ProjectView"

import Tender from "../pages/Tenders/Tenders"
import TenderView from "../pages/Tenders/Components/TenderView"
import TenderViewPurchases from "../pages/Tenders/Components/TenderViewPurchase"

import Polls from '../pages/SurveyManagemet/Polls';
import Surveys from '../pages/SurveyManagemet/Surveys/Surveys';
import SurveyView from '../pages/SurveyManagemet/Surveys/SurveyView';
import {AdminPermissions} from "wuc-library/permissions";
import Events from "../pages/Events/Events";
import EventView from "../pages/Events/Components/EventView";
import Banners from "../pages/Banners/Banners";
import LabServiceProducts from "../pages/CustomerRelated/LaboratoryServices/LabServiceProducts";
import LabQuotationRequests from "../pages/CustomerRelated/LaboratoryServices/LabQuotationRequests";
import PublicationCategory from "../pages/Gallery/Publications/PublicationCategories/PublicationCategory";
import Publications from "../pages/Gallery/Publications/Publications/Publications";
import Videos from "../pages/Gallery/VideoGallery/VideoGallery";
import Faqs from "../pages/Gallery/Faqs/Faqs";
import Albums from "../pages/Gallery/ImageGallery/Albums/Albums";
import LeakReport from "../pages/MeterReports/LeakReport/LeakReport";
import OnlinePayments from "../pages/Payments/Payments";
import ShortageReport from "../pages/MeterReports/ShortageReport/ShortageReport";
import News from "../pages/News/News";
import GroupEmailManagement from "../pages/groupEmailManagement/GroupEmailManagement";

const Routes = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000); // 2 seconds delay
    }, []);

    if (isLoading) {
        return <Preloader />;
    }
    return (
        <RouterRoutes>
            {/* Define routes for different pages */}
            <Route element={<AuthRouter />}>
                <Route index element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route element={<VerificationRouter />}>
                    <Route path="/otp" element={<OTP/>} />
                    <Route path="/create-password" element={<CreatePassword />} />
                </Route>
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />
            </Route>

            {/* Private Routes */}
            <Route element={<PrivateRoute permissionsRequired={[]}/>}>
                <Route index element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[AdminPermissions.MANAGEMENT_CENTER]} />}>
                <Route path="/management-centers" element={<ManagementCenter />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[AdminPermissions.CUSTOMER]} />}>
                <Route path="/admin-users" element={<Admin />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[AdminPermissions.CUSTOMER]} />}>
                <Route path="/customers" element={<Customer />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[AdminPermissions.CITIES_LIST]} />}>
                <Route path="/cities" element={<Cities />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[AdminPermissions.AREAS_LIST]} />}>
                <Route path="/areas" element={<Areas />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[]} />}>
                <Route path="/group-emails" element={<GroupEmailManagement />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[AdminPermissions.DOCUMENTS]} />}>
                <Route path="/publication-categories" element={<PublicationCategory />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[AdminPermissions.DOCUMENTS]} />}>
                <Route path="/publications" element={<Publications />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[AdminPermissions.PICTURES]} />}>
                <Route path="/albums" element={<Albums />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[AdminPermissions.VIDEOS]} />}>
                <Route path="/videos" element={<Videos />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[AdminPermissions.FAQ]} />}>
                <Route path="/faqs" element={<Faqs />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[AdminPermissions.DOCUMENTS, AdminPermissions.PICTURES, AdminPermissions.VIDEOS]} />}>
                <Route path="/media-gallery" element={<MediaMenu />} />
                <Route path="/video-gallery" element={<VideoPage />} />
                <Route path="/image-gallery" element={<ImageMenu />} />
                <Route path="/manage-photos" element={<PhotoPage />} />
                <Route path="/manage-albums" element={<AlbumPage />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[AdminPermissions.CUSTOMER_CARE_CONSULTANTS]} />}>
                <Route path="/consultants" element={<Consultants />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[AdminPermissions.BRANCHES]} />}>
                <Route path="/branches" element={<Branches />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[AdminPermissions.CONNECTION_APPLICATIONS]} />}>
                <Route path="/disconnection-requests" element={<Disconnection />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[AdminPermissions.NOTICES]} />}>
                <Route path="/notices" element={<Notices />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[AdminPermissions.INFORMATION_PAGES]} />}>
                <Route path="/pages" element={<Page />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[AdminPermissions.CONNECTION_APPLICATIONS]} />}>
                <Route path="/individual-connection" element={<NewConnection />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[AdminPermissions.ONLINE_POLLS]} />}>
                <Route path="/polls" element={<Polls />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[AdminPermissions.SURVEY]} />}>
                <Route path="/surveys" element={<Surveys />} />
                <Route path="/survey/view" element={<SurveyView />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[]} />}>
                <Route path="/projects" element={<Project />} />
                <Route path="/project/view/*" element={<ProjectView />} />
            </Route>

            <Route element={<PrivateRoute permissionsRequired={[]} />}>
                <Route path="/tenders" element={<Tender />} />
                <Route path="/tender/view/*" element={<TenderView />} />
                <Route path="/tender/purchases/view/*" element={<TenderViewPurchases />} />
            </Route>
            
            <Route element={<PrivateRoute permissionsRequired={[]} />}>
                <Route path="/events" element={<Events />} />
                <Route path="/event/view/*" element={<EventView />} />
            </Route>
                <Route element={<PrivateRoute permissionsRequired={[]} />}>
                    <Route path="/banners" element={<Banners />} />
                </Route>
            <Route element={<PrivateRoute permissionsRequired={[]} />}>
                <Route path="/lab-services-products" element={<LabServiceProducts />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[]} />}>
                <Route path="/lab-services-quotation-requests" element={<LabQuotationRequests />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[]} />}>
                <Route path="/leak-reports" element={<LeakReport />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[]} />}>
                <Route path="/payments" element={<OnlinePayments />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[]} />}>
                <Route path="/shortage-reports" element={<ShortageReport />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[]} />}>
                <Route path="/news" element={<News />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[]} />}>
                <Route path="/*" element={<NotFound />} />
            </Route>
        </RouterRoutes>
    );
};

export default Routes;
