import { API1 } from "../../utils/api";
import utils from "../../utils/commonFunctions";

// #################################
//          PROJECTS API FUNCTIONS
// #################################

// Fetch All LabServiceProducts
export const fetchAllProjects = async (pageNumber = 1, pageSize = 10, filterOptions = {}) => {
    try {
        const queryParams = new URLSearchParams({
            ...filterOptions,
            pageNumber,
            pageSize
        }).toString();

        const response = await API1.get(`/projects?${queryParams}`);

        if (response.status === 200) {
            const { docs: projects, totalDocs } = response.data;
            return {
                projects: response.data,
                totalPages: Math.ceil(totalDocs / pageSize),
            };
        } else {
            throw new Error('Unexpected response from the server while fetching projects.');
        }
    } catch (error) {
        utils.sendMessageToBugNinja("Error while fetching project data:", error.message, error)
        throw new Error('Failed to fetch projects. Please try again.');
    }
};

// addProject API
export const addProject = async (projectData) => {
    try {
        console.table("projectData", projectData);
        const response = await API1.post(`/projects`, projectData);
        if (response.status === 201) {
            return response.data;
        } else {
            throw new Error('Unexpected response from the server while adding project.');
        }
    } catch (error) {
        utils.sendMessageToBugNinja("Error while adding project data:", error.message, error)
        throw new Error('Failed to add project. Please try again.');
    }
};

// fetch projects by id
export const fetchProjectById = async (projectId) => {
    try {
        const response = await API1.get(`/projects/${projectId}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Unexpected response from the server while fetching project.');
        }
    } catch (error) {
        utils.sendMessageToBugNinja("Error while fetching project data by id:", error.message, error)
        throw new Error('Failed to fetch project. Please try again.');
    }
};

// update project API
export const updateProject = async (projectId, projectData) => {
    try {
        console.log("projectData", projectData);
        console.log("projectId", projectId);
        const response = await API1.put(`/projects/${projectId}`, projectData);

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Unexpected response from the server while updating project.');
        }
    } catch (error) {
        utils.sendMessageToBugNinja("Error while updating project data:", error.message, error)
        throw new Error('Failed to update project. Please try again.');
    }
};

// delete project by ID API
export const deleteProject = async (projectId) => {
    try {
        const response = await API1.delete(`/projects/${projectId}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Unexpected response from the server while deleting project.');
        }
    } catch (error) {
        utils.sendMessageToBugNinja("Error while deleting project data:", error.message, error)
        throw new Error('Failed to delete project. Please try again.');
    }
};
