import React from 'react';
import { exportToExcel } from 'react-json-to-excel';
import moment from "moment";
const DownloadExcel = ({data=[],fileName=`excel-export-${moment().format('DD-MM-YYYY')}`}) => {

    return (
        <button onClick={() => exportToExcel(data, `${fileName}-${moment().format('DD-MM-YYYY')}`)}>
            Export as Excel
        </button>
    );
}

export default DownloadExcel;
