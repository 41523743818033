import { API1 } from "../../../utils/api";

// Fetch All Areas
export const fetchAllLeakReports = async (pageNumber = 1, pageSize = 10, filterOptions = {}) => {
    try {
        const queryParams = new URLSearchParams({
            ...filterOptions,
            pageNumber,
            pageSize
        }).toString();

        const response = await API1.get(`/leaks/?${queryParams}`);
        console.log("RESPONSE:",response)
        if (response.status === 200) {
            const { docs: leakReports, totalDocs } = response.data;
            return {
                leakReports,
                totalPages: Math.ceil(totalDocs / pageSize),
            };
        } else {
            throw new Error('Unexpected response from the server while fetching leaks.');
        }
    } catch (error) {
        console.error("Error fetching leaks:", error);
        throw new Error('Failed to fetch leaks. Please try again.');
    }
};

export const exportLeakReports = async () => {
    try {

        const response = await API1.get(`/leaks/export`);

        if (response.status === 200) {
            const leaks = response.data;
            console.log("leaks:",leaks)
            return leaks;
        } else {
            throw new Error('Unexpected response from the server while fetching leaks.');
        }
    } catch (error) {
        console.error("Error fetching leaks:", error);
        throw new Error('Failed to fetch leaks. Please try again.');
    }
};
// Fetch Area by ID using query parameters
export const fetchAreaById = async (areaId) => {
    try {
        const queryParams = new URLSearchParams({ id: areaId }).toString();
        const response = await API1.get(`/areas?${queryParams}`);
        if (response.status === 200 && response.data.docs.length > 0) {
            return response.data.docs[0];
        } else {
            throw new Error('Area not found.');
        }
    } catch (error) {
        throw new Error(error?.message || "Failed to fetch the area details.");
    }
};
export const deleteLeakReport = async (areaId) => {
    try {
        const response = await API1.delete(`/leaks/${areaId}`);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "Failed to delete leak report");
    }
};




