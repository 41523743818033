import React, {useCallback, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box,
    Badge,
    Center,
} from '@chakra-ui/react';
import SurveyEditForm from './SurveyEditForm';
import { useDrawer } from "../../../Context/DrawerContext/DrawerContext";
import {EditButton, ViewButton, DeleteButton} from "../../../components/Styled/ActionButtons";
import { StyledTableContainer } from "../../../components/Styled/StyledComponents";
import PaginationControls from "../../../Context/PaginationControls/PaginationControls";
import { fetchAllSurveys, fetchPollById, deletePoll, deleteSurvey} from "../SurveyAPI";
import ConfirmDialog from "../../../Context/ConfirmDialog/ConfirmDialog";
import 'react-quill/dist/quill.snow.css';
import useSingleToast from "../../../hooks/UseSingleToast/UseSingleToast";

const SurveysTable = ({ surveys, setSurveys, error, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag }) => {
    const { openDrawer } = useDrawer();
    const showToast = useSingleToast();
    const navigate = useNavigate();
    const handleEdit = (surveyData) => {
        openDrawer({
            title: 'Edit Survey',
            component: SurveyEditForm,
            props: {
                surveyData: surveyData,
                refetchSurveyData: refetchSurveyData,
                setRefreshFlag: setRefreshFlag,
            },
            key: `edit-${surveyData._id}`,
        }, 'md');
    };

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    const requestConfirm = (options) => {
        setConfirmDialog({
            isOpen: true,
            title: options.title,
            message: options.message,
            onConfirm: options.onConfirm,
            surveyId: options.surveyId 
        });
    };

    const handleView = async (survey) => {
        navigate('/survey/view/', {state: { surveyData: survey}});
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const deleteOption = (surveyId) => {
        requestConfirm({
            title: "Delete Confirmation",
            message: "Are you sure you want to delete this Survey? If you proceed this survey will be lost forever!",
            onConfirm: handleConfirm,
            surveyId: surveyId
        });
    };

    const handleConfirm = async () => {
        try{
            await deleteSurvey(confirmDialog.surveyId);
            showToast({
                title: 'Survey was deleted successfully',
                description: deletePoll.message,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchSurveyData();
        }catch(error){
            showToast({
                title: 'Error deleting survey data',
                description: error.message || 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
        // confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const refetchSurveyData = useCallback(async () => {
        try {
            const data = await fetchAllSurveys(pageNumber, 10);
            setSurveys(data.surveys);
            setTotalPages(data.totalPages);
        } catch (error) {
            console.error("Fetch surveys error:", error.message);
        }
    }, [pageNumber, setSurveys, setTotalPages]);

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) {
          return text;
        }
        return text.slice(0, maxLength) + '...';
      };


    if (error) {
        return <Box textAlign="center" my="4">Failed to fetch surveys & Survey data: {error}</Box>;
    }


    if (dataLoaded && Array.isArray(surveys) && surveys.length === 0) {
        return <Box textAlign="center" my="4">No records found.</Box>;
    }

    return (
        <>
            <StyledTableContainer>
                <PaginationControls
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    totalPages={totalPages}
                />
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th>#</Th> 
                            <Th>Title</Th>
                            <Th>Objectives</Th>
                            <Th>
                                <Center>
                                    Survey Questions
                                </Center>
                            </Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {surveys?.map((survey, index) => (
                            <Tr key={index}>
                                <Td>S0{index + 1}</Td>
                                <Td>{truncateText(survey.title, 40)}</Td>
                                <Td>
                                    <div dangerouslySetInnerHTML={{ __html: truncateText(survey.description, 50) }} />
                                </Td>
                                <Td>{
                                        <Center>
                                            <Badge ml='1' colorScheme='green'>
                                                {survey.polls?.length}
                                            </Badge>
                                        </Center>
                                    }
                                </Td>
                                <Td>
                                    <ViewButton onClick={() => handleView(survey)} />
                                    <EditButton onClick={() => handleEdit(survey)} />
                                    <DeleteButton onClick={() => deleteOption(survey._id)} />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </StyledTableContainer>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

export default SurveysTable;
