import React from 'react';
import {
    VStack, Box, Text, Stack, SimpleGrid, Badge, AspectRatio
} from '@chakra-ui/react';
import {Icon} from "@iconify/react";
import FormActionLabel from "../../../../components/Styled/FormActionLabel";
import SectionHeading from "../../../../components/SectionHeading/SectionHeading";
import {useDrawer} from "../../../../Context/DrawerContext/DrawerContext";
import utils from "../../../../utils/commonFunctions";
import FileDisplay from "../../../../components/FileDisplay";
import CustomAlert from "../../../../components/Styled/StyledAlert";
import {ButtonStack, CustomButton} from "../../../../components/Styled/StyledButtons";


const VideoGalleryViewForm = ({ formData }) => {
    const { closeDrawer } = useDrawer();
    const { title, videoCode, fileUrl, source, thumbnail, published, description, size } = formData;

    const getStatusColor = (status) => {
        return status ? 'green' : 'red';
    };

    return (
        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
            <FormActionLabel formAction="view" formName="Video"/>
            <Stack spacing={4} px={2} py={4} >
                <SectionHeading icon="fa-solid:file-video" text="Video Information" />
                <SimpleGrid columns={{base: 1, sm: 1}} gap={4} px={4}>
                    <Text><strong>Caption: </strong> {title}</Text>
                    <Text><strong>Video: </strong> {description}</Text>
                    {source === 'YouTube' && (
                        <AspectRatio ratio={16 / 9}>
                            <Box
                                as="iframe"
                                srcDoc={videoCode}
                                width="100%"
                                height="200px"
                                frameBorder="0"
                                allowFullScreen
                            />
                        </AspectRatio>
                    )}
                    {source === 'URL' && fileUrl && (
                        <video width="100%" controls>
                            <source src={fileUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    )}
                    <Text><strong>Source: </strong>
                        <Badge colorScheme="blue" variant="subtle">{source}</Badge>
                    </Text>
                    {/*{thumbnail && <Image src={thumbnail} alt="Thumbnail" boxSize="100px" objectFit="cover" />}*/}
                    <Text><strong>Status: </strong>
                        <Badge variant="subtle" colorScheme={getStatusColor(published)}>{published ? 'Published' : 'Unpublished'}</Badge>
                    </Text>
                    <Text><strong>Created At:</strong> {`${utils.DateFormat(formData.createdAt)}`}</Text>
                    <Text><strong>Updated At:</strong> {`${utils.DateFormat(formData.updatedAt)}`}</Text>

                    <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }} mt={8} mb={8}>
                        <CustomAlert status="warning" message="View Only Mode" />
                        <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                            <CustomButton type="cancel" onClick={closeDrawer} showIcon={false}>
                                Cancel
                            </CustomButton>
                        </ButtonStack>
                    </Box>
                </SimpleGrid>
            </Stack>
        </VStack>
    );
};

export default VideoGalleryViewForm;