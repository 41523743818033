import React, { useCallback } from 'react';
import { Flex, GridItem } from '@chakra-ui/react';
import { Icon } from "@iconify/react";
import {StyledGrid} from "../../../../components/Styled/StyledComponents";
import {ButtonStack, CustomButton} from "../../../../components/Styled/StyledButtons";
import {useDrawer} from "../../../../Context/DrawerContext/DrawerContext";
import VideoGallerySearchFilter from "./VideoGallerySearchFilter";
import VideoGalleryAddForm from "./VideoGalleryAddForm";

const VideoGalleryTableTopBar = ({ refetchVideoData, setPageNumber, setFilterOptions, setFetchTrigger }) => {
    const { openDrawer } = useDrawer();

    const handleFilterChange = useCallback(async (newFilterOptions) => {
        setPageNumber(1);
        await new Promise(resolve => setTimeout(resolve, 0));
        setFilterOptions(newFilterOptions);
        setFetchTrigger(f => !f);
    }, [setPageNumber, setFilterOptions, setFetchTrigger]);



    return (
        <>
            <StyledGrid templateColumns="repeat(12, 1fr)">
                <GridItem colSpan={{ base: 10, md: 11 }}>
                    <VideoGallerySearchFilter onFilterChange={handleFilterChange} />
                </GridItem>
                <GridItem colSpan={{ base: 12, md: 1 }}>
                    <ButtonStack direction="row">
                        <CustomButton
                            type="addNew"
                            showIcon={true}
                            showTooltip={true}
                            tooltipLabel="Add New Video"
                            icon={<Icon icon="carbon:video-add" />}
                            onClick={() => openDrawer({
                                title: 'Add New Video',
                                component: VideoGalleryAddForm,
                                props: { refetchVideoData }
                            }, 'md')}
                        >
                            Add New
                        </CustomButton>
                    </ButtonStack>
                </GridItem>
            </StyledGrid>
            <Flex bg="white" mb={4}></Flex>
        </>
    );
};

export default VideoGalleryTableTopBar;
