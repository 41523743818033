import React, { useState, useEffect } from 'react';
import { Box, useToast, Spinner } from '@chakra-ui/react';
import PhotoTableTopBar from './PhotoTableTopBar';
import PhotoTable from './PhotoTable';
import photoService from '../../../../../services/photoService';

const PhotoContainer = () => {
  const [photos, setPhotos] = useState([]);
  const [filteredPhotos, setFilteredPhotos] = useState([]);
  const [albums, setAlbums] = useState([]); // Assume we need albums for filtering
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    fetchPhotos();
    fetchAlbums();
  }, []);

  const fetchPhotos = async () => {
    setLoading(true);
    try {
      const response = await photoService.getAllPhotos();
      setPhotos(response);
      setFilteredPhotos(response);
    } catch (error) {
      toast({
        title: 'Error fetching photos',
        description: error.message || 'Failed to load photos.',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchAlbums = async () => {
    try {
      const response = await photoService.getAllAlbums(); // Adjust this method if needed
      setAlbums(response);
    } catch (error) {
      toast({
        title: 'Error fetching albums',
        description: error.message || 'Failed to load albums.',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  };

  return (
    <>
      <Box>
        <PhotoTableTopBar
          albums={albums}
          setFilteredPhotos={setFilteredPhotos}
          photos={photos}
        />
        {loading ? (
          <Spinner />
        ) : (
          <PhotoTable
            filteredPhotos={filteredPhotos}
            fetchPhotos={fetchPhotos}
            albums={albums}
          />
        )}
      </Box>
    </>
  );
};

export default PhotoContainer;
