import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDrawer} from "../../../../Context/DrawerContext/DrawerContext";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";
import {deleteVideo, fetchAllVideos, fetchVideoById} from "../VideoGalleryAPI";
import {Badge, Box, IconButton, Table, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import VideoGalleryEditForm from "./VideoGalleryEditForm";
import {StyledTableContainer} from "../../../../components/Styled/StyledComponents";
import PaginationControls from "../../../../Context/PaginationControls/PaginationControls";
import utils from "../../../../utils/commonFunctions";
import {DeleteButton, EditButton, ViewButton} from "../../../../components/Styled/ActionButtons";
import ConfirmDialog from "../../../../Context/ConfirmDialog/ConfirmDialog";
import {Icon} from "@iconify/react";
import VideoGalleryViewForm from "./VideoGalleryViewForm";
import {ViewIcon} from "@chakra-ui/icons";

const VideoGalleryTable = ({ videos, setVideos, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag, filterOptions }) => {
    const { openDrawer } = useDrawer();
    const [error, setError] = useState('');
    const showToast = useSingleToast();
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    const showToastRef = useRef(showToast);


    // Update function encapsulated in useCallback to ensure it's only created once
    const updateVideosAndPages = useCallback((newVideos, totalDocs) => {
        setVideos(newVideos);
        setTotalPages(Math.ceil(totalDocs / 10));
    }, [setVideos, setTotalPages]);

    const refetchVideoData = useCallback(async (options = {}) => {
        setError('');
        try {
            const videosResponse = await fetchAllVideos(pageNumber, 10, filterOptions);

            if (videosResponse && videosResponse.totalPages) {
                updateVideosAndPages(videosResponse.videos, videosResponse.totalPages);
            } else {
                console.error("No totalPages found:", videosResponse);
                setVideos([]);
                setTotalPages(0);
            }
            if (!videosResponse || !videosResponse.videos ) {
                throw new Error('No data received from the server');
            }
            updateVideosAndPages(videosResponse.videos, videosResponse.totalDocs);

        } catch (error) {
            console.error("Error fetching data:", error);
            setError(error.message || 'Failed to fetch data. Please try again later.');
            showToastRef.current({
                title: "Error fetching data",
                description: error.message || "An unexpected error occurred.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    }, [filterOptions, pageNumber, setVideos, setTotalPages, updateVideosAndPages]);

    useEffect(() => {
        refetchVideoData();
    }, [refetchVideoData, pageNumber, filterOptions]);




    if (error) {
        return <Box textAlign="center" my="4">{error}</Box>;
    }

    if (!videos.length && dataLoaded) {
        return <Box textAlign="center" my="4">No records found.</Box>;
    }

    const togglePreview = (id) => {
        setVideos(videos.map(video => {
            if (video._id === id) {
                return { ...video, showPreview: !video.showPreview };
            }
            return video;
        }));
    };




    const handleEdit = (videoId) => {
        openDrawer({
            title: 'Edit Video',
            component: VideoGalleryEditForm,
            props: {
                videoId: videoId,
                refetchVideoData: refetchVideoData,
                setRefreshFlag: setRefreshFlag,
            },
            key: `edit-${videoId}`,
        }, 'md');
    };


    const handleView = async (video) => {
        try {
            const videoData = await fetchVideoById(video._id);
            if (videoData) {
                openDrawer({
                    title: 'View Video',
                    component: VideoGalleryViewForm,
                    props: { formData: videoData },
                    key: `view-${video._id}`,
                }, 'md');
            } else {
                throw new Error('Video data not found.');
            }
        } catch (error) {
            setError(error.message);
            showToast({
                title: 'Error',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };


    const handleDelete = (videoId) => {
        setConfirmDialog({
            isOpen: true,
            title: "Confirm Delete",
            message: "Are you sure you want to delete this video? This action cannot be undone.",
            onConfirm: () => confirmDelete(videoId),
        });
    };

    const confirmDelete = async (videoId) => {
        try {
            await deleteVideo(videoId);
            showToast({
                title: "Video Deleted",
                description: "Successfully deleted the video.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ ...confirmDialog, isOpen: false });
            refetchVideoData(filterOptions); // Refresh the list
        } catch (error) {
            showToast({
                title: "Deletion Failed",
                description: "Could not delete the video. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ ...confirmDialog, isOpen: false });
        }
    };

    return (
        <>
            <StyledTableContainer>
                <PaginationControls pageNumber={pageNumber} setPageNumber={setPageNumber} totalPages={totalPages} />
                {videos.length > 0 && (
                    <Table variant="striped">
                        <Thead>
                            <Tr>
                                <Th>Caption</Th>
                                <Th>Source</Th>
                                <Th>Status</Th>
                                <Th>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {videos.map(video => (
                                <Tr key={video._id}>
                                    <Td>{video.title}</Td>
                                    <Td>{video.source}</Td>
                                    <Td>
                                        <Badge colorScheme={video.published ? 'green' : 'red'}>
                                            {video.published ? 'Published' : 'Unpublished'}
                                        </Badge>
                                    </Td>

                                    <Td>
                                        <ViewButton onClick={() => handleView(video)} />
                                        <EditButton onClick={() => handleEdit(video._id)} />
                                        <DeleteButton onClick={() => handleDelete(video._id)} />
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                )}
            </StyledTableContainer>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                title={confirmDialog.title}
                message={confirmDialog.message}
                onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
                onConfirm={confirmDialog.onConfirm}
            />
        </>
    );
};

export default VideoGalleryTable;
