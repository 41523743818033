import React, { useCallback, useRef, useState } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Box, Badge, Tooltip, IconButton } from '@chakra-ui/react';
import { useDrawer } from "../../../../../Context/DrawerContext/DrawerContext";
import { ViewButton } from "../../../../../components/Styled/ActionButtons";
import {StyledIconButton, StyledTableContainer} from "../../../../../components/Styled/StyledComponents";
import PaginationControls from "../../../../../Context/PaginationControls/PaginationControls";
import { fetchLabQuotationRequestById } from "../../LabQuotationRequestsAPI";
import ConfirmDialog from "../../../../../Context/ConfirmDialog/ConfirmDialog";
import useSingleToast from "../../../../../hooks/UseSingleToast/UseSingleToast";
import getStatusColor from "../LabRequestStatusColors";
import { Icon } from "@iconify/react";
import { useReactToPrint } from "react-to-print";
import utils from "../../../../../utils/commonFunctions";
import LabQuotationRequestViewForm from "./LabQuotationRequestViewForm";
import PrintLabQuotationRequest from "./PrintLabQuotationRequest";

const LabQuotationRequestTable = ({ labQuotationRequests, setLabQuotationRequests, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag, filterOptions }) => {
    const { openDrawer } = useDrawer();
    const [error, setError] = useState('');
    const showToast = useSingleToast();
    const printComponentRef = useRef();
    const [printData, setPrintData] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });


    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current,
        onBeforeGetContent: () => new Promise((resolve) => {
            if (!printData) {
                console.error('Print data is not set.');
                resolve();
            }
            resolve();
        }),
    });

    const preparePrintData = useCallback((labQuotationRequest) => {
        const headerInformation = [{
            key: 'Web Document Number',
            value: labQuotationRequest.zQuotationHeader.webDocNumber || 'N/A'
        }, {
            key: 'Status',
            value: labQuotationRequest.status || 'N/A'
        }, {
            key: 'Created At',
            value: labQuotationRequest.createdAt ? utils.formatDate(labQuotationRequest.createdAt) : 'N/A'
        }, {
            key: 'Updated At',
            value: labQuotationRequest.updatedAt ? utils.formatDate(labQuotationRequest.updatedAt) : 'N/A'
        }];

        const partnerDetails = labQuotationRequest.gtPartners.item.map(partner => [
            { key: 'Partner Number', value: partner.pNumber || 'N/A' },
            { key: 'Name', value: partner.name || 'N/A' },
            { key: 'Street', value: partner.street || 'N/A' },
            { key: 'City', value: partner.city || 'N/A' },
            { key: 'Postal Code', value: partner.postalCode || 'N/A' },
            { key: 'Email Address', value: partner.emailAddress || 'N/A' }
        ]).flat();

        const serviceItems = labQuotationRequest.gtServiceItems.item.map(item => [
            { key: 'Number', value: item.number || 'N/A' },
            { key: 'Material', value: item.material || 'N/A' },
            { key: 'Quantity', value: item.quantity || 'N/A' }
        ]).flat();

        const preparedData = {
            'Quotation Header Information': headerInformation,
            'Partner Details': partnerDetails,
            'Service Items': serviceItems
        };

        setPrintData(preparedData);

        return preparedData;
    }, []);




    const onPrintClick = (labQuotationRequest) => {
        preparePrintData(labQuotationRequest);
        handlePrint();
    };



    const handleView = async (labQuotationRequest) => {
        try {
            const labQuotationRequestData = await fetchLabQuotationRequestById(labQuotationRequest._id);
            if (labQuotationRequestData) {
                openDrawer({
                    title: 'View Lab Service Quotation Request',
                    component: LabQuotationRequestViewForm,
                    props: { formData: labQuotationRequestData },
                    key: `view-${labQuotationRequest._id}`,
                }, 'xl');
            } else {
                throw new Error('Lab Service Quotation Request data not found.');
            }
        } catch (error) {
            showToast({
                title: 'Error',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    if (error) {
        console.log("Rendering error state", error);
        return <Box textAlign="center" my="4">Failed to fetch Lab Service Product data: {error}</Box>;
    }

    if (dataLoaded && Array.isArray(labQuotationRequests) && labQuotationRequests.length === 0) {
        return <Box textAlign="center" my="4">No records found.</Box>;
    }

    return (
        <>
            <StyledTableContainer>
                <PaginationControls pageNumber={pageNumber} setPageNumber={setPageNumber} totalPages={totalPages}/>
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th>Web Document Number</Th>
                            <Th>Partner Number</Th>
                            <Th>Partner Name</Th>
                            <Th>Material</Th>
                            <Th>Quantity</Th>
                            <Th>Email</Th>
                            <Th>Status</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {labQuotationRequests.map(labQuotationRequest => {
                            const firstServiceItem = labQuotationRequest.gtServiceItems?.item?.[0];
                            const firstPartner = labQuotationRequest.gtPartners?.item?.[0];
                            return (
                                <Tr key={labQuotationRequest._id}>
                                    <Td>{labQuotationRequest.zQuotationHeader.webDocNumber}</Td>
                                    <Td>{firstPartner?.pNumber || 'N/A'}</Td>
                                    <Td>{firstPartner?.name || 'N/A'}</Td>
                                    <Td>{firstServiceItem?.material || 'N/A'}</Td>
                                    <Td>{firstServiceItem?.quantity || 'N/A'}</Td>
                                    <Td>{firstPartner?.emailAddress || 'N/A'}</Td>
                                    <Td>
                                        <Badge ml='1' colorScheme={getStatusColor(labQuotationRequest.status)}>
                                            {labQuotationRequest.status}
                                        </Badge>
                                    </Td>
                                    <Td>
                                        <ViewButton onClick={() => handleView(labQuotationRequest)}/>
                                        <Tooltip label="Print">
                                            <IconButton
                                                aria-label="Print"
                                                icon={<Icon icon="material-symbols-light:print-outline"/>}
                                                onClick={() => onPrintClick(labQuotationRequest)}
                                                style={{ minWidth: '32px', height: '32px', padding: '0' }}
                                                variant='outline'
                                            />
                                        </Tooltip>
                                    </Td>
                                </Tr>
                            );
                        })}
                    </Tbody>

                </Table>
                <div style={{display: "none"}}>
                    {printData && (
                        <PrintLabQuotationRequest
                            ref={printComponentRef}
                            data={printData}
                            title={`Laboratory Service Quotation Request : ${printData['Quotation Header Information'].find(x => x.key === 'Web Document Number').value}`}
                        />
                    )}
                </div>
            </StyledTableContainer>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                title={confirmDialog.title}
                message={confirmDialog.message}
                onClose={() => setConfirmDialog({...confirmDialog, isOpen: false})}
                onConfirm={confirmDialog.onConfirm}
            />
        </>
    );
};

export default LabQuotationRequestTable;
