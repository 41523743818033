import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box,
    Badge,
} from '@chakra-ui/react';
import { useDrawer } from "../../../Context/DrawerContext/DrawerContext";
import {EditButton, ViewButton, DeleteButton, PublishButton} from "../../../components/Styled/ActionButtons";
import { StyledTableContainer } from "../../../components/Styled/StyledComponents";
import PaginationControls from "../../../Context/PaginationControls/PaginationControls";
import {fetchAllEvents, fetchEventById, deleteEvent, updateEvent} from "../eventAPI";
import ConfirmDialog from "../../../Context/ConfirmDialog/ConfirmDialog";
import useSingleToast from "../../../hooks/UseSingleToast/UseSingleToast";
import eventEditForm from "./EventEditForm";

const EventsTable = ({ events, setEvents, error, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag }) => {
    const { openDrawer } = useDrawer();
    const showToast = useSingleToast();
    const navigate = useNavigate();

    const handleEdit = (event) => {
        console.log("Inside Table event edit function. Id:"+event._id);
        openDrawer({
            title: 'Edit Event',
            component: eventEditForm,
            props: {
                eventId: event._id,
                eventData: event,
                refetchEventsData: refetchEventsData,
                setRefreshFlag: setRefreshFlag,
            },
            key: `edit-${event._id}`,
        }, 'md');
    };

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => { },
        eventId: null
    });

    const requestConfirm = (options) => {
        setConfirmDialog({
            isOpen: true,
            title: options.title,
            message: options.message,
            onConfirm: options.onConfirm,
            eventId: options.eventId
        });
    };

    const handleView = (eventId) => {
        navigate(`/event/view/${eventId}`, { state: { eventId: eventId } });
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const deleteOption = (eventId) => {
        console.log("Inside the delete option ID:" + eventId);
        console.log(eventId);
        requestConfirm({
            title: "Delete Confirmation",
            message: "Are you sure you want to delete this event? If you proceed this proiject will be lost forever!",
            onConfirm: handleConfirm,
            eventId: eventId
        });
    };

    const handlePublish = async (eventId,eventData) => {
        try {
            await updateEvent(eventId, eventData);
            showToast({
                title: 'Event updated successfully',
                description: deleteEvent.message,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchEventsData();
        } catch (error) {
            console.log("An unexpected error occured while updating record" + error);
            showToast({
                title: 'Error udating event data',
                description: error.message || 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
        // confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };
    const handleConfirm = async () => {
        try {
            await deleteEvent(confirmDialog.eventId);
            showToast({
                title: 'Event  deleted successfully',
                description: deleteEvent.message,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchEventsData();
        } catch (error) {
            console.log("An unexpected error occured while deleting record" + error);
            showToast({
                title: 'Error deleting event data',
                description: error.message || 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
        // confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) {
          return text;
        }
        return text.slice(0, maxLength) + '...';
      };

    const refetchEventsData = useCallback(async () => {
        try {
            const data = await fetchAllEvents(pageNumber, 10);
            setEvents(data.events);
            setTotalPages(data.totalPages);
        } catch (error) {
            console.error("Fetch events error:", error.message);
        }
    }, [pageNumber, setEvents, setTotalPages]);

    useEffect(() => {
        refetchEventsData();
    }, [refetchEventsData]);

    if (error) {
        return <Box textAlign="center" my="4">Failed to fetch Events data: {error}</Box>;
    }

    if (dataLoaded && Array.isArray(events) && events.length === 0) {
        return <Box textAlign="center" my="4">No records found.</Box>;
    }

    return (
        <>
            <StyledTableContainer>
                <PaginationControls
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    totalPages={totalPages}
                />
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th>#</Th>
                            <Th>Title</Th>
                            <Th>Description</Th>
                            <Th>Start Date</Th>
                            <Th>End Date</Th>
                            <Th>Published</Th>
                            <Th isNumeric>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {events?.map((event, index) => (
                            <Tr key={index}>
                                <Td>EV0{index + 1}</Td>
                                {/* <Td>{event.number}</Td> */}
                                <Td>{event.title}</Td>
                                <Td>
                                    <div dangerouslySetInnerHTML={{ __html: truncateText(event.description, 50) }} />
                                </Td>
                                <Td>
                                    <Badge ml='1' colorScheme='green'>
                                        {event.startDate}
                                    </Badge>
                                </Td>
                                <Td>
                                <Badge ml='1' colorScheme='green'>
                                        {event.closingDate}
                                    </Badge>
                                </Td>
                                <Td>
                                    <Badge ml='1' colorScheme={event?.published?'green':'red'}>
                                        {event?.published?'Published':'Unpublished'}
                                    </Badge>
                                </Td>
                                <Td isNumeric>
                                    <ViewButton onClick={() =>
                                        handleView(event._id)
                                    } />
                                    <EditButton onClick={() =>
                                        handleEdit(event)
                                    } />
                                    {!event?.published&&
                                        <PublishButton onClick={() =>
                                            handlePublish(event._id,{published:true})
                                        } />
                                    }

                                    <DeleteButton onClick={() =>
                                        deleteOption(event._id)
                                    } />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </StyledTableContainer>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

export default EventsTable;
