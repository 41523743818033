import React from 'react';
import { Flex, Heading } from '@chakra-ui/react';
import { Icon } from '@iconify/react';

const SectionHeading = ({ icon, text, children, style = {} }) => {
    return (
        <Flex align="center" px={2} py={3} bg="blue.50" {...style} mt={8} borderRadius="0.375em">
            <Icon icon={icon} width="20" height="24" style={{ marginRight: '10px', color: '#63b3ed', marginLeft: '10px' }} />
            <Heading size="sm" fontWeight="bold" color="blue.800">{text}</Heading>
            {children && <span style={{ marginLeft: 'auto' }}>{children}</span>}
        </Flex>
    );
};

export default SectionHeading;
