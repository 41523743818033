import React, {useEffect, useRef, useState} from 'react';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    Box,
    Text,
    SimpleGrid,
    Select,
    InputGroup,
    InputRightElement,
    IconButton, Spinner
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useDrawer } from '../../../../Context/DrawerContext/DrawerContext';
import { fetchAreaById, updateArea, fetchAllCities } from "../AreasAPI";
import { Icon } from "@iconify/react";
import { ThemedStyledInput } from "../../../../components/Styled";
import CustomAlert from "../../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../../components/Styled/StyledButtons";
import FormActionLabel from "../../../../components/Styled/FormActionLabel";
import ConfirmDialog from "../../../../Context/ConfirmDialog/ConfirmDialog";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";

const AreaEditForm = ({ areaId, refetchAreaData }) => {
    const notifyUserRef = useRef();
    const { closeDrawer } = useDrawer();
    const showToast = useSingleToast();
    const [cities, setCities] = useState([]);
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });
    const [initialValues, setInitialValues] = useState({
        name: '',
        cityId: ''
    });


    notifyUserRef.current = (options) => {
        showToast(options);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const areaData = await fetchAreaById(areaId);
                const citiesData = await fetchAllCities();
                if (areaData && citiesData) {
                    setInitialValues({
                        name: areaData.name,
                        cityId: areaData.cityId,
                    });
                    setCities(citiesData.cities);
                }
            } catch (error) {
                // Use the current property to access the latest version of `notifyUser`
                notifyUserRef.current({
                    title: 'Error',
                    description: 'Failed to fetch data',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };
        fetchData();
    }, [areaId]);


    const areaSchema = Yup.object().shape({
        name: Yup.string().required('Area name is required.'),
        cityId: Yup.string().required('City is required.')
    });

    const handleSubmit = async (values, actions) => {
        try {
            await updateArea(areaId, values);
            showToast({
                title: 'Area updated successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchAreaData(); // Refresh the data
            closeDrawer();
        } catch (error) {
            showToast({
                title: 'Error',
                description: error.message || 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            actions.setSubmitting(false);
        }
    };

    const handleCancel = () => {
        setConfirmDialog({
            isOpen: true,
            title: "Cancel Confirmation",
            message: "Are you sure you want to cancel? Any unsaved changes will be lost.",
            onConfirm: () => {
                closeDrawer();
                setConfirmDialog({ ...confirmDialog, isOpen: false }); // Close dialog after confirmation
            }
        });
    };


    const handleConfirm = () => {
        confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    return (
        <>
            <FormActionLabel formAction="edit" formName="Area" />
            <Formik
                initialValues={initialValues}
                validationSchema={areaSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {formikProps => (
                    <Form>
                        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                            <SimpleGrid columns={1} gap={4} px={2} py={4}>
                                <FieldControl
                                    formikProps={formikProps}
                                    name="name"
                                    label="Area Name"
                                    placeholder="Enter Area Name"
                                    icon={<Icon icon="gis:map-edit" />}
                                />
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.cityId && formikProps.touched.cityId}>
                                        <FormLabel htmlFor="cityId">City</FormLabel>
                                        <Field as={Select} name="cityId" placeholder="Select City">
                                            {cities.map((city) => (
                                                <option key={city._id} value={city._id}>
                                                    {city.name}
                                                </option>
                                            ))}
                                        </Field>
                                        {formikProps.errors.cityId && formikProps.touched.cityId && (
                                            <Text color="red.500" fontSize="sm">{formikProps.errors.cityId}</Text>
                                        )}
                                    </FormControl>
                                </GridItem>
                            </SimpleGrid>
                            <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}  mt={8} mb={8}>
                                <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
                                <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                                    <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                                        Cancel
                                    </CustomButton>
                                    <CustomButton
                                        type="submit"
                                        disabled={formikProps.isSubmitting}
                                        style={{
                                            opacity: formikProps.isSubmitting ? 0.7 : 1,
                                            pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                                        }}
                                    >
                                        {formikProps.isSubmitting ? (
                                            <>
                                                <Spinner size="xs" mr={2} />
                                                Updating...
                                            </>
                                        ) : 'Update'}
                                    </CustomButton>
                                </ButtonStack>
                            </Box>
                        </VStack>
                    </Form>
                )}
            </Formik>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

const FieldControl = ({ formikProps, name, label, placeholder, icon }) => (
    <GridItem>
        <FormControl isInvalid={formikProps.errors[name] && formikProps.touched[name]}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <InputGroup>
                <ThemedStyledInput
                    {...formikProps.getFieldProps(name)}
                    id={name}
                    placeholder={placeholder}
                />
                {icon && (
                    <InputRightElement>
                        <IconButton
                            aria-label="Icon button label"
                            bg="blue.300"
                            _hover={{ bg: '#69C5EC' }}
                            _active={{ bg: '#4699bc' }}
                            color="white"
                        >
                            {icon}
                        </IconButton>
                    </InputRightElement>
                )}
            </InputGroup>
            {formikProps.errors[name] && formikProps.touched[name] && (
                <Text color="red.500" fontSize="sm">{formikProps.errors[name]}</Text>
            )}
        </FormControl>
    </GridItem>
);




export default AreaEditForm;
