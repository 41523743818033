import React from 'react'
import DefaultTemplate from '../../../components/DefaultTemplate/DefaultTemplate'
import BranchUserContainer from './Components/BranchContainer'
import {Icon} from "@iconify/react";

const Branches = () => {
    return (
        <>
            <DefaultTemplate Content={ <BranchUserContainer/> } Title={"Branches Management" } icon={<Icon icon="mdi:locations" />}/>
        </>
    )
}

export default Branches
