import { API1 } from "../../../utils/api";

// Create City
export const createNotices= async (cityData) => {
    try {
        const response = await API1.post('/notices', cityData);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || "An unexpected error occurred while creating notice.");
    }
};

export const fetchAllCities= async (pageNumber = 1, pageSize = 10, filterOptions = {}) => {
    try {
        const queryParams = new URLSearchParams({
            ...filterOptions,
            pageNumber,
            pageSize
        }).toString();

        const response = await API1.get(`/cities?${queryParams}`);

        if (response.status === 200) {
            const { docs: cities, totalDocs } = response.data;
            return {
                cities,
                totalPages: Math.ceil(totalDocs / pageSize),
            };
        } else {
            throw new Error('Unexpected response from the server while fetching cities.');
        }
    } catch (error) {
        console.error("Error fetching cities:", error);
        throw new Error('Failed to fetch cities. Please try again.');
    }
};

// Fetch All Notices
export const fetchAllNotices= async (pageNumber = 1, pageSize = 10, filterOptions = {}) => {
    try {
        const queryParams = new URLSearchParams({
            ...filterOptions,
            pageNumber,
            pageSize
        }).toString();

        const response = await API1.get(`/notices?${queryParams}`);

        if (response.status === 200) {
            const { docs: notices, totalDocs } = response.data;
            return {
                notices,
                totalPages: Math.ceil(totalDocs / pageSize),
            };
        } else {
            throw new Error('Unexpected response from the server while fetching notices.');
        }
    } catch (error) {
        console.error("Error fetching notices:", error);
        throw new Error('Failed to fetch notices. Please try again.');
    }
};

// Fetch City by ID using query parameters
export const fetchNoticeById = async (noticeId) => {
    try {
        const queryParams = new URLSearchParams({ id: noticeId }).toString();
        const response = await API1.get(`/notices?${queryParams}`);
        if (response.status === 200 && response.data.docs.length > 0) {
            return response.data.docs[0];
        } else {
            throw new Error('Notice not found.');
        }
    } catch (error) {
        throw new Error(error.response?.data?.message || "Failed to fetch the notice details.");
    }
};

// Update City
export const updateNotice = async (noticeId, noticeData) => {
    try {
        const response = await API1.put(`/notices/${noticeId}`, noticeData);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || "An error occurred while updating the notice.");
    }
};

// Delete City
export const deleteNotice = async (cityId) => {
    try {
        const response = await API1.delete(`/notices/${cityId}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || "Failed to delete notice");
    }
};


export const fetchAllManagementCenters = async (pageNumber = 1, pageSize = 10, filterOptions = {}) => {
    try {
        const queryParams = new URLSearchParams({
            ...filterOptions,
            pageNumber,
            pageSize
        }).toString();

        const response = await API1.get(`/managementCenters?${queryParams}`);

        if (response.status === 200) {
            const { docs: managementCenters, totalDocs } = response.data;
            return {
                managementCenters,
                totalPages: Math.ceil(totalDocs / pageSize),
            };
        } else {
            throw new Error('Unexpected response from the server while fetching management centers.');
        }
    } catch (error) {
        console.error("Error fetching management centers:", error);
        throw new Error('Failed to fetch cities. Please try again.');
    }
};

// Fetch Management Center by ID using query parameters
export const fetchManagementCenterById = async (managementCenterId) => {
    try {
        const queryParams = new URLSearchParams({ id: managementCenterId }).toString();
        const response = await API1.get(`/managementCenters?${queryParams}`);
        if (response.status === 200 && response.data.docs.length > 0) {
            return response.data.docs[0];
        } else {
            throw new Error('Management Center not found.');
        }
    } catch (error) {
        throw new Error(error.response?.data?.message || "Failed to fetch the Management Center details.");
    }
};