import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box,
    Badge,
} from '@chakra-ui/react';
import { useDrawer } from "../../../Context/DrawerContext/DrawerContext";
import { EditButton, ViewButton, DeleteButton } from "../../../components/Styled/ActionButtons";
import { StyledTableContainer } from "../../../components/Styled/StyledComponents";
import PaginationControls from "../../../Context/PaginationControls/PaginationControls";
import { fetchAllProjects, fetchProjectById, deleteProject } from "../projectAPI";
import ConfirmDialog from "../../../Context/ConfirmDialog/ConfirmDialog";
import useSingleToast from "../../../hooks/UseSingleToast/UseSingleToast";
import projectEditForm from "./ProjectEditForm";
import utils from '../../../utils/commonFunctions';

const ProjectsTable = ({ projects, setProjects, error, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag }) => {
    const { openDrawer } = useDrawer();
    const showToast = useSingleToast();
    const navigate = useNavigate();

    const handleEdit = (project) => {
        console.log("Inside Table project edit function. Id:"+project._id);
        openDrawer({
            title: 'Edit Project',
            component: projectEditForm,
            props: {
                projectId: project._id,
                projectData: project,
                refetchProjectsData: refetchProjectsData,
                setRefreshFlag: setRefreshFlag,
            },
            key: `edit-${project._id}`,
        }, 'md');
    };

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => { },
        projectId: null
    });

    const requestConfirm = (options) => {
        setConfirmDialog({
            isOpen: true,
            title: options.title,
            message: options.message,
            onConfirm: options.onConfirm,
            projectId: options.projectId
        });
    };

    const handleView = (projectId) => {
        navigate(`/project/view/${projectId}`, { state: { projectId: projectId } });
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const deleteOption = (projectId) => {
        console.log("Inside the delete option ID:" + projectId);
        console.log(projectId);
        requestConfirm({
            title: "Delete Confirmation",
            message: "Are you sure you want to delete this project? If you proceed this project will be lost forever!",
            onConfirm: handleConfirm,
            projectId: projectId
        });
    };

    const handleConfirm = async () => {
        try {
            await deleteProject(confirmDialog.projectId);
            showToast({
                title: 'Project deleted successfully',
                description: deleteProject.message,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchProjectsData();
        } catch (error) {
            utils.sendMessageToBugNinja("Error occured when deleting project:", error.message, error)
            showToast({
                title: 'Error deleting projest data',
                description: error.message || 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
        // confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) {
          return text;
        }
        return text.slice(0, maxLength) + '...';
      };

    const refetchProjectsData = useCallback(async () => {
        try {
            const data = await fetchAllProjects(pageNumber, 10);
            setProjects(data.projects);
            setTotalPages(data.totalPages);
        } catch (error) {
            utils.sendMessageToBugNinja("Error fetching project data:", error.message, error)
        }
    }, [pageNumber, setProjects, setTotalPages]);

    useEffect(() => {
        refetchProjectsData();
    }, [refetchProjectsData]);

    if (error) {
        return <Box textAlign="center" my="4">Failed to fetch projects data: {error}</Box>;
    }

    if (dataLoaded && Array.isArray(projects) && projects.length === 0) {
        return <Box textAlign="center" my="4">No records found.</Box>;
    }

    return (
        <>
            <StyledTableContainer>
                <PaginationControls
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    totalPages={totalPages}
                />
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th>#</Th>
                            <Th>Title</Th>
                            <Th>Description</Th>
                            <Th>Start Date</Th>
                            <Th>End Date</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {projects?.map((project, index) => (
                            <Tr key={index}>
                                <Td>PR0{index + 1}</Td>
                                {/* <Td>{project.number}</Td> */}
                                <Td>{truncateText(project.title, 40)}</Td>
                                <Td>
                                    <div dangerouslySetInnerHTML={{ __html: truncateText(project.description, 50) }} />
                                </Td>
                                <Td>
                                    <Badge ml='1' colorScheme='green'>
                                        {project.startDate}
                                    </Badge>
                                </Td>
                                <Td>
                                <Badge ml='1' colorScheme='green'>
                                        {project.closingDate}
                                    </Badge>
                                </Td>
                                <Td>
                                    <ViewButton onClick={() =>
                                        handleView(project._id)
                                    } />
                                    <EditButton onClick={() =>
                                        handleEdit(project)
                                    } />
                                    <DeleteButton onClick={() =>
                                        deleteOption(project._id)
                                    } />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </StyledTableContainer>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

export default ProjectsTable;
