import React from 'react';
import DefaultTemplate from '../../../components/DefaultTemplate/DefaultTemplate';
import VideoContainer from './Components/VideoContainer'; // Adjust path as necessary
import { Icon } from "@iconify/react";

const VideoPage = () => {
    // Define or import icons for different types of content if needed
    const icon = "mdi:video";

    // Define the title for the video management page
    const title = "Video Management";

    return (
        <DefaultTemplate
            Content={<VideoContainer />}
            Title={<>
                <Icon icon={icon} /> {title}
            </>}
        />
    );
};

export default VideoPage;
