import React from 'react';
import DefaultTemplate from '../../components/DefaultTemplate/DefaultTemplate';
import { Icon } from "@iconify/react";
import PageContainer from "./components/PageContainer";  // Assuming usage for consistent icons across your app

const Page = () => {
    return (
        <DefaultTemplate 
            Content={<PageContainer />} 
            Title={"Page Management"} 
            icon={<Icon icon="mdi:file-document-outline" />}  // Replace with the appropriate icon
        />
    );
};

export default Page;
