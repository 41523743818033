import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    Box,
    Text,
    InputGroup,
    InputRightElement,
    IconButton,
    SimpleGrid,
    Spinner,
    Textarea,
    Select
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDrawer } from '../../../../../Context/DrawerContext/DrawerContext';
import { Icon } from "@iconify/react";
import { ThemedStyledInput } from "../../../../../components/Styled";
import CustomAlert from "../../../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../../../components/Styled/StyledButtons";
import FormActionLabel from "../../../../../components/Styled/FormActionLabel";
import useSingleToast from "../../../../../hooks/UseSingleToast/UseSingleToast";
import { createPublication, fetchAllPublicationsCategories } from "../PublicationsAPI";
import utils from "../../../../../utils/commonFunctions";
import ConfirmDialog from "../../../../../Context/ConfirmDialog/ConfirmDialog";
import DropBox from "../../../../../components/DropBox/DropBox";

const PublicationAddForm = ({ refetchPublicationData, filterOptions }) => {
    const { closeDrawer } = useDrawer();
    const showToast = useSingleToast();
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fileData, setFileData] = useState({ fileUrl: null, fileName: null, fileType: null, size: null });
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    const showToastRef = useRef(showToast);

    useEffect(() => {
        const fetchPublicationCategories = async () => {
            try {
                const { publicationCategories } = await fetchAllPublicationsCategories();
                setCategories(publicationCategories.map(cat => ({ label: cat.name, value: cat._id })));
            } catch (error) {
                showToastRef.current({
                    title: 'Error',
                    description: 'Failed to fetch categories',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };
        fetchPublicationCategories();
    }, []);

    const publicationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required.'),
        description: Yup.string().required('Description is required.'),
        categoryId: Yup.string().required('Category is required.'),
        status: Yup.string().required('Status is required.'),
        fileData: Yup.object({
            fileUrl: Yup.string().required('Publication document is required.'),
            fileName: Yup.string().required('File name is required.'),
            extension: Yup.string().required('File extension is required.'),
        })
    });


    const handleFileChange = async (fieldName, event, setFieldValue) => {
        const file = event.target.files[0];
        const fileId = uuidv4();
        if (file) {
            setLoading(prev => ({ ...prev, [fieldName]: true }));
            try {
                const uploadResponse = await utils.handleFileUpload(file, `connection/${fileId}`);
                if (uploadResponse && uploadResponse.data && uploadResponse.data.fileUrl) {
                    const fileExtension = utils.getFileExtensionFromUrl(file?.name);
                    setFieldValue(`fileData.${fieldName}`, uploadResponse.data.fileUrl);
                    setFieldValue(`fileData.fileId`, fileId);
                    setFieldValue(`fileData.fileName`, file?.name);
                    setFieldValue(`fileData.fileType`, file?.type);
                    setFieldValue('fileData.extension', fileExtension);
                    setFieldValue(`fileData.size`, file?.size);
                } else {
                    setFieldValue(`fileData.${fieldName}.error`, 'Upload failed. Incomplete response received.');
                }
            } catch (error) {
                console.error("File upload error:", error);
                setFieldValue(`fileData.${fieldName}.error`, 'Upload failed: ' + error.message);
            } finally {
                setLoading(prev => ({ ...prev, [fieldName]: false }));
            }
        }
    };



    const handleSubmit = async (values, actions) => {
        if (!values.fileData.fileUrl) {
            showToast({
                title: 'File not uploaded',
                description: 'Please upload a file before submitting.',
                status: 'warning',
                duration: 5000,
                isClosable: true,
            });
            actions.setSubmitting(false);
            return;
        }

        const submissionData = {
            ...values,
            fileId: values.fileData.fileId,
            fileName: values.fileData.fileName,
            fileType: values.fileData.fileType,
            extension: values.fileData.extension,
            fileUrl: values.fileData.fileUrl,
            size: values.fileData.size,
        };

        try {
            await createPublication(submissionData);
            refetchPublicationData(filterOptions);
            closeDrawer();
            showToast({
                title: 'Success',
                description: 'Publication added successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            showToast({
                title: 'Error',
                description: error.message || 'An unexpected error occurred',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            actions.setSubmitting(false);
        }
    };


    const requestConfirm = (options) => {
        setConfirmDialog({
            isOpen: true,
            title: options.title,
            message: options.message,
            onConfirm: options.onConfirm,
        });
    };

    const handleConfirm = () => {
        confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    return (
        <>
            <FormActionLabel formAction="add" formName="Publication" />
            <Formik
                initialValues={{
                    title: '',
                    description: '',
                    categoryId: '',
                    status: 'Archived',
                    fileData: {
                        fileId: null,
                        fileUrl: null,
                        fileName: null,
                        fileType: null,
                        size: null,
                        extension: '',
                    }
                }}
                validationSchema={publicationSchema}
                onSubmit={handleSubmit}
            >
                {(formikProps) => (
                    <Form>
                        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                            <SimpleGrid columns={1} gap={4} px={2} py={4}>
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.title && formikProps.touched.title}>
                                        <FormLabel htmlFor="title">Publication Title</FormLabel>
                                        <InputGroup>
                                            <ThemedStyledInput
                                                {...formikProps.getFieldProps('title')}
                                                id="title"
                                                placeholder="Enter Publication Title"
                                            />
                                            <InputRightElement>
                                                <IconButton
                                                    aria-label="Icon button label"
                                                    bg="blue.300"
                                                    _hover={{ bg: '#69C5EC' }}
                                                    _active={{ bg: '#4699bc' }}
                                                    color="white"
                                                    icon={<Icon icon="mdi:rename-outline" />}
                                                />
                                            </InputRightElement>
                                        </InputGroup>
                                        {formikProps.errors.title && formikProps.touched.title && (
                                            <Text color="red.500" fontSize="sm">{formikProps.errors.title}</Text>
                                        )}
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.description && formikProps.touched.description}>
                                        <FormLabel htmlFor="description">Description</FormLabel>
                                        <Textarea
                                            {...formikProps.getFieldProps('description')}
                                            id="description"
                                            placeholder="Enter Description"
                                            borderWidth="2px"
                                            focusBorderColor="#63B3ED"
                                        />
                                        {formikProps.errors.description && formikProps.touched.description && (
                                            <Text color="red.500" fontSize="sm">{formikProps.errors.description}</Text>
                                        )}
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.categoryId && formikProps.touched.categoryId}>
                                        <FormLabel htmlFor="categoryId">Category</FormLabel>
                                        <Select
                                            {...formikProps.getFieldProps('categoryId')}
                                            id="categoryId"
                                            placeholder=" -- Select Category -- "
                                            borderWidth="2px"
                                            focusBorderColor="#63B3ED"
                                        >
                                            {categories.map(option => (
                                                <option key={option.value} value={option.value}>{option.label}</option>
                                            ))}
                                        </Select>
                                        {formikProps.errors.categoryId && formikProps.touched.categoryId && (
                                            <Text color="red.500" fontSize="sm">{formikProps.errors.categoryId}</Text>
                                        )}
                                    </FormControl>
                                 </GridItem>
                                <GridItem>
                                    <FormControl
                                        isInvalid={formikProps.errors.fileData && formikProps.errors.fileData.fileUrl && formikProps.touched.fileData && formikProps.touched.fileData.fileUrl}>
                                        <DropBox
                                            label="Publication Document"
                                            name="fileData.fileUrl"
                                            filePath={formikProps.values.fileData.fileUrl}
                                            loading={loading.fileUrl}
                                            formikProps={formikProps}
                                            callback={(event) => handleFileChange('fileUrl', event, formikProps.setFieldValue)}
                                            result={formikProps.values.fileData.fileUrl}
                                            instruction="Please attach relevant documents."
                                        />
                                        {/*<pre>{JSON.stringify(formikProps.errors, null, 2)}</pre>
                                        <pre>{JSON.stringify(formikProps.values, null, 2)}</pre>*/}
                                        {formikProps.errors.fileData && formikProps.errors.fileData.fileUrl && formikProps.touched.fileData && formikProps.touched.fileData.fileUrl && (
                                            <Text color="red.500"
                                                  fontSize="sm">{formikProps.errors.fileData.fileUrl}</Text>
                                        )}
                                    </FormControl>
                                </GridItem>


                                <GridItem>
                                <FormControl isInvalid={formikProps.errors.status && formikProps.touched.status}>
                                        <FormLabel htmlFor="status">Status</FormLabel>
                                        <Select
                                            {...formikProps.getFieldProps('status')}
                                            id="status"
                                            placeholder=" -- Select Status -- "
                                            borderWidth="2px"
                                            focusBorderColor="#63B3ED"
                                        >
                                            <option value="Active">Publish</option>
                                            <option value="Archived">Archived</option>
                                        </Select>
                                        {formikProps.errors.status && formikProps.touched.status && (
                                            <Text color="red.500" fontSize="sm">{formikProps.errors.status}</Text>
                                        )}
                                    </FormControl>
                                </GridItem>

                            </SimpleGrid>
                        <AlertAndButtons
                            formikProps={formikProps}
                            closeDrawer={closeDrawer}
                            requestConfirm={requestConfirm}
                        />
                    </VStack>
                    </Form>
                    )}
            </Formik>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};


const AlertAndButtons = ({ formikProps, closeDrawer, requestConfirm }) => {

    const handleCancel = () => {
        requestConfirm({
            title: "Cancel Confirmation",
            message: "Are you sure you want to cancel? Any unsaved changes will be lost.",
            onConfirm: () => {
                formikProps.handleReset();
                closeDrawer();
            }
        });
    };

    const handleReset = () => {
        requestConfirm({
            title: "Reset Confirmation",
            message: "Are you sure you want to reset all your changes?",
            onConfirm: () => {
                formikProps.handleReset();
            }
        });
    };

    return (
        <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}>
            <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
            <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                    Cancel
                </CustomButton>
                <CustomButton onClick={handleReset} type="reset">
                    Reset
                </CustomButton>
                <CustomButton
                    type="submit"
                    disabled={formikProps.isSubmitting}
                    style={{
                        opacity: formikProps.isSubmitting ? 0.7 : 1,
                        pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                    }}
                >
                    {formikProps.isSubmitting ? (
                        <>
                            <Spinner size="xs" mr={2} />
                            Submitting...
                        </>
                    ) : 'Submit'}
                </CustomButton>
            </ButtonStack>
        </Box>
    );
};

export default PublicationAddForm;
