import { API1 } from "../../../utils/api";

// Add Customer
export const addCustomer = async (customerData) => {
    try {
        const dataWithRoleAndCreator = {
            ...customerData,
            role: 'user',
            createdBy: 'admin',
        };
        const response = await API1.post('/auth/signup', dataWithRoleAndCreator);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Fetch All Customers
export const fetchAllCustomers = async (pageNumber = 1, pageSize = 10, filterOptions = {}) => {
    try {
        filterOptions.role= 'user';
        const data = {
            filterOptions,
            pageNumber,
            pageSize
        };

        const response = await API1.post('/auth/users', data);
        if (response.status === 200) {
            const { docs: customers, totalDocs } = response.data.data;
            return {
                customers,
                totalPages: Math.ceil(totalDocs / pageSize),
            };
        } else {
            console.log(response.status)
            throw new Error('Unexpected response from the server while fetching customers.');
        }
    } catch (error) {
        console.error("Error fetching customers:", error);
        throw new Error('Failed to fetch customers. Please try again.');
    }
};

// Fetch Customer by ID
export const fetchCustomerById = async (customerId) => {
    try {
        const data = {
            filterOptions: { id: customerId, role: 'user' },
            pageNumber: 1,
            pageSize: 1
        };

        const response = await API1.post('/auth/users', data);
        if (response.data.status === 'Success' && response.data.data.docs.length > 0) {
            const customerData = response.data.data.docs[0];
            return customerData;
        } else {
            throw new Error('Customer not found.');
        }
    } catch (error) {
        throw error;
    }
};

// Update Customer
export const updateCustomer = async (customerId, customerData) => {
    try {
        const response = await API1.put('/auth/user/update', { _id: customerId, ...customerData });
        return response.data;
    } catch (error) {
        throw error;
    }
};


// Fetch User Profile by userId
export const fetchUserProfileById = async (userId) => {
    try {
        const queryParams = new URLSearchParams({ userId: userId }).toString();
        const response = await API1.get(`/kyc/get?${queryParams}`);
        if (response.data.status === 'Success' && response.data.data) {
            return { success: true, data: response.data.data.docs};
        } else {
            return {
                success: false,
                message: response.data.message || 'User has not yet updated his KYC.'
            };
        }
    } catch (error) {
        throw error;
    }
};


// Update User Profile

export const updateUserProfile = async (profileData) => {
    try {
        const response = await API1.put('/kyc/update', profileData);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Failed to update profile: ' + response.data.message);
        }
    } catch (error) {
        if (error.response) {

            throw new Error('Server error: ' + error.response.data.error);
        } else if (error.request) {

            throw new Error('Network error: Unable to reach the server.');
        } else {
            throw new Error('Error: ' + error.message);
        }
    }
};

/*export const updateUserProfile = async (profileData) => {
    try {
        const response = await API1.put('/kyc/update', JSON.stringify(profileData), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Update failed:', error.response ? error.response.data : error.message);
        throw error;
    }
};*/

