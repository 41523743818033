import React from 'react';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    Box,
    Text, InputGroup, InputRightElement, IconButton, SimpleGrid, Spinner
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDrawer } from '../../../../../Context/DrawerContext/DrawerContext';
import { createPublicationCategory } from "../PublicationCategoryAPI";
import { Icon } from "@iconify/react";
import {ThemedStyledInput} from "../../../../../components/Styled";
import CustomAlert from "../../../../../components/Styled/StyledAlert";
import {ButtonStack, CustomButton} from "../../../../../components/Styled/StyledButtons";
import FormActionLabel from "../../../../../components/Styled/FormActionLabel";
import useSingleToast from "../../../../../hooks/UseSingleToast/UseSingleToast";

const PublicationCategoryAddForm = ({ refetchPublicationCategoryData, filterOptions }) => {
    const { closeDrawer } = useDrawer();
    const showToast = useSingleToast();

    const publicationCategorySchema = Yup.object().shape({
        name: Yup.string().required('Publication Category name is required.')
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            await createPublicationCategory(values);
            showToast({
                title: 'Publication Category added successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchPublicationCategoryData(filterOptions);
            closeDrawer();
        } catch (error) {
            showToast({
                title: 'Error',
                description: error?.message || 'An unexpected error occurred',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <FormActionLabel formAction="add" formName="Publication Category" />
            <Formik
                initialValues={{
                    name: '',
                    email: ''
                }}
                validationSchema={publicationCategorySchema}
                onSubmit={handleSubmit}
            >
                {formikProps => (
                    <Form>
                        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                            <SimpleGrid columns={1} gap={4} px={2} py={4}>
                                <FieldControl
                                    formikProps={formikProps}
                                    name="name"
                                    label="Publication Category Name"
                                    placeholder="Enter Category Name"
                                    icon={<Icon icon="mdi:rename-outline" />}
                                />
                            </SimpleGrid>
                            <AlertAndButtons
                                formikProps={formikProps}
                                closeDrawer={closeDrawer}
                            />
                        </VStack>
                    </Form>
                )}
            </Formik>
        </>
    );
};

const FieldControl = ({ formikProps, name, label, placeholder, icon }) => (
    <GridItem>
        <FormControl isInvalid={formikProps.errors[name] && formikProps.touched[name]}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <InputGroup>
                <ThemedStyledInput
                    {...formikProps.getFieldProps(name)}
                    id={name}
                    placeholder={placeholder}
                />
                {icon && (
                    <InputRightElement>
                        <IconButton
                            aria-label="Icon button label"
                            bg="blue.300"
                            _hover={{ bg: '#69C5EC' }}
                            _active={{ bg: '#4699bc' }}
                            color="white"

                        >
                            {icon}
                        </IconButton>
                    </InputRightElement>

                )}
            </InputGroup>
            {formikProps.errors[name] && formikProps.touched[name] && (
                <Text color="red.500" fontSize="sm">{formikProps.errors[name]}</Text>
            )}
        </FormControl>
    </GridItem>
);

const AlertAndButtons = ({ formikProps, closeDrawer }) => (
    <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}>
        <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
        <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
            <CustomButton onClick={closeDrawer} type="cancel" showIcon={false}>
                Cancel
            </CustomButton>
            <CustomButton
                type="submit"
                disabled={formikProps.isSubmitting}
                style={{
                    opacity: formikProps.isSubmitting ? 0.7 : 1,
                    pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                }}
            >
                {formikProps.isSubmitting ? (
                    <>
                        <Spinner size="xs" mr={2} />
                        Submitting...
                    </>
                ) : 'Submit'}
            </CustomButton>
        </ButtonStack>
    </Box>
);

export default PublicationCategoryAddForm;
