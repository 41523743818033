import React from 'react';
import { Spinner, Center } from '@chakra-ui/react';

const Preloader = () => {
    return (
        <Center h="100vh">
            <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.300"
                size="xl"
            />
        </Center>
    );
};

export default Preloader;
