import React, {useState} from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    Box,
    Text,
    InputGroup,
    InputRightElement,
    IconButton,
    SimpleGrid,
    Spinner,
    Textarea,
    Select, AspectRatio
} from '@chakra-ui/react';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import {useDrawer} from "../../../../../Context/DrawerContext/DrawerContext";
import useSingleToast from "../../../../../hooks/UseSingleToast/UseSingleToast";
import CustomAlert from "../../../../../components/Styled/StyledAlert";
import {ThemedStyledInput} from "../../../../../components/Styled";
import ConfirmDialog from "../../../../../Context/ConfirmDialog/ConfirmDialog";
import {ButtonStack, CustomButton} from "../../../../../components/Styled/StyledButtons";
import {createAlbum} from "../AlbumAPI";
import utils from "../../../../../utils/commonFunctions";
import FormActionLabel from "../../../../../components/Styled/FormActionLabel";
import DropBox from "../../../../../components/DropBox/DropBox";
import {Icon} from "@iconify/react";

const AlbumAddForm = ({ refetchAlbumData, filterOptions }) => {
    const { closeDrawer } = useDrawer();
    const showToast = useSingleToast();
    const [loading, setLoading] = useState(false);
    const [coverPreviewUrl, setCoverPreviewUrl] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    const albumSchema = Yup.object().shape({
        title: Yup.string().required('Title is required').min(3, 'Title must be at least 3 characters'),
        description: Yup.string(),
        cover: Yup.string(),
        status: Yup.string().required('Status is required'),
    });

    const handleFileChange = async (event, setFieldValue) => {
        const file = event.target.files[0];
        if (file) {
            setLoading(true);
            try {
                const uploadResponse = await utils.handleFileUpload(file, `albums/covers/${uuidv4()}`);
                if (uploadResponse && uploadResponse.data && uploadResponse.data.fileUrl) {
                    setFieldValue('cover', uploadResponse.data.fileUrl);
                    setCoverPreviewUrl(uploadResponse.data.fileUrl);
                } else {
                    showToast({
                        title: 'Upload Failed',
                        description: 'Unable to upload cover image, please try again.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                showToast({
                    title: 'Error',
                    description: `Upload failed: ${error.message}`,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } finally {
                setLoading(false);
            }
        }
    };

    const handleSubmit = async (values, actions) => {
        if (!coverPreviewUrl) {
            showToast({
                title: 'Missing Cover Image',
                description: 'Please select a cover image before submitting.',
                status: 'warning',
                duration: 5000,
                isClosable: true,
            });
            actions.setSubmitting(false);
            return;
        }

        try {
            const albumData = {
                title: values.title,
                description: values.description,
                status: values.status,
                cover: coverPreviewUrl
            };

            await createAlbum(albumData);
            showToast({
                title: 'Success',
                description: 'Album added successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchAlbumData(filterOptions);
            closeDrawer();
        } catch (error) {
            showToast({
                title: 'Error',
                description: error.message || 'An unexpected error occurred',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            actions.setSubmitting(false);
        }
    };

    const requestConfirm = (options) => {
        setConfirmDialog({
            isOpen: true,
            title: options.title,
            message: options.message,
            onConfirm: options.onConfirm,
        });
    };

    const handleConfirm = () => {
        confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    return (
        <>
            <FormActionLabel formAction="add" formName="Album" />
            <Formik
                initialValues={{
                    title: '',
                    description: '',
                    cover: '',
                    status: 'draft',
                }}
                validationSchema={albumSchema}
                onSubmit={handleSubmit}
            >
                {(formikProps) => (
                    <Form>
                        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                            <SimpleGrid columns={1} gap={4} px={2} py={4}>
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.title && formikProps.touched.title}>
                                        <FormLabel htmlFor="title">Album Title</FormLabel>
                                        <InputGroup>
                                            <ThemedStyledInput
                                                {...formikProps.getFieldProps('title')}
                                                id="title"
                                                placeholder="Enter Album Title"
                                            />
                                            <InputRightElement>
                                                <IconButton
                                                    aria-label="Icon button label"
                                                    bg="blue.300"
                                                    _hover={{ bg: '#69C5EC' }}
                                                    _active={{ bg: '#4699bc' }}
                                                    color="white"
                                                    icon={<Icon icon="mdi:rename-outline" />}
                                                />
                                            </InputRightElement>
                                        </InputGroup>
                                        {formikProps.errors.title && formikProps.touched.title && (
                                            <Text color="red.500" fontSize="sm">{formikProps.errors.title}</Text>
                                        )}
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl>
                                        <FormLabel htmlFor="description">Album Description</FormLabel>
                                        <Textarea
                                            {...formikProps.getFieldProps('description')}
                                            id="description"
                                            placeholder="Enter Album Description"
                                            borderWidth="2px"
                                            focusBorderColor="#63B3ED"
                                        />
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.cover && formikProps.touched.cover}>
                                        <FormLabel htmlFor="cover">Album Cover</FormLabel>
                                        <DropBox
                                            label="Upload Cover Image"
                                            name="cover"
                                            /*filePath={formikProps.values.cover}*/
                                            loading={loading}
                                            formikProps={formikProps}
                                            callback={(event) => handleFileChange(event, formikProps.setFieldValue)}
                                            instruction="Upload Album Cover Image"
                                        />
                                        {formikProps.errors.cover && formikProps.touched.cover && (
                                            <Text color="red.500" fontSize="sm">{formikProps.errors.cover}</Text>
                                        )}
                                        {coverPreviewUrl && (
                                            <Box mt="4" p={1} borderWidth="1px" borderRadius="lg" overflow="hidden" position="relative">
                                                <AspectRatio ratio={16 / 9}>
                                                    <img src={coverPreviewUrl} alt="Cover Preview" style={{ width: '100%', borderRadius: '8px', objectFit: 'cover' }} />
                                                </AspectRatio>
                                            </Box>
                                        )}
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl>
                                        <FormLabel htmlFor="status">Publishing Status</FormLabel>
                                        <Select
                                            {...formikProps.getFieldProps('status')}
                                            id="status"
                                            placeholder=" -- Select Status -- "
                                        >
                                            <option value="draft">Draft</option>
                                            <option value="published">Published</option>
                                            <option value="archived">Archived</option>
                                        </Select>
                                    </FormControl>
                                </GridItem>
                            </SimpleGrid>
                            <AlertAndButtons
                                formikProps={formikProps}
                                closeDrawer={closeDrawer}
                                requestConfirm={requestConfirm}
                                setCoverPreviewUrl={setCoverPreviewUrl}
                            />
                        </VStack>
                    </Form>
                )}
            </Formik>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};


const AlertAndButtons = ({ formikProps, closeDrawer, requestConfirm, setCoverPreviewUrl }) => {

    const handleCancel = () => {
        requestConfirm({
            title: "Cancel Confirmation",
            message: "Are you sure you want to cancel? Any unsaved changes will be lost.",
            onConfirm: () => {
                formikProps.handleReset();
                setCoverPreviewUrl(null);
                closeDrawer();
            }
        });
    };

    const handleReset = () => {
        requestConfirm({
            title: "Reset Confirmation",
            message: "Are you sure you want to reset all your changes?",
            onConfirm: () => {
                formikProps.handleReset();
                setCoverPreviewUrl(null);
            }
        });
    };

    return (
        <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}>
            <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
            <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                    Cancel
                </CustomButton>
                <CustomButton onClick={handleReset} type="reset">
                    Reset
                </CustomButton>
                <CustomButton
                    type="submit"
                    disabled={formikProps.isSubmitting}
                    style={{
                        opacity: formikProps.isSubmitting ? 0.7 : 1,
                        pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                    }}
                >
                    {formikProps.isSubmitting ? (
                        <>
                            <Spinner size="xs" mr={2} />
                            Submitting...
                        </>
                    ) : 'Submit'}
                </CustomButton>
            </ButtonStack>
        </Box>
    );
};

export default AlbumAddForm;
