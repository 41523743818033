import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box,
    Badge, useDisclosure,
} from '@chakra-ui/react';
import { useDrawer } from "../../../Context/DrawerContext/DrawerContext";
import {EditButton, ViewButton, DeleteButton, PublishButton} from "../../../components/Styled/ActionButtons";
import { StyledTableContainer } from "../../../components/Styled/StyledComponents";
import PaginationControls from "../../../Context/PaginationControls/PaginationControls";
import {fetchAllBanners, fetchBannerById, deleteBanner, updateBanner} from "../BannerAPI";
import ConfirmDialog from "../../../Context/ConfirmDialog/ConfirmDialog";
import useSingleToast from "../../../hooks/UseSingleToast/UseSingleToast";
import bannerEditForm from "./BannerEditForm";
import ImageModal from "./ImageModal";

const BannerTable = ({ banners, setBanners, error, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag }) => {
    const { openDrawer } = useDrawer();
    const showToast = useSingleToast();
    const {onOpen,isOpen,onClose} = useDisclosure()
    const [banner,setBanner]=useState()

    const handleEdit = (banner) => {
        openDrawer({
            title: 'Edit Banner',
            component: bannerEditForm,
            props: {
                bannerId: banner._id,
                bannerData: banner,
                refetchBannersData: refetchBannersData,
                setRefreshFlag: setRefreshFlag,
            },
            key: `edit-${banner._id}`,
        }, 'md');
    };

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => { },
        bannerId: null
    });

    const requestConfirm = (options) => {
        setConfirmDialog({
            isOpen: true,
            title: options.title,
            message: options.message,
            onConfirm: options.onConfirm,
            bannerId: options.bannerId
        });
    };

    const handleView = (banner) => {
        setBanner(banner)
        onOpen()
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const deleteOption = (bannerId) => {
        requestConfirm({
            title: "Delete Confirmation",
            message: "Are you sure you want to delete this banner? If you proceed this banner will be lost forever!",
            onConfirm: handleConfirm,
            bannerId: bannerId
        });
    };

    const handlePublish = async (bannerId,bannerData) => {
        try {
            await updateBanner(bannerId, bannerData);
            showToast({
                title: 'Banner updated successfully',
                description: deleteBanner.message,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchBannersData();
        } catch (error) {
            showToast({
                title: 'Error updating banner data',
                description: error.message || 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
        // confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };
    const handleConfirm = async () => {
        try {
            await deleteBanner(confirmDialog.bannerId);
            showToast({
                title: 'Banner  deleted successfully',
                description: deleteBanner.message,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchBannersData();
        } catch (error) {
            showToast({
                title: 'Error deleting banner data',
                description: error.message || 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
        // confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) {
          return text;
        }
        return text.slice(0, maxLength) + '...';
      };


    const refetchBannersData = useCallback(async () => {
        try {
            const data = await fetchAllBanners(pageNumber, 10);
            setBanners(data.docs);
            setTotalPages(data.totalPages);
        } catch (error) {
        }
    }, [pageNumber, setBanners, setTotalPages]);

    useEffect(() => {
        refetchBannersData();
    }, [refetchBannersData]);

    if (error) {
        return <Box textAlign="center" my="4">Failed to fetch Banners data: {error}</Box>;
    }

    if (dataLoaded && Array.isArray(banners) && banners.length === 0) {
        return <Box textAlign="center" my="4">No records found.</Box>;
    }

    return (
        <>
            <StyledTableContainer>
                <PaginationControls
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    totalPages={totalPages}
                />
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th>#</Th>
                            <Th>Title</Th>
                            <Th>Description</Th>
                            <Th>Position</Th>
                            <Th>Published</Th>
                            <Th isNumeric>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {banners?.map((banner, index) => (
                            <Tr key={index}>
                                <Td>BN0{index + 1}</Td>
                                {/* <Td>{banner.number}</Td> */}
                                <Td>{banner.title}</Td>
                                <Td>
                                    <div dangerouslySetInnerHTML={{ __html: truncateText(banner.description, 50) }} />
                                </Td>
                                <Td>
                                    {banner.position}
                                </Td>

                                <Td>
                                    <Badge ml='1' colorScheme={banner?.published?'green':'red'}>
                                        {banner?.published?'Published':'Unpublished'}
                                    </Badge>
                                </Td>
                                <Td isNumeric>
                                    <ViewButton onClick={() =>
                                        handleView(banner)
                                    } />
                                    <EditButton onClick={() =>
                                        handleEdit(banner)
                                    } />

                                        <PublishButton published={banner.published} onClick={() =>
                                            handlePublish(banner._id,{published:!banner.published})
                                        } />



                                    <DeleteButton onClick={() =>
                                        deleteOption(banner._id)
                                    } />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </StyledTableContainer>

            <ImageModal onClose={onClose} image={banner} isOpen={isOpen}/>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

export default BannerTable;
