import React, { useCallback, useEffect, useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  useToast,

} from '@chakra-ui/react';
import FAQEditForm from './FAQEditForm'; 
import FAQViewForm from './FAQViewForm'; 
import { useDrawer } from "../../../Context/DrawerContext/DrawerContext";
import { DeleteButton, EditButton, ViewButton } from "../../../components/Styled/ActionButtons";
import { StyledTableContainer } from "../../../components/Styled/StyledComponents";
import PaginationControls from "../../../Context/PaginationControls/PaginationControls";
import faqService from "../../../services/faqService";

const FAQTable = ({ faqs, setFAQs, error, pageNumber, setPageNumber, totalPages, setTotalPages }) => {
  const { openDrawer } = useDrawer();
  const toast = useToast({ position: 'top-right' });


  const handleEdit = (faqId) => {
    openDrawer({
      title: 'Edit FAQ',
      component: FAQEditForm,
      props: {
        faqId: faqId,
        refetchFAQData: refetchFAQData,
      },
      key: `edit-${faqId}`,
    }, 'md');
  };

  const handleView = async (faq) => {
    try {
      const updatedFAQData = await faqService.getFAQById(faq._id);
      openDrawer({
        title: 'View FAQ',
        component: FAQViewForm,
        props: { faqData: updatedFAQData },
        key: `view-${faq._id}`,
      }, 'md');
    } catch (error) {
      console.error("Failed to fetch latest FAQ data:", error);
    }
  };

  const handleDelete = async(id)=>{
    try{
    await faqService.softDeleteFAQ(id)
    toast({
      title: 'FAQ deleted successfully',
      status: 'success',
      duration: 5000,
      isClosable: true,
  });
  refetchFAQData()
    }catch(e){
      console.error('Failed to delete FAQ',)
    }

  }

  const refetchFAQData = useCallback(async () => {
    try {
      const data = await faqService.getAllFAQs(true); // Assume true fetches published FAQs
      console.log('DATA',data)
      setFAQs(data); // Ensure this matches the data structure returned by your API
      setTotalPages(Math.ceil(data.total / 10));
    } catch (error) {
      console.error("Fetch FAQs error:", error.message); 
    }
  }, [setFAQs, setTotalPages]);

  useEffect(() => {
    refetchFAQData();
  }, [refetchFAQData]);

  if (error) {
    return <Box textAlign="center" my="4">Failed to fetch FAQ data: {error}</Box>;
  }


  if (Array.isArray(faqs) && faqs.length === 0) {
    return <Box textAlign="center" my="4">No FAQs found. Please add new FAQs to see them listed here.</Box>;
  }

  return (
    <>
      <StyledTableContainer>
        <PaginationControls pageNumber={pageNumber} setPageNumber={setPageNumber} totalPages={totalPages} />
        <Table variant="striped">
          <Thead>
            <Tr>
            <Th>Category</Th>
              <Th>Question</Th>
              <Th>Answer</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {faqs?.map((faq) => (
              <Tr key={faq._id}>

                <Td>{faq.category}</Td>
                <Td>{faq.question}</Td>
                <Td>{faq.answer}</Td>
                <Td>
                  <ViewButton onClick={() => handleView(faq)} />
                  <EditButton onClick={() => handleEdit(faq._id)} />
                  <DeleteButton onClick={() => handleDelete(faq._id)} />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </StyledTableContainer>
    </>
  );
};

export default FAQTable;
