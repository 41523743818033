import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Box,
  useToast,
  InputGroup,
  Image,
  Button as ChakraButton,
  Textarea
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import photoService from '../../../../../services/photoService';  
import fileService from '../../../../../services/fileService';  
import { ButtonStack, CustomButton } from "../../../../../components/Styled/StyledButtons";
import { useDrawer } from '../../../../../Context/DrawerContext/DrawerContext'; 

const AlbumEditForm = ({ albumId }) => {
    const toast = useToast();
    const { closeDrawer } = useDrawer();
    const [album, setAlbum] = useState({
        title: '',
        description: '',
        cover: ''
    });

    useEffect(() => {
        const fetchAlbumDetails = async () => {
            try {
                const albumData = await photoService.getAlbumById(albumId);
                setAlbum({
                    title: albumData.title,
                    description: albumData.description,
                    cover: albumData.cover
                });
            } catch (error) {
                toast({
                    title: 'Error fetching album details',
                    description: error.message || "An error occurred while fetching album details.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                });
            }
        };

        fetchAlbumDetails();
    }, [albumId, toast]);

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Album title is required'),
        description: Yup.string(),
        cover: Yup.mixed()
    });

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            if (typeof values.cover === 'object') {
                const uploadResult = await fileService.uploadFile(values.cover, 'user123', 'albumCover');
                values.cover = uploadResult.url; // Adjust this based on the actual response structure
            }

            await photoService.updateAlbum(albumId, values);
            toast({
                title: 'Album Updated',
                description: "The album has been updated successfully.",
                status: 'success',
                duration: 5000,
                isClosable: true
            });
            closeDrawer();
        } catch (error) {
            toast({
                title: 'Failed to update album',
                description: error.message || "An error occurred while updating the album.",
                status: 'error',
                duration: 5000,
                isClosable: true
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Formik initialValues={album} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize >
            {({ isSubmitting, setFieldValue, handleChange, handleBlur }) => (
                <Form>
                    <Field name="title">
                        {({ field, form }) => (
                            <FormControl isRequired mt={4}>
                                <FormLabel htmlFor='title'>Album Title</FormLabel>
                                <Input {...field} id='title' placeholder='Enter album title' />
                            </FormControl>
                        )}
                    </Field>
                    <Field name="description">
                        {({ field, form }) => (
                            <FormControl mt={4}>
                                <FormLabel htmlFor='description'>Description</FormLabel>
                                <Textarea {...field} id='description' placeholder='Enter album description' />
                            </FormControl>
                        )}
                    </Field>
                    <FormControl mt={4}>
                        <FormLabel htmlFor='cover'>Cover Image</FormLabel>
                        <InputGroup>
                            <Input
                                id='cover'
                                name='cover'
                                type='file'
                                onChange={(event) => {
                                    setFieldValue('cover', event.currentTarget.files[0]);
                                }}
                            />
                            {album.cover && typeof album.cover === 'string' && (
                                <Image src={album.cover} alt="Album Cover" maxH="100px" mt="2" />
                            )}
                        </InputGroup>
                    </FormControl>
                    <Box mt={4}>
                        <CustomButton
                            isLoading={isSubmitting}
                            type='submit'
                            colorScheme='blue'
                            showIcon={false} 
                        >
                            Update Album
                        </CustomButton>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default AlbumEditForm;
