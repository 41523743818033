import React from 'react'
import PollsContainer from './Components/PollsContainer'
import {Icon} from "@iconify/react";
import DefaultTemplate from "../../components/DefaultTemplate/DefaultTemplate";

const Polls = () => {
    return (
        <>
            <DefaultTemplate 
                Content={ <PollsContainer/> } 
                Title={"Polls"} 
                icon={<Icon icon="mdi:poll" />}
            />
        </>
    )
}

export default Polls
