import React, { useEffect, useState } from 'react';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    useToast,
    Box,
    Text,
    SimpleGrid,
    Textarea,
    Switch,
    Tooltip, Spinner
} from '@chakra-ui/react';
import { ThemedStyledInput } from "../../../components/Styled";
import FormActionLabel from "../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../components/Styled/StyledButtons";
import { useDrawer } from '../../../Context/DrawerContext/DrawerContext';
import faqService from "../../../services/faqService";  
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ConfirmDialog from "../../../Context/ConfirmDialog/ConfirmDialog";

const FAQEditForm = ({ faqId, refetchFAQData, setRefreshFlag }) => {
    const { closeDrawer } = useDrawer();
    const toast = useToast({ position: 'top-right' });
    const [initialValues, setInitialValues] = useState({
        question: '',
        answer: '',
        category: '',
        published: false,
    });

    useEffect(() => {
        const fetchAndSetFAQData = async () => {
            try {
                const faqData = await faqService.getFAQById(faqId);
                if (faqData) {
                    setInitialValues({
                        question: faqData.question,
                        answer: faqData.answer,
                        category: faqData.category,
                        published: faqData.published,
                    });
                }
            } catch (error) {
                toast({
                    title: 'Error fetching FAQ data',
                    description: error.message || 'An unexpected error occurred.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };

        fetchAndSetFAQData();
    }, [faqId, toast]);

    const faqSchema = Yup.object().shape({
        question: Yup.string().required('Question is required.'),
        answer: Yup.string().required('Answer is required.'),
        category: Yup.string().required('Category is required.'),
        published: Yup.boolean().required('Publish status is required.'),
    });

    const handleSubmit = async (values, actions) => {
        try {
            await faqService.updateFAQ(faqId, values);
            toast({
                title: 'FAQ updated successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchFAQData();
        //    setRefreshFlag(prevFlag => !prevFlag);
            closeDrawer();
        } catch (error) {
            toast({
                title: 'Error updating FAQ',
                description: error.message || 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            actions.setSubmitting(false);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={faqSchema}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {formikProps => (
                <Form>
                    <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                        <FormActionLabel formAction="edit" formName="FAQ"/>
                        <SimpleGrid columns={2} gap={4} px={2} py={4}>
                            <GridItem>
                                <FormControl isInvalid={formikProps.errors.question && formikProps.touched.question}>
                                    <FormLabel htmlFor="question">Question</FormLabel>
                                    <ThemedStyledInput
                                        {...formikProps.getFieldProps('question')}
                                        id="question"
                                        placeholder="Enter Question"
                                    />
                                    <Text color="red.500" fontSize="sm">
                                        {formikProps.errors.question}
                                    </Text>
                                </FormControl>
                            </GridItem>
                            <GridItem colSpan={2}>
                                <FormControl isInvalid={formikProps.errors.answer && formikProps.touched.answer}>
                                    <FormLabel htmlFor="answer">Answer</FormLabel>
                                    <Textarea
                                        {...formikProps.getFieldProps('answer')}
                                        id="answer"
                                        placeholder="Enter Answer"
                                        type="text"
                                    />
                                    <Text color="red.500" fontSize="sm">
                                        {formikProps.errors.answer}
                                    </Text>
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl isInvalid={formikProps.errors.category && formikProps.touched.category}>
                                    <FormLabel htmlFor="category">Category</FormLabel>
                                    <ThemedStyledInput
                                        {...formikProps.getFieldProps('category')}
                                        id="category"
                                        placeholder="Enter Category"
                                    />
                                    <Text color="red.500" fontSize="sm">
                                        {formikProps.errors.category}
                                    </Text>
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl>
                                    <FormLabel htmlFor="published">Published</FormLabel>
                                    <Switch
                                        {...formikProps.getFieldProps('published')}
                                        id="published"
                                        isChecked={formikProps.values.published}
                                    />
                                </FormControl>
                            </GridItem>
                        </SimpleGrid>
                        <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }} mt={8} mb={8}>
                            <CustomAlert status="warning" message="Ensure all fields are correct before submitting." />
                            <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                                <CustomButton type="cancel" onClick={closeDrawer} showIcon={false}>
                                    Cancel
                                </CustomButton>
                                <CustomButton
                                    type="submit"
                                    disabled={formikProps.isSubmitting}
                                    style={{
                                        opacity: formikProps.isSubmitting ? 0.7 : 1,
                                        pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                                    }}
                                >
                                    {formikProps.isSubmitting ? 'Updating...' : 'Update'}
                                </CustomButton>
                            </ButtonStack>
                        </Box>
                    </VStack>
                </Form>
            )}
        </Formik>
    );
};

export default FAQEditForm;
