import React from 'react';
import CsvDownloadButton from "react-json-to-csv";
import moment from "moment";
import {Text} from "@chakra-ui/react";

const DownloadCSV = ({data=[],fileName=`excel-export-${moment().format('DD-MM-YYYY')}`}) => {

    return (
        <CsvDownloadButton
            children={ <Text>Export as CSV </Text>}
            data={data}
            filename={`${fileName}-${moment().format('DD-MM-YYYY')}`}
        />

    );
}

export default DownloadCSV;
