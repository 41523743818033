import React, { useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import { Grid, GridItem, Stack } from '@chakra-ui/react';
import * as Yup from 'yup';
import { CustomButton } from "../../../../components/Styled/StyledButtons";
import { Icon } from "@iconify/react";
import FieldErrorMessage from "../../../../components/FieldErrorMessage/FieldErrorMessage";
import { ThemedStyledInput } from "../../../../components/Styled";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";

const DisconnectionSearchFilter = ({ onFilterChange }) => {
    const showToast = useSingleToast();
    const showToastRef = useRef(showToast);

    return (
        <Formik
            initialValues={{
                customerNumber: '',
                contractNumber: '',
            }}
            validationSchema={Yup.object({
                customerNumber: Yup.number(),
                contractNumber: Yup.number(),
            })}
            onSubmit={(values, { setSubmitting }) => {
                const filteredValues = Object.fromEntries(Object.entries(values).filter(([_, v]) => v !== ''));

                if (Object.keys(filteredValues).length === 0) {
                    showToastRef.current({
                        title: "No Parameters Supplied",
                        description: "Please enter search criteria.",
                        status: "warning",
                        duration: 5000,
                        isClosable: true,
                    });
                } else {
                    onFilterChange(filteredValues);
                }
                setSubmitting(false);
            }}
        >
            {({ handleSubmit, resetForm }) => (
                <Form>
                    <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                        <GridItem colSpan={[12, 5]}>
                            <Field as={ThemedStyledInput} id="customerNumber" name="customerNumber" type="text" placeholder="Customer Number" />
                            <FieldErrorMessage name="customerNumber" />
                        </GridItem>
                        <GridItem colSpan={[12, 5]}>
                            <Field as={ThemedStyledInput} id="contractNumber" name="contractNumber" type="text" placeholder="Contract Number" />
                            <FieldErrorMessage name="contractNumber" />
                        </GridItem>
                        <GridItem colSpan={[12, 1]}>
                            <Stack spacing={2} direction="row" align="center" justify="center">
                                <CustomButton onClick={handleSubmit} type="search" tooltipLabel="Search" icon={<Icon icon="bi:search" />} showLabel={false}>
                                    Search
                                </CustomButton>
                                <CustomButton
                                    type="reset"
                                    onClick={() => {
                                        resetForm();
                                        onFilterChange({ customerNumber: '', contractNumber: '' });
                                    }}
                                    tooltipLabel="Clear Filters"
                                    icon={<Icon icon="radix-icons:reset" />}
                                    showLabel={false}
                                >
                                    Clear
                                </CustomButton>
                            </Stack>
                        </GridItem>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default DisconnectionSearchFilter;
