import React from 'react';
import { Box, Grid, Text, VStack, Image} from '@chakra-ui/react';
import WUCLogo from "../../../../../assests/images/BlueLogoNoBG.png";
import { Icon } from "@iconify/react";

const statusIcons = {
    Paid: { icon: 'mdi:check-all', color: '#48BB78' },
    Accepted: { icon: 'mdi:check-circle-outline', color: '#48BB78' },
    Rejected: { icon: 'mdi:close-circle-outline', color: '#E53E3E' },
    Created: { icon: 'mdi:clipboard-text-outline', color: '#3182CE' },
    Failed: { icon: 'mdi:close-circle-outline', color: '#E53E3E' },
    Submitted: { icon: 'mdi:clipboard-text-outline', color: '#3182CE' }
};

const PrintLabQuotationRequest = React.forwardRef(({ data, title }, ref) => {
    if (!data) {
        return <div>No data available</div>;
    }

    return (
        <div ref={ref}>
            <VStack spacing={4} p={5} align="stretch">
                <Image src={WUCLogo} alt="WUC Logo" htmlWidth="100px" alignSelf="center" mb={2} />
                <Text fontSize="2xl" fontWeight="bold" alignSelf="center" mb={4}>{title}</Text>
                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                    {Object.entries(data).map(([sectionTitle, items]) => (
                        <Box key={sectionTitle} mb={4}>
                            <Text fontSize="xl" fontWeight="bold" mb={2}>{sectionTitle}</Text>
                            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                                {items.map((item, index) => (
                                    <Box key={index} p={2} border="1px solid #63B3ED" borderRadius="md">
                                        <Text fontWeight="semibold">{item.key}:</Text>
                                        <Text display="flex" alignItems="center">
                                            {item.value}
                                            {item.key === 'Status' && statusIcons[item.value] && (
                                                <Icon icon={statusIcons[item.value].icon}
                                                      style={{ marginLeft: '8px', color: statusIcons[item.value].color }}
                                                />
                                            )}
                                        </Text>
                                    </Box>
                                ))}
                            </Grid>
                        </Box>
                    ))}
                </Grid>
            </VStack>
        </div>
    );
});

export default PrintLabQuotationRequest;

