import React, { useState, useEffect } from 'react';
import {
    FormControl,
    FormLabel,
    VStack,
    useToast,
    Box,
    Text, SimpleGrid, Spinner,
    Grid, GridItem,
    Flex, Image, Input, InputGroup,

} from '@chakra-ui/react';
import DropBox from "../../../components/DropBox/DropBox";
import FormActionLabel from "../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../components/Styled/StyledButtons";
import FieldControl from '../../../components/Styled/FieldControl';
import { useDrawer } from '../../../Context/DrawerContext/DrawerContext';
import { Formik, Form, Field } from 'formik';
import ConfirmDialog from "../../../Context/ConfirmDialog/ConfirmDialog";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import styles
import { addEvent } from "../eventAPI";
import SectionHeading from "../../../components/SectionHeading/SectionHeading";
import utils from "../../../utils/commonFunctions";
import { IconButton, Center, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import pdfFileImage from '../../../assests/images/pdfFile.png';
// "C:\weblogic\wuc-admin-portal\src\assests\images\pdfFile.png"

const EventAddForm = ({ refetchEventsData, eventData }) => {
    const { closeDrawer } = useDrawer();
    const [descriptionValue, setDescriptionValue] = useState('');
    const toast = useToast({ position: 'top-right' });
    const [images, setImages] = useState([]);
    const [pdfs, setPdfs] = useState([]);

    const [loading, setLoading] = useState(false);

    let [eventSchema, setEventSchema] = useState({
        title: eventData?.title || '',
        description: eventData?.description || '',
        imagesDocumentsKeys: eventData?.imagesDocumentsKeys || [],
        otherDocumentsKeys: eventData?.otherDocumentsKeys || [],
        startDate: eventData?.startDate || '',
        closingDate: eventData?.closingDate || '',
        isClosed: false
    });

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => { },
    });


    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        
        // validations for title
        if (!values.title ) {
            toast.closeAll()
            toast({
                title: 'Event title is required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }


        // validations for descriptions
        if (!values.description ) {
            toast({
                title: 'Event description is required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        // validations for images
        if (!images|| images?.length === 0 ) {
            toast({
                title: 'Event images are required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        // validations for startdate
        if (!values.startDate ) {

            toast({
                title: 'Start date is required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        if (!values.closingDate ) {

            toast({
                title: 'Closing date is required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        try {
            await addEvent(JSON.stringify({
                "title": values.title,
                "description": descriptionValue,
                "imagesDocumentsKeys": images,
                "otherDocumentsKeys": pdfs,
                "closingDate": values.closingDate,
                "startDate": values.startDate
            }));
            toast({
                title: 'Event added successfully',
                // description: `It will be available once its published`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
            resetForm();
            refetchEventsData(); // Trigger refetching of events data
            closeDrawer();
        } catch (error) {
            if (error.response) {
                // Server error with response
                toast({
                    title: 'Error adding event',
                    description: error.response.data.message || 'An unexpected server error occurred',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } else if (error.request) {
                // Network error without response
                toast({
                    title: 'Network Error',
                    description: 'Unable to connect to the server. Please check your internet connection.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                // Other errors
                toast({
                    title: 'Error',
                    description: error.message || 'An unexpected error occurred',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } finally {
            setSubmitting(false);
        }
    };

    const requestConfirm = (options) => {
        setConfirmDialog({
            isOpen: true,
            title: options.title,
            message: options.message,
            onConfirm: options.onConfirm,
        });
    };

    const handleConfirm = () => {
        confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    function isValidFileType(file) {
        // Define acceptable MIME types for images and PDFs
        const validImageTypes = ['image/jpeg', 'image/png']//, 'image/gif', 'image/bmp', 'image/webp'];
        const validPdfType = 'application/pdf';

        // Check if the file type is one of the valid image types or PDF
        if (validImageTypes.includes(file.type)) {
            return {
                valid: true,
                type: 'image'
            }
        }
        
        if (file.type === validPdfType) {
            return {
                valid: true,
                type: 'pdf'
            };
        }

        return {
            valid: false,
            type: 'invalid'
        }
    }

    const handleFileChange = async (fieldName, event, setFieldValue) => {
        const file = event.target.files[0];
        
        if (file) {
            setLoading(true);
            try {
                // Check file size (2MB = 2097152 bytes)
                if (file.size > 2097152) {
                    toast({
                        title: 'File size too large',
                        description: 'Please upload files less than 2MB in size.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                    setLoading(false);
                    return;
                }

                // Check file type
                const validFileType = isValidFileType(file);
                console.log("Valid file type:", validFileType);
                if (!validFileType.valid) {
                    toast({
                        title: 'Invalid file type',
                        description: 'Please upload images or PDF files only.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                    setLoading(false);
                    return;
                }

                // Proceed with file upload
                const uploadResponse = await utils.handleFileUpload(file, `events/${validFileType.type}/${Date.now()}`);
                if (uploadResponse && uploadResponse.data && uploadResponse.data.fileUrl) {
                    if (validFileType.type === 'image') {
                        setImages(prevImages => [...prevImages, uploadResponse.data.fileUrl]);
                        setFieldValue(`imagesDocumentsKeys`, images);
                    } else if (validFileType.type === 'pdf') {
                        setPdfs(prevPdfs => [...prevPdfs, uploadResponse.data.fileUrl]);
                        setFieldValue(`otherDocumentsKeys`, pdfs);
                    }

                    setFieldValue(`files.${fieldName}`, uploadResponse.data.fileUrl);

                    toast({
                        title: 'File uploaded successfully',
                        description: 'Your file has been uploaded.',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    });
                } else {
                    setFieldValue(`files.${fieldName}.error`, 'Upload failed. Incomplete response received.');
                    toast({
                        title: 'File upload failed',
                        description: 'An error occurred while uploading the file. Please try again.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                toast({
                    title: 'File upload error',
                    description: error.message || 'An unexpected error occurred while uploading the file.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                console.error("File upload error:", error);
                setFieldValue(`files.${fieldName}.error`, 'Upload failed: ' + error.message);
            } finally {
                setLoading(false);
            }

            console.log("images:", images);
            console.log("pdfs:", pdfs);
        }
    };

    const handleDate = (e) => {
        const { value } = e.target;
        let minDate = getMinAgeDate()
        console.log(eventSchema.closingDate)
        if (minDate < value) {
            setEventSchema(prevState => ({
                ...prevState, closing_date: getMinAgeDate()
            }));
        }
        else {
            setEventSchema(prevState => ({
                ...prevState, closing_date: value
            }));
        }
    };

    // a minDate function to get the minimum date
    const getMinAgeDate = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();
        return `${yyyy}-${mm}-${dd}`;
    };

    const handleDelete = (index) => {
        const newImages = images.filter((_, i) => i !== index);
        setImages(newImages);
    };

    const handlePdfDelete = (index) => {
        const newPdfs = pdfs.filter((_, i) => i !== index);
        setPdfs(newPdfs);
    };

    console.log("event data", eventData);


    return (
        <>
            <Formik
                initialValues={eventSchema}
                onSubmit={handleSubmit}
            >
                {formikProps => (
                    <Form>
                        <VStack spacing={4} align="stretch">
                            <FormActionLabel formAction="add" formName="Event" />
                            <SimpleGrid columns={1} gap={4} px={2} py={4}>
                                <FieldControl formikProps={formikProps} name="title" label="Title" placeholder="Enter event title" />
                                <Flex justify={'space-between'}>
                                    <FormControl id="startDate" isRequired me={2}>
                                        <FormLabel >Start Date</FormLabel>
                                        <InputGroup>
                                            <Input
                                                type="date"
                                                name="startDate"
                                                id="startDate"
                                                min={getMinAgeDate()}
                                                value={formikProps.values.startDate}
                                                onChange={(event) => {
                                                    formikProps.handleChange(event);
                                                    handleDate(event);
                                                }}
                                            />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl id="closingDate" isRequired>
                                        <FormLabel>Closing Date</FormLabel>
                                        <InputGroup>
                                            <Input
                                                type="date"
                                                name="closingDate"
                                                id="closingDate"
                                                min={formikProps.values.startDate}
                                                value={formikProps.values.closingDate}
                                                onChange={(event) => {
                                                    formikProps.handleChange(event);
                                                    handleDate(event);
                                                }}
                                            />
                                        </InputGroup>
                                    </FormControl>
                                </Flex>

                                <Box>
                                    <FormLabel htmlFor="description">Description</FormLabel>
                                    <ReactQuill
                                        theme="snow"
                                        value={formikProps.values.description}
                                        onChange={(value) => {
                                            setDescriptionValue(value);
                                            formikProps.setFieldValue('description', value);
                                        }}
                                    />
                                </Box>

                                <SectionHeading icon="mdi:account-file-text" text="Upload Event Files" />
                                <Grid templateColumns="repeat(12, 1fr)" gap={1} color={"blue.300"} px={4}>

                                    <GridItem colSpan={{ base: 12, md: 12 }}>
                                        <DropBox
                                            label={"Attachments"}
                                            name="images"
                                            // filePath={formikProps.values.files}
                                            loading={loading}
                                            formikProps={formikProps}
                                            callback={(event) => handleFileChange('idKey', event, formikProps.setFieldValue, formikProps.values.userId)}
                                        // result={formikProps.values.files}
                                        // instruction={`Please attach images only png, jpeg.`}
                                        />
                                    </GridItem>
                                </Grid>
                                <CustomAlert status="info" message="This section is for event images." />

                                {/* <FileArea heading="Images"> */}
                                {images.map((image, index) => (
                                    <Box key={index} position="relative" mr="4" bg={'gray.100'} p={3} shadow={'md'} rounded={'md'}>
                                        <Center>
                                            <Image src={image} alt={`Image ${index + 1}`} boxSize="200px" />
                                        </Center>
                                        <IconButton
                                            icon={<CloseIcon />}
                                            size="sm"
                                            aria-label="Delete image"
                                            position="absolute"
                                            bg={'tomato'}
                                            top="2"
                                            right="2"
                                            onClick={() => handleDelete(index)}
                                        />
                                    </Box>
                                ))}

                                {/* if there are no images */}
                                {images.length === 0 && (
                                    <Center>
                                        <Text color="gray.500" fontSize="sm" mt={2}>No images uploaded</Text>
                                    </Center>
                                )}
                                {/* </FileArea> */}

                                <CustomAlert status="info" message="This section is for event Files (PDFs only)." />
                                {/* if there are no images */}
                                {pdfs.length === 0 && (
                                    <Center>
                                        <Text color="gray.500" fontSize="sm" mt={2}>No PDF files uploaded</Text>
                                    </Center>
                                )}
                                {pdfs.map((pdf, index) => (
                                    <Box key={index} position="relative" mr="4" bg={'gray.100'} p={3} shadow={'md'} rounded={'md'}>
                                        <Center>
                                            <Image src={pdfFileImage} alt={`pdf-file ${index + 1}`} boxSize="200px" />
                                        </Center>
                                        <IconButton
                                            icon={<CloseIcon />}
                                            size="sm"
                                            aria-label="Delete PDF"
                                            position="absolute"
                                            bg={'tomato'}
                                            top="2"
                                            right="2"
                                            onClick={() => handlePdfDelete(index)}
                                        />
                                    </Box>
                                ))}

                            </SimpleGrid>
                            <AlertAndButtons
                                formikProps={formikProps}
                                closeDrawer={closeDrawer}
                                requestConfirm={requestConfirm}
                            />
                        </VStack>
                    </Form>
                )}
            </Formik>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

const AlertAndButtons = ({ formikProps, closeDrawer, requestConfirm }) => {

    const handleCancel = () => {
        requestConfirm({
            title: "Cancel Confirmation",
            message: "Are you sure you want to cancel? Any unsaved changes will be lost.",
            onConfirm: () => {
                formikProps.handleReset();
                closeDrawer();
            }
        });
    };

    const handleReset = () => {
        requestConfirm({
            title: "Reset Confirmation",
            message: "Are you sure you want to reset all your changes?",
            onConfirm: () => {
                formikProps.handleReset();
            }
        });
    };

    return (
        <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}>
            <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
            <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                    Cancel
                </CustomButton>
                <CustomButton onClick={handleReset} type="reset">
                    Reset
                </CustomButton>
                <CustomButton
                    type="submit"
                    disabled={formikProps.isSubmitting}
                    style={{
                        opacity: formikProps.isSubmitting ? 0.7 : 1,
                        pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                    }}
                >
                    {formikProps.isSubmitting ? (
                        <>
                            <Spinner size="xs" mr={2} />
                            Submitting...
                        </>
                    ) : 'Submit'}
                </CustomButton>
            </ButtonStack>
        </Box>
    );
};



export default EventAddForm;
