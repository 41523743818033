import { API1 } from "../../../utils/api";

// Create Consultant
export const createConsultant = async (consultantData) => {
    try {
        const response = await API1.post('/consultants', consultantData);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "Consultant creation failed. Please try a different email ID.");
    }
};

// Fetch All Consultants
export const fetchAllConsultants = async (pageNumber = 1, pageSize = 10, filterOptions = {}) => {
    try {
        const queryParams = new URLSearchParams({
            ...filterOptions,
            pageNumber,
            pageSize
        }).toString();

        const response = await API1.get(`/consultants?${queryParams}`);

        if (response.status === 200) {
            const { docs: consultants, totalDocs } = response.data;
            return {
                consultants,
                totalPages: Math.ceil(totalDocs / pageSize),
            };
        } else {
            throw new Error('Unexpected response from the server while fetching consultants.');
        }
    } catch (error) {
        console.error("Error fetching consultants:", error);
        throw new Error('Failed to fetch consultants. Please try again.');
    }
};

// Fetch Consultant by ID using query parameters
export const fetchConsultantById = async (consultantId) => {
    try {
        const queryParams = new URLSearchParams({ id: consultantId }).toString();
        const response = await API1.get(`/consultants?${queryParams}`);
        if (response.status === 200 && response.data.docs.length > 0) {
            return response.data.docs[0];
        } else {
            throw new Error('Consultant not found.');
        }
    } catch (error) {
        throw new Error(error?.message || "Failed to fetch the consultant details.");
    }
};

// Update Consultant
export const updateConsultant = async (consultantId, consultantData) => {
    try {
        const response = await API1.put(`/consultants/${consultantId}`, consultantData);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "An error occurred while updating the consultant.");
    }
};

// Delete Consultant
export const deleteConsultant = async (consultantId) => {
    try {
        const response = await API1.delete(`/consultants/${consultantId}`);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "Failed to delete the consultant. It might have associated entities.");
    }
};


export const fetchAllAreas = async () => {
    try {
        const response = await API1.get(`/areas`);
        if (response.status === 200) {
            const { docs: areas} = response.data;
            return {
                areas,
            };
        } else {
            throw new Error('Unexpected response from the server while fetching areas.');
        }
    } catch (error) {
        console.error("Error fetching areas:", error);
        throw new Error('Failed to fetch areas. Please try again.');
    }
};

// Fetch Area by ID using query parameters
export const fetchAreaByName = async (area) => {
    try {
        const queryParams = new URLSearchParams({ id: area }).toString();
        const response = await API1.get(`/areas?${queryParams}`);
        if (response.status === 200 && response.data.docs.length > 0) {
            return response.data.docs[0];
        } else {
            throw new Error('Area not found.');
        }
    } catch (error) {
        throw new Error(error?.message || "Failed to fetch the area details.");
    }
};