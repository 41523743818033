import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box,
    Badge,
} from '@chakra-ui/react';
import DisconnectionEditForm from './DisconnectionEditForm';
import DisconnectionViewForm from './DisconnectionViewForm';
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import {DeleteButton, UpdateApplication, ViewButton} from "../../../../components/Styled/ActionButtons";
import { StyledTableContainer } from "../../../../components/Styled/StyledComponents";
import PaginationControls from "../../../../Context/PaginationControls/PaginationControls";
import {fetchDisconnectionById, fetchAllDisconnections} from "../DisconnectionAPI";
import moment from "moment";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";

const DisconnectionTable = ({ disconnections, setDisconnections, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag, filterOptions }) => {
    const { openDrawer } = useDrawer();
    const [error, setError] = useState('');
    const showToast = useSingleToast();
    const toastRef = useRef(showToast);


    const handleEdit = (disconnectionId) => {
        openDrawer({
            title: 'Edit Disconnection',
            component: DisconnectionEditForm,
            props: {
                disconnectionId: disconnectionId,
                refetchDisconnectionData: refetchDisconnectionData,
                setRefreshFlag: setRefreshFlag,
            },
            key: `edit-${disconnectionId}`,
        }, 'xl');
    };

    const statusColorScheme = {
        Approved: 'green',
        Declined: 'red',
        Complete: 'blue',
        Review: 'yellow',
        Submitted: 'cyan',
    };

    const getStatusColorScheme = (status) => {
        return statusColorScheme[status] || 'cyan';
    };



    const handleView = async (disconnection) => {
        try {
            const disconnectionData = await fetchDisconnectionById(disconnection._id);
            if (disconnectionData) {
                openDrawer({
                    title: 'View Disconnection',
                    component: DisconnectionViewForm,
                    props: { formData: disconnectionData },
                    key: `view-${disconnection._id}`,
                }, 'xl');
            } else {
                throw new Error('Disconnection data not found.');
            }
        } catch (error) {
            setError(error.message);
            showToast({
                title: 'Error',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };


    const updateDisconnectionsAndPages = useCallback((newDisconnections, totalDocs) => {
        setDisconnections(newDisconnections);
        setTotalPages(Math.ceil(totalDocs / 10));
    }, [setDisconnections, setTotalPages]);

    const refetchDisconnectionData = useCallback(async (options={}) => {
        setError('');
        try {
            const disconnectionsResponse = await fetchAllDisconnections(pageNumber, 10, filterOptions);
            if (!disconnectionsResponse || !disconnectionsResponse.disconnections) {
                throw new Error('No data received from the server');
            }

            if (disconnectionsResponse && disconnectionsResponse.totalDocs) {
                updateDisconnectionsAndPages(disconnectionsResponse.disconnections, disconnectionsResponse.totalPages);
            } else {
                console.error("No totalPages found:", disconnectionsResponse);
                setDisconnections([]);
                setTotalPages(0);
            }

            updateDisconnectionsAndPages(disconnectionsResponse.disconnections, disconnectionsResponse.totalDocs);
            setDisconnections(disconnectionsResponse.disconnections);


        } catch (error) {
            console.error("Error fetching data:", error);
            setError(error.message || 'Failed to fetch data. Please try again later.');
            toastRef.current({
                title: "Error fetching data",
                description: error.message || "An unexpected error occurred.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    }, [filterOptions, pageNumber, setDisconnections, setTotalPages, updateDisconnectionsAndPages]);

    useEffect(() => {
        refetchDisconnectionData();
    }, [refetchDisconnectionData, pageNumber, filterOptions]);

    if (error) {
        console.log("Rendering error state", error);
        return <Box textAlign="center" my="4">Failed to fetch disconnection data: {error}</Box>;
    }

    if (dataLoaded && Array.isArray(disconnections) && disconnections.length === 0) {
        return <Box textAlign="center" my="4">No records found. Please add new disconnections to see them listed here.</Box>;
    }

    return (
        <>
            <StyledTableContainer>
                <PaginationControls
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    totalPages={totalPages} />
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th>Customer Number</Th>
                            <Th>Contract Number</Th>
                            <Th>Name</Th>
                            <Th>City</Th>
                            <Th>Status</Th>
                            <Th>Date of Application</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {disconnections.map((disconnection) => (
                            <Tr key={disconnection._id}>
                                <Td>{disconnection.customerNumber}</Td>
                                <Td>{disconnection.contractNumber}</Td>
                                <Td>{disconnection.personalDetails.firstName + " " + disconnection.personalDetails.lastName}</Td>
                                <Td>{disconnection.address.city}</Td>
                                <Td>
                                    <Badge colorScheme={getStatusColorScheme(disconnection.status)} ml={2} variant="solid">
                                    {disconnection.status}
                                    </Badge>
                                </Td>
                                <Td>{moment(disconnection.dateOfApplication).format('DD-MM-YYYY')}</Td>
                                <Td>
                                    <ViewButton onClick={() => handleView(disconnection)} />
                                    <UpdateApplication onClick={() => handleEdit(disconnection._id)} />
                                    <DeleteButton />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </StyledTableContainer>
        </>
    );
};

export default DisconnectionTable;
