import React, {useEffect, useRef, useState} from 'react';
import {Formik, Form, Field } from 'formik';
import {FormControl, Select, Stack} from '@chakra-ui/react';
import { ThemedStyledInput } from '../../../../../components/Styled';
import * as Yup from 'yup';
import { CustomButton } from "../../../../../components/Styled/StyledButtons";
import { Icon } from "@iconify/react";
import FieldErrorMessage from "../../../../../components/FieldErrorMessage/FieldErrorMessage";
import useSingleToast from "../../../../../hooks/UseSingleToast/UseSingleToast";
import {fetchAllPublicationsCategories} from "../PublicationsAPI";

const validationSchema = Yup.object({
    title: Yup.string()
        .max(100, 'Maximum 100 characters'),
    status: Yup.string(),
    categoryId: Yup.string(),
    extension: Yup.string()
});

const PublicationSearchFilter = ({ onFilterChange }) => {
    const showToast = useSingleToast();
    const showToastRef = useRef(showToast);
    const [publicationCategories, setPublicationCategories] = useState([]);

    useEffect(() => {
        fetchAllPublicationsCategories().then(data => {
            setPublicationCategories(data.publicationCategories);
        }).catch(error => {
            showToastRef.current({
                title: "Error",
                description: "Failed to fetch Publication Categories.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        });
    }, []);

    return (
        <Formik
            initialValues={{
                title: '',
                status: '',
                categoryId: '',
                extension: ''
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                if (!values.title && !values.status && !values.categoryId && !values.extension) {
                    onFilterChange({ title: '', status: '', categoryId: '', extension: '' });
                } else {
                    onFilterChange(values);
                }
                setSubmitting(false);
            }}
            onReset={() => {
                onFilterChange({ title: '', status: '', categoryId: '', extension: '' });
            }}
        >
            {({ resetForm, handleSubmit }) => (
                <Form>
                    <Stack spacing={2} direction="row" align="center">
                        <FormControl flex="2">
                            <Field
                                as={ThemedStyledInput}
                                id="title"
                                name="title"
                                type="text"
                                placeholder="Search by Title"
                            />
                            <FieldErrorMessage name="title" />
                        </FormControl>
                        <FormControl flex="1">
                            <Field as={Select} name="categoryId" placeholder=" -- Select Category --">
                                {publicationCategories.map(pc => (
                                    <option key={pc._id} value={pc._id}>{pc.name}</option>
                                ))}
                            </Field>
                        </FormControl>
                        <FormControl flex="1">
                            <Field
                                as={Select}
                                name="status"
                                placeholder="Filter by Status"
                            >
                                <option value="Active">Active</option>
                                <option value="Archived">Archived</option>
                            </Field>
                        </FormControl>
                        <FormControl flex="1">
                            <Field
                                as={ThemedStyledInput}
                                id="extension"
                                name="extension"
                                type="text"
                                placeholder="Filter by File Extension"
                            />
                        </FormControl>
                        <CustomButton
                            onClick={handleSubmit}
                            type="search"
                            tooltipLabel="Search"
                            icon={<Icon icon="bi:search" />}
                            showLabel={false}
                        >
                            Search
                        </CustomButton>
                        <CustomButton
                            type="reset"
                            onClick={() => {
                                resetForm();
                                onFilterChange({ title: '', status: '', categoryId: '', extension: '' });
                            }}
                            tooltipLabel="Clear Filters"
                            icon={<Icon icon="radix-icons:reset" />}
                            showLabel={false}
                        >
                            Clear
                        </CustomButton>
                    </Stack>
                </Form>
            )}
        </Formik>
    );
};

export default PublicationSearchFilter;
