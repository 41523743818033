import { API1 } from "../../../utils/api";

// Create City
export const createCity = async (cityData) => {
    try {
        const response = await API1.post('/cities', cityData);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "An unexpected error occurred while creating the city.");
    }
};

// Fetch All NewConnection
export const fetchAllCities = async (pageNumber = 1, pageSize = 10, filterOptions = {}) => {
    try {
        const queryParams = new URLSearchParams({
            ...filterOptions,
            pageNumber,
            pageSize
        }).toString();

        const response = await API1.get(`/cities?${queryParams}`);

        if (response.status === 200) {
            const { docs: cities, totalDocs } = response.data;
            return {
                cities,
                totalPages: Math.ceil(totalDocs / pageSize),
            };
        } else {
            throw new Error('Unexpected response from the server while fetching cities.');
        }
    } catch (error) {
        console.error("Error fetching cities:", error);
        throw new Error('Failed to fetch cities. Please try again.');
    }
};

// Fetch City by ID using query parameters
export const fetchCityById = async (cityId) => {
    try {
        const queryParams = new URLSearchParams({ id: cityId }).toString();
        const response = await API1.get(`/cities?${queryParams}`);
        if (response.status === 200 && response.data.docs.length > 0) {
            return response.data.docs[0];
        } else {
            throw new Error('City not found.');
        }
    } catch (error) {
        throw new Error(error?.message || "Failed to fetch the city details.");
    }
};

// Update City
export const updateCity = async (cityId, cityData) => {
    try {
        const response = await API1.put(`/cities/${cityId}`, cityData);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "An error occurred while updating the city.");
    }
};

// Delete City
export const deleteCity = async (cityId) => {
    try {
        const response = await API1.delete(`/cities/${cityId}`);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "Failed to delete the city. It might have associated areas.");
    }
};


export const fetchAllManagementCenters = async () => {
    try {

        const response = await API1.get(`/managementCenters`);

        if (response.status === 200) {
            const { docs: managementCenters} = response.data;
            return {
                managementCenters,

            };
        } else {
            throw new Error('Unexpected response from the server while fetching management centers.');
        }
    } catch (error) {
        console.error("Error fetching management centers:", error);
        throw new Error('Failed to fetch cities. Please try again.');
    }
};

// Fetch Management Center by ID using query parameters
export const fetchManagementCenterById = async (managementCenterId) => {
    try {
        const queryParams = new URLSearchParams({ id: managementCenterId }).toString();
        const response = await API1.get(`/managementCenters?${queryParams}`);
        if (response.status === 200 && response.data.docs.length > 0) {
            return response.data.docs[0];
        } else {
            throw new Error('Management Center not found.');
        }
    } catch (error) {
        throw new Error(error?.message || "Failed to fetch the Management Center details.");
    }
};