import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  GridItem,
  Image,
  Text,
  Button,
  VStack
} from '@chakra-ui/react';

const MenuGrid = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <Box p={5}>
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
      <GridItem w="100%">
          <VStack spacing={4} align="stretch" p={5} borderWidth="1px" borderRadius="lg" overflow="hidden">
            <Image src="https://via.placeholder.com/150" alt="Image Gallery" />
            <Text fontSize="xl">Document Categories</Text>
            <Text>Manage the categories your documents and publications fall under</Text>
            <Button
              colorScheme="pink"
              onClick={() => handleNavigation('/document-categories')}
            >
              Document Categories
            </Button>
          </VStack>
        </GridItem>
        <GridItem w="100%">
          <VStack spacing={4} align="stretch" p={5} borderWidth="1px" borderRadius="lg" overflow="hidden">
            <Image src="https://via.placeholder.com/150" alt="Video Gallery" />
            <Text fontSize="xl">Video Gallery</Text>
            <Text>Manage and organize video documents. Perform create, read, update, and delete operations on video content.</Text>
            <Button
              colorScheme="teal"
              onClick={() => handleNavigation('/video-gallery')}
            >
              Open Video Gallery
            </Button>
          </VStack>
        </GridItem>
        <GridItem w="100%">
          <VStack spacing={4} align="stretch" p={5} borderWidth="1px" borderRadius="lg" overflow="hidden">
            <Image src="https://via.placeholder.com/150" alt="Image Gallery" />
            <Text fontSize="xl">Image Gallery</Text>
            <Text>Manage and organize image documents. Access and manage a wide range of image files, allowing for full data operations.</Text>
            <Button
              colorScheme="pink"
              onClick={() => handleNavigation('/image-gallery')}
            >
              Open Image Gallery
            </Button>
          </VStack>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default MenuGrid;
