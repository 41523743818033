import React, { useState, useRef } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Button,
    SimpleGrid,
    Table,
    Tbody,
    TableContainer,
    useToast 
} from '@chakra-ui/react';
import { Formik, Form, Field, FieldArray } from 'formik';
import { CustomButton } from "../../../components/Styled/StyledButtons";
import { DeleteButton } from '../../../components/Styled/ActionButtons';
import { Icon } from '@iconify/react';
import { Grid, GridItem } from '@chakra-ui/react';
import CustomAlert from "../../../components/Styled/StyledAlert";

// SurveyAddQuestionForm component
const SurveyEditQuestionForm = ({ modalTitle = "Add", updateQuestions, removePreviousQuestion, isOpen, onClose, poll }) => {
    const initialRef = useRef(null);
    const finalRef = useRef(null);
    const toast = useToast(
        { position: "top-center", duration: 5000, isClosable: true }
    )

    const initialValues = {
        question: poll.question ? poll.question : '',
        options: poll.options ? poll.options : ['']
    };

    // Handle form submission
    const handleSubmit = (values, actions) => {
        // console.log(values);  // Form values will be logged here

        // check if question is empty
        if (values.question === '') {
            actions.setSubmitting(false);
            toast({
                title: "Attention!",
                description: "Survey question is required",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        // check if options are less than 2
        if (values.options.length < 2) {
            actions.setSubmitting(false);
            toast({
                title: "Attention!",
                description: "A minimum of 2 options is required",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        if(modalTitle == 'Edit'){
            removePreviousQuestion(poll);
        }
        updateQuestions(values);
        actions.setSubmitting(false);
        //reset form
        actions.resetForm();
        onClose();
    };

    console.log(poll);

    return (
        <>
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
                size="lg"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader marginStart={0}>Survey Question</ModalHeader>
                    <ModalCloseButton />
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                    >
                        {({ values, isSubmitting }) => (
                            <Form>
                                <SimpleGrid columns={1} gap={4} px={0} py={4}>
                                    <FormControl mb={4}>
                                        <FormLabel>Question</FormLabel>
                                        <Field
                                            as={Input}
                                            type="text"
                                            name="question"
                                            placeholder="Enter survey question"
                                        />
                                    </FormControl>
                                    <CustomAlert status="warning" message="Add possible answers for this survey question. A minimum of 2 options is required" />
                                    <FieldArray name="options" >
                                        {({ push, remove }) => (
                                            <TableContainer >
                                                <Table variant="simple" size="lg" mb={4}>
                                                    <Tbody>
                                                        {values?.options?.map((option, index) => (
                                                            <Grid templateColumns='4fr 1fr' gap={4} key={index} my={3}>
                                                                <GridItem>
                                                                <Field
                                                                        as={Input}
                                                                        name={`options[${index}]`}
                                                                        placeholder="Enter option value"
                                                                    />
                                                                </GridItem>
                                                                <GridItem pt={1}>
                                                                    <DeleteButton onClick={() => remove(index)} />
                                                                </GridItem>
                                                            </Grid>
                                                        ))}
                                                    </Tbody>
                                                </Table>
                                                <CustomButton
                                                    type="button"
                                                    showIcon={true}
                                                    showTooltip={true}
                                                    tooltipLabel="Add Option"
                                                    icon={<Icon icon="ci:plus" />}
                                                    onClick={() => push('')}
                                                >
                                                    Add Survey Option
                                                </CustomButton>
                                            </TableContainer>
                                        )}
                                    </FieldArray>
                                </SimpleGrid>
                                <ModalFooter>
                                    <Button
                                        colorScheme="blue"
                                        mr={3}
                                        type="submit"
                                        isLoading={isSubmitting}
                                    >
                                        Save
                                    </Button>
                                    <Button onClick={onClose}>Cancel</Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalContent>
            </Modal>
        </>
    );
};

export default SurveyEditQuestionForm;
