import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Table, Thead, Tbody, Tr, Th, Td, Box, Badge} from '@chakra-ui/react';
import LabServiceProductEditForm from './LabServiceProductEditForm';
import LabServiceProductViewForm from './LabServiceProductViewForm';
import { useDrawer } from "../../../../../Context/DrawerContext/DrawerContext";
import { DeleteButton, EditButton, ViewButton } from "../../../../../components/Styled/ActionButtons";
import { StyledTableContainer } from "../../../../../components/Styled/StyledComponents";
import PaginationControls from "../../../../../Context/PaginationControls/PaginationControls";
import { fetchLabServiceProductByMaterialNumber, fetchAllLabServiceProducts, deleteLabServiceProduct } from "../../LabServiceProductsAPI";
import ConfirmDialog from "../../../../../Context/ConfirmDialog/ConfirmDialog";
import useSingleToast from "../../../../../hooks/UseSingleToast/UseSingleToast";

const LabServiceProductTable = ({ labServiceProducts, setLabServiceProducts, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag, filterOptions }) => {
    const { openDrawer } = useDrawer();
    const [error, setError] = useState('');
    const showToast = useSingleToast();
    const toastRef = useRef(showToast);
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    // Update function encapsulated in useCallback to ensure it's only created once
    const updateLabServiceProductsAndPages = useCallback((newLabServiceProducts, totalDocs) => {
        setLabServiceProducts(newLabServiceProducts);
        setTotalPages(Math.ceil(totalDocs / 10));
    }, [setLabServiceProducts, setTotalPages]);

    const refetchLabServiceProductData = useCallback(async (options={}) => {
        setError('');
        try {
            const labServiceProductsResponse = await fetchAllLabServiceProducts(pageNumber, 10, filterOptions);
            if (!labServiceProductsResponse || !labServiceProductsResponse.labServiceProducts) {
                throw new Error('No data received from the server');
            }

            if (labServiceProductsResponse && labServiceProductsResponse.totalDocs) {
                updateLabServiceProductsAndPages(labServiceProductsResponse.labServiceProducts, labServiceProductsResponse.totalPages);
            } else {
                console.error("No totalPages found:", labServiceProductsResponse);
                setLabServiceProducts([]);
                setTotalPages(0);
            }

            updateLabServiceProductsAndPages(labServiceProductsResponse.labServiceProducts, labServiceProductsResponse.totalDocs);
            setLabServiceProducts(labServiceProductsResponse.labServiceProducts);


        } catch (error) {
            console.error("Error fetching data:", error);
            setError(error.message || 'Failed to fetch data. Please try again later.');
            toastRef.current({
                title: "Error fetching data",
                description: error.message || "An unexpected error occurred.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    }, [filterOptions, pageNumber, setLabServiceProducts, setTotalPages, updateLabServiceProductsAndPages]);

    useEffect(() => {
        refetchLabServiceProductData();
    }, [refetchLabServiceProductData, pageNumber, filterOptions]);



    const handleEdit = (labServiceProductMaterialNumber) => {
        openDrawer({
            title: 'Edit Lab Service Product',
            component: LabServiceProductEditForm,
            props: {
                labServiceProductMaterialNumber,
                refetchLabServiceProductData,
                setRefreshFlag,
            },
            key: `edit-${labServiceProductMaterialNumber}`,
        }, 'md');
    };

    const handleView = async (labServiceProduct) => {
        try {
            const labServiceProductData = await fetchLabServiceProductByMaterialNumber(labServiceProduct.materialNumber);
            if (labServiceProductData) {
                openDrawer({
                    title: 'View Lab Service Product',
                    component: LabServiceProductViewForm,
                    props: { formData: labServiceProductData },
                    key: `view-${labServiceProduct.materialNumber}`,
                }, 'md');
            } else {
                throw new Error('Lab Service Product data not found.');
            }
        } catch (error) {
            showToast({
                title: 'Error',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleDelete = (labServiceProductMaterialNumber) => {
        setConfirmDialog({
            isOpen: true,
            title: "Confirm Delete",
            message: "Are you sure you want to delete this Lab Service Product? This action cannot be undone.",
            onConfirm: () => confirmDelete(labServiceProductMaterialNumber),
        });
    };

    const confirmDelete = async (labServiceProductMaterialNumber) => {
        try {
            await deleteLabServiceProduct(labServiceProductMaterialNumber);
            showToast({
                title: "Lab Service Product Deleted",
                description: "Successfully deleted the Lab Service Product.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            refetchLabServiceProductData(filterOptions); // Refresh the list
            setConfirmDialog({ ...confirmDialog, isOpen: false });
        } catch (error) {
            showToast({
                title: "Deletion Failed",
                description: "Could not delete the Lab Service Product. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ ...confirmDialog, isOpen: false });
        }
    };

    function itemStatus(isActive) {
        if (!isActive) {
            return <Badge colorScheme="red">Deactivated</Badge>;
        }
        return isActive
            ? <Badge colorScheme="green">Active</Badge> 
            : <Badge colorScheme="red">Deactivated</Badge>;
    }


    if (error) {
        console.log("Rendering error state", error);
        return <Box textAlign="center" my="4">Failed to fetch Lab Service Product data: {error}</Box>;
    }

    if (dataLoaded && Array.isArray(labServiceProducts) && labServiceProducts.length === 0) {
        return <Box textAlign="center" my="4">No records found.</Box>;
    }

    return (
        <>
            <StyledTableContainer>
                <PaginationControls pageNumber={pageNumber} setPageNumber={setPageNumber} totalPages={totalPages} />
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th>Material Number</Th>
                            <Th>Name</Th>
                            <Th>Unit Price (BWP)</Th>
                            <Th>Category</Th>
                            <Th>Status</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {labServiceProducts.map(labServiceProduct => (
                            <Tr key={labServiceProduct.materialNumber}>
                                <Td>{labServiceProduct.materialNumber}</Td>
                                <Td>{labServiceProduct.name}</Td>
                                <Td>{labServiceProduct.unitPrice}</Td>
                                <Td>
                                    <Badge ml='1' colorScheme='green'>
                                        {labServiceProduct.category}
                                    </Badge>
                                </Td>
                                <Td>{itemStatus(labServiceProduct?.isActive)}</Td>
                                <Td>
                                    <ViewButton onClick={() => handleView(labServiceProduct)} />
                                    <EditButton onClick={() => handleEdit(labServiceProduct.materialNumber)} />
                                    <DeleteButton onClick={() => handleDelete(labServiceProduct.materialNumber)} />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </StyledTableContainer>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                title={confirmDialog.title}
                message={confirmDialog.message}
                onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
                onConfirm={confirmDialog.onConfirm}
            />
        </>
    );
};

export default LabServiceProductTable;

