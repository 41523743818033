import React from 'react';
import {
    VStack, Box, Text, Badge, Stack, Tooltip, Flex, SimpleGrid, Heading
} from '@chakra-ui/react';
import {Icon} from "@iconify/react";
import {useDrawer} from "../../../Context/DrawerContext/DrawerContext";
import FormActionLabel from "../../Styled/FormActionLabel";
import DetailSection from "../../DetailSection/DetailSection";
import CustomAlert from "../../Styled/StyledAlert";
import {ButtonStack, CustomButton} from "../../Styled/StyledButtons";

const PermissionItem = ({ permission }) => (
    <Flex alignItems="center" py={1}>
        <Icon icon="heroicons:shield-check-16-solid" style={{ color: 'green', marginRight: '8px', fontSize: '20px'}} />
        <Tooltip label={permission} aria-label="Permission Description" placement="top">
            <Text>{permission}</Text>
        </Tooltip>
    </Flex>
);


const AdminProfileView = ({ formData }) => {
    const { closeDrawer } = useDrawer();
    const { firstName, lastName, phone, email, role, isVerified, permissions } = formData;

    return (
        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
            <FormActionLabel formAction="view" formName="My Profile"/>
            <Stack spacing={4} px={2} py={4} mt={4}>
                <DetailSection title="Admin User Information" icon="material-symbols:admin-panel-settings">
                    <SimpleGrid columns={{ base: 1, sm: 2 }} gap={4} px={4}>
                        <Text><strong>Name:</strong> {firstName} {lastName}</Text>
                        <Text><strong>Email:</strong> {email}</Text>
                        <Text><strong>Phone:</strong> {phone}</Text>
                        <Text><strong>Role:</strong> {role}</Text>
                        <Text>
                            <strong>Verified:</strong>
                            <Badge colorScheme={isVerified ? 'green' : 'red'} ml={2} variant='solid'>
                                {isVerified ? 'Verified' : 'Not Verified'}
                            </Badge>
                        </Text>
                    </SimpleGrid>
                </DetailSection>
                <DetailSection title="Permissions" icon="fluent-mdl2:permissions-solid">
                    <SimpleGrid columns={{ base: 1, sm: 3 }} gap={4} px={4}>
                        {permissions.map((permission, index) => (
                            <PermissionItem key={index} permission={permission} />
                        ))}
                    </SimpleGrid>
                </DetailSection>

                <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}  mt={8} mb={8}>
                    <CustomAlert status="warning" message="You are viewing your profile details." />
                    <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                        <CustomButton type="cancel" onClick={closeDrawer} showIcon={false}>
                            Close
                        </CustomButton>
                    </ButtonStack>
                </Box>
            </Stack>
        </VStack>
    );
};

export default AdminProfileView;
