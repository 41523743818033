import React, { useEffect, useState, useCallback } from 'react';
import LeakReportTable from './LeakReportTable';
import LeakReportTableTopBar from './LeakReportTableTopBar';
import { fetchAllLeakReports } from '../LeaksAPI';


const LeakReportContainer = () => {
    const [LeakReports, setLeakReports] = useState([]);
    const [error, setError] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [filterOptions, setFilterOptions] = useState({});
    const [fetchTrigger, setFetchTrigger] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const refetchLeakReportData = useCallback(async (options = {}) => {
        setDataLoaded(false);
        try {
            const data = await fetchAllLeakReports(pageNumber, 10, filterOptions);
            setLeakReports(data.leakReports);
            setTotalPages(data.totalPages);
            setError('');
            setDataLoaded(true);
        } catch (error) {
            console.error('Error fetching all Leak Reports:', error.message);
            setError('Failed to fetch Leak Reports data.');
            setLeakReports([]);
            setTotalPages(0);
        }
    }, [filterOptions, pageNumber]);

    useEffect(() => {
        refetchLeakReportData();
    }, [pageNumber, refreshFlag, refetchLeakReportData, totalPages, fetchTrigger, filterOptions ]);

    return (
        <>
            <LeakReportTableTopBar
                refetchLeakReportData={refetchLeakReportData}
                setPageNumber={setPageNumber}
                setFilterOptions={setFilterOptions}
                setFetchTrigger={setFetchTrigger}
                leakReports={LeakReports}
            />
            <LeakReportTable
                leakReports={LeakReports}
                setLeakReports={setLeakReports}
                setRefreshFlag={setRefreshFlag}
                refetchLeakReportData={refetchLeakReportData}
                error={error}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                filterOptions={filterOptions}
                dataLoaded={dataLoaded}
            />
        </>
    );
};

export default LeakReportContainer;
