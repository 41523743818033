import React, {useEffect, useRef, useState} from 'react';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    Box,
    Text,
    SimpleGrid, InputRightElement, InputGroup, IconButton, Spinner,
} from '@chakra-ui/react';
import { ThemedStyledInput } from "../../../../../components/Styled";
import FormActionLabel from "../../../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../../../components/Styled/StyledButtons";
import { useDrawer } from '../../../../../Context/DrawerContext/DrawerContext';
import { fetchPublicationCategoryById, updatePublicationCategory } from "../PublicationCategoryAPI";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ConfirmDialog from "../../../../../Context/ConfirmDialog/ConfirmDialog";
import {Icon} from "@iconify/react";
import useSingleToast from "../../../../../hooks/UseSingleToast/UseSingleToast";

const PublicationCategoryEditForm = ({ publicationCategoryId, refetchPublicationCategoryData }) => {
    const { closeDrawer } = useDrawer();
    const showToast = useSingleToast();
    const [initialValues, setInitialValues] = useState({
        _id: '',
        name: '',
    });
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    const hasFetched = useRef(false);

    useEffect(() => {
        const fetchAndSetPublicationCategoryData = async () => {
            if (hasFetched.current) {
                return;
            }

            try {
                const publicationCategoryData = await fetchPublicationCategoryById(publicationCategoryId);
                if (publicationCategoryData) {
                    setInitialValues({
                        _id: publicationCategoryData._id,
                        name: publicationCategoryData.name,
                    });
                }
                hasFetched.current = true;
            } catch (error) {
                showToast({
                    title: 'Error fetching publication category data',
                    description: error.message || 'An unexpected error occurred.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };

        fetchAndSetPublicationCategoryData();
    }, [publicationCategoryId, showToast]);



    const publicationCategorySchema = Yup.object().shape({
        name: Yup.string().required('Publication Category name is required.')
    });

    const handleSubmit = async (values, actions) => {
        try {
            await updatePublicationCategory(publicationCategoryId, values);
            showToast({
                title: 'Publication Category updated successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchPublicationCategoryData(); // Trigger refetching of publication category data
            closeDrawer();
        } catch (error) {
            showToast({
                title: 'Error updating publication category',
                description: error.message || 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            actions.setSubmitting(false);
        }
    };


    const handleCancel = () => {
        setConfirmDialog({
            isOpen: true,
            title: "Cancel Confirmation",
            message: "Are you sure you want to cancel? Any unsaved changes will be lost.",
            onConfirm: () => {
                closeDrawer();
                setConfirmDialog({ ...confirmDialog, isOpen: false }); // Close dialog after confirmation
            }
        });
    };


    const handleConfirm = () => {
        confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };


    return (
        <>
            <FormActionLabel formAction="edit" formName="Publication Category" />
            <Formik
                initialValues={initialValues}
                validationSchema={publicationCategorySchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {formikProps => (
                    <Form>
                        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                            <SimpleGrid columns={1} gap={4} px={2} py={4}>
                                <FieldControl
                                    formikProps={formikProps}
                                    name="name"
                                    label="Publication Category Name"
                                    placeholder="Enter Category Name"
                                    icon={<Icon icon="mdi:rename-outline" />}
                                />
                            </SimpleGrid>
                            <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}  mt={8} mb={8}>
                                <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
                                <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                                    <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                                        Cancel
                                    </CustomButton>
                                    <CustomButton
                                        type="submit"
                                        disabled={formikProps.isSubmitting}
                                        style={{
                                            opacity: formikProps.isSubmitting ? 0.7 : 1,
                                            pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                                        }}
                                    >
                                        {formikProps.isSubmitting ? (
                                            <>
                                                <Spinner size="xs" mr={2} />
                                                Updating...
                                            </>
                                        ) : 'Update'}
                                    </CustomButton>
                                </ButtonStack>
                            </Box>
                        </VStack>
                    </Form>
                )}
            </Formik>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

const FieldControl = ({ formikProps, name, label, placeholder, icon }) => (
    <GridItem>
        <FormControl isInvalid={formikProps.errors[name] && formikProps.touched[name]}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <InputGroup>
                <ThemedStyledInput
                    {...formikProps.getFieldProps(name)}
                    id={name}
                    placeholder={placeholder}
                    defaultValue={formikProps.values[name]} // Ensure the input is pre-filled with current values for editing
                />
                {icon && (
                    <InputRightElement>
                        <IconButton
                            aria-label="Icon button label"
                            bg="blue.300"
                            _hover={{ bg: '#69C5EC' }}
                            _active={{ bg: '#4699bc' }}
                            color="white"

                        >
                            {icon}
                        </IconButton>
                    </InputRightElement>
                )}
            </InputGroup>
            {formikProps.errors[name] && formikProps.touched[name] && (
                <Text color="red.500" fontSize="sm">{formikProps.errors[name]}</Text>
            )}
        </FormControl>
    </GridItem>
);


export default PublicationCategoryEditForm;
