import React from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  useToast
} from '@chakra-ui/react';
import { useDrawer } from "../../../../../Context/DrawerContext/DrawerContext";
import { ViewButton, EditButton, DeleteButton } from "../../../../../components/Styled/ActionButtons";
import { StyledTableContainer } from "../../../../../components/Styled/StyledComponents";
import PaginationControls from "../../../../../Context/PaginationControls/PaginationControls";
import photoService from '../../../../../services/photoService'; // Adjust path as necessary
import AlbumEditForm from './AlbumEditForm'; // Adjust path as necessary
import AlbumViewForm from './AlbumViewForm'; // Adjust path as necessary

const AlbumTable = ({ albums, setAlbums, fetchAlbums }) => {
  const { openDrawer } = useDrawer();
  const toast = useToast({ position: 'top-right' });
  const [loading, setLoading] = React.useState(false);

  const handleView = async (albumId) => {
    setLoading(true);
    try {
     // const albumDetails = await photoService.getAlbumById(albumId);
      openDrawer({
        title: 'View Album',
        component: AlbumViewForm,
        props: { albumId }
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: `Failed to fetch album details: ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (albumId) => {
    openDrawer({
      title: 'Edit Album',
      component: AlbumEditForm,
      props: {
        albumId,
        refetchAlbums: fetchAlbums
      }
    });
  };

  const handleDelete = async (albumId) => {
    setLoading(true);
    try {
      await photoService.deleteAlbum(albumId);
      toast({
        title: 'Album deleted successfully',
        description: 'The album has been successfully deleted.',
        status: 'success',
        duration: 5000,
        isClosable: true
      });
      fetchAlbums(); // Refresh the list of albums
    } catch (error) {
      toast({
        title: 'Failed to delete album',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <StyledTableContainer>
        <PaginationControls /* Pagination Props here */ />
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>Title</Th>
              <Th>Description</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {albums.map((album) => (
              <Tr key={album._id}>
                <Td>{album.title}</Td>
                <Td>{album.description}</Td>
                <Td>
                  <ViewButton onClick={() => handleView(album._id)} />
                  <EditButton onClick={() => handleEdit(album._id)} />
                  <DeleteButton onClick={() => handleDelete(album._id)} />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </StyledTableContainer>
    </>
  );
};

export default AlbumTable;
