import React from 'react';
import { GridItem, Input, Select } from '@chakra-ui/react';
import { StyledGrid } from "../../../components/Styled/StyledComponents";
import { ButtonStack, CustomButton } from "../../../components/Styled/StyledButtons";
import { useDrawer } from "../../../Context/DrawerContext/DrawerContext";
import { Icon } from "@iconify/react";
import AddPageForm from './AddPageForm'; // Adjust the path as necessary

const categories = [
    "RESIDENTIAL",
    "COMMERCIAL",
    "SERVICES",
    "HELP_AND_ADVICE",
    "PRODUCT_AND_SERVICES",
    "DAM_LEVELS",
    "WUC_AT_A_GLANCE",
    "YOUR_WATER_ACCOUNT",
    "PRE_DEVELOPMENT_ENQUIRY",
    "ABOUT_YOUR_METER_READING",
    "TERMS_AND_CONDITIONS",
    "QUICK_LINKS"
];
const PageTableTopBar = ({ refetchPages,pages, setSearchQuery, setCategoryFilter, searchQuery }) => {
    const { openDrawer } = useDrawer();

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        refetchPages();
    };

    const handleCategoryChange = (event) => {
        setCategoryFilter(event.target.value);
        refetchPages();
    };

    const handleAddNewPage = () => {
        openDrawer({
            title: 'Add New Page',
            component: AddPageForm,
            props: { refetchPages, pages },
            size: "2xl"
        });
    };

    return (
        <>
            <StyledGrid templateColumns="repeat(12, 1fr)">
                <GridItem colSpan={{ base: 6, md: 4 }}>
                    <Input
                        placeholder="Search pages..."
                        onChange={handleSearchChange}
                        value={searchQuery}
                    />
                </GridItem>
                <GridItem colSpan={{ base: 6, md: 4 }}>
                    <Select placeholder="Select category" onChange={handleCategoryChange}>
                        <option value="">All Categories</option>
                        {categories.map((category, index) => (
                            <option key={index} value={category}>{category}</option>
                        ))}
                    </Select>
                </GridItem>
                <GridItem colSpan={{ base: 12, md: 4 }}>
                    <ButtonStack direction="row">
                        <CustomButton
                            type="addNew"
                            showIcon={true}
                            tooltipLabel="Add New Page"
                            onClick={handleAddNewPage}
                            leftIcon={<Icon icon="fa6-solid:plus" />}
                        >
                            Add New
                        </CustomButton>
                    </ButtonStack>
                </GridItem>
            </StyledGrid>
        </>
    );
};

export default PageTableTopBar;
