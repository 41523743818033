import React, {useEffect, useRef, useState} from 'react';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    Box,
    Text,
    SimpleGrid, InputRightElement, InputGroup, IconButton, Spinner, Switch, Badge,
} from '@chakra-ui/react';
import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';
import {useDrawer} from "../../../../Context/DrawerContext/DrawerContext";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";
import {fetchFaqById, updateFaq} from "../FaqsAPI";
import FormActionLabel from "../../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../../components/Styled/StyledAlert";
import {ButtonStack, CustomButton} from "../../../../components/Styled/StyledButtons";
import ConfirmDialog from "../../../../Context/ConfirmDialog/ConfirmDialog";
import {ThemedStyledInput, ThemedStyledSelectInput} from "../../../../components/Styled";
import Icon from "../../../../@core/components/icon";
import faqCategories from "./FaqCategories";
import TextEditor from "../../../../Context/TextEditor/TextEditor";

const FaqEditForm = ({ faqId, refetchFaqData }) => {
    const { closeDrawer } = useDrawer();
    const showToast = useSingleToast();
    const [initialValues, setInitialValues] = useState({
        question: '',
        answer: '',
        category: '',
        published: false
    });
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    const hasFetched = useRef(false);

    useEffect(() => {
        const fetchAndSetFaqData = async () => {
            if (hasFetched.current) {
                return;
            }

            try {
                const faqData = await fetchFaqById(faqId);
                if (faqData) {
                    setInitialValues({
                        question: faqData.question,
                        answer: faqData.answer,
                        category: faqData.category,
                        published: faqData.published
                    });
                }
                hasFetched.current = true;
            } catch (error) {
                showToast({
                    title: 'Error fetching FAQ data',
                    description: error.message || 'An unexpected error occurred.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };

        fetchAndSetFaqData();
    }, [faqId, showToast]);



    const faqSchema = Yup.object().shape({
        question: Yup.string().required('FAQ question is required.'),
        answer: Yup.string().required('FAQ answer is required.'),
        category: Yup.string().required('Category is required.'),
        published: Yup.boolean().required('Published status is required.')
    });

    const handleSubmit = async (values, actions) => {
        try {
            await updateFaq(faqId, values);
            showToast({
                title: 'FAQ updated successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchFaqData(); // Trigger refetching of FAQ data
            closeDrawer();
        } catch (error) {
            showToast({
                title: 'Error updating FAQ',
                description: error.message || 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            actions.setSubmitting(false);
        }
    };


    const handleCancel = () => {
        setConfirmDialog({
            isOpen: true,
            title: "Cancel Confirmation",
            message: "Are you sure you want to cancel? Any unsaved changes will be lost.",
            onConfirm: () => {
                closeDrawer();
                setConfirmDialog({ ...confirmDialog, isOpen: false }); // Close dialog after confirmation
            }
        });
    };


    const handleConfirm = () => {
        confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };


    return (
        <>
            <FormActionLabel formAction="edit" formName="FAQ" />
            <Formik
                initialValues={initialValues}
                validationSchema={faqSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ setFieldValue, ...formikProps }) => (
                    <Form>
                        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                            <SimpleGrid columns={1} gap={4} px={2} py={4}>
                                <FieldControl
                                    formikProps={formikProps}
                                    name="question"
                                    label="Question :"
                                    placeholder="Enter Question"
                                    icon={<Icon icon="mdi:rename-outline" />}
                                />
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.answer && formikProps.touched.answer}>
                                        <FormLabel htmlFor="answer">Answer :</FormLabel>
                                        <Field name="answer">
                                            {({ field }) => (
                                                <TextEditor
                                                    value={field.value}
                                                    onChange={(value) => setFieldValue('answer', value)}
                                                    height="200px"
                                                />
                                            )}
                                        </Field>
                                        {formikProps.errors.answer && formikProps.touched.answer && (
                                            <Text color="red.500" fontSize="sm">{formikProps.errors.answer}</Text>
                                        )}
                                    </FormControl>
                                </GridItem>
                                <FormControl isInvalid={formikProps.errors['category'] && formikProps.touched['category']}>
                                    <FormLabel htmlFor="category">Category : </FormLabel>
                                    <ThemedStyledSelectInput
                                        {...formikProps.getFieldProps('category')}
                                        placeholder=" -- Select Category -- "
                                    >
                                        {Object.values(faqCategories).map((category, index) => (
                                            <option key={index} value={category}>{category}</option>
                                        ))}
                                    </ThemedStyledSelectInput>
                                    {formikProps.errors['category'] && formikProps.touched['category'] && (
                                        <Text color="red.500" fontSize="sm">{formikProps.errors['category']}</Text>
                                    )}
                                </FormControl>
                                <FormControl display="flex" alignItems="center">
                                    <FormLabel htmlFor="published" mb="0">
                                        Visibility Status :
                                    </FormLabel>
                                    <Box display="flex" alignItems="center">
                                        <Switch {...formikProps.getFieldProps('published')} id="published" isChecked={formikProps.values.published} />
                                        <Badge ml={2} variant="solid" colorScheme={formikProps.values.published ? "green" : "red"}>
                                            {formikProps.values.published ? "Published" : "Unpublished"}
                                        </Badge>
                                    </Box>
                                </FormControl>
                            </SimpleGrid>
                            <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}  mt={8} mb={8}>
                                <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
                                <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                                    <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                                        Cancel
                                    </CustomButton>
                                    <CustomButton
                                        type="submit"
                                        disabled={formikProps.isSubmitting}
                                        style={{
                                            opacity: formikProps.isSubmitting ? 0.7 : 1,
                                            pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                                        }}
                                    >
                                        {formikProps.isSubmitting ? (
                                            <>
                                                <Spinner size="xs" mr={2} />
                                                Updating...
                                            </>
                                        ) : 'Update'}
                                    </CustomButton>
                                </ButtonStack>
                            </Box>
                        </VStack>
                    </Form>
                )}
            </Formik>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

const FieldControl = ({ formikProps, name, label, placeholder, icon }) => (
    <GridItem>
        <FormControl isInvalid={formikProps.errors[name] && formikProps.touched[name]}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <InputGroup>
                <ThemedStyledInput
                    {...formikProps.getFieldProps(name)}
                    id={name}
                    placeholder={placeholder}
                    defaultValue={formikProps.values[name]}
                />
                {icon && (
                    <InputRightElement>
                        <IconButton
                            aria-label="Icon button label"
                            bg="blue.300"
                            _hover={{ bg: '#69C5EC' }}
                            _active={{ bg: '#4699bc' }}
                            color="white"

                        >
                            {icon}
                        </IconButton>
                    </InputRightElement>
                )}
            </InputGroup>
            {formikProps.errors[name] && formikProps.touched[name] && (
                <Text color="red.500" fontSize="sm">{formikProps.errors[name]}</Text>
            )}
        </FormControl>
    </GridItem>
);


export default FaqEditForm;
