import React from "react";
import {
    Flex,
    FormControl,
    Icon,
    Text,
    useDisclosure, Tooltip,
} from "@chakra-ui/react";

import {FaFilePdf} from "react-icons/fa6";
import utils from "../utils/commonFunctions";
import PdfViewerModal from "./PdfViewer/PdfViewerModal";

const DropBox = ({ label,filePath}) => {
//open modal
    const {isOpen, onOpen, onClose} = useDisclosure()
    return (
        <>
            <FormControl id={label}>
                <>
                    {filePath && (
                        <Tooltip label={'Click to View File'}>
                            <Flex

                                as={'button'}
                                type={'button'}
                                onClick={onOpen}
                                alignItems="center"
                                h={'80px'}
                                borderRadius="lg"
                                bg="white"
                                w="full"
                                boxShadow="md"
                                _hover={{bg: 'gray.50'}}
                            >
                                <Flex justify={'center'} align={'center'}   h={'full'}
                                      borderLeftRadius="lg" px={4}>
                                    <Icon as={FaFilePdf} color={'red.400'} fontSize={{base:'32px',md:'48px'}}/>
                                </Flex>
                                <Text fontWeight="medium" px={2} isTruncated={true} fontWeight={'bold'} color={'gray.400'}>
                                    {utils.getOriginalFileName(filePath)}
                                </Text>
                            </Flex>
                        </Tooltip>
                    )}
                </>

            </FormControl>
            <PdfViewerModal  filePath={filePath} isOpen={isOpen} onOpen={onOpen} onClose={onClose}/>
        </>
    );
};

export default DropBox;
