import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box,
    Badge, Center
} from '@chakra-ui/react';
import { useDrawer } from "../../../Context/DrawerContext/DrawerContext";
import { EditButton, ViewButton, DeleteButton, Purchases } from "../../../components/Styled/ActionButtons";
import { StyledTableContainer } from "../../../components/Styled/StyledComponents";
import PaginationControls from "../../../Context/PaginationControls/PaginationControls";
import { fetchAllTenders, deleteTender } from "../tenderAPI";
import ConfirmDialog from "../../../Context/ConfirmDialog/ConfirmDialog";
import useSingleToast from "../../../hooks/UseSingleToast/UseSingleToast";
import tenderEditForm from "./TenderEditForm";
import utils from '../../../utils/commonFunctions';

const TendersTable = ({ tenders, setTenders, error, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag }) => {
    const { openDrawer } = useDrawer();
    const showToast = useSingleToast();
    const navigate = useNavigate();

    const handleEdit = (tender) => {
        openDrawer({
            title: 'Edit Tender',
            component: tenderEditForm,
            props: {
                tenderId: tender._id,
                tenderData: tender,
                refetchTendersData: refetchTendersData,
                setRefreshFlag: setRefreshFlag,
            },
            key: `edit-${tender._id}`,
        }, 'md');
    };

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => { },
        tenderId: null
    });

    const requestConfirm = (options) => {
        setConfirmDialog({
            isOpen: true,
            title: options.title,
            message: options.message,
            onConfirm: options.onConfirm,
            tenderId: options.tenderId
        });
    };

    const handleView = (tender) => {
        navigate(`/tender/view/${tender._id}`, { state: { tenderId: tender._id, title: tender.title } });
    };
    const handleViewPurchases = (tenderId) => {
        navigate(`/tender/purchases/view/${tenderId}`, { state: { tenderId: tenderId } });
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const deleteOption = (tenderId) => {
        console.log("Inside the delete option ID:" + tenderId);
        console.log(tenderId);
        requestConfirm({
            title: "Delete Confirmation",
            message: "Are you sure you want to delete this tender? If you proceed this proiject will be lost forever!",
            onConfirm: handleConfirm,
            tenderId: tenderId
        });
    };

    const handleConfirm = async () => {
        try {
            const response = await deleteTender(confirmDialog.tenderId);
            showToast({
                title: 'Tender deleted successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchTendersData();
        } catch (error) {
            utils.sendMessageToBugNinja("Error deleting tender data", error.message, error);
            showToast({
                title: 'Error deleting tender data',
                description: error.message || 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
        // confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) {
          return text;
        }
        return text.slice(0, maxLength) + '...';
      };

    const refetchTendersData = useCallback(async () => {
        try {
            const data = await fetchAllTenders(pageNumber, 10);
            console.log('Refetched Tenders data');
            // console.table(data.tenders)
            setTenders(data.tenders);
            setTotalPages(data.totalPages);
        } catch (error) {
            showToast({
                title: 'Error fetching tenders',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            utils.sendMessageToBugNinja("Error fetching tenders", error.message, error);
        }
    }, [pageNumber, setTenders, setTotalPages]);

    useEffect(() => {
        refetchTendersData();
    }, [refetchTendersData]);

    if (error) {
        return <Box textAlign="center" my="4">Failed to fetch tenders data: {error}</Box>;
    }

    if (dataLoaded && Array.isArray(tenders) && tenders.length === 0) {
        return <Box textAlign="center" my="4">No records found.</Box>;
    }

    return (
        <>
            <StyledTableContainer>
                <PaginationControls
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    totalPages={totalPages}
                />
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th>#</Th>
                            <Th>Title</Th>
                            <Th>Description</Th>
                            <Th>Start Date</Th>
                            <Th>End Date</Th>
                            <Th><Center>Actions</Center></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        
                        {tenders?.map((tender, index) => (
                            <Tr key={index}>
                                <Td>WUC-TR00{tender.number}</Td>
                                <Td>{truncateText(tender.title, 30)}</Td>
                                <Td>
                                    <div dangerouslySetInnerHTML={{ __html: truncateText(tender.description, 30) }} />
                                </Td>
                                <Td>
                                    <Badge ml='1' colorScheme='green'>
                                        {tender.startDate}
                                    </Badge>
                                </Td>
                                <Td>
                                <Badge ml='1' colorScheme='green'>
                                        {tender.closingDate}
                                    </Badge>
                                </Td>
                                <Td>
                                    <ViewButton onClick={() =>
                                        handleView(tender)
                                    } />
                                    <EditButton onClick={() =>
                                        handleEdit(tender)
                                    } />
                                    <DeleteButton onClick={() =>
                                        deleteOption(tender._id)
                                    } />
                                    <Purchases onClick={() =>
                                        handleViewPurchases(tender._id)
                                    } />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </StyledTableContainer>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

export default TendersTable;
