import React, {useCallback, useEffect, useRef, useState} from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Box } from '@chakra-ui/react';
import CityEditForm from './CityEditForm';
import CityViewForm from './CityViewForm';
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import { DeleteButton, EditButton, ViewButton } from "../../../../components/Styled/ActionButtons";
import { StyledTableContainer } from "../../../../components/Styled/StyledComponents";
import PaginationControls from "../../../../Context/PaginationControls/PaginationControls";
import { fetchCityById, fetchAllCities, deleteCity} from "../CitiesAPI";
import ConfirmDialog from "../../../../Context/ConfirmDialog/ConfirmDialog";
import {fetchAllManagementCenters} from "../../managementCenters/ManagementCenterAPI";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";

const CityTable = ({ cities, setCities, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag, filterOptions }) => {
    const { openDrawer } = useDrawer();
    const [managementCenters, setManagementCenters] = useState([]);
    const [error, setError] = useState('');
    const showToast = useSingleToast();
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    const showToastRef = useRef(showToast);

    
    const updateCitiesAndPages = useCallback((newCities, totalDocs) => {
        setCities(newCities);
        setTotalPages(Math.ceil(totalDocs / 10));
    }, [setCities, setTotalPages]);


    const refetchCityData = useCallback(async (options = {}) => {
        setError('');
        try {
            const citiesResponse = await fetchAllCities(pageNumber, 10, filterOptions);
            const managementCentersResponse = await fetchAllManagementCenters();

            if (citiesResponse && citiesResponse.totalPages) {
                updateCitiesAndPages(citiesResponse.cities, citiesResponse.totalDocs);
            } else {
                console.error("No totalPages found:", citiesResponse);
                setCities([]);
                setTotalPages(0);
            }

            if (managementCentersResponse && managementCentersResponse.managementCenters) {
                setManagementCenters(managementCentersResponse.managementCenters);
            } else {
                console.error("Management Centers data missing:", managementCentersResponse);
                setManagementCenters([]);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setError(error.message || 'Failed to fetch data. Please try again later.');
            showToastRef.current({
                title: "Error fetching data",
                description: error.message || "An unexpected error occurred.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    }, [filterOptions, pageNumber, setCities, setTotalPages, updateCitiesAndPages]);

    useEffect(() => {
        refetchCityData();
    }, [refetchCityData, pageNumber, filterOptions]);

    const getManagementCenterNameById = useCallback((id) => {
        const center = managementCenters.find(mc => mc._id === id);
        return center ? center.name : "Unknown";
    }, [managementCenters]);


    if (error) return <Box textAlign="center" my="4">{error}</Box>;
    if (dataLoaded && !cities.length) return <Box textAlign="center" my="4">No records found.</Box>;


    const handleEdit = (cityId) => {
        openDrawer({
            title: 'Edit City',
            component: CityEditForm,
            props: {
                cityId: cityId,
                refetchCityData: refetchCityData,
                setRefreshFlag: setRefreshFlag,
            },
            key: `edit-${cityId}`,
        }, 'md');
    };


    const handleView = async (city) => {
        try {
            const cityData = await fetchCityById(city._id);
            if (cityData) {
                openDrawer({
                    title: 'View City',
                    component: CityViewForm,
                    props: { formData: cityData },
                    key: `view-${city._id}`,
                }, 'md');
            } else {
                throw new Error('City data not found.');
            }
        } catch (error) {
            setError(error.message);
            showToast({
                title: 'Error',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };


    const handleDelete = (cityId) => {
        setConfirmDialog({
            isOpen: true,
            title: "Confirm Delete",
            message: "Are you sure you want to delete this city? This action cannot be undone.",
            onConfirm: () => confirmDelete(cityId),
        });
    };

    const confirmDelete = async (cityId) => {
        try {
            await deleteCity(cityId);
            showToast({
                title: "City Deleted",
                description: "Successfully deleted the city.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ ...confirmDialog, isOpen: false });
            await refetchCityData(filterOptions); // Refresh the list
        } catch (error) {
            showToast({
                title: "Deletion Failed",
                description: "Could not delete the city. It might have associated areas.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ ...confirmDialog, isOpen: false });
        }
    };

    return (
        <>
            <StyledTableContainer>
                <PaginationControls pageNumber={pageNumber} setPageNumber={setPageNumber} totalPages={totalPages} />
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th>City</Th>
                            <Th>Management Center</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {cities.map(city => (
                            <Tr key={city._id}>
                                <Td>{city.name}</Td>
                                <Td>{getManagementCenterNameById(city.managementCenterId)}</Td>
                                <Td>
                                    <ViewButton onClick={() => handleView(city)} />
                                    <EditButton onClick={() => handleEdit(city._id)} />
                                    <DeleteButton onClick={() => handleDelete(city._id)} />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </StyledTableContainer>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                title={confirmDialog.title}
                message={confirmDialog.message}
                onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
                onConfirm={confirmDialog.onConfirm}
            />
        </>
    );
};

export default CityTable;
