import React, { useState } from 'react';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    useToast,
    Box,
    Text,
    Switch,
    Textarea,
    Tooltip,
    SimpleGrid,
    Spinner
} from '@chakra-ui/react';
import  {ThemedStyledInput} from "../../../components/Styled";
import FormActionLabel from "../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../components/Styled/StyledButtons";
import { useDrawer } from '../../../Context/DrawerContext/DrawerContext';
import faqService from '../../../services/faqService';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
const faqCategories = {
    BILLING_PAYMENT: 'Billing and Payment',
    WATER_QUALITY: 'Water Quality',
    SERVICE_ISSUES: 'Service Issues',
    CONSERVATION_TIPS: 'Conservation Tips',
    ACCOUNT_MANAGEMENT: 'Account Management',
    REGULATIONS_COMPLIANCE: 'Regulations and Compliance',
    INSTALLATION_SERVICES: 'Installation Services',
    EMERGENCY_PROCEDURES: 'Emergency Procedures',
    SUSTAINABILITY_INITIATIVES: 'Sustainability Initiatives',
    TECHNICAL_SUPPORT: 'Technical Support'
};
const FAQAddForm = ({ refetchFAQData }) => {
    const { closeDrawer } = useDrawer();
    const toast = useToast({ position: 'top-right' });

    const faqSchema = Yup.object().shape({
        question: Yup.string().required('Question is required.'),
        answer: Yup.string().required('Answer is required.'),
        category: Yup.string().oneOf(Object.values(faqCategories), 'Invalid category').required('Category is required.'),
        published: Yup.boolean().required('Publish status is required.')
    });

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try { 
            await faqService.createFAQ(values)
            toast({
                title: 'FAQ added successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            resetForm();
         
            refetchFAQData();  
            
            closeDrawer();
        } catch (error) {
            toast({
                title: 'Error adding FAQ',
                description: error.message || 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Formik
            initialValues={{
                question: '',
                answer: '',
                category: '',
                published: false
            }}
            validationSchema={faqSchema}
            onSubmit={handleSubmit}
        >
            {formikProps => (
                <Form>
                    <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                        <FormActionLabel formAction="add" formName="FAQ"/>
                        <SimpleGrid columns={1} gap={4} px={2} py={4}>
                            <FieldControl formikProps={formikProps} name="question" label="Question" placeholder="Enter the FAQ question" />
                            <TextAreaControl formikProps={formikProps} name="answer" label="Answer" placeholder="Enter the FAQ answer" />
                            <CategoryControl formikProps={formikProps} name="category" label="Category" />
                            <SwitchControl formikProps={formikProps} name="published" label="Published" />
                        </SimpleGrid>
                        <AlertAndButtons formikProps={formikProps} closeDrawer={closeDrawer} />
                    </VStack>
                </Form>
            )}
        </Formik>
    );
};

const FieldControl = ({ formikProps, name, label, placeholder }) => (
    <GridItem>
        <FormControl isInvalid={formikProps.errors[name] && formikProps.touched[name]}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <ThemedStyledInput
                {...formikProps.getFieldProps(name)}
                id={name}
                placeholder={placeholder}
            />
            {formikProps.errors[name] && formikProps.touched[name] && (
                <Text color="red.500" fontSize="sm">{formikProps.errors[name]}</Text>
            )}
        </FormControl>
    </GridItem>
);
const TextAreaControl = ({ formikProps, name, label, placeholder }) => (
    <GridItem>
        <FormControl isInvalid={formikProps.errors[name] && formikProps.touched[name]}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <Textarea
                {...formikProps.getFieldProps(name)}
                id={name}
                placeholder={placeholder}
            />
            {formikProps.errors[name] && formikProps.touched[name] && (
                <Text color="red.500" fontSize="sm">{formikProps.errors[name]}</Text>
            )}
        </FormControl>
    </GridItem>
);

const CategoryControl = ({ formikProps, name, label }) => (
    <FormControl isInvalid={formikProps.errors[name] && formikProps.touched[name]}>
        <FormLabel>{label}</FormLabel>
        <ThemedStyledInput as="select" {...formikProps.getFieldProps(name)}>
            {Object.values(faqCategories).map(option => (
                <option key={option} value={option}>{option}</option>
            ))}
        </ThemedStyledInput>
        {formikProps.errors[name] && formikProps.touched[name] && (
            <Text color="red.500" fontSize="sm">{formikProps.errors[name]}</Text>
        )}
    </FormControl>
);

const SwitchControl = ({ formikProps, name, label }) => (
    <FormControl display="flex" alignItems="center">
        <FormLabel htmlFor={name} mb="0">
            {label}
        </FormLabel>
        <Switch {...formikProps.getFieldProps(name)} isChecked={formikProps.values[name]} />
    </FormControl>
);

const AlertAndButtons = ({ formikProps, closeDrawer }) => (
    <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}>
        <CustomAlert status="warning" message="Make sure all fields are correctly filled before submitting." />
        <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
            <CustomButton onClick={() => formikProps.handleReset()} type="reset">
                Reset
            </CustomButton>
            <CustomButton type="submit" disabled={formikProps.isSubmitting} style={{ opacity: formikProps.isSubmitting ? 0.7 : 1 }}>
                Submit
            </CustomButton>
        </ButtonStack>
    </Box>
);

export default FAQAddForm;
