import React, { useCallback, useEffect, useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box,
    Badge,
} from '@chakra-ui/react';
import CustomerEditForm from './CustomerEditForm';
import CustomerViewForm from './CustomerViewForm';
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import { EditButton, ViewButton, UpdateKYC } from "../../../../components/Styled/ActionButtons";
import { StyledTableContainer } from "../../../../components/Styled/StyledComponents";
import PaginationControls from "../../../../Context/PaginationControls/PaginationControls";
import { fetchCustomerById, fetchAllCustomers, fetchUserProfileById } from "../CustomerAPI";
import CustomerUpdateKYCForm from "./CustomerKycUpdateForm";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";
import { useCustomer } from "../../../../Context/CustomerContext/CustomerContext";

const CustomerTable = () => {
    const { openDrawer } = useDrawer();
    const {fetchCustomers, customers, pageNumber, setPageNumber, totalPages, setTotalPages, filterOptions, setFilterOptions, error, setError } = useCustomer();
    const showToast = useSingleToast();
    
    useEffect(() => {
        fetchCustomers();
    }, []);

    const handleEdit = (customerId) => {
        openDrawer({
            title: 'Edit Customer',
            component: CustomerEditForm,
            props: {
                customerId: customerId,
                fetchCustomers: fetchCustomers
            },
            key: `edit-${customerId}`,
        }, 'md');
    };

    const handleUpdateKYC = async (customerId) => {
        try {
            const response = await fetchUserProfileById(customerId);
            const customer = response.data[0];
            if (customer === undefined || customer === null) {

                showToast({
                    title: "No Details Found!",
                    description: "User has not submitted KYC.",
                    status: "info",
                    duration: 5000,
                    isClosable: true,
                });

                return;
            }

            openDrawer({
                title: 'Update Customer Profile',
                component: CustomerUpdateKYCForm,
                props: {
                    customer: customer,
                    customerId: customerId,
                    fetchCustomers: fetchCustomers
                },
                key: `edit-${customer._id}`,
            }, 'xl');

        } catch (error) {
            showToast({
                title: "Error",
                description: "Failed to fetch customer profile data. Please try again later.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            console.error("Failed to fetch customer profile data:", error);
        }

    };

    const handleView = async (customer) => {
        try {
            const updatedCustomerData = await fetchCustomerById(customer._id);
            let profileResponse = { success: false, data: {} };
            try {
                const response = await fetchUserProfileById(customer._id);
                profileResponse = response.success && response.data.length > 0 ? { success: true, data: response.data[0] } : { success: false, data: {} };
            } catch (profileError) {
                console.error("Failed to fetch customer profile data:", profileError);
            }


            openDrawer({
                title: 'View Customer',
                component: CustomerViewForm,
                props: {
                    formData: updatedCustomerData,
                    profileData: profileResponse ? profileResponse.data : {},
                },
                key: `view-${customer._id}`,
            }, 'xl');

        } catch (error) {
            console.error("Failed to fetch customer data:", error);
            setError("Failed to fetch customer data. Please try again later.");
        }
    };

    if (error) {
        return <Box textAlign="center" my="4">Failed to fetch customer data: {error}</Box>;
    }

    if ( Array.isArray(customers) && customers.length === 0) {
        return <Box textAlign="center" my="4">No records found.</Box>;
    }

    return (
        <>
            <StyledTableContainer>
                <PaginationControls
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    totalPages={totalPages}
                /> 
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th>First Name</Th>
                            <Th>Last Name</Th>
                            <Th>Email</Th>
                            <Th>Phone</Th>
                            <Th>Verification Status</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {customers.map((customer) => (
                            <Tr key={customer._id}>
                                <Td>{customer.firstName}</Td>
                                <Td>{customer.lastName}</Td>
                                <Td>{customer.email}</Td>
                                <Td>{customer.phone}</Td>
                                <Td>
                                    <Badge colorScheme={customer.isVerified ? 'green' : 'red'} ml={2} variant="solid">
                                        {customer.isVerified ? 'Verified' : 'Not Verified'}
                                    </Badge>
                                </Td>
                                <Td>
                                    <ViewButton onClick={() => handleView(customer)} />
                                    <EditButton onClick={() => handleEdit(customer._id)} />
                                    <UpdateKYC onClick={() => handleUpdateKYC(customer._id)} />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </StyledTableContainer>
        </>
    );
};

export default CustomerTable;
