import { API1 } from "../../../utils/api";

// Create LabServiceProduct
export const createLabServiceProduct = async (labServiceProductData) => {
    try {
        const response = await API1.post('/labs/products', labServiceProductData);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "An unexpected error occurred while creating the Lab Service Product.");
    }
};

// Fetch All LabServiceProducts
export const fetchAllLabServiceProducts = async (pageNumber = 1, pageSize = 10, filterOptions = {}) => {
    try {
        const queryParams = new URLSearchParams({
            ...filterOptions,
            pageNumber,
            pageSize
        }).toString();

        const response = await API1.get(`/labs/products?${queryParams}`);

        if (response.status === 200) {
            const { docs: labServiceProducts, totalDocs } = response.data.data;
            return {
                labServiceProducts,
                totalPages: Math.ceil(totalDocs / pageSize),
            };
        } else {
            throw new Error('Unexpected response from the server while fetching Lab Service Product.');
        }
    } catch (error) {
        console.error("Error fetching Lab Service Product:", error);
        throw new Error('Failed to fetch Lab Service Product. Please try again.');
    }
};

// Fetch LabServiceProduct by ID using query parameters
export const fetchLabServiceProductByMaterialNumber = async (labServiceProductMaterialNumber) => {
    try {
        const queryParams = new URLSearchParams({ materialNumber: labServiceProductMaterialNumber }).toString();
        const response = await API1.get(`/labs/products?${queryParams}`);
        if (response.status === 200 && response.data.data.docs.length > 0) {
            return response.data.data.docs[0];
        } else {
            throw new Error('Lab Service Product not found.');
        }
    } catch (error) {
        throw new Error(error?.message || "Failed to fetch the Lab Service Product details.");
    }
};

// Update LabServiceProduct
export const updateLabServiceProduct = async (labServiceProductMaterialNumber, labServiceProductData) => {
    try {
        const response = await API1.put(`/labs/products/`, {materialNumber: labServiceProductMaterialNumber, ...labServiceProductData});
        if (response.status === 201) {
            return response.data;
        } else {
            throw new Error('Failed to update Lab Service Product' + response.data.message);
        }
    } catch (error) {
        if (error.response) {

            throw new Error('Server error: ' + error.response.data.message);
        } else if (error.request) {

            throw new Error('Network error: Unable to reach the server.');
        } else {
            throw new Error('Error: ' + error.message);
        }
    }
};

// Delete LabServiceProduct
export const deleteLabServiceProduct = async (labServiceProductMaterialNumber) => {
    try {
        const response = await API1.delete(`/labs/products/`,{params:{materialNumber:labServiceProductMaterialNumber}});
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "Failed to delete Lab Service Product");
    }
};