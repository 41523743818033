import React, {useCallback, useEffect, useRef, useState} from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Box } from '@chakra-ui/react';
import NoticeEditForm from './NoticeEditForm';
import NoticeViewForm from './NoticeViewForm';
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import { DeleteButton, EditButton, ViewButton } from "../../../../components/Styled/ActionButtons";
import { StyledTableContainer } from "../../../../components/Styled/StyledComponents";
import PaginationControls from "../../../../Context/PaginationControls/PaginationControls";
import {fetchAllNotices, deleteNotice, fetchNoticeById} from "../NoticesAPI";
import ConfirmDialog from "../../../../Context/ConfirmDialog/ConfirmDialog";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";

const NoticeTable = ({ notices, setNotices, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag, filterOptions  }) => {
    const { openDrawer } = useDrawer();
    const [error, setError] = useState('');
    const showToast = useSingleToast();
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    const showToastRef = useRef(showToast);



    // Update function encapsulated in useCallback to ensure it's only created once
    const updateNoticesAndPages = useCallback((newNotices, totalDocs) => {
        setNotices(newNotices);
        setTotalPages(Math.ceil(totalDocs / 10));
    }, [setNotices, setTotalPages]);

    const refetchNoticeData = useCallback(async (options = {}) => {
        setError('');
        try {
            const noticesResponse = await fetchAllNotices(pageNumber, 10, filterOptions);
            if (!noticesResponse || !noticesResponse.notices) {
                throw new Error('No data received from the server');
            }
            if (noticesResponse && noticesResponse.totalPages) {
                updateNoticesAndPages(noticesResponse.consultants, noticesResponse.totalDocs);
            } else {
                console.error("No totalPages found:", noticesResponse);
                setNotices([]);
                setTotalPages(0);
            }
            
            updateNoticesAndPages(noticesResponse.notices, noticesResponse.totalDocs);

        } catch (error) {
            console.error("Error fetching data:", error);
            setError(error.message || 'Failed to fetch data. Please try again later.');
            showToastRef.current({
                title: "Error fetching data",
                description: error.message || "An unexpected error occurred.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    }, [filterOptions, pageNumber, setNotices, setTotalPages, updateNoticesAndPages]);

    useEffect(() => {
       refetchNoticeData();
    }, [refetchNoticeData, pageNumber, filterOptions]);


    if (error) return <Box textAlign="center" my="4">{error}</Box>;
    if (dataLoaded && !notices.length) return <Box textAlign="center" my="4">No records found.</Box>;


    const handleEdit = (noticeId) => {
        openDrawer({
            title: 'Edit Notice',
            component: NoticeEditForm,
            props: {
               noticeId: noticeId,
               refetchNoticeData:refetchNoticeData,
                setRefreshFlag: setRefreshFlag,
            },
            key: `edit-${noticeId}`,
        }, 'xl');
    };


    const handleView = async (notice) => {
        try {
            const noticeData = await fetchNoticeById(notice._id);
            if (noticeData) {
                openDrawer({
                    title: 'ViewNotice',
                    component:NoticeViewForm,
                    props: { formData:noticeData },
                    key: `view-${notice._id}`,
                }, 'xl');
            } else {
                throw new Error('Notice data not found.');
            }
        } catch (error) {
            setError(error.message);
            showToast({
                title: 'Error',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };


    const handleDelete = (noticeId) => {
        setConfirmDialog({
            isOpen: true,
            title: "Confirm Delete",
            message: "Are you sure you want to delete this notice? This action cannot be undone.",
            onConfirm: () => confirmDelete(noticeId),
        });
    };

    const confirmDelete = async (noticeId) => {
        try {
            await deleteNotice(noticeId);
            showToast({
                title: "Notice Deleted",
                description: "Successfully deleted notice.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ ...confirmDialog, isOpen: false });
           refetchNoticeData(filterOptions); // Refresh the list
        } catch (error) {
            console.log(error)
            showToast({
                title: "Deletion Failed",
                description: "Could not delete.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ ...confirmDialog, isOpen: false });
        }
    };

    return (
        <>
            <StyledTableContainer>
                <PaginationControls pageNumber={pageNumber} setPageNumber={setPageNumber} totalPages={totalPages} />
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th>Title</Th>
                            <Th>City</Th>
                            <Th>Area</Th>
                            <Th>Actions</Th>

                        </Tr>
                    </Thead>
                    <Tbody>
                        {notices&&notices.map(notice => (
                            <Tr key={notice._id}>
                                <Td>{notice.title}</Td>
                                <Td>{notice.cityName}</Td>
                                <Td>{notice.areaName}</Td>
                                <Td>
                                    <ViewButton onClick={() => handleView(notice)} />
                                    <EditButton onClick={() => handleEdit(notice._id)} />
                                    <DeleteButton onClick={() => handleDelete(notice._id)} />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </StyledTableContainer>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                title={confirmDialog.title}
                message={confirmDialog.message}
                onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
                onConfirm={confirmDialog.onConfirm}
            />
        </>
    );
};

export default NoticeTable;
