import React from "react";
import {
    Box,
    Flex,
    FormControl,
    FormLabel,
    Icon,
    Text,
    Spinner,
    useDisclosure
} from "@chakra-ui/react";

import {FaFile} from "react-icons/fa6";
import {LuFileUp} from "react-icons/lu";
import utils from "../../utils/commonFunctions";
import PdfViewerModal from "../PdfViewer/PdfViewerModal";
import {Tooltip} from "../Styled/StyledComponents";
import {get} from "lodash";
import FieldErrorMessage from "../FieldErrorMessage/FieldErrorMessage";

const DropBox = ({formikProps, loading, label, callback, name, filePath,instruction,isRequired}) => {
const {isOpen,onOpen,onClose}=useDisclosure()
const isError = get(formikProps.touched, `files.${name}`) && get(formikProps.errors, `files.${name}`);
if (isError) {
    const errorMessage = get(formikProps.errors, `files.${name}`);
}
return (
        <>
            <FormControl id={label} isRequired={isRequired} isInvalid={isError}>
                <FormLabel color="blue.800" mt={4} fontWeight={'bold'}>{label}</FormLabel>
                <Flex boxShadow={'sm'} mb={'2'} justifyContent={'center'} alignItems={'center'}
                      borderColor={'gray.300'} borderRadius={'lg'} borderStyle={'dashed'}
                      borderWidth={'1px'}>
                    <Box w={'full'} textAlign={'center'}>
                        <Box py={2}>
                            {!loading ?
                                <>
                                    <Icon as={LuFileUp} color={'gray.300'} boxSize={8}/>
                                    <input
                                        type="file"
                                        accept=".pdf,.docx,.doc,.mp4"
                                        name={name}
                                        id={label}
                                        style={{display: 'none'}}
                                        onChange={(event) => {
                                            callback(event, name);
                                            formikProps.setFieldValue(name, event.currentTarget.files[0]);
                                        }}
                                    />
                                    <FormLabel htmlFor={label} textAlign={'center'}
                                               cursor="pointer" color="blue.300" fontWeight={'bold'} fontSize={'xs'}>
                                        CLICK HERE TO UPLOAD
                                    </FormLabel>
                                </>
                                : <Spinner color={'brand.500'}/>
                            }
                        </Box>

                        <Box w={'full'} bg={'gray.100'} p={2} borderBottomRadius={'lg'}>
                            <Text fontSize={'xs'} fontWeight={'medium'} color={'gray.500'}>
                                {instruction}
                            </Text>
                        </Box>
                    </Box>
                </Flex>

                {isError && (
                    <FieldErrorMessage name={`files.${name}`} />
                )}


                {filePath && (
                    <Tooltip label={'Click to View File'}>
                        <Box
                            as={'button'}
                            type={'button'}
                            onClick={onOpen}
                            display="flex"
                            alignItems="center"
                            h={'50px'}
                            borderRadius="lg"
                            borderWidth="1px"
                            borderStyle={'dashed'}
                            borderColor="gray.300"
                            bg="white"
                            w="full"
                            boxShadow="sm"
                            _hover={{bg:'gray.100'}}
                        >
                            <Flex justify={'center'} align={'center'} h={'full'} bg="gray.100"
                                  borderLeftRadius="lg" px={4}>
                                <Icon as={FaFile} color={'blue.300'} fontSize={'24px'}/>
                            </Flex>
                            <Text fontWeight="medium" px={2} isTruncated={true} color={'gray.500'}>
                                {utils.getOriginalFileName(filePath)}
                            </Text>
                        </Box>
                    </Tooltip>
                )}
            </FormControl>
            <PdfViewerModal filePath={filePath} isOpen={isOpen} onOpen={onOpen} onClose={onClose}/>
        </>

    );
};

export default DropBox;
