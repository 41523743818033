import React, { useEffect, useState, useCallback } from 'react';
import CityTable from './CitiesTable';
import CityTableTopBar from './CityTableTopBar';
import { fetchAllCities } from '../CitiesAPI';

const CityContainer = () => {
    const [cities, setCities] = useState([]);
    const [error, setError] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [filterOptions, setFilterOptions] = useState({});
    const [fetchTrigger, setFetchTrigger] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);


    const refetchCityData = useCallback(async (options = {}) => {
        setDataLoaded(false);
        try {
            const data = await fetchAllCities(pageNumber, 10, filterOptions);
            setCities(data.cities);
            setTotalPages(data.totalPages);
            setError('');
            setDataLoaded(true);
        } catch (error) {
            console.error('Error fetching cities:', error);
            setError('Failed to fetch city data.');
            setCities([]);
            setTotalPages(0);
        }
    }, [pageNumber, filterOptions]);


    useEffect(() => {
        refetchCityData();
    }, [fetchTrigger, refetchCityData, pageNumber, filterOptions, totalPages, refreshFlag]);


    return (
        <>
            <CityTableTopBar
                refetchCityData={refetchCityData}
                setPageNumber={setPageNumber}
                setFilterOptions={setFilterOptions}
                setFetchTrigger={setFetchTrigger}
            />
            <CityTable
                cities={cities}
                setCities={setCities}
                setRefreshFlag={setRefreshFlag}
                refetchCityData={refetchCityData}
                error={error}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                filterOptions={filterOptions}
                dataLoaded={dataLoaded}
            />
        </>
    );
};

export default CityContainer;
