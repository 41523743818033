// pageService.js
import {API1} from "../utils/api";

const pageService = {
    createPage: async (pageData) => {
        if (!pageData.parentPage) {
            delete pageData.parentPage;  
        }
        const response = await API1.post(`/pages/page`, pageData);
        if (response.status!==200) {
            throw new Error(`Error creating page: ${response.data.status}`);
        }
        return response;
    },
    updatePage: async (pageId, data) => {
        const response = await API1.put(`/pages/page/${pageId}`,data);
        if (response.status!==200) {
            throw new Error(`Error updating page parent: ${response.statusText}`);
        }
        return response;
    },
    deletePage: async (pageId) => {
        const response = await API1.delete(`/pages/page/${pageId}`);
        if (response.status!==200) {
            throw new Error(`${response.data.message}`);
        }
        return response;
    },
    setActiveRevision: async (pageId, revisionId) => {
        const response = await API1.put(`/pages/page/${pageId}/revisions/${revisionId}/active`);
        if (response?.status!==200) {
            throw new Error(`Error setting active revision: ${response.statusText}`);
        }
        return response.data;
    },

    getPages: async () => {
        const response = await API1.get(`/pages/pages`);
        if (response?.status!==200) {
            throw new Error(`Error fetching pages: ${response.statusText}`);
        }
        return response.data;
    },
    getPageById: async (pageId) => {
        const response = await API1.get(`/pages/page/${pageId}`);
        if (response.status!==200) {
            throw new Error(`Error fetching pages: ${response.statusText}`);
        }
        return response.data;
    },
    getPageCategory: async (pageId) => {
        const response = await API1.get(`/pages/page/${pageId}`);
        if (response.status!==200) {
            throw new Error(`Error creating page: ${response.data.status}`);
        }
        return response.data.data.category
    },

    publishPage:async (pageId) =>{
        const response = await API1.put(`/pages/page/publish/${pageId}`);
        if (response.status!==200) {
            throw new Error(`Error publishing page: ${response.data.status}`);
        }
        return response;
    }
};

export default pageService;
