import React, { useEffect, useState } from 'react';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    Box,
    Text, InputGroup, InputRightElement, IconButton, SimpleGrid, Switch, Tooltip, Stack, Spinner,
} from '@chakra-ui/react';
import { ThemedStyledInput } from "../../../../components/Styled";
import FormActionLabel from "../../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../../components/Styled/StyledButtons";
import { useDrawer } from '../../../../Context/DrawerContext/DrawerContext';
import { addAdmin } from "../AdminAPI";
import {RepeatIcon, ViewIcon, ViewOffIcon} from "@chakra-ui/icons";
import { AdminPermissions } from "wuc-library/permissions";
import { Formik, Form} from 'formik';
import * as Yup from 'yup';
import ConfirmDialog from "../../../../Context/ConfirmDialog/ConfirmDialog";
import FieldErrorMessage from "../../../../components/FieldErrorMessage/FieldErrorMessage";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";

const AdminAddForm = ({ refetchAdminData, filterOptions }) => {
    const { closeDrawer } = useDrawer();
    const showToast = useSingleToast();

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    const adminSchema = Yup.object().shape({
        firstName: Yup.string().required('First name is required.'),
        lastName: Yup.string().required('Last name is required.'),
        phone: Yup.string().matches(/^\d{8}$/, 'Valid phone number is required (8 digits).').required('Phone is required.'),
        email: Yup.string().email('Invalid email address').required('Email is required.'),
        password: Yup.string().required('Password is required.'),
        permissions: Yup.array().min(1, '***At least one permission is required.'),
    });

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            await addAdmin(values);
            showToast({
                title: 'Admin added successfully',
                description: `Temporary password that has been sent: ${values.password}`,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            resetForm();
            refetchAdminData(filterOptions); // Trigger refetching of admin data
            closeDrawer();
        } catch (error) {
            if (error.response) {
                // Server error with response
                showToast({
                    title: 'Error adding admin',
                    description: error.response.data.message || 'An unexpected server error occurred',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } else if (error.request) {
                // Network error without response
                showToast({
                    title: 'Network Error',
                    description: 'Unable to connect to the server. Please check your internet connection.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                // Other errors
                showToast({
                    title: 'Error',
                    description: error.message || 'An unexpected error occurred',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } finally {
            setSubmitting(false);
        }
    };


    const requestConfirm = (options) => {
        setConfirmDialog({
            isOpen: true,
            title: options.title,
            message: options.message,
            onConfirm: options.onConfirm,
        });
    };

    const handleConfirm = () => {
        confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    return (
        <>
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    phone: '',
                    email: '',
                    password: '',
                    role: 'admin',
                    permissions: []
                }}
                validationSchema={adminSchema}
                onSubmit={handleSubmit}
            >
                {formikProps => (
                    <Form>
                        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                            <FormActionLabel formAction="add" formName="Admin User"/>
                            <SimpleGrid columns={3} gap={4} px={2} py={4}>
                                <FieldControl formikProps={formikProps} name="firstName" label="First Name" placeholder="Enter First Name" />
                                <FieldControl formikProps={formikProps} name="lastName" label="Last Name" placeholder="Enter Last Name" />
                                <FieldControl formikProps={formikProps} name="phone" label="Phone" placeholder="Enter phone" />
                                <FieldControl formikProps={formikProps} name="email" label="Email" placeholder="Enter email" />
                                <PasswordFieldControl formikProps={formikProps} name="password" label="Password" />
                                <PermissionsControl formikProps={formikProps} />
                            </SimpleGrid>
                            <AlertAndButtons
                                formikProps={formikProps}
                                closeDrawer={closeDrawer}
                                requestConfirm={requestConfirm} />
                        </VStack>
                    </Form>
                )}
            </Formik>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};



const FieldControl = ({ formikProps, name, label, placeholder }) => (
    <GridItem>
        <FormControl isInvalid={formikProps.errors[name] && formikProps.touched[name]}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <ThemedStyledInput
                {...formikProps.getFieldProps(name)}
                id={name}
                placeholder={placeholder}
            />
            {formikProps.errors[name] && formikProps.touched[name] && (
                <Text color="red.500" fontSize="sm">{formikProps.errors[name]}</Text>
            )}
        </FormControl>
    </GridItem>
);


const PasswordFieldControl = ({ formikProps, name, label }) => {
    const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = () => setShowPassword(prevState => !prevState);

    // Destructure setFieldValue and values from formikProps
    const { setFieldValue, values } = formikProps;

    const generatePassword = () => {
        const passwordLength = 10;
        const chars = 'abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890';
        let newPassword = '';
        let criteria = { lower: 0, upper: 0, digit: 0, special: 0 };

        while (criteria.lower < 1 || criteria.upper < 1 || criteria.digit < 1 || criteria.special < 1 || newPassword.length < passwordLength) {
            let char = chars.charAt(Math.floor(Math.random() * chars.length));
            newPassword += char;
            if (/[a-z]/.test(char)) criteria.lower++;
            if (/[A-Z]/.test(char)) criteria.upper++;
            if (/[0-9]/.test(char)) criteria.digit++;
            if (/[^a-zA-Z0-9]/.test(char)) criteria.special++;
        }

        return newPassword;
    };

    useEffect(() => {
        if (!values[name]) {
            const newPassword = generatePassword();
            setFieldValue(name, newPassword);
        }
    }, [setFieldValue, values, name]);


    if (!formikProps) {
        console.error('PasswordFieldControl: `formikProps` is undefined. Please pass Formik props to this component.');
        return null;
    }

    return (
        <FormControl id={name} isRequired>
            <FormLabel>{label}</FormLabel>
            <Stack direction="row" align="center">
                <InputGroup>
                    <ThemedStyledInput
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Enter password"
                        {...formikProps.getFieldProps(name)}
                    />
                    <InputRightElement>
                        <IconButton
                            aria-label={showPassword ? 'Hide password' : 'Show password'}
                            icon={showPassword ? <ViewOffIcon color="white" /> : <ViewIcon color="white" />}
                            onClick={toggleShowPassword}
                            bg="blue.300"
                            _hover={{ bg: '#69C5EC' }}
                            _active={{ bg: '#4699bc' }}
                        />
                    </InputRightElement>
                </InputGroup>
                <IconButton
                    aria-label="Regenerate password"
                    icon={<RepeatIcon />}
                    variant="ghost"
                    onClick={() => formikProps.setFieldValue(name, generatePassword())} // This action is user-initiated, thus won't cause an infinite loop
                />
            </Stack>
            <Text mt={2} color="gray.500" fontSize="sm">
                Reminder: AdminUser password is copyable.
            </Text>
        </FormControl>
    );
};



const PermissionsControl = ({ formikProps }) => {
    const allPermissionsChecked = formikProps.values.permissions.length === Object.values(AdminPermissions).length;

    const handleToggleAllPermissions = () => {
        if (allPermissionsChecked) {
            formikProps.setFieldValue('permissions', []);
        } else {
            formikProps.setFieldValue('permissions', Object.values(AdminPermissions));
        }
    };

    return (
        <GridItem colSpan={3}>
            <FormControl id="permissions" mt={4}>
                <FormLabel>Permissions</FormLabel>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={4}
                     p={3} borderWidth="1px" borderRadius="md" bg="blue.50" borderColor="blue.200">
                    <Text>Select All Permissions :</Text>
                    <Tooltip label="Select All Permissions" hasArrow placement="top">
                        <Switch
                            isChecked={allPermissionsChecked}
                            onChange={handleToggleAllPermissions}
                            size="lg"
                            colorScheme="blue"
                            sx={{
                                '.chakra-switch__track': {
                                    bg: allPermissionsChecked ? 'blue.300' : 'gray.200',
                                },
                                '.chakra-switch__thumb': {
                                    _checked: { bg: 'white' },
                                },
                            }}
                        />
                    </Tooltip>
                </Box>
                <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 5 }} spacing={5}>
                    {Object.entries(AdminPermissions).map(([key, permission]) => (
                        <Tooltip key={key} label={`Assign "${permission}" permission`} placement="top" hasArrow>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Text mr={2}>{permission}</Text>
                                <Switch
                                    isChecked={formikProps.values.permissions.includes(permission)}
                                    onChange={(e) => {
                                        const permissions = e.target.checked
                                            ? [...formikProps.values.permissions, permission]
                                            : formikProps.values.permissions.filter(p => p !== permission);
                                        formikProps.setFieldValue('permissions', permissions);
                                    }}
                                    sx={{
                                        '.chakra-switch__track': {
                                            bg: formikProps.values.permissions.includes(permission) ? 'blue.300' : 'gray.200',
                                        },
                                        '.chakra-switch__thumb': {
                                            _checked: { bg: 'white' },
                                        },
                                    }}
                                />
                            </Box>
                        </Tooltip>
                    ))}
                </SimpleGrid>
                <FieldErrorMessage name="permissions"/>
            </FormControl>
        </GridItem>
    );
};




const AlertAndButtons = ({ formikProps, closeDrawer, requestConfirm }) => {

    const handleCancel = () => {
        requestConfirm({
            title: "Cancel Confirmation",
            message: "Are you sure you want to cancel? Any unsaved changes will be lost.",
            onConfirm: () => {
                formikProps.handleReset();
                closeDrawer();
            }
        });
    };

    const handleReset = () => {
        requestConfirm({
            title: "Reset Confirmation",
            message: "Are you sure you want to reset all your changes?",
            onConfirm: () => {
                formikProps.handleReset();
            }
        });
    };

    return (
        <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}>
            <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
            <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                    Cancel
                </CustomButton>
                <CustomButton onClick={handleReset} type="reset">
                    Reset
                </CustomButton><CustomButton
                type="submit"
                disabled={formikProps.isSubmitting}
                style={{
                    opacity: formikProps.isSubmitting ? 0.7 : 1,
                    pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                }}
            >
                {formikProps.isSubmitting ? (
                    <>
                        <Spinner size="xs" mr={2} />
                        Submitting...
                    </>
                ) : 'Submit'}
            </CustomButton>
            </ButtonStack>
        </Box>
    );
};


export default AdminAddForm;
