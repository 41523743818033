import {Outlet, Navigate} from "react-router-dom";
function VerificationRouter() {
  //console.log(localStorage.getItem('userToken'))
  if (localStorage.getItem('userToken')) {
    return <Outlet/>
  }
  return <Navigate to={'/login'}/>
}

export default VerificationRouter;
