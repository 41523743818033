import React from 'react'
import TendersContainer from './Components/TendersContainer'
import {Icon} from "@iconify/react";
import DefaultTemplate from "../../components/DefaultTemplate/DefaultTemplate";

const Tenders = () => {
    return (
        <>
            <DefaultTemplate 
                Content={ <TendersContainer/> } 
                Title={"Tenders"} 
                icon={<Icon icon="fa:briefcase" />}
            />
        </>
    )
}

export default Tenders
