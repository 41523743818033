import React, { useEffect, useRef, useState } from 'react';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    useToast,
    Box,
    Text,
    SimpleGrid, Spinner,
    TableContainer,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    InputGroup,
    Input,
    Switch,

} from '@chakra-ui/react';
import { ThemedStyledInput } from "../../../components/Styled";
import FormActionLabel from "../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../components/Styled/StyledButtons";
import { useDrawer } from '../../../Context/DrawerContext/DrawerContext';
import { fetchPollById, updatePoll } from "../SurveyAPI";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ConfirmDialog from "../../../Context/ConfirmDialog/ConfirmDialog";
import { EditButton, DeleteButton } from "../../../components/Styled/ActionButtons";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import styles
import { Icon } from "@iconify/react";
import { set } from 'lodash';

const PollsEditForm = ({ pollId, refetchPollData, setRefreshFlag }) => {
    const { closeDrawer } = useDrawer();
    const toast = useToast({ position: 'top-right' });
    const hasFetched = useRef(false);
    var [initialValues, setInitialValues] = useState({
        question: '',
        options: [],
        start_date: '',
        published: false,
        closing_date: ''
    });
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => { },
    });

    useEffect(() => {
        const fetchAndSetPollData = async () => {
            if (hasFetched.current) {
                return;
            }

            try {
                const pollData = await fetchPollById(pollId);
                if (pollData) {
                    setInitialValues(prevState => ({
                        ...prevState,
                        question: pollData.question,
                        options: pollData.options,
                        published: pollData.published,
                        start_date: pollData.start_date ? pollData.start_date.split('T')[0] : '', // Format start_date
                        closing_date: pollData.closing_date ? pollData.closing_date.split('T')[0] : '', // Format closing_date
                    }));
                }
                hasFetched.current = true;
            } catch (error) {
                toast({
                    title: 'Error fetching poll data',
                    description: error.message || 'An unexpected error occurred.',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }

        };

        fetchAndSetPollData();
    }, [pollId, toast]);

    const handleSubmit = async (values, actions) => {

        if (values.question == null || values.question == '') {
            toast({
                title: 'Poll question is required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        if (values.options == null || values.options.length == 0 || values.options == []) {
            toast({
                title: 'Poll options are required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        if (values.options.length < 2) {
            toast({
                title: 'At least 2 poll options are required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        if (values.start_date == null || values.start_date == '') {
            toast({
                title: 'Start date is required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        if (values.closing_date == null || values.closing_date == '') {

            toast({
                title: 'End date is required',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        // Check if the closing date is greater than the start date
        if (values.closing_date < values.start_date) {
            toast({
                title: 'Closing date cannot be less than the start date',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            return;
        }
        

        try {
            console.log(values);
            await updatePoll(pollId, JSON.stringify(values));
            toast({
                title: 'Polls updated successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchPollData();
            setRefreshFlag(prevFlag => !prevFlag); // Optionally trigger refresh
            closeDrawer();
        } catch (error) {
            toast({
                title: 'Error updating polls',
                description: error.message || 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            actions.setSubmitting(false);
        }
    };

    const requestConfirm = (options) => {
        setConfirmDialog({
            isOpen: true,
            title: options.title,
            message: options.message,
            onConfirm: options.onConfirm,
        });
    };

    const handleCancel = () => {
        requestConfirm({
            title: "Cancel Confirmation",
            message: "Are you sure you want to cancel? Any unsaved changes will be lost.",
            onConfirm: closeDrawer
        });
    };

    const handleConfirm = () => {
        confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const addPollOption = () => {
        // Create a copy of the pollSchema object
        const updatedPollSchema = { ...initialValues };
        // Push a new default option to the options array
        updatedPollSchema.options.push('option' + updatedPollSchema.options.length);
        // Update the state with the new options array
        setInitialValues(updatedPollSchema);
    };

    const deleteOption = (index) => {
        // Create a copy of the pollSchema object
        const updatedPollSchema = { ...initialValues };
        // Remove the option at the specified index from the options array
        updatedPollSchema.options.splice(index, 1);
        // Update the state with the new options array
        setInitialValues(updatedPollSchema);
    };

    const handleDate = (e) => {
        const { value } = e.target;
        let minDate = getMinAgeDate()
        console.log(initialValues.closing_date)
        if (minDate < value) {
            // setDate(getMinAgeDate())
            setInitialValues(prevState => ({
                ...prevState, closing_date: getMinAgeDate()
            }));
        }
        else {
            // setDate(value)
            setInitialValues(prevState => ({
                ...prevState, closing_date: value
            }));
        }
    };

    // a minDate function to get the minimum date
    const getMinAgeDate = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();
        return `${yyyy}-${mm}-${dd}`;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInitialValues(prevState => ({
            ...prevState, [name]: value
        }));
    };

    console.log("Closing date", initialValues.closing_date);


    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {(formikProps) => (
                    <Form>
                        <VStack spacing={4} align="stretch">
                            <FormActionLabel formAction="edit" formName="Poll question" />
                            <SimpleGrid columns={1} gap={4} px={2} py={4}>
                                <FieldControl formikProps={formikProps} name="question" label="Question" placeholder="Enter questions" />
                                <TableContainer p={0}>
                                    <Table variant='simple' ps={0} ms={0}>
                                        <Thead ps={0} ms={0}>
                                            <Tr ps={0} ms={0}>
                                                <Th ps={0} ms={0}>Poll Options</Th>
                                                <Th>
                                                    <CustomButton
                                                        type="addNew"
                                                        showIcon={true}
                                                        showTooltip={true}
                                                        tooltipLabel="Add Option"
                                                        icon={<Icon icon="ci:plus" />}
                                                        onClick={addPollOption}
                                                    >
                                                        Add New
                                                    </CustomButton>
                                                </Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody ps={0} ms={0}>
                                            {initialValues.options?.map((option, index) =>
                                                <Tr key={index} ps={0} ms={0}>
                                                    <Td ps={0} ms={0}>
                                                        <FieldControl
                                                            formikProps={formikProps}
                                                            name={`options.${index}`}
                                                            value={option}
                                                            placeholder="Enter option value"
                                                        />
                                                    </Td>
                                                    <Td>
                                                        <DeleteButton onClick={() => deleteOption(index)} />
                                                    </Td>
                                                </Tr>
                                            )}
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                                <FormControl display='flex' alignItems='center'>
                                    <FormLabel htmlFor='published' mb='0'>
                                        Publish?
                                    </FormLabel>
                                    <Switch 
                                        id='published' 
                                        name="publish" 
                                        isChecked={formikProps.values.published}
                                        onChange={(e) => {
                                                formikProps.setFieldValue('published', e.target.checked);
                                            }
                                        }
                                    />
                                </FormControl>
                                <FormControl id="start_date" isRequired>
                                    <FormLabel >Start Date</FormLabel>
                                    <InputGroup>
                                        <Input
                                            type="date"
                                            name="start_date"
                                            id="start_date"
                                            min={getMinAgeDate()}
                                            value={formikProps.values.start_date}
                                            onChange={(event) => {
                                                formikProps.handleChange(event);
                                                handleDate(event);
                                            }}
                                        />
                                    </InputGroup>
                                </FormControl>
                                <FormControl id="closing_date" isRequired>
                                    <FormLabel>Closing Date</FormLabel>
                                    <InputGroup>
                                        <Input
                                            type="date"
                                            name="closing_date"
                                            id="closing_date"
                                            min={formikProps.values.start_date}
                                            value={formikProps.values.closing_date}
                                            onChange={(event) => {
                                                formikProps.handleChange(event);
                                                handleDate(event);
                                            }}
                                        />
                                    </InputGroup>
                                </FormControl>
                            </SimpleGrid>
                            <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}>
                                <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
                                <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                                    <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                                        Cancel
                                    </CustomButton>
                                    <CustomButton
                                        type="submit"
                                        disabled={formikProps.isSubmitting}
                                        style={{
                                            opacity: formikProps.isSubmitting ? 0.7 : 1,
                                            pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                                        }}
                                    >
                                        {formikProps.isSubmitting ? (
                                            <>
                                                <Spinner size="xs" mr={2} />
                                                Updating...
                                            </>
                                        ) : 'Update'}
                                    </CustomButton>
                                </ButtonStack>
                            </Box>
                        </VStack>
                    </Form>
                )}
            </Formik>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

const FieldControl = ({ formikProps, name, label, placeholder }) => (
    <GridItem colSpan={[3, 1]}>
        <FormControl isInvalid={formikProps.errors[name] && formikProps.touched[name]}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <ThemedStyledInput
                {...formikProps.getFieldProps(name)}
                id={name}
                placeholder={placeholder}
            />
            {formikProps.errors[name] && formikProps.touched[name] && (
                <Text color="red.500" fontSize="sm">{formikProps.errors[name]}</Text>
            )}
        </FormControl>
    </GridItem>
);

export default PollsEditForm;

