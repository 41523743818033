import React, { useEffect, useState, useCallback } from 'react';
import DisconnectionTable from './DisconnectionTable';
import DisconnectionTableTopBar from './DisconnectionTableTopBar';
import { fetchAllDisconnections } from '../DisconnectionAPI';

const DisconnectionContainer = () => {
    const [disconnections, setDisconnections] = useState([]);
    const [error, setError] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [filterOptions, setFilterOptions] = useState({});
    const [fetchTrigger, setFetchTrigger] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const refetchDisconnectionData = useCallback(async (options = {}) => {
        setDataLoaded(false);
        try {
            const data = await fetchAllDisconnections(pageNumber, 10, filterOptions);
            if (data && Array.isArray(data.disconnections)) {
                setDisconnections(data.disconnections);
                setTotalPages(data.totalPages || 0);
            } else {
                setDisconnections([]);
                setTotalPages(0);
            }
            setError('');
            setDataLoaded(true);
        } catch (error) {
            console.error('Error fetching all disconnections:', error);
            setError('Failed to fetch disconnection data.');
            setDisconnections([]);
            setTotalPages(0);
        }
        
    }, [pageNumber, filterOptions]);


    useEffect(() => {
        refetchDisconnectionData();
    }, [pageNumber, refreshFlag, refetchDisconnectionData, totalPages, fetchTrigger, filterOptions]);


    return (
        <>
            <DisconnectionTableTopBar
                refetchDisconnectionData={refetchDisconnectionData}
                setPageNumber={setPageNumber}
                setFilterOptions={setFilterOptions}
                setFetchTrigger={setFetchTrigger}
            />
            <DisconnectionTable
                disconnections={disconnections}
                setDisconnections={setDisconnections}
                setRefreshFlag={setRefreshFlag}
                refetchDisconnectionData={refetchDisconnectionData}
                error={error}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                filterOptions={filterOptions}
                dataLoaded={dataLoaded}
            />
        </>
    );
};

export default DisconnectionContainer;
