import React, { useState, useEffect } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Box,
  useToast,
  VStack
} from '@chakra-ui/react';
import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';
import { ButtonStack, CustomButton } from "../../../../../components/Styled/StyledButtons";
import photoService from '../../../../../services/photoService';  
import fileService from '../../../../../services/fileService';  
import { useDrawer } from '../../../../../Context/DrawerContext/DrawerContext'; 

const PhotoEditForm = ({ photoId, albums, onClose }) => {
    const toast = useToast();
    const { closeDrawer } = useDrawer();
    const [photo, setPhoto] = useState(null);
    const [file, setFile] = useState(null);

    useEffect(() => {
        const fetchPhoto = async () => {
            try {
                const result = await photoService.getPhotoById(photoId);
                setPhoto(result);
            } catch (error) {
                toast({
                    title: 'Error fetching photo',
                    description: error.message || 'Failed to fetch photo details.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                });
            }
        };

        fetchPhoto();
    }, [photoId, toast]);

    const initialValues = {
        albumId: photo?.album || '',
        description: photo?.caption || '',
        photo: photo?.url || ''
    };

    const validationSchema = Yup.object({
        albumId: Yup.string().required('Selecting an album is required'),
        description: Yup.string().nullable(),
        photo: Yup.mixed().nullable()
    });

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            let photoUrl = values.photo;
            if (file) {
                const uploadResult = await fileService.uploadFile(file,  values.albumId, 'photos');
                photoUrl = uploadResult.url; // Adjust this based on the actual response structure
            }

            const updatedPhotoData = {
                albumId: values.albumId,
                caption: values.description,
                url: photoUrl
            };

            await photoService.updatePhoto(photoId, updatedPhotoData);
            toast({
                title: 'Photo Updated',
                description: 'The photo details have been successfully updated.',
                status: 'success',
                duration: 5000,
                isClosable: true
            });
            if (onClose) onClose();
            closeDrawer();
        } catch (error) {
            toast({
                title: 'Error updating photo',
                description: error.message || 'Failed to update photo.',
                status: 'error',
                duration: 5000,
                isClosable: true
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
            {({ isSubmitting, setFieldValue, values }) => (
                <Form>
                    <VStack spacing={4}>
                        {/* AlbumId: Select */}
                        <Field name="albumId">
                            {({ field }) => (
                                <FormControl isRequired isDisabled>
                                    <FormLabel htmlFor="albumId">Album</FormLabel>
                                    <Select {...field} id="albumId">
                                        {albums?.map((album) => (
                                            <option key={album._id} value={album._id}>
                                                {album.title}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </Field>

                        {/* Description: Input */}
                        <Field name="description">
                            {({ field }) => (
                                <FormControl>
                                    <FormLabel htmlFor="description">Caption</FormLabel>
                                    <Input {...field} id="description" />
                                </FormControl>
                            )}
                        </Field>
                        <FormControl>
                            <FormLabel htmlFor='photo'>Photo</FormLabel>
                            <Input id='photo' name='photo' type='file' accept='image/*'
                                   onChange={(event) => {
                                       setFile(event.currentTarget.files[0]);
                                       setFieldValue('photo', event.currentTarget.files[0].name); // To display file name
                                   }} />
                            {photo && (
                                <Box mt={2}>
                                    <FormLabel>Current Photo:</FormLabel>
                                    <img src={photo.url} alt="Current Photo" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                                </Box>
                            )}
                        </FormControl>
                        <Box>
                            <CustomButton type='submit' showIcon={false}  isLoading={isSubmitting} colorScheme='blue'>
                                Update Photo
                            </CustomButton>
                        </Box>
                    </VStack>
                </Form>
            )}
        </Formik>
    );
};

export default PhotoEditForm;
