import React from 'react';
import DefaultTemplate from '../../components/DefaultTemplate/DefaultTemplate';
import FAQContainer from './Components/FAQContainer';  
import { Icon } from "@iconify/react";

const FAQPage = () => {
  return (
    <>
        <DefaultTemplate 
            Content={ <FAQContainer /> } 
            Title={"Frequently Asked Questions"} 
            icon={<Icon icon="heroicons:question-mark-circle-20-solid" />}
        />
    </>
  )
}

export default FAQPage;
