import React, {useState} from 'react';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    Box,
    Text, InputGroup, InputRightElement, IconButton, SimpleGrid, Select, Spinner,
    Switch
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useDrawer } from '../../../../../Context/DrawerContext/DrawerContext';
import { createLabServiceProduct } from "../../LabServiceProductsAPI";
import { Icon } from "@iconify/react";
import {ThemedStyledInput} from "../../../../../components/Styled";
import CustomAlert from "../../../../../components/Styled/StyledAlert";
import {ButtonStack, CustomButton} from "../../../../../components/Styled/StyledButtons";
import FormActionLabel from "../../../../../components/Styled/FormActionLabel";
import ConfirmDialog from "../../../../../Context/ConfirmDialog/ConfirmDialog";
import useSingleToast from "../../../../../hooks/UseSingleToast/UseSingleToast";
import {LabServiceProductCategories} from "./LabServiceProductsCategories";

const LabServiceProductAddForm = ({ refetchLabServiceProductData, filterOptions }) => {
    const { closeDrawer } = useDrawer();
    const showToast = useSingleToast();
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    const labServiceProductSchema = Yup.object().shape({
        name: Yup.string().required('Product name is required.'),
        category: Yup.string().required('Category is required.'),
        unitPrice: Yup.number().required('Unit price is required.').typeError('Unit price must be a number.'),
        isActive: Yup.boolean().required('Active status is required.'),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            await createLabServiceProduct(values);
            showToast({
                title: 'Product added successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchLabServiceProductData(filterOptions);
            closeDrawer();
        } catch (error) {
            showToast({
                title: 'Error',
                description: error.message || 'An unexpected error occurred',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setSubmitting(false);
        }
    };

    const requestConfirm = (options) => {
        setConfirmDialog({
            isOpen: true,
            title: options.title,
            message: options.message,
            onConfirm: options.onConfirm,
        });
    };

    const handleConfirm = () => {
        confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    return (
        <>
            <FormActionLabel formAction="add" formName="Lab Service Product" />
            <Formik
                enableReinitialize
                initialValues={{
                    name: '',
                    category: '',
                    unitPrice: '',
                    isActive: true,
                }}
                validationSchema={labServiceProductSchema}
                onSubmit={handleSubmit}
            >
                {formikProps => (
                    <Form>
                        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                            <SimpleGrid columns={1} gap={4} px={2} py={4}>
                                
                                <FieldControl
                                    formikProps={formikProps}
                                    name="name"
                                    label="Product Name"
                                    placeholder="Enter Product Name"
                                    icon={<Icon icon="mdi:rename-outline" />}
                                />

                                <FieldControl
                                    formikProps={formikProps}
                                    name="category"
                                    label="Category"
                                    placeholder="Select Category"
                                    component="select"
                                    options={LabServiceProductCategories}
                                />

                                <FieldControl
                                    formikProps={formikProps}
                                    name="unitPrice"
                                    label="Unit Price"
                                    placeholder="Enter Unit Price"
                                    icon={<Icon icon="ion:pricetag-sharp" />}
                                />

                                <FormControl display='flex' alignItems='center'>
                                    <FormLabel htmlFor='isActive' mb='0'>
                                        Set Active?
                                    </FormLabel>
                                    <Switch 
                                        id='isActive' 
                                        name="isActive" 
                                        isChecked={formikProps.values.isActive}
                                        onChange={(e) => {
                                                formikProps.setFieldValue('isActive', e.target.checked);
                                                // setIsActiveSchema((prevSchema) => ({
                                                //     ...prevSchema,
                                                //     isActive: e.target.checked,
                                                // }));
                                            }
                                        }
                                    />
                                    
                                </FormControl>
                            </SimpleGrid>
                            <AlertAndButtons
                                formikProps={formikProps}
                                closeDrawer={closeDrawer}
                                requestConfirm={requestConfirm}
                            />
                        </VStack>
                    </Form>
                )}
            </Formik>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

const FieldControl = ({ formikProps, name, label, placeholder, component = "input", options = [], icon }) => (
    <GridItem>
        <FormControl isInvalid={formikProps.errors[name] && formikProps.touched[name]}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <InputGroup>
                {component === "select" ? (
                    <Field as={Select} id={name} name={name} placeholder={placeholder}>
                        {options.map(option => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </Field>
                ) : (
                    <ThemedStyledInput
                        {...formikProps.getFieldProps(name)}
                        id={name}
                        placeholder={placeholder}
                    />
                )}
                {icon && (
                    <InputRightElement>
                        <IconButton
                            aria-label="Icon button label"
                            bg="blue.300"
                            _hover={{ bg: '#69C5EC' }}
                            _active={{ bg: '#4699bc' }}
                            color="white"

                        >
                            {icon}
                        </IconButton>
                    </InputRightElement>

                )}
            </InputGroup>
            {formikProps.errors[name] && formikProps.touched[name] && (
                <Text color="red.500" fontSize="sm">{formikProps.errors[name]}</Text>
            )}
        </FormControl>
    </GridItem>
);

const AlertAndButtons = ({ formikProps, closeDrawer, requestConfirm }) => {
    const handleCancel = () => {
        requestConfirm({
            title: "Cancel Confirmation",
            message: "Are you sure you want to cancel? Any unsaved changes will be lost.",
            onConfirm: () => {
                formikProps.handleReset();
                closeDrawer();
            }
        });
    };

    const handleReset = () => {
        requestConfirm({
            title: "Reset Confirmation",
            message: "Are you sure you want to reset all your changes?",
            onConfirm: () => {
                formikProps.handleReset();
            }
        });
    };

    return (
        <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}>
            <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
            <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                    Cancel
                </CustomButton>
                <CustomButton onClick={handleReset} type="reset">
                    Reset
                </CustomButton>
                <CustomButton
                    type="submit"
                    disabled={formikProps.isSubmitting}
                    style={{
                        opacity: formikProps.isSubmitting ? 0.7 : 1,
                        pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                    }}
                >
                    {formikProps.isSubmitting ? (
                        <>
                            <Spinner size="xs" mr={2} />
                            Submitting...
                        </>
                    ) : 'Submit'}
                </CustomButton>
            </ButtonStack>
        </Box>
    );
};

export default LabServiceProductAddForm;
