import React, { useEffect, useState, useCallback } from 'react';
import SurveysTable from './SurveysTable';
import SurveysTableTopBar from './SurveysTableTopBar';
import { fetchAllPolls, fetchAllSurveys } from '../SurveyAPI';

const SurveyContainer = () => {
    const [surveys, setSurveys] = useState([]);
    const [pollData, setPollData] = useState([]);
    const [error, setError] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const refetchPollsData = useCallback(async (filter={}, fetchAll = false) => {
        setDataLoaded(false);
        try {
            const poll_data = await fetchAllPolls(pageNumber, 10, filter, fetchAll);
            const survey_data = await fetchAllSurveys(pageNumber, 10, filter, fetchAll);
            setPollData(poll_data.polls)
            setSurveys(survey_data.surveys);
            setTotalPages(survey_data.totalPages);
            setError('');
            setDataLoaded(true);
        } catch (error) {
            console.error('Error fetching all surveys:', error.message);
            setError('Failed to fetch survey data.');
            setSurveys([]);
            setTotalPages(0);
        }
    }, [pageNumber]);

    useEffect(() => {
        refetchPollsData();
    }, [pageNumber, refreshFlag, refetchPollsData]);

    return (
        <>
            <SurveysTableTopBar refetchPollsData={refetchPollsData} pollData={pollData} />
            <SurveysTable
                surveys={surveys}
                setSurveys={setSurveys}
                setRefreshFlag={setRefreshFlag}
                refetchPollsData={refetchPollsData}
                error={error}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                dataLoaded={dataLoaded}
            />
        </>
    );
};

export default SurveyContainer;
