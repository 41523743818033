import React from 'react';
import { Formik, Form, Field } from 'formik';
import { FormControl, Select, Stack } from '@chakra-ui/react';
import { ThemedStyledInput } from '../../../../../components/Styled';
import * as Yup from 'yup';
import { CustomButton } from "../../../../../components/Styled/StyledButtons";
import { Icon } from "@iconify/react";
import FieldErrorMessage from "../../../../../components/FieldErrorMessage/FieldErrorMessage";
import useSingleToast from "../../../../../hooks/UseSingleToast/UseSingleToast";
import {LabServiceProductCategories} from "./LabServiceProductsCategories";

const LabServiceProductSearchFilter = ({ onFilterChange }) => {
    const showToast = useSingleToast();

    const validationSchema = Yup.object({
        name: Yup.string()
            .max(50, 'Name must be 50 characters or less')
            .trim('Name cannot be just whitespace')
            .strict(true),
        category: Yup.string(),
        materialNumber: Yup.string()
            .trim('Material Number cannot be just whitespace')
            .strict(true)
    });

    return (
        <Formik
            initialValues={{ name: '', category: '', materialNumber: '' }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                if (!values.name && !values.category && !values.materialNumber) {
                    onFilterChange({ name: '', category: '', materialNumber: '' });
                } else {
                    onFilterChange(values);
                }
                setSubmitting(false);
            }}
            onReset={() => {
                onFilterChange({ name: '', category: '', materialNumber: '' });
            }}
        >
            {({ handleSubmit, resetForm }) => (
                <Form>
                    <Stack spacing={2} direction="row" align="center">
                        <FormControl>
                            <Field as={ThemedStyledInput} id="name" name="name" type="text" placeholder="Search by Name" />
                            <FieldErrorMessage name="name" />
                        </FormControl>
                        <FormControl>
                            <Field as={Select} id="category" name="category" placeholder=" -- Search by Category --">
                                {LabServiceProductCategories.map((category) => (
                                    <option key={category} value={category}>{category}</option>
                                ))}
                            </Field>
                            <FieldErrorMessage name="category" />
                        </FormControl>
                        <FormControl>
                            <Field as={ThemedStyledInput} id="materialNumber" name="materialNumber" type="text" placeholder="Search by Material Number" />
                            <FieldErrorMessage name="materialNumber" />
                        </FormControl>
                        <CustomButton
                            onClick={handleSubmit}
                            type="search"
                            tooltipLabel="Search"
                            icon={<Icon icon="bi:search" />}
                            showLabel={false}
                        >
                            Search
                        </CustomButton>
                        <CustomButton
                            type="reset"
                            onClick={() => {
                                resetForm();
                                onFilterChange({ name: '', category: '', materialNumber: '' });
                            }}
                            tooltipLabel="Clear Filters"
                            icon={<Icon icon="radix-icons:reset" />}
                            showLabel={false}
                        >
                            Clear
                        </CustomButton>
                    </Stack>
                </Form>
            )}
        </Formik>
    );
};

export default LabServiceProductSearchFilter;

