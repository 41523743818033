import React, {useCallback, useEffect, useState} from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box,Badge
} from '@chakra-ui/react';
import GroupEmailManagementEditForm from './GroupEmailManagementEditForm';
import GroupEmailManagementViewForm from './GroupEmailManagementViewForm';
import { useDrawer } from "../../../Context/DrawerContext/DrawerContext";
import { DeleteButton, EditButton, ViewButton } from "../../../components/Styled/ActionButtons";
import { StyledTableContainer } from "../../../components/Styled/StyledComponents";
import PaginationControls from "../../../Context/PaginationControls/PaginationControls";
import {fetchGroupEmailManagementById, fetchAllGroupEmailManagements, deleteGroupEmailManagement} from "../GroupEmailManagementAPI";
import ConfirmDialog from "../../../Context/ConfirmDialog/ConfirmDialog";
import useSingleToast from "../../../hooks/UseSingleToast/UseSingleToast";
import {useError} from "../../../Context/ErrorContext/ErrorContext";

const GroupEmailManagementTable = ({ groupEmails, setGroupEmail, error, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag, filterOptions, refetchGroupEmailManagementData }) => {
    const { handleError } = useError();
    const { openDrawer } = useDrawer();
    const showToast = useSingleToast();
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });


    const handleEdit = (groupEmailId) => {
        console.log(groupEmailId);
        openDrawer({
            title: 'Edit Email',
            component: GroupEmailManagementEditForm,
            props: {
                groupEmailId: groupEmailId,
                refetchGroupEmailManagementData: refetchGroupEmailManagementData,
                setRefreshFlag: setRefreshFlag,
            },
            key: `edit-${groupEmailId}`,
        }, 'md');
    };


    const handleView = async (groupEmailRecord) => {

        try {
            const updatedManagementCenterData = await fetchGroupEmailManagementById(groupEmailRecord._id);
            openDrawer({
                title: 'View Email Details',
                component: GroupEmailManagementViewForm,
                props: { formData: updatedManagementCenterData },
                key: `view-${groupEmailRecord._id}`,
            }, 'md');
        } catch (error) {
            handleError("Failed to fetch latest Email data.");
        }
    };


    const refetchGroupEmailData = useCallback(async (options = {}) => {
        try {
            const data = await fetchAllGroupEmailManagements(pageNumber, 10, filterOptions, handleError);
            setGroupEmail(data.groupEmails);
            setTotalPages(Math.ceil(data.totalDocs / 10));
        } catch (error) {
            handleError(error.message || "An error occurred.");
        }
    }, [filterOptions, handleError, pageNumber, setGroupEmail, setTotalPages]);



    useEffect(() => {
        refetchGroupEmailData(filterOptions);
    }, [pageNumber, refetchGroupEmailData, filterOptions, setRefreshFlag]);

    if (!groupEmails) {
        return (
            <Box textAlign="center" my="4" p="4" color="red.600" bg="blue.50">
                <p>Loading Emails...</p>
            </Box>
        );
    }


    if (error) {
        return (
            <Box textAlign="center" my="4" p="4" color="red.600" bg="yellow.100">
                <p>Error fetching Group Emails center data:</p>
                <code>{error.toString()}</code>
            </Box>
        );
    }



    if (dataLoaded && Array.isArray(groupEmails) && groupEmails.length === 0) {
        return <Box textAlign="center" my="4">No records found.</Box>;
    }

    const handleDelete = (groupEmailId) => {
        setConfirmDialog({
            isOpen: true,
            title: "Confirm Delete",
            message: "Are you sure you want to delete this Email? This action cannot be undone.",
            onConfirm: () => confirmDelete(groupEmailId),
        });
    };

    const confirmDelete = async (groupEmailId) => {
        try {
            await deleteGroupEmailManagement(groupEmailId);
            showToast({
                title: "Group Email Deleted",
                description: "Successfully deleted the Email address.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ isOpen: false });
            setRefreshFlag(prev => !prev);
        } catch (error) {
            showToast({
                title: "Deletion Failed",
                description: "Failed to delete the Email Address.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ isOpen: false });
        }
    };



    return (
        <>
            <StyledTableContainer>
                <PaginationControls
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    totalPages={totalPages}
                />
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th>User Full Names</Th>
                            <Th>Email</Th>
                            <Th>Group</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {groupEmails.map((groupEmailRecord) => (
                            <Tr key={groupEmailRecord._id}>
                                <Td>{groupEmailRecord.name}</Td>
                                <Td>{groupEmailRecord.email}</Td>
                                <Td><Badge colorScheme='green'>{groupEmailRecord.category}</Badge></Td>
                                <Td>
                                    <ViewButton onClick={() => handleView(groupEmailRecord)} />
                                    <EditButton onClick={() => handleEdit(groupEmailRecord._id)} />
                                    <DeleteButton onClick={() => handleDelete(groupEmailRecord._id)} />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </StyledTableContainer>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                title={confirmDialog.title}
                message={confirmDialog.message}
                onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
                onConfirm={confirmDialog.onConfirm}
            />
        </>
    );
};

export default GroupEmailManagementTable;
