import React, { useState } from 'react';
import { FormControl, FormLabel, Input, Select, Box, useToast, VStack } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ButtonStack, CustomButton } from "../../../../../components/Styled/StyledButtons";
import photoService from '../../../../../services/photoService'; // Adjust path as necessary
import fileService from '../../../../../services/fileService'; // Adjust path as necessary
import { useDrawer } from '../../../../../Context/DrawerContext/DrawerContext'; // Adjust path as necessary

const PhotoAddForm = ({ albums }) => {
    const toast = useToast();
    const { closeDrawer } = useDrawer();
    const [file, setFile] = useState(null);

    const initialValues = {
        albumId: '',
        description: '',
        photo: null
    };

    const validationSchema = Yup.object().shape({
        albumId: Yup.string().required('Selecting an album is required'),
        description: Yup.string(),
        photo: Yup.mixed().required('A photo is required')
    });

    const onSubmit = async (values, { setSubmitting }) => {
        if (file) {
            try {
                // Assuming userID and docType are provided as needed
                const uploadResult = await fileService.uploadFile(file, values.albumId, 'photos');
                console.log('UPLOAD RSP',uploadResult)
                const photoData = {
                    albumId: values.albumId,
                    caption: values.description,
                    url: uploadResult.data.fileUrl // Adjust this according to the actual response structure
                };
                await photoService.addPhotoToAlbum(photoData);
                toast({
                    title: 'Photo Added',
                    description: 'The photo has been successfully added to the album.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true
                });
                closeDrawer();
            } catch (error) {
                toast({
                    title: 'Error adding photo',
                    description: error.message || 'Failed to add photo.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                });
            } finally {
                setSubmitting(false);
            }
        }
    };

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ isSubmitting, setFieldValue }) => (
                <Form>
                    <VStack spacing={4}>
                        <FormControl isRequired>
                            <FormLabel htmlFor='albumId'>Album</FormLabel>
                            <Select id='albumId' name='albumId' placeholder='Select album'
                                    onChange={(e) => setFieldValue('albumId', e.target.value)}>
                                {albums.map(album => (
                                    <option key={album._id} value={album._id}>{album.title}</option>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor='description'>Caption</FormLabel>
                            <Input id='description' name='description' placeholder='Photo description'
                                   onChange={(e) => setFieldValue('description', e.target.value)} />
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel htmlFor='photo'>Photo</FormLabel>
                            <input id='photo' name='photo' type='file' accept='image/*'
                                   onChange={(event) => {
                                       setFile(event.currentTarget.files[0]);
                                       setFieldValue('photo', event.currentTarget.files[0]);
                                   }} />
                        </FormControl>
                        <Box>
                            <CustomButton type='submit' showIcon={false}  isLoading={isSubmitting} colorScheme='blue'>
                                Add Photo
                            </CustomButton>
                        </Box>
                    </VStack>
                </Form>
            )}
        </Formik>
    );
};

export default PhotoAddForm;
