import React, { useState, useEffect } from 'react';
import {
  Box,
  useToast,
  Spinner
} from '@chakra-ui/react';
import AlbumTableTopBar from './AlbumTableTobBar';
import AlbumTable from './AlbumTable';
import photoService from '../../../../../services/photoService';

const AlbumContainer = () => {
  const [albums, setAlbums] = useState([]);
  const [filteredAlbums, setFilteredAlbums] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const toast = useToast();
  const [loading, setLoading] = useState(false); 

  useEffect(() => {
    fetchAlbums();
  }, []);

  const fetchAlbums = async () => {
    setLoading(true);
    try {
      const response = await photoService.getAllAlbums();
      console.log('Retrieved Albums',response)
      setAlbums(response);
      setFilteredAlbums(response); // Initialize filtered albums
    } catch (error) {
      toast({
        title: 'Error fetching albums',
        description: error.message || "Failed to load albums.",
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (!query) {
      setFilteredAlbums(albums);
    } else {
      const filtered = albums.filter(album => album.title.toLowerCase().includes(query.toLowerCase()));
      setFilteredAlbums(filtered);
    }
  };

  if (loading) {
    return (
      <Box textAlign="center" my="4">
        <Spinner />
      </Box>
    );
  }

  return (
    <>
      <Box>
        <AlbumTableTopBar
          setSearchQuery={handleSearch}
          fetchData={fetchAlbums}
          albums={filteredAlbums}
          setFilteredAlbums={setFilteredAlbums}
          fetchAlbums={fetchAlbums}
        />
        <AlbumTable
          albums={filteredAlbums}
          setAlbums={setFilteredAlbums}
          fetchAlbums={fetchAlbums}
        />
      </Box>
    </>
  );
};

export default AlbumContainer;
