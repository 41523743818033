import React, {useCallback, useEffect, useState} from 'react';
import {Box} from '@chakra-ui/react';
import VideoTable from './VideoTable';
import VideoTableTopBar from './VideoTableTopBar';
import videoService from "../../../../services/videoService";


const VideoContainer = () => {
    const [fetchTrigger, setFetchTrigger] = useState(false);
    const [videos, setVideos] = useState([]);
    const [error, setError] = useState('');
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [filterOptions, setFilterOptions] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);


    const refetchVideoData = useCallback(async (options = {}) => {
        setDataLoaded(false);
        try {
            const data =  await videoService.getAllVideos(filterOptions);
            console.log(data,filterOptions,"Container")
            setVideos(data);
            setError('');
            setDataLoaded(true);
        } catch (error) {
            console.error('Error fetching videos:', error);
            setError('Failed to fetch video data.');
            setVideos([]);
        }
    }, [filterOptions]);
    // Invoke fetchVideos on component mount and when filters change
    useEffect(() => {
        refetchVideoData();
    }, [fetchTrigger, refetchVideoData,  filterOptions, refreshFlag]);


    return (<>

        <Box>
            <VideoTableTopBar
                setFetchTrigger={setFetchTrigger}
                refetchVideoData={refetchVideoData}
                setFilterOptions={setFilterOptions}
            />
            <VideoTable
                videos={videos} 
                setVideos={setVideos}
                setRefreshFlag={setRefreshFlag}
                refetchVideoData={refetchVideoData}
                error={error}
                filterOptions={filterOptions}
                dataLoaded={dataLoaded}
            />
        </Box>
    </>);
};

export default VideoContainer;
