import React, { useEffect, useState, useCallback } from 'react';
import ConsultantTable from './ConsultantTable';
import ConsultantTableTopBar from './ConsultantTableTopBar';
import { fetchAllConsultants } from '../ConsultantsAPI';

const ConsultantContainer = () => {
    const [consultants, setConsultants] = useState([]);
    const [error, setError] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [filterOptions, setFilterOptions] = useState({});
    const [fetchTrigger, setFetchTrigger] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const refetchConsultantData = useCallback(async (options = {}) => {
        setDataLoaded(false);
        try {
            const data = await fetchAllConsultants(pageNumber, 10,  filterOptions);
            setConsultants(data.consultants);
            setTotalPages(data.totalPages);
            setError('');
            setDataLoaded(true);
        } catch (error) {
            console.error('Error fetching all consultants:', error.message);
            setError('Failed to fetch consultants data.');
            setConsultants([]);
            setTotalPages(0);
        }
    }, [pageNumber, filterOptions]);

    useEffect(() => {
        refetchConsultantData();
    }, [pageNumber, refreshFlag, refetchConsultantData, totalPages, fetchTrigger, filterOptions]);

    return (
        <>
            <ConsultantTableTopBar
                refetchConsultantData={refetchConsultantData}
                setPageNumber={setPageNumber}
                setFilterOptions={setFilterOptions}
                setFetchTrigger={setFetchTrigger}
            />
            <ConsultantTable
                consultants={consultants}
                setConsultants={setConsultants}
                setRefreshFlag={setRefreshFlag}
                refetchConsultantData={refetchConsultantData}
                error={error}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                filterOptions={filterOptions}
                dataLoaded={dataLoaded}
            />
        </>
    );
};

export default ConsultantContainer;
