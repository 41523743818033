import React, { useEffect, useState, useCallback } from 'react';
import {Box} from "@chakra-ui/react";
import {fetchAllAlbums} from "../AlbumAPI";
import {useError} from "../../../../../Context/ErrorContext/ErrorContext";
import AlbumTable from "./AlbumTable";
import AlbumTableTopBar from "./AlbumTableTopBar";

const AlbumsContainer = () => {
    const [albums, setAlbums] = useState([]);
    const [filterOptions, setFilterOptions] = useState({});
    const [error, setError] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [fetchTrigger, setFetchTrigger] = useState(false);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const { handleError } = useError();

    const refetchAlbumData = useCallback(async (options = {}) => {
        setDataLoaded(false);
        try {
            const data = await fetchAllAlbums(pageNumber, 10, filterOptions, handleError);
            setAlbums(data.albums);
            setTotalPages(data.totalPages);
            setError('');
            setDataLoaded(true);
        } catch (error) {
            setError(error.message);
            setAlbums([]);
            setTotalPages(0);
        }
    }, [pageNumber, filterOptions, handleError]);

    useEffect(() => {
        refetchAlbumData();
    }, [fetchTrigger, refetchAlbumData, refreshFlag, totalPages, fetchTrigger, filterOptions]);

    if (!albums) {
        return (
            <Box textAlign="center" my="4" p="4" color="red.600" bg="blue.50">
                <p>Loading Albums...</p>
            </Box>
        );
    }

    return (
        <>
            <AlbumTableTopBar
                refetchAlbumData={refetchAlbumData}
                setPageNumber={setPageNumber}
                setFilterOptions={setFilterOptions}
                setFetchTrigger={setFetchTrigger}
            />
            <AlbumTable
                albums={albums}
                setAlbums={setAlbums}
                error={error}
                pageNumber={pageNumber}
                setRefreshFlag={setRefreshFlag}
                setPageNumber={setPageNumber}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                filterOptions={filterOptions}
                dataLoaded={dataLoaded}
                refetchAlbumData={refetchAlbumData}
            />
        </>
    );
};

export default AlbumsContainer;
