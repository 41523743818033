import React from 'react';
import { Formik, Form, Field } from 'formik';
import { FormControl, Stack, GridItem, FormLabel, InputGroup, Select, InputRightElement, IconButton, Text } from '@chakra-ui/react';
import { ThemedStyledInput } from '../../../components/Styled';
import * as Yup from 'yup';
import { CustomButton } from "../../../components/Styled/StyledButtons";
import { Icon } from "@iconify/react";
import FieldErrorMessage from "../../../components/FieldErrorMessage/FieldErrorMessage";
import useSingleToast from "../../../hooks/UseSingleToast/UseSingleToast";
import { GroupEmails } from './GroupEmails';

const validationSchema = Yup.object({
    email: Yup.string()
        .email('Search & Filters Error: Invalid email address, please input a valid email address')
        .max(50, 'Search & Filters Error: Email Must be 50 characters or less')
        .trim('Search & Filters Error: Email cannot be just whitespace')
        .strict(true),
    name: Yup.string()
        .max(30, 'Search & Filters Error: Name Must be 30 characters or less')
        .trim('Search & Filters Error: Name cannot be just whitespace')
        .strict(true),
    category: Yup.string()
        .max(30, 'Search & Filters Error: Category Must be 30 characters or less')
        .trim('Search & Filters Error: Category cannot be just whitespace')
        .strict(true)
});

const GroupEmailManagementSearchFilter = ({ onFilterChange }) => {
    const showToast = useSingleToast();
    return (
        <Formik
            initialValues={{ email: '', name: '', category: '' }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                onFilterChange(values);
                setSubmitting(false);
            }}
            onReset={() => {
                onFilterChange({ email: '', name: '', category: '' });
            }}
        >
            {({ values, resetForm, handleSubmit }) => (
                <Form>
                    <Stack spacing={2} direction="row" align="center">
                        <FormControl>
                            <Field
                                as={ThemedStyledInput}
                                id="name"
                                name="name"
                                type="text"
                                placeholder="Search by Fullname"
                            />
                        </FormControl>
                        <FormControl>
                        <FieldControl
                            width={'md'}
                            pt={0}
                            name="category"
                            // label="Category"
                            placeholder="Select Group"
                            component="select"
                            options={GroupEmails}
                        />
                        </FormControl>
                        <FormControl>
                            <Field
                                as={ThemedStyledInput}
                                id="email"
                                name="email"
                                type="email"
                                placeholder="Search by Email"
                            />
                        </FormControl>
                        <CustomButton
                            onClick={handleSubmit}
                            type="search"
                            tooltipLabel="Search"
                            icon={<Icon icon="bi:search" />}
                            showLabel={false}
                        >
                            Search
                        </CustomButton>
                        <CustomButton
                            type="reset"
                            onClick={() => {
                                resetForm();
                                onFilterChange({ email: '', name: '', category: '' });
                            }}
                            tooltipLabel="Clear Filters"
                            icon={<Icon icon="radix-icons:reset" />}
                            showLabel={false}
                        >
                            Clear
                        </CustomButton>
                    </Stack>
                    <FieldErrorMessage name="email" />
                    <FieldErrorMessage name="name" />
                </Form>
            )}
        </Formik>
    );
};

const FieldControl = ({ name, label, placeholder, component = "input", options = [], icon }) => (
    <GridItem>
        <FormControl>
            <InputGroup>
                {component === "select" ? (
                    <Field as={Select} id={name} name={name} placeholder={placeholder}>
                        {options.map(option => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </Field>
                ) : (
                    <Field
                        as={ThemedStyledInput}
                        id={name}
                        name={name}
                        placeholder={placeholder}
                    />
                )}
                {icon && (
                    <InputRightElement>
                        <IconButton
                            aria-label="Icon button label"
                            bg="blue.300"
                            _hover={{ bg: '#69C5EC' }}
                            _active={{ bg: '#4699bc' }}
                            color="white"
                            icon={icon}
                        />
                    </InputRightElement>
                )}
            </InputGroup>
        </FormControl>
    </GridItem>
);

export default GroupEmailManagementSearchFilter;
