import React from 'react'
import DefaultTemplate from '../../../components/DefaultTemplate/DefaultTemplate'
import LabServiceProductContainer from './Components/LabServiceProducts/LabServiceProductContainer'
import {Icon} from "@iconify/react";

const LabServiceProducts = () => {
    return (
        <>
            <DefaultTemplate Content={ <LabServiceProductContainer/> } Title={"Lab Service Products" } icon={<Icon icon="icomoon-free:lab" />}/>
        </>
    )
}

export default LabServiceProducts
