    import React, {useCallback, useEffect, useRef, useState} from 'react';
    import {useDrawer} from "../../../../../Context/DrawerContext/DrawerContext";
    import useSingleToast from "../../../../../hooks/UseSingleToast/UseSingleToast";
    import {deleteAlbum, fetchAlbumById, fetchAllAlbums} from "../AlbumAPI";
    import {Badge, Box, Table, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
    import {StyledTableContainer} from "../../../../../components/Styled/StyledComponents";
    import PaginationControls from "../../../../../Context/PaginationControls/PaginationControls";
    import {DeleteButton, EditButton, UploadImagesButton, ViewButton} from "../../../../../components/Styled/ActionButtons";
    import ConfirmDialog from "../../../../../Context/ConfirmDialog/ConfirmDialog";
    import AlbumEditForm from "./AlbumEditForm";
    import AlbumViewForm from "./AlbumViewForm";
    import AlbumUploadImages from "./AlbumUploadImages";
    import utils from "../../../../../utils/commonFunctions";

    const AlbumTable = ({ albums, setAlbums, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag, filterOptions }) => {
        const { openDrawer } = useDrawer();
        const [error, setError] = useState('');
        const showToast = useSingleToast();
        const [confirmDialog, setConfirmDialog] = useState({
            isOpen: false,
            title: '',
            message: '',
            onConfirm: () => {},
        });

        const showToastRef = useRef(showToast);


        // Update function encapsulated in useCallback to ensure it's only created once
        const updateAlbumsAndPages = useCallback((newAlbums, totalDocs) => {
            setAlbums(newAlbums);
            setTotalPages(Math.ceil(totalDocs / 10));
        }, [setAlbums, setTotalPages]);

        const refetchAlbumData = useCallback(async (options = {}) => {
            setError('');
            try {
                const albumsResponse = await fetchAllAlbums(pageNumber, 10, filterOptions);

                if (albumsResponse && albumsResponse.totalPages) {
                    updateAlbumsAndPages(albumsResponse.albums, albumsResponse.totalPages);
                } else {
                    console.error("No totalPages found:", albumsResponse);
                    setAlbums([]);
                    setTotalPages(0);
                }
                if (!albumsResponse || !albumsResponse.albums ) {
                    throw new Error('No data received from the server');
                }
                updateAlbumsAndPages(albumsResponse.albums, albumsResponse.totalDocs);

            } catch (error) {
                console.error("Error fetching data:", error);
                setError(error.message || 'Failed to fetch data. Please try again later.');
                showToastRef.current({
                    title: "Error fetching data",
                    description: error.message || "An unexpected error occurred.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        }, [filterOptions, pageNumber, setAlbums, setTotalPages, updateAlbumsAndPages]);

        useEffect(() => {
            refetchAlbumData();
        }, [refetchAlbumData, pageNumber, filterOptions]);




        if (error) {
            return <Box textAlign="center" my="4">{error}</Box>;
        }

        if (!albums.length && dataLoaded) {
            return <Box textAlign="center" my="4">No records found.</Box>;
        }

        const togglePreview = (id) => {
            setAlbums(albums.map(album => {
                if (album._id === id) {
                    return { ...album, showPreview: !album.showPreview };
                }
                return album;
            }));
        };




        const handleUploadImages = (albumId) => {
            openDrawer({
                title: 'Upload Images',
                component: AlbumUploadImages,
                props: {
                    albumId: albumId,
                    refetchAlbumData: refetchAlbumData,
                    setRefreshFlag: setRefreshFlag,
                },
                key: `upload-${albumId}`,
            }, 'xl');
        };


        const handleEdit = (albumId) => {
            openDrawer({
                title: 'Edit Album',
                component: AlbumEditForm,
                props: {
                    albumId: albumId,
                    refetchAlbumData: refetchAlbumData,
                    setRefreshFlag: setRefreshFlag,
                },
                key: `edit-${albumId}`,
            }, 'md');
        };


        const handleView = async (album) => {
            try {
                const albumData = await fetchAlbumById(album._id);
                if (albumData) {
                    openDrawer({
                        title: 'View Album',
                        component: AlbumViewForm,
                        props: { formData: albumData },
                        key: `view-${album._id}`,
                    }, 'xl');
                } else {
                    throw new Error('Album data not found.');
                }
            } catch (error) {
                setError(error.message);
                showToast({
                    title: 'Error',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };


        const handleDelete = (albumId) => {
            setConfirmDialog({
                isOpen: true,
                title: "Confirm Delete",
                message: "Are you sure you want to delete this album? This action cannot be undone.",
                onConfirm: () => confirmDelete(albumId),
            });
        };

        const confirmDelete = async (albumId) => {
            try {
                await deleteAlbum(albumId);
                showToast({
                    title: "Album Deleted",
                    description: "Successfully deleted the album.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                setConfirmDialog({ ...confirmDialog, isOpen: false });
                refetchAlbumData(filterOptions); // Refresh the list
            } catch (error) {
                showToast({
                    title: "Deletion Failed",
                    description: "Could not delete the album. Please try again.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                setConfirmDialog({ ...confirmDialog, isOpen: false });
            }
        };

        return (
            <>
                <StyledTableContainer>
                    <PaginationControls pageNumber={pageNumber} setPageNumber={setPageNumber} totalPages={totalPages} />
                    {albums.length > 0 && (
                        <Table variant="striped">
                            <Thead>
                                <Tr>
                                    <Th>Title</Th>
                                    {/*<Th>Created At</Th>
                                    <Th>Updated At</Th>*/}
                                    <Th isNumeric></Th>
                                    <Th isNumeric></Th>
                                    <Th isNumeric></Th>
                                    <Th isNumeric></Th>
                                    <Th isNumeric></Th>
                                    <Th>Status</Th>
                                    <Th>Actions</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {albums?.map(album => (
                                    <Tr key={album._id}>
                                        <Td isTruncated>{album.title}</Td>
                                        <Td isNumeric></Td>
                                        <Td isNumeric></Td>
                                        <Td isNumeric></Td>
                                        <Td isNumeric></Td>
                                        <Td isNumeric></Td>
                                        {/*<Td>{`${utils.DateFormat(album.createdAt)}`}</Td>
                                        <Td>{`${utils.DateFormat(album.updatedAt)}`}</Td>*/}
                                        <Td>
                                            <Badge colorScheme={
                                                album.status === 'published' ? 'green' :
                                                    album.status === 'draft' ? 'orange' :
                                                        'red' // Defaults to red for archived or any other status
                                            }>
                                                {album.status.charAt(0).toUpperCase() + album.status.slice(1)}
                                            </Badge>
                                        </Td>

                                        <Td>
                                            <ViewButton onClick={() => handleView(album)} />
                                            <EditButton onClick={() => handleEdit(album._id)} />
                                            <UploadImagesButton onClick={() => handleUploadImages(album._id)} />
                                            <DeleteButton onClick={() => handleDelete(album._id)} />
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    )}
                </StyledTableContainer>
                <ConfirmDialog
                    isOpen={confirmDialog.isOpen}
                    title={confirmDialog.title}
                    message={confirmDialog.message}
                    onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
                    onConfirm={confirmDialog.onConfirm}
                />
            </>
        );
    };

    export default AlbumTable;
