import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box,
    Badge, useDisclosure,
} from '@chakra-ui/react';
import { useDrawer } from "../../../Context/DrawerContext/DrawerContext";
import {EditButton, ViewButton, DeleteButton, PublishButton} from "../../../components/Styled/ActionButtons";
import { StyledTableContainer } from "../../../components/Styled/StyledComponents";
import PaginationControls from "../../../Context/PaginationControls/PaginationControls";
import {fetchAllNews, fetchNewsById, deleteNews, updateNews} from "../NewsAPI";
import ConfirmDialog from "../../../Context/ConfirmDialog/ConfirmDialog";
import useSingleToast from "../../../hooks/UseSingleToast/UseSingleToast";
import newsEditForm from "./NewsEditForm";
import ImageModal from "./ImageModal";

const NewsTable = ({ news, setNews, error, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag }) => {
    const { openDrawer } = useDrawer();
    const showToast = useSingleToast();
    const {onOpen,isOpen,onClose} = useDisclosure()
    const [selectedNews,setSelectedNews]=useState()

    const handleEdit = (news) => {
        openDrawer({
            title: 'Edit News',
            component: newsEditForm,
            props: {
                newsId: news._id,
                newsData: news,
                refetchNewsData: refetchNewsData,
                setRefreshFlag: setRefreshFlag,
            },
            key: `edit-${news._id}`,
        }, 'md');
    };

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => { },
        newsId: null
    });

    const requestConfirm = (options) => {
        setConfirmDialog({
            isOpen: true,
            title: options.title,
            message: options.message,
            onConfirm: options.onConfirm,
            newsId: options.newsId
        });
    };

    const handleView = (news) => {
        setSelectedNews(news)
        onOpen()
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const deleteOption = (newsId) => {
        requestConfirm({
            title: "Delete Confirmation",
            message: "Are you sure you want to delete this news? If you proceed this news will be lost forever!",
            onConfirm: handleConfirm,
            newsId: newsId
        });
    };

    const handlePublish = async (newsId,newsData) => {
        try {
            await updateNews(newsId, newsData);
            showToast({
                title: 'News updated successfully',
                description: deleteNews.message,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchNewsData();
        } catch (error) {
            showToast({
                title: 'Error updating news data',
                description: error.message || 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
        // confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };
    const handleConfirm = async () => {
        try {
            await deleteNews(confirmDialog.newsId);
            showToast({
                title: 'News  deleted successfully',
                description: deleteNews.message,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchNewsData();
        } catch (error) {
            showToast({
                title: 'Error deleting news data',
                description: error.message || 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
        // confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) {
          return text;
        }
        return text.slice(0, maxLength) + '...';
      };


    const refetchNewsData = useCallback(async () => {
        try {
            const data = await fetchAllNews(pageNumber, 10);
            setNews(data.docs);
            setTotalPages(data.totalPages);
        } catch (error) {
        }
    }, [pageNumber, setNews, setTotalPages]);

    useEffect(() => {
        refetchNewsData();
    }, [refetchNewsData]);

    if (error) {
        return <Box textAlign="center" my="4">Failed to fetch News data: {error}</Box>;
    }

    if (dataLoaded && Array.isArray(news) && news.length === 0) {
        return <Box textAlign="center" my="4">No records found.</Box>;
    }

    return (
        <>
            <StyledTableContainer>
                <PaginationControls
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    totalPages={totalPages}
                />
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th>#</Th>
                            <Th>Title</Th>
                            <Th>Description</Th>
                            <Th>Published</Th>
                            <Th isNumeric>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {news&&news?.map((news, index) => (
                            <Tr key={index}>
                                <Td>NW0{index + 1}</Td>
                                {/* <Td>{news.number}</Td> */}
                                <Td>{news.title}</Td>
                                <Td>
                                    <div dangerouslySetInnerHTML={{ __html: truncateText(news.description, 50) }} />
                                </Td>
                                <Td>
                                    <Badge ml='1' colorScheme={news?.published?'green':'red'}>
                                        {news?.published?'Published':'Unpublished'}
                                    </Badge>
                                </Td>
                                <Td isNumeric>
                                    <ViewButton onClick={() =>
                                        handleView(news)
                                    } />
                                    <EditButton onClick={() =>
                                        handleEdit(news)
                                    } />

                                        <PublishButton published={news.published} onClick={() =>
                                            handlePublish(news._id,{published:!news.published})
                                        } />



                                    <DeleteButton onClick={() =>
                                        deleteOption(news._id)
                                    } />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </StyledTableContainer>

            <ImageModal onClose={onClose} news={selectedNews} isOpen={isOpen}/>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

export default NewsTable;
