import React, {useEffect, useState} from 'react';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    Box,
    Text,
    SimpleGrid,
    Radio,
    HStack,
    RadioGroup,
    Select,
    Stack,
    Grid,
    Checkbox,
    Spinner,
    Badge,
    Textarea,
    CheckboxGroup,
} from '@chakra-ui/react';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import { useDrawer } from '../../../../Context/DrawerContext/DrawerContext';
import ConfirmDialog from "../../../../Context/ConfirmDialog/ConfirmDialog";
import FormActionLabel from "../../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../../components/Styled/StyledButtons";
import {ThemedStyledInput} from "../../../../components/Styled";
import DropBox from "../../../../components/DropBox/DropBox";
import SectionHeading from "../../../../components/SectionHeading/SectionHeading";
import utils from "../../../../utils/commonFunctions";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";
import {updateConnection} from "../NewConnectionAPI";
import {fetchAllAreas} from "../../../LocationNetwork/Consultants/ConsultantsAPI";
import {fetchAllCities} from "../../../LocationNetwork/Areas/AreasAPI";
import enums from "wuc-library/enums";
import FieldErrorMessage from "../../../../components/FieldErrorMessage/FieldErrorMessage";
import {validateContractNumber, validateCustomerNumber} from "../../../../utils/validateContract";
import connectionUpdateSchema from "./connectionUpdateSchema";



const NewConnectionEditForm = ({ connection, connectionId, refetchConnectionData, setRefreshFlag }) => {
    const { closeDrawer } = useDrawer();
    const showToast = useSingleToast();
    const [flag, setFlag] = useState(true);
    const [loading, setLoading] = useState({
        idKey: false,
        maritalProofKey: false,
        leeseOrTitleDeed: false,
    });
    const [lastSavedStatus, setLastSavedStatus] = useState(connection.status || 'Submitted');
    const [cities, setCities] = useState([]);
    const [areas, setAreas] = useState([]);
    const [initialValues, setInitialValues] = useState({
        userId: '',
        profileId: '',
        customerNumber: '',
        isExistingConnection: false,
        contractNumber: '',
        personalDetails: {
            firstName: '',
            middleName: '',
            lastName: '',
            identityNo: '',
            identityType: '',
            maritalStatus: '',
            maritalType: '',
            sex: '',
            dateOfBirth: '',
        },
        nextOfKin: {
            firstName: '',
            middleName: '',
            lastName: '',
            relationship: '',
            cell: '',
        },
        address: {
            plotNo: '',
            postal: '',
            street: '',
            ward: '',
            city: '',
            district: ''
        },
        contactDetails: {
            cell: '',
            homeTel: '',
            workTel: '',
            email: ''
        },
        existingConnections: '',
        files: {
            idKey: '',
            maritalProofKey: '',
            leeseOrTitleDeed: ''
        },
        spouseCustomerNumber: '',
        consent: true,
        dateOfConsent:'',
        dateOfApplication:'',
        isPlotFenced: '',
        connectionType: '',
        applicantType: '',
        serviceConnectionDetails: {
            plotNo: '',
            ward: '',
            city: ''
        },
        ownerOrLandlord: {
            firstName: '',
            middleName: '',
            lastName: '',
            cell: '',
            cell2: '',
            email: ''
        },
        status: '',
        comments: ''

    });
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    const getStatusColor = (status) => {
        switch (status) {
            case 'Approved':
                return 'green';
            case 'Declined':
                return 'red';
            case 'Submitted':
                return 'cyan';
            case 'Review':
                return 'yellow';
            case 'Complete':
                return 'blue';
            default:
                return 'gray';
        }
    };

    const statusTransitions = {
        Submitted: ["Submitted", "Review", "Declined", "Approved", "Complete"],
        Review: ["Review", "Approved", "Declined", "Complete"],
        Declined: ["Declined"],
        Approved: ["Approved", "Complete"],
        Complete: ["Complete"]
    };


    const getAllowedTransitions = (currentStatus) => {
        return statusTransitions[currentStatus] || [];
    };


    useEffect(() => {
        const loadData = async () => {
            const cities = await fetchAllCities();
            const areas = await fetchAllAreas();
            setCities(cities.cities);
            setAreas(areas.areas);
        };
        loadData();
    }, []);




    useEffect(() => {
        if (!flag || !connection) return;

        setInitialValues({
            userId: connectionId,
            profileId: connection.profileId || '',
            customerNumber: connection.customerNumber || '',
            contractNumber: connection.contractNumber || '',

            isExistingConnection: connection.isExistingConnection || false,
            connectionType: connection.connectionType || '',
            applicantType: connection.applicantType || '',
            serviceConnectionDetails: {
                plotNo: connection.serviceConnectionDetails?.plotNo || '',
                ward: connection.serviceConnectionDetails?.ward || '',
                city: connection.serviceConnectionDetails?.city || ''
            },
            ownerOrLandlord: {
                firstName: connection.ownerOrLandlord?.firstName || '',
                middleName: connection.ownerOrLandlord?.middleName || '',
                lastName: connection.ownerOrLandlord?.lastName || '',
                cell: connection.ownerOrLandlord?.cell || '',
                cell2: connection.ownerOrLandlord?.cell2 || '',
                email: connection.ownerOrLandlord?.email || ''
            },
            comments: connection.comments || '',
            personalDetails: {
                firstName: connection.personalDetails?.firstName || '',
                middleName: connection.personalDetails?.middleName || '',
                lastName: connection.personalDetails?.lastName || '',
                identityNo: connection.personalDetails?.identityNo || '',
                identityType: connection.personalDetails?.identityType || '',
                maritalStatus: connection.personalDetails?.maritalStatus || '',
                maritalType: connection.personalDetails?.maritalType || '',
                sex: connection.personalDetails?.sex || '',
                dateOfBirth: connection.personalDetails?.dateOfBirth ? utils.formatDate(connection.personalDetails.dateOfBirth) : ''
            },
            nextOfKin: {
                firstName: connection.nextOfKin?.firstName || '',
                middleName: connection.nextOfKin?.middleName || '',
                lastName: connection.nextOfKin?.lastName || '',
                relationship: connection.nextOfKin?.relationship || '',
                cell: connection.nextOfKin?.cell || '',
            },
            address: {
                plotNo: connection.address?.plotNo || '',
                postal: connection.address?.postal || '',
                street: connection.address?.street || '',
                ward: connection.address?.ward || '',
                city: connection.address?.city || '',
                district: connection.address?.district || ''
            },
            contactDetails: {
                cell: connection.contactDetails?.cell || '',
                homeTel: connection.contactDetails?.homeTel || '',
                workTel: connection.contactDetails?.workTel || '',
                email: connection.contactDetails?.email || ''
            },
            existingConnections: connection.existingConnections || [],
            files: {
                idKey: connection.files?.idKey || '',
                maritalProofKey: connection.files?.maritalProofKey || '',
                leeseOrTitleDeed: connection.files?.leeseOrTitleDeed || ''
            },
            spouseCustomerNumber: connection.spouseCustomerNumber || '',
            consent: connection.consent || false,
            dateOfConsent: connection.dateOfConsent ? new Date(connection.dateOfConsent).toISOString().split('T')[0] : '',
            dateOfApplication: connection.dateOfApplication || '',
            isPlotFenced: connection.isPlotFenced || false,
            status: connection.status || ''
        });

        setFlag(false);
    }, [flag, connection, showToast, connectionId]);



    const handleFileChange = async (fieldName, event, setFieldValue, userId) => {
        const file = event.target.files[0];
        if (file) {
            setLoading(prev => ({ ...prev, [fieldName]: true }));
            try {
                const uploadResponse = await utils.handleFileUpload(file, `connection/${userId}`);
                if (uploadResponse && uploadResponse.data && uploadResponse.data.fileUrl) {
                    setFieldValue(`files.${fieldName}`, uploadResponse.data.fileUrl);
                } else {
                    setFieldValue(`files.${fieldName}.error`, 'Upload failed. Incomplete response received.');
                }
            } catch (error) {
                console.error("File upload error:", error);
                setFieldValue(`files.${fieldName}.error`, 'Upload failed: ' + error.message);
            } finally {
                setLoading(prev => ({ ...prev, [fieldName]: false }));
            }
        }
    };

    const getAllowedStatusOptions = () => {
        let allowed = statusTransitions[lastSavedStatus] || [];
        return Object.keys(statusTransitions).map((status) => ({
            value: status,
            label: status,
            disabled: !allowed.includes(status) && status !== lastSavedStatus
        }));
    };





    const handleSubmit = async (values, { setSubmitting }) => {
        console.log("Current form values on submit:", values);

        let errorOccurred = false;

        // Check for the ID document, Lease or Title Deed
        if (!values.files.idKey || !values.files.leeseOrTitleDeed) {
            showToast({
                title: 'ID Document OR Lease or Title Deed Document Missing',
                description: 'Please upload the required documents.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            errorOccurred = true;
        }

        // Check for the Marriage Proof document only if the marital status is 'Married'
        if (values.personalDetails.maritalStatus === 'Married' && !values.files.maritalProofKey) {
            showToast({
                title: 'Marriage Proof Document Missing',
                description: 'Please upload the required marriage proof document.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            errorOccurred = true;
        }

        // Check for customer number and contract number if status is 'Complete'
        if (values.status === 'Complete') {
            if (!values.customerNumber) {
                showToast({
                    title: 'Missing Customer Number',
                    description: 'Customer number is required when status is Complete.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                errorOccurred = true;
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
            if (!values.contractNumber) {
                showToast({
                    title: 'Missing Contract Number',
                    description: 'Contract number is required when status is Complete.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                errorOccurred = true;
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }

        // Stop submission if any errors occurred
        if (errorOccurred) {
            setSubmitting(false);
            return;
        }

        // Confirm status changes for Approved, Declined
        if (['Approved', 'Declined'].includes(values.status)) {
            requestConfirm({
                title: "Confirm Status Change",
                message: `Setting the status to ${values.status} cannot be reverted. Are you sure you want to proceed?`,
                onConfirm: () => finalizeSubmission(values, setSubmitting)
            });
        } else {
            await finalizeSubmission(values, setSubmitting);
        }
    };


    const finalizeSubmission = async (values, setSubmitting) => {
        if (values.personalDetails.maritalStatus !== 'Married') {
            values.personalDetails.maritalType = null;
        }
        const payload = {
            userId: connectionId,
            profileId: values.profileId || '',
            customerNumber: values.customerNumber,
            contractNumber: values.contractNumber || '',
            personalDetails: {
                firstName: values.personalDetails.firstName,
                middleName: values.personalDetails.middleName || '',
                lastName: values.personalDetails.lastName,
                identityNo: values.personalDetails.identityNo,
                identityType: values.personalDetails.identityType,
                maritalStatus: values.personalDetails.maritalStatus,
                maritalType: values.personalDetails.maritalType || null,
                sex: values.personalDetails.sex,
                dateOfBirth: values.personalDetails.dateOfBirth ? utils.formatDate(values.personalDetails.dateOfBirth) : ''
                //dateOfBirth: utils.formatDate(values.personalDetails.dateOfBirth)
            },
            nextOfKin: {
                firstName: values.nextOfKin.firstName,
                middleName: values.nextOfKin.middleName || '',
                lastName: values.nextOfKin.lastName,
                relationship: values.nextOfKin.relationship,
                cell: values.nextOfKin.cell,
            },
            address: {
                plotNo: values.address.plotNo,
                postal: values.address.postal,
                street: values.address.street,
                ward: values.address.ward,
                city: values.address.city,
                district: values.address.district || '',
            },
            contactDetails: {
                cell: values.contactDetails.cell,
                homeTel: values.contactDetails.homeTel || '',
                workTel: values.contactDetails.workTel || '',
                email: values.contactDetails.email,
            },
            isExistingConnection: values.isExistingConnection,
            existingConnections: values.existingConnections || [],
            files: {
                idKey: values.files.idKey,
                maritalProofKey: values.personalDetails.maritalStatus === 'Married' ? values.files.maritalProofKey : null,
                leeseOrTitleDeed: values.files.leeseOrTitleDeed,
            },
            spouseCustomerNumber: values.spouseCustomerNumber || '',
            consent: values.consent,
            dateOfConsent: values.consent ? new Date(values.dateOfConsent).toISOString() : new Date().toISOString(),
            //dateOfConsent: values.consent ? new Date(values.dateOfConsent).toISOString() : null,
            dateOfApplication: values.dateOfApplication || new Date().toISOString(),
            isPlotFenced: values.isPlotFenced,
            connectionType: values.connectionType,
            applicantType: values.applicantType,
            serviceConnectionDetails: {
                plotNo: values.serviceConnectionDetails.plotNo,
                ward: values.serviceConnectionDetails.ward,
                city: values.serviceConnectionDetails.city,
            },
            ownerOrLandlord: {
                firstName: values.ownerOrLandlord.firstName,
                middleName: values.ownerOrLandlord.middleName || '',
                lastName: values.ownerOrLandlord.lastName,
                cell: values.ownerOrLandlord.cell,
                cell2: values.ownerOrLandlord.cell2 || '',
                email: values.ownerOrLandlord.email,
            },
            status: values.status || enums.ConnectionRequestStatus.SUBMITTED,
            comments: values.comments || ''
        };


        console.log("Submitting the following payload to backend:", payload);
        try {
            const updateResponse = await updateConnection(connectionId, payload);
            if (updateResponse.status === 'Success') {
                setLastSavedStatus(values.status);
                showToast({
                    title: 'Success',
                    description: updateResponse.message || 'KYC profile updated successfully.',
                    status: 'success',
                    isClosable: true,
                });
                closeDrawer();
                refetchConnectionData();
            } else {
                throw new Error(updateResponse.message || 'Failed to update KYC profile.');
            }
        } catch (error) {
            showToast({
                title: 'Error',
                description: error.message || 'An error occurred while updating the KYC profile.',
                status: 'error',
                isClosable: true,
            });
        } finally {
            setSubmitting(false);
        }
    };

    const requestConfirm = (options) => {
        setConfirmDialog({
            isOpen: true,
            title: options.title,
            message: options.message,
            onConfirm: () => {
                options.onConfirm();
                setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
            },
        });
    };


    const handleCancel = () => {
        requestConfirm({
            title: "Cancel Confirmation",
            message: "Are you sure you want to cancel? Any unsaved changes will be lost.",
            onConfirm: closeDrawer
        });
    };

    const handleConfirm = () => {
        confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };



    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={connectionUpdateSchema}
                onSubmit={handleSubmit}
                enableReinitialize
                validateOnMount={true}
            >
                {(formikProps) => (
                    <Form>
                        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                            <FormActionLabel formAction="edit" formName="Connection Application"/>

                            <SectionHeading icon="fluent:document-copy-24-filled" text="Connection Request Details" />
                            <SimpleGrid columns={2} gap={4} px={4}>
                                {/* Customer Number */}
                                <GridItem>
                                    <FormControl>
                                        <FormLabel htmlFor="customerNumber">Customer Number</FormLabel>
                                        {/*<ThemedStyledInput
                                            {...formikProps.getFieldProps('customerNumber')}
                                            id="customerNumber"
                                            placeholder="Enter Customer Number"
                                        />*/}
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('customerNumber')}
                                            onBlur={async e => {
                                                formikProps.handleBlur(e);
                                                const error = await validateCustomerNumber(e.target.value);
                                                formikProps.setFieldError('customerNumber', error);
                                            }}
                                            id="customerNumber"
                                            placeholder="Enter Customer Number"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.customerNumber && formikProps.touched.customerNumber && formikProps.errors.customerNumber}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Contract Number */}
                                {formikProps.values.status === 'Complete' && (
                                    <GridItem>
                                        <FormControl>
                                            <FormLabel htmlFor="contractNumber">Contract Number</FormLabel>
                                            {/*<ThemedStyledInput
                                                {...formikProps.getFieldProps('contractNumber')}
                                                id="contractNumber"
                                                placeholder="Enter Contract Number"
                                            />*/}
                                            <ThemedStyledInput
                                                {...formikProps.getFieldProps('contractNumber')}
                                                onBlur={async e => {
                                                    formikProps.handleBlur(e);
                                                    const customerNumber = formikProps.values.customerNumber;
                                                    const contractNumber = e.target.value;

                                                    if (customerNumber && contractNumber) {
                                                        const error = await validateContractNumber(customerNumber, contractNumber);
                                                        formikProps.setFieldError('contractNumber', error);
                                                    }
                                                }}
                                                id="contractNumber"
                                                placeholder="Enter Contract Number"
                                            />

                                            <Text color="red.500" fontSize="sm">
                                                {formikProps.errors.contractNumber && formikProps.touched.contractNumber && formikProps.errors.contractNumber}
                                            </Text>
                                        </FormControl>
                                    </GridItem>
                                )}

                                {/* Is Existing Connection */}
                                <GridItem>
                                    <FormControl>
                                        <FormLabel htmlFor="isExistingConnection">Is Existing Connection</FormLabel>
                                        <RadioGroup
                                            name="isExistingConnection"
                                            value={String(formikProps.values.isExistingConnection)}
                                            onChange={(value) => formikProps.setFieldValue('isExistingConnection', value === 'true')}
                                        >
                                            <HStack>
                                                <Radio value="true">Yes</Radio>
                                                <Radio value="false">No</Radio>
                                            </HStack>
                                        </RadioGroup>
                                    </FormControl>
                                </GridItem>

                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.isPlotFenced && formikProps.touched.isPlotFenced}>
                                        <FormLabel htmlFor="isPlotFenced">Is Plot Fenced</FormLabel>
                                        <RadioGroup
                                            name="isPlotFenced"
                                            value={formikProps.values.isPlotFenced?.toString()}
                                            onChange={value => formikProps.setFieldValue('isPlotFenced', value === 'true')}
                                        >
                                            <Stack spacing={5} direction="row">
                                                <Radio id="fenced" value='true'>Yes</Radio>
                                                <Radio id="notFenced" value='false'>No</Radio>
                                            </Stack>
                                        </RadioGroup>
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.isPlotFenced && formikProps.touched.isPlotFenced && formikProps.errors.isPlotFenced}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                <GridItem>
                                    <FormControl>
                                        <FormLabel htmlFor="serviceConnectionDetails.plotNo">PC/Plot Number</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('serviceConnectionDetails.plotNo')}
                                            id="serviceConnectionDetails.plotNo"
                                            placeholder="Enter Plot Number"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.serviceConnectionDetails?.plotNo && formikProps.touched.serviceConnectionDetails?.plotNo && formikProps.errors.serviceConnectionDetails.plotNo}
                                        </Text>
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.connectionType && formikProps.touched.connectionType}>
                                        <FormLabel htmlFor="connectionType">Connection Type</FormLabel>
                                        <RadioGroup
                                            name="isExistingConnection"
                                            value={formikProps.values?.isExistingConnection?.toString()}
                                            onChange={value => formikProps.setFieldValue('isExistingConnection', value === 'true')}
                                        >
                                            <Stack spacing={5} direction="row">
                                                <Radio id="existingYes" value="true">Existing Connection</Radio>
                                                <Radio id="existingNo" value="false">New Connection</Radio>
                                            </Stack>
                                        </RadioGroup>
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.connectionType && formikProps.touched.connectionType && formikProps.errors.connectionType}
                                        </Text>
                                    </FormControl>
                                </GridItem>


                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.serviceType && formikProps.touched.serviceType}>
                                        <FormLabel htmlFor="serviceType">Service Type</FormLabel>
                                        <CheckboxGroup
                                            name="connectionType"
                                            value={formikProps.values.connectionType}
                                            onChange={setFieldValue => formikProps.setFieldValue('connectionType', setFieldValue)}
                                        >
                                            <Stack spacing={5} direction="row">
                                                <Checkbox id="portable" value="Portable">Portable</Checkbox>
                                                <Checkbox id="wastewater" value="Wastewater">Wastewater</Checkbox>
                                            </Stack>
                                        </CheckboxGroup>
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.serviceType && formikProps.touched.serviceType && formikProps.errors.serviceType}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Applicant Type */}
                                <GridItem>
                                    <FormControl
                                        isInvalid={formikProps.touched.applicantType && formikProps.errors.applicantType}>

                                        <FormLabel htmlFor="applicantType">Applicant Type :</FormLabel>
                                        <RadioGroup
                                            name="applicantType"
                                            value={formikProps.values.applicantType}
                                            onChange={value => formikProps.setFieldValue('applicantType', value)}
                                        >
                                            <Stack spacing={5} direction="row">
                                                <Radio id="Tenant" value="Tenant">Tenant</Radio>
                                                <Radio id="Owned" value="Owner">Owned</Radio>
                                            </Stack>
                                        </RadioGroup>
                                        {formikProps.touched.applicantType && formikProps.errors.applicantType && (
                                            <Text color="red.500"
                                                  fontSize="sm">{formikProps.errors.applicantType}</Text>
                                        )}
                                    </FormControl>
                                </GridItem>

                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.serviceConnectionDetails?.city && formikProps.touched.serviceConnectionDetails?.city}>
                                        <FormLabel htmlFor="serviceConnectionDetails.city">City</FormLabel>
                                        <Select
                                            {...formikProps.getFieldProps('serviceConnectionDetails.city')}
                                            id="serviceConnectionDetails.city"
                                            placeholder="Select City"
                                        >
                                            {cities.map(city => (
                                                <option key={city._id} value={city.name}>{city.name}</option>
                                            ))}
                                        </Select>
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.serviceConnectionDetails?.city && formikProps.touched.serviceConnectionDetails?.city && formikProps.errors.serviceConnectionDetails.city}
                                        </Text>
                                    </FormControl>

                                </GridItem>
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.serviceConnectionDetails?.ward && formikProps.touched.serviceConnectionDetails?.ward}>
                                        <FormLabel htmlFor="serviceConnectionDetails.ward">Ward</FormLabel>
                                        <Select
                                            {...formikProps.getFieldProps('serviceConnectionDetails.ward')}
                                            id="serviceConnectionDetails.ward"
                                            placeholder="Select Ward"
                                        >
                                            {areas.map(area => (
                                                <option key={area._id} value={area.name}>{area.name}</option>
                                            ))}
                                        </Select>
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.serviceConnectionDetails?.ward && formikProps.touched.serviceConnectionDetails?.ward && formikProps.errors.serviceConnectionDetails.ward}
                                        </Text>
                                    </FormControl>

                                </GridItem>
                            </SimpleGrid>



                            <SectionHeading icon="material-symbols-light:add-home" text="Landlord Details"/>
                            <SimpleGrid columns={2} gap={4} px={4}>
                                {/* First Name */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.ownerOrLandlord?.firstName && formikProps.touched.ownerOrLandlord?.firstName}>
                                        <FormLabel htmlFor="ownerOrLandlord.firstName">First Name</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('ownerOrLandlord.firstName')}
                                            id="ownerOrLandlord.firstName"
                                            placeholder="Enter First Name"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.ownerOrLandlord?.firstName && formikProps.touched.ownerOrLandlord?.firstName && formikProps.errors.ownerOrLandlord.firstName}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Middle Name */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.ownerOrLandlord?.middleName && formikProps.touched.ownerOrLandlord?.middleName}>
                                        <FormLabel htmlFor="ownerOrLandlord.middleName">Middle Name</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('ownerOrLandlord.middleName')}
                                            id="ownerOrLandlord.middleName"
                                            placeholder="Enter Middle Name"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.ownerOrLandlord?.middleName && formikProps.touched.ownerOrLandlord?.middleName && formikProps.errors.ownerOrLandlord.middleName}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Last Name */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.ownerOrLandlord?.lastName && formikProps.touched.ownerOrLandlord?.lastName}>
                                        <FormLabel htmlFor="ownerOrLandlord.lastName">Last Name</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('ownerOrLandlord.lastName')}
                                            id="ownerOrLandlord.lastName"
                                            placeholder="Enter Last Name"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.ownerOrLandlord?.lastName && formikProps.touched.ownerOrLandlord?.lastName && formikProps.errors.ownerOrLandlord.lastName}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Email */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.ownerOrLandlord?.email && formikProps.touched.ownerOrLandlord?.email}>
                                        <FormLabel htmlFor="ownerOrLandlord.email">Email</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('ownerOrLandlord.email')}
                                            id="ownerOrLandlord.email"
                                            placeholder="Enter Email Address"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.ownerOrLandlord?.email && formikProps.touched.ownerOrLandlord?.email && formikProps.errors.ownerOrLandlord.email}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Cell */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.ownerOrLandlord?.cell && formikProps.touched.ownerOrLandlord?.cell}>
                                        <FormLabel htmlFor="ownerOrLandlord.cell">Cell</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('ownerOrLandlord.cell')}
                                            id="ownerOrLandlord.cell"
                                            placeholder="Enter Cell Number"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.ownerOrLandlord?.cell && formikProps.touched.ownerOrLandlord?.cell && formikProps.errors.ownerOrLandlord.cell}
                                        </Text>
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.ownerOrLandlord?.cell2 && formikProps.touched.ownerOrLandlord?.cell2}>
                                        <FormLabel htmlFor="ownerOrLandlord.cell">Another Contact</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('ownerOrLandlord.cell2')}
                                            id="ownerOrLandlord.cell2"
                                            placeholder="Enter Alternate Number"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.ownerOrLandlord?.cell2 && formikProps.touched.ownerOrLandlord?.cell2 && formikProps.errors.ownerOrLandlord.cell2}
                                        </Text>
                                    </FormControl>
                                </GridItem>
                            </SimpleGrid>


                            <SectionHeading icon="bxs:user-detail" text="Personal Details :" />
                            <SimpleGrid columns={2} gap={4} px={4} >
                                {/* First Name */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.personalDetails?.firstName && formikProps.touched.personalDetails?.firstName}>
                                        <FormLabel htmlFor="personalDetails.firstName">First Name</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('personalDetails.firstName')}
                                            id="personalDetails.firstName"
                                            placeholder="Enter First Name"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.personalDetails?.firstName && formikProps.touched.personalDetails?.firstName && formikProps.errors.personalDetails.firstName}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Middle Name */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.personalDetails?.middleName && formikProps.touched.personalDetails?.middleName}>
                                        <FormLabel htmlFor="personalDetails.middleName">Middle Name</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('personalDetails.middleName')}
                                            id="personalDetails.middleName"
                                            placeholder="Enter Middle Name"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.personalDetails?.middleName && formikProps.touched.personalDetails?.middleName && formikProps.errors.personalDetails.middleName}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Last Name */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.personalDetails?.lastName && formikProps.touched.personalDetails?.lastName}>
                                        <FormLabel htmlFor="personalDetails.lastName">Last Name</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('personalDetails.lastName')}
                                            id="personalDetails.lastName"
                                            placeholder="Enter Last Name"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.personalDetails?.lastName && formikProps.touched.personalDetails?.lastName && formikProps.errors.personalDetails.lastName}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.personalDetails?.sex && formikProps.touched.personalDetails?.sex}>
                                        <FormLabel htmlFor="personalDetails.sex">Gender</FormLabel>
                                        <RadioGroup
                                            name="personalDetails.sex"
                                            value={formikProps.values.personalDetails.sex} // Explicitly set the value from Formik's state
                                            onChange={val => formikProps.setFieldValue('personalDetails.sex', val)} // Use setFieldValue to update
                                        >
                                            <HStack>
                                                <Radio value="Male">Male</Radio>
                                                <Radio value="Female">Female</Radio>
                                                <Radio value="Other">Other</Radio>
                                            </HStack>
                                        </RadioGroup>
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.personalDetails?.sex && formikProps.touched.personalDetails?.sex && formikProps.errors.personalDetails.sex}
                                        </Text>
                                    </FormControl>

                                </GridItem>


                                {/* Identity Number */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.personalDetails?.identityNo && formikProps.touched.personalDetails?.identityNo}>
                                        <FormLabel htmlFor="personalDetails.identityNo">Identity No</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('personalDetails.identityNo')}
                                            id="personalDetails.identityNo"
                                            placeholder="Enter Identity No"
                                            bg={formikProps.values.personalDetails?.identityNo ? "gray.100" : "white"}
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.personalDetails?.identityNo && formikProps.touched.personalDetails?.identityNo && formikProps.errors.personalDetails.identityNo}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Identity Type Radio Buttons */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.personalDetails?.identityType && formikProps.touched.personalDetails?.identityType}>
                                        <FormLabel htmlFor="personalDetails.identityType">Identity Type</FormLabel>
                                        <RadioGroup
                                            name="personalDetails.identityType"
                                            value={formikProps.values.personalDetails.identityType}
                                            onChange={val => formikProps.setFieldValue('personalDetails.identityType', val)}
                                        >
                                            <Stack direction="row">
                                                <Radio value="Omang">Identity card (omang)</Radio>
                                                <Radio value="Passport">Passport</Radio>
                                            </Stack>
                                        </RadioGroup>
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.personalDetails?.identityType && formikProps.touched.personalDetails?.identityType && formikProps.errors.personalDetails.identityType}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Marital Status Radio Buttons */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.personalDetails?.maritalStatus && formikProps.touched.personalDetails?.maritalStatus}>
                                        <FormLabel htmlFor="personalDetails.maritalStatus">Marital Status</FormLabel>
                                        <RadioGroup
                                            name="personalDetails.maritalStatus"
                                            value={formikProps.values.personalDetails.maritalStatus}
                                            onChange={val => {
                                                formikProps.setFieldValue('personalDetails.maritalStatus', val);
                                                if (val === 'Married') {
                                                    formikProps.setFieldValue('personalDetails.maritalType', formikProps.values.personalDetails.maritalType || 'In Community Of Property');
                                                } else {
                                                    formikProps.setFieldValue('personalDetails.maritalType', null);
                                                }
                                            }}
                                        >
                                            <HStack>
                                                <Radio value="Single">Single</Radio>
                                                <Radio value="Married">Married</Radio>
                                                <Radio value="Divorced">Divorced</Radio>
                                                <Radio value="Widowed">Widowed</Radio>
                                            </HStack>
                                        </RadioGroup>
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.personalDetails?.maritalStatus && formikProps.touched.personalDetails?.maritalStatus && formikProps.errors.personalDetails.maritalStatus}
                                        </Text>
                                    </FormControl>

                                </GridItem>

                                {/* Conditional Marital Type Radio Buttons */}
                                {formikProps.values.personalDetails.maritalStatus === 'Married' && (


                                    <GridItem>
                                        <FormControl isInvalid={formikProps.errors.personalDetails?.maritalType && formikProps.touched.personalDetails?.maritalType}>
                                            <FormLabel htmlFor="personalDetails.maritalType">Marriage Type</FormLabel>
                                            <RadioGroup
                                                name="personalDetails.maritalType"
                                                value={formikProps.values.personalDetails.maritalType}
                                                onChange={val => formikProps.setFieldValue('personalDetails.maritalType', val)}
                                            >
                                                <HStack>
                                                    {/* Adjust the value to match the enum in the backend */}
                                                    <Radio value="In Community Of Property">In community of property</Radio>
                                                    <Radio value="Out Of Community Of Property">Out of community of property</Radio>
                                                </HStack>
                                            </RadioGroup>
                                            <Text color="red.500" fontSize="sm">
                                                {formikProps.errors.personalDetails?.maritalType && formikProps.touched.personalDetails?.maritalType && formikProps.errors.personalDetails.maritalType}
                                            </Text>
                                        </FormControl>
                                    </GridItem>

                                )}

                                {/* Date of Birth */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.personalDetails?.dateOfBirth && formikProps.touched.personalDetails?.dateOfBirth}>
                                        <FormLabel htmlFor="personalDetails.dateOfBirth">Date of Birth</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('personalDetails.dateOfBirth')}
                                            id="personalDetails.dateOfBirth"
                                            type="date"  // Ensure type is set to 'date'
                                            placeholder="YYYY-MM-DD"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.personalDetails?.dateOfBirth && formikProps.touched.personalDetails?.dateOfBirth && formikProps.errors.personalDetails.dateOfBirth}
                                        </Text>
                                    </FormControl>
                                </GridItem>


                            </SimpleGrid>


                            <SectionHeading icon="material-symbols:supervisor-account" text="Next of Kin Details :" />
                            <SimpleGrid columns={2} gap={4} px={4}>
                                {/* First Name */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.nextOfKin?.firstName && formikProps.touched.nextOfKin?.firstName}>
                                        <FormLabel htmlFor="nextOfKin.firstName">First Name</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('nextOfKin.firstName')}
                                            id="nextOfKin.firstName"
                                            placeholder="Enter First Name"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.nextOfKin?.firstName && formikProps.touched.nextOfKin?.firstName && formikProps.errors.nextOfKin.firstName}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Middle Name */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.nextOfKin?.middleName && formikProps.touched.nextOfKin?.middleName}>
                                        <FormLabel htmlFor="nextOfKin.middleName">Middle Name</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('nextOfKin.middleName')}
                                            id="nextOfKin.middleName"
                                            placeholder="Enter Middle Name (Optional)"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.nextOfKin?.middleName && formikProps.touched.nextOfKin?.middleName && formikProps.errors.nextOfKin.middleName}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Last Name */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.nextOfKin?.lastName && formikProps.touched.nextOfKin?.lastName}>
                                        <FormLabel htmlFor="nextOfKin.lastName">Last Name</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('nextOfKin.lastName')}
                                            id="nextOfKin.lastName"
                                            placeholder="Enter Last Name"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.nextOfKin?.lastName && formikProps.touched.nextOfKin?.lastName && formikProps.errors.nextOfKin.lastName}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Relationship */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.nextOfKin?.relationship && formikProps.touched.nextOfKin?.relationship}>
                                        <FormLabel htmlFor="nextOfKin.relationship">Relationship</FormLabel>
                                        <Select
                                            {...formikProps.getFieldProps('nextOfKin.relationship')}
                                            id="nextOfKin.relationship"
                                            placeholder="-- Select relationship --"
                                        >
                                            <option value="spouse">Spouse</option>
                                            <option value="parent">Parent</option>
                                            <option value="sibling">Sibling</option>
                                            <option value="child">Child</option>
                                            <option value="friend">Friend</option>
                                            <option value="other">Other</option>
                                        </Select>
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.nextOfKin?.relationship && formikProps.touched.nextOfKin?.relationship && formikProps.errors.nextOfKin.relationship}
                                        </Text>
                                    </FormControl>
                                </GridItem>


                                {/* Cell */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.nextOfKin?.cell && formikProps.touched.nextOfKin?.cell}>
                                        <FormLabel htmlFor="nextOfKin.cell">Cell</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('nextOfKin.cell')}
                                            id="nextOfKin.cell"
                                            placeholder="Enter Cell Number"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.nextOfKin?.cell && formikProps.touched.nextOfKin?.cell && formikProps.errors.nextOfKin.cell}
                                        </Text>
                                    </FormControl>
                                </GridItem>
                            </SimpleGrid>


                            <SectionHeading icon="basil:map-location-solid" text="Address Details :" />
                            <SimpleGrid columns={2} gap={4} px={4}>
                                {/* Plot No */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.address?.plotNo && formikProps.touched.address?.plotNo}>
                                        <FormLabel htmlFor="address.plotNo">Plot No</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('address.plotNo')}
                                            id="address.plotNo"
                                            placeholder="Enter Plot No"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.address?.plotNo && formikProps.touched.address?.plotNo && formikProps.errors.address.plotNo}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Postal */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.address?.postal && formikProps.touched.address?.postal}>
                                        <FormLabel htmlFor="address.postal">Postal</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('address.postal')}
                                            id="address.postal"
                                            placeholder="Enter Postal Address"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.address?.postal && formikProps.touched.address?.postal && formikProps.errors.address.postal}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Street */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.address?.street && formikProps.touched.address?.street}>
                                        <FormLabel htmlFor="address.street">Street</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('address.street')}
                                            id="address.street"
                                            placeholder="Enter Street Name"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.address?.street && formikProps.touched.address?.street && formikProps.errors.address.street}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* City */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.address?.city && formikProps.touched.address?.city}>
                                        <FormLabel htmlFor="address.city">City</FormLabel>
                                        <Select
                                            {...formikProps.getFieldProps('address.city')}
                                            id="address.city"
                                            placeholder="Select City"
                                        >
                                            {cities.map(city => (
                                                <option key={city._id} value={city.name}>{city.name}</option>
                                            ))}
                                        </Select>
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.address?.city && formikProps.touched.address?.city && formikProps.errors.address.city}
                                        </Text>
                                    </FormControl>

                                </GridItem>
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.address?.ward && formikProps.touched.address?.ward}>
                                        <FormLabel htmlFor="address.ward">Ward</FormLabel>
                                        <Select
                                            {...formikProps.getFieldProps('address.ward')}
                                            id="address.ward"
                                            placeholder="Select Ward"
                                        >
                                            {areas.map(area => (
                                                <option key={area._id} value={area.name}>{area.name}</option>
                                            ))}
                                        </Select>
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.address?.ward && formikProps.touched.address?.ward && formikProps.errors.address.ward}
                                        </Text>
                                    </FormControl>

                                </GridItem>
                            </SimpleGrid>


                            <SectionHeading icon="mage:phone-call-fill" text="Contact Details :" />
                            <SimpleGrid columns={2} gap={4} px={4}>
                                {/* Cell */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.contactDetails?.cell && formikProps.touched.contactDetails?.cell}>
                                        <FormLabel htmlFor="contactDetails.cell">Cell</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('contactDetails.cell')}
                                            id="contactDetails.cell"
                                            placeholder="Enter Cell Number"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.contactDetails?.cell && formikProps.touched.contactDetails?.cell && formikProps.errors.contactDetails.cell}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Home Telephone */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.contactDetails?.homeTel && formikProps.touched.contactDetails?.homeTel}>
                                        <FormLabel htmlFor="contactDetails.homeTel">Home Telephone</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('contactDetails.homeTel')}
                                            id="contactDetails.homeTel"
                                            placeholder="Enter Home Telephone Number (Optional)"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.contactDetails?.homeTel && formikProps.touched.contactDetails?.homeTel && formikProps.errors.contactDetails.homeTel}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Work Telephone */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.contactDetails?.workTel && formikProps.touched.contactDetails?.workTel}>
                                        <FormLabel htmlFor="contactDetails.workTel">Work Telephone</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('contactDetails.workTel')}
                                            id="contactDetails.workTel"
                                            placeholder="Enter Work Telephone Number (Optional)"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.contactDetails?.workTel && formikProps.touched.contactDetails?.workTel && formikProps.errors.contactDetails.workTel}
                                        </Text>
                                    </FormControl>
                                </GridItem>

                                {/* Email */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.contactDetails?.email && formikProps.touched.contactDetails?.email}>
                                        <FormLabel htmlFor="contactDetails.email">Email</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('contactDetails.email')}
                                            id="contactDetails.email"
                                            placeholder="Enter Email Address"
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.contactDetails?.email && formikProps.touched.contactDetails?.email && formikProps.errors.contactDetails.email}
                                        </Text>
                                    </FormControl>
                                </GridItem>
                            </SimpleGrid>



                            <SectionHeading icon="fluent:document-one-page-link-20-filled" text="Documents Upload :" />
                            <Grid templateColumns="repeat(12, 1fr)" gap={2} color={"blue.300"} px={4}>
                                {/* Certified National Identity Card/Passport */}
                                <GridItem colSpan={{base: 12, md: 6}}>
                                    <DropBox
                                        label={"Identity Proof Document"}
                                        name="idKey"
                                        filePath={formikProps.values.files.idKey}
                                        loading={loading.idKey}
                                        formikProps={formikProps}
                                        callback={(event) => handleFileChange('idKey', event, formikProps.setFieldValue,formikProps.values.userId)}
                                        result={formikProps.values.files.idKey}
                                        instruction={`Please attach relevant documents.`}
                                    />
                                    {/*<pre>{JSON.stringify(formikProps.errors, null, 2)}</pre>
                                    <pre>{JSON.stringify(formikProps.values, null, 2)}</pre>*/}

                                </GridItem>
                                {/* Proof Of Marriage */}
                                {formikProps.values.personalDetails.maritalStatus === 'Married' && (
                                    <GridItem colSpan={{base: 12, md: 6}}>
                                        <DropBox
                                            label={"Marriage Proof Document"}
                                            name="maritalProofKey"
                                            filePath={formikProps.values.files.maritalProofKey}
                                            loading={loading.maritalProofKey}
                                            formikProps={formikProps}
                                            callback={(event) => handleFileChange('maritalProofKey', event, formikProps.setFieldValue,formikProps.values.userId)}
                                            result={formikProps.values.files.maritalProofKey}
                                            instruction={`Please attach relevant documents.`}
                                        />
                                    </GridItem>
                                )}
                                <GridItem colSpan={{base: 12, md: 6}}>
                                    <DropBox
                                        label="Lease or Title Deed Document"
                                        name="files.leeseOrTitleDeed"
                                        filePath={formikProps.values.files.leeseOrTitleDeed}
                                        loading={loading.leeseOrTitleDeed}
                                        formikProps={formikProps}
                                        callback={(event) => handleFileChange('leeseOrTitleDeed', event, formikProps.setFieldValue, formikProps.values.userId)}
                                        result={formikProps.values.files.leeseOrTitleDeed}
                                        instruction="Please attach the Leese or Title Deed document."
                                    />
                                </GridItem>

                            </Grid>


                            <SectionHeading icon="mdi:user-settings" text="Consent Details :" />
                            <SimpleGrid columns={2} gap={4} px={4}>
                                <GridItem>
                                    <FormControl display="flex" alignItems="center">
                                        <Checkbox
                                            id="consent"
                                            isChecked={formikProps.values.consent}
                                            onChange={formikProps.handleChange}
                                            name="consent"
                                            colorScheme="blue"
                                            variant="outline"
                                            mr={2}
                                        >
                                            Consent :
                                        </Checkbox>
                                        <Text pl={2}>{formikProps.values.consent ? "Yes" : "No"}</Text>
                                    </FormControl>
                                </GridItem>

                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.dateOfConsent && formikProps.touched.dateOfConsent}>
                                        <FormLabel htmlFor="dateOfConsent">Date of Consent</FormLabel>
                                        <ThemedStyledInput
                                            type="date"
                                            {...formikProps.getFieldProps('dateOfConsent')}
                                            id="dateOfConsent"
                                        />
                                        {formikProps.errors.dateOfConsent && formikProps.touched.dateOfConsent && (
                                            <Text color="red.500" fontSize="sm">{formikProps.errors.dateOfConsent}</Text>
                                        )}
                                    </FormControl>
                                </GridItem>


                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.spouseCustomerNumber && formikProps.touched.spouseCustomerNumber}>
                                        <FormLabel htmlFor="spouseCustomerNumber">Spouse connection Number</FormLabel>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('spouseCustomerNumber')}
                                            id="spouseCustomerNumber"
                                            placeholder="Enter Spouse connection Number (Optional)"
                                        />
                                        {formikProps.errors.spouseCustomerNumber && formikProps.touched.spouseCustomerNumber && (
                                            <Text color="red.500" fontSize="sm">{formikProps.errors.spouseCustomerNumber}</Text>
                                        )}
                                    </FormControl>
                                </GridItem>
                            </SimpleGrid>


                            <SectionHeading icon="material-symbols-light:order-approve-rounded" text="Application Status :">
                                <Badge ml="4" colorScheme={getStatusColor(formikProps.values.status)} variant="solid">
                                    {formikProps.values.status || 'Not Set'}
                                </Badge>
                            </SectionHeading>

                            <SimpleGrid columns={2} gap={4} px={4}>
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.status && formikProps.touched.status}>
                                        <FormLabel htmlFor="status">Update Status</FormLabel>
                                        <Select
                                            id="status"
                                            placeholder="-- Select Status --"
                                            value={formikProps.values.status}
                                            onChange={(e) => {
                                                const newStatus = e.target.value;
                                                formikProps.setFieldValue('status', newStatus);
                                            }}
                                            disabled={formikProps.isSubmitting}
                                        >
                                            {getAllowedStatusOptions().map(option => (
                                                <option key={option.value} value={option.value} disabled={option.disabled}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Select>
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.status && formikProps.touched.status && formikProps.errors.status}
                                        </Text>
                                    </FormControl>
                                </GridItem>



                                {/* Comments */}
                                <GridItem colSpan={4}>
                                    <FormControl>
                                        <FormLabel htmlFor="comments">Comments</FormLabel>
                                        <Textarea
                                            {...formikProps.getFieldProps('comments')}
                                            id="comments"
                                            placeholder="Enter comments here..."
                                        />
                                        <Text color="red.500" fontSize="sm">
                                            {formikProps.errors.comments && formikProps.touched.comments && formikProps.errors.comments}
                                        </Text>
                                    </FormControl>
                                </GridItem>
                            </SimpleGrid>


                            <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }} mt={8} mb={8}>
                                <SimpleGrid columns={2} gap={4} px={4}>
                                    <>
                                    <GridItem colSpan={1}>
                                            <CustomAlert status="info" message={`Allowed transitions for the current connection application: ${getAllowedTransitions(formikProps.values.status).join(', ') || 'Completed'}`} />
                                            <CustomAlert status="info" message={`Customer Number & Contract Number: ${formikProps.values.customerNumber} , ${formikProps.values.contractNumber}`} />
                                            <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
                                    </GridItem>
                                    <GridItem colSpan={1}>
                                            <Text fontSize="lg" fontWeight="bold" mb={2}>Form Errors:</Text>
                                            <FieldErrorMessage name="customerNumber" />
                                            <FieldErrorMessage name="contractNumber" />
                                            <FieldErrorMessage name="personalDetails.firstName" />
                                            <FieldErrorMessage name="personalDetails.middleName" />
                                            <FieldErrorMessage name="personalDetails.lastName" />
                                            <FieldErrorMessage name="personalDetails.identityNo" />
                                            <FieldErrorMessage name="personalDetails.identityType" />
                                            <FieldErrorMessage name="personalDetails.maritalStatus" />
                                            <FieldErrorMessage name="personalDetails.maritalType" />
                                            <FieldErrorMessage name="personalDetails.sex" />
                                            <FieldErrorMessage name="personalDetails.dateOfBirth" />
                                            <FieldErrorMessage name="nextOfKin.firstName" />
                                            <FieldErrorMessage name="nextOfKin.middleName" />
                                            <FieldErrorMessage name="nextOfKin.lastName" />
                                            <FieldErrorMessage name="nextOfKin.relationship" />
                                            <FieldErrorMessage name="nextOfKin.cell" />
                                            <FieldErrorMessage name="address.plotNo" />
                                            <FieldErrorMessage name="address.postal" />
                                            <FieldErrorMessage name="address.street" />
                                            <FieldErrorMessage name="address.ward" />
                                            <FieldErrorMessage name="address.city" />
                                            <FieldErrorMessage name="address.district" />
                                            <FieldErrorMessage name="contactDetails.cell" />
                                            <FieldErrorMessage name="contactDetails.homeTel" />
                                            <FieldErrorMessage name="contactDetails.workTel" />
                                            <FieldErrorMessage name="contactDetails.email" />
                                            <FieldErrorMessage name="files.idKey" />
                                            <FieldErrorMessage name="files.maritalProofKey" />
                                            <FieldErrorMessage name="files.leeseOrTitleDeed" />
                                            <FieldErrorMessage name="spouseCustomerNumber" />
                                            <FieldErrorMessage name="consent" />
                                            <FieldErrorMessage name="dateOfConsent" />
                                            <FieldErrorMessage name="dateOfApplication" />
                                            <FieldErrorMessage name="isExistingConnection" />
                                            <FieldErrorMessage name="existingConnections" />
                                            <FieldErrorMessage name="connectionType" />
                                            <FieldErrorMessage name="applicantType" />
                                            <FieldErrorMessage name="serviceConnectionDetails.plotNo" />
                                            <FieldErrorMessage name="serviceConnectionDetails.ward" />
                                            <FieldErrorMessage name="serviceConnectionDetails.city" />
                                            <FieldErrorMessage name="ownerOrLandlord.firstName" />
                                            <FieldErrorMessage name="ownerOrLandlord.middleName" />
                                            <FieldErrorMessage name="ownerOrLandlord.lastName" />
                                            <FieldErrorMessage name="ownerOrLandlord.cell" />
                                            <FieldErrorMessage name="ownerOrLandlord.cell2" />
                                            <FieldErrorMessage name="ownerOrLandlord.email" />
                                            <FieldErrorMessage name="status" />
                                            <FieldErrorMessage name="comments" />
                                    </GridItem>

                                    <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                                        <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                                            Cancel
                                        </CustomButton>
                                        <CustomButton type="submit" disabled={formikProps.isSubmitting} style={{
                                            opacity: formikProps.isSubmitting ? 0.7 : 1,
                                            pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                                        }}>
                                            {formikProps.isSubmitting ? (
                                                <>
                                                    <Spinner size="xs" mr={2} />
                                                    Updating...
                                                </>
                                            ) : 'Update'}
                                        </CustomButton>
                                    </ButtonStack>

                                </>
                                </SimpleGrid>
                            </Box>
                        </VStack>
                    </Form>
                )}
            </Formik>

            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

export default NewConnectionEditForm;
