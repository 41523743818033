import React from 'react';
import { Formik, Form, Field } from 'formik';
import { FormControl, Select, Stack } from '@chakra-ui/react';
import { ThemedStyledInput } from '../../../../../components/Styled';
import * as Yup from 'yup';
import { CustomButton } from "../../../../../components/Styled/StyledButtons";
import { Icon } from "@iconify/react";
import FieldErrorMessage from "../../../../../components/FieldErrorMessage/FieldErrorMessage";
import useSingleToast from "../../../../../hooks/UseSingleToast/UseSingleToast";

const LabQuotationRequestSearchFilter = ({ onFilterChange }) => {
    const showToast = useSingleToast();

    const validationSchema = Yup.object({
        webDocNumber: Yup.string()
            .trim('Web Document Number cannot be just whitespace')
            .strict(true),
        pNumber: Yup.string()
            .trim('Partner Number cannot be just whitespace')
            .strict(true),
        quotationNumber: Yup.string()
            .trim('Quotation Number cannot be just whitespace')
            .strict(true),
        emailAddress: Yup.string()
            .email('Invalid email address')
            .trim('Email Address cannot be just whitespace')
            .strict(true),
        name: Yup.string()
            .max(50, 'Name must be 50 characters or less')
            .trim('Name cannot be just whitespace')
            .strict(true),
        status: Yup.string()
    });

    return (
        <Formik
            initialValues={{ webDocNumber: '', pNumber: '', quotationNumber: '', emailAddress: '', name: '', status: '' }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                if (Object.values(values).every(value => value === '')) {
                    showToast({
                        title: "No Parameters Supplied",
                        description: "Please enter search criteria.",
                        status: "warning",
                        duration: 5000,
                        isClosable: true,
                    });
                } else {
                    onFilterChange(values);
                }
                setSubmitting(false);
            }}
            onReset={() => {
                onFilterChange({ webDocNumber: '', pNumber: '', quotationNumber: '', emailAddress: '', name: '', status: '' });
            }}
        >
            {({ handleSubmit, resetForm, setFieldValue }) => (
                <Form>
                    <Stack spacing={2} direction="row" align="center">
                        <FormControl flex="1">
                            <Field as={ThemedStyledInput} id="webDocNumber" name="webDocNumber" type="text" placeholder="Web Doc Number" />
                            <FieldErrorMessage name="webDocNumber" />
                        </FormControl>
                        <FormControl flex="1">
                            <Field as={ThemedStyledInput} id="pNumber" name="pNumber" type="text" placeholder="Partner Number" />
                            <FieldErrorMessage name="pNumber" />
                        </FormControl>
                        <FormControl flex="1">
                            <Field as={ThemedStyledInput} id="quotationNumber" name="quotationNumber" type="text" placeholder="Quotation Number" />
                            <FieldErrorMessage name="quotationNumber" />
                        </FormControl>
                        <FormControl flex="1.5">
                            <Field as={ThemedStyledInput} id="emailAddress" name="emailAddress" type="text" placeholder="Email Address" />
                            <FieldErrorMessage name="emailAddress" />
                        </FormControl>
                        <FormControl flex="1">
                            <Field as={ThemedStyledInput} id="name" name="name" type="text" placeholder="Partner Name" />
                            <FieldErrorMessage name="name" />
                        </FormControl>
                        <FormControl flex="1">
                            <Select id="status" name="status" placeholder="Select status" onChange={e => setFieldValue('status', e.target.value)}>
                                <option value="Created">Created</option>
                                <option value="Submitted">Submitted</option>
                                <option value="Rejected">Rejected</option>
                                <option value="Accepted">Accepted</option>
                                <option value="Paid">Paid</option>
                                <option value="Failed">Failed</option>
                            </Select>
                            <FieldErrorMessage name="status" />
                        </FormControl>
                        <CustomButton
                            onClick={handleSubmit}
                            type="search"
                            tooltipLabel="Search"
                            icon={<Icon icon="bi:search" />}
                            showLabel={false}
                        >
                            Search
                        </CustomButton>
                        <CustomButton
                            type="reset"
                            onClick={() => {
                                resetForm();
                                onFilterChange({ webDocNumber: '', pNumber: '', quotationNumber: '', emailAddress: '', name: '', status: '' });
                            }}
                            tooltipLabel="Clear Filters"
                            icon={<Icon icon="radix-icons:reset" />}
                            showLabel={false}
                        >
                            Clear
                        </CustomButton>
                    </Stack>
                </Form>

            )}
        </Formik>
    );
};

export default LabQuotationRequestSearchFilter;

