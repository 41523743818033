import React, { useEffect, useState } from 'react';
import {
  Box,
  VStack,
  Text,
  Heading,
  Image,
  AspectRatio,
  useToast,
} from '@chakra-ui/react';
import videoService from '../../../../services/videoService'; // adjust the import path as necessary

const VideoViewForm = ({ videoId }) => {
  const [video, setVideo] = useState(null);
  const toast = useToast();

  useEffect(() => {
    const fetchVideo = async () => {
      try {
        const videoData = await videoService.getVideoById(videoId);
        setVideo(videoData);
      } catch (error) {
        toast({
          title: 'Error',
          description: `Failed to fetch video: ${error.message}`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchVideo();
  }, [videoId, toast]);

  // Function to determine how to display the video player based on the source
  const renderVideoPlayer = (video) => {
    if (video.source === 'YouTube') {
      return (
        <AspectRatio ratio={16 / 9}>
          <iframe
            title="Video Player"
            src={`https://www.youtube.com/embed/${video.videoCode}`}
            allowFullScreen
          />
        </AspectRatio>
      );
    } else if (video.source === 'Facebook') {
      return (
        <AspectRatio ratio={16 / 9}>
          <iframe
            title="Video Player"
            src={`https://www.facebook.com/plugins/video.php?href=https://www.facebook.com/facebook/videos/${video.videoCode}`}
            allowFullScreen
          />
        </AspectRatio>
      );
    } else {
      // Assuming direct URL for the 'URL' source type
      return (
        <AspectRatio ratio={16 / 9}>
          <video
            title="Video Player"
            src={video.url}
            controls
          />
        </AspectRatio>
      );
    }
  };

  if (!video) {
    return <Text>Loading video...</Text>;
  }

  return (
    <VStack spacing={4}>
      <Heading as="h3" size="lg">{video.title}</Heading>
      {video.thumbnail ? (
        <Image src={video.thumbnail} alt={`${video.title} thumbnail`} />
      ) : (
        <Text>No thumbnail available</Text>
      )}
      {renderVideoPlayer(video)}
      <Box>
        <Text fontSize="md"><strong>Source:</strong> {video.source}</Text>
        <Text fontSize="md"><strong>URL:</strong> {video.url}</Text>
        <Text fontSize="md"><strong>Published:</strong> {video.published ? 'Yes' : 'No'}</Text>
      </Box>
    </VStack>
  );
};

export default VideoViewForm;
