const getStatusColor = (status) => {
    switch (status) {
        case 'Created':
            return 'cyan';
        case 'Submitted':
            return 'blue';
        case 'Rejected':
            return 'orange';
        case 'Accepted':
            return 'green';
        case 'Paid':
            return 'purple';
        case 'Failed':
            return 'red';
        default:
            return 'yellow';
    }
};
export default getStatusColor;