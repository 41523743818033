import React, {useEffect, useRef} from 'react';
import {
    VStack, Box, Text, Badge, SimpleGrid, Stack, IconButton, Tooltip,
} from '@chakra-ui/react';
import FormActionLabel from "../../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../../components/Styled/StyledButtons";
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import {Icon} from "@iconify/react";
import { useReactToPrint } from 'react-to-print';
import PrintableComponent from "../../../../components/PrintableComponent/PrintableComponent";
import SectionHeading from "../../../../components/SectionHeading/SectionHeading";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";



const DisconnectionViewForm = ({ formData}) => {
    const showToast = useSingleToast();
    const showToastRef = useRef(false);
    const printComponentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current,
    });

    const statusColorScheme = {
        Approved: 'green',
        Declined: 'red',
        Complete: 'blue',
        Review: 'yellow',
        Submitted: 'gray',
    };

    const getStatusColorScheme = (status) => {
        return statusColorScheme[status] || 'gray';
    };


    const handlePrintDebug = () => {
        console.log("Attempting to print...");
        handlePrint();
    };


   useEffect(() => {
        if ((!formData || Object.keys(formData).length === 0 || !formData.personalDetails) && !showToastRef.current) {
            showToast({
                title: 'KYC Information',
                description: "User is yet to update his KYC.",
                status: 'warning',
                duration: 5000,
                isClosable: true,
            });
            showToastRef.current = true;
        }
    }, [formData, showToast]);

    const { closeDrawer } = useDrawer();


    const {
        customerNumber = '',
        contractNumber = '',
        personalDetails = {},
        address = {},
        landLordDetails = {},
        bankingDetails = {},
        status = '',
        consent = false,
        dateOfConsent = '',
    } = formData || {};


    const formattedDateOfConsent = dateOfConsent
        ? new Date(dateOfConsent).toLocaleDateString()
        : '';

    const printData = {
        'General Information': {
            'Customer Number': formData.customerNumber || '',
            'Contract Number': formData.contractNumber || '',
            'Disconnection Request Status': status,
            'Date of Application': formData.dateOfApplication ? new Date(formData.dateOfApplication).toLocaleDateString() : '',
            'Consent Given': consent ? 'Yes' : 'No',
            'Date of Consent': formattedDateOfConsent,
        },
        'Personal Details': {
            'First Name': personalDetails.firstName || '',
            'Middle Name': personalDetails.middleName || '',
            'Last Name': personalDetails.lastName || '',
        },
        'Address': {
            'Plot No': address.lotNo || '',
            'Street': address.street || '',
            'Ward': address.ward || '',
            'City': address.city || '',
            'District': address.district || '',
        },
        'Landlord Details': {
            'Name': landLordDetails.name || '',
            'Identity No': landLordDetails.identityNo || '',
            'Identity Type': landLordDetails.identityType || '',
            'Cell': landLordDetails.cell || '',
            'Email': landLordDetails.email || '',
        },
        'Banking Details': {
            'Bank Name': bankingDetails.name || '',
            'Branch': bankingDetails.branch || '',
            'Branch Code': bankingDetails.branchCode || '',
            'Account Number': bankingDetails.accountNumber || '',
        },
    };


    return (
        <>
            <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                <FormActionLabel formAction="view" formName="Disconnection Request"/>
                <Stack spacing={4} px={2} py={4} mt={4}>

                    {/* KYC Details Sections */}
                    <SectionHeading text="Contract Details" icon="mdi:account-tag" />
                        <SimpleGrid columns={{ base: 1, sm: 2 }} gap={4} px={4}>
                            <Text><strong>Customer Number:</strong> {customerNumber}</Text>
                            <Text><strong>Contract Number:</strong> {contractNumber}</Text>
                        </SimpleGrid>

                    {/* KYC Details Sections */}
                    <SectionHeading text="Personal Details" icon="bxs:user-detail" />
                        <SimpleGrid columns={{ base: 1, sm: 2 }} gap={4} px={4}>
                            <Text><strong>First Name:</strong> {personalDetails.firstName}</Text>
                            <Text><strong>Middle Name:</strong> {personalDetails.middleName}</Text>
                            <Text><strong>Last Name:</strong> {personalDetails.lastName}</Text>
                        </SimpleGrid>

                    {/* Address Details Section */}
                    <SectionHeading text="Address" icon="material-symbols:location-away" />
                        <SimpleGrid columns={{ base: 1, sm: 2 }} gap={4} px={4}>
                            <Text><strong>Lot No:</strong> {address.lotNo}</Text>
                            <Text><strong>Street:</strong> {address.street}</Text>
                            <Text><strong>Ward:</strong> {address.ward}</Text>
                            <Text><strong>City:</strong> {address.city}</Text>
                            <Text><strong>District:</strong> {address.district}</Text>
                        </SimpleGrid>

                    {/* Landlord Details Section */}
                    <SectionHeading text="Landlord Details" icon="material-symbols-light:add-home" />
                        <SimpleGrid columns={{ base: 1, sm: 2 }} gap={4} px={4}>
                            <Text><strong>Name:</strong> {landLordDetails.name}</Text>
                            <Text><strong>Identity No:</strong> {landLordDetails.identityNo}</Text>
                            <Text><strong>Identity Type:</strong> {landLordDetails.identityType}</Text>
                            <Text><strong>Cell:</strong> {landLordDetails.cell}</Text>
                            <Text><strong>Email:</strong> {landLordDetails.email}</Text>
                        </SimpleGrid>

                    {/* Banking Details Section */}
                    <SectionHeading text="Banking Details" icon="fluent:building-bank-link-48-filled" />
                        <SimpleGrid columns={{ base: 1, sm: 2 }} gap={4} px={4}>
                            <Text><strong>Bank Name:</strong> {bankingDetails.name}</Text>
                            <Text><strong>Branch:</strong> {bankingDetails.branch}</Text>
                            <Text><strong>Branch Code:</strong> {bankingDetails.branchCode}</Text>
                            <Text><strong>Account Number:</strong> {bankingDetails.accountNumber}</Text>
                        </SimpleGrid>

                    {/* Application Status and Consent Section */}
                    <SectionHeading text="Application Status and Consent" icon="material-symbols-light:order-approve-rounded" />
                        <SimpleGrid columns={{ base: 1, sm: 2 }} gap={4} px={4}>
                            <Text>
                                <strong>Status:</strong>
                                <Badge colorScheme={getStatusColorScheme(status)} ml={2} variant="solid">
                                    {status}
                                </Badge>
                            </Text>
                            <Text><strong>Consent Given:</strong> {consent ? 'Yes' : 'No'}</Text>
                            <Text><strong>Date of Consent:</strong> {formattedDateOfConsent}</Text>
                        </SimpleGrid>

                    <Box flexDirection="column" alignItems="left" style={{alignItems: 'flex-start'}} mt={8} mb={8}>
                        <ButtonStack direction="row" style={{justifyContent: 'flex-start'}}>
                            <CustomAlert status="warning" message="--End of Information--"/>
                            <CustomButton type="cancel" onClick={closeDrawer} showIcon={false}>
                                Close
                            </CustomButton>
                            <Tooltip label="Print This Disconnection Request">
                                <IconButton
                                    aria-label="Print"
                                    icon={<Icon icon="material-symbols-light:print-outline"/>}
                                    onClick={handlePrintDebug}
                                />
                            </Tooltip>
                        </ButtonStack>
                    </Box>
                </Stack>
            </VStack>
            <div style={{display: "none"}}>
                <PrintableComponent ref={printComponentRef} data={printData} title="Disconnection Request" />
            </div>
        </>
    );
};

export default DisconnectionViewForm;
