import React from 'react'
import DefaultTemplate from '../../../components/DefaultTemplate/DefaultTemplate'
import AreaContainer from './Components/AreaContainer'
import {Icon} from "@iconify/react";

const Areas = () => {
    return (
        <>
            <DefaultTemplate Content={ <AreaContainer/> } Title={"Areas Management" } icon={<Icon icon="fa-solid:map-marked-alt" />}/>
        </>
    )
}

export default Areas
