import React, { useEffect } from "react";
import {
  SimpleGrid,
  Image,
  Box,
  Center,
  VStack,
  Text
} from '@chakra-ui/react';
import emptyFiles from '../../../assests/images/folderEmpty.png';
import pdfFileImage from '../../../assests/images/pdfFile.png';
import PdfViewerModal from "../../../components/PdfViewer/PdfViewerModal";
import { useDisclosure } from "@chakra-ui/react";

const TenderDocumentList = ({ tender }) => {

  console.log("tender",tender)

  const { isOpen, onOpen, onClose }=useDisclosure()
  const [ filePath, setFilePath ]=React.useState('')
  const [ filesExist, setFilesExist ]=React.useState(true)

  useEffect(() => {
    if(tender?.otherDocumentsKeys?.length > 0 && tender?.advertDocumentKey?.key && tender?.tenderDocumentKey?.key){
      setFilesExist(false)
    }
  }, [tender])

  const handlePdfClick = (pdf) => {
    console.log("pdf",pdf)
    setFilePath(pdf)
    onOpen()
  };

  return (
    <>
      <SimpleGrid columns={4} spacing={3}>
        {tender?.otherDocumentsKeys?.map((pdf, index) => (
          <Box key={index} 
                position="relative"
                mr="4" bg={'gray.100'} 
                p={3} shadow={'md'} 
                rounded={'md'}
                cursor="pointer"
                onClick={() => handlePdfClick(pdf?.key)}
                >
            <Center>
              <Image src={pdfFileImage} alt={`pdf-file ${index + 1}`} />
            </Center>
            <Text fontSize="sm" fontWeight="bold" align="center" color="gray.500">
                {pdf?.name}
              </Text>
          </Box>
        ))}

        {tender?.advertDocumentKey?.key && (
          <Box 
                position="relative"
                mr="4" bg={'gray.100'} 
                p={3} shadow={'md'} 
                rounded={'md'}
                cursor="pointer"
                onClick={() => handlePdfClick(tender.advertDocumentKey?.key)}
                >
            <Center>
              <Image src={pdfFileImage} alt={`pdf-file advert`} />
            </Center>
            <Text fontSize="sm" fontWeight="bold" align="center" color="gray.500">
                {tender.advertDocumentKey?.name}
              </Text>
          </Box>
        )}

        {tender?.tenderDocumentKey?.key && (
          <Box 
                position="relative"
                mr="4" bg={'gray.100'} 
                p={3} shadow={'md'} 
                rounded={'md'}
                cursor="pointer"
                onClick={() => handlePdfClick(tender.tenderDocumentKey?.key)}
                >
            <Center>
              <Image src={pdfFileImage} alt={`pdf-file tender`} />
            </Center>
              <Text fontSize="sm" fontWeight="bold" align="center" color="gray.500">
                {tender.tenderDocumentKey?.name}
              </Text>
          </Box>
        )}

      </SimpleGrid>
      {filesExist && (
        <VStack spacing={4} align="stretch">
          <Center>
            <Image
              objectFit="fit"
              src={emptyFiles}
              alt={`no documents found`}
              borderRadius="md"
              height={{ base: '150px', md: '150px' }}
            />
          </Center>
          <Box>
            <Text fontSize="lg" fontWeight="bold" align="center" color="gray.500">
              No Documents
            </Text>
          </Box>
        </VStack>
      )}
      
      {filePath && <PdfViewerModal filePath={filePath} isOpen={isOpen} onOpen={onOpen} onClose={onClose}/>}
    </>
  );
};

export default TenderDocumentList;
