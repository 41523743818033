import React, { useEffect, useState } from 'react';
import { Flex, GridItem, Stack } from '@chakra-ui/react';
import EventAddForm from './EventAddForm'; // Ensure this form is for adding new customers
import { StyledGrid } from "../../../components/Styled/StyledComponents";
import { ButtonStack, CustomButton } from "../../../components/Styled/StyledButtons";
import { useDrawer } from "../../../Context/DrawerContext/DrawerContext";
import { Icon } from "@iconify/react";
import { ThemedStyledInput } from "../../../components/Styled";

const SearchAndFilterComponent = ({ callback, resetFilters }) => {
    const [filters, setFilters] = useState({});
    useEffect(() => {
        callback(filters);
    }, [filters, callback]);

    const handleSearch = (event) => {
        const { name, value } = event.target;
        setFilters(prev => ({ ...prev, [name]: value }));
    };

    return (
        <>
            <Stack spacing={2} direction="row" align="center">
                <ThemedStyledInput
                    type="text"
                    name="question"
                    w="full"
                    placeholder="Search by Title"
                    onChange={handleSearch}
                />
                <GridItem colSpan={{ base: 12, md: 1 }}>
                    <CustomButton
                        type="reset"
                        onClick={() => resetFilters()}
                        tooltipLabel="Clear Filters"
                        icon={<Icon icon="radix-icons:reset" />}
                        showLabel={false}
                    >
                        Clear
                    </CustomButton>
                </GridItem>
            </Stack>
        </>
    );
};
const EventTableTopBar = ({ refetchEventsData, eventsData }) => { // Pass refetchEventsData if needed for the AddForm to trigger a refresh
    const { openDrawer } = useDrawer();

    const resetFilters = () => {
        refetchEventsData({}, true);
    };

    return (
        <>
            <StyledGrid templateColumns="repeat(12, 1fr)">
                <GridItem colSpan={{ base: 10, md: 11 }}>
                    <SearchAndFilterComponent callback={refetchEventsData} resetFilters={resetFilters} />
                </GridItem>
                <GridItem colSpan={{ base: 12, md: 1 }}>
                    <ButtonStack direction="row">
                        <CustomButton
                            type="addNew"
                            showIcon={true}
                            showTooltip={true}
                            tooltipLabel="Add New Event"
                            icon={<Icon icon="mdi:chart-box-plus-outline" />}
                            onClick={() => openDrawer({
                                title: 'Create New Event',
                                component: EventAddForm,
                                props: { refetchEventsData, eventsData }
                            }, 'md')}
                        >
                            Add New
                        </CustomButton>
                    </ButtonStack>
                </GridItem>
            </StyledGrid>
            <Flex bg="white" mb={4}></Flex>
        </>
    );
};

export default EventTableTopBar;
