import React, { useEffect, useState, useCallback } from 'react';
import ProjectsTable from './ProjectsTable';
import ProjectTableTopBar from './ProjectTableTopBar';
import { fetchAllProjects } from '../projectAPI';
import utils from '../../../utils/commonFunctions';

const ProjectsContainer = () => {
    const [projects, setProjects] = useState([]);
    const [error, setError] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const refetchProjectsData = useCallback(async (filter={}, fetchAll = false) => {
        setDataLoaded(false);
        try {
            const data = await fetchAllProjects(pageNumber, 10, filter, fetchAll);
            setProjects(data.projects);
            setTotalPages(data.totalPages);
            setError('');
            setDataLoaded(true);
        } catch (error) {
            utils.sendMessageToBugNinja("Error fetching project data:", error.message, error)
            setError('Failed to fetch projects data.');
            setProjects([]);
            setTotalPages(0);
        }
    }, [pageNumber]);

    useEffect(() => {
        refetchProjectsData();
    }, [pageNumber, refreshFlag, refetchProjectsData]);

    return (
        <>
            <ProjectTableTopBar refetchProjectsData={refetchProjectsData} />
            <ProjectsTable
                projects={projects}
                setProjects={setProjects}
                setRefreshFlag={setRefreshFlag}
                refetchProjectsData={refetchProjectsData}
                error={error}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                dataLoaded={dataLoaded}
            />
        </>
    );
};

export default ProjectsContainer;
