import React, { useRef, useCallback, useState, useEffect } from 'react';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    useToast,
    Box,
    Switch,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Button,
    Select, Flex
} from '@chakra-ui/react';
import { CustomButton, ButtonStack } from "../../../components/Styled/StyledButtons";
import CustomAlert from "../../../components/Styled/StyledAlert";
import pageService from '../../../services/pageService';
import fileService from '../../../services/fileService';
import { useDrawer } from '../../../Context/DrawerContext/DrawerContext';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDisclosure } from '@chakra-ui/react';
import { ThemedStyledInput } from "../../../components/Styled";
import utils from "../../../utils/commonFunctions";
import DropBox from "../../../components/DropBox/DropBox";
import { StyledTooltip } from "../../../components/Styled/StyledComponents";
import CKEditorComponent from "./CKEditor";
import TinyMCE from "./TinyMCE";
import DropBoxList from "./DropboxList";

const categories = {
    RESIDENTIAL: 'Residential',
    COMMERCIAL: 'Commercial',
    SERVICES: 'Services',
    YOUR_WATER_ACCOUNT: 'Your Water Account',
    /** HELP_AND_ADVICE: 'Help and Advice',
     PRODUCT_AND_SERVICES: 'Product and Services',
     DAM_LEVELS: 'Dam Levels',
     WUC_AT_A_GLANCE: 'WUC at a Glance',
     PRE_DEVELOPMENT_ENQUIRY: 'Pre Development Enquiry',
     ABOUT_YOUR_METER_READING: 'About your Meter Reading',
     TERMS_AND_CONDITIONS: 'Terms and Conditions',
     QUICK_LINKS: 'Quick Links'*/
};

const modules = {
    toolbar: {
        container: [
            [{ 'font': [] }, { 'size': [] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'script': 'super' }, { 'script': 'sub' }],
            [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['direction', { 'align': [] }],
            ['link', 'image', 'video', 'formula'],
            ['clean']
        ],
    },
    clipboard: {
        matchVisual: false,
    }
};

const formats = [
    'header', 'font',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet',
    'link', 'image', 'video'
];

const EditPageForm = ({ pageId, refetchPages }) => {
    const { closeDrawer } = useDrawer();
    const quillRef = useRef(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState(false);
    const [category, setCategory] = useState(null);
    const [isForm, setIsForm] = useState(false);
    const [isQuicklink, setIsQuicklink] = useState(false);
    const toast = useToast({ position: 'top-right' });
    const [page, setPage] = useState(null);
    const [activeRevision, setActiveRevision] = useState(null);
    const [isSubmitting, setSubmitting] = useState(false);
    const imageHandler = useCallback(() => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            if (file) {
                try {
                    const response = await fileService.uploadFile(file, new Date().getTime().toString(), "pageImages");
                    const quill = quillRef.current.getEditor();
                    const range = quill.getSelection(true);
                    quill.insertEmbed(range.index, 'image', response.data.fileUrl, Quill.sources.USER);
                    quill.setSelection(range.index + 1, Quill.sources.SILENT);
                } catch (error) {
                    console.error('Error uploading image: ', error);
                    toast({
                        title: 'Error uploading image',
                        description: error.message || 'Failed to upload image.',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }
            }
        };
    }, [toast]);

    useEffect(() => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();
            quill.getModule('toolbar').addHandler('image', imageHandler);
        }
    }, [imageHandler]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const fetchedPage = await pageService.getPageById(pageId);
                setPage(fetchedPage.data);
                setIsQuicklink(fetchedPage.data.isQuicklink);
            } catch (error) {
                toast.closeAll();
                toast({
                    title: 'Error fetching page data',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                });
            }
        };
        if (pageId) {
            fetchData();
        }

    }, [pageId]);
    useEffect(() => {
        const getActiveRevision = async () => {
            try {
                if (page && page.revisions) {
                    setActiveRevision(await page.revisions.find(revision => revision?.active));
                } else {
                    setActiveRevision(null);
                }
            } catch (e) {
                setActiveRevision(null);
            }
        };
        if (page) {
            getActiveRevision();
        }
    }, [page]);

    const validationSchema = Yup.object().shape({
        pageName: Yup.string().required('Page name is required.'),
        description: Yup.string().nullable(),
        category: Yup.string().required('Category is required.'),
        windowTitle: Yup.string().nullable(),
        isForm: Yup.boolean().required('Form is required.'),
        url: Yup.array().of(Yup.object().shape({
            title: Yup.string().nullable(),
            link: Yup.string().nullable()
        })),
        isQuicklink: Yup.boolean(),
    });


    const handleSubmit = async (values) => {
        try {
            if (values.isForm && (values.url.length && !values.url[values.url.length - 1]?.link)) {
                values.description = ""
                toast({
                    title: 'Upload document on the last input field',
                    status: 'warning',
                    duration: 5000,
                    isClosable: true,
                });
                return
            }
            if (values.parentPage && !values.isForm && !values.description) {
                values.url = []
                toast({
                    title: 'Page content is required',
                    status: 'warning',
                    duration: 5000,
                    isClosable: true,
                });
                return
            }
            setSubmitting(true);
            console.log(pageId, values)
            await pageService.updatePage(pageId, values);
            toast({
                title: 'Page updated successfully',
                description: 'The page details have been successfully updated.',
                status: 'success',
                duration: 5000,
                isClosable: true
            });
            refetchPages();
            closeDrawer();
        } catch (error) {
            toast({
                title: 'Failed to update page',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Formik
            enableReinitialize
            initialValues={{
                url: activeRevision?.url || [],
                windowTitle: activeRevision?.windowTitle || '',
                description: activeRevision?.description || '',
                isForm: activeRevision?.isForm || false,
                pageName: page?.pageName || '',
                category: page?.category || '',
                isQuicklink: isQuicklink
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {formikProps => (
                <Form>
                    <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                        <CustomAlert status="warning" message="Make sure all fields are correctly filled before submitting." />
                        <Box>
                            <GridItem>
                                <FormControl isRequired isInvalid={formikProps.errors.category && formikProps.touched.category}>
                                    <FormLabel htmlFor="category">Category</FormLabel>
                                    <>
                                        <ThemedStyledInput
                                            {...formikProps.getFieldProps('category')}
                                            id="category"
                                            disabled
                                            placeholder="Enter the category"
                                            value={page?.category}
                                        />
                                    </>
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl isRequired isInvalid={formikProps.errors.pageName && formikProps.touched.pageName}>
                                    <FormLabel htmlFor="pageName">Page Name</FormLabel>
                                    <ThemedStyledInput
                                        {...formikProps.getFieldProps('pageName')}
                                        id="pageName"
                                        placeholder="Enter the page name"
                                    />
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl isRequired isInvalid={formikProps.errors.windowTitle && formikProps.touched.windowTitle}>
                                    <FormLabel htmlFor="windowTitle">Window Title</FormLabel>
                                    <ThemedStyledInput
                                        {...formikProps.getFieldProps('windowTitle')}
                                        id="windowTitle"
                                        placeholder="Enter the window title"
                                    />
                                </FormControl>
                            </GridItem>
                            {page?.parentPage &&
                                <>
                                    {!page?.isForm ?
                                        <GridItem>
                                            <FormControl isRequired isInvalid={formikProps.errors.description && formikProps.touched.description}>
                                                <FormLabel htmlFor="description">Content</FormLabel>
                                                <ReactQuill
                                                    theme="snow"
                                                    ref={quillRef}
                                                    value={formikProps.values.description}
                                                    modules={modules}
                                                    onChange={(content) => formikProps.setFieldValue('description', content)}
                                                />
                                            </FormControl>
                                        </GridItem> :
                                        <DropBoxList formikProps={formikProps} />
                                    }

                                    <FormControl display='flex' alignItems='center'>
                                        <FormLabel htmlFor='isQuicklink' mb='0'>
                                            Show in Quick Links?
                                        </FormLabel>
                                        <Switch
                                            id='isQuicklink'
                                            name="isQuicklink"
                                            isChecked={formikProps.values.isQuicklink}
                                            onChange={(e) => {
                                                formikProps.setFieldValue('isQuicklink', e.target.checked);
                                            }}
                                        />
                                    </FormControl>
                                </>
                            }
                        </Box>
                        <ButtonStack>
                            {!formikProps.values.isForm &&
                                <CustomButton type="button" showIcon={false} onClick={onOpen}>Preview</CustomButton>
                            }
                            <CustomButton type="submit" showIcon={false} isLoading={formikProps.isSubmitting}>Submit</CustomButton>
                        </ButtonStack>
                    </VStack>
                    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Preview Page</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <div className="ql-editor">
                                    <Text mb={4} dangerouslySetInnerHTML={{ __html: formikProps.values.description }} />
                                </div>
                            </ModalBody>
                            <Button mr={3} onClick={onClose}>
                                Close
                            </Button>
                        </ModalContent>
                    </Modal>
                </Form>
            )}
        </Formik>
    );
};

export default EditPageForm;
